import useAppState, { State } from '../hooks/useAppState'
import useClientWindowApi from '../hooks/useClientWindowApi'
import useIsMobile from '../hooks/useIsMobile'
import Box from './Box'
import { StrategyView } from './StrategyView'
import IndicatorDashboard from './Dashboard'
import Menu from './Menu'
import OnboardingDownloadAppMobile from './OnboardingDownloadAppMobile'
import StrategyViewOnMount from './StrategyViewOnMount'
import SandboxLoggedInPage from './SandboxLoggedInPage'
import Settings from './Settings'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { Config } from '../ygdrasil/types/types'

const AppContainer = () => {
  const { state } = useAppState()
  const isMobile = useIsMobile()
  const config = useLoggedInConfig()
  useClientWindowApi()

  if (config.enableLoggedInSandbox) return <SandboxLoggedInPage />

  if (isMobile) return <OnboardingDownloadAppMobile />

  const MainComponent = getMainComponent(state, config)

  return (
    <Box fullWidth fullHeight>
      <StrategyViewOnMount />
      <Box fullWidth align="stretch" direction="row" fullHeight>
        <Box fullWidth align="stretch" direction="row" fullHeight>
          <Menu />
          <Box fullWidth>
            <MainComponent />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AppContainer

function getMainComponent(state: State, config: Config) {
  switch (true) {
    case state.showSettings:
      return Settings
    case state.showDashboard:
      return IndicatorDashboard
    default:
      return StrategyView
  }
}
