import { smallSpacing, SMALL_SPACING_PX } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { DashboardListArrowDown, DashboardListArrowRight, DashboardListGoalsIcon } from './Icons'

export function MyActionsRowHeader({
  item,
  level = 0,
  onClickBadge,
  onClickHeader
}: {
  item: Item
  level?: number
  onClickBadge?: () => unknown
  onClickHeader?: () => unknown
}) {
  const offset = level * SMALL_SPACING_PX * 2
  const textKey =
    item.data.searchType === 'keyResult' ? Texts.myActionsIndicatorSummary : Texts.myActionObjectiveSummary
  return (
    <Box fullWidth direction="row" align="center" width="inherit">
      <Box style={{ marginLeft: offset }} onClick={onClickBadge} pointer>
        {item.isExpanded && item.hasChildren ? <DashboardListArrowDown /> : <DashboardListArrowRight />}
      </Box>
      {item.data.searchType === 'keyResult' && (
        <Box right spacing={smallSpacing}>
          <DashboardListGoalsIcon />
        </Box>
      )}
      <Box right spacing={smallSpacing} width="inherit" onClick={onClickHeader} link>
        <FigmaText textKey={textKey} text={item.data.name} />
      </Box>
    </Box>
  )
}

// test version lens again
