const YOUTUBE_URLS = ['youtube', 'youtu.be']
const VIMEO = 'vimeo'

function getExtension(filename) {
  const parts = filename.split('.')
  return parts[parts.length - 1]
}
export function isVideo(url?: string) {
  if (!url) return false
  if (isYoutube(url)) return true
  if (isVimeo(url)) return true
  const ext = getExtension(url)
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
    case 'mov':
      return true
  }
  return false
}

export const isVimeo = (url: string) => url.includes(VIMEO)
export const isYoutube = (url: string) => YOUTUBE_URLS.some((youtube) => url.includes(youtube))
