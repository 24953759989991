import { FormikProps } from 'formik'
import { tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import RichTextEditor from './RichTextEditor'

export function RichTextEditorFormik<T>(props: {
  name: string
  labelTextKey?: AntiloopTextType
  formProps: FormikProps<T>
  onBlur?: () => void
  placeHolderTextKey?: AntiloopTextType
}) {
  const { name, formProps, labelTextKey, onBlur, placeHolderTextKey } = props
  return (
    <Box fullWidth>
      {labelTextKey && <FigmaText textKey={labelTextKey} />}
      <Box top spacing={tinySpacing} fullWidth>
        <RichTextEditor
          value={formProps.values[name]}
          onChange={(state) => formProps.setFieldValue(name, state)}
          onBlur={onBlur}
          placeHolderTextKey={placeHolderTextKey}
        />
      </Box>
    </Box>
  )
}
