import dayjs from 'dayjs'
import EventCategories from '../enums/EventCategories'
import EventTypes from '../enums/EventTypes'
import { Activity, BaseObject, GrowthIndicator, KeyResult, Objective, Organization, Team, User } from '../types/types'

export type Event<T> = BaseObject &
  CreateEventRequest<T> & {
    likes: { [uid: string]: { likedAt: string; name: string } }
  }

export type OurEvent<T> = Event<T>
export type CreateEventRequest<T> = {
  uid: string
  timestamp: string // dayjs().format(),
  orgName: string // org.name
  email: string // user.email
  organizationId: string // org._id
  eventCategory: EventCategories
  eventType?: EventTypes
  objectiveId?: string // objective._id
  teamId?: string // team._id
  indicatorId?: string // indicator._id
  growthIndicatorId?: string // growthIndicator._id
  activityId?: string // activity._id
  params: T
}

export type EventParams = {
  user: User
  org: Organization
  eventType?: EventTypes
  objective?: Objective
  team?: Team
  indicator?: KeyResult
  growthIndicator?: GrowthIndicator
  activity?: Activity
} & Partial<UserCelebratedEventParams> &
  Partial<UserAddIndicatorValueParams>

type UserAddIndicatorValueParamsBase = {
  indicator: KeyResult
  objective: Objective
}
export type UserCelebratedEventParams = UserAddIndicatorValueParamsBase & {
  celebratedBy: string
  eventId: string
  eventCreatedBy: string
}

export type UserAddIndicatorValueParams = UserAddIndicatorValueParamsBase & {
  prevValue: number
}

export type UserUpdateIndicatorValueParams = UserAddIndicatorValueParams

export const mapEvent = <T extends EventParams>(catagory: EventCategories, params: T): CreateEventRequest<T> => {
  const { user, org, eventType, objective, team, indicator, growthIndicator, activity } = params
  return {
    uid: user._id,
    timestamp: dayjs().format(),
    orgName: org.name,
    email: user.email || '',
    organizationId: org._id,
    eventCategory: catagory,
    eventType,
    objectiveId: objective?._id,
    teamId: team?._id,
    indicatorId: indicator?._id,
    growthIndicatorId: growthIndicator?._id,
    activityId: activity?._id,
    params
  }
}
