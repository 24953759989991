import Menu, { MenuProps } from '@mui/material/Menu'
import { MenuItem } from './MenuItem'
import { findKeyResultAndObjectiveForActivity } from '../ygdrasil/libs/KeyResultHelper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Activity } from '../ygdrasil/types/types'

import { withStopProgagation } from '../libs/HtmlHelper'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { updateActivity } from '../ygdrasil/libs/ProcessHandler'
import { openEditActivityDrawer } from './DrawerGlobalEditActivityDialog'
import { CreateEditActivityFormProps, OnCreateEditActivity } from './EditActivityDialogForm'

type Props = {
  activity: Activity
  onClose: () => void
  anchorEl?: HTMLElement
  onCreateEditActivity?: OnCreateEditActivity
  onDeleteActivity?: OnCreateEditActivity
} & Partial<MenuProps>

export default function StrategyViewObjectiveListKeyResultActivityListItemMenu(props: Props) {
  const { activity, anchorEl, onClose, onCreateEditActivity, onDeleteActivity } = props
  const flaggedTextKey = activity.flagged
    ? Texts.activityMenuUnflagActivityButtonText
    : Texts.activityMenuFlagActivityButtonText

  const prioritizedTextKey = activity.isPrioritized
    ? Texts.activityMenuUnPriorizeActivityButtonText
    : Texts.activityMenuPriorizedActivityButtonText

  const logEvent = useLogEvent()
  const allItems = useAllItems()
  const res = findKeyResultAndObjectiveForActivity(activity, allItems)

  if (!res) return null

  const onFlagItem = withStopProgagation(() => {
    const _activity = { ...activity, flagged: !activity.flagged } as Activity
    updateActivity(_activity, logEvent)
    onCreateEditActivity && onCreateEditActivity(_activity)
    onClose()
  })

  const onPrioritized = withStopProgagation(() => {
    const _activity = { ...activity, isPrioritized: !activity.isPrioritized } as Activity
    updateActivity(_activity, logEvent)
    onCreateEditActivity && onCreateEditActivity(_activity)
    onClose()
  })

  const onClickEditItem = withStopProgagation(() => {
    onClose()
    const { keyResult } = res
    const props: CreateEditActivityFormProps = {
      activity,
      keyResult,
      onCreateEditActivity,
      onDeleteActivity
    }
    openEditActivityDrawer(props)
  })

  return (
    <Menu open={Boolean(anchorEl)} {...props}>
      <MenuItem onClick={onClickEditItem} text={getFigmaText(Texts.activityMenuEditActivityButtonText)}></MenuItem>
      <MenuItem onClick={onFlagItem} text={getFigmaText(flaggedTextKey)}></MenuItem>
      <MenuItem onClick={onPrioritized} text={getFigmaText(prioritizedTextKey)}></MenuItem>
    </Menu>
  )
}
