import Box from './Box'
import RoadmapView from './RoadmapView'
import StrategyViewObjectiveTree from './StrategyViewObjectiveTree'

export default function Roadmap() {
  return (
    <Box top bottom fullWidth>
      <RoadmapView />
      <Box top fullWidth>
        <StrategyViewObjectiveTree hideSerchBar onlyShowIfSelectedObjective searchState={{ searchDates: undefined }} />
      </Box>
    </Box>
  )
}
