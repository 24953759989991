import styled from '@emotion/styled'
import { FormControlLabel } from '@mui/material'
import Switch from '@mui/material/Switch'
import { useState } from 'react'
import { minBorderRadius } from '../enums/BorderRadixes'
import { defaultSpacing, littleSpacing, mediumSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getFilteredGrowthIndicators, SuccessViewGraphEditType } from '../libs/SuccessViewBarChartHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { GrowthIndicator, SearchDates } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { InfoIcon } from './Icons'
import SuccessViewGraphViewBarChart from './SuccessViewGraphViewBarChart'

export function SuccessViewGraphView({
  growthIndicators: allGrowthIndicators,
  searchDates
}: {
  growthIndicators: GrowthIndicator[]
  searchDates: SearchDates
}) {
  const { state, setState } = useAppState()
  const [editType, setEditType] = useState<SuccessViewGraphEditType>(SuccessViewGraphEditType.FIELDS)
  const { searchState } = state
  const { showArchivedGrowthIndicators, searchTextGrowthView } = searchState
  let growthIndicators: GrowthIndicator[] = allGrowthIndicators.filter(({ isArchived }) => !isArchived)

  if (showArchivedGrowthIndicators) growthIndicators = allGrowthIndicators
  if (!!searchTextGrowthView)
    growthIndicators = growthIndicators.filter(({ name }) =>
      name.toUpperCase().includes(searchTextGrowthView.toUpperCase())
    )

  const filteredGrowthIndicators = getFilteredGrowthIndicators(growthIndicators, searchDates)
  const shouldShowSwitchControl = !!filteredGrowthIndicators && filteredGrowthIndicators.length > 0

  const onClickEmptyStateText = () => setState({ ...state, selectedSuccessView: 'sheet' })

  return (
    <Box fullWidth>
      {shouldShowSwitchControl && (
        <SwitchWrapper fullWidth direction="row" align="center" justify="space-between">
          <Box direction="row" align="center">
            <Box right left spacing={littleSpacing}>
              <InfoIcon />
            </Box>
            <FigmaText
              textKey={
                editType === SuccessViewGraphEditType.FIELDS
                  ? Texts.graphViewToggleEditWithFields
                  : Texts.graphViewToggleEditByDragging
              }
            />
          </Box>
          <StyledSwitch>
            <FormControlLabel
              control={<Switch />}
              label={<FigmaText textKey={Texts.graphViewToggleEditInGraph} />}
              labelPlacement="start"
              color={Colors.primaryBase}
              onChange={(e, checked) =>
                setEditType(checked ? SuccessViewGraphEditType.DRAG : SuccessViewGraphEditType.FIELDS)
              }
            />
          </StyledSwitch>
        </SwitchWrapper>
      )}

      <GraphsWrapper fullWidth direction="row" top>
        {!!filteredGrowthIndicators &&
          filteredGrowthIndicators.length > 0 &&
          filteredGrowthIndicators.map((growthIndicator) => (
            <SuccessViewGraphViewBarChart
              editType={editType}
              key={growthIndicator._id}
              growthIndicator={growthIndicator}
              searchDates={searchDates}
            />
          ))}
        {(!filteredGrowthIndicators || filteredGrowthIndicators.length === 0) && (
          <Box fullWidth align="center" justify="center">
            <Button buttonType="empty_state" onClick={onClickEmptyStateText}>
              <FigmaTextForStrategyModel textKey={Texts.emptyStateGraphView} />
            </Button>
          </Box>
        )}
      </GraphsWrapper>
    </Box>
  )
}

const SwitchWrapper = styled(Box)`
  background-color: ${Colors.surface};
  height: ${mediumSpacing};
  border-radius: ${minBorderRadius};
`
const StyledSwitch = styled(Box)`
  .MuiFormControlLabel-root {
    margin: 0 !important;
  }
`
const GraphsWrapper = styled(Box)`
  flex-wrap: wrap;
  gap: ${defaultSpacing};
`
