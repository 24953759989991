import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import InviteUserAddIcon from './InviteUserAddIcon'
import { PageHeaderButtons } from './PageHeaderButtons'
import UserWidget from './UserWidget'

export function PageHeader({
  headerTextKey,
  headerText: text,
  disabledUserMenu
}: {
  headerTextKey: AntiloopTextType
  headerText?: string
  disabledUserMenu?: boolean
}) {
  return (
    <Box direction="row" justify="space-between" fullWidth>
      <Box fullWidth>
        <FigmaTextForStrategyModel textKey={headerTextKey} text={text} />
      </Box>
      <Box direction="row" align="center" top spacing={tinySpacing}>
        <PageHeaderButtons />

        <Box left spacing={smallSpacing}>
          <UserWidget disabled={disabledUserMenu} />
        </Box>
      </Box>
    </Box>
  )
}
