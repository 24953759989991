import React from 'react'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import DetectOverflow from './DetectOverflow'
import Tooltip from './Tooltip'

const WithTooltipOverflow: React.FC<{
  ellipsisTextKey: AntiloopTextType
  text?: string
  style?: React.CSSProperties
}> = ({ ellipsisTextKey: textKey, text = '', children, style }) => {
  const [isOverflowing, setIsOverflowing] = React.useState(false)
  if (!isOverflowing) text = ''
  const _style = { ...textKey.style, ...style }

  return (
    <Tooltip title={text} placement="top">
      <Box fullWidth>
        <DetectOverflow onChange={setIsOverflowing} style={_style}>
          {children}
        </DetectOverflow>
      </Box>
    </Tooltip>
  )
}

export default WithTooltipOverflow
