import styled from '@emotion/styled'
import invariant from 'invariant'
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts'
import { GraphProps, useGraph } from '../ygdrasil/hooks/useGraph'
import Colors from '../ygdrasil/libs/Colors'
import { createUniqueId } from '../ygdrasil/libs/Common'
import { KeyResult } from '../ygdrasil/types/types'
import Box from './Box'
import { GraphTooltip } from './GraphTooltip'

export const GraphTiny = (props: GraphProps) => {
  const { item } = props
  const keyResult = item.data.data as KeyResult

  const { lastValue, values } = useGraph(props)

  const isEmptyState = (keyResult.values || []).length === 0

  if (!lastValue) return null
  invariant(typeof lastValue.value === 'number', 'Bad input: lastValue.value')

  return (
    <Container fullWidth position="relative" fullHeight>
      <Box fullWidth style={{ opacity: isEmptyState ? 0.5 : 1 }} fullHeight>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={values} margin={{ right: 10, top: 10 }}>
            <Line
              type="monotone"
              dataKey="value"
              stroke={Colors.good}
              dot={(props) => <span key={createUniqueId()} />}
            />
            {!isEmptyState && <Tooltip content={(props) => <GraphTooltip {...props} />} />}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.whiteBg};
`
