import React from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { InitialValues } from '../libs/EditKeyResultDialogHelper'
import { KeyResult, Unit } from '../ygdrasil/types/types'
import Box from './Box'
import { EditKeyResultsSectionUnitButton } from './EditKeyResultsSectionUnitButton'

export type Props = {
  keyResult: InitialValues
  onSelectUnit: (unit: Unit) => unknown
}

export default function EditKeyResultsSectionUnitButtons(props: Props) {
  return (
    <Box fullWidth>
      <Box fullWidth direction="row">
        <Box>
          <EditKeyResultsSectionUnitButton unit={Unit.NUMERICAL} {...props} />
        </Box>
        <Box left spacing={smallSpacing}>
          <EditKeyResultsSectionUnitButton unit={Unit.PERCENT} {...props} />
        </Box>
        <Box left spacing={smallSpacing}>
          <EditKeyResultsSectionUnitButton unit={Unit.MONEY} {...props} />
        </Box>
        <Box left spacing={smallSpacing}>
          <EditKeyResultsSectionUnitButton unit={Unit.BOOLEAN} {...props} />
        </Box>
        <Box left spacing={smallSpacing}>
          <EditKeyResultsSectionUnitButton unit={Unit.INTEGRATION} {...props} />
        </Box>
      </Box>
      <Box fullWidth direction="row" top spacing={tinySpacing}>
        {/**
         * <Box left spacing={smallSpacing}>
          <EditKeyResultsSectionUnitButton unit={Unit.GLOBAL} {...props} />
        </Box>
         * 
         */}
      </Box>
    </Box>
  )
}
