import dayjs from 'dayjs'
import { AllItems } from '../hooks/useAllItems'
import { AntiloopImageType, AntiloopTextType } from '../types/Antiloop'
import { KeyResult, LeadNotificationInterval, Objective, User } from '../types/types'
import Images from './Images'
import { isCurrentOrFutureObjective } from './ObjectiveHelper'
import Texts from './Texts'

export const DEFAULT_LEAD_NOTIFICATION_INTERVAL = LeadNotificationInterval.WEEKLY

export const LeadNotificationIntervalTypes: {
  [leadNotificationInterval: string]: { textKey: AntiloopTextType; imageKey: AntiloopImageType }
} = {
  // eslint-disable-next-line prettier/prettier
  [LeadNotificationInterval.NONE]: {
    textKey: Texts.checkinNotificationOptionNoNotifications,
    imageKey: Images.notificationsOff
  },
  [LeadNotificationInterval.DAILY]: { textKey: Texts.checkinNotificationOptionDaily, imageKey: Images.dailyIcon },
  [LeadNotificationInterval.WEEKLY]: { textKey: Texts.checkinNotificationOptionWeekly, imageKey: Images.weeklyIcon },
  [LeadNotificationInterval.MONTHLY]: { textKey: Texts.checkinNotificationOptionMonthly, imageKey: Images.monthly }
}

const DEFAULT_LEAD_NOTIFICATION_INTERVAL_TYPE = LeadNotificationIntervalTypes[DEFAULT_LEAD_NOTIFICATION_INTERVAL]

export const getLeadNotificationIntervalType = (object: Objective | KeyResult) => {
  const { leadMetaData } = object
  if (!leadMetaData) return DEFAULT_LEAD_NOTIFICATION_INTERVAL_TYPE
  const { notificationInterval } = leadMetaData
  if (!notificationInterval) return DEFAULT_LEAD_NOTIFICATION_INTERVAL_TYPE
  return LeadNotificationIntervalTypes[notificationInterval]
}

export function getLeadObjectsForUser({ allIndicators, allObjectives }: AllItems, user: User, now = dayjs().format()) {
  const objectives = allObjectives
    .filter((o) => o.leadUserId === user._id)
    .filter((o) => isCurrentOrFutureObjective(o, now))
  const keyResults = allObjectives
    .filter((o) => isCurrentOrFutureObjective(o, now))
    .reduce((a, b) => {
      allIndicators
        .filter((i) => i.parentId === b._id)
        .filter((i) => i.leadUserId === user._id)
        .forEach((i) => a.push(i))
      return a
    }, [] as KeyResult[])
  return { objectives, keyResults }
}

export function hasEnabledNotifications(user: User) {
  return user.enableLeadNotifications === true
}
