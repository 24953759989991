import styled from '@emotion/styled'
import useShouldShowExpandedMenu from '../hooks/useShouldShowExpandedMenu'
import { isPropValid } from '../libs/EmotionHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'

type Props = { size: number }

export const OrganizationLogoEmptyState = ({ size }: Props) => {
  const shou = useShouldShowExpandedMenu()
  const textKey = shou ? Texts.applicationMenuBigLoggoButtonText : Texts.applicationMenuSmallLoggoButtonText
  return (
    <OrganizationLogoEmptyStateContainer justify="center" align="center" alignText="center" size={size}>
      <FigmaText textKey={textKey}></FigmaText>
    </OrganizationLogoEmptyStateContainer>
  )
}

const OrganizationLogoEmptyStateContainer = styled(Box, { shouldForwardProp: isPropValid })<{
  size: number
}>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border-radius: 50%;
  background-color: ${Colors.whiteBg};
  border: 0.5px solid ${Colors.secondary50};
`
