import styled from '@emotion/styled'
import MaterialSlider from '@mui/material/Slider'
import dayjs from 'dayjs'
import invariant from 'invariant'
import React, { useEffect, useState } from 'react'
import { SMALL_SPACING_PX } from '../enums/Spacings'
import {
  DEFAULT_SLIDER_VALUE_END,
  DEFAULT_SLIDER_VALUE_START,
  getMarks,
  getSliderDescriptionTextForValue,
  getStartAndEndValue,
  OnChangePeriod
} from '../libs/ObjectivePeriodAndTypeOptionsHelper'
import {
  createHandleSliderChange,
  getCalculatedSidesValues,
  getValuesSideChanged,
  onResetSlider
} from '../libs/ObjectivePeriodAndTypeOptionsSliderHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { hasCustomDates } from '../ygdrasil/libs/ObjectiveHelper'
import Texts from '../ygdrasil/libs/Texts'
import { SearchDates } from '../ygdrasil/types/types'
import Box from './Box'
import { ObjectivePeriodAndTypeOptionsLabels } from './ObjectivePeriodAndTypeOptionsLabels'
import { ObjectivePeriodRoadmapOptionsLabels } from './ObjectivePeriodRoadmapOptionsLabels'

type Props = {
  searchDates: SearchDates
  onChange: OnChangePeriod
  sliderStartDate?: string
  sliderEndDate?: string
  enableHelperText?: boolean
  timespanRestriction?: number
  dynamicPeriod?: boolean
}

export default function ObjectivePeriodAndTypeOptionsSlider(props: Props) {
  const {
    searchDates,
    onChange,
    sliderStartDate,
    sliderEndDate,
    dynamicPeriod,
    timespanRestriction,
    enableHelperText = true
  } = props
  const marks = getMarks(sliderStartDate, sliderEndDate)

  const values = marks.map(({ value }) => value)

  const min = Math.min(...values)
  const max = Math.max(...values)

  const [value, setValue] = React.useState<Array<number | undefined>>(getStartAndEndValue(marks, searchDates))
  const [valueHistory, setValueHistory] = useState<Array<number | undefined>>(getStartAndEndValue(marks, searchDates))
  const isDisabled = (searchDates && hasCustomDates(searchDates)) || false

  const handleChange = createHandleSliderChange(setValue)

  useEffect(() => setValue(getStartAndEndValue(marks, searchDates)), [searchDates])

  const onChangeCommitted = (values: number[]) => {
    const { isLeftValueChanged, isRightValueChanged } = getValuesSideChanged(valueHistory, values)
    const { nextLeftValue, nextRightValue } = getCalculatedSidesValues(
      isRightValueChanged,
      isLeftValueChanged,
      marks,
      values,
      timespanRestriction
    )
    const startDate = marks.find((mark) => mark.value === nextLeftValue)?.date
    invariant(startDate, '!startDate')
    let endDate = marks.find((mark) => mark.value === nextRightValue)?.date
    invariant(endDate, '!endDate')

    endDate = dayjs(endDate).add(-1, 'quarter').endOf('quarter').format()
    onChange({ startDate, endDate })
    if (!timespanRestriction) return
    setValueHistory(isLeftValueChanged ? [values[0], nextRightValue] : [nextLeftValue, values[1]])
  }

  const sliderHelperText = getSliderDescriptionTextForValue(value, marks, searchDates)
  const _onResetSlider = () => onResetSlider(isDisabled, onChange, searchDates)

  return (
    <Box fullWidth>
      {!dynamicPeriod && <ObjectivePeriodAndTypeOptionsLabels disabled={isDisabled} marks={marks} />}
      {dynamicPeriod && <ObjectivePeriodRoadmapOptionsLabels disabled={isDisabled} marks={marks} />}
      <Box fullWidth onClick={_onResetSlider}>
        <Slider
          value={[value[0] || DEFAULT_SLIDER_VALUE_START, value[1] || DEFAULT_SLIDER_VALUE_END]}
          disabled={isDisabled}
          onChange={handleChange}
          valueLabelDisplay="off"
          aria-labelledby="range-slider"
          marks={marks}
          step={null}
          min={min}
          max={max}
          onChangeCommitted={(e, value) => onChangeCommitted(value as number[])}
        />
      </Box>
      {enableHelperText && (
        <Box fullWidth top alignText="center">
          {sliderHelperText && <FigmaText textKey={Texts.cssPeriodSelectedResultText} text={sliderHelperText} />}
          {!sliderHelperText && hasCustomDates(searchDates) && (
            <Box fullWidth align="center">
              <Box onClick={_onResetSlider} link>
                <FigmaText textKey={Texts.periodSelectorGoBacktoQuartersInfo} />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

const Slider = styled(MaterialSlider)`
  color: ${Colors.primaryBase};

  .MuiSlider-markLabel {
    top: -${SMALL_SPACING_PX * 1.5}px;
  }

  .MuiSlider-thumb {
    background-color: #fff;
    border: 2px solid currentColor;
  }
`
