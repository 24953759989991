import { tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopImageType, AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { FigmaImageContainerSVGFullWidth } from './FigmaImageContainerSVGFullWidth'
import { StrategicPillar } from '../ygdrasil/types/types'
import { BusinessPlanRingArrowInfo } from './BusinessPlanRingArrowInfo'

export function Arrow({
  imageKey,
  strategicPillar,
  innerFontSize
}: {
  imageKey: AntiloopImageType
  strategicPillar: StrategicPillar
  innerFontSize: number
}) {
  const { name: text } = strategicPillar
  const textKey: AntiloopTextType = Texts.strategicPillar
  const { style } = textKey

  return (
    <Box position="relative" bottom spacing={tinySpacing} fullWidth align="center" justify="center">
      <Box fullWidth alignText="center" position="absolute" style={{ bottom: '60%' }}>
        <FigmaText textKey={{ ...textKey, style: { ...style, fontSize: innerFontSize / 1.4 } }} text={text} />
      </Box>
      <Box width="50%" direction="row" align="center">
        <FigmaImageContainerSVGFullWidth imageKey={imageKey} />
        <Box left>
          <BusinessPlanRingArrowInfo strategicPillar={strategicPillar} />
        </Box>
      </Box>
    </Box>
  )
}
