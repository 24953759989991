import useAppState, { State } from './useAppState'
import { BreakPointRule, useMediaQueryRules } from './useMediaQuery'

export default function useShouldShowExpandedMenu() {
  const { state } = useAppState()
  const rule = useMediaQueryRules()

  return getShouldShowExpendedMenu(rule, state)
}

export const getShouldShowExpendedMenu = (rule: BreakPointRule, state: State) => {
  const { shouldShowExpandedMenu } = state

  if (typeof shouldShowExpandedMenu === 'boolean') return shouldShowExpandedMenu

  return rule.shouldShowExpandedMenu
}
