import React, { useEffect } from 'react'
import Box from './Box'

type Props = {
  Body: React.FC
  Header: React.FC<{ isExpanded: boolean; onToggleExpand: () => unknown }>
  isExpanded?: boolean
}

export default function RoadmapAccordion(props: Props) {
  const [isExpanded, setIsExpanded] = React.useState(true)
  const { Body, Header } = props

  const _onToggleExpand = () => {
    const _isExpanded = !isExpanded
    setIsExpanded(_isExpanded)
  }

  useEffect(() => {
    if (typeof props.isExpanded === 'undefined') return
    setIsExpanded(props.isExpanded)
  }, [props.isExpanded])

  return (
    <>
      <Box key="header" fullWidth direction="row" align="center">
        <Header isExpanded={isExpanded} onToggleExpand={_onToggleExpand} />
      </Box>
      {isExpanded && (
        <Box key="body" fullWidth direction="row">
          <Body />
        </Box>
      )}
    </>
  )
}
