import styled from '@emotion/styled'
import { SMALL_SPACING_PX } from '../enums/Spacings'
import { ItemData } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { FigmaTextWithSearchResultProps } from './FigmaTextWithSearchResult'
import WithTooltipOverflow from './WithTooltipOverflowExperimental'

export function StrategyViewObjectiveListItemSummaryItem({
  item,
  level,
  FigmaTextComponent,
  searchText,
  textKey
}: {
  item: ItemData
  level: number
  FigmaTextComponent: React.FC<FigmaTextWithSearchResultProps>
  textKey: AntiloopTextType
  searchText?: string
}) {
  const offset = level * SMALL_SPACING_PX * 2
  const { isSelected } = item
  const figmTextProps = { textKey, text: item.name, searchText }

  if (isSelected)
    return (
      <WrappedBox width="inherit">
        <FigmaTextComponent {...figmTextProps} />
      </WrappedBox>
    )

  return (
    <WithTooltipOverflow textKey={textKey} text={item.name} offset={offset}>
      <FigmaTextComponent {...figmTextProps} />
    </WithTooltipOverflow>
  )
}

const WrappedBox = styled(Box)`
  overflow-wrap: anywhere;
`
