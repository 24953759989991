/* eslint-disable prettier/prettier */

import { AuthenticationResult } from '@azure/msal-common'
import { State } from '../../hooks/useAppState'
import CloudFunction from '../enums/CloudFunctions'
import { AcquireTokenByCodeRequest, AcquireTokenForConnection, CreateCustomAuthTokenRequest, GetDataForGoogleSheetDriveItemRequest, CreateLoginTokenRequest, CustomAuthToken, DeleteOrganizationRequest, GoogleSheetDriveItemData, GetOrganizationsForNonOnboardedFirebaseUserRequest, GoogleSheetDriveItem, InvitationRequest, LoginWithCodeToken, Organization, ShouldOnboardRequest, CreateCustomAuthTokenRequestForInternalUser, GetJiraIssuesReq } from '../types/types'
import { callFunction } from './CloudFunctionsApiHandlerHelper'
import { Conversation } from './ConversationHelper'
import { JiraIssue } from '../types/Jira'

export const getOrganizationsForNonOnboardedFirebaseUser = (req: GetOrganizationsForNonOnboardedFirebaseUserRequest, state: State): Promise<Organization[]> => callFunction(CloudFunction.getOrganizationsForNonOnboardedFirebaseUser, state, req)

export const throwError = (state: State): Promise<void> => callFunction(CloudFunction.throwError, state)

export const throwServerError = (state: State): Promise<void> => callFunction(CloudFunction.throwServerError, state)

export const shouldOnboard = (req: ShouldOnboardRequest, state: State): Promise<boolean> => callFunction(CloudFunction.shouldOnboard, state, req)

export const shouldOnboardEmailSignup = (req: ShouldOnboardRequest, state: State): Promise<boolean> => callFunction(CloudFunction.shouldOnboardEmailSignup, state, req)

export const deleteAccount = (uid: string, state: State): Promise<void> => callFunction(CloudFunction.deleteAccount, state, uid)

export const deleteOrganization = (req: DeleteOrganizationRequest, state: State): Promise<void> => callFunction(CloudFunction.deleteOrganization, state, req)

export const invite = (req: InvitationRequest, state: State): Promise<void> => callFunction(CloudFunction.invite, state, req)

export const createAuthenticationToken = (req: CreateCustomAuthTokenRequest, state: State): Promise<void> => callFunction(CloudFunction.createAuthenticationToken, state, req)

export const createLoginWithCodeToken = (req: CreateLoginTokenRequest, state: State): Promise<CreateLoginTokenRequest> => callFunction(CloudFunction.createLoginWithCodeToken, state, req)

export const createAuthenticationTokenForLoginToken = (req: LoginWithCodeToken, state: State): Promise<CustomAuthToken> => callFunction(CloudFunction.createAuthenticationTokenForLoginToken, state, req)

export const acquireTokenByCode = (req: AcquireTokenByCodeRequest, state: State): Promise<AuthenticationResult> => callFunction(CloudFunction.acquireTokenByCode, state, req)

export const acquireTokenForConnection = (req: AcquireTokenForConnection, state: State): Promise<AuthenticationResult> => callFunction(CloudFunction.acquireTokenForConnection, state, req)

export const syncDataForDatasource = (dataSourceId: string, state: State): Promise<AuthenticationResult> => callFunction(CloudFunction.syncDataForDatasource, state, dataSourceId)

export const getDataForGoogleSheetDriveItem = (req: GetDataForGoogleSheetDriveItemRequest, state: State): Promise<GoogleSheetDriveItemData | undefined> => callFunction(CloudFunction.getDataForGoogleSheetDriveItem, state, req)

export const getGoogleSheetDriveItem = (driveItemId: string, state: State): Promise<GoogleSheetDriveItem> => callFunction(CloudFunction.getGoogleSheetDriveItem, state, driveItemId)

export const getChaptGPTAnswer = (message: string, state: State): Promise<string> => callFunction(CloudFunction.getChaptGPTAnswer, state, message)

export const continueConversation = (conversation: Conversation, state: State) => callFunction(CloudFunction.continueConversation, state, conversation)

export const createAuthenticationTokenForInternalUsers = (req: CreateCustomAuthTokenRequestForInternalUser, state: State) => callFunction(CloudFunction.createAuthenticationTokenForInternalUsers, state, req)

export const getJiraIssues = (req: GetJiraIssuesReq, state: State): Promise<JiraIssue[]> => callFunction(CloudFunction.getJiraIssues, state, req)

export const createStripePortalLink = (req, state: State): Promise<{url: string}> => callFunction(CloudFunction.createStripePortalLink, state, req)