import { Formik } from 'formik'
import React from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH } from '../libs/HardCodedSizes'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import CheckboxFormik from './CheckboxFormik'
import FigmaText from '../ygdrasil/components/FigmaText'
import FigmaTextLoader from './FigmaTextLoader'
import Modal, { ModalProps } from './Modal'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'

type Props = ModalProps
type InitialValues = { showArchivedGrowthIndicators?: boolean }
export function ModalSearchGrowthIndicators(props: Props) {
  const { state, setSearchState } = useAppState()
  const { searchState } = state
  const { showArchivedGrowthIndicators } = searchState
  const initialValues: InitialValues = { showArchivedGrowthIndicators }
  const { onClose } = props

  const onSubmit = (values: InitialValues) => {
    setSearchState({ ...searchState, ...values })
    onClose()
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(formProps) => {
        return (
          <Modal {...props}>
            <Box fullWidth style={{ maxWidth: EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH }}>
              <Box fullWidth top align="center" direction="row" spacing={smallSpacing}>
                <Box right spacing={smallSpacing}>
                  <CheckboxFormik formProps={formProps} name="showArchivedGrowthIndicators" />
                </Box>
                <FigmaTextForStrategyModel textKey={Texts.filterDialogShowArchivedObjectiveCheckBoxText} />
              </Box>
              <Box top direction="row" align="center" justify="flex-end" fullWidth>
                <Box right spacing={tinySpacing}>
                  <Button
                    buttonType="secondary"
                    textKey={Texts.cancelButtonText}
                    onClick={onClose}
                    spacing={smallSpacing}
                  />
                </Box>
                <Button onClick={formProps.handleSubmit as any} spacing={smallSpacing}>
                  <FigmaTextLoader textKey={Texts.filterButtonText} />
                </Button>
              </Box>
            </Box>
          </Modal>
        )
      }}
    </Formik>
  )
}
