import { regularSpacing } from '../enums/Spacings'
import useResponsive from '../hooks/useResponsive'
import { shouldShowGoogle, shouldShowMicrosoft } from '../libs/SignInWithAuthProvidersHelper'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { SignInViewProps } from '../ygdrasil/types/SignInTypes'
import Box from './Box'
import ContinueWithButton from './ContinueWithButton'

export function SignInWithAuthProviders(signInViewProps: SignInViewProps) {
  const { onLoginWithGoogle, onLoginWithMicrosoft, onLoginWithApple, signInState, enableAppleAuthProvider } =
    signInViewProps
  const { preferredLoginMethod } = signInState

  const showGoogle = shouldShowGoogle(preferredLoginMethod)
  const showMicrosoft = shouldShowMicrosoft(preferredLoginMethod)
  const { isMobile } = useResponsive()

  return (
    <Box fullWidth direction={!isMobile ? 'row' : 'column'} gap={regularSpacing}>
      {showGoogle && (
        <Box fullWidth={isMobile}>
          <ContinueWithButton
            onLogin={onLoginWithGoogle}
            textKey={Texts.onboardingAuthProviderGoogleButtonText}
            imageKey={Images.googleIcon}
          />
        </Box>
      )}
      {showMicrosoft && (
        <Box fullWidth={isMobile}>
          <ContinueWithButton
            onLogin={onLoginWithMicrosoft}
            textKey={Texts.onboardingAuthProviderMicrosoftButtonText}
            imageKey={Images.office365}
          />
        </Box>
      )}
      {enableAppleAuthProvider && (
        <Box top fullWidth={isMobile}>
          <ContinueWithButton
            onLogin={onLoginWithApple}
            textKey={Texts.onboardingAuthProviderAppleButtonText}
            imageKey={Images.appleIcon}
          />
        </Box>
      )}
    </Box>
  )
}
