// @flow
import dayjs from 'dayjs'
import invariant from 'invariant'
import qs from 'query-string'
import { Organization } from '../types/types'

type QueryParams = { [key: string]: string }

export const groupBy = <T>(xs: Array<T>, key: keyof T): { [key: string]: Array<T> } => {
  return xs.reduce((rv, x: any) => {
    // eslint-disable-next-line prettier/prettier
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {} as { [key: string]: Array<T> })
}

export const repeatEvery = (func: Function, interval: number) => {
  const now = dayjs().valueOf()
  const delay = interval - (now % interval)
  setTimeout(func, delay)
  setTimeout(() => setInterval(func, interval), delay)
}

export const createUniqueId = () => Math.random().toString(36).substr(2, 9)

export const getArray = (length: number): Array<number> =>
  // eslint-disable-next-line prefer-spread
  Array.apply(null, { length } as any).map(Number.call, Number) as number[]

export const asObject = <T>(arr: Array<T>, key: keyof T): { [key: string]: T } =>
  arr.reduce((a, b) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    a[b[key]] = b
    return a
  }, {} as { [key: string]: T })

export const removeDuplicates = <T>(arr: Array<T>, key: keyof T): T[] => asArray(asObject(arr, key))

export const asArray = <T>(obj: { [key: string]: T }): T[] => Object.keys(obj).map((_id) => obj[_id])

export const isNumber = (n: unknown) => typeof n === 'number' && !isNaN(n)
export const isString = (n: unknown) => typeof n === 'string'

export const getRandomValue = (floor: number, ceil: number) => {
  const diff = ceil - floor
  return floor + Math.random() * diff
}

export const getFeatureFlagsFromUrl = () => {
  if (typeof window === 'undefined') return {}
  if (typeof window.location === 'undefined') return {}
  let _text = qs.parse(window.location.search)?.features
  if (!_text) return {}

  if (Array.isArray(_text)) _text = _text[0]
  const text = _text as string
  return text.split(',').reduce((a, flag: string) => {
    a[flag] = true
    return a
  }, {} as { [flag: string]: boolean })
}

export const getMean = (arr: number[]) => {
  if (arr.length === 0) return 0
  return arr.reduce((a, b) => a + b, 0) / arr.length
}

export const formatPercent = (n: number) => `${n.toFixed()}%`

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getDomain = (email: string) => {
  const domain = email.split('@')[1]
  invariant(domain, 'Cant parse email: %s', email)
  return domain.toLowerCase()
}

export const getOrganizationsForEmail = (email: string, orgs: Organization[]): Organization[] => {
  const domain = getDomain(email)
  return orgs.filter((org) => (org as any).domain === domain || org.domains?.includes(domain))
}

export const getEmailForFirebaseUser = (user: any): string | undefined => {
  return user.email || user.providerData?.[0]?.email
}

export const mapCssHeightToNumber = (cssHeightInPixels: string) => parseInt(cssHeightInPixels.replace('px', ''))

export function getDecimals(num: number) {
  if (Math.floor(num.valueOf()) === num.valueOf()) return 0
  return num.toString().split('.')[1]?.length || 0
}

export const toPx = (cssString: string) => parseInt(cssString.replace('px', ''))

export const addQueryParamsToUrl = (url: string, queryParams: QueryParams) => {
  const keys = Object.keys(queryParams).filter((key) => !!queryParams[key])
  if (keys.length === 0) return url
  return keys.reduce((a, key, i, arr) => a + `${key}=${queryParams[key]}${i === arr.length - 1 ? '' : '&'}`, `${url}?`)
}

export function getObjectOrBoolForJSON(str = ''): object | boolean {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}

export const isValidJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getChunks = <T>(array: Array<T>, chunkSize: number): Array<Array<T>> => {
  let i
  let j
  let temparray = [] as T[]
  const res = [] as T[][]
  for (i = 0, j = array.length; i < j; i += chunkSize) {
    temparray = array.slice(i, i + chunkSize)
    res.push(temparray)
  }

  return res
}

export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export const isThisWeek = (date: string) => dayjs(dayjs(date)).isSame(new Date(), 'week')

export const adjust = (color: string, amount: number) =>
  '#' +
  color
    .replace(/^#/, '')
    .replace(/../g, (color) => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
