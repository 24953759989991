import React from 'react'
import { FirebaseUser } from './types'
export enum OnboardingStep {
  NAME,
  TELL_US,
  ADD_OBJECTIVES,
  DONE,
  TERMS
}

export type OnboardingViewsType = {
  [OnboardingStep.NAME]: React.FC<OnboardingViewProps>
  [OnboardingStep.TELL_US]: React.FC<OnboardingViewProps>
  [OnboardingStep.ADD_OBJECTIVES]: React.FC<OnboardingViewProps>
  [OnboardingStep.DONE]: React.FC<OnboardingViewProps>
  [OnboardingStep.TERMS]: React.FC<OnboardingViewProps>
}

export type OnboardingState = {
  data: {
    nameOfUser: string
    name: string
    vision: string
    countryCode: string
    howManyCollegues: string
    firstObjective?: {
      name: string
      accomplishBy: string
      startDate?: string
    }
    team?: {
      name: string
      _id: string
    }
    user: FirebaseUser
  }
  firebaseUser: FirebaseUser
  step: OnboardingStep
}

export type OnboardingViewProps = {
  state: OnboardingState
  onPressContinue: (state: OnboardingState) => void
  onPressSkip: (state: OnboardingState) => void
  onClickBack: (state: OnboardingState) => void
}

export enum TimeFrameOption {
  LONG_TERM = 'LONG_TERM',
  QUARTERLY = 'QUARTERLY'
  /*     QUARTERLY = 'QUARTERLY' */
}

export enum SizeOfOrganisationOption {
  SMALL = '1-10',
  MEDIUM = '11-50',
  LARGE = '51-200',
  ENTERPRISE = '201-1000',
  LARGEENTERPRISE = '1001+',
  UNKNOWN = 'Unknown'
}
