import useAppState from '../hooks/useAppState'
import Box from './Box'
import StrategyViewObjectiveTree from './StrategyViewObjectiveTree'
import { FocusedObjectives } from './FocusedObjectives'
import { UNASSIGNED_TEAM_ID } from '../ygdrasil/libs/Consts'
import { smallSpacing } from '../enums/Spacings'
import useResponsive from '../hooks/useResponsive'
import StrategyViewObjectiveListTreeDropDown from './StrategyViewObjectiveListTreeDropDown'
import { createContext, useState } from 'react'

export const SizeContext = createContext<any>({
  size: 0,
  setSize: () => ({})
})

export default function StrategyViewObjectivesFocusedObjectivesView() {
  const { state } = useAppState()
  const { showListView } = state
  const { isTablet } = useResponsive()
  const [size, setSize] = useState(0)

  return (
    <SizeContext.Provider value={{ size, setSize }}>
      <Box fullWidth align="center">
        {state.selectedTeamId !== UNASSIGNED_TEAM_ID && <FocusedObjectives />}
        {!showListView && <StrategyViewObjectiveListTreeDropDown />}
        {isTablet && (
          <Box spacing={smallSpacing} top fullWidth>
            <StrategyViewObjectiveTree />
          </Box>
        )}
        {!isTablet && (
          <Box spacing={smallSpacing} top fullWidth>
            <StrategyViewObjectiveTree />
          </Box>
        )}
      </Box>
    </SizeContext.Provider>
  )
}
