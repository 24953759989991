import { SMALL_SPACING_PX } from '../enums/Spacings'
import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import { getActivityItemsForGraphList, getRatioLastChangeDateRatio } from '../ygdrasil/libs/ActivityHelper'
import { groupBy } from '../ygdrasil/libs/Common'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { Activity, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { GraphActivityListColumn } from './GraphActivityListColumn'
import { DEFAULT_ICON_SIZE } from './Icons'

export default function GraphActivityList({ item, parentItem }: { item: Item; parentItem: Item }) {
  const objective = parentItem.data.data as Objective
  const children = getActivityItemsForGraphList(item)

  const _children = groupBy(children, 'dueDate')
  const height =
    (Math.max(...Object.entries(_children).map(([dueDate, children]) => children.length)) * DEFAULT_ICON_SIZE) / 3 +
    SMALL_SPACING_PX

  return (
    <Box fullWidth position="relative" style={{ height }}>
      {Object.entries(_children).map(([dueDate, children], key) => {
        return (
          <Box
            key={key}
            position="absolute"
            style={{ left: `${getRatioLastChangeDateRatio(dueDate, objective) * 100}%` }}
            fullWidth
          >
            <GraphActivityListColumn items={children} />
          </Box>
        )
      })}
    </Box>
  )
}
