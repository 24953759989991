import { useRef } from 'react'
import { useClientWidthOnMount } from '../hooks/useClientWidth'
import { getConfidencesForCheckInProgress } from '../libs/CheckInProgressHelper'
import { Confidence, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { CheckInProgressItem } from './CheckInProgressItem'

export type Props = { confidences?: Confidence[]; objective: Objective; enableHead?: boolean }
export const CheckInProgress = ({ confidences = [], objective, enableHead }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const defaultWidth = useClientWidthOnMount(ref)

  confidences = getConfidencesForCheckInProgress(confidences, objective)

  return (
    <Box direction="row" align="center" fullWidth ref={ref}>
      {confidences.map((confidence, key) => (
        <CheckInProgressItem
          key={key}
          confidence={confidence}
          index={key}
          confidences={confidences}
          objective={objective}
          totalWidth={defaultWidth || 0}
          enableHead={enableHead}
        />
      ))}
    </Box>
  )
}
