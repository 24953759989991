import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { shouldShowNotifications } from '../libs/DeepLinkHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import SettingsMyOrganization from './SettingsMyOrganization'
import SettingsNotifications from './SettingsNotifications'
import SettingsPersonal from './SettingsPersonal'
import SettingsSuperAdmin from './SettingsSuperAdmin'
import Tabs from './Tabs'
import SettingsApplication from './SettingsApplication'
import SettingsIntegrations from './SettingsIntegrations'
import SettingsSubscription from './SettingsSubscription'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import useAppState from '../hooks/useAppState'
import { shouldManangeSubscription } from '../libs/shouldManangeSubscription'

export enum SettingsTabKey {
  PERSONAL,
  NOTIFICATIONS,
  APPLICATION,
  MY_ORGANIZATION,
  INTEGRATIONS,
  SUBSCRIPTION,
  SUPER_ADMIN
}

export default function SettingsTabs() {
  const config = useLoggedInConfig()
  const { state } = useAppState()
  const { data: org, isLoading } = useOrganization(state)

  if (isLoading) return null
  return (
    <Tabs
      tabs={[
        {
          tabKey: SettingsTabKey.PERSONAL,
          labelElement: (
            <FigmaText textKey={Texts.cssAdminMenuText} text={getFigmaText(Texts.adminPersonalButtonText)} />
          ),
          labelSelectedElement: (
            <FigmaText textKey={Texts.cssAdminMenuTextActive} text={getFigmaText(Texts.adminPersonalButtonText)} />
          ),
          contentElement: <SettingsPersonal />
        },
        {
          tabKey: SettingsTabKey.NOTIFICATIONS,
          labelElement: (
            <FigmaText textKey={Texts.cssAdminMenuText} text={getFigmaText(Texts.adminNotificationsButtonText)} />
          ),
          labelSelectedElement: (
            <FigmaText textKey={Texts.cssAdminMenuTextActive} text={getFigmaText(Texts.adminNotificationsButtonText)} />
          ),
          contentElement: <SettingsNotifications />,
          default: shouldShowNotifications(window.location)
        },
        {
          tabKey: SettingsTabKey.APPLICATION,
          labelElement: (
            <FigmaText textKey={Texts.cssAdminMenuText} text={getFigmaText(Texts.adminAppSettingButtonText)} />
          ),
          labelSelectedElement: (
            <FigmaText textKey={Texts.cssAdminMenuTextActive} text={getFigmaText(Texts.adminAppSettingButtonText)} />
          ),
          contentElement: <SettingsApplication />,
          enable: config.enableApplicationSettings
        },
        {
          tabKey: SettingsTabKey.MY_ORGANIZATION,
          labelElement: <FigmaText textKey={Texts.cssAdminMenuText} text={getFigmaText(Texts.adminMyOrgButtonText)} />,
          labelSelectedElement: (
            <FigmaText textKey={Texts.cssAdminMenuTextActive} text={getFigmaText(Texts.adminMyOrgButtonText)} />
          ),
          contentElement: <SettingsMyOrganization />,
          enable: config.enableMyOrganization
        },
        {
          tabKey: SettingsTabKey.INTEGRATIONS,
          labelElement: (
            <FigmaText textKey={Texts.cssAdminMenuText} text={getFigmaText(Texts.adminIntegrationsButtonText)} />
          ),
          labelSelectedElement: (
            <FigmaText textKey={Texts.cssAdminMenuTextActive} text={getFigmaText(Texts.adminIntegrationsButtonText)} />
          ),
          contentElement: <SettingsIntegrations />,
          enable: config.enableIntegrations
        },
        {
          tabKey: SettingsTabKey.SUBSCRIPTION,
          labelElement: <FigmaText textKey={Texts.cssAdminMenuText} text={getFigmaText(Texts.adminMenuSubscription)} />,
          labelSelectedElement: (
            <FigmaText textKey={Texts.cssAdminMenuTextActive} text={getFigmaText(Texts.adminMenuSubscription)} />
          ),
          contentElement: <SettingsSubscription />,
          enable: shouldManangeSubscription(org, state) && config.enableSuperAdmin
        },
        {
          tabKey: SettingsTabKey.SUPER_ADMIN,
          labelElement: <FigmaText textKey={Texts.cssAdminMenuText} text="Super Admin" />,
          labelSelectedElement: <FigmaText textKey={Texts.cssAdminMenuTextActive} text="Super Admin" />,
          contentElement: <SettingsSuperAdmin />,
          enable: config.enableSuperAdmin
        }
      ]}
    />
  )
}
