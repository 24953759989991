import React, { useState } from 'react'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useClientWidth from '../hooks/useClientWidth'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Images from '../ygdrasil/libs/Images'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective, Value } from '../ygdrasil/types/types'
import AddButton from './AddButton'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import { GraphDeprecated } from './GraphDeprecated'
import { CloseFullScreen } from './Icons'
import {
  OPEN_GRAPH_HEIGHT,
  CLOSED_GRAPH_HEIGHT,
  MAX_GRAPH_WIDTH
} from './StrategyViewObjectiveListRowDetailBodyIndicator'
import { StrategyViewObjectiveListRowIndicatorBodyForm } from './StrategyViewObjectiveListRowIndicatorBodyForm'

export function StrategyViewObjectiveListRowDetailBodyIndicatorGraph({
  item,
  parentItem,
  onClickToogleExpand,
  open
}: {
  item?: Item
  parentItem?: Item
  onClickToogleExpand?: () => void
  open: boolean
}) {
  const keyResult = item?.data.data as KeyResult
  const objective = parentItem?.data.data as Objective

  const [activeValue, setActiveValue] = useState<Value>()

  const ref = React.useRef<HTMLDivElement>(null)

  const clientWidth = useClientWidth(ref) || MAX_GRAPH_WIDTH
  const graphWidth = Math.min(clientWidth, MAX_GRAPH_WIDTH)

  return (
    <Box fullWidth position="relative">
      {!open && (
        <Box fullWidth align="flex-end">
          <Box />
          <Button onClick={onClickToogleExpand} buttonType="secondary" spacing={tinySpacing}>
            <FigmaImageContainer imageKey={Images.iconExpand} />
          </Button>
        </Box>
      )}
      {item && parentItem && (
        <Box top spacing={smallSpacing} fullWidth>
          <Box spacing={bigSpacing} right fullWidth ref={ref}>
            <GraphDeprecated
              item={item}
              parentItem={parentItem}
              activeValue={activeValue}
              width={open ? undefined : graphWidth}
              height={open ? OPEN_GRAPH_HEIGHT : CLOSED_GRAPH_HEIGHT}
              enableActivityList={open}
            />
            {!open && (
              <Box spacing={bigSpacing} left>
                <StrategyViewObjectiveListRowIndicatorBodyForm
                  objective={objective}
                  keyResult={keyResult}
                  item={item}
                  setActiveValue={setActiveValue}
                  enableLabel={false}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
