import dayjs from 'dayjs'
import { AllItems } from '../hooks/useAllItems'
import { Activity, KeyResult, Objective, Unit, Value } from '../types/types'
import * as Common from './Common'
import { DASHBOARD_RATE_OF_CHANGE_DAYS } from './Consts'

export const ASCENDING = 1
export const DESCENDING = -1

export const getTodaysValueDeprecated = (kr: KeyResult) => {
  const { values = [] } = kr
  const k = (kr.goalValue - kr.startValue) / values.length
  const m = kr.startValue
  const t0 = dayjs(values[0]?.date).valueOf()
  const t1 = dayjs(values[values.length - 1]?.date).valueOf()
  const t = dayjs().valueOf()
  const x = (t - t0) / (t1 - t0)
  return k * x + m
}

export const getChange = (kr: KeyResult) => {
  const { values = [] } = kr
  const first = values.filter(({ date }) =>
    dayjs(date).isBefore(dayjs().add(-DASHBOARD_RATE_OF_CHANGE_DAYS, 'days'))
  )[0]?.value

  if (!first) return 0
  const last = getTodaysValueDeprecated(kr)
  if (!last) return 0
  return ((last - first) / first) * 100
}

export const inChonologicalOrder = (a: Value, b: Value) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()

export const getProgress = (kr: KeyResult) => Math.min(kr.goalValue / getTodaysValueDeprecated(kr), 1) * 100

export const getCurrentRatio = ({ values = [], startValue, goalValue }: KeyResult): number => {
  values = values.filter(({ value }) => typeof value === 'number')
  const value = values[values.length - 1]
  const valueSpan = goalValue - startValue //Calculate value span (goalValue - startValue). Descending will be negative.
  const currentValue = value ? value.value : startValue
  if (isNaN(currentValue)) return !isNaN(startValue) ? startValue : 0
  const changeFromStart = currentValue - startValue //Calculate diff between today and start value. Descending will be negative.
  let ratio = changeFromStart === 0 ? 0 : (changeFromStart / valueSpan) * 100 //handle division by zero and both accending and descending cases. Descending will be division with two negative -> positive
  if (isNaN(ratio))
    console.warn('getCurrentRatio is NaN', 'startValue', startValue, 'goalValue', goalValue, 'values', values)

  if (ratio > 100) ratio = 100
  if (ratio < 0) ratio = 0

  return ratio
}

export const getCurrentRatioForValue = (value: number, { startValue, goalValue }: KeyResult): number => {
  const valueSpan = goalValue - startValue //Calculate value span (goalValue - startValue). Descending will be negative.
  const changeFromStart = value - startValue //Calculate diff between today and start value. Descending will be negative.
  const ratio = changeFromStart === 0 ? 0 : changeFromStart / valueSpan //handle division by zero and both accending and descending cases. Descending will be division with two negative -> positive
  return ratio
}

export const getValue = ({ values = [], startValue }: KeyResult): number => {
  const value = values[values.length - 1]
  if (typeof value?.value !== 'undefined') return value.value
  return startValue
}

export const getDecimals = (kr: KeyResult) =>
  Math.max(Common.getDecimals(kr.startValue), Common.getDecimals(kr.goalValue)) || 0

export const getDirection = ({ startValue, goalValue }: KeyResult) => (goalValue >= startValue ? ASCENDING : DESCENDING)

export const getUnitText = ({ unit, currency }: KeyResult) => {
  switch (unit) {
    case Unit.MONEY:
      return currency
    case Unit.PERCENT:
      return '%'
    default:
      return ''
  }
}

export const isRetainIndicator = ({ startValue, goalValue }: KeyResult) => startValue === goalValue

export const findKeyResultAndObjectiveForActivity = (activity: Activity, { allIndicators, allObjectives }: AllItems) =>
  allObjectives.reduce((a, objective) => {
    if (a) return a
    const keyResult = allIndicators.find((_kr) => _kr._id === activity.parentId)
    if (!keyResult) return a
    return { keyResult, objective }
  }, undefined as { keyResult: KeyResult; objective: Objective } | undefined)

export function getProgressText(ratio: number) {
  const res = (ratio / 100).toFixed(2)
  if (res.endsWith('.00')) return res.substring(0, res.length - 3)
  if (res.endsWith('0')) return res.substring(0, res.length - 1)
  return res
}
