import { useState } from 'react'
import { deleteAccount } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { useUsersAsSuperAdmin } from '../ygdrasil/libs/QueryHooks'
import useAppState from './useAppState'
import useNotification from './useNotification'

/* eslint-disable no-restricted-globals */
export default function useDeleteUserAsSuperAdmin() {
  const { state } = useAppState()
  const { data: users = [] } = useUsersAsSuperAdmin()
  const notification = useNotification()
  const [isLoading, setIsLoading] = useState(false)

  const deleteUser = (userId: string) =>
    confirm(`Are you sure you want to delete user with email: ${users.find((u) => u._id === userId)?.email || ''}?`) &&
    Promise.resolve(setIsLoading(true))
      .then(() => deleteAccount(userId, state))
      .then(() => notification.success())
      .catch((e) => captureAndNotifyError(e))
      .finally(() => setIsLoading(false))

  return { isLoading, deleteUser }
}
