import styled from '@emotion/styled'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { minSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { CloseIcon } from './Icons'

type Props = { text: string; textKey: AntiloopTextType; handleDelete: () => unknown }
export default function Chip({ text, textKey, handleDelete }: Props) {
  return (
    <Container direction="row" align="center" link>
      <TextContainer right spacing={tinySpacing}>
        <FigmaText textKey={textKey} text={text} />
      </TextContainer>
      <Box onClick={handleDelete}>
        <CloseIcon color={Colors.white} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background: ${Colors.primaryBase};
  border-radius: ${tinyBorderRadius};
  padding: ${minSpacing} ${tinySpacing};
`

const TextContainer = styled(Box)`
  margin-top: -2px; /* UGLY AS HELL, dont reuse */
`
