import useAppState from '../hooks/useAppState'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import { logEvent } from '../ygdrasil/libs/FirebaseHelper'
import Texts from '../ygdrasil/libs/Texts'
import AddButton from './AddButton'

export const AddButtonObjective = () => {
  const { state } = useAppState()
  const onClickAddObjective = () => {
    logEvent(AnalyticsEvent.DASHBOARD_ADDOBJECTIVE, state)

    eventEmitter.emit(Events.OPEN_EDIT_OBJETIVE_DIALOG, {
      objective: undefined
    })
  }
  const textKey = !!state.selectedTeamId ? Texts.addTeamObjectiveButtonText : Texts.genericAddObjectiveButtonText
  return <AddButton labelTextKey={textKey} onClick={onClickAddObjective} />
}
