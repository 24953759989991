import React, { useState } from 'react'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { getLeadNotificationIntervalType } from '../ygdrasil/libs/SettingsNotificationsHelper'
import SettingsNotificationsRowMenu from './SettingsNotificationsRowMenu'
import { NoWrapFigmaText } from './StyledComponents'
import { smallSpacing } from '../enums/Spacings'

export const SettingsNotificationRowInterval = ({
  object,
  onSelectInterval
}: {
  object: Objective | KeyResult
  onSelectInterval: (LeadNotificationInterval) => unknown
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const { textKey, imageKey } = getLeadNotificationIntervalType(object)
  return (
    <Box>
      <SettingsNotificationsRowMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        onSelect={onSelectInterval}
      />
      <Box direction="row" align="center" right fullWidth link onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Box right align="center" justify="center" spacing={smallSpacing}>
          <FigmaImageContainer imageKey={imageKey} />
        </Box>
        <Box minWidth="auto">
          <NoWrapFigmaText textKey={textKey} />
        </Box>
      </Box>
    </Box>
  )
}
