import { BoxProps } from '@mui/material'
import React from 'react'
import useAppState from '../hooks/useAppState'
import { getStateForMyActivities } from '../libs/MenuHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { MenuActivityBoardIcon } from './Icons'
import { MenuButton } from './MenuButton'

const MenuButtonMyActivities: React.FC<BoxProps> = function () {
  const { state, setState } = useAppState()
  const { showMyActivities } = state

  const onClick = () => setState(getStateForMyActivities(state))

  return (
    <Box id="MyActivities" fullWidth>
      <MenuButton
        onClick={onClick}
        isSelected={showMyActivities}
        textKey={Texts.applicationMenuMyFocusButtonText}
        Icon={MenuActivityBoardIcon}
      />
    </Box>
  )
}

export default MenuButtonMyActivities
