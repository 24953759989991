import styled from '@emotion/styled'
import { ClickAwayListener, Popper } from '@mui/material'
import invariant from 'invariant'
import React, { useEffect, useRef } from 'react'
import Shadows from '../enums/Shadows'
import { smallSpacing, SMALL_SPACING_PX } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useEvents } from '../ygdrasil/hooks/useEvents'
import EventCategories from '../ygdrasil/enums/EventCategories'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import Colors from '../ygdrasil/libs/Colors'
import { updateUser } from '../ygdrasil/libs/DBApiHandler'
import Images from '../ygdrasil/libs/Images'
import { useUser } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import CelebrationView from './CelebrationView'
import FigmaImageContainer from './FigmaImageContainer'
import Tooltip from './Tooltip'
import dayjs from 'dayjs'

export default function Celebration() {
  const ref = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { state, setState } = useAppState()
  const { data: user } = useUser(state.user._id)
  const logEvent = useLogEvent()

  const onClickCelebration = () => {
    setAnchorEl(ref.current)
    invariant(user, 'user is null')
    const _user = { ...user, lastSeenNotificationsAt: dayjs().format() }
    setState({ ...state, user: _user })
    updateUser(_user)
    logEvent(EventCategories.UserOpensCelebrationDialog)
  }

  const onClose = () => setAnchorEl(null)
  const { hasUnseenNotifications } = useEvents()

  useEffect(() => {
    if (!state.showCelebrationView) return
    if (!user) return

    onClickCelebration()
    setState({ ...state, showCelebrationView: false })
  }, [user])

  return (
    <>
      <Tooltip title={getFigmaText(Texts.rightPanelCelebrateDialog)}>
        <Box onClick={onClickCelebration} pointer position="relative" ref={ref}>
          <FigmaImageContainer imageKey={Images.celebrationIcon} />
          {hasUnseenNotifications && <RedDot position="absolute" />}
        </Box>
      </Tooltip>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={onClose}>
          <Box fullWidth top>
            <CelebrationView style={CelebratioionContainerStyle} onClose={onClose} />
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const CelebratioionContainerStyle = { height: '92vh', boxShadow: Shadows.heavy }

const RedDot = styled(Box)`
  width: ${smallSpacing};
  height: ${smallSpacing};
  border-radius: 50%;
  bottom: ${-SMALL_SPACING_PX / 2}px;
  right: 0;
  background-color: ${Colors.bad};
`
