import { connectStorageEmulator } from 'firebase/storage'
import { useEffect } from 'react'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { updateOrganization } from '../ygdrasil/libs/DBApiHandler'
import { DEFAULT_BUSINESSPLAN } from '../ygdrasil/libs/Consts'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'

import Box from './Box'
import BusinessplanRing from './BusinessplanRing'
import { BusinessPlanSwot } from './BusinessPlanSwot'
import { BusinessPlanVision } from './BusinessPlanVision'
import Button from './Button'

export function BusinessPlan() {
  const { state } = useAppState()
  const { data: org, isLoading } = useOrganization(state)
  const config = useLoggedInConfig()

  useEffect(() => {
    if (!org) return
    if (!!org?.businessPlan) return
    if (isLoading) return
    if (!config.enableDefaultBusinessPlan) return
    updateOrganization({ ...org, businessPlan: DEFAULT_BUSINESSPLAN })
  }, [org])

  if (!org?.businessPlan) return null // TODO, WRITE TEST, should not render business if business plan does not exist

  return (
    <Box fullWidth direction="row" fullHeight>
      <Box fullPadding fullWidth fullHeight>
        <BusinessplanRing />
      </Box>
      <Box fullPadding fullWidth>
        <Box bottom spacing={bigSpacing}>
          <BusinessPlanVision />
        </Box>
        <BusinessPlanSwot />
      </Box>
    </Box>
  )
}
