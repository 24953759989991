import { useEffect } from 'react'
import { StorageKey, StorageType } from '../libs/useLocalStorageItemHelper'
import { useStorageItem } from './useStorageItem'
import { State } from './useAppState'
import useQueryParams, { UseSessionStateQueryParams } from '../ygdrasil/hooks/useQueryParams'
import { getStateForDashboard, getStateForMyActivities } from '../libs/MenuHelper'

export const useSessionState = (state: State) => {
  const { shouldShowExpandedMenu } = state
  const queryParams = useQueryParams<UseSessionStateQueryParams>()

  const sessionState = getSessionState(queryParams, state)

  const { storageItem: session, setStorageItem: setSession } = useStorageItem(
    StorageKey.SESSION,
    { ...sessionState, shouldShowExpandedMenu } as Partial<State>,
    {
      storageType: StorageType.SESSION_STORAGE
    }
  )

  useEffect(() => {
    setSession({ shouldShowExpandedMenu })
  }, [shouldShowExpandedMenu])

  return session
}

export const getSessionState = (queryParams: UseSessionStateQueryParams, state: State): Partial<State> => {
  const { shouldShowExpandedMenu } = state
  let sessionState: Partial<State> = {}
  if (queryParams.showDashboard) sessionState = getStateForDashboard(state)
  if (queryParams.showMyActivities) sessionState = getStateForMyActivities(state)
  if (queryParams.selectedDashboardLinkId) sessionState.selectedDashboardLinkId = queryParams.selectedDashboardLinkId
  if (queryParams.showDashboardCelebrations)
    sessionState.showDashboardCelebrations = queryParams.showDashboardCelebrations !== 'false'
  return { ...sessionState, shouldShowExpandedMenu }
}
