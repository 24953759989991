import { useEffect, useState } from 'react'

export default function useClientWidth(ref) {
  const [size, setSize] = useState<number>()

  useEffect(() => {
    if (!ref?.current) return
    const size = ref.current.clientWidth
    setSize(size)
  }, [ref?.current?.clientWidth])

  return size
}

export function useClientWidthOnMount(ref) {
  const [size, setSize] = useState<number>()
  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTrigger(true)
    })
  }, [])

  useEffect(() => {
    if (!ref?.current) return
    const size = ref.current.clientWidth
    setSize(size)
  }, [trigger])

  return size
}
