import styled from '@emotion/styled'
import React from 'react'
import { minBorderRadius } from '../enums/BorderRadixes'
import { defaultSpacing, minSpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import FigmaTextLoader from './FigmaTextLoader'
type Props = { className?: string; spacing?: string; textKey?: AntiloopTextType; loading?: boolean }

const ButtonEmptyState: React.FC<Props> = (props) => {
  const { children, className, textKey, loading, ...buttonProps } = props

  return (
    <StyledButton className={className} {...buttonProps} pointer alignText="left">
      {children || (
        <FigmaTextLoader
          textKey={Texts.cssGenericDialogButtonSecondaryButtonText}
          text={textKey ? getFigmaText(textKey) : undefined}
          loading={loading}
        />
      )}
    </StyledButton>
  )
}

const StyledButton = styled(Box)<{ spacing?: string }>`
  background: ${Colors.primary100};
  padding-bottom: ${minSpacing};
  padding-top: ${minSpacing};
  padding-left: ${({ spacing }) => spacing || defaultSpacing};
  padding-right: ${({ spacing }) => spacing || defaultSpacing};

  border: 1px dashed ${Colors.primaryBase};
  border-radius: ${minBorderRadius};
  :hover {
    background-color: ${Colors.primary95};
  }
`

export default ButtonEmptyState
