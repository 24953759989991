import { Value } from '../types/types'
import { DATE, VALUE, YYYY_MM_DD } from './Consts'
import { DayJS } from './DayJsHelper'
import type { Printable } from './TextRepository'

export function isValidData(rows?: Printable[][]) {
  if (!rows) return false
  if (rows[0]?.[0] !== DATE) return false
  if (rows[0]?.[1] !== VALUE) return false

  const hasCorrectValues = rows.every((row, i) => i === 0 || typeof row[1] === 'number')
  if (!hasCorrectValues) return false

  return true
}

export const getValuesForRows = (rows: Printable[][] = []) =>
  rows
    .map((r) => ({ date: r[0], value: r[1] }))
    .reduce((a, { date: _date, value }) => {
      if (typeof value !== 'number') return a
      const date = getDate(_date)
      if (!date) return a
      a.push({ date, value } as Value)
      return a
    }, [] as Value[])

const getDate = (date: Printable): string | undefined => {
  if (typeof date === 'number') return getDateFormExcelDateSerial(date)
  const mom = DayJS(date, YYYY_MM_DD, true)
  if (mom.isValid()) return mom.format(YYYY_MM_DD)
  return undefined
}

const getDateFormExcelDateSerial = (serial) => {
  const utc_days = Math.floor(serial - 25569)
  const utc_value = utc_days * 86400
  const date_info = new Date(utc_value * 1000)

  const fractional_day = serial - Math.floor(serial) + 0.0000001

  let total_seconds = Math.floor(86400 * fractional_day)

  const seconds = total_seconds % 60

  total_seconds -= seconds

  const hours = Math.floor(total_seconds / (60 * 60))
  const minutes = Math.floor(total_seconds / 60) % 60

  return DayJS(
    new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds)
  ).format(YYYY_MM_DD)
}
