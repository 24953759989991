import styled from '@emotion/styled'
import { ClickAwayListener, Popper } from '@mui/material'
import React, { useRef } from 'react'
import Shadows from '../enums/Shadows'
import { SMALL_SPACING_PX, bigSpacing, smallSpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { PageHeaderButtonMyTodosIcon } from './Icons'
import { MyTodos } from './MyTodos'
import Tooltip from './Tooltip'

export default function PageHeaderButtonsMyTodos() {
  const ref = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const onClickMyTodos = () => {
    setAnchorEl(ref.current)
  }

  const onClose = () => setAnchorEl(null)

  return (
    <>
      <Tooltip title={getFigmaText(Texts.userMenuMyTodos)}>
        <Box onClick={onClickMyTodos} pointer ref={ref}>
          <PageHeaderButtonMyTodosIcon />
        </Box>
      </Tooltip>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={onClose}>
          <Box fullWidth top>
            <Box style={ContainerStyle}>
              <MyTodos onClose={onClose} />
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const ContainerStyle = { height: '92vh', boxShadow: Shadows.heavy, backgroundColor: Colors.whiteBg }

const size = smallSpacing
const RedDot = styled(Box)`
  width: ${size};
  height: ${size};
  border-radius: 50%;
  bottom: ${-SMALL_SPACING_PX / 2}px;
  right: 0;
  background-color: ${Colors.bad};
`
