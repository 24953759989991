import React, { CSSProperties, useRef, useState } from 'react'

import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'
import './styles.css'

const aspect = 1
const CROP_CIRCE_WIDTH = 300

const MIN_SIZE = 200
export function ReactImageCrop({
  imgSrc,
  canvasRef,
  onCompletedCrop,
  onLoadCrop
}: {
  imgSrc: string
  canvasRef: React.RefObject<HTMLCanvasElement>
  onCompletedCrop?: (crop: PixelCrop) => void
  onLoadCrop
}) {
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [imgStyle, setImgStyle] = useState<CSSProperties>()

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const file = e.currentTarget
      const { width: originalWidth, height: originalHeight } = file
      const maxWidth = window.innerWidth * 0.5
      const maxHeight = window.innerHeight * 0.5
      const { width: newWidth, height: newHeight } = getNewImageDimensions({
        originalHeight,
        originalWidth,
        minHeight: MIN_SIZE,
        minWidth: MIN_SIZE,
        maxWidth,
        maxHeight
      })

      const crop = centerAspectCrop(newWidth, newHeight, aspect)
      setCrop(crop)
      setImgStyle({ width: newWidth, height: newHeight })
      onLoadCrop(file)
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && canvasRef.current) {
        canvasPreview(imgRef.current, canvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop]
  )

  return (
    <ReactCrop
      crop={crop}
      onChange={(_, percentCrop) => setCrop(percentCrop)}
      onComplete={(c) => {
        setCompletedCrop(c)
        onCompletedCrop?.(c)
      }}
      aspect={aspect}
      circularCrop
    >
      <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} style={imgStyle} />
    </ReactCrop>
  )
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: CROP_CIRCE_WIDTH
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

// TODO WRITE TEST
function getNewImageDimensions({
  originalWidth,
  originalHeight,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight
}: {
  originalWidth
  originalHeight
  minWidth
  maxWidth
  minHeight
  maxHeight
}) {
  // Calculate the aspect ratio of the original image
  const aspectRatio = originalWidth / originalHeight

  // Limit the height and width within the min and max ranges
  let newHeight
  let newWidth
  if (originalHeight < minHeight) {
    newHeight = minHeight
  } else if (originalHeight > maxHeight) {
    newHeight = maxHeight
  } else {
    newHeight = originalHeight
  }
  if (originalWidth < minWidth) {
    newWidth = minWidth
  } else if (originalWidth > maxWidth) {
    newWidth = maxWidth
  } else {
    newWidth = originalWidth
  }

  // Adjust the height or width based on aspect ratio
  if (newHeight * aspectRatio > newWidth) {
    newHeight = newWidth / aspectRatio
  } else {
    newWidth = newHeight * aspectRatio
  }

  // Return the new dimensions as an object
  return {
    width: Math.round(newWidth),
    height: Math.round(newHeight)
  }
}
