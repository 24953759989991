import TableCell from '@mui/material/TableCell'
import { useState } from 'react'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import ConnectionType from '../ygdrasil/enums/ConnectionType'
import { syncDataForDatasource } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { Connection, Datasource } from '../ygdrasil/types/types'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import Loader from './Loader'
import { MoreActionVert } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import { StyledTableRow } from './SettingsIntegrationsConnectionsTable'
import SettingsIntegrationsDatasourceMenu from './SettingsIntegrationsDatasourceMenu'
import { SettingsIntegrationsDatasourceTableRowLastSyncedAt } from './SettingsIntegrationsDatasourceTableRowLastSyncedAt'
import { canUseConnection } from '../libs/SettingsIntegrationsHelper'
import { TableBox } from './TableBox'

const A_THIRD = 0.35
export const SettingsIntegrationsDatasourceTableRow = ({
  dataSource: ds,
  connection
}: {
  dataSource: Datasource
  connection: Connection
}) => {
  const { state } = useAppState()
  const onSyncDataSource = (): unknown =>
    Promise.resolve(setIsSyncing(true))
      .then(() => syncDataForDatasource(ds._id, state))
      .catch(captureAndNotifyError)
      .finally(() => setIsSyncing(false))

  const [isSyncing, setIsSyncing] = useState(false)

  const shouldShowMenu = canUseConnection(connection, state)

  let imageKey = connection.connectionType === ConnectionType.EXCEL_ONLINE ? Images.microsoftExcel : Images.googleSheets
  imageKey = { ...imageKey, height: imageKey.height * A_THIRD, width: imageKey.width * A_THIRD }

  return (
    <StyledTableRow>
      <TableCell>
        <TableBox left>
          <Box direction="row" align="center" left spacing={bigSpacing}>
            <FigmaImageContainer imageKey={imageKey} />
            <Box left spacing={smallSpacing}>
              <FigmaText textKey={Texts.office365Connection} text={ds.name} />
            </Box>
          </Box>
        </TableBox>
      </TableCell>
      <TableCell>
        {isSyncing && <Loader size={smallSpacing} />}
        {!isSyncing && !!ds.lastSyncedAt && <SettingsIntegrationsDatasourceTableRowLastSyncedAt ds={ds} />}
      </TableCell>
      <TableCell>
        {shouldShowMenu && (
          <MoreActionWithMenu
            visible
            Menu={(props) => (
              <SettingsIntegrationsDatasourceMenu
                {...props}
                connection={connection}
                dataSource={ds}
                onSyncDataSource={onSyncDataSource}
                onDataSourceUpdated={onSyncDataSource}
              />
            )}
            MoreAction={MoreActionVert}
            isAlwaysVisible
          />
        )}
      </TableCell>
    </StyledTableRow>
  )
}
