import invariant from 'invariant'
import { SMALL_SPACING_PX } from '../enums/Spacings'
import { DayJS } from '../ygdrasil/libs/DayJsHelper'
import { Confidence, Objective } from '../ygdrasil/types/types'

export const MIN_WIDTH = SMALL_SPACING_PX * 0.5
export const getWidth = (
  index: number,
  { confidences, objective }: { confidences: Confidence[]; objective: Objective },
  totalWidth
) => {
  const endDate = DayJS.max(DayJS(objective.endDate), ...confidences.map(({ date }) => DayJS(date)))
  const startDate = DayJS.min(DayJS(objective.startDate), ...confidences.map(({ date }) => DayJS(date)))
  const interval = DayJS(endDate).valueOf() - DayJS(startDate).valueOf()

  const currentConfidence = confidences.find((c, i) => index === i)
  invariant(currentConfidence, '!currentConfidence %s', index)
  const nextConfidence = confidences.find((c, i) => index + 1 === i)

  if (!nextConfidence) return 0

  const x = DayJS(nextConfidence.date).valueOf() - DayJS(currentConfidence.date).valueOf()

  return Math.max((x / interval) * totalWidth, MIN_WIDTH)
}
