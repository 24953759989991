/* eslint-disable prettier/prettier */
// @flow
import { collection } from '@firebase/firestore'
import { State } from '../../hooks/useAppState'
import { db } from '../../libs/Firebase'
import { ServerFirebaseUser } from '../types/ServerTypes'
import { BaseObject, User } from '../types/types'
import Collections from './Collections'
import { MAX_QUERYABLE_ARRAY_LENGTH } from './Consts'
import * as FirebaseStorageClientBase from './FirebaseStorageClientBase'
import * as mapSnapshot from "./FirebaseStorageClientBaseHelper"

const toServerUser = ({user}: State) => ({uid: user._id}) as ServerFirebaseUser
const toQueryableArray = arr => arr.filter((x,i) => i < MAX_QUERYABLE_ARRAY_LENGTH)

export const createObject = <T>(object: T, collection: string, state: State): Promise<T> => FirebaseStorageClientBase.createObject({ ...mapSnapshot.toBaseObject(toServerUser(state)), ...object }, toServerUser(state), collection, db)
export const addObject = <T>(object: T, collection: string): Promise<T> => FirebaseStorageClientBase.addObject(object, collection, db)
export const updateObject = <T extends BaseObject>(object: T, collection: string): Promise<T> => FirebaseStorageClientBase.updateObject(object, collection, db)
export const removeObject = <T extends BaseObject>(object: T, collection: string): Promise<void> => FirebaseStorageClientBase.removeObject(object._id, collection, db)
  
export const getObject = (_id: string, collection: string) => FirebaseStorageClientBase.getObject(_id, collection, db)

export const getAllObjectsForSelectedOrganization = ({selectedOrganizationId}: State, collection: Collections) =>  FirebaseStorageClientBase.getAllObjectsWithOrganizationId(selectedOrganizationId, collection, db)

export const getAllObjectsForSelectedOrganizationQuery = ({selectedOrganizationId}: State, collection: Collections, key?: string) =>  FirebaseStorageClientBase.getAllObjectsWithOrganizationIdQuery(selectedOrganizationId, collection, db, key)

export const getObjectQuery = (_id: string, collection: Collections) => FirebaseStorageClientBase.getObjectQuery(db, collection, _id)

export const getAllUsersForSelectedOrganizationQuery = ({selectedOrganizationId}: State) => FirebaseStorageClientBase.getAllUsersForUserQuery(db, [selectedOrganizationId])

export const getObjectsQuery = (_collection: string) => collection(db, _collection)

export const getAllOrgsQueryForUser = (user?: User) => FirebaseStorageClientBase.getAllOrgsQuery(db, toQueryableArray(user?.organizationIds || []))

export const getAllOrgsQueryForOrgIds = (orgIds: string[] = []) => FirebaseStorageClientBase.getAllOrgsQuery(db, toQueryableArray(orgIds))

export const getAllOrgsForDomainQuery = (domain: string) => FirebaseStorageClientBase.getAllOrgsForDomainQuery(db, domain)