import { slack } from '../../libs/SlackHelper'
import type { User as SentryUser } from '../../Sentry'
import { addQueryParamsToUrl, UpdateValueInIndicatorQueryParams } from '../hooks/useQueryParams'
import { KeyResult, Organization, SlackChannels, User } from '../types/types'
import { prod } from '../../Config'
import { OurEvent, UserAddIndicatorValueParams } from './EventHelper'
import { getTodaysValue } from './GraphHelper'

export const error = (error: Error, user?: SentryUser) =>
  slack(`user: ${user?.email || ''}\n${error.name} ${error.message} ${error.stack}`, SlackChannels.ERRORS)

export const logLogin = (user: User) => slack(`New Login: ${user.email}`, SlackChannels.LOGINS)

export const logActiveUser = (keyResult: KeyResult, org?: Organization, user?: User) =>
  slack(`user: ${user?.email} updated ${keyResult.name} (${org?.name})`, SlackChannels.OPS_ACTIVE_USERS)

export const logActiveOurBigPictureUser = (
  { params, _id: eventId }: OurEvent<UserAddIndicatorValueParams>,
  { name }: User
) =>
  slack(
    `${name} har uppdaterat ${params.indicator.name} till ${getTodaysValue(params.indicator).value} (${
      params.prevValue
    }). 🎆 <${addQueryParamsToUrl<UpdateValueInIndicatorQueryParams>(`${prod.appUrl}/`, {
      showCelebrationView: true,
      celebratedEventId: eventId
    })}|Click to Celebrate!>`,
    SlackChannels.WINNING_TOGETHER
  )
