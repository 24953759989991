import React from 'react'
import { Team } from '../ygdrasil/types/types'
import Modal, { ModalProps } from './Modal'
import ModalCreateEditTeamForm from './ModalCreateEditTeamForm'

type Props = {
  team?: Team
  onClickDeleteTeam?: () => unknown
  onCreatedTeam?: (team: Team) => unknown
} & ModalProps
export function ModalCreateEditTeam(props: Props) {
  const { team, onClickDeleteTeam, onCreatedTeam, ...modalProps } = props
  return (
    <Modal {...modalProps}>
      <ModalCreateEditTeamForm
        onClose={props.onClose}
        team={team}
        onClickDeleteTeam={onClickDeleteTeam}
        onCreatedTeam={onCreatedTeam}
      />
    </Modal>
  )
}
