import { getAllChildObjectives, getAllObjectivesForObjective, getParent } from '../ygdrasil/libs/ObjectiveHelper'
import { CreateOrganizationRequest, FirebaseUser, Objective, User } from '../ygdrasil/types/types'
import { State } from '../hooks/useAppState'
import * as CloudFunctionsApiHandler from '../ygdrasil/libs/CloudFunctionsApiHandler'
import * as DBApiHandler from '../ygdrasil/libs/DBApiHandler'
import { getDomain, getEmailForFirebaseUser } from '../ygdrasil/libs/Common'
import invariant from 'invariant'
import { COMMON_EMAIL_DOMAINS } from '../ygdrasil/libs/Consts'
import { getExistingJoinableOrganizations, shouldCreateNewOrganization } from '../ygdrasil/libs/Helper'
import { ServerFirebaseUser } from '../ygdrasil/types/ServerTypes'
import { toUser } from '../ygdrasil/libs/Mapper'

export const archiveObjective = (o: Objective, allObjectives: Objective[]) =>
  Promise.all(
    getAllObjectivesForObjective(o, allObjectives).map((o) => DBApiHandler.updateObjective({ ...o, isArchived: true }))
  )

export const unArchiveObjective = (o: Objective, allObjectives: Objective[]) =>
  Promise.all(getUnarchivedObjectives(o, allObjectives).map((o) => DBApiHandler.updateObjective(o)))

export const getUnarchivedObjectives = (o: Objective, allObjectives: Objective[]) => {
  const hasArchivedParent = getParent(o, allObjectives)?.isArchived
  return [
    {
      ...o,
      isArchived: false,
      parentObjectiveId: hasArchivedParent ? undefined : o.parentObjectiveId
    },
    ...getAllChildObjectives(o, allObjectives).map((o) => ({ ...o, isArchived: false }))
  ]
}

export const createUser = (req: CreateOrganizationRequest, firebaseUser: FirebaseUser, state: State): Promise<User> => {
  const email = getEmailForFirebaseUser(firebaseUser)
  invariant(email, `Cant find email on user with id: %s`, firebaseUser.uid)
  const { uid } = firebaseUser
  const serverFirebaseUser = firebaseUser as unknown as ServerFirebaseUser

  return CloudFunctionsApiHandler.getOrganizationsForNonOnboardedFirebaseUser({ uid }, state).then((orgs) => {
    return Promise.resolve()
      .then(() => {
        const _domain = getDomain(email)
        const isCommonEmailDomain = COMMON_EMAIL_DOMAINS.includes(_domain)
        const domain = isCommonEmailDomain ? '' : _domain
        req.domains = [domain]
        req.name = req.name || domain

        if (shouldCreateNewOrganization(email, orgs))
          return DBApiHandler.createOrganization(req, state).then((o) => [o])

        const existingJoinablerganizations = getExistingJoinableOrganizations({
          email,
          orgs,
          firebaseUser: serverFirebaseUser
        })
        invariant(existingJoinablerganizations.length > 0, `Cant find organization to join uid: ${firebaseUser.uid}`)
        return existingJoinablerganizations
      })
      .then((orgs) => {
        const user = toUser(firebaseUser, {
          organizationIds: orgs.map(({ _id }) => _id),
          name: req.nameOfUser
        })
        invariant(user.email, `Cant find email on user with id: %s`, firebaseUser.uid)
        invariant(user._id === firebaseUser.uid, `User id is not same as firebaseUser id`, firebaseUser.uid)
        return DBApiHandler.createUser(user, state)
      })
  })
}
