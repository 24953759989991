import React from 'react'
import { tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Box from './Box'
import TextFieldFormik, { TextFieldFormikProps } from './TextFieldFormik'
import { TextFieldOutlinedNumberInputFormik } from './TextFieldOutlinedNumberInput'

export const TextFieldWithLabelFormik: React.FC<TextFieldFormikProps> = (props) => {
  const { labelTextKey, fullWidth, labelText, ...textFieldProps } = props
  return (
    <Box fullWidth={fullWidth}>
      {labelTextKey && <FigmaText textKey={labelTextKey} text={labelText} />}
      <Box top spacing={tinySpacing} fullWidth>
        <TextFieldFormik {...textFieldProps} fullWidth />
      </Box>
    </Box>
  )
}

export const TextFieldNumberInputWithLabelFormik: React.FC<TextFieldFormikProps> = (props) => {
  const { labelTextKey, fullWidth, labelText, ...textFieldProps } = props
  return (
    <Box fullWidth={fullWidth}>
      {labelTextKey && <FigmaText textKey={labelTextKey} text={labelText} />}
      <Box top spacing={tinySpacing} fullWidth>
        <TextFieldOutlinedNumberInputFormik {...textFieldProps} fullWidth />
      </Box>
    </Box>
  )
}
