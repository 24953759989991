import Box from './Box'
import React from 'react'
import styled from '@emotion/styled'
import PublicAssets from '../ygdrasil/enums/PublicAssets'
import { smallSpacing } from '../enums/Spacings'
import Texts from '../ygdrasil/libs/Texts'
import BackButtonMobileOnboarding from './BackButtonMobileOnboarding'

type Props = { content?: React.FC; onClickBack?: () => unknown }
const OnboardingMobileWrapper: React.FC<Props> = ({ content: Content, onClickBack, children: _children }) => {
  const children = Content ? React.createElement(Content) : _children
  return (
    <Container fullWidth>
      {!!onClickBack && (
        <Box fullWidth align="flex-start" justify="center" left spacing={smallSpacing} top>
          <BackButtonMobileOnboarding textKey={Texts.onboardingMobileBackButtonText} onClickBack={onClickBack} />
        </Box>
      )}
      {children}
    </Container>
  )
}

const Container = styled(Box)`
  background: url(${PublicAssets.MOBILE_BACKGROUND}) no-repeat center top fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
`
export default OnboardingMobileWrapper
