import { styled } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { useConnections } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { SettingsIntegrationsConnectionsTableRow } from './SettingsIntegrationsConnectionsTableRow'

export default function SettingsIntegrationsConnectionsTable() {
  const { state } = useAppState()
  const { data: connections = [] } = useConnections(state)
  return (
    <Table>
      <TableHead>
        <HeaderTableRow>
          <TableCell width="72%">
            <FigmaText textKey={Texts.adminIntegrationTableNameCellHeader} />
          </TableCell>
          <TableCell width="25%">
            <FigmaText textKey={Texts.adminIntegrationTableSyncCellHeader} />
          </TableCell>
          <TableCell width="3%" align="center"></TableCell>
        </HeaderTableRow>
      </TableHead>
      <TableBody>
        {connections.map((c, key) => (
          <SettingsIntegrationsConnectionsTableRow connection={c} key={key} />
        ))}
      </TableBody>
    </Table>
  )
}

export const StyledTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    padding: 0px;
  }
`

const HeaderTableRow = styled(StyledTableRow)`
  & .MuiTableCell-root {
    background-color: ${Colors.surfacePlus2};
    padding: 0px;
  }
`
