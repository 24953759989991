import { BoxProps } from '@mui/material'
import React from 'react'
import useAppState from '../hooks/useAppState'
import { isStrategicView } from '../libs/MenuHelper'
import Texts from '../ygdrasil/libs/Texts'
import { IncreaseIcon } from './Icons'
import { MenuButton } from './MenuButton'

const MenuButtonStrategyView: React.FC<BoxProps> = function ({ onClick }) {
  const { state } = useAppState()
  const isSelected = isStrategicView(state)
  return (
    <MenuButton
      onClick={onClick}
      isSelected={isSelected}
      textKey={Texts.applicationMenuStrategyButtonText}
      Icon={IncreaseIcon}
    />
  )
}

export default MenuButtonStrategyView
