import Texts from '../ygdrasil/libs/Texts'
import { EditDatasourceMachineViewProps } from '../ygdrasil/types/EditDatasourceMachine'
import { Container, EditDatasourceMachineViewHeader } from './EditDatasourceMachineViewHeader'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import ConnectionType from '../ygdrasil/enums/ConnectionType'
import Box from './Box'
import Button from './Button'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import React from 'react'

export default function EditDatasourceMachineViewEditConnection(props: EditDatasourceMachineViewProps) {
  const { onPressContinue, onClickBack, setMachineState, onClose, state } = props

  return (
    <Container>
      <EditDatasourceMachineViewHeader
        textKey={Texts.adminIntegrationsCreateConnectionWizardStep1HeaderText}
        {...props}
        onClickBack={undefined}
      />
      <EditDatasourceMachineViewEditConnectionForm {...props} />
    </Container>
  )
}

const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation()
})

function EditDatasourceMachineViewEditConnectionForm({ onPressContinue, state }: EditDatasourceMachineViewProps) {
  const { connection } = state.data

  const initialValues = {
    name:
      connection?.name || state.data.connectionType === ConnectionType.EXCEL_ONLINE
        ? 'Excel Online'
        : 'Google Spreadsheet'
  }

  const onSubmit = ({ name: connectionName }) => onPressContinue({ ...state, data: { ...state.data, connectionName } })

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
      {(formProps) => (
        <Box fullWidth left right top>
          <Box fullWidth>
            <TextFieldWithLabelFormik
              fullWidth
              name="name"
              placeholderTextKey={Texts.adminIntegrationsCreateConnectionWizardStep1ConnectionNameHelperText}
              labelTextKey={Texts.adminIntegrationsCreateConnectionWizardStep1ConnectionNameLabel}
              formProps={formProps}
            />
          </Box>
          <Box top fullWidth align="flex-end">
            <Button
              textKey={Texts.continueButtonText}
              onClick={formProps.handleSubmit as any}
              loading={state.isLoading}
            />
          </Box>
        </Box>
      )}
    </Formik>
  )
}
