import { useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useConversationQueryResponse } from '../hooks/useConversationQuery'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { continueConversation } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { Conversation, ConversationTextKeys } from '../ygdrasil/libs/ConversationHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import { SuggestionButtonView } from './SuggestionButtonView'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'

export function SuggestionButton({
  initialConversation,
  onNewSuggestion,
  enableRerunConversation
}: {
  initialConversation: Conversation
  onNewSuggestion: (suggestion: string) => unknown
  enableRerunConversation?: boolean
}) {
  const config = useLoggedInConfig()
  const [hasClickedConfirm, setHasClickedConfirm] = useState(false)
  const { state } = useAppState()
  const [conversation, setConversation] = useState(initialConversation)
  const conversationResponse = useConversationQueryResponse(conversation._id)
  const suggestion = conversationResponse?.response
  const [isLoading, setIsLoading] = useState(false)
  const textKey = ConversationTextKeys[conversation.type][conversation.step] || Texts.editObjectiveChatGptSuggestion2

  const onClick = () => {
    let _conversation = conversation
    if (enableRerunConversation) _conversation = { ...conversation, responses: [], step: 0 }
    setIsLoading(true)
    continueConversation(_conversation, state)
      .then((c) => setConversation({ ...c, step: Math.min(_conversation.step + 1, _conversation.requests.length - 1) }))
      .catch(captureAndNotifyError)
      .finally(() => setIsLoading(false))
  }

  const onClickConfirm = () => {
    if (!suggestion) return
    const _suggestion = suggestion.replaceAll('"', '')
    onNewSuggestion(_suggestion)
    setHasClickedConfirm(true)
  }

  if (hasClickedConfirm) return null

  if (!config.enableChatGPT) return null

  return (
    <Box fullWidth>
      <SuggestionButtonView textKey={textKey} isLoading={isLoading} text={suggestion} onClick={onClick} />
      {!!suggestion && (
        <Box fullWidth align="center" justify="flex-end" direction="row" top bottom spacing={smallSpacing}>
          <Button textKey={Texts.aiImprovementUseItButtonText} spacing={smallSpacing} onClick={onClickConfirm} />
          <Box left spacing={tinySpacing}>
            <Button
              buttonType="secondary"
              textKey={Texts.aiImprovementTryAgainButtonText}
              spacing={smallSpacing}
              onClick={onClick}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
