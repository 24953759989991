import { useEffect } from 'react'
import config from '../Config'
import { loadChatBubble } from '../libs/TidioHelper'
import { useLanguage } from '../ygdrasil/libs/useLanguage'

export default function useTidio() {
  const language = useLanguage()
  useEffect(() => {
    if (!config.enableTidio) return
    loadChatBubble()
    ;(document as any).tidioChatLang = language
  }, [language])

  return language
}
