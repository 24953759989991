import styled from '@emotion/styled'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import { StrategyModel } from '../ygdrasil/enums/StrategyModel'
import Term from '../ygdrasil/enums/Term'
import { A_COLOR_THAT_NEEDS_TO_BE_IN_FIGMA } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import { Language } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { SettingsApplicationStrategyModelTerms } from './SettingsApplicationStrategyModelTerms'

const SETTINGS_APPLICATION_STRATEGY_MODEL_WIDTH = 450

export default function SettingsApplicationStrategyModelTab({
  strategyModel,
  language
}: {
  strategyModel: StrategyModel
  language: Language
}) {
  const terms = [
    Term.VISION,
    Term.STRATEGIC_PILLAR,
    Term.STRATEGIC_GOALS,
    Term.OBJECTIVES,
    Term.INDICATORS,
    Term.ACTIVITY
  ]
  const views = [Term.BUSINESS_PLAN, Term.DASHBOARD, Term.SUCCESS_VIEW, Term.STRATEGY_VIEW]
  return (
    <Box fullWidth top>
      <Container>
        <SettingsApplicationStrategyModelTerms
          terms={terms}
          language={language}
          strategyModel={strategyModel}
          shouldPadLeft
        />
      </Container>
      <Box top>
        <FigmaText textKey={Texts.applicationSettingStrategyModelViewDefinitionsHeader} />
        <Box top spacing={tinySpacing}>
          <Container>
            <SettingsApplicationStrategyModelTerms terms={views} language={language} strategyModel={strategyModel} />
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

const Container = styled(Box)`
  min-width: ${SETTINGS_APPLICATION_STRATEGY_MODEL_WIDTH}px;
`

export const Row = styled(Box)`
  border-radius: ${tinyBorderRadius};
  background-color: ${A_COLOR_THAT_NEEDS_TO_BE_IN_FIGMA};
`
