/* eslint-disable react-hooks/rules-of-hooks */
import { Organization, User } from '../types/types'
import { useFirestoreQuery } from './useFirestoreQuery'
import { ReducerState } from './useFirestoreQueryHelper'
import { getAllOrgsQueryForOrgIds } from '../libs/FirebaseStorageClientForWebApp'
import { getChunks } from '../libs/Common'
import { MAX_QUERYABLE_ARRAY_LENGTH } from '../libs/Consts'

export const useOrganizations = (user: User) => {
  return getChunks(user?.organizationIds || [], MAX_QUERYABLE_ARRAY_LENGTH).reduce(
    (acc, chunk) => {
      const query = useFirestoreQuery<Organization[]>(getAllOrgsQueryForOrgIds(chunk), { fnName: 'useOrganizations' })
      return { ...acc, ...query, data: [...(acc.data || []), ...(query.data || [])] }
    },
    { data: [] as Organization[], isLoading: true, error: undefined } as ReducerState<Organization[]>
  )
}
