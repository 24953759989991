import styled from '@emotion/styled'
import MaterialButton from '@mui/material/Button'
import { minBorderRadius } from '../enums/BorderRadixes'
import { bigSpacing } from '../enums/Spacings'

export default styled(MaterialButton)<{ spacing?: string }>`
  padding-left: ${({ spacing }) => spacing || bigSpacing};
  padding-right: ${({ spacing }) => spacing || bigSpacing};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  min-width: auto;
  text-transform: none;
  border-radius: ${minBorderRadius};
`
