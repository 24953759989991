import React from 'react'
import { CheckinStatus } from '../ygdrasil/enums/CheckinStatus'
import {
  BIG_ICON_SIZE,
  CheckinStatusAtRisk,
  CheckinStatusDontKnow,
  CheckinStatusFeelsGood,
  CheckinStatusNotAchievable
} from './Icons'

export const CHECKIN_ICON_SIZE = BIG_ICON_SIZE
export const CheckinStatusView = ({ checkinStatus }: { checkinStatus?: CheckinStatus }) => {
  switch (checkinStatus) {
    case CheckinStatus.FEELS_GOOD:
      return <CheckinStatusFeelsGood size={CHECKIN_ICON_SIZE} />
    case CheckinStatus.AT_RISK:
      return <CheckinStatusAtRisk size={CHECKIN_ICON_SIZE} />
    case CheckinStatus.NOT_ACHIEVABLE:
      return <CheckinStatusNotAchievable size={CHECKIN_ICON_SIZE} />
    default:
      return <CheckinStatusDontKnow size={CHECKIN_ICON_SIZE} />
  }
}
