import dayjs from 'dayjs'
import { BusinessPlan, StrategicPillar } from '../types/types'
import Collections from './Collections'
import { createUniqueId } from './Common'

export const XXX = 'XXX'
export const PREVIOUS_OUTPUT = 'PREVIOUS_OUTPUT'
export const MONGO = 'MONGO'
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const DELETE = 'DELETE'
export const EUROPE_STOCKHOLM = 'Europe/Stockholm'
export const DEFAULT_PROTOCOL = 'https'
export const HTTPS_REGEXP = /(https?:\/\/[^\s]+)/g

export const HTTP_STATUS_CODE_INTERNAL_ERROR = 500
export const HTTP_STATUS_CODE_BAD_REQUEST = 400
export const HTTP_STATUS_CODE_NOT_FOUND = 404
export const HTTP_STATUS_CODE_UNAUTHORIZED = 401
export const HTTP_STATUS_CODE_OK = 200

export const HALF_SECOND = 500
export const ONE_SECOND = 1000
export const TWO_SECONDS = 2000
export const FIVE_SECONDS = ONE_SECOND * 5
export const ONE_MINUTE = 60 * 1000
export const JIRA_ISSUES_ONE_HOUR_STALE = 3600000 //1h

// Collections
export const USERS = Collections.USERS
export const OBJECTIVES = Collections.OBJECTIVES
export const ORGANIZATIONS = Collections.ORGANIZATIONS
export const TEAM = Collections.TEAM
export const MAIL = Collections.MAIL

export const SEVENTYSEVEN_EPICS_IN_DOING = '77epicsInDoing.json'
export const FUNNEL_DATA = 'FunnelData.json'

export const NUMBER_OF_DEFAULT_VALUES = 10
export const DD_MMM = 'DD MMM'

export const SNACK_BAR_PROPS: any = { maxSnack: 3, anchorOrigin: { vertical: 'top', horizontal: 'right' } }

export const DASHBOARD_RATE_OF_CHANGE_DAYS = 30
export const CHART_ASPECT_RATIO = 16 / 9
export const DASHBOARD_CHART_WIDTH = 400
export const ADMINISTRATION_CHART_WIDTH = 1000

export const MAX_TOP_OBJECTIVES = 10

export const TAB_KEYCODE = 9
export const ENTER_KEYCODE = 13
export const ESC_KEYCODE = 27

export const STATE = 'STATE'

export const OUR_BIG_PICTURE_ORG_ID = '02b5ei80z'

export const COMMON_EMAIL_DOMAINS = [
  'gmail.com',
  'outlook.com',
  'yahoo.com',
  'hotmail.com',
  'inbox.com',
  'icloud.com',
  'mail.com',
  'me.com'
]

export const UNASSIGNED_TEAM_ID = 'UNASSIGNED_TEAM_ID'

export const sv = 'sv'
export const en = 'en'
export const LANGUAGES = [sv, en]
export const LANGUAGES_OBJECT = { sv: 'Svenska', en: 'English' }
export const LANGUAGES_OPTIONS = Object.keys(LANGUAGES_OBJECT).map((code) => ({
  key: LANGUAGES_OBJECT[code],
  value: code
}))

export const IGNORED_TEXT_KEYS: string[] = ['exampleText', 'ignoreText', 'genericText']

export const INVITE_MAIL_BUTTON_TEXT_TO_BE_REPLACED = 'INVITE_MAIL_BUTTON_TEXT_TO_BE_REPLACED'
export const INVITE_URL_TEMPLATE_STRING_TO_BE_REPLACED = 'INVITE_URL_TEMPLATE_STRING_TO_BE_REPLACED'
export const INVITE_MAIL_SUBJECT = 'You have been invited to join your colleagues at XXX'
export const LOGIN_WITH_CODE_SUBJECT = 'Our Big Picture confirmation code: XXX'

export const INVITATION_TOKEN_EXPIRY_MONTHS = 3

export const URL_REGEXP = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export const START_VALUE = 'startValue'
export const GOAL_VALUE = 'goalValue'

export const OUR_BIG_PICTURE_DOMAIN = 'ourbigpicture.se'
export const YYYYMMDD_HHmm = 'YYYY-MM-DD HH:mm'
export const YYYY_MM_DD = 'YYYY-MM-DD'
export const MMM_YYYY = 'MMM YYYY'
export const MMM_DD = 'MMM DD'
export const MMM_D = 'MMM D'

export const TEN_PERCENT = 0.1
export const TWENTY_PERCENT = 0.2

export const ORGANIZATIONS_FOR_NON_ONBOARDED_USER = 'ORGANIZATIONS_FOR_NON_ONBOARDED_USER'

export const STRATEGIC_VALUE_DEFAULT_THRESHOLD = 3

export const JOIN_BETA_SLACK_CHANNEL = 'join_beta'
export const OPS_LEAD_NOTIFICATION_SLACK_CHANNEL = 'ops_lead_notifications'
export const ALL_NON_INTERNAL_USERS_SLACK_CHANNEL = '7_day_active_user'
export const BACKUP_CHANNEL = 'backup'

export const EUROPE_WEST_1 = 'europe-west1'
export const US_CENTRAL_1 = 'us-central1'

export const NO_OF_GROWTH_INDICATOR_HEADER_DATES = 12
export const NO_OF_ROADMAP_HEADER_DATES = 4

export const BLUR_KEYCODES = [TAB_KEYCODE, ENTER_KEYCODE]

export const TIDIO_SCRIPT_URL = '//code.tidio.co/xwejhzwarhljmfvjjcmr0bapd4oruzem.js'
export const STONLY_SCRIPT_URL = 'stonly.js'

export const MIN_SEARCH_LENGTH = 0

export const MIN_CONTAINER_WIDTH = 600

// Query Params
export const LEAD_USER_ID = 'leadUserId'
export const CHECKIN_KEYRESULT_ID = 'checkInKeyResultId'
export const ORG_ID = 'orgId'
export const ORGANIZATION_ID = 'organizationId'

export const ONBOARDING_ACCOMPLISH_BY_DEFAULT_VALUE = dayjs().add(1, 'y').endOf('y').format()

export const ALL_STRATEGIC_PILLAR: StrategicPillar = { name: 'All', _id: 'All' }

export const NO_LEAD = 'NONE'

export const NO_STRATEGIC_PILLAR: StrategicPillar = { name: 'ÖÖÖ_NO_STRATEGIC_PILLAR', _id: 'ÖÖÖ_NO_STRATEGIC_PILLAR' }

export const DEFAULT_BUSINESSPLAN: BusinessPlan = {
  strategicPillars: ['Market', 'Product', 'Efficiency'].map((name) => ({ name, _id: createUniqueId() })),
  values: ['Transparency', 'Humbleness', 'Honesty', 'Braveness'],
  swot: {
    strength:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
    weakness:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
    opportunity:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
    threats:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.'
  }
}

export const COMPANY_LOGO_SIZE_BIG = 120
export const COMPANY_LOGO_SIZE_SMALL = 40

export const NO_OF_LETTERS_IN_SIGN_IN_CODE = 6

export const SAGA_APP_STORE_URL = 'https://apps.apple.com/app/id1609847596'
export const SAGA_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.responsive.saga'

export const ANDROID_NOTIFICATION_CHANNEL_ID = 'saga-notification-channel'

export const MAX_QUERYABLE_ARRAY_LENGTH = 10

export const AZURE_APPLICATION_CLIENT_ID = '81104617-ed9d-44ad-93b4-adea2e1bb462'

export const MS_GRAPH_RESOURCE = 'https://graph.microsoft.com'

export const MS_AUTHORITY_URL = 'https://login.microsoftonline.com/common'

export const MS_SCOPES = ['Files.Read.All', 'openid', 'profile', 'offline_access']

export const EVERY_DAY = 'Every Day'

export const DATE = 'Date'
export const VALUE = 'Value'

export const A_COLOR_THAT_NEEDS_TO_BE_IN_FIGMA = 'rgba(84, 85, 172, 0.08)'

export const APP_REVIEW_USER_EMAIL = 'ourbigpicture.appstore@gmail.com'

export const DATE_VALIDATION_MESSAGE = `Enter date (${YYYY_MM_DD})`

export const SUGGESTABLE_NAME_LENGTH = 60

export const WHITELISTED_UIDS_FOR_DEV_LOGIN = [
  'j1WyxqbKq6fLdL4fcMWynu2x6m33' // Anders
]

export const ONE_YEAR_IN_QUARTER = 4
export const ONE_YEAR_AND_HALF_IN_MONTHS = 18

export const OUR_BIG_PICTURE_WEBSITE_URL = 'https://www.ourbigpicture.se'
export const PRIVACY_POLICY_URL = 'https://www.responsivetribes.com/integritetspolicy'

export const GC_FILE_PATH_DESIGN_TOKENS = `roamer/assets/design_tokens/design-tokens.tokens.json`
export const GC_FILE_PATH_DESIGN_SYSTEM_TOKENS = `roamer/assets/design_tokens/design-tokens.tokens.system.json`
