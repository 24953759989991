import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import { EditableIndicator } from '../components/SuccessViewGraphViewBarChart'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { DayJS } from '../ygdrasil/libs/DayJsHelper'
import {
  Direction,
  GrowthIndicator,
  GrowthIndicatorValue,
  GrowthIndicatorValues,
  SearchDates
} from '../ygdrasil/types/types'
import { isGreen } from './GrowthViewGrowthIndicatorValueActualHelper'
import { BAR_CHART_BAR_SIZE_FACTOR, BAR_CHART_WIDTH } from './HardCodedSizes'

export const getDayStringsOfMonthsBetween = (startDate: Date, endDate: Date): string[] => {
  const monthsBetween: string[] = []
  if (!startDate || !endDate) return []
  const currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    monthsBetween.push(currentDate.toISOString())
    currentDate.setMonth(currentDate.getMonth() + 1)
  }

  return monthsBetween
}

export type SuccessViewGraphBarData = {
  date: string
  goal: number | null
  actual: number | null
  quarter?: string
  year?: string
}

export const fillMissingData = (monthsBetween: string[], values: GrowthIndicatorValues) => {
  const formattedData = Object.entries(values)
    .map(([key, value]) => {
      return {
        date: key,
        goal: isNil(value.goal) ? null : value.goal,
        actual: isNil(value.actual) ? null : value.actual
      }
    })
    .sort((a, b) => a.date.localeCompare(b.date))

  let result: SuccessViewGraphBarData[] = []

  const minMaxValues: number[] = []

  //fill in the missing data for the months that have no data
  monthsBetween.forEach((month) => {
    const foundMatching = formattedData.filter((row) => DayJS(row.date).isSame(month, 'month'))

    if (!foundMatching.length) {
      const lastEntry = result[result.length - 1]
      const date = !lastEntry ? DayJS(month) : DayJS(lastEntry.date)
      const year = date.year().toString()

      result.push({
        date: DayJS(month).format(YYYY_MM_DD),
        goal: null,
        actual: null,
        quarter: DayJS(month).quarter().toString() + `_${DayJS(month).year().toString()}`,
        year
      })
    } else {
      const formattedMatching = foundMatching.map((row) => {
        const date = DayJS(row.date)
        const year = date.year().toString()

        return {
          ...row,
          quarter: `${date.quarter().toString()}_${year}`,
          year
        }
      })
      result = [...result, ...formattedMatching]
    }
  })

  //claculate the min and max values to use them as domain of the yAxis
  result.forEach((row) => {
    !!row.goal && minMaxValues.push(row.goal)
    !!row.actual && minMaxValues.push(row.actual)
    if (row?.actual === null || row?.goal === null) {
      minMaxValues.push(0)
    }
  })
  const dataMinValue = Math.min(...minMaxValues)
  const dataMaxValue = Math.max(...minMaxValues)

  //set dataMin to be a little bit smaller than the smallest value so that if we have actual value equeal to min value it will still be visible
  const dataMin = dataMinValue - Math.abs(dataMinValue / 10) || 0
  const dataMax = dataMaxValue + dataMaxValue / 10 || 0

  return { filledData: result, dataMin, dataMax }
}

export const getFilteredGrowthIndicators = (growthIndicators: GrowthIndicator[], searchDates: SearchDates) => {
  return growthIndicators
    .map((growthIndicator) => {
      const { values } = growthIndicator
      const filteredValues = Object.entries(values)
        .filter(([key, value]) => {
          const date = new Date(key)
          const startDate = new Date(searchDates.startDate)
          const endDate = new Date(searchDates.endDate)
          return date >= startDate && date <= endDate
        })
        .reduce((acc, [key, value]) => {
          return { ...acc, [key]: value }
        }, {})
      if (!Object.keys(filteredValues)?.length) return null
      return { ...growthIndicator, values: filteredValues }
    })
    .filter((i) => !!i) as unknown as GrowthIndicator[]
}

export type FilledData = {
  date: string
  goal: number | null
  actual: number | null
  quarter?: string
  year?: string
}[]

export const getCurrentXAxisDataKey = (monthsWithData: SuccessViewGraphBarData[]) => {
  if (monthsWithData.length <= 12) {
    return 'date'
  }
  if (monthsWithData.length > 36) {
    return 'year'
  }
  return 'quarter'
}

export const getXAxisLabel = (xAxisDataKey: 'date' | 'year' | 'quarter', value: any) => {
  switch (true) {
    case xAxisDataKey === 'year':
      return value ? value : ''
    case xAxisDataKey === 'quarter': {
      const formattedValue = value?.split('_')?.[0] || ''
      return `Q${formattedValue}`
    }
    default:
      return value ? DayJS(value).format('MMM') : ''
  }
}

export const getXAxisData = (filledData: FilledData, xAxisDataKey: 'date' | 'year' | 'quarter') => {
  if (!filledData) return []
  const xAxisData = Object.values(filledData)
    .map((item) => item[xAxisDataKey])
    .filter((i) => !!i) as string[]

  const formattedXAxisData: string[] = []

  for (let i = 0; i < xAxisData.length; i++) {
    if (i === 0 || xAxisData[i] !== xAxisData[i - 1]) {
      formattedXAxisData.push(xAxisData[i])
    }
  }
  return formattedXAxisData
}

export const formatYAxisLabel = (number: number) => {
  if (typeof number !== 'number') return ''
  const formatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 })
  return formatter.format(number)
}

export enum EditableIndicatorPropertyEnum {
  GOAL = 'goal',
  ACTUAL = 'actual'
}
export enum EditableIndicatorDirectionEnum {
  UP = 'up',
  DOWN = 'down'
}

export enum SuccessViewGraphEditType {
  FIELDS = 'fields',
  DRAG = 'drag'
}

export const calculateDragIndicatorValueChange = (
  dataMax: number,
  dataMin: number,
  nextState: { chartY: number },
  event: any
) => {
  const chartArea = document.querySelector('.recharts-cartesian-grid')
  const chartHeight = Number(chartArea?.getBoundingClientRect?.()?.height) + 20
  const valuePerYValue = (dataMax - dataMin) / chartHeight
  const isDownEvent = event?.nativeEvent?.layerY > chartHeight / 2
  const fallback = isDownEvent ? dataMin : dataMax
  const outOfBounds = isNil(nextState?.chartY)
  const currentClickHeight = chartHeight - (nextState?.chartY ?? 0)
  const currentClickValue = outOfBounds ? fallback : dataMin + currentClickHeight * valuePerYValue
  return Math.floor(Number(currentClickValue.toFixed()))
}

export const getCalculatedDragIndicatorValues = (
  indicatorValueChange: number,
  editableIndicator,
  growthIndicatorValues,
  currentEditableProp: EditableIndicatorPropertyEnum
) => {
  const { date } = editableIndicator || {}
  const growthIndicatorValuesCopy = cloneDeep(growthIndicatorValues)

  if (!growthIndicatorValuesCopy[date]) {
    growthIndicatorValuesCopy[date] = { ...editableIndicator, height: editableIndicator?.height || 0 }
  }
  growthIndicatorValuesCopy[date][currentEditableProp] = indicatorValueChange
  return growthIndicatorValuesCopy
}

export const getCurrentEditableValue = (
  currentEditableProp: EditableIndicatorPropertyEnum | null,
  currentEditableIndicator: any
) =>
  currentEditableProp === EditableIndicatorPropertyEnum.ACTUAL
    ? currentEditableIndicator?.actual
    : currentEditableIndicator?.goal

export const getCustomizedDotData = (props) => {
  return {
    ...props.payload,
    actual: props?.payload?.actual || 0,
    goal: props?.payload?.goal || 0,
    height: props.cy || 1
  }
}

export const getActualBarSize = (monthsBetween: string[]) =>
  (BAR_CHART_WIDTH * BAR_CHART_BAR_SIZE_FACTOR) / monthsBetween.length

export const getNewCurrentEditableIndicator = (
  growthIndicatorValuesCopy: any,
  currentEditableIndicator: EditableIndicator | null,
  currentEditableProp: EditableIndicatorPropertyEnum
) => {
  const { date } = currentEditableIndicator || {}
  if (!date) return null
  const currentValue = growthIndicatorValuesCopy[date][currentEditableProp]
  const newCurrentEditableIndicator = {
    ...currentEditableIndicator,
    height: currentEditableIndicator?.height || 1,
    [currentEditableProp]: currentValue
  }
  return newCurrentEditableIndicator
}

export const isActualGreen = (
  value: GrowthIndicatorValue,
  direction: Direction,
  filledData: SuccessViewGraphBarData[],
  index: number
) => {
  const currentEntry = filledData[index]
  if (typeof currentEntry.goal === 'number') return { isGreenActual: isGreen(value, direction) }

  let prev: SuccessViewGraphBarData | null = null
  let next: SuccessViewGraphBarData | null = null
  let prevIdx: number = index
  let nextIdx: number = index

  for (let i = index; i >= 0; i--) {
    if (typeof filledData[i].goal === 'number') {
      prev = filledData[i]
      prevIdx = i
      break
    }
  }
  for (let i = index; i < filledData.length; i++) {
    if (typeof filledData[i].goal === 'number') {
      next = filledData[i]
      nextIdx = i
      break
    }
  }
  if (!prev || !next) return { isGreenActual: false }

  const indexDiff = nextIdx - prevIdx
  const targetDiff = index - prevIdx
  const timeRatio = targetDiff / indexDiff
  const linearValue = linearInterpolation(prev, next, timeRatio)
  if (!linearValue) return { isGreenActual: false }
  return { isGreenActual: isGreen({ ...value, goal: linearValue }, direction), linearValue }
}

const linearInterpolation = (
  startMonth: SuccessViewGraphBarData,
  endMonth: SuccessViewGraphBarData,
  timeRatio: number
): number | null => {
  if (startMonth.goal === null || endMonth.goal === null) return null
  return startMonth.goal + timeRatio * (endMonth.goal - startMonth.goal)
}
