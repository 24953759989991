import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getKeyResultValueText } from '../libs/EditObjectiveDialogHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { GOAL_VALUE, START_VALUE } from '../ygdrasil/libs/Consts'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult } from '../ygdrasil/types/types'
import Box from './Box'
import { logEvent } from '../ygdrasil/libs/FirebaseHelper'
import { OBJECTIVE_DIALOG_KEY_RESULT_BOX_WIDTH } from '../libs/HardCodedSizes'
import styled from '@emotion/styled'

export const EditObjectiveDialogKeyResultRow = ({
  keyResult,
  onClickKeyResult
}: {
  keyResult: KeyResult
  onClickKeyResult: (keyResult: KeyResult) => void
}) => {
  const { state } = useAppState()
  const { name } = keyResult
  return (
    <Box
      direction="row"
      fullWidth
      align="center"
      onClick={() => {
        logEvent(AnalyticsEvent.OBJECTIVE_EDITINDICATOR, state)
        onClickKeyResult(keyResult)
      }}
      pointer
      bottom
      spacing={tinySpacing}
    >
      <Box right>
        <KeyResultValueContainer align="flex-start" top bottom spacing={tinySpacing}>
          <FigmaText
            textKey={Texts.rightPanelIndicatorListItemActiveNumber}
            text={getKeyResultValueText(keyResult, START_VALUE)}
          />
          <FigmaText
            textKey={Texts.rightPanelIndicatorListItemGoalNumber}
            text={getFigmaText(Texts.successViewGoalHeaderLabel) + ': ' + getKeyResultValueText(keyResult, GOAL_VALUE)}
          />
        </KeyResultValueContainer>
      </Box>
      <Box direction="row">
        <FigmaText textKey={Texts.rightPanelIndicatorListItemDescription} text={name} />
      </Box>
    </Box>
  )
}

const KeyResultValueContainer = styled(Box)`
  width: ${OBJECTIVE_DIALOG_KEY_RESULT_BOX_WIDTH};
`
