import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import Colors from '../ygdrasil/libs/Colors'
import Images from '../ygdrasil/libs/Images'
import Box from './Box'
import CelebrationView from './CelebrationView'
import FigmaImageContainer from './FigmaImageContainer'
import { MenuCollapseIcon } from './Icons'
import { CelebrationsContainer } from './Dashboard'

export function IndicatorDashboardCelebrations() {
  const { state, setState } = useAppState()
  const onToggleCelebrations = () => setState({ ...state, showDashboardCelebrations: !state.showDashboardCelebrations })

  if (!state.showDashboardCelebrations)
    return (
      <CelebrationsClosedContainer fullPadding onClick={onToggleCelebrations} pointer spacing={smallSpacing}>
        <FigmaImageContainer imageKey={Images.celebrationIcon} />
        <Box fullHeight />
        <MenuCollapseIcon />
      </CelebrationsClosedContainer>
    )

  return (
    <CelebrationsContainer fullHeight pointer>
      <CelebrationView onClose={onToggleCelebrations} />
    </CelebrationsContainer>
  )
}
const CelebrationsClosedContainer = styled(Box)`
  height: 100vh;
  background-color: ${Colors.neutral95};
`
