import styled from '@emotion/styled'
import { MenuItem, MenuProps } from '@mui/material'
import { SelectProps } from '@mui/material/Select'
import { useRef } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { asObject } from '../ygdrasil/libs/Common'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { MaterialSelectStyled } from './SelectFieldWithLabelMulti'

type SelectFieldWithTextKeyAndLabelProps = SelectProps & {
  labelTextKey?: AntiloopTextType
  placeholderTextKey?: AntiloopTextType
  fullWidth?: boolean
  options: SelectFieldWithTextKeyAndLabelOptions[]
  menuProps?: Partial<MenuProps>
}

export type SelectFieldWithTextKeyAndLabelOptions = {
  key: string
  value: string
  paddingLeft?: number
  bold?: boolean
}

export default function SelectFieldWithTextKeyAndLabel(props: SelectFieldWithTextKeyAndLabelProps) {
  const { labelTextKey, placeholderTextKey, menuProps, variant = 'outlined', ...selectProps } = props

  const menuAnchorElement = useRef(null)
  const _options = asObject(props.options, 'value')
  return (
    <Box fullWidth>
      {labelTextKey && (
        <Box bottom spacing={tinySpacing}>
          <FigmaTextForStrategyModel textKey={labelTextKey} />
        </Box>
      )}
      <MaterialSelectStyled
        {...selectProps}
        placeholder={placeholderTextKey && getFigmaText(placeholderTextKey)}
        variant={variant}
        size="small"
        MenuProps={{
          ...menuProps,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        renderValue={(value) => (
          <FigmaText
            textKey={Texts.parentObjectiveListItemCompanyObjective}
            text={_options[value as string]?.key || ''}
          />
        )}
      >
        {props.options.map((option) => {
          const { key, value, paddingLeft = 0, bold } = option
          const isSelected = value === props.value
          let textKey = Texts.parentObjectiveListItemCompanyObjective
          if (bold) textKey = Texts.parentObjectiveListItemStrategicObjective
          if (isSelected) textKey = Texts.parentObjectiveListItemSelectedRow

          return (
            <StyledMenuItem key={key} value={value}>
              <Box
                fullWidth
                style={{ backgroundColor: value === props.value ? Colors.primaryBase : 'transparent' }}
                spacing={tinySpacing}
                fullPadding
              >
                <Box fullWidth left spacing={smallSpacing}>
                  <Box left spacing={`${paddingLeft}px`} fullWidth>
                    <FigmaText textKey={textKey} text={key} />
                  </Box>
                </Box>
              </Box>
            </StyledMenuItem>
          )
        })}
      </MaterialSelectStyled>
      <div ref={menuAnchorElement} />
    </Box>
  )
}

const StyledMenuItem = styled(MenuItem)`
  padding: 0px;
`
