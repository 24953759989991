import { Chip, styled } from '@mui/material'
import { minBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { getInitials } from '../ygdrasil/libs/UserWidgetHelper'
import Colors from '../ygdrasil/libs/Colors'
import { toPx } from '../ygdrasil/libs/Common'
import Texts from '../ygdrasil/libs/Texts'
import { Team } from '../ygdrasil/types/types'
import Box from './Box'
import Tooltip from './Tooltip'

export const ChipInputTeamList = ({
  teamIds,
  teams,
  enableInitials
}: {
  enableInitials?: boolean
  teamIds: string[]
  teams: Team[]
}) => {
  return (
    <Box direction="row">
      {teams
        .filter((t) => teamIds.includes(t._id))
        .map((team, key, arr) => (
          <Box key={key} right spacing={smallSpacing}>
            <Tooltip title={enableInitials ? team.name : ''} placement="top">
              <StyledChip
                key={team._id}
                label={enableInitials && arr.length > 2 ? getInitials(team.name) : team.name}
              />
            </Tooltip>
          </Box>
        ))}
    </Box>
  )
}

export const StyledChip = styled(Chip)`
  background: ${Colors.whiteBg};
  border: 1px solid ${Colors.neutralVariant80};
  border-radius: ${minBorderRadius};
  font-family: ${Texts.cSsInvolvedTeamNameText.style.fontFamily};
  font-size: ${Texts.cSsInvolvedTeamNameText.style.fontSize};
  font-weight: ${Texts.cSsInvolvedTeamNameText.style.fontWeight};
  cursor: pointer;
  height: ${toPx(Texts.cSsInvolvedTeamNameText.style.fontSize) * 2}px;
`
