import dayjs from 'dayjs'
import { DEFAULT_STATE } from '../hooks/useAppState'
import { SearchDates } from '../ygdrasil/types/types'

export const MODAL_SLIDER_START_DATE = dayjs(DEFAULT_STATE.searchState.searchDates?.startDate)
  .add(-1, 'quarter')
  .startOf('quarter')
  .format()

export const getStartDateForEditObjectiveDialog = ({ startDate }: SearchDates): string => {
  const defaultStartMoment = dayjs(DEFAULT_STATE.searchState.searchDates?.startDate).startOf('quarter')

  if (defaultStartMoment.isAfter(startDate)) return dayjs(startDate).startOf('quarter').format()
  return defaultStartMoment.format()
}
