import { useEffect, useMemo, useState } from 'react'
import useAppState, { State } from '../../hooks/useAppState'
import { useActivities, useIndicators, useObjectives } from '../libs/QueryHooks'
import { Activity, KeyResult, Objective } from '../types/types'

export type AllItems = {
  allObjectives: Objective[]
  allIndicators: KeyResult[]
  allActivities: Activity[]
  isLoading?: boolean
  isLoadingIndicators?: boolean
  isLoadingActivities?: boolean
}

const DEFAULT_STATE = {
  allObjectives: [],
  allIndicators: [],
  allActivities: [],
  isLoading: true,
  isLoadingIndicators: true,
  isLoadingActivities: true
}
export const useAllItems = (refetch = false): AllItems => {
  const [query, setQuery] = useState<AllItems>(DEFAULT_STATE)
  const { state } = useAppState()
  const selectedOrganizationId = useMemo(() => state.selectedOrganizationId, [state.selectedOrganizationId])

  const q = useObjectives({ selectedOrganizationId } as State, refetch)
  const { data: allObjectives = [], isLoading } = q
  const _q = useIndicators({ selectedOrganizationId } as State, refetch)
  const { data: allIndicators = [], isLoading: isLoadingIndicators } = _q
  const __q = useActivities({ selectedOrganizationId } as State, refetch)
  const { data: allActivities = [], isLoading: isLoadingActivities } = __q
  useEffect(() => setQuery({ ...query, isLoading, allObjectives }), [q])
  useEffect(() => setQuery({ ...query, isLoadingIndicators, allIndicators }), [_q])
  useEffect(() => setQuery({ ...query, isLoadingActivities, allActivities }), [__q])

  return query
}
