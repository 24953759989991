import React from 'react'
import { tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Box from './Box'
import DatePicker from './DatePicker'
import { TextFieldFormikProps } from './TextFieldFormik'

export type DatePickerWithFormikProps = {
  minDate?: string
  maxDate?: string
  textfieldFormikProps: TextFieldFormikProps
}
export const DatePickerWithLabelFormik: React.FC<DatePickerWithFormikProps> = (props: DatePickerWithFormikProps) => {
  const { textfieldFormikProps } = props
  const { labelTextKey, fullWidth, labelText } = textfieldFormikProps
  return (
    <Box fullWidth={fullWidth}>
      {labelTextKey && <FigmaText textKey={labelTextKey} text={labelText} />}
      <Box top spacing={tinySpacing} fullWidth>
        <DatePicker {...props} />
      </Box>
    </Box>
  )
}
