/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-restricted-globals */
import styled from '@emotion/styled'
import Menu from '@mui/material/Menu'
import dayjs from 'dayjs'
import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useMyFocusRows } from '../hooks/useMyFocusRows'
import { WEEKLY_DATE_MENU_WIDTH } from '../libs/HardCodedSizes'
import { getMyFocusForMenu, getWeeklyDateText } from '../libs/MyActionsMyFocusHelper'
import { hasContent } from '../libs/WYSIWYGEditor'
import Texts from '../ygdrasil/libs/Texts'
import { useLanguage } from '../ygdrasil/libs/useLanguage'
import { MyFocusRequest } from '../ygdrasil/types/types'
import Box from './Box'
import { MyActionsMyFocusCheckIcon as NotDoneIcon, MyActionsMyFocusDoneCheckIcon as DoneIcon } from './Icons'
import { MenuItem } from './MenuItem'

type Props = {
  anchorEl?: HTMLElement
  onClose: (e) => void
  onClick: (date: string) => unknown
}

export default function MyActionsMyFocusWeeklyMenu({ anchorEl, onClose, onClick }: Props) {
  const { state } = useAppState()
  const language = useLanguage()
  const rows = useMyFocusRows()
  const _rows = getMyFocusForMenu(rows, state)

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} disableAutoFocusItem>
      {_rows
        .sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf())
        .map((myFocus: MyFocusRequest, key) => {
          const { date } = myFocus
          const why = hasContent(myFocus.why)
          const retro = hasContent(myFocus.retro)

          const _onClick = (e) => {
            onClick(myFocus.date)
            onClose(e)
          }

          const textKey = dayjs(date).isAfter(dayjs(), 'week')
            ? Texts.weeklyFocusDropDownItemFuture
            : Texts.weeklyFocusDropDownItem

          return (
            <StyledMenuItem
              key={key}
              onClick={_onClick}
              textKey={textKey}
              text={getWeeklyDateText(date, language)}
              rightElement={
                <Box direction="row" align="center" left>
                  <Box>{why ? <DoneIcon /> : <NotDoneIcon />}</Box>
                  <Box left spacing={tinySpacing}>
                    {retro ? <DoneIcon /> : <NotDoneIcon />}
                  </Box>
                </Box>
              }
            />
          )
        })}
    </Menu>
  )
}

const StyledMenuItem = styled(MenuItem)`
  width: ${WEEKLY_DATE_MENU_WIDTH}px;
`
