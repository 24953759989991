import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { StrategyViewObjectivesHeaderToggleButton } from './StrategyViewObjectivesHeaderToggleButton'
import { SuccessViewHeaderToggleButton } from './SuccessViewHeaderToggleButton'

export const SuccessViewHeaderToggle = () => {
  const { state, setState } = useAppState()
  const { selectedSuccessView } = state
  const onClickListView = () => setState({ ...state, selectedSuccessView: 'sheet' })

  const onClickGraphView = () => setState({ ...state, selectedSuccessView: 'graph' })

  return (
    <Box direction="row">
      <Box direction="row" alignSelf="center">
        <SuccessViewHeaderToggleButton
          textKey={Texts.successViewGraphViewButton}
          onClick={onClickGraphView}
          isSelected={selectedSuccessView === 'graph'}
        />

        <Box left spacing={tinySpacing}>
          <SuccessViewHeaderToggleButton
            textKey={Texts.successViewSheetViewButton}
            onClick={onClickListView}
            isSelected={selectedSuccessView === 'sheet'}
          />
        </Box>
      </Box>
    </Box>
  )
}
