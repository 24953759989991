import React, { useRef } from 'react'
import Box from './Box'
import FigmaImage from './FigmaImage'
import { AntiloopImageType } from '../ygdrasil/types/Antiloop'
import useClientWidth from '../hooks/useClientWidth'

export function FigmaImageContainerSVGFullWidth({ imageKey }: { imageKey: AntiloopImageType }) {
  const ref = useRef<HTMLDivElement>(null)
  const clientWidth = useClientWidth(ref) || 0

  let { width, height } = imageKey
  height = (clientWidth / width) * height
  width = clientWidth

  return (
    <Box fullWidth ref={ref}>
      <FigmaImage imageKey={imageKey} imageStyle={{ width, height }} />
    </Box>
  )
}
