/* eslint-disable no-restricted-globals */
import Menu from '@mui/material/Menu'
import useAppState from '../hooks/useAppState'
import { getStateForStrategicView } from '../libs/MenuHelper'
import { useOrganizations } from '../ygdrasil/hooks/useOrganizations'
import { inNameOrder } from '../ygdrasil/libs/UserWidgetHelper'
import { Organization } from '../ygdrasil/types/types'
import { MenuItem } from './MenuItem'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
}

export default function UserWidgetMenuOrganizationMenu({ anchorEl, onClose }: Props) {
  const { state, setState } = useAppState()
  const { selectedOrganizationId } = state
  const { data: organizations = [] } = useOrganizations(state.user)

  const selectableOrgainzations = organizations.filter((org) => org._id !== selectedOrganizationId)

  const onClickOrganization = (o: Organization) => {
    onClose()
    setState({ ...getStateForStrategicView(state), selectedOrganizationId: o._id })
  }

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={onClose}
      >
        {selectableOrgainzations
          .sort((a, b) => inNameOrder(a, b))
          .map((o, key) => (
            <MenuItem key={key} onClick={() => onClickOrganization(o)} text={` ${o.name}`} />
          ))}
      </Menu>
    </div>
  )
}
