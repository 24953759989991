import * as draftJs from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { getObjectOrBoolForJSON } from '../ygdrasil/libs/Common'
export { Editor } from 'react-draft-wysiwyg'

export const stringify = (object?: draftJs.EditorState | string) => {
  if (!object) return ''
  if (typeof object === 'string') return object
  return JSON.stringify(draftJs.convertToRaw(object.getCurrentContent()))
}

export const toHtml = (json = ''): string | undefined => {
  const obj = getObjectOrBoolForJSON(json) as draftJs.RawDraftContentState
  if (obj) obj.blocks = mapBlocks(obj.blocks)
  return !!obj ? draftToHtml(obj) : undefined
}

export const createWithContent = (json?: string): EditorState => {
  const obj = getObjectOrBoolForJSON(json) as draftJs.RawDraftContentState
  if (!obj) return createWithText(json)
  return draftJs.EditorState.createWithContent(draftJs.convertFromRaw(obj))
}

export const createWithText = (text = '') =>
  draftJs.EditorState.createWithContent(draftJs.ContentState.createFromText(text))

export type EditorState = draftJs.EditorState

export function mapBlocks(blocks: draftJs.RawDraftContentState['blocks']) {
  return blocks.map((block) => ({ ...block, text: block.text || '\n' }))
}

export const hasContent = (json) => {
  const obj = getObjectOrBoolForJSON(json) as draftJs.RawDraftContentState
  if (!obj) return false
  return obj.blocks.reduce((a, { text }) => a + text, '').length > 0
}
