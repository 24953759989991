import React from 'react'
import MaterialDrawer, { DrawerProps as MaterialDrawerProps } from '@mui/material/Drawer'

export type DrawerProps = { content: React.ReactNode } & MaterialDrawerProps
export default function Drawer(props: DrawerProps) {
  const { content, onClose, ...drawerProps } = props
  return (
    <MaterialDrawer anchor="right" onClose={onClose} {...drawerProps}>
      {content}
    </MaterialDrawer>
  )
}
