import styled from '@emotion/styled'
import { CSSProperties } from 'react'
import { bigSpacing, tinySpacing } from '../enums/Spacings'
import { useEvents } from '../ygdrasil/hooks/useEvents'
import { DRAWER_WIDTH_STRING, DRAWER_WIDTH } from '../libs/HardCodedSizes'
import Box from './Box'
import { CelebrationViewEmptyState } from './CelebrationViewEmptyState'
import { CelebrationViewHeader } from './CelebrationViewHeader'
import { CelebrationViewItem } from './CelebrationViewItem'
import Loader from './Loader'
import { GreyLine } from './StyledComponents'

export default function CelebrationView({ onClose, style }: { style?: CSSProperties; onClose?: () => void }) {
  const { events, isLoading } = useEvents()

  const isEmptyState = events.length === 0 && !isLoading

  return (
    <CelebrationViewContainer style={{ height: '100vh', overflowY: 'scroll', ...style }}>
      <Box bottom spacing={tinySpacing} fullWidth>
        <CelebrationViewHeader onClose={onClose} />
      </Box>
      {isLoading && (
        <Box fullWidth align="center" fullPadding>
          <Loader size={bigSpacing} />
        </Box>
      )}
      {isEmptyState && <CelebrationViewEmptyState />}
      {!isEmptyState && (
        <Box fullWidth>
          {events.map((event, index) => (
            <Box fullWidth key={index}>
              <Box top bottom fullWidth spacing={tinySpacing}>
                <GreyLine />
              </Box>
              <CelebrationViewItem event={event} key={index} index={index} />
            </Box>
          ))}
        </Box>
      )}
    </CelebrationViewContainer>
  )
}

export const CelebrationViewContainer = styled(Box)`
  min-width: ${DRAWER_WIDTH}px;
  background: white;
`
