import React from 'react'
import { smallSpacing } from '../enums/Spacings'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { DashedLine } from './EditObjectiveDialog'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'

export function EditObjectiveDialogHeaderWithLine({
  textKey,
  RightComponent
}: {
  RightComponent?: React.FC
  textKey: AntiloopTextType
}) {
  return (
    <Box fullWidth top bottom spacing={smallSpacing}>
      <Box bottom spacing={smallSpacing} fullWidth direction="row" justify="space-between">
        <Box visibility="hidden">{RightComponent && <RightComponent />}</Box>
        <FigmaTextForStrategyModel textKey={textKey} />
        <Box>{RightComponent && <RightComponent />}</Box>
      </Box>
      <DashedLine fullWidth />
    </Box>
  )
}
