import { User } from 'firebase/auth'
import { CreateLoginTokenRequest, LoginWithCodeToken } from './types'

export enum PreferredLoginMethod {
  MAIL = 'mail',
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  NONE = 'none'
}

export type SignInState = {
  data: {
    email: string
    showPasswordField?: boolean
    invitationToken?: string
    pendingUserCredential?: object
    pendingUser?: User
    pendingProviderId?: string
    signInMethods: string[]
    loginWithCodeToken?: LoginWithCodeToken
    loginWithCodeTokenError?: string
    code?: string
    isSignUp?: boolean
  }
  preferredLoginMethod: PreferredLoginMethod
  step: SignInSteps
}

export type SignInViewProps = {
  isLoading: boolean
  signInState: SignInState
  onPressContinue: (state: SignInState) => Promise<unknown>
  onClickSignup: () => unknown
  onTakeMeBack: () => unknown
  onLoginWithGoogle: () => unknown
  onLoginWithMicrosoft: () => unknown
  onLoginWithApple: () => unknown
  onClickBack: () => unknown
  enableAppleAuthProvider: boolean
}

export enum SignInSteps {
  LANDING = 'LANDING',
  SIGN_UP = 'SIGN_UP',
  ENTER_CODE = 'ENTER_CODE',
  DISABLED = 'DISABLED',
  ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIALS = 'ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIALS'
}
