import Collections from './Collections'
import {
  Activity,
  Connection,
  CustomAuthToken,
  DashboardLink,
  Datasource,
  GrowthIndicator,
  KeyResult,
  MyFocus,
  Objective,
  Organization,
  Team,
  Todo,
  User
} from '../types/types'
import { State } from '../../hooks/useAppState'
import useFirestoreQueryDeprecated from '../hooks/useFirestoreQuery'
import {
  getObjectsQuery,
  getAllObjectsForSelectedOrganizationQuery,
  getAllUsersForSelectedOrganizationQuery,
  getObjectQuery
} from './FirebaseStorageClientForWebApp'
import { toObjective, toOrganization } from './Mapper'
import { useFirestoreQuery } from '../hooks/useFirestoreQuery'
import type { Event } from '../libs/EventHelper'
import { getAddIndicatorValueEvents } from './FirebaseStorageClientBase'
import { db } from '../../libs/Firebase'
import { Conversation } from './ConversationHelper'

// Secure
// Multiple Collections
export const useGrowthIndicators = (s: State) =>
  useFirestoreQueryDeprecated<GrowthIndicator[]>(
    getAllObjectsForSelectedOrganizationQuery(s, Collections.GROWTH_INDICATORS),
    'useGrowthIndicators'
  )
export const useTeams = (s: State) =>
  useFirestoreQueryDeprecated<Team[]>(getAllObjectsForSelectedOrganizationQuery(s, Collections.TEAM), 'useTeams')
export const useObjectives = (s: State, refetch = false) =>
  useFirestoreQueryDeprecated<Objective[]>(
    getAllObjectsForSelectedOrganizationQuery(s, Collections.OBJECTIVES),
    'useObjectives',
    toObjective,
    refetch
  )
export const useUsersForOrganization = (state: State) =>
  useFirestoreQueryDeprecated<User[]>(getAllUsersForSelectedOrganizationQuery(state), 'useUsersForOrganization')
export const useIndicators = (s: State, refetch = false) =>
  useFirestoreQuery<KeyResult[]>(getAllObjectsForSelectedOrganizationQuery(s, Collections.INDICATORS), {
    fnName: 'useIndicators',
    refetch
  })
export const useActivities = (s: State, refetch = false) =>
  useFirestoreQuery<Activity[]>(getAllObjectsForSelectedOrganizationQuery(s, Collections.ACTIVITIES), {
    fnName: 'useActivities',
    refetch
  })
export const useConnections = (s: State) =>
  useFirestoreQuery<Connection[]>(getAllObjectsForSelectedOrganizationQuery(s, Collections.CONNECTIONS), {
    fnName: 'useConnections'
  })

export const useDatasources = (s: State) =>
  useFirestoreQuery<Datasource[]>(getAllObjectsForSelectedOrganizationQuery(s, Collections.DATA_SOURCES), {
    fnName: 'useDatasources'
  })

export const useEvents = <T>(s: State, refetch = false) =>
  useFirestoreQuery<Event<T>[]>(getAddIndicatorValueEvents(s.selectedOrganizationId, db), {
    fnName: 'useEvents',
    refetch
  })

export const useDashboardLinks = (s: State) =>
  useFirestoreQuery<DashboardLink[]>(getAllObjectsForSelectedOrganizationQuery(s, Collections.DASHBOARD_LINKS), {
    fnName: 'useDashboardLinks'
  })

export const useMyFocusRows = (s: State) =>
  useFirestoreQuery<MyFocus[]>(getAllObjectsForSelectedOrganizationQuery(s, Collections.MY_FOCUS), {
    fnName: 'useMyFocusRows'
  })

export const useTodos = (s: State) =>
  useFirestoreQuery<Todo[]>(getAllObjectsForSelectedOrganizationQuery(s, Collections.TODOS), {
    fnName: 'useTodos'
  })

// Single Objects
export const useOrganization = (s: State) =>
  useFirestoreQuery<Organization>(getObjectQuery(s.selectedOrganizationId, Collections.ORGANIZATIONS), {
    fnName: 'useOrganization',
    mapFn: toOrganization,
    enabled: !!s.selectedOrganizationId
  })
export const useEvent = <T>(id: string) =>
  useFirestoreQuery<Event<T>>(getObjectQuery(id, Collections.EVENTS), { fnName: 'useEvent', enabled: !!id })
export const useCustomAuthToken = (id: string) =>
  useFirestoreQuery<CustomAuthToken>(getObjectQuery(id, Collections.CUSTOM_AUTH_TOKENS), {
    fnName: 'useCustomAuthToken',
    enabled: !!id
  })
export const useUser = (id: string) =>
  useFirestoreQuery<User>(getObjectQuery(id, Collections.USERS), { fnName: 'useUser', enabled: !!id })
export const useConversation = (id: string) =>
  useFirestoreQuery<Conversation>(getObjectQuery(id, Collections.CONVERSATIONS), {
    fnName: 'useConversation',
    enabled: !!id
  })

// Super Admin
export const useUsersAsSuperAdmin = () =>
  useFirestoreQueryDeprecated<User[]>(getObjectsQuery(Collections.USERS), 'useUsersAsSuperAdmin')
export const useAllOrganizationsAsSuperAdmin = () =>
  useFirestoreQueryDeprecated<Organization[]>(
    getObjectsQuery(Collections.ORGANIZATIONS),
    'usedAllOrganizationsAsSuperAdmin'
  )
