import { GrowthIndicatorValue, GrowthIndicatorValues } from '../ygdrasil/types/types'
import { getValueTextGrowthView } from '../ygdrasil/libs/GraphHelper'
import Texts from '../ygdrasil/libs/Texts'
import { NoWrapFigmaText } from './StyledComponents'
import { isNumber } from '../ygdrasil/libs/Common'

type Props = {
  value: GrowthIndicatorValue
  values: GrowthIndicatorValues
}

export const GrowthViewGrowthIndicatorValueGoal = (props: Props) => {
  const { value } = props
  // const previousValue = getPreviousValue(props)
  const previousValue = value.goal // Deprecated plz remove, (PRD-1391)
  const goalValue = isNumber(value.goal) ? value.goal : previousValue

  if (!isNumber(goalValue)) return <NoWrapFigmaText textKey={Texts.cssSuccessViewGoalText} text={'-'} />

  const textKey = isNumber(value.goal) ? Texts.cssSuccessViewChangeText : Texts.cssSuccessViewNoChangeText

  const text = getValueTextGrowthView(goalValue as number)
  return <NoWrapFigmaText textKey={textKey} text={text} />
}
