import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import shadows from '../enums/Shadows'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useResponsive from '../hooks/useResponsive'
import { shouldShowStrategicPillarsInDashboard } from '../libs/StrategyViewObjectivesHeaderStrategiPillarsHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import Colors from '../ygdrasil/libs/Colors'
import { adjust } from '../ygdrasil/libs/Common'
import { updateObjective } from '../ygdrasil/libs/DBApiHandler'
import Images from '../ygdrasil/libs/Images'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaImage from './FigmaImage'
import { BIG_ICON_SIZE, CloseIcon } from './Icons'
import Modal from './Modal'
import Roadmap from './Roadmap'
import StrategyViewObjectivesFocusedObjectivesView from './StrategyViewObjectivesFocusedObjectivesView'
import StrategyViewObjectivesHeader from './StrategyViewObjectivesHeader'
import { StrategyViewObjectivesHeaderStrategiPillars as DashboardObjectivesHeaderStrategiPillarsExp } from './StrategyViewObjectivesHeaderStrategiPillars'
import { StrategyViewObjectivesHeaderToggle } from './StrategyViewObjectivesHeaderToggle'

const DarkStrategyObjectiveColors = [
  '#BF360C',
  '#FF6F00',
  '#827717',
  '#33691E',
  '#004D40',
  '#006064',
  '#0D47A1',
  '#1A237E',
  '#311B92',
  '#4A148C',
  '#B71C1C'
]

export function StrategyViewObjectives() {
  const { state, setState } = useAppState()
  const { focusedObjectiveId } = state
  const { data: org } = useOrganization(state)
  const showStrategicPillars = shouldShowStrategicPillarsInDashboard(org)
  const { isTablet } = useResponsive()
  const { allObjectives, isLoading } = useAllItems()
  const currentObjective = allObjectives.find((o) => o._id === focusedObjectiveId)
  const [chosenColor, setChosenColor] = useState<any>('')

  useEffect(() => {
    setChosenColor(!!isLoading ? '' : currentObjective?.backgroundColor)
  }, [isLoading, focusedObjectiveId])

  const handleObjectiveColorChange = (color: string) => {
    if (!focusedObjectiveId) return
    if (!currentObjective) return
    updateObjective({ ...currentObjective, backgroundColor: color }).then(() =>
      setState({ ...state, focusedObjectiveId: null })
    )
  }

  return (
    <Box top spacing={smallSpacing} fullWidth>
      {focusedObjectiveId && (
        <Modal onClose={() => setState({ ...state, focusedObjectiveId: null })}>
          <Box fullPadding align="center" position="relative">
            <FigmaText textKey={Texts.indicatorDetailPageIndicatorSummary} text={currentObjective?.name} />
            <Box
              onClick={() => setState({ ...state, focusedObjectiveId: null })}
              pointer
              position="absolute"
              style={{ top: 20, right: 0 }}
            >
              <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
            </Box>
            <Box fullWidth top bottom>
              <FigmaText textKey={Texts.objectiveColorSelectionText} />
            </Box>
            <Box
              fullWidth
              direction="row"
              align="flex-end"
              justify="center"
              bottom
              gap="8px"
              style={{ flexWrap: 'wrap' }}
            >
              {DarkStrategyObjectiveColors.map((color) => {
                const isSelected = chosenColor === color && focusedObjectiveId === currentObjective?._id
                return (
                  <Box key={color}>
                    {isSelected && (
                      <Box>
                        <FigmaImage imageKey={Images.greenCheckIcon} />
                      </Box>
                    )}
                    <Color
                      pointer
                      isSelected={isSelected}
                      color={color}
                      fullPadding
                      spacing="8px"
                      onClick={() => setChosenColor(color)}
                    ></Color>
                  </Box>
                )
              })}
            </Box>
            <Box top fullWidth align="flex-end">
              <Button
                textKey={Texts.genericDialogButtonPrimaryButtonText}
                onClick={() => chosenColor && handleObjectiveColorChange(chosenColor)}
              />
            </Box>
          </Box>
        </Modal>
      )}
      <Box direction="row" align="center" justify="space-between" fullWidth>
        <Box>
          {showStrategicPillars && !isTablet && (
            <Box bottom spacing={smallSpacing}>
              <DashboardObjectivesHeaderStrategiPillarsExp />
            </Box>
          )}
          <StrategyViewObjectivesHeader />
        </Box>
        {!isTablet && (
          <Box alignSelf="flex-start" minWidth="auto">
            <StrategyViewObjectivesHeaderToggle />
          </Box>
        )}
      </Box>

      <Box fullWidth>
        {state.selectedDashboardView === 'roadmap' && <Roadmap />}
        {state.selectedDashboardView !== 'roadmap' && <StrategyViewObjectivesFocusedObjectivesView />}
      </Box>
    </Box>
  )
}

const Color = styled(Box)<{ isSelected: boolean; color: string }>`
  border-radius: 50%;
  background-color: ${({ color }) => color};
  width: 48px;
  height: 48px;
  box-shadow: ${shadows.soft};
  border: ${({ isSelected, color }) => (isSelected ? `2px solid ${adjust(color, -50)}` : 'none')};
`
