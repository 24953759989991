/* eslint-disable react/display-name */
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { useMsal as _useMsal } from '@azure/msal-react'
import invariant from 'invariant'
import { useEffect } from 'react'
import { MsalLogoutRequest } from '../libs/MsalHelper'
import { generateCodeVerifier } from '../libs/Oauth2Helper'
import config from '../Config'
import { AZURE_APPLICATION_CLIENT_ID, MS_AUTHORITY_URL, MS_SCOPES } from '../ygdrasil/libs/Consts'
import RoutePath from '../ygdrasil/libs/RoutePath'
import useAppState, { useUser } from './useAppState'

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: AZURE_APPLICATION_CLIENT_ID
  }
}

export const MsalPublicClientApplication = new PublicClientApplication(configuration)

export const MSAL_POPUP_WINDOW = 'popupwindow'

export const useMsal = () => {
  const { state, setState } = useAppState()
  const { instance } = _useMsal()
  const user = useUser()

  return {
    logout: () => instance.logout(MsalLogoutRequest),
    openPopupWindow: () => {
      const codeVerifier = generateCodeVerifier()
      setState({ ...state, codeVerifier })
      openPopupWindow({ codeVerifier, loginHint: user?.email })
    },
    instance
  }
}

export const MSAL_REDIRECT_URL = `${config.appUrl}${RoutePath.REDIRECT}`

const openPopupWindow = ({ redirecturl = MSAL_REDIRECT_URL, codeVerifier, loginHint }) => {
  const templateAuthUrl =
    MS_AUTHORITY_URL +
    '/oauth2/v2.0/authorize?response_type=code&client_id=' +
    AZURE_APPLICATION_CLIENT_ID +
    '&scope=' +
    MS_SCOPES.join(' ') +
    '&redirect_uri=' +
    redirecturl +
    '&response_mode=query' +
    '&code_challenge=' +
    codeVerifier +
    '&login_hint=' +
    loginHint

  window.open(
    templateAuthUrl,
    MSAL_POPUP_WINDOW,
    `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100`
  )
}
