import { useUser } from '../ygdrasil/libs/QueryHooks'
import { BIG_ICON_SIZE } from './Icons'
import Tooltip from './Tooltip'
import { UserWidgetViewLead } from './UserWidget'

import { Item, ItemData } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Box from './Box'
import { LeadSelectFieldMenu } from './LeadSelectFieldMenu'
import React from 'react'
import useAppState from '../hooks/useAppState'
import { updateActivity, updateIndicator, updateObjective } from '../ygdrasil/libs/DBApiHandler'
import { Activity, KeyResult, Objective } from '../ygdrasil/types/types'
import { getLeadMetaData } from '../libs/EditKeyResultsSectionHelper'
import { withStopProgagation } from '../libs/HtmlHelper'
import { useCanEditIndicator } from '../ygdrasil/libs/PermissionsHelper'
import { PictureSizes } from '../ygdrasil/hooks/useUrlForProfilePicture'

export function StrategyViewObjectiveListRowLead(props: { item: Item; onSelectLeadUserId?: (item: Item) => unknown }) {
  const { item } = props
  const { data } = item
  const canEditIndicator = useCanEditIndicator()
  const { leadUserId: value } = data
  const { state } = useAppState()
  const _value = value || 'aUserId'
  const { data: user } = useUser(_value)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const onClick = withStopProgagation((e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (!canEditIndicator) return
    setAnchorEl(e.currentTarget)
  })

  const onClose = (): void => setAnchorEl(null)

  const onSelectLeadUserId = (leadUserId: string) => {
    onClose()
    const leadProps = { leadUserId, leadMetaData: getLeadMetaData(state) }
    if (data.searchType === 'keyResult') updateIndicator({ ...(data.data as KeyResult), ...leadProps })
    if (data.searchType === 'objective') updateObjective({ ...(data.data as Objective), ...leadProps })
    if (data.searchType === 'activity') updateActivity({ ...(data.data as Activity), ...leadProps })

    props.onSelectLeadUserId?.({ ...item, data: { ...data, ...leadProps, data: { ...data.data, ...leadProps } } })
  }

  return (
    <Box pointer width={`${BIG_ICON_SIZE + 2}px`}>
      <Tooltip title={user?.name || ''}>
        <Box onClick={onClick}>
          <UserWidgetViewLead userId={_value} size={BIG_ICON_SIZE} />
        </Box>
      </Tooltip>
      <LeadSelectFieldMenu value={value || ''} onClose={onClose} onClick={onSelectLeadUserId} anchorEl={anchorEl} />
    </Box>
  )
}
