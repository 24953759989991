import Divider from '@mui/material/Divider'
import Menu, { MenuProps } from '@mui/material/Menu'
import React from 'react'
import useAppState from '../hooks/useAppState'
import { withStopProgagation } from '../libs/HtmlHelper'
import { addStar } from '../ygdrasil/libs/DashboardHelper'
import Term from '../ygdrasil/enums/Term'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { DashboardLink } from '../ygdrasil/types/types'
import { ModalAreYouSure } from './ModalAreYouSure'
import { ModalEditDashboardLink } from './ModalEditDashboardLink'
import { MenuItem } from './MenuItem'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  onClickShowAllIndicators: () => void
  onClickCopyMenuLink: () => void
  onDeleteDashboard: () => void
  onClickSaveDashboard: () => void
  onCreateUpateDashboardLink: () => void
  dashboardLink: Partial<DashboardLink>
  isEditingDashboardLink: boolean
} & Partial<MenuProps>

export default function IndicatorDashboardMenu(props: Props) {
  const { state } = useAppState()
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [showEditModal, setShowEditModal] = React.useState(false)
  const { data: org } = useOrganization(state)

  const {
    onClose: onCloseMenu,
    anchorEl,
    dashboardLink,
    isEditingDashboardLink,
    onCreateUpateDashboardLink,
    ...rest
  } = props

  const onClickShowAllIndicators = withStopProgagation(() => {
    onCloseMenu()
    props.onClickShowAllIndicators()
  })

  const onClickCopyMenuLink = withStopProgagation(() => {
    onCloseMenu()
    props.onClickCopyMenuLink()
  })

  const onClickDeleteDashboard = withStopProgagation(() => {
    onCloseMenu()
    setShowDeleteModal(true)
  })

  const onClickEditDashboard = withStopProgagation(() => {
    onCloseMenu()
    setShowEditModal(true)
  })

  const onClickSaveDashboard = withStopProgagation(() => {
    onCloseMenu()
    props.onClickSaveDashboard()
  })

  const onDeleteDashboard = () => Promise.resolve(props.onDeleteDashboard())

  return (
    <div>
      <Menu {...rest} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onCloseMenu} disableAutoFocusItem>
        <MenuItem onClick={onClickCopyMenuLink}>{getTextForTextKey(Texts.dashboardContextMenuCopyLink, org)}</MenuItem>
        <MenuItem onClick={onClickShowAllIndicators}>
          {getTextForTextKey(Texts.dashboardContextMenuShowAll, org)}
        </MenuItem>
        <Divider />
        <MenuItem onClick={onClickEditDashboard}>
          {getTextForTextKey(Texts.dashboardContextMenuCreateNewDashboard, org)}
        </MenuItem>

        {!!dashboardLink._id && (
          <>
            <Divider />
            {isEditingDashboardLink && (
              <MenuItem
                onClick={onClickSaveDashboard}
                text={addStar(getTextForTextKey(Texts.dashboardContextMenuSaveDashboard, org), isEditingDashboardLink)}
              ></MenuItem>
            )}
            <MenuItem
              onClick={onClickEditDashboard}
              text={getTextForTextKey(Texts.dashboardContextMenuUpdateDashboardName, org)}
            ></MenuItem>
            <Divider />
            <MenuItem
              onClick={onClickDeleteDashboard}
              text={getTextForTextKey(Texts.dashboardContextMenuDeleteDashboard, org)}
            ></MenuItem>
          </>
        )}
      </Menu>
      {showDeleteModal && (
        <ModalAreYouSure
          onClose={() => setShowDeleteModal(false)}
          onDelete={onDeleteDashboard}
          term={Term.DASHBOARD}
          name={dashboardLink?.name || ''}
        />
      )}
      {showEditModal && (
        <ModalEditDashboardLink
          onClose={() => setShowEditModal(false)}
          dashboardLink={dashboardLink}
          onCreateUpateDashboardLink={onCreateUpateDashboardLink}
        />
      )}
    </div>
  )
}
