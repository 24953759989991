import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { smallSpacing } from '../enums/Spacings'
import ZIndices from '../enums/Zindices'
import useAppState from '../hooks/useAppState'
import { RequiredTextValidation, UrlValidation } from '../libs/ValidationHelper'
import EventCategories from '../ygdrasil/enums/EventCategories'
import { getSuggestStrategicPillarConversation } from '../ygdrasil/libs/ConversationHelper'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { Organization } from '../ygdrasil/types/types'
import Box from './Box'
import { BusinessPlanDrawerFormRichTextEditor } from './BusinessPlanDrawerFormRichTextEditor'
import { DrawerFooterButtons } from './DrawerFooterButtons'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { SuggestionButton } from './SuggestionButton'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

export enum FormRowType {
  URL = 'URL',
  REQUIRED_TEXT = 'REQUIRED_TEXT',
  RICH_TEXT = 'RICH_TEXT'
}

export type BusinessPlanDrawerFormTypeRow = {
  labelTextKey: AntiloopTextType
  name: string
  multiline?: boolean
  type?: FormRowType
}

export type BusinessPlanDrawerFormType = {
  headerTextKey: AntiloopTextType
  rows: BusinessPlanDrawerFormTypeRow[]
  onSubmit: (values: any, org: Organization) => Organization
  getIntitialValues: (org: Organization) => object
  onDelete?: (org: Organization) => Organization
  eventCategory: EventCategories
  removeEventCategory?: EventCategories
  suggestionConfig?: {
    onNewSuggestion: (text: string, formProps: FormikProps<object>) => unknown
    isVisible: (org: FormikProps<any>) => boolean
  }
}

type Props = BusinessPlanDrawerFormType & {
  headerTextKey: AntiloopTextType
  rows: { labelTextKey: AntiloopTextType; name: string }[]
  onCancel: (formProps: FormikProps<object>) => unknown
  onSubmit: (values: any) => any
  initialValues: object
  onDelete?: () => unknown
}

export function BusinessPlanDrawerForm({
  rows,
  onSubmit,
  onCancel,
  onDelete,
  headerTextKey,
  initialValues,
  suggestionConfig
}: Props) {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const validationSchema = rows.reduce((a, row) => {
    if (row.type === FormRowType.URL) a[row.name] = UrlValidation()
    if (row.type === FormRowType.REQUIRED_TEXT) a[row.name] = RequiredTextValidation()
    return a
  }, {})

  if (!org) return null

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={Yup.object().shape(validationSchema)}>
      {(formProps) => {
        return (
          <Box fullWidth fullHeight>
            <Box fullWidth fullHeight fullPadding>
              <Box bottom>
                <FigmaTextForStrategyModel textKey={headerTextKey} />
              </Box>
              {rows.map((props, key, arr) => {
                const { labelTextKey, name, multiline, type } = props
                const isRichtextEditor = type === FormRowType.RICH_TEXT
                const zIndex = isRichtextEditor ? ZIndices.high : ZIndices.regular

                return (
                  <Box fullWidth bottom key={key} spacing={smallSpacing} style={{ zIndex }}>
                    {isRichtextEditor && <BusinessPlanDrawerFormRichTextEditor {...props} formProps={formProps} />}
                    {type !== FormRowType.RICH_TEXT && (
                      <TextFieldWithLabelFormik
                        fullWidth
                        labelTextKey={labelTextKey}
                        labelText={getTextForTextKey(labelTextKey, org)}
                        name={name}
                        formProps={formProps}
                        placeholderTextKey={Texts.allInputTypeHereText}
                        multiline={multiline}
                        rows={multiline ? 3 : 1}
                        autoFocus={arr.length === 1} // if only one element then autoFocus
                      />
                    )}
                  </Box>
                )
              })}
              {!!suggestionConfig && suggestionConfig.isVisible(formProps) && (
                <Box bottom spacing={smallSpacing} fullWidth>
                  <SuggestionButton
                    onNewSuggestion={(suggestion) => suggestionConfig.onNewSuggestion(suggestion, formProps)}
                    initialConversation={getSuggestStrategicPillarConversation(
                      (formProps.values as any).description as string,
                      state
                    )}
                  />
                </Box>
              )}
            </Box>

            <DrawerFooterButtons
              onClickCancel={() => onCancel(formProps)}
              onClickDelete={onDelete}
              onClickContinue={formProps.handleSubmit as any}
              style={{ visibility: 'hidden' }}
            />
            <DrawerFooterButtons
              onClickCancel={() => onCancel(formProps)}
              onClickDelete={onDelete}
              onClickContinue={formProps.handleSubmit as any}
              style={{ position: 'fixed', bottom: 0 }}
            />
          </Box>
        )
      }}
    </Formik>
  )
}
