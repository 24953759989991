import useAppState from '../hooks/useAppState'
import { isDefault } from '../libs/SettingsApplicationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { StrategyModel } from '../ygdrasil/enums/StrategyModel'
import { updateOrganization } from '../ygdrasil/libs/DBApiHandler'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Language } from '../ygdrasil/types/Antiloop'
import SettingsApplicationStrategyModelTab from './SettingsApplicationStrategyModelTab'
import Tabs from './Tabs'

const cssUnSelectedTextKey = Texts.cssButtonSelectionUnselected
const cssSelectedTextKey = Texts.cssButtonSelectionSelected

export default function SettingsApplicationTabs({ language }: { language: Language }) {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)

  if (!org) return null

  const onChangeStrategyModel = (strategyModel: StrategyModel) => {
    if (!org) return
    return updateOrganization({ ...org, strategyModel })
  }

  return (
    <Tabs
      tabs={[
        {
          default: isDefault(org, StrategyModel.DEFAULT),
          labelElement: (
            <FigmaText
              textKey={cssUnSelectedTextKey}
              text={getFigmaText(Texts.applicationSettingsStrategyModelOptionDefault)}
            />
          ),
          labelSelectedElement: (
            <FigmaText
              textKey={cssSelectedTextKey}
              text={getFigmaText(Texts.applicationSettingsStrategyModelOptionDefault)}
            />
          ),
          contentElement: (
            <SettingsApplicationStrategyModelTab strategyModel={StrategyModel.DEFAULT} language={language} />
          ),
          onChangeTab: () => onChangeStrategyModel(StrategyModel.DEFAULT)
        },
        {
          default: isDefault(org, StrategyModel.OKR),
          labelElement: (
            <FigmaText
              textKey={cssUnSelectedTextKey}
              text={getFigmaText(Texts.applicationSettingsStrategyModelOptionOkr)}
            />
          ),
          labelSelectedElement: (
            <FigmaText
              textKey={cssSelectedTextKey}
              text={getFigmaText(Texts.applicationSettingsStrategyModelOptionOkr)}
            />
          ),
          contentElement: <SettingsApplicationStrategyModelTab strategyModel={StrategyModel.OKR} language={language} />,
          onChangeTab: () => onChangeStrategyModel(StrategyModel.OKR)
        },
        {
          default: isDefault(org, StrategyModel.CUSTOM),
          labelElement: (
            <FigmaText
              textKey={cssUnSelectedTextKey}
              text={getFigmaText(Texts.applicationSettingsStrategyModelOptionCustom)}
            />
          ),
          labelSelectedElement: (
            <FigmaText
              textKey={cssSelectedTextKey}
              text={getFigmaText(Texts.applicationSettingsStrategyModelOptionCustom)}
            />
          ),
          contentElement: (
            <SettingsApplicationStrategyModelTab strategyModel={StrategyModel.CUSTOM} language={language} />
          ),
          onChangeTab: () => onChangeStrategyModel(StrategyModel.CUSTOM)
        }
      ]}
    />
  )
}
