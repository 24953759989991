import React from 'react'
import styled from '@emotion/styled'
import { minSpacing, tinySpacing } from '../enums/Spacings'
import { PROGRESS_STATUS_CIRCLE_SIZE } from '../libs/HardCodedSizes'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { NoWrapFigmaText } from './StyledComponents'
import { User, OrgUserStatus } from '../ygdrasil/types/types'
import Colors from '../ygdrasil/libs/Colors'
import useAppState from '../hooks/useAppState'

export function SettingsMyOrganizationUserStatusColumn({ user }: { user: User }) {
  const { state } = useAppState()
  const { selectedOrganizationId } = state
  const status = user.orgUserInfo?.[selectedOrganizationId]?.status
  const textKey = getTextKey(status)
  return (
    <Box direction="row" align="center">
      <StatusCircle type={status}></StatusCircle>
      <Box left spacing={tinySpacing} direction="row" align="flex-end">
        <NoWrapFigmaText textKey={textKey} />
      </Box>
    </Box>
  )
}

const StatusCircle = styled.span<{ type?: OrgUserStatus }>`
  height: ${PROGRESS_STATUS_CIRCLE_SIZE};
  width: ${PROGRESS_STATUS_CIRCLE_SIZE};
  background-color: ${({ type }) => getColor(type)};
  border-radius: 50%;
  display: inline-block;
  margin-right: ${minSpacing};
`

const getColor = (type?: OrgUserStatus) => {
  if (type === OrgUserStatus.INVITED) return Colors.primary20
  if (type === OrgUserStatus.DISABLED) return Colors.neutralUnselected
  return Colors.good
}
function getTextKey(type?: OrgUserStatus) {
  if (type === OrgUserStatus.INVITED) return Texts.adminUserListInvitedLabel
  if (type === OrgUserStatus.DISABLED) return Texts.adminUserListDisabledLabel
  return Texts.adminUserListActiveLabel
}
