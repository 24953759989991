import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { openSwotDrawer } from '../libs/BusinessPlanHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText as g } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { SWOTItem } from '../ygdrasil/types/types'
import Box from './Box'
import { BusinessPlanDrawerFormType } from './BusinessPlanDrawerForm'
import {
  BUSINESS_PLAN_DRAWER_FORM_ADD_VISION,
  BUSINESS_PLAN_DRAWER_FORM_EDIT_VISION,
  createBusienssPlanDrawerFormAddStrategicPillar,
  createBusienssPlanDrawerFormAddValue,
  createBusienssPlanDrawerFormEditStrategicPillar,
  createBusienssPlanDrawerFormEditValue
} from './BusinessPlanDrawers'
import Button from './Button'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { BIG_ICON_SIZE, CloseIcon } from './Icons'
import { MenuStatementContent } from './MenuStatement'
import { RichText } from './RichText'
import { LinkStyles } from './StyledComponents'
import Tooltip from './Tooltip'

type Props = {
  onOpenForm: (businessPlanDrawerProps: BusinessPlanDrawerFormType) => unknown
  onClose: () => unknown
}

export function BusinessPlanDrawerContent({ onOpenForm, onClose }: Props) {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const hasValues = !!org?.businessPlan?.values[0]
  const hasStrategicPillars = !!org?.businessPlan?.strategicPillars[0]
  const config = useLoggedInConfig()

  const textKey = hasStrategicPillars
    ? Texts.rightPanelHeaderValuesAddStrategicPillar
    : Texts.addYourFirstStrategicPillar
  return (
    <Box fullWidth left right top>
      <CloseDrawerContainer onClick={onClose}>
        <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
      </CloseDrawerContainer>

      <Box bottom fullWidth>
        <Box bottom spacing={smallSpacing}>
          <FigmaTextForStrategyModel textKey={Texts.rightPanelHeaderVision} />
        </Box>
        {org?.vision && (
          <Box fullWidth pointer>
            <MenuStatementContent
              statement={org?.vision}
              onClickContent={() => onOpenForm(BUSINESS_PLAN_DRAWER_FORM_EDIT_VISION)}
            />
          </Box>
        )}
        {!org?.vision && (
          <Button
            buttonType="empty_state"
            textKey={Texts.rightPanelHeaderAddVision}
            onClick={() => onOpenForm(BUSINESS_PLAN_DRAWER_FORM_ADD_VISION)}
          />
        )}
      </Box>
      <Box bottom fullWidth>
        <Box bottom spacing={smallSpacing}>
          <FigmaTextForStrategyModel textKey={Texts.rightPanelHeaderValues} />
        </Box>
        {hasValues &&
          org?.businessPlan.values.map((value, key) => (
            <Box key={key} onClick={() => onOpenForm(createBusienssPlanDrawerFormEditValue(key))} link>
              <FigmaText textKey={Texts.rightPanelValuesListItem} text={`· ${value}`} />
            </Box>
          ))}
        <Box fullWidth top spacing={smallSpacing}>
          <Button
            buttonType="empty_state"
            textKey={hasValues ? Texts.rightPanelHeaderValuesAddNewValue : Texts.rightPanelHeaderValuesAddValues}
            onClick={() => onOpenForm(createBusienssPlanDrawerFormAddValue(org?.businessPlan.values.length || 0))}
          />
        </Box>
      </Box>
      <Box bottom fullWidth>
        <Box bottom spacing={smallSpacing}>
          <FigmaTextForStrategyModel textKey={Texts.rightPanelHeaderStrategicPillars} />
        </Box>
        {hasStrategicPillars &&
          org?.businessPlan.strategicPillars.map((strategicPillar, key) => (
            <Box key={key} onClick={() => onOpenForm(createBusienssPlanDrawerFormEditStrategicPillar(key))} link>
              <FigmaText textKey={Texts.rightPanelValuesListItem} text={`· ${strategicPillar.name}`} />
            </Box>
          ))}
        <Box fullWidth top spacing={smallSpacing}>
          <Tooltip
            title={org?.businessPlan?.strategicPillars?.length === 8 ? ' ' : ''}
            titleElement={
              <FigmaTextForStrategyModel textKey={Texts.rightPanelHeaderValuesAddStrategicPillarsTooltipText} />
            }
            placement="top-start"
          >
            <AddPillarsButton
              buttonType="empty_state"
              isDisabled={org?.businessPlan?.strategicPillars?.length === 8}
              textKey={textKey}
              onClick={() =>
                onOpenForm(
                  createBusienssPlanDrawerFormAddStrategicPillar(org?.businessPlan.strategicPillars.length || 0)
                )
              }
            >
              <FigmaTextForStrategyModel textKey={textKey} />
            </AddPillarsButton>
          </Tooltip>
        </Box>
      </Box>
      <Box bottom fullWidth>
        <Box bottom spacing={smallSpacing}>
          <FigmaTextForStrategyModel textKey={Texts.rightPanelHeaderSwot} />
        </Box>
        {org?.businessPlan.swot && (
          <Box fullWidth pointer>
            <Box bottom spacing={smallSpacing} onClick={() => openSwotDrawer(org, SWOTItem.Strength, config)} pointer>
              <Box link>
                <FigmaText textKey={Texts.editSwotWeaknessHeader} text={g(Texts.editSwotStrengthHeader)} />
              </Box>
              <RichText
                fallbackTextKey={Texts.rightPanelValuesListItem}
                richText={`${org?.businessPlan.swot.strength}`}
                fallbackText={`${org?.businessPlan.swot.strength}`}
              />
            </Box>
            <Box bottom spacing={smallSpacing} onClick={() => openSwotDrawer(org, SWOTItem.Weaknesses, config)} pointer>
              <Box link>
                <FigmaTextForStrategyModel textKey={Texts.editSwotWeaknessHeader} />
              </Box>
              <RichText
                fallbackTextKey={Texts.rightPanelValuesListItem}
                richText={`${org?.businessPlan.swot.weakness}`}
                fallbackText={`${org?.businessPlan.swot.weakness}`}
              />
            </Box>
            <Box
              bottom
              spacing={smallSpacing}
              onClick={() => openSwotDrawer(org, SWOTItem.Opportunities, config)}
              pointer
            >
              <Box link>
                <FigmaTextForStrategyModel textKey={Texts.editSwotOpportunityHeader} />
              </Box>
              <RichText
                fallbackTextKey={Texts.rightPanelValuesListItem}
                richText={`${org?.businessPlan.swot.opportunity}`}
                fallbackText={`${org?.businessPlan.swot.opportunity}`}
              />
            </Box>
            <Box bottom spacing={smallSpacing} onClick={() => openSwotDrawer(org, SWOTItem.Threats, config)} pointer>
              <Box link>
                <FigmaTextForStrategyModel textKey={Texts.editSwotThreatsHeader} />
              </Box>
              <RichText
                fallbackTextKey={Texts.rightPanelValuesListItem}
                richText={`${org?.businessPlan.swot.threats}`}
                fallbackText={`${org?.businessPlan.swot.threats}`}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const CloseDrawerContainer = styled.div`
  position: absolute;
  top: ${tinySpacing};
  right: ${tinySpacing};
  ${LinkStyles}
`
const AddPillarsButton = styled(Button)<{ isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${Colors.neutral95};
      border-color: ${Colors.gray};
      span {
        color: ${Colors.neutral30} !important;
      }
      pointer-events: none;
    `};
`
