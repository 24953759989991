import styled from '@emotion/styled'
import { minSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useShouldShowExpandedMenu from '../hooks/useShouldShowExpandedMenu'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'
import { OpacityTransitionContainer } from './OpacityTransitionContainer'
import { MenuCollapseIcon, MenuExpandIcon } from './Icons'

export default function MenuExpandCollapseLine() {
  const { state, setState } = useAppState()
  const shouldShowExpandedMenu = useShouldShowExpandedMenu()
  const onClick = () => setState({ ...state, shouldShowExpandedMenu: !shouldShowExpandedMenu })

  return (
    <OpacityTransitionContainer fullHeight pointer delay={0.1}>
      <Box fullHeight position="relative" align="center" justify="center" onClick={onClick} pointer>
        <Line fullHeight position="absolute"></Line>
        <Box top bottom right style={{ backgroundColor: Colors.white }} spacing={minSpacing}>
          {shouldShowExpandedMenu ? <MenuCollapseIcon /> : <MenuExpandIcon />}
        </Box>
      </Box>
    </OpacityTransitionContainer>
  )
}

const Line = styled(Box)`
  background-color: ${Colors.primaryBase};
  width: 3px;
`
