import { Formik } from 'formik'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import { bigSpacing, defaultSpacing, smallSpacing } from '../enums/Spacings'
import { logOnboardingEvent } from '../ygdrasil/libs/FirebaseHelper'
import { ONBOARDING_NAME_MOBILE_PICTURE_OPACITY, ONBOARDING_NAME_MOBILE_PICTURE_POSITION } from '../libs/HardCodedSizes'
import { RequiredNameValidationDeprecated } from '../libs/ValidationHelper'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { OnboardingViewProps } from '../ygdrasil/types/Onboarding'
import BackButtonMobileOnboarding from './BackButtonMobileOnboarding'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import OnboardingMobileWrapper from './OnboardingMobileWrapper'
import OnboardingPaginationComponent from './OnboardingPaginationComponent'
import { getOnboardingTellUsInitialValues } from '../libs/OnboardingTellUsHelper'
import TextFieldFormikErrorComponent from './TextFieldFormikErrorComponent'
import { TextFieldMobile } from './TextFieldMobile'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

const ValidationSchema = Yup.object().shape({
  name: RequiredNameValidationDeprecated('name'),
  vision: RequiredNameValidationDeprecated('vision')
})

export default function OnboardingTellUsMobile(props: OnboardingViewProps) {
  const { onPressContinue, state, onClickBack } = props
  const initialValues = getOnboardingTellUsInitialValues(state)

  const onSubmit = (data) => onPressContinue({ ...state, data: { ...state.data, ...data } })

  useEffect(() => {
    logOnboardingEvent(AnalyticsEvent.ONBOARDING_STARTED, state)
  }, [])

  return (
    <OnboardingMobileWrapper
      content={() => (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
          {(formProps) => (
            <Box align="center" fullWidth top spacing={defaultSpacing}>
              <Box fullWidth align="flex-start" justify="center" left spacing={smallSpacing}>
                <BackButtonMobileOnboarding
                  textKey={Texts.onboardingMobileBackButtonText}
                  onClickBack={() => onClickBack(state)}
                />
              </Box>

              <Box fullWidth top spacing={`${Images.imageArrows.height}px`}>
                <StyledBox position="absolute" right top spacing={bigSpacing}>
                  <FigmaImageContainer imageKey={Images.imageArrows} />
                </StyledBox>
                <Box fullWidth top spacing={bigSpacing}>
                  <Box fullWidth align="center" left right spacing={smallSpacing}>
                    <Box fullWidth bottom>
                      <TextFieldWithLabelFormik
                        labelTextKey={Texts.onboardingMobileOrgNameHelperText}
                        formProps={formProps}
                        name="name"
                        component={TextFieldMobile}
                        fullWidth
                        isMobile
                        ErrorComponent={TextFieldFormikErrorComponent}
                      />
                    </Box>
                    <Box fullWidth>
                      <TextFieldWithLabelFormik
                        labelTextKey={Texts.onboardingMobileVisionHelperText}
                        formProps={formProps}
                        name="vision"
                        component={TextFieldMobile}
                        fullWidth
                        isMobile
                        ErrorComponent={TextFieldFormikErrorComponent}
                      />
                    </Box>
                    <Box fullWidth align="center" top bottom>
                      <Button
                        buttonType="white"
                        onClick={formProps.handleSubmit as any}
                        textKey={Texts.onboardingContinueButtonText}
                      ></Button>
                    </Box>
                    <OnboardingPaginationComponent
                      opacityFirstDot={100}
                      opacitySecondDot={100}
                      opacityThirdDot={40}
                      opacityForthDot={40}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Formik>
      )}
    />
  )
}

const StyledBox = styled(Box)`
  opacity: ${ONBOARDING_NAME_MOBILE_PICTURE_OPACITY}%;
  top: ${ONBOARDING_NAME_MOBILE_PICTURE_POSITION}%;
`
