import { ClickAwayListener } from '@mui/material'
import { FormikProps } from 'formik'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useIsBigScreen } from '../hooks/useMediaQuery'
import { getMaxWidth } from '../libs/GrowthViewGrowthIndicatorRowNameHelper'
import { onClickIndicatorTitle } from '../libs/GrowthViewTableHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { BLUR_KEYCODES } from '../ygdrasil/libs/Consts'
import { createGrowthIndicator, updateGrowthIndicator } from '../ygdrasil/libs/ProcessHandler'
import Texts from '../ygdrasil/libs/Texts'
import { CreateGrowthIndicatorRequest, GrowthIndicator } from '../ygdrasil/types/types'
import Box from './Box'
import FigmaTextWithSearchResult from './FigmaTextWithSearchResult'
import { InitialValues, NAME } from './GrowthViewGrowthIndicatorRow'
import TextFieldFormik from './TextFieldFormik'
import WithTooltipOverflow from './WithTooltipOverflow'

export function GrowthViewGrowthIndicatorRowName(props: {
  growthIndicator: Partial<GrowthIndicator>
  formProps: FormikProps<CreateGrowthIndicatorRequest>
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => unknown // bad stuff plz remove
  onBlur?: () => unknown
  onClickAway?: () => unknown
}) {
  const { state, setState } = useAppState()
  const { searchState } = state
  const { searchTextGrowthView } = searchState
  const { growthIndicator, formProps, onBlur, isEditing, onClickAway, setIsEditing } = props
  const { isArchived, _id, name } = growthIndicator
  const isBigScreen = useIsBigScreen()
  const logEvent = useLogEvent()
  const config = useLoggedInConfig()

  const onBlurName = (e, formProps: FormikProps<InitialValues>) => {
    formProps.handleBlur(NAME)(e)
    formProps
      .validateForm()
      .then((errors) => {
        if (Object.keys(errors).length > 0) return
        if (_id) return updateGrowthIndicator({ ...growthIndicator, ...formProps.values } as GrowthIndicator, logEvent)
        createGrowthIndicator(formProps.values as CreateGrowthIndicatorRequest, state, logEvent)
      })
      .finally(() => {
        setState({ ...state, showNewIndicatorRow: false })
        onBlur && onBlur()
      })
  }

  if (isArchived) return <FigmaText textKey={Texts.cssSuccessViewIndicatorTextArchieved} text={formProps.values.name} />

  if (isEditing || !_id)
    return (
      <ClickAwayListener onClickAway={() => onClickAway && onClickAway()}>
        <Box fullWidth>
          <TextFieldFormik
            name={NAME}
            onBlur={(e) => onBlurName(e, formProps)}
            formProps={formProps}
            onKeyDown={(e) => BLUR_KEYCODES.includes(e.keyCode) && onBlurName(e, formProps)}
            fullWidth
            autoFocus
          />
        </Box>
      </ClickAwayListener>
    )

  return (
    <Box
      fullWidth
      onClick={onClickIndicatorTitle(setIsEditing, config)}
      link
      spacing={smallSpacing}
      top
      bottom
      style={{ maxWidth: getMaxWidth(isBigScreen) }}
    >
      <WithTooltipOverflow text={name} ellipsisTextKey={Texts.cssSuccessViewIndicatorText}>
        <FigmaTextWithSearchResult
          textKey={Texts.cssSuccessViewIndicatorText}
          text={name}
          searchText={searchTextGrowthView}
        />
      </WithTooltipOverflow>
    </Box>
  )
}
