import { Confidence, KeyResult, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { getColorForCheckInStatus } from '../ygdrasil/libs/CheckInHelper'
import { StyledLinearProgress } from './LinearProgressWithLabel'
import { Props } from './CheckinProgress'
import { CheckinStatusView, CHECKIN_ICON_SIZE } from './CheckinStatusView'
import { PROGRESS_BAR_HEIGHT } from '../libs/HardCodedSizes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { GreyLine } from './StyledComponents'
import { getWidth } from '../libs/CheckInProgressItemHelper'

const CHECKIN_STATUS_VIEW_THRESHOLD = CHECKIN_ICON_SIZE * 1.5
export function CheckInProgressItem({
  index,
  confidences,
  objective,
  totalWidth,
  confidence,
  enableHead
}: {
  index: number
  confidences: Confidence[]
  objective: Objective
  totalWidth: number
  confidence: Confidence
  enableHead?: boolean
}): JSX.Element {
  const width = Math.round(getWidth(index, { confidences, objective }, totalWidth))
  return (
    <Box key={index} width={`${width}px`} position="relative">
      <StyledLinearProgress
        variant="determinate"
        value={100}
        backgroundColor={getColorForCheckInStatus(confidence.status)}
      />
      {enableHead && width > CHECKIN_STATUS_VIEW_THRESHOLD && (
        <Box
          position="absolute"
          style={{
            top: (PROGRESS_BAR_HEIGHT - CHECKIN_ICON_SIZE) / 2,
            right: smallSpacing,
            borderRadius: '50%',
            backgroundColor: 'white'
          }}
        >
          <CheckinStatusView checkinStatus={confidence.status} />
        </Box>
      )}
    </Box>
  )
}
