import React from 'react'
import { smallSpacing } from '../enums/Spacings'
import Box from './Box'
import { GrowthViewChipInput } from './GrowthViewChipInput'
import { TuneIcon } from './Icons'
import { ModalSearchGrowthIndicators } from './ModalSearchGrowthIndicators'
import { SearchFieldContainer } from './Search'

export function GrowthViewSearch() {
  const [showModal, setShowModal] = React.useState(false)

  const onClickSearch = () => setShowModal(true)

  return (
    <Box direction="row" align="center">
      {showModal && <ModalSearchGrowthIndicators onClose={() => setShowModal(false)} />}
      <SearchFieldContainer>
        <GrowthViewChipInput />
      </SearchFieldContainer>
      <Box left spacing={smallSpacing} link onClick={onClickSearch}>
        <TuneIcon />
      </Box>
    </Box>
  )
}
