import Term from '../ygdrasil/enums/Term'
import { getTerm, TermsItemKey } from '../ygdrasil/libs/TermsHelper'
import { Language } from '../ygdrasil/types/Antiloop'
import { Organization } from '../ygdrasil/types/types'

export function getTermText(term: Term, org?: Organization, language?: Language) {
  return (
    getTerm(term, org, { language, termsItemKey: TermsItemKey.PLURAL }) ||
    getTerm(term, org, { language, termsItemKey: TermsItemKey.SINGULAR })
  )
}
