import { smallSpacing } from '../enums/Spacings'
import { openGuidedTour } from '../libs/Stonly'
import { openChat } from '../libs/TidioHelper'
import Box from './Box'
import { BIG_ICON_SIZE, ChatIcon, HelpOutlineIcon } from './Icons'
import { GreyLine } from './StyledComponents'
import { useStonlyWelcomeGuide } from './useStonlyWelcomeGuide'

export default function MenuContactSupportCollapsed() {
  const guide = useStonlyWelcomeGuide()
  return (
    <Box fullWidth align="center">
      <GreyLine />
      <Box spacing={smallSpacing} top bottom onClick={() => openGuidedTour(guide)} pointer>
        <HelpOutlineIcon size={BIG_ICON_SIZE} />
      </Box>
      <Box spacing={smallSpacing} bottom onClick={openChat} pointer>
        <ChatIcon size={BIG_ICON_SIZE} />
      </Box>
    </Box>
  )
}
