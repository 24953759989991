import { addDoc, collection, doc, setDoc } from '@firebase/firestore'
import dayjs from 'dayjs'
import { ServerFirebaseUser } from '../types/ServerTypes'
import { BaseObject, DB } from '../types/types'
import Collections from './Collections'
import { createUniqueId } from './Common'

// eslint-disable-next-line prettier/prettier
export const toUpdatedObject = <T extends BaseObject>(object: T, u?: ServerFirebaseUser): T => ({
  ...object,
  updatedAt: dayjs().format(),
  updatedBy: u?.uid || 'admin'
})
// eslint-disable-next-line prettier/prettier
export const setItem = <T extends BaseObject>(path: string | Collections, object: T, db: DB): Promise<T> =>
  setDoc(doc(db, path, object._id), object).then(() => object)
// eslint-disable-next-line prettier/prettier
export const addItem = <T>(path: string | Collections, object: T, db: DB): Promise<T> =>
  addDoc(object as any, collection(db, path)).then(() => object)

type MapFunction = (item: any) => any

export const mapQueryResponse = (response: any, mapFn?: MapFunction) =>
  response.docs ? getCollectionData(response, mapFn) : getDocData(response, mapFn)

// Get array of doc data from collection
export const getCollectionData = (collection, mapFn?: MapFunction) =>
  collection.docs.map((doc) => getDocData(doc, mapFn))

// Get doc data and merge doc.id
function getDocData(doc, mapFn?: MapFunction) {
  if (!doc.exists) return null
  const data = { id: doc.id, ...doc.data() }
  return mapFn ? mapFn(data) : data
}

export const toBaseObject = ({ uid }: ServerFirebaseUser): BaseObject => ({
  _id: createUniqueId(),
  createdAt: dayjs().format(),
  updatedAt: dayjs().format(),
  createdBy: uid,
  updatedBy: uid
})
