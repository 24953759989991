import { useQuery } from 'react-query'
import { State } from '../hooks/useAppState'
import CloudFunction from '../ygdrasil/enums/CloudFunctions'
import { getJiraIssues } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { JIRA_ISSUES_ONE_HOUR_STALE } from '../ygdrasil/libs/Consts'

export const useJiraIssues = (organizationId: string, state: State, options: any = { enabled: true }) =>
  useQuery(
    [CloudFunction.getJiraIssues, organizationId],
    () => getJiraIssues({ organziationId: state.selectedOrganizationId }, state),
    {
      ...options,
      enabled: options?.enabled,
      staleTime: options?.staleTime || JIRA_ISSUES_ONE_HOUR_STALE,
      cacheTime: options?.cacheTime || JIRA_ISSUES_ONE_HOUR_STALE * 2
    }
  )
