import { useMemo } from 'react'
import useAppState from '../../hooks/useAppState'
import * as CelebrationHelper from '../libs/CelebrationHelper'
import { EventParams } from '../libs/EventHelper'
import * as QueryHooks from '../libs/QueryHooks'

export function useEvents(refetch = false) {
  const { state } = useAppState()
  const { data: _events = [], isLoading } = QueryHooks.useEvents<Partial<EventParams>>(state, refetch)

  const { events, hasUnseenNotifications, unseenEvents } = useMemo(() => {
    const events = CelebrationHelper.getEventsForCelebrationView(_events, state)
    const hasUnseenNotifications = CelebrationHelper.hasUnseenNotifications(events, state)
    const unseenEvents = CelebrationHelper.getUnseenCelebrationEventsCreatedByLoggedInUser(events, state)

    return { events, hasUnseenNotifications, unseenEvents: unseenEvents }
  }, [_events, state.user.lastSeenNotificationsAt])

  return { events, hasUnseenNotifications, isLoading, unseenEvents }
}
