import { isNumber } from '../ygdrasil/libs/Common'
import { GrowthIndicator, GrowthIndicatorValue, GrowthIndicatorValueType } from '../ygdrasil/types/types'
import { canEditActual } from './GrowthViewGrowthIndicatorValueHelper'

export function getNewValues(
  growthIndicatorValue: GrowthIndicatorValue,
  name: GrowthIndicatorValueType,
  date: string,
  growthIndicator: GrowthIndicator,
  newValue?: number | null,
  now?: string
) {
  if (name === GrowthIndicatorValueType.ACTUAL && !canEditActual(date, now)) return growthIndicator.values

  const _growthIndicatorValue: GrowthIndicatorValue = { ...growthIndicatorValue, [name]: newValue, date }

  if (!isNumber(newValue)) delete _growthIndicatorValue[name]

  const values = { ...growthIndicator.values, [date]: _growthIndicatorValue }

  if (!isNumber(_growthIndicatorValue.goal) && !isNumber(_growthIndicatorValue.actual)) delete values[date]

  return values
}
