/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-restricted-globals */
import styled from '@emotion/styled'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import React from 'react'
import { useHistory } from 'react-router'
import { littleSpacing, mediumSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { useLogout } from '../hooks/useLogout'
import { deleteAccount, deleteOrganization } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { getStateForSettingsView } from '../libs/MenuHelper'
import { useOrganizations } from '../ygdrasil/hooks/useOrganizations'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import RoutePath from '../ygdrasil/libs/RoutePath'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { OrgUserStatus } from '../ygdrasil/types/types'
import Box from './Box'
import { KeyboardArrowRightIcon } from './Icons'
import { MenuItem } from './MenuItem'
import { ModalCreateOrganization } from './ModalCreateOrganization'
import UserWidgetMenuOrganizationMenu from './UserWidgetMenuOrganizationMenu'
import { captureException } from '../ygdrasil/libs/SentryHelperDeprecated'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
}

export default function UserWidgetMenu({ anchorEl, onClose }: Props) {
  const { state, setState } = useAppState()
  const config = useLoggedInConfig()
  const history = useHistory()

  const [orgMenuAnchorEl, setOrgMenuAnchorEl] = React.useState<HTMLElement>()
  const { user, selectedOrganizationId } = state
  const [showCreateOrganziationModal, setShowCreateOrganizationModal] = React.useState(false)
  const { organizationIds } = user
  let { data: organizations = [] } = useOrganizations(user)
  const selectedOrganization = organizations.find(({ _id }) => _id === selectedOrganizationId)

  const enableDevMenu = config.enableDevMenu
  organizations = organizations.filter(
    (org) => organizationIds.includes(org._id) && state.user.orgUserInfo?.[org._id]?.status !== OrgUserStatus.DISABLED
  )

  const selectableOrgainzations = organizations.filter((org) => org._id !== selectedOrganizationId)
  const hasMultipleOrganizations = selectableOrgainzations.length > 0

  const onDeleteOrganization = () =>
    confirm(`Are you sure you want to delete organization: '${selectedOrganization?.name}'?`) &&
    deleteOrganization({ uid: user._id, orgId: selectedOrganizationId }, state)
      .then(() => {
        const nextOrg = organizations.find(({ _id }) => _id !== selectedOrganizationId)
        if (!nextOrg) return
        setState({ ...state, selectedOrganizationId: nextOrg._id })
        onClose()
      })
      .catch((err) => captureAndNotifyError(err))

  const onDeleteUserAndOrganization = () =>
    confirm(
      `Are you sure you want to delete user with email '${user.email}' and organization: '${selectedOrganization?.name}'?`
    ) && deleteOrganization({ uid: user._id, orgId: selectedOrganizationId }, state).then(() => _onDeleteUser())

  const _onDeleteUser = () =>
    deleteAccount(user._id, state)
      .then(() => logout())
      .catch(captureException)

  const onLogout = () => {
    onClose()
    history.push(RoutePath.ROOT)
  }

  const logout = useLogout(onLogout)

  const onCreateOrganization = () => {
    onClose()
    setShowCreateOrganizationModal(true)
  }

  const onCloseOrganizationMenu = () => {
    setOrgMenuAnchorEl(undefined)
    onClose()
  }

  return (
    <div>
      <UserWidgetMenuOrganizationMenu onClose={onCloseOrganizationMenu} anchorEl={orgMenuAnchorEl} />
      {showCreateOrganziationModal && <ModalCreateOrganization onClose={() => setShowCreateOrganizationModal(false)} />}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItemTitle textKey={Texts.usermenuUserNameButtonText} text={user.email?.toString()} />
        <MenuItem
          onClick={() => setState(getStateForSettingsView(state))}
          textKey={Texts.userMenuSettingsButtonText}
        ></MenuItem>
        <Divider />
        <MenuItem
          button={false}
          textKey={Texts.userMenuChosenOrgCss}
          text={selectedOrganization?.name}
          topElement={
            hasMultipleOrganizations ? (
              <Box spacing={tinySpacing} bottom>
                <FigmaText textKey={Texts.userMenuChosenOrgLabel} text={getFigmaText(Texts.userMenuChosenOrgLabel)} />
              </Box>
            ) : undefined
          }
        />

        {hasMultipleOrganizations && (
          <MenuItem
            onClick={(e) => setOrgMenuAnchorEl(e.currentTarget)}
            textKey={Texts.userMenuGoToOrgButtonText}
            rightElement={<KeyboardArrowRightIcon />}
          ></MenuItem>
        )}
        <Divider />

        {enableDevMenu && <MenuItem onClick={onCreateOrganization} textKey={Texts.userMenuCreateCopyOrg}></MenuItem>}
        {enableDevMenu && hasMultipleOrganizations && (
          <MenuItem onClick={onDeleteOrganization} textKey={Texts.userMenuDeleteOrg}></MenuItem>
        )}
        {enableDevMenu && (
          <MenuItem onClick={onDeleteUserAndOrganization} textKey={Texts.userMenuDeleteUserAndOrgButtonText}></MenuItem>
        )}
        <Divider />

        <MenuItem onClick={logout} textKey={Texts.userMenuLogoutButtonText} />
      </Menu>
    </div>
  )
}

const MenuItemTitle = styled(FigmaText)`
  padding: ${littleSpacing} ${mediumSpacing};
  background-color: ${Colors.primaryBase};
  display: block;
`
