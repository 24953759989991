import {
  OpenEditObjectiveDialogEventParams,
  openEditObjectiveDrawer
} from '../components/DrawerGlobalEditObjectiveDialog'
import { State } from '../hooks/useAppState'
import { Objective, ObjectiveType } from '../ygdrasil/types/types'
import { isSearching } from './StrategyViewObjectiveTreeHelper'

export function shouldEnableReordering({ searchState, selectedDashboardView }: State) {
  return !isSearching(searchState) && selectedDashboardView === 'list'
}

export function createOnClickAddAlignedObjective(
  objective: Objective,
  functions: {
    openEditObjectiveDrawer: (props: OpenEditObjectiveDialogEventParams) => unknown
    onClose: () => unknown
  }
) {
  const { openEditObjectiveDrawer, onClose } = functions
  return () => {
    onClose()
    openEditObjectiveDrawer({ objective: { parentObjectiveId: objective._id, type: ObjectiveType.TOP } })
  }
}
