import { MenuProps as MaterialMenuProps } from '@mui/material'
import React, { useEffect } from 'react'
import Box from './Box'
import { MoreActionProps } from './MoreAction'

export type MenuProps = {
  anchorEl?: HTMLElement
  onClose: () => void
} & Partial<MaterialMenuProps>

export const MoreActionWithMenu = ({
  Menu,
  MoreAction,
  visible,
  isAlwaysVisible
}: {
  visible: boolean
  Menu: React.FC<MenuProps>
  MoreAction: React.FC<MoreActionProps>
  isAlwaysVisible?: boolean
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const [showMoreAction, setShowMoreAction] = React.useState(visible)

  const onCloseMenu = () => {
    setAnchorEl(undefined)
    setShowMoreAction(isAlwaysVisible ? true : false)
  }

  const onClickMore = (e) => {
    e.stopPropagation()
    setAnchorEl(e.target)
  }

  useEffect(() => {
    setShowMoreAction(visible)
  }, [visible])

  if (!showMoreAction) return null

  return (
    <Box>
      <Menu anchorEl={anchorEl} onClose={onCloseMenu} />
      <MoreAction onClick={onClickMore} visible={showMoreAction && visible} />
    </Box>
  )
}
