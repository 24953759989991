import { defaultSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useResponsive from '../hooks/useResponsive'
import { useSearchResultForTree } from '../hooks/useSearchResultForTree'
import { getEmptySearchState, isSearching } from '../libs/StrategyViewObjectiveTreeHelper'
import { asObject } from '../ygdrasil/libs/Common'
import { useCanEditObjective } from '../ygdrasil/libs/PermissionsHelper'
import { useUsersForOrganization } from '../ygdrasil/libs/QueryHooks'
import { AddButtonObjective } from './AddButtonObjective'
import Box from './Box'
import { FilterOffButton } from './FilterOffButton'
import { ModalSearchObjectives } from './ModalSearchObjectives'
import { Search } from './Search'
import { getSearchStateForUserFilterButtons, getUsersForFilterButtons } from './StrategyViewObjectiveTreeFilterHelper'
import { StrategyViewObjectiveTreeFilterUser } from './StrategyViewObjectiveTreeFilterUser'

export function StrategyViewObjectiveTreeFilter({ hideSerchBar }: { hideSerchBar?: boolean }) {
  const { state, setSearchState } = useAppState()
  const { searchState } = state
  const { data: users = [] } = useUsersForOrganization(state)
  const _users = asObject(users, '_id')
  const { isTablet } = useResponsive()

  const onClickFilterOff = () => setSearchState(getEmptySearchState(searchState))

  const _shouldShowClearFilterButton = isSearching(searchState)
  const tree = useSearchResultForTree(getSearchStateForUserFilterButtons(state))
  const __users = getUsersForFilterButtons(_users, tree)

  return (
    <Box direction="row" align="center" justify="space-between" fullWidth>
      <Box direction="row" align="center" pointer>
        <Box direction="row" align="center">
          {__users.map((user, key) => {
            return <StrategyViewObjectiveTreeFilterUser key={key} user={user} />
          })}
        </Box>
        <Box left>{_shouldShowClearFilterButton && <FilterOffButton onClick={onClickFilterOff} />}</Box>
      </Box>
      <Box direction="row" gap={defaultSpacing}>
        {useCanEditObjective() && isTablet && (
          <Box right spacing={smallSpacing} id="AddObjectiveButton">
            <AddButtonObjective />
          </Box>
        )}
        <Search Modal={ModalSearchObjectives} hidden={hideSerchBar} />
      </Box>
    </Box>
  )
}
