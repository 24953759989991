import dayjs from 'dayjs'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import FigmaText from '../ygdrasil/components/FigmaText'
import { DATE_VALIDATION_MESSAGE, YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { hasCustomDates } from '../ygdrasil/libs/ObjectiveHelper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

type OnChangeDate = (date: string) => unknown
type Props = { startDate: string; endDate: string; onChangeStartDate: OnChangeDate; onChangeEndDate: OnChangeDate }

export function ObjectivePeriodAndTypeOptionsCustomForm(props: Props) {
  const { startDate, endDate, onChangeStartDate, onChangeEndDate } = props
  const [showMore, setShowMore] = React.useState(hasCustomDates(props))
  const onClickShowMore = () => setShowMore(true)

  const _onChangeStartDate = (e, formProps) => {
    formProps.handleChange('startDate')(e)
    const { target } = e

    onChangeStartDate(target.value)
  }

  const _onChangeEndDate = (e, formProps) => {
    formProps.handleChange('endDate')(e)
    const { target } = e
    onChangeEndDate(target.value)
  }

  const ValidationSchema = Yup.object().shape({
    startDate: Yup.string()
      .required(DATE_VALIDATION_MESSAGE)
      .test('Before End Date', getFigmaText(Texts.dateValidationMessageToDateBeforeStartDate), (date) =>
        dayjs(date).isBefore(endDate)
      ),
    endDate: Yup.string()
      .required(DATE_VALIDATION_MESSAGE)
      .test('After Start date', getFigmaText(Texts.dateValidationMessageToDateBeforeStartDate), (date) =>
        dayjs(date).isAfter(startDate)
      )
      .test('After current date', DATE_VALIDATION_MESSAGE, (date) => dayjs(date).isAfter(dayjs().format()))
  })

  return (
    <Formik
      onSubmit={() => ({} as any)}
      validationSchema={ValidationSchema}
      initialValues={{ startDate, endDate }}
      enableReinitialize
    >
      {(formProps) => (
        <Box fullWidth>
          {!showMore && (
            <Box fullWidth align="center">
              <Box link onClick={onClickShowMore}>
                <FigmaText textKey={Texts.periodSelectorExpandCustomDatesText} />
              </Box>
            </Box>
          )}
          {showMore && (
            <Box fullWidth direction="row" justify="space-around">
              <TextFieldWithLabelFormik
                name="startDate"
                formProps={formProps}
                labelTextKey={Texts.periodSelectorFromDateHelperText}
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dayjs(formProps.values.startDate).format(YYYY_MM_DD)}
                onChange={(e) => _onChangeStartDate(e, formProps)}
                component={TextFieldOutlined}
              />
              <TextFieldWithLabelFormik
                name="endDate"
                formProps={formProps}
                labelTextKey={Texts.periodSelectorToDateHelperText}
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dayjs(formProps.values.endDate).format(YYYY_MM_DD)}
                onChange={(e) => _onChangeEndDate(e, formProps)}
                component={TextFieldOutlined}
              />
            </Box>
          )}
        </Box>
      )}
    </Formik>
  )
}
