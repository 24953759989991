/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-restricted-globals */
import Menu from '@mui/material/Menu'
import React from 'react'
import { CheckinStatus as CheckInStatusEnum } from '../ygdrasil/enums/CheckinStatus'
import Texts from '../ygdrasil/libs/Texts'
import { CheckinStatusAtRisk, CheckinStatusDontKnow, CheckinStatusFeelsGood, CheckinStatusNotAchievable } from './Icons'
import { MenuItem } from './MenuItem'

type Props = {
  anchorEl?: HTMLElement
  onClose: (e) => void
  onSelectCheckinStatus: (CheckinStatus) => unknown
}

export default function CheckinStatusMenu({ anchorEl, onClose, onSelectCheckinStatus: _onSelectCheckinStatus }: Props) {
  const onSelectCheckinStatus = (checkinStatus: CheckInStatusEnum, e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    onClose(e)
    _onSelectCheckinStatus(checkinStatus)
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} disableAutoFocusItem>
      <MenuItem
        onClick={(e) => onSelectCheckinStatus(CheckInStatusEnum.DONT_KNOW, e)}
        textKey={Texts.checkinStatusOptionUnknown}
        leftElement={<CheckinStatusDontKnow />}
      ></MenuItem>
      <MenuItem
        onClick={(e) => onSelectCheckinStatus(CheckInStatusEnum.FEELS_GOOD, e)}
        textKey={Texts.checkinStatusOptionOk}
        leftElement={<CheckinStatusFeelsGood />}
      ></MenuItem>
      <MenuItem
        onClick={(e) => onSelectCheckinStatus(CheckInStatusEnum.AT_RISK, e)}
        textKey={Texts.checkinStatusOptionAtRisk}
        leftElement={<CheckinStatusAtRisk />}
      ></MenuItem>
      <MenuItem
        onClick={(e) => onSelectCheckinStatus(CheckInStatusEnum.NOT_ACHIEVABLE, e)}
        textKey={Texts.checkinStatusOptionProbablyNotArchievable}
        leftElement={<CheckinStatusNotAchievable />}
      ></MenuItem>
    </Menu>
  )
}
