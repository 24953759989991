import { FormikProps } from 'formik'
import { ONE_REM_IN_PX, tinySpacing } from '../enums/Spacings'
import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import { Activity } from '../ygdrasil/types/types'
import Box from './Box'
import { InitialValues } from './EditActivityDialogForm'
import { Menu } from '@mui/material'
import ZIndices from '../enums/Zindices'
import { withStopProgagation } from '../libs/HtmlHelper'
import { NoWrapFigmaText } from './StyledComponents'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import styled from '@emotion/styled'
import { ACITIVITY_STATUS_MENU_ITEM_FALLBACK_HEIGHT } from '../libs/HardCodedSizes'
import { mapActivityStatusItems } from '../libs/ActivityBoardHelper'

type Props = {
  formProps: FormikProps<InitialValues>
  activity: Activity
  anchorEl: HTMLElement | null
  onClose: () => void
  value?: string
}

const SelectActivityStatusMenu: React.FC<Props> = ({ formProps, anchorEl, onClose }) => {
  const _onClose = withStopProgagation(onClose)
  const currentSelectedIndex = Object.values(ActivityStatus).findIndex((status) => status === formProps.values.status)
  const currentPosition = currentSelectedIndex * ACITIVITY_STATUS_MENU_ITEM_FALLBACK_HEIGHT + ONE_REM_IN_PX / 2

  const mappedStatusItems = mapActivityStatusItems(formProps)

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      style={{ zIndex: ZIndices.modal }}
      onClose={_onClose}
      anchorOrigin={{ vertical: -currentPosition, horizontal: 'left' }}
    >
      <Box>
        {mappedStatusItems.map(({ activityStatusInfo, status }, key) => {
          const { color, textKey, styleTextKey } = activityStatusInfo
          return (
            <Container
              key={`${key}${status}`}
              fullWidth
              color={color}
              fullPadding
              spacing={tinySpacing}
              pointer
              onClick={(e) => {
                formProps.setValues({ ...formProps.values, status })
                _onClose(e)
              }}
            >
              <NoWrapFigmaText
                styleTextKey={styleTextKey}
                textKey={textKey as AntiloopTextType}
                textTransform="uppercase"
              />
            </Container>
          )
        })}
      </Box>
    </Menu>
  )
}

export default SelectActivityStatusMenu

const Container = styled(Box)<{ color?: string }>`
  background-color: ${({ color }) => color};
`
