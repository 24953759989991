import React from 'react'
import FigmaText, { FigmaTextProps } from '../ygdrasil/components/FigmaText'
import WithTooltipOverflow from './WithTooltipOverflow'

export function FigmaTextWithTooltipOverflow(props: FigmaTextProps) {
  const { textKey, text, ...restProps } = props
  return (
    <WithTooltipOverflow ellipsisTextKey={textKey} text={text}>
      <FigmaText textKey={textKey} text={text} {...restProps} />
    </WithTooltipOverflow>
  )
}
