import styled from '@emotion/styled'
import { ClickAwayListener } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { getArray } from '../ygdrasil/libs/Common'
import { NO_OF_GROWTH_INDICATOR_HEADER_DATES, TAB_KEYCODE } from '../ygdrasil/libs/Consts'
import { useGrowthIndicators } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { CreateGrowthIndicatorRequest, Direction } from '../ygdrasil/types/types'
import Box from './Box'
import DashboardGrowthViewGrowthIndicatorRow, { GrowthViewTableCell } from './GrowthViewGrowthIndicatorRow'
import { ArrowBack, ArrowForward } from './Icons'

export default function DashboardGrowthViewTable({
  searchDates
}: {
  searchDates: {
    startDate: string
    endDate: string
  }
}) {
  type _CreateGrowthIndicatorRequest = CreateGrowthIndicatorRequest & { isNewRow?: boolean }

  const { state } = useAppState()
  const { searchState } = state
  const { startDate } = searchDates
  const { showArchivedGrowthIndicators, searchTextGrowthView } = searchState
  const [newIndicatorRow, setNewIndicatorRow] = useState<_CreateGrowthIndicatorRequest>()
  let { data: growthIndicators = [] } = useGrowthIndicators(state)
  if (!showArchivedGrowthIndicators) growthIndicators = growthIndicators.filter(({ isArchived }) => !isArchived)
  if (!!searchTextGrowthView)
    growthIndicators = growthIndicators.filter(({ name }) =>
      name.toUpperCase().includes(searchTextGrowthView.toUpperCase())
    )

  const [headerStartDate, setHeaderStartDate] = useState(dayjs().format())
  const headerDates = getArray(NO_OF_GROWTH_INDICATOR_HEADER_DATES).map((x, i) =>
    dayjs(headerStartDate).add(i, 'month').format()
  )
  const onClickBack = () => setHeaderStartDate(dayjs(headerStartDate).add(-1, 'month').format())
  const onClickForward = () => setHeaderStartDate(dayjs(headerStartDate).add(1, 'month').format())
  if (newIndicatorRow) growthIndicators = [...growthIndicators, newIndicatorRow as any]
  growthIndicators = growthIndicators.sort((a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf())

  useEffect(() => {
    setHeaderStartDate(dayjs(startDate).format())
  }, [searchDates])

  useEffect(() => {
    const newRow = {
      name: '',
      values: {},
      organizationId: state.selectedOrganizationId,
      direction: Direction.INCREASING
    }
    setNewIndicatorRow(state.showNewIndicatorRow ? newRow : undefined)
  }, [state.showNewIndicatorRow])

  const indicatorInputs = useRef<{ [key: number]: HTMLDivElement }>({})

  const [currentSelectedInput, setCurrentSelectedInput] = useState<any>(null)

  const handleTab = (event) => {
    if (event.keyCode === TAB_KEYCODE) {
      let newSelectInputIdx
      if (!currentSelectedInput) {
        newSelectInputIdx = 1
      } else {
        newSelectInputIdx = currentSelectedInput + 1
      }
      setCurrentSelectedInput(newSelectInputIdx)
    }
  }

  useEffect(() => {
    if (currentSelectedInput && indicatorInputs.current[currentSelectedInput]) {
      indicatorInputs.current[currentSelectedInput].focus()
    }
  }, [currentSelectedInput])

  useEffect(() => {
    window.addEventListener('keydown', handleTab)

    return () => {
      window.removeEventListener('keydown', handleTab)
    }
  }, [handleTab])

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <GrowthViewTableCell />
            <GrowthViewTableCell align="right">
              <Box fullWidth align="center">
                <ArrowContainer fullPadding spacing={tinySpacing} link onClick={onClickBack}>
                  <ArrowBack />
                </ArrowContainer>
              </Box>
            </GrowthViewTableCell>
            <GrowthViewTableCell />
            {headerDates.map((date, i) => {
              const showYear = i === 0 || dayjs(date).month() === 0
              const showLeftBorder = showYear && i !== 0
              return (
                <GrowthViewTableCell key={i} $showLeftBorder={showLeftBorder}>
                  <Box fullWidth align="center">
                    {showYear && (
                      <FigmaText textKey={Texts.cssSuccessViewYearsText} text={dayjs(date).format('YYYY')} />
                    )}
                    {!showYear && (
                      <HiddenFigmaText textKey={Texts.cssSuccessViewYearsText} text={dayjs(date).format('YYYY')} />
                    )}
                    <FigmaText textKey={Texts.cssSuccessViewMonthlyText} text={dayjs(date).format('MMM')} />
                  </Box>
                </GrowthViewTableCell>
              )
            })}
            <GrowthViewTableCell>
              <ArrowContainer fullPadding spacing={tinySpacing} alignText="center" link onClick={onClickForward}>
                <ArrowForward />
              </ArrowContainer>
            </GrowthViewTableCell>
          </TableRow>
        </TableHead>
        <ClickAwayListener onClickAway={() => setCurrentSelectedInput(null)}>
          <TableBody>
            {growthIndicators.map((row, i) => (
              <DashboardGrowthViewGrowthIndicatorRow
                growthIndicator={row}
                key={i}
                headerDates={headerDates}
                indicatorInputs={indicatorInputs}
                rowIndex={i}
                setCurrentSelectedInput={setCurrentSelectedInput}
                currentSelectedInput={currentSelectedInput}
              />
            ))}
          </TableBody>
        </ClickAwayListener>
      </Table>
    </TableContainer>
  )
}

const ArrowContainer = styled(Box)`
  background-color: ${Colors.whiteBg};
`
const HiddenFigmaText = styled(FigmaText)`
  visibility: hidden;
`
