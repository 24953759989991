import { littleSpacing } from '../enums/Spacings'
import FigmaTextArray from '../ygdrasil/components/FigmaTextArray'
import { PRIVACY_POLICY_URL } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'

export function SignInSignupAgreeCheckbox() {
  return (
    <Box top spacing={littleSpacing} fullWidth direction="row" align="center" alignText="center">
      <FigmaTextArray
        figmaTextPropSets={[
          { textKey: Texts.signUpAcceptTermsConfirmation1 },
          { textKey: Texts.signUpAcceptTermsConfirmation2, anchorHref: PRIVACY_POLICY_URL }
        ]}
      />
    </Box>
  )
}
