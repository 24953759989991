import styled from '@emotion/styled'
import useAppState from '../hooks/useAppState'
import { getStateForStrategicView } from '../libs/MenuHelper'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import Tooltip from './Tooltip'

export const IndicatorDashboardEmptyState = () => {
  const { state, setState } = useAppState()
  const { data: org } = useOrganization(state)
  const onClick = () => setState(getStateForStrategicView(state))
  return (
    <Box fullWidth fullHeight align="center" justify="center">
      <Tooltip title={getTextForTextKey(Texts.dashboardEmptyStateTooltipNoIndicatorText, org)}>
        <Button buttonType="empty_state" onClick={onClick}>
          <FigmaTextForStrategyModel textKey={Texts.dashboardEmptyStateText} />
        </Button>
      </Tooltip>
    </Box>
  )
}
