import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { shouldShowAddTodoButton, shouldShowCopyAllTodosToTodayButton } from '../libs/MyActionsMyToDosHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { createTodo, updateTodo } from '../ygdrasil/libs/DBApiHandler'
import Texts from '../ygdrasil/libs/Texts'
import { Todo, TodoRequest } from '../ygdrasil/types/types'
import Box from './Box'
import DragDropContext from './DragDropContext'
import { MyActionsMyTodosEditTodoForm } from './MyTodosEditTodoForm'

export function MyTodosList({ todos, date, setActiveDayOffset }: { todos: Todo[]; date: string; setActiveDayOffset }) {
  const { state } = useAppState()

  const { todos: _todos, setNewTodo } = useNewTodos(todos)

  const shouldShowAddTodo = shouldShowAddTodoButton(date)
  const shouldShowCopyAllTodosToToday = shouldShowCopyAllTodosToTodayButton(date, todos)

  const onClickAddNewTodo = () =>
    setNewTodo({ name: '', done: false, date, userId: state.user._id, organizationId: state.selectedOrganizationId })

  const onClickCopyAllTodosToToday = () => {
    setActiveDayOffset(0) // select today as active day
    _todos
      .filter((t) => !t.done)
      .map((t) =>
        createTodo(
          {
            name: t.name,
            done: false,
            date: dayjs().format(YYYY_MM_DD),
            organizationId: state.selectedOrganizationId,
            userId: state.user._id
          },
          state
        )
      )
  }

  const hasTodos = todos.length > 0
  return (
    <Box fullWidth spacing={smallSpacing}>
      <DragDropContext
        rows={_todos}
        Component={({ data: todo }) => (
          <Box fullWidth spacing={smallSpacing}>
            <MyActionsMyTodosEditTodoForm todo={todo} />
          </Box>
        )}
        onDragEnd={(todo) => Promise.all(todo.map(updateTodo))}
        enableReInitialize
      />

      <Box fullWidth left top spacing={tinySpacing}>
        {shouldShowAddTodo && (
          <Box onClick={() => onClickAddNewTodo()} link top={!hasTodos} spacing={tinySpacing}>
            <FigmaText textKey={Texts.myTodosAddNewTodoButton} />
          </Box>
        )}
        {shouldShowCopyAllTodosToToday && (
          <Box onClick={() => onClickCopyAllTodosToToday()} link>
            <FigmaText textKey={Texts.myTodosCopyUnfinishedTodosToToday} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
function useNewTodos(todos: Todo[]) {
  const [newTodo, setNewTodo] = useState<TodoRequest>()

  useEffect(() => {
    setNewTodo(undefined)
  }, [todos])

  if (newTodo) todos = [...todos, newTodo] as Todo[]
  return { todos, setNewTodo }
}
