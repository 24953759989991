/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { StorageKey, StorageType, Options, DEFAULT_OPTIONS } from '../libs/useLocalStorageItemHelper'
import { getInitialItem, formatLocalStorageItem } from './useAppStateHelper'

export const useStorageItem = <T>(
  key: StorageKey,
  defaultValue: T,
  { ignoredKeys = [], storageType = StorageType.LOCAL_STORAGE }: Options = DEFAULT_OPTIONS
): { storageItem; setStorageItem } => {
  const Storage = storageType === StorageType.LOCAL_STORAGE ? localStorage : sessionStorage
  const stored = Storage.getItem(key)
  const initial = getInitialItem<T>(stored, defaultValue)
  const [storageItem, setStorageItem] = useState<T>(initial)

  useEffect(() => {
    try {
      let _storageItem = storageItem
      // eslint-disable-next-line prettier/prettier
      if(ignoredKeys.length > 0) _storageItem = formatLocalStorageItem(_storageItem, ignoredKeys)
      Storage.setItem(key, JSON.stringify(_storageItem))
    } catch (error) {
      console.warn(error)
    }
  }, [key, storageItem])

  return { storageItem, setStorageItem }
}
