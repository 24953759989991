import React, { useEffect } from 'react'
import { useLogout } from './useLogout'

export const useLogoutWithLoading = () => {
  const [loading, setLoading] = React.useState(true)
  const logout = useLogout()
  useEffect(() => {
    logout().finally(() => setLoading(false))
  }, [])

  return loading
}
