import { DayJS } from './DayJsHelper'

const DEFAULT_SEARCH_DATES_LIST = {
  startDate: DayJS().startOf('quarter').format(),
  endDate: DayJS().endOf('quarter').add(1, 'quarter').format()
}
const DEFAULT_SEARCH_DATES_ROADMAP = {
  ...DEFAULT_SEARCH_DATES_LIST,
  endDate: DayJS().endOf('quarter').add(3, 'quarter').format()
}

export const getDefaultSearchDates = (state) =>
  state.selectedDashboardView === 'list' ? DEFAULT_SEARCH_DATES_LIST : DEFAULT_SEARCH_DATES_ROADMAP
