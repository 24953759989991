import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Formik, FormikProps } from 'formik'
import invariant from 'invariant'
import { smallBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useDeleteUserAsSuperAdmin from '../hooks/useDeleteUser'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { asObject } from '../ygdrasil/libs/Common'
import { updateUser } from '../ygdrasil/libs/DBApiHandler'
import { useAllOrganizationsAsSuperAdmin, useUser, useUsersAsSuperAdmin } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { Id, Organization, OrgUserStatus, User } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import SelectFieldWithLabel from './SelectFieldWithLabel'
import { SettingsSuperAdminOrgRow } from './SettingsSuperAdminOrgRow'

const SHOW_SAVE_BUTTON_ORG_LENGTH = 25

const getInitialValues = (user: User, orgs: Organization[]) => ({
  userId: user._id,
  ...orgs.reduce((a, org) => {
    a[org._id] = user.organizationIds.includes(org._id)
    return a
  }, {})
})

export default function SettingsSuperAdmin() {
  const { state, setState } = useAppState()
  const { user } = state
  const { data: users = [], isLoading: isLoadingUsers } = useUsersAsSuperAdmin()
  let { data: orgs = [] } = useAllOrganizationsAsSuperAdmin()
  const _orgs = asObject(orgs, '_id')
  const initialValues = getInitialValues(user, orgs)
  const { deleteUser, isLoading } = useDeleteUserAsSuperAdmin()

  const onSubmit = (values) => {
    const { userId } = values
    const user = users.find((u) => u._id === userId)
    invariant(user, '!user')
    const organizationIds = Object.keys(values).filter((key) => !!values[key] && !!_orgs[key]) // TODO WRITE TEST PAIN (should not set "userId" as organizationId)
    user.organizationIds = organizationIds

    user.orgUserInfo = organizationIds.reduce((a, orgId) => {
      a[orgId] = { ...a[orgId], status: OrgUserStatus.ACTIVE }
      return a
    }, {})

    updateUser(user)
    if (user._id === state.user._id) state.user = user // TODO WRITE TEST PAIN it should update user widget with new companies
    setState({ ...state, showSettings: false })
  }

  const onChangeUser = (userId: string, formProps: FormikProps<any>) => {
    const user = users.find((u) => userId === u._id)
    invariant(user, '!user')
    formProps.setValues(getInitialValues(user, orgs))
  }

  orgs = orgs.sort((a, b) => a.name?.localeCompare(b.name))

  if (isLoadingUsers) return null

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {(formProps) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { selectedOrgs, mostActiveOrgs, otherOrgs } = useOrgsForSuperAdmin(orgs, formProps.values.userId)
        return (
          <Box fullWidth>
            <Box top direction="row" justify="space-between" align="center" fullWidth>
              <Box>
                <SelectFieldWithLabel
                  labelTextKey={Texts.allUserSelectLabel}
                  value={formProps.values.userId}
                  options={users
                    .map(({ _id: value, email }) => ({ key: email || '', value }))
                    .sort((a, b) => a.key.localeCompare(b.key))}
                  onChange={({ target: { value: userId } }) => onChangeUser(userId as string, formProps)}
                />
              </Box>
              <Box spacing={tinySpacing} top>
                <Button
                  textKey={Texts.deleteButtonText}
                  buttonType="tertiary"
                  onClick={() => deleteUser(formProps.values.userId)}
                  spacing={tinySpacing}
                  loading={isLoading}
                />
              </Box>
            </Box>
            <Box>
              <Box top bottom fullWidth direction="row" align="center">
                <FigmaText textKey={Texts.cssAdminMenuText} />
                {orgs.length > SHOW_SAVE_BUTTON_ORG_LENGTH && (
                  <Box fullWidth align="flex-end">
                    <Button textKey={Texts.saveButtonText} onClick={formProps.handleSubmit as any} />
                  </Box>
                )}
              </Box>
              <OrgContainer fullPadding>
                <Box fullWidth>
                  <Box top bottom spacing={smallSpacing}>
                    <FigmaText textKey={Texts.adminSuperadminOrgListSectionSelected} />
                  </Box>
                  {selectedOrgs.map((org, key) => (
                    <SettingsSuperAdminOrgRow key={key} formProps={formProps} org={org} />
                  ))}
                </Box>
                <Box fullWidth>
                  <Box top bottom spacing={smallSpacing}>
                    <FigmaText textKey={Texts.adminSuperadminOrgListSectionMostActive} />
                  </Box>
                  {mostActiveOrgs.map((org, key) => (
                    <SettingsSuperAdminOrgRow key={key} formProps={formProps} org={org} />
                  ))}
                </Box>
                <Box fullWidth>
                  <Box top bottom spacing={smallSpacing}>
                    <FigmaText textKey={Texts.adminSuperadminOrgListSectionOther} />
                  </Box>
                  {otherOrgs.map((org, key) => (
                    <SettingsSuperAdminOrgRow key={key} formProps={formProps} org={org} />
                  ))}
                </Box>
              </OrgContainer>
              <Box top fullWidth align="flex-end">
                <Button textKey={Texts.saveButtonText} onClick={formProps.handleSubmit as any} />
              </Box>
            </Box>
          </Box>
        )
      }}
    </Formik>
  )
}

const OrgContainer = styled(Box)`
  background: ${Colors.whiteBg};
  border: 1px solid ${Colors.neutralUnselected};
  border-radius: ${smallBorderRadius};
`
function useOrgsForSuperAdmin(orgs: Organization[], userId: Id) {
  const { data: user = { _id: userId, organizationIds: [] as string[] } } = useUser(userId)
  const selectedOrgs = orgs.filter((org) => user?.organizationIds?.includes(org._id))
  const mostActiveOrgs = orgs
    .filter((org) => !!user?.organizationIds && !user.organizationIds?.includes(org._id))
    .filter((org) => !!org.lastUserActiveAt)
    .sort((a, b) => dayjs(b.lastUserActiveAt).valueOf() - dayjs(a.lastUserActiveAt).valueOf())
    .filter((_, i) => i < 10)
  const orgIds = [...selectedOrgs.map((o) => o._id), ...mostActiveOrgs.map((o) => o._id)]
  const otherOrgs = orgs.filter((org) => !orgIds.includes(org._id))
  return { selectedOrgs, mostActiveOrgs, otherOrgs }
}
