import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useState } from 'react'
import useAppState from '../hooks/useAppState'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { SUBSCRIPTION_SETTINGS_WIDTH } from '../libs/HardCodedSizes'
import { defaultSubscriptionInfo, SubscriptionInfo, SubscriptionInfoType } from '../libs/SubscriptionInfo'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { capitalize } from '../ygdrasil/libs/Common'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { DefaultOrgSubscriptionInfo, OrgSubscriptionInfo } from '../ygdrasil/libs/OrgSubscriptionInfo'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { Subscription } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { SettingsSubscriptionJsonView } from './SettingsSubscriptionJsonView'

const LEFT_WIDTH = 30
const RIGHT_WIDTH = 70
const ACTIVE_SUBSCRIPTION_STATUSES: Subscription['status'][] = ['active', 'trialing', 'past_due', 'unpaid']

export default function SettingsSubscription() {
  const { state } = useAppState()
  const orgSubscriptionInfo = OrgSubscriptionInfo[state.selectedOrganizationId] || DefaultOrgSubscriptionInfo
  const [loading, setLoading] = useState(false)
  const { data: org } = useOrganization(state)
  const config = useLoggedInConfig()

  const hasSubscription = !!org?.subscription && ACTIVE_SUBSCRIPTION_STATUSES.includes(org.subscription.status)

  const { settingsPaymentMethodTextKey, onClickContinue }: SubscriptionInfoType =
    SubscriptionInfo[org?.subscription?.status || ''] || defaultSubscriptionInfo

  const _onClickContinue = () =>
    Promise.resolve(setLoading(true))
      .then(() => onClickContinue(orgSubscriptionInfo, state))
      .catch(captureAndNotifyError)
      .finally(() => setLoading(false))

  const cancelAt = org?.subscription?.cancel_at?.seconds
  const status = capitalize(org?.subscription?.status || '')

  return (
    <Box>
      <Container fullWidth top>
        <Box direction="row" fullWidth justify="space-between">
          <Box width={`${LEFT_WIDTH}%`}>
            <FigmaText styleTextKey={Texts.adminSubscriptionMethodLabel} textKey={Texts.adminSubscriptionButtonText} />
          </Box>
          <Box width={`${RIGHT_WIDTH}%`}>
            <FigmaText textKey={Texts.adminSubscriptionTypeDefaultValue} />
          </Box>
        </Box>
        <Box top direction="row" fullWidth justify="space-between">
          <Box width={`${LEFT_WIDTH}%`}>
            <FigmaText styleTextKey={Texts.adminSubscriptionMethodLabel} textKey={Texts.adminSubscriptionUsersLabel} />
          </Box>
          <Box width={`${RIGHT_WIDTH}%`}>
            <FigmaText textKey={Texts.adminSubscriptionUsersValue} text={orgSubscriptionInfo.users.toString()} />
          </Box>
        </Box>
        {status && (
          <Box top direction="row" fullWidth justify="space-between">
            <Box width={`${LEFT_WIDTH}%`}>
              <FigmaText textKey={Texts.adminSubscriptionMethodLabel} text="Status" />
            </Box>
            <Box width={`${RIGHT_WIDTH}%`}>
              <FigmaText textKey={Texts.adminSubscriptionUsersValue} text={status} />
            </Box>
          </Box>
        )}
        <Box top direction="row" fullWidth justify="space-between">
          <Box width={`${LEFT_WIDTH}%`}>
            <FigmaText styleTextKey={Texts.adminSubscriptionMethodLabel} textKey={Texts.adminSubscriptionMethodLabel} />
          </Box>
          <Box width={`${RIGHT_WIDTH}%`} onClick={_onClickContinue} link>
            <FigmaTextLoader textKey={settingsPaymentMethodTextKey} loading={hasSubscription && loading} />
          </Box>
        </Box>
        {cancelAt && (
          <Box top direction="row" fullWidth justify="space-between">
            <Box width={`${LEFT_WIDTH}%`}>
              <FigmaText textKey={Texts.adminSubscriptionMethodLabel} text="Expires At" />
            </Box>
            <Box width={`${RIGHT_WIDTH}%`}>
              <FigmaText textKey={Texts.adminSubscriptionUsersValue} text={dayjs(cancelAt * 1000).format(YYYY_MM_DD)} />
            </Box>
          </Box>
        )}
      </Container>
      {!hasSubscription && (
        <Box top>
          <Button
            textKey={Texts.adminSubscriptionChangeToStripeButtonText}
            onClick={_onClickContinue}
            loading={loading}
          />
        </Box>
      )}

      {config.enableSubscriptionJsonView && (
        <Box top>
          <SettingsSubscriptionJsonView data={org?.subscription || {}} />
        </Box>
      )}
    </Box>
  )
}

const Container = styled(Box)`
  min-width: ${SUBSCRIPTION_SETTINGS_WIDTH}px;
  max-width: ${SUBSCRIPTION_SETTINGS_WIDTH}px;
`
