import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { minSpacing } from '../enums/Spacings'
import { formatQuaterTextForObjective } from '../libs/RoadmapHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { RoadmapObjective } from './RoadmapObjective'

type Visibility = 'hidden' | 'visible'

type Props = {
  objective: Objective
  headerStartDate: string
  headerEndDate: string
  visibility: Visibility
  width?: string
  allObjectives: Objective[]
}

export const RoadmapObjectiveWithLabel = ({
  allObjectives,
  objective,
  headerStartDate,
  headerEndDate,
  visibility,
  width
}: Props) => (
  <Container spacing={minSpacing} fullPadding visibility={visibility} width={width} fullWidth={!width}>
    <Box fullWidth>
      {dayjs(objective.startDate).isBefore(dayjs(headerStartDate).startOf('quarter')) && (
        <Box alignSelf="flex-start">
          <FigmaText
            textKey={Texts.formatRoadmapObjectiveStartPeriod}
            text={formatText(getFigmaText(Texts.formatRoadmapObjectiveStartPeriod), [
              formatQuaterTextForObjective(objective.startDate, objective)
            ])}
          />
        </Box>
      )}
      {dayjs(objective.endDate).isAfter(dayjs(headerEndDate).endOf('quarter')) && (
        <Box alignSelf="flex-end">
          <FigmaText
            textKey={Texts.formatRoadmapObjectiveEndPeriod}
            text={formatText(getFigmaText(Texts.formatRoadmapObjectiveEndPeriod), [
              formatQuaterTextForObjective(objective.endDate, objective)
            ])}
          />
        </Box>
      )}
    </Box>
    <RoadmapObjective objective={objective} allObjectives={allObjectives} />
  </Container>
)

const Container = styled(Box)<{ visibility: Visibility }>`
  visibility: ${({ visibility }) => visibility}; ;
`
