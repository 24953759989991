import React from 'react'
import { useHistory } from 'react-router'
import styled from '@emotion/styled'
import FigmaText from '../ygdrasil/components/FigmaText'
import RoutePath from '../ygdrasil/libs/RoutePath'
import Texts from '../ygdrasil/libs/Texts'
import { bigSpacing } from '../enums/Spacings'
import { ONBOARDING_MAX_WIDTH_MOBILE } from '../libs/HardCodedSizes'
import Box from './Box'
import Button from './Button'

export default function SignInDisabled() {
  const history = useHistory()
  return (
    <Box fullWidth align="center" fullHeight justify="center">
      <Container fullWidth align="center" spacing={bigSpacing}>
        <Box fullWidth bottom alignText="center">
          <FigmaText textKey={Texts.onboardingDisabledAccountInfo} />
        </Box>
        <Box fullWidth align="center">
          <Button onClick={() => history.push(RoutePath.ROOT)} fullWidth>
            <FigmaText textKey={Texts.onboardingReturnToHomeButtonText} />
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

const Container = styled(Box)`
  max-width: ${ONBOARDING_MAX_WIDTH_MOBILE};
`
