import styled from '@emotion/styled'
import { Drawer } from '@mui/material'
import React from 'react'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useShouldShowExpandedMenu from '../hooks/useShouldShowExpandedMenu'
import useUnassignedTeamObjectives from '../hooks/useUnassignedTeamObjectives'
import { MENU_WIDTH } from '../libs/HardCodedSizes'
import { getStateForStrategicView, getStateForTeamView } from '../libs/MenuHelper'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import { COMPANY_LOGO_SIZE_BIG, UNASSIGNED_TEAM_ID } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import AddLogoMenu from './AddLogoMenu'
import Box from './Box'
import { DEFAULT_ICON_SIZE, MenuUnassignedGoalsIcon } from './Icons'
import MenuBusinessPlanButton from './MenuBusinessPlanButton'
import { MenuButton } from './MenuButton'
import MenuCollapsed from './MenuCollapsed'
import MenuContactSupport from './MenuContactSupport'
import MenuButtonDashboard from './MenuButtonDashboard'
import MenuExpandCollapseLine from './MenuExpandCollapseLine'
import MenuButtonGrowthView from './MenuButtonGrowthView'
import MenuButtonTeam from './MenuButtonTeam'
import MenuButtonStrategyView from './MenuButtonStrategyView'
import { ModalAddLogo } from './ModalAddLogo'
import OrganizationLogo from './OrganizationLogo'
import MenuButtonMyActivities from './MenuButtonMyActivities'

const WIDTH = MENU_WIDTH

export default function MenuExpanded() {
  const { state, setState } = useAppState()
  const [showAddLogoModal, setShowAddLogoModal] = React.useState(false)
  const unassignedTeamObjectives = useUnassignedTeamObjectives()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const hasSelectedUnassignedGoals = state.selectedTeamId === UNASSIGNED_TEAM_ID
  const config = useLoggedInConfig()

  const shouldShowExpandedMenu = useShouldShowExpandedMenu()
  const onClickTopObjectives = () => {
    setState(getStateForStrategicView(state))
  }

  const onCloseEditLogoMenu = () => {
    setAnchorEl(undefined)
    setShowAddLogoModal(false)
  }

  const onClickUnassignedGoals = () => setState(getStateForTeamView(UNASSIGNED_TEAM_ID, state))

  const onClickOrganizationLogo: (e: any) => unknown = (e) =>
    config.enableEditOrganizationLogo && setAnchorEl(e.currentTarget)

  if (!shouldShowExpandedMenu) return <MenuCollapsed />

  return (
    <Drawer variant="permanent">
      <Container left right bottom justify="space-between" fullHeight position="relative">
        <Box fullWidth>
          <AddLogoMenu anchorEl={anchorEl} onClose={onCloseEditLogoMenu} />
          {showAddLogoModal && <ModalAddLogo onClose={() => setShowAddLogoModal(false)} />}
          <Box fullWidth align="center" top bottom>
            <OrganizationLogo onClick={onClickOrganizationLogo} size={COMPANY_LOGO_SIZE_BIG} />
          </Box>

          <Box bottom spacing={smallSpacing} fullWidth>
            <MenuBusinessPlanButton />
          </Box>
          <Box bottom spacing={smallSpacing} fullWidth id="GrowthViewMenuButton">
            <MenuButtonGrowthView />
          </Box>
          <Box bottom spacing={smallSpacing} fullWidth>
            <MenuButtonDashboard />
          </Box>

          <Box bottom fullWidth spacing={smallSpacing} id="StrategyViewMenuButton">
            <MenuButtonStrategyView onClick={onClickTopObjectives} />
          </Box>
          <Box bottom fullWidth spacing={smallSpacing} id="TeamViewMenuButton">
            <MenuButtonTeam />
          </Box>
          <Box bottom spacing={smallSpacing} fullWidth id="MyActivities">
            <MenuButtonMyActivities />
          </Box>

          {unassignedTeamObjectives.length > 0 && (
            <Box bottom fullWidth spacing={smallSpacing}>
              <MenuButton
                onClick={onClickUnassignedGoals}
                isSelected={hasSelectedUnassignedGoals}
                Icon={MenuUnassignedGoalsIcon}
                textKey={Texts.applicationMenuUnassignedGoalsButtonText}
              />
            </Box>
          )}
        </Box>

        <MenuContactSupport />
        <Box style={{ right: -DEFAULT_ICON_SIZE / 2 }} pointer position="absolute" fullHeight>
          <MenuExpandCollapseLine />
        </Box>
      </Container>
    </Drawer>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.surface};
  min-width: auto;
  max-width: ${WIDTH}px;
  overflow-x: hidden;
`
