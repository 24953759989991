import FigmaText from '../ygdrasil/components/FigmaText'
import { formatObjectivePeriod } from '../ygdrasil/libs/ObjectiveHelper'
import { ItemData } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { NoWrapFigmaText } from './StyledComponents'

export function StrategyViewObjectiveListRowPeriod({ data }: { data: ItemData }) {
  if (shouldShowPeriodColumn(data) === false) return null

  const period = data.searchType === 'objective' ? formatObjectivePeriod(data.data as Objective) : undefined
  return (
    <Box>
      <NoWrapFigmaText textKey={Texts.cssObjectiveListRowObjectivePeriod} text={period} />
    </Box>
  )
}

export function StrategyViewObjectiveListRowPeriodHeader({
  data,
  styleTextKey = Texts.objectiveListHeaderObjectivePeriod
}: {
  data?: ItemData
  styleTextKey?: AntiloopTextType
}) {
  if (shouldShowPeriodColumn(data) === false) return null
  return <FigmaText textKey={Texts.objectiveListHeaderObjectivePeriod} styleTextKey={styleTextKey} />
}

export const shouldShowPeriodColumn = (data?: ItemData) => {
  if (!data) return true // TODO: should show Period column for main table
  if (data.searchType === 'keyResult') return false
  if (data.searchType === 'activity') return false
  return true
}
