import { SignInPageQueryParams } from '../ygdrasil/hooks/useQueryParams'
import type { PreferredLoginMethod, SignInState, SignInSteps } from '../ygdrasil/types/SignInTypes'

export function getDefaultSignInState(
  queryParams: SignInPageQueryParams,
  localStorageItem: any,
  signInStateFromProps?: Partial<SignInState>
) {
  const {
    email: emailFromQueryParam,
    invitationToken: invitationTokenFromQueryParam,
    preferredLoginMethod,
    step // used in SignUpPage to not make it crash see PRODCUT-444
  } = queryParams

  const defaultSignInState: SignInState = { ...localStorageItem, ...signInStateFromProps } // used to set step from VerifyActionCode component
  if (step) defaultSignInState.step = step as SignInSteps
  if (emailFromQueryParam) defaultSignInState.data.email = emailFromQueryParam
  if (invitationTokenFromQueryParam) defaultSignInState.data.invitationToken = invitationTokenFromQueryParam
  if (preferredLoginMethod) defaultSignInState.preferredLoginMethod = preferredLoginMethod as PreferredLoginMethod
  return defaultSignInState
}
