import { State } from '../../hooks/useAppState'
import { ActivityStatus } from '../enums/ActivityStatus'
import { AllItems } from '../hooks/useAllItems'
import { Activity, KeyResult, Objective, ObjectiveType, SearchDates } from '../types/types'
import { isMatch } from './isMatch'

import { inRankOrder } from './RankHelper'

export type SearchState = {
  searchText: string
  searchTextGrowthView: string
  showArchivedObjectives?: boolean
  showTeamObjectivesWhenNoTeamIsSelected?: boolean
  showArchivedGrowthIndicators?: boolean
  leadUserIds?: string[]
  activityLeadUserId?: string
  selectedItemId?: string
  searchDates: SearchDates
  strategicPillar?: string
  strategicObjective?: boolean
  selectedObjectiveId?: string
  selectedIndicatorId?: string
}

export interface TreeData {
  rootId: string
  items: Record<string, TreeItem>
}

export declare type TreeItem = {
  id: string
  children: TreeItem[]
  hasChildren?: boolean
  isExpanded?: boolean
  isChildrenLoading?: boolean
  data: SearchObject
  ordo?: number
  createdAt: string
}

export declare type TreeSourcePosition = {
  parentId: string
  index: number
}
export declare type TreeDestinationPosition = {
  parentId: string
  index?: number
}

export type SearchType = 'objective' | 'keyResult' | 'activity'

export type SearchObject = {
  id: string
  rootObjectiveId?: string
  indicatorIds: string[]
  searchType: SearchType
  parentId?: string
  data: Objective | KeyResult | Activity
  isSelected?: boolean
  autoFocus?: boolean
  startDate?: string
  endDate?: string
  objectiveType?: ObjectiveType
  strategicPillarIds: string[]
  name: string
  leadUserId?: string
  leadUserIdIncludingParent?: string
  isArchived?: boolean
  activityStatus?: ActivityStatus
  ordo?: number
  createdAt: string
  teamIds: string[]
  allChildren: SearchObject[] // TODO WRITE TEST migrate to InternalSearchObject
  children: SearchObject[] // TODO WRITE TEST migrate to InternalSearchObject
  allParents: SearchObject[]
  level: number
  description?: string

  isMatch?: boolean
  hasMatchedChild?: boolean
  hasSelectedChild?: boolean
}

export type SearchResult = { [_id: string]: SearchObject }

export const ROOT_ID = 'ROOT_ID'

export const DEFAULT_TREE_DATA = {
  rootId: ROOT_ID,
  items: {}
}

export const getSearchResultForAllItems = ({ allObjectives, allIndicators, allActivities }: AllItems, state: State) =>
  getSearchResult(allObjectives, allIndicators, allActivities, state)

export const getSearchResult = (
  allObjectives: Objective[],
  indicators: KeyResult[],
  allActivities: Activity[],
  state: State
): TreeData => {
  const items = getSearchResultItems(allObjectives, indicators, allActivities, state)

  const rootObjectiveids = Object.keys(items)
    .filter((key) => !!items[key] && !items[key].data.parentId)
    .filter((key) => shouldIncludeSearchObject(items[key].data, state))
    .map((key) => items[key])
    .sort(inRankOrder)

  items[ROOT_ID] = { id: ROOT_ID, children: rootObjectiveids, data: { name: 'Root' } as any, createdAt: '' }

  return {
    rootId: ROOT_ID,
    items
  }
}

export const getSearchResultItems = (
  allObjectives: Objective[],
  indicators: KeyResult[],
  activities: Activity[],
  state: State
): Record<string, TreeItem> => {
  const searchObjects = getSearchObjects(allObjectives, indicators, activities)

  return Object.keys(searchObjects).reduce((a, key) => {
    const so = searchObjects[key]
    if (!shouldIncludeSearchObject(so, state, {} as SearchObject)) return a
    a[so.id] = mapTreeItem(so, state)
    return a
  }, {})
}

export const isIncludedObjective = (so: SearchObject, state: State) => isIncludedObjectiveForSearchObjects(so, state)

export const isIncludedObjectiveForSearchObjects = (so: SearchObject, state: State) => isMatch(so, state)

const isSelected = (item: SearchObject, { selectedItemId }: SearchState) =>
  !!selectedItemId && item.id === selectedItemId

export const getSearchObjects = (
  allObjectives: Objective[],
  indicators: KeyResult[],
  activities: Activity[]
): { [key: string]: SearchObject } => {
  const map = {}
  allObjectives.forEach((o) => {
    if (!!o.parentObjectiveId) return
    const so = getSearchObjectForObjective(o, allObjectives, indicators, activities)
    map[so.id] = so
    so.allChildren.forEach((so) => (map[so.id] = so))
  })

  return map
}

export const getSearchObjectForObjective = (
  objective: Objective,
  allObjectives: Objective[],
  indicators: KeyResult[],
  activities: Activity[],
  parent?: SearchObject
): SearchObject => {
  const level = (parent?.level || -1) + 1

  const soObjective: SearchObject = {
    id: objective._id,
    rootObjectiveId: parent?.rootObjectiveId || objective._id,
    indicatorIds: [],
    parentId: objective.parentObjectiveId,
    searchType: 'objective' as SearchType,
    data: objective,
    startDate: objective.startDate,
    endDate: objective.endDate,
    objectiveType: objective.type,
    strategicPillarIds: parent?.strategicPillarIds || objective.strategicPillarIds || [],
    name: objective.name,
    leadUserId: objective.leadUserId,
    leadUserIdIncludingParent: objective.leadUserId || parent?.leadUserId,
    isArchived: objective.isArchived,
    activityStatus: undefined,
    ordo: objective.ordo,
    createdAt: objective.createdAt,
    allChildren: [],
    children: [],
    teamIds: objective.teamIds,
    level,
    description: objective.description,
    allParents: parent ? [parent, ...parent.allParents] : []
  }

  const children = allObjectives
    .filter((o) => !!o.parentObjectiveId && o.parentObjectiveId === objective._id)
    .map((o) => getSearchObjectForObjective(o, allObjectives, indicators, activities, soObjective))

  const allChildren = [...children, ...children.reduce((a, c) => a.concat(c.allChildren), [] as SearchObject[])]

  soObjective.children = children
  soObjective.allChildren = allChildren

  indicators
    .filter((kr) => kr.parentId === objective._id)
    .forEach((kr) => {
      const soKeyResult: SearchObject = {
        id: kr._id,
        indicatorIds: [kr._id],
        rootObjectiveId: soObjective?.rootObjectiveId,
        parentId: objective._id,
        searchType: 'keyResult' as SearchType,
        data: kr,
        startDate: objective.startDate,
        endDate: objective.endDate,
        objectiveType: objective.type,
        strategicPillarIds: objective.strategicPillarIds,
        name: kr.name,
        leadUserId: kr.leadUserId,
        leadUserIdIncludingParent: kr.leadUserId || soObjective.leadUserId,
        isArchived: objective.isArchived,
        activityStatus: undefined,
        ordo: kr.ordo,
        createdAt: kr.createdAt,
        allChildren: [],
        children: [],
        teamIds: [],
        level: soObjective.level + 1,
        description: kr.description,
        allParents: [soObjective, ...soObjective.allParents]
      }

      soObjective.indicatorIds.push(kr._id)
      soObjective.children.push(soKeyResult)
      soObjective.allChildren.push(soKeyResult)

      activities
        .filter((a) => a.parentId === kr._id)
        .forEach((activity) => {
          const soActivity: SearchObject = {
            id: activity._id,
            indicatorIds: [kr._id],
            rootObjectiveId: soObjective?.rootObjectiveId,
            parentId: kr._id,
            searchType: 'activity' as SearchType,
            data: activity,
            startDate: objective.startDate,
            endDate: objective.endDate,
            objectiveType: objective.type,
            strategicPillarIds: objective.strategicPillarIds,
            name: activity.name,
            leadUserId: activity.leadUserId,
            leadUserIdIncludingParent: activity.leadUserId || soKeyResult.leadUserId,
            isArchived: objective.isArchived,
            activityStatus: activity.status,
            ordo: activity.ordo,
            createdAt: activity.createdAt,
            allChildren: [],
            children: [],
            teamIds: [],
            level: soKeyResult.level + 1,
            description: undefined,
            allParents: [soKeyResult, ...soKeyResult.allParents]
          }

          soKeyResult.children.push(soActivity)
          soKeyResult.allChildren.push(soActivity)
          soObjective.allChildren.push(soActivity)
        })
    })

  return soObjective
}

export const inSearchTypeAndRankOrder = (a: SearchObject, b: SearchObject) => {
  const searchTypeOrder = inSearchTypeOrder(a, b)
  if (searchTypeOrder !== 0) return searchTypeOrder
  return inRankOrder(a.data, b.data)
}

const SEARCH_TYPE_ORDER: { [key: string]: number } = { objective: 1, keyResult: 0, activity: 2 }

export const inSearchTypeOrder = (a: SearchObject, b: SearchObject): number =>
  (SEARCH_TYPE_ORDER[a.searchType] || 0) - (SEARCH_TYPE_ORDER[b.searchType] || 0)

export function mapTreeItem(so: SearchObject, state: State): TreeItem {
  const data = {
    ...so,
    isMatch: isMatch(so, state),
    isSelected: isSelected(so, state.searchState),
    hasSelectedChild: hasSelectedChild(so, state),
    hasMatchedChild: hasMatchedChild(so, state)
  }

  return {
    id: so.id,
    hasChildren: so.children.length > 0,
    children: so.children
      .map((c) => mapTreeItem(c, state))
      .filter((c) => shouldIncludeSearchObject(c.data, state, so))
      .sort((a, b) => inSearchTypeAndRankOrder(a.data, b.data)),
    isExpanded: data.isMatch || data.hasMatchedChild || data.isSelected || data.hasSelectedChild,
    data,
    createdAt: so.createdAt,
    ordo: so.ordo
  }
}

function shouldIncludeSearchObject(child: SearchObject, state: State, parent?: SearchObject): unknown {
  return (
    // TODO WRITE TEST
    (parent && isMatch(parent, state)) ||
    // TODO WRITE TEST
    isMatch(child, state) !== false ||
    // TODO WRITE TEST
    hasMatchedChild(child, state) ||
    // TODO WRITE TEST
    isSelected(child, state.searchState) ||
    // TODO WRITE TEST
    hasSelectedChild(child, state)
  )
}

function hasSelectedChild(searchObject: SearchObject, state: State): boolean | undefined {
  return searchObject.allChildren.some((c) => isSelected(c, state.searchState))
}

function hasMatchedChild(searchObject: SearchObject, state: State): boolean | undefined {
  return searchObject.allChildren.some((c) => isMatch(c, state))
}
