import React from 'react'
import { PROGRESS_BAR_HEIGHT_KEY_RESULT } from '../libs/HardCodedSizes'
import * as KeyResultHelper from '../ygdrasil/libs/KeyResultHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { KeyResult } from '../ygdrasil/types/types'
import Box from './Box'
import { GraphPopover } from './GraphPopover'
import { KeyResultSliderPopper } from './KeyResultSliderPopper'
import LinearProgressWithLabel from './LinearProgressWithLabel'
import { shouldShowPopper } from './StrategyViewObjectiveListRowProgressKeyResultHelper'

export function StrategyViewObjectiveListRowProgressKeyResult({ item, parentItem }: { item: Item; parentItem?: Item }) {
  const { data: itemData } = item
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()

  const _shouldShowPopper = shouldShowPopper(item)
  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => _shouldShowPopper && setAnchorEl(e.currentTarget)
  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => setAnchorEl(undefined)

  const ratio = KeyResultHelper.getCurrentRatio(itemData.data as KeyResult)

  return (
    <>
      <Box fullWidth pointer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} direction="row" align="center">
        <LinearProgressWithLabel value={ratio} height={PROGRESS_BAR_HEIGHT_KEY_RESULT} />

        {parentItem && (
          <KeyResultSliderPopper
            anchorEl={_shouldShowPopper ? anchorEl : undefined}
            item={item}
            parentItem={parentItem}
            GraphComponent={GraphPopover}
          />
        )}
      </Box>
    </>
  )
}
