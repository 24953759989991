import MaterialCheckbox, { CheckboxProps } from '@mui/material/Checkbox'
import { FormikProps } from 'formik'
import styled from '@emotion/styled'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Colors from '../ygdrasil/libs/Colors'

type Props<T> = CheckboxProps & { formProps: FormikProps<T>; name: string; onChange?: (checked: boolean) => unknown }
export default function CheckboxFormik<T>(props: Props<T>) {
  const { formProps, name, ..._props } = props
  const value = !!formProps.values[name]
  return (
    <StyledCheckbox
      checkedIcon={<StyledCheckboxIcon />}
      {..._props}
      name={name}
      onChange={(e) => {
        formProps.setFieldValue(name, !value)
        props.onChange?.(!value)
      }}
      checked={value}
    />
  )
}

const StyledCheckbox = styled(MaterialCheckbox)`
  padding: 0px;
`

export const StyledCheckboxIcon = styled(CheckBoxIcon)`
  color: ${Colors.primaryBase};
`
