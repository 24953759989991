import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getFocusedObjectivesForState } from '../libs/StrategyViewHelper'
import { isStrategicView } from '../libs/MenuHelper'
import { useAllVisibleItems } from '../ygdrasil/hooks/useAllVisibleItems'
import { MAX_TOP_OBJECTIVES } from '../ygdrasil/libs/Consts'
import { Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { FocusedObjective } from './FocusedObjective'
import { FocusedObjectivesTeamView } from './FocusedObjectivesTeamView'
import styled from '@emotion/styled'

export function FocusedObjectives() {
  const { state } = useAppState()
  const { shouldShowExpandedMenu } = state
  const allItems = useAllVisibleItems()
  const focusedObjectives = getFocusedObjectivesForState(allItems, state)
  const wrapperWidth = `calc(100vw - ${shouldShowExpandedMenu ? '274px' : '74px'})`
  return (
    <Box fullWidth top spacing={smallSpacing}>
      {isStrategicView(state) && (
        <FocusedObjectivesStrategyView objectives={focusedObjectives} wrapperWidth={wrapperWidth} />
      )}
      {!isStrategicView(state) && (
        <FocusedObjectivesTeamView objectives={focusedObjectives} wrapperWidth={wrapperWidth} />
      )}
    </Box>
  )
}

const FocusedObjectivesStrategyView = ({
  objectives,
  wrapperWidth
}: {
  objectives: Objective[]
  wrapperWidth: string
}) => {
  return (
    <Box fullWidth bottom style={{ width: wrapperWidth, overflow: 'hidden' }}>
      <Wrapper fullWidth direction="row" right gap={smallSpacing}>
        {objectives
          .filter((x, i) => i < MAX_TOP_OBJECTIVES)
          .map((t, key, arr) => (
            <FocusedObjective key={key} objective={t} index={key} numberOfObjectives={arr.length} />
          ))}
      </Wrapper>
    </Box>
  )
}

const Wrapper = styled(Box)`
  overflow: auto;
`
