import { DrawerProps } from '@mui/material'
import React, { useEffect } from 'react'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import { OurDrawer } from './Drawers'

export default function DrawerGlobal<T>(props: {
  event: Events
  ContentComponent: React.FC<T>
  onClose?: () => unknown
  drawerProps?: DrawerProps
}) {
  const { event, ContentComponent, drawerProps } = props
  const [visible, setVisible] = React.useState(false)
  const [contentProps, setContentProps] = React.useState<T>()
  const onClose = () => {
    setContentProps(undefined)
    setVisible(false)
    props.onClose?.()
  }

  useEffect(() => {
    eventEmitter.on(event, (contentProps = {}) => {
      setContentProps(contentProps)
      setVisible(true)
    })

    return () => {
      eventEmitter.removeAllListeners(event)
    }
  }, [])

  if (!contentProps) return null

  return (
    <OurDrawer
      {...drawerProps}
      open={visible}
      content={<ContentComponent {...contentProps} onClose={onClose} />}
      onClose={onClose}
    />
  )
}
