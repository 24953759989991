/* eslint-disable react-hooks/rules-of-hooks */
import invariant from 'invariant'
import React from 'react'
import { bigSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useMsal } from '../hooks/useMsal'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import ConnectionType from '../ygdrasil/enums/ConnectionType'
import { EVERY_DAY, ONE_SECOND } from '../ygdrasil/libs/Consts'
import {
  createConnection,
  createDatasource,
  getConnections,
  updateConnection,
  updateDatasource
} from '../ygdrasil/libs/DBApiHandler'
import { isValidData } from '../ygdrasil/libs/ExcelHelper'
import { captureException } from '../ygdrasil/libs/SentryHelperDeprecated'
import {
  EditDatasourceMachineState,
  EditDatasourceMachineSteps,
  EditDatasourceMachineType,
  EditDatasourceMachineViewProps
} from '../ygdrasil/types/EditDatasourceMachine'
import { Connection, Datasource } from '../ygdrasil/types/types'
import Box from './Box'
import {
  EditDatasourceMachineProps,
  getFirstStep,
  GOOGLE_SERVICE_ACCOUNT_EMAIL
} from '../libs/EditDatasourceMachineHelper'
import EditDatasourceMachineViewEditConnection from './EditDatasourceMachineViewEditConnection'
import EditDatasourceMachineViewEditGoogleDatasource from './EditDatasourceMachineViewEditGoogleDatasource'
import EditDatasourceMachineViewEditSheet from './EditDatasourceMachineViewEditSheet'
import EditDatasourceMachineViewVerifyData from './EditDatasourceMachineViewVerifyData'
import Loader from './Loader'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { useToggleChatBubble } from '../hooks/useToggleChatBubble'
import dayjs from 'dayjs'

const DEFAULT_STATE: EditDatasourceMachineState = {
  data: {
    rows: [],
    values: [],
    connectionType: ConnectionType.EXCEL_ONLINE
  },
  step: EditDatasourceMachineSteps.EDIT_CONNECTION,
  isLoading: false
}

const Views: EditDatasourceMachineType = {
  [EditDatasourceMachineSteps.EDIT_GOOGLE_DATA_SOURCE]: EditDatasourceMachineViewEditGoogleDatasource,
  [EditDatasourceMachineSteps.EDIT_CONNECTION]: EditDatasourceMachineViewEditConnection,
  [EditDatasourceMachineSteps.EDIT_SHEET]: EditDatasourceMachineViewEditSheet,
  [EditDatasourceMachineSteps.VERIFY_DATA]: EditDatasourceMachineViewVerifyData
}

const EditDatasourceMachine = (props: EditDatasourceMachineProps) => {
  const { connection, onClose, onDataSourceUpdated, dataSource, connectionType } = props
  useToggleChatBubble()

  const defaultMachineState: EditDatasourceMachineState = {
    ...DEFAULT_STATE,
    data: { ...DEFAULT_STATE.data, dataSource, connection },
    step: getFirstStep(props)
  }

  const [machineState, setMachineState] = React.useState<EditDatasourceMachineState>(defaultMachineState)
  const { state } = useAppState()
  const { data: org } = useOrganization(state)

  const { openPopupWindow: openLoginPopupWindowOffice365 } = useMsal()

  if (!machineState)
    return (
      <Box fullWidth fullPadding spacing={bigSpacing} align="center">
        <Loader />
      </Box>
    )

  const onPressContinue = (machineState: EditDatasourceMachineState) => {
    setMachineState({ ...machineState, isLoading: true })
    return Promise.resolve()
      .then((): Promise<EditDatasourceMachineState> => {
        switch (true) {
          case machineState.step === EditDatasourceMachineSteps.EDIT_CONNECTION:
            return Promise.resolve()
              .then(() => openLoginPopupWindowOffice365())
              .then(
                () =>
                  new Promise<Connection>((resolve) => {
                    const startTime = dayjs()
                    setInterval(
                      () =>
                        Promise.resolve()
                          .then(() => getConnections(state))
                          .then((connections) => {
                            const connection = connections.find(
                              (c) => c.updatedBy === state.user._id && dayjs(c.updatedAt).isAfter(startTime)
                            )
                            connection && resolve(connection as Connection)
                          }),
                      ONE_SECOND
                    )
                  })
              )
              .then((connection: Connection) => {
                invariant(connection, 'connection is required')
                const name = machineState.data.connectionName
                invariant(name, 'name is required')
                return updateConnection({ ...connection, name })
              })
              .then((connection) => {
                return {
                  ...machineState,
                  data: { ...machineState.data, connection },
                  step: EditDatasourceMachineSteps.EDIT_SHEET
                }
              })
          case machineState.step === EditDatasourceMachineSteps.EDIT_SHEET:
            return Promise.resolve({ ...machineState, step: EditDatasourceMachineSteps.VERIFY_DATA })
          case machineState.step === EditDatasourceMachineSteps.EDIT_GOOGLE_DATA_SOURCE: {
            invariant(org, 'org is required')
            return Promise.resolve()
              .then(() => {
                if (props.connection) return props.connection
                return createConnection(
                  {
                    uid: state.user._id,
                    name: `Google SpreadSheet (${GOOGLE_SERVICE_ACCOUNT_EMAIL})`,
                    connectionType: ConnectionType.GOOGLE_SHEETS,
                    organizationId: org._id,
                    homeAccountId: GOOGLE_SERVICE_ACCOUNT_EMAIL
                  },
                  state
                )
              })
              .then((connection) => ({
                ...machineState,
                data: { ...machineState.data, connection },
                step: EditDatasourceMachineSteps.VERIFY_DATA
              }))
          }
          case machineState.step === EditDatasourceMachineSteps.VERIFY_DATA:
            return Promise.resolve()
              .then(() => {
                const {
                  data: { dataSource, selectedDriveItem, selectedSheet, connection }
                } = machineState

                invariant(connection, 'connection is required')

                const driveItemId = selectedDriveItem?.id || dataSource?.driveItemId
                const sheetId = selectedSheet?.id || dataSource?.sheetId

                invariant(driveItemId, 'driveItemId is required')
                invariant(sheetId, 'sheetId is required')
                const _dataSource = {
                  ...dataSource,
                  connectionId: connection._id,
                  sheetId,
                  driveItemId,
                  organizationId: connection.organizationId,
                  interval: EVERY_DAY
                } as Datasource

                if (!!_dataSource._id) return updateDatasource(_dataSource)

                return createDatasource(_dataSource, state)
              })
              .then(() => onDataSourceUpdated())
              .then(() => {
                onClose({ stopPropagation: () => null })
                return machineState
              })
          default:
            throw new Error(`Unknown step ${machineState.step}`)
        }
      })
      .then((machineState: EditDatasourceMachineState) => setMachineState({ ...machineState, isLoading: false }))
      .catch((err) => {
        captureAndNotifyError(err)
        setMachineState({ ...machineState, isLoading: false })
      })
  }

  const editDatasourceMachineViewProps: EditDatasourceMachineViewProps = {
    state: machineState,
    onPressContinue: (state: EditDatasourceMachineState) => onPressContinue(state).catch(captureException),
    onPressSkip: (state: EditDatasourceMachineState) => onPressContinue(state).catch(captureException),
    onClickBack: () =>
      Promise.resolve()
        .then(() => {
          const connectionType = machineState.data.connection?.connectionType || machineState.data.connectionType
          switch (machineState.step) {
            case EditDatasourceMachineSteps.EDIT_SHEET:
              return { ...machineState, step: EditDatasourceMachineSteps.EDIT_CONNECTION }
            case EditDatasourceMachineSteps.VERIFY_DATA:
              return {
                ...machineState,
                step:
                  connectionType === ConnectionType.GOOGLE_SHEETS
                    ? EditDatasourceMachineSteps.EDIT_GOOGLE_DATA_SOURCE
                    : EditDatasourceMachineSteps.EDIT_SHEET
              }
            default:
              throw new Error(`Unknown step ${machineState.step}`)
          }
        })
        .then((state) => setMachineState(state)),
    onClose,
    setMachineState,
    isValidData
  }

  const { step } = editDatasourceMachineViewProps.state
  const component = Views[step]
  invariant(component, `Cant find Step for %s`, step)

  return React.createElement(component, editDatasourceMachineViewProps)
}

export default EditDatasourceMachine
