import Menu from '@mui/material/Menu'
import { MenuItem } from './MenuItem'
import React from 'react'
import { GrowthIndicator } from '../ygdrasil/types/types'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import useAppState from '../hooks/useAppState'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { updateGrowthIndicator } from '../ygdrasil/libs/ProcessHandler'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  growthIndicator: GrowthIndicator
}

export default function GrowthViewGrowthIndicatorRowMenuForArchivedIndicator(props: Props) {
  const { growthIndicator, anchorEl, onClose } = props
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const logEvent = useLogEvent()

  const onClickUnArchive = (e) => {
    onClose()
    updateGrowthIndicator({ ...growthIndicator, isArchived: false }, logEvent)
  }

  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItem
          onClick={onClickUnArchive}
          text={getTextForTextKey(Texts.unarchiveObjectiveButtonText, org)}
        ></MenuItem>
      </Menu>
    </div>
  )
}
