import styled from '@emotion/styled'
import { Formik } from 'formik'
import invariant from 'invariant'
import { Stringifiable } from 'query-string'
import { useState } from 'react'
import ZIndices from '../enums/Zindices'
import useAppState from '../hooks/useAppState'
import {
  getInitialValues,
  getSelectParentObjectiveOptions,
  InitialValues,
  shouldShowDeleteButton
} from '../libs/EditKeyResultDialogHelper'
import { onChangeLeadUserId, ONE, onSelectUnit, shouldShowMore, ZERO } from '../libs/EditKeyResultsSectionHelper'
import { DRAWER_WIDTH, DRAWER_WIDTH_STRING } from '../libs/HardCodedSizes'
import { KeyResultValidation } from '../libs/ValidationHelper'
import { stringify } from '../libs/WYSIWYGEditor'
import FigmaText from '../ygdrasil/components/FigmaText'
import Currency from '../ygdrasil/enums/Currency'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { syncDataForDatasource } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import Colors from '../ygdrasil/libs/Colors'
import { mapCssHeightToNumber } from '../ygdrasil/libs/Common'
import {
  GOAL_VALUE as _GOAL_VALUE,
  START_VALUE as _START_VALUE,
  SUGGESTABLE_NAME_LENGTH
} from '../ygdrasil/libs/Consts'
import { toBaseObject } from '../ygdrasil/libs/Mapper'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective, Unit } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import { DrawerFooterButtons } from './DrawerFooterButtons'
import { EditKeyResultsSectionHeader } from './EditKeyResultsSectionHeader'
import EditKeyResultsSectionUnitButtons from './EditKeyResultsSectionUnitButtons'
import { EditObjectiveDialogHeaderWithLine } from './EditObjectiveDialogHeaderWithLine'
import { EditObjectiveDialogShowMore } from './EditObjectiveDialogShowMore'
import LeadSelectFieldWithLabel from './LeadSelectFieldWithLabel'
import { RichTextEditorFormik } from './RichTextEditorFormik'
import { SelectDatasourceWithLabel } from './SelectDatasourceWithLabel'
import SelectFieldWithLabel from './SelectFieldWithLabel'
import SelectFieldWithTextKeyAndLabel from './SelectFieldWithTextKeyAndLabel'
import { TextFieldNumberInputWithLabelFormik, TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import { SuggestionButton } from './SuggestionButton'
import { smallSpacing } from '../enums/Spacings'
import { getSuggestKeyresultNameConversation } from '../ygdrasil/libs/ConversationHelper'

export type Props = {
  objective?: Partial<Objective>
  onSaveKeyResult: (keyResult: KeyResult) => unknown
  onCancel: () => unknown
  onDeleteKeyResult: () => unknown
  keyResult?: KeyResult
  showHeader?: boolean
  onClose: () => unknown
}

export const EditKeyResultsSection = (props: Props) => {
  const { onCancel, onDeleteKeyResult, onSaveKeyResult, showHeader = true, keyResult, objective, onClose } = props
  const { allObjectives, isLoading } = useAllItems()
  const { state } = useAppState()
  const config = useLoggedInConfig()
  const [showMore, setShowMore] = useState(shouldShowMore(keyResult))
  const initialValues: InitialValues = getInitialValues(keyResult, objective as Objective, state)

  const _onCancel = () => onCancel()

  const onSubmit = (values) => {
    const { startValue, goalValue } = values
    if (typeof startValue === 'string') values.startValue = parseFloat(startValue)
    if (typeof goalValue === 'string') values.goalValue = parseFloat(goalValue)
    if (values.unit !== Unit.MONEY) values.currency = undefined
    if (!!values.description) values.description = stringify(values.description)
    delete values.isNew // TODO WRUTE TEST, should not save isNew prop

    onSaveKeyResult({ ...toBaseObject(state), ...values } as KeyResult)

    if (values.dataSourceId) syncDataForDatasource(values.dataSourceId, state) // TODO WRITE TEST, should sync data for indicator
  }

  if (isLoading) return null

  return (
    <Formik onSubmit={onSubmit} validationSchema={KeyResultValidation} initialValues={initialValues}>
      {(formProps) => {
        const { values } = formProps
        return (
          <Box fullHeight fullWidth>
            <Box fullWidth fullHeight fullPadding>
              {showHeader && <EditKeyResultsSectionHeader {...props} onCancel={_onCancel} />}
              {showHeader && <EditObjectiveDialogHeaderWithLine textKey={Texts.rightPanelIndicatorHeader} />}
              <Box bottom fullWidth>
                <TextFieldWithLabelFormik
                  name="name"
                  fullWidth
                  formProps={formProps}
                  labelTextKey={Texts.rightPanelIndicatorSummaryLabel}
                />
              </Box>
              {!!keyResult?.name && keyResult.name.length > SUGGESTABLE_NAME_LENGTH && (
                <Box bottom spacing={smallSpacing} fullWidth>
                  <SuggestionButton
                    initialConversation={getSuggestKeyresultNameConversation(formProps.values.name, state)}
                    onNewSuggestion={(name) => formProps.setFieldValue('name', name)}
                  />
                </Box>
              )}
              {showMore && (
                <Box bottom fullWidth zIndex={ZIndices.high /** Link dropdown in rich text editor needs this*/}>
                  <RichTextEditorFormik
                    name="description"
                    formProps={formProps}
                    labelTextKey={Texts.rightPanelObjectiveEditObjectiveLongerDescriptionLabel}
                  />
                </Box>
              )}
              <Box bottom fullWidth>
                <EditKeyResultsSectionUnitButtons
                  keyResult={formProps.values}
                  onSelectUnit={(unit) => onSelectUnit(unit, formProps)}
                />
              </Box>
              <Box bottom fullWidth>
                {(() => {
                  switch (values.unit) {
                    case Unit.MONEY:
                      return (
                        <Box fullWidth>
                          <Box bottom fullWidth>
                            <SelectFieldWithLabel
                              labelTextKey={Texts.rightPanelIndicatorTypeCurrencyLabel}
                              onChange={({ target: { value } }) => formProps.setFieldValue(`currency`, value)}
                              options={Object.keys(Currency).map((key) => ({ key, value: Currency[key] }))}
                            />
                          </Box>
                          <Box direction="row" fullWidth align="center">
                            <StyledTextFieldWithLabelFormik
                              labelTextKey={Texts.rightPanelIndicatorStartValueLabel}
                              name={_START_VALUE}
                              formProps={formProps}
                            />
                            <Box left right>
                              <HeightSpacing />
                              <FigmaText textKey={Texts.rightPanelIndicatorStartValueLabel} text="/" />
                            </Box>
                            <StyledTextFieldWithLabelFormik
                              labelTextKey={Texts.rightPanelIndicatorEndValueLabel}
                              name={_GOAL_VALUE}
                              formProps={formProps}
                            />
                            <Box left>
                              <HeightSpacing />
                              <FigmaText textKey={Texts.currencyUsd} text={values.currency} />
                            </Box>
                          </Box>
                        </Box>
                      )
                    case Unit.BOOLEAN:
                      return (
                        <Box direction="row" fullWidth align="center">
                          <Box>
                            <FigmaText textKey={Texts.rightPanelIndicatorStartValueLabel} />
                            <ToggleButton name={_START_VALUE} otherName={_GOAL_VALUE} formProps={formProps} />
                          </Box>
                          <Box left right>
                            <HeightSpacing />
                            <FigmaText textKey={Texts.rightPanelIndicatorStartValueLabel} text="/" />
                          </Box>
                          <Box>
                            <FigmaText textKey={Texts.rightPanelIndicatorEndValueLabel} />
                            <ToggleButton name={_GOAL_VALUE} otherName={_START_VALUE} formProps={formProps} />
                          </Box>
                        </Box>
                      )
                    case Unit.INTEGRATION:
                      return (
                        <Box fullWidth>
                          <Box fullWidth bottom>
                            <SelectDatasourceWithLabel formProps={formProps} onClose={onClose} />
                          </Box>
                          <Box direction="row" fullWidth align="center">
                            <StyledTextFieldWithLabelFormik
                              labelTextKey={Texts.rightPanelIndicatorStartValueLabel}
                              name={_START_VALUE}
                              formProps={formProps}
                            />
                            <Box left right>
                              <FigmaText textKey={Texts.rightPanelIndicatorStartValueLabel} text="/" />
                            </Box>
                            <StyledTextFieldWithLabelFormik
                              labelTextKey={Texts.rightPanelIndicatorEndValueLabel}
                              name={_GOAL_VALUE}
                              formProps={formProps}
                            />
                          </Box>
                        </Box>
                      )
                    default:
                      return (
                        <Box direction="row" fullWidth align="center">
                          <StyledTextFieldWithLabelFormik
                            labelTextKey={Texts.rightPanelIndicatorStartValueLabel}
                            name={_START_VALUE}
                            formProps={formProps}
                          />
                          <Box left right>
                            <FigmaText textKey={Texts.rightPanelIndicatorStartValueLabel} text="/" />
                          </Box>
                          <StyledTextFieldWithLabelFormik
                            labelTextKey={Texts.rightPanelIndicatorEndValueLabel}
                            name={_GOAL_VALUE}
                            formProps={formProps}
                          />
                        </Box>
                      )
                  }
                })()}
              </Box>
              {showMore && keyResult && (
                <Box bottom width="50%">
                  <SelectFieldWithTextKeyAndLabel
                    placeholderTextKey={Texts.selectComponentHelperText}
                    labelTextKey={Texts.rightPanelIndicatorEditParentObjectiveLabel}
                    options={getSelectParentObjectiveOptions(objective as Objective, allObjectives)}
                    value={formProps.values.parentId || ''}
                    onChange={({ target: { value: parentId } }) =>
                      formProps.setValues({ ...formProps.values, parentId: parentId as string })
                    }
                  />
                </Box>
              )}
              {showMore && (
                <Box bottom width="50%">
                  <LeadSelectFieldWithLabel
                    value={values.leadUserId || ''}
                    onChangeLeadUserId={(leadUserId) => onChangeLeadUserId(leadUserId, formProps, state)}
                  />
                </Box>
              )}
              <Box fullWidth bottom>
                <EditObjectiveDialogShowMore expanded={showMore} setExpanded={setShowMore} />
              </Box>
            </Box>

            <DrawerFooterButtons
              onClickCancel={_onCancel}
              onClickContinue={formProps.handleSubmit as any}
              onClickDelete={(shouldShowDeleteButton(values) && onDeleteKeyResult) || undefined}
            />
          </Box>
        )
      }}
    </Formik>
  )
}

const toggleString = (oneOrZero: Stringifiable) => (oneOrZero === ONE ? ZERO : ONE)

const ToggleButton = ({ formProps, name, otherName }: { formProps; name: string; otherName: string }) => {
  const val = `${formProps.values[name]}`
  const otherVal = `${formProps.values[otherName]}`
  const fieldPath = name
  const otherFieldPath = otherName
  invariant([ZERO, ONE].includes(val), 'val %s is not be 0 or 1', val)

  const onClick = () => {
    formProps.setFieldValue(fieldPath, toggleString(val))
    formProps.setFieldValue(otherFieldPath, toggleString(otherVal))
  }

  return (
    <Button
      buttonType={val === ONE ? 'primary' : 'secondary'}
      onClick={onClick}
      textKey={val === ONE ? Texts.rightPanelIndicatorFalseButtonText : Texts.rightPanelIndicatorTrueButtonText}
    />
  )
}

const StyledTextFieldWithLabelFormik = styled(TextFieldNumberInputWithLabelFormik)`
  width: 150px;
`

const DISTANCE_BETWEEN_HEADER_TEXT_AND_SELECT_INPUT = 2
const HeightSpacing = styled.div`
  width: 100%;
  height: ${mapCssHeightToNumber(Texts.cssSuccessEditGoalText.style.fontSize) +
  DISTANCE_BETWEEN_HEADER_TEXT_AND_SELECT_INPUT}px;
`

// Deprecated use NoOverflowFigmaText
export const StyledFigmaText = styled(FigmaText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: ${DRAWER_WIDTH / 2}px;
`

export const ButtonContainer = styled(Box)`
  /* Neutral97 */
  background: ${Colors.neutral97};
  /* Neutral90 */
  border: 1px solid ${Colors.neutral90};
  width: ${DRAWER_WIDTH_STRING};
`
