import { TopObjectiveContainerProps } from '../components/FocusedObjective'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { Objective, ObjectivesObject, ObjectiveType } from '../ygdrasil/types/types'
import { FOCUES_OBJECTIVE_BIG_WIDTH, FOCUES_OBJECTIVE_SMALL_WIDTH } from './HardCodedSizes'
import * as DashboardHelper from './StrategyViewHelper'

export function getRootObjective(o: Objective, allObjectives: Objective[]): Objective {
  // eslint-disable-next-line prettier/prettier
  const objectivesObject = allObjectives.reduce((a, b) => {
    a[b._id] = b
    return a
  }, {} as ObjectivesObject)
  const parent = DashboardHelper.getRootObjective(o, objectivesObject)
  return parent
}

export function getWidth(numberOfObjectives: number) {
  return numberOfObjectives <= 3 ? FOCUES_OBJECTIVE_BIG_WIDTH : FOCUES_OBJECTIVE_SMALL_WIDTH
}

export const getColor = ({ type, isSelected, preferenceColor }: TopObjectiveContainerProps): string => {
  if (preferenceColor) return preferenceColor
  switch (true) {
    case isSelected && type === ObjectiveType.TOP:
    case isSelected && type === ObjectiveType.STRATEGIC:
      return Colors.primary20
    case isSelected && type === ObjectiveType.TEAM:
      return Colors.secondary20
    case type === ObjectiveType.TEAM:
      return Colors.secondaryBase
    default:
      return Colors.primaryBase
  }
}

export const getTextKeyForPeriodText = ({
  type,
  isSelected
}: Partial<TopObjectiveContainerProps>): AntiloopTextType => {
  switch (true) {
    case isSelected && type === ObjectiveType.TOP:
    case isSelected && type === ObjectiveType.STRATEGIC:
      return Texts.strategicObjectivesListStrategicObjectivePeriodTextSelected
    case isSelected && type === ObjectiveType.TEAM:
      return Texts.strategicObjectivesListTeamObjectivePeriodTextSelected
    case type === ObjectiveType.TEAM:
      return Texts.strategicObjectivesListTeamObjectivePeriodText
    default:
      return Texts.strategicObjectivesListStrategicObjectivePeriodText
  }
}
