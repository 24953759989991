import styled from '@emotion/styled'
import { ButtonProps } from '@mui/material/Button'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import ButtonBase from './ButtonBase'
import FigmaTextLoader from './FigmaTextLoader'

type Props = { className?: string; spacing?: string; textKey?: AntiloopTextType; loading?: boolean } & ButtonProps
export default function ButtonPrimary(props: Props) {
  const { children, className, textKey, loading, ...buttonProps } = props
  return (
    <StyledButton className={className} variant="contained" color="primary" {...buttonProps}>
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader
            textKey={Texts.cssGenericDialogButtonPrimaryButtonText}
            text={textKey ? getFigmaText(textKey) : undefined}
            textTransform="uppercase"
            loading={loading}
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<{ spacing?: string }>``
