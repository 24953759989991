import { Formik } from 'formik'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import { smallBorderRadius } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { logOnboardingEvent } from '../ygdrasil/libs/FirebaseHelper'
import { ONBOARDING_MAX_WIDTH_MOBILE } from '../libs/HardCodedSizes'
import { RequiredNameValidationDeprecated, TextValidationDeprecated } from '../libs/ValidationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { OnboardingViewProps } from '../ygdrasil/types/Onboarding'
import Box from './Box'
import Button from './Button'
import { getOnboardingTellUsInitialValues } from '../libs/OnboardingTellUsHelper'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

const ValidationSchema = Yup.object().shape({
  name: RequiredNameValidationDeprecated('name'),
  vision: TextValidationDeprecated('vision'),
  countryCode: TextValidationDeprecated('country code'),
  howManyCollegues: TextValidationDeprecated('how many collegues')
})

export default function OnboardingTellUs({ onPressContinue, state, onPressSkip }: OnboardingViewProps) {
  const initialValues = getOnboardingTellUsInitialValues(state)

  const _onPressSkip = () => onPressSkip({ ...state, data: { ...state.data, ...initialValues } })

  const onSubmit = (data) => onPressContinue({ ...state, data: { ...state.data, ...data } })

  useEffect(() => {
    logOnboardingEvent(AnalyticsEvent.ONBOARDING_STARTED, state)
  }, [])

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
      {(formProps) => (
        <Box fullWidth align="center">
          <Container fullWidth top align="center" spacing={bigSpacing}>
            <Box fullWidth align="center" bottom spacing={bigSpacing}>
              <FigmaText textKey={Texts.onboardingMobileTellUsAboutYouHeader} />
            </Box>

            <Box fullWidth bottom spacing={smallSpacing}>
              <TextFieldWithLabelFormik
                labelTextKey={Texts.onboardingOrgNameLabel}
                formProps={formProps}
                name="name"
                component={TextFieldOutlined}
                fullWidth
              />
            </Box>
            <Box fullWidth bottom spacing={smallSpacing}>
              <TextFieldWithLabelFormik
                labelTextKey={Texts.onboardingStrategyLabel}
                formProps={formProps}
                name="vision"
                component={TextFieldOutlined}
                fullWidth
              />
            </Box>
            {/*     
            Removed until we have more countries to select from
            <Box fullWidth bottom spacing={smallSpacing}>
              <TextFieldWithLabelFormik
                select
                labelTextKey={Texts.onboardingOrgLocation}
                formProps={formProps}
                name="countryCode"
                component={TextFieldOutlined}
                fullWidth
              >
                <MenuItem value="SE">{getFigmaText(Texts.countryDropdownSweden)}</MenuItem>
              </TextFieldWithLabelFormik>
            </Box> */}
            {
              <Box
                fullWidth
                link
                align="center"
                alignText="center"
                onClick={_onPressSkip}
                bottom
                spacing={smallSpacing}
              >
                <FigmaText textKey={Texts.onboardingMobileSkipForNow} />
              </Box>
            }
            <Box fullWidth align="center">
              <Button
                onClick={formProps.handleSubmit as any}
                textKey={Texts.onboardingMobileNextButtonText}
                loading={formProps.isSubmitting}
              ></Button>
            </Box>
          </Container>
          {/* <InfoContainer fullWidth top align="center" spacing={bigSpacing}>
            <Box fullWidth align="center">
              <FigmaText textKey={Texts.onboardingGuideVisionHeader} />
            </Box>
            <QuoteContainer fullWidth align="center">
              <FigmaText textKey={Texts.onboardingGuideVisionText} />
            </QuoteContainer>
            <ExampleBox fullWidth align="center">
              <FigmaText textKey={Texts.onboardingGuideVisionExamplesText} />
            </ExampleBox>
          </InfoContainer> */}
        </Box>
      )}
    </Formik>
  )
}

const Container = styled(Box)`
  max-width: ${ONBOARDING_MAX_WIDTH_MOBILE};
`

const InfoContainer = styled(Box)`
  margin: 50px;
  background: ${Colors['materialTheme/sys/light/onSurfaceVariant']};
  border-top: 3px solid ${Colors.primary30};
  padding: 20px;
  height: 100vh;
`

const QuoteContainer = styled(Box)`
  background-color: ${Colors.primaryBase};
  max-width: ${ONBOARDING_MAX_WIDTH_MOBILE};
  padding: 15px;
  margin: 10px;
  border-radius: ${smallBorderRadius};
`

const ExampleBox = styled(Box)`
  max-width: ${ONBOARDING_MAX_WIDTH_MOBILE};
  padding: 10px;
`
