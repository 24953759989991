import { useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult } from '../ygdrasil/types/types'
import ActivityBoardKanban, { ActivityBoardKanbanProps } from './ActivityBoardKanban'
import { ActivityBoardUserFilter } from './ActivityBoardUserFilter'
import Box from './Box'
import Button from './Button'
import { openEditActivityDrawer } from './DrawerGlobalEditActivityDialog'

export function ActivityBoard(props: ActivityBoardKanbanProps) {
  const [selectedLeadUserId, setSelectedLeadUserId] = useState<string | undefined>(undefined)
  const { item } = props
  function onClickLeadUserId(userId?: string) {
    setSelectedLeadUserId(userId)
  }

  return (
    <Box fullWidth fullPadding spacing={smallSpacing}>
      <Box direction="row" fullWidth justify="space-between" align="center" bottom spacing={tinySpacing}>
        <Box direction="row" align="center">
          <ActivityBoardUserFilter onClick={onClickLeadUserId} selectedUserId={selectedLeadUserId} item={item} />
        </Box>
        <Box fullPadding spacing={tinySpacing} visibility={item.data.searchType === 'keyResult' ? 'visible' : 'hidden'}>
          <Button
            onClick={() => openEditActivityDrawer({ keyResult: item.data.data as KeyResult })}
            spacing={tinySpacing}
          >
            <FigmaText
              textKey={Texts.cssGenericDialogButtonPrimaryButtonText}
              text={getFigmaText(Texts.dialogAddActivityHeader)}
            />
          </Button>
        </Box>
      </Box>
      <ActivityBoardKanban {...props} leadUserId={selectedLeadUserId} />
    </Box>
  )
}
