import { smallSpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { BIG_ICON_SIZE, CloseIcon } from './Icons'

type Props = {
  onClose?: () => void
}

export function CelebrationViewHeader({ onClose }: Props) {
  return (
    <Box fullWidth>
      <Box fullWidth direction="row" align="center" justify="space-between" top right spacing={smallSpacing}>
        <Box />
        <Box>
          <FigmaText textKey={Texts.celebrationHeaderText} />
        </Box>
        <Box onClick={onClose} pointer>
          <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
        </Box>
      </Box>
    </Box>
  )
}
