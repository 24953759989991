import styled from '@emotion/styled'
import { useMemo } from 'react'
import useAppState from '../hooks/useAppState'
import { ACTION_BOARD_COLUMN_MAX_WIDTH } from '../libs/HardCodedSizes'
import { getMyActivitesRows } from '../libs/MyActivitiesHelper'
import { SearchState } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import Box from './Box'
import { MyActionsEmptyState } from './MyActionsEmptyState'
import { MyActionsMyFocus } from './MyActionsMyFocus'
import { MyActionsRow } from './MyActionsRow'
import useTreeItems from './useTreeItems'

export function MyActions() {
  const { state } = useAppState()
  const searchState = useMemo(
    () =>
      ({
        activityLeadUserId: state.user._id
      } as SearchState),
    [state.user._id]
  )
  const { rootItems } = useTreeItems({ searchState })
  const rows = getMyActivitesRows(rootItems)

  if (rows.length === 0) return <MyActionsEmptyState />

  return (
    <Container fullWidth>
      <Box fullWidth top bottom>
        <MyActionsMyFocus items={rootItems} />
      </Box>
      {rows.map((item, key) => (
        <MyActionsRow key={key} item={item} level={0} />
      ))}
    </Container>
  )
}

const Container = styled(Box)`
  max-width: ${ACTION_BOARD_COLUMN_MAX_WIDTH * 3}px;
`
