import { FormikProps } from 'formik'
import { useState } from 'react'
import { tinySpacing } from '../enums/Spacings'
import { useUserOptionsObject } from '../hooks/useUserOptionsObject'
import FigmaText from '../ygdrasil/components/FigmaText'
import { NO_LEAD } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { InitialValues } from './EditActivityDialogForm'
import { LeadSelectFieldMenu } from './LeadSelectFieldMenu'
import { SIZE } from './LeadSelectFieldWithLabel'
import { UserWidgetViewLead } from './UserWidget'

type Props = {
  formProps: FormikProps<InitialValues>
}

const EditActivityDialogSelectLeadMenu: React.FC<Props> = ({ formProps }) => {
  const _usersOptions = useUserOptionsObject()
  const formLeadUserId = formProps.values.leadUserId || ''
  const leadUserName = formLeadUserId !== NO_LEAD && formLeadUserId ? _usersOptions[formLeadUserId]?.key : undefined
  const [leadSelectEl, setLeadSelectEl] = useState<HTMLElement | null>(null)

  return (
    <Box gap={tinySpacing}>
      <FigmaText textKey={Texts.dialogEditActivityLeadLabel} />
      <Box direction="row" align="center" gap={tinySpacing}>
        <Box onClick={(e) => setLeadSelectEl(e.currentTarget)} pointer>
          <UserWidgetViewLead userId={formLeadUserId} size={SIZE} />
        </Box>
        <FigmaText textKey={Texts.dialogEditActivityLeadNone} text={leadUserName} />
        <LeadSelectFieldMenu
          anchorEl={leadSelectEl}
          onClose={() => setLeadSelectEl(null)}
          onClick={(leadUserId: string) => {
            formProps.setValues({ ...formProps.values, leadUserId })
            setLeadSelectEl(null)
          }}
          value={formLeadUserId}
        />
      </Box>
    </Box>
  )
}

export default EditActivityDialogSelectLeadMenu
