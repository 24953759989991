import { State } from '../hooks/useAppState'
import { createCheckoutSession, getStripeCallbackUrl } from './StripeHelper'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { Subscription } from '../ygdrasil/types/types'
import { createStripePortalLink } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { OrgSubscriptionInfoType } from '../ygdrasil/libs/OrgSubscriptionInfo'
import Texts from '../ygdrasil/libs/Texts'

export type SubscriptionInfoType = {
  onClickContinue: (infoType: OrgSubscriptionInfoType, state: State) => Promise<unknown>
  settingsButtonTextKey: AntiloopTextType
  settingsPaymentMethodTextKeyNotOwner: AntiloopTextType
  settingsPaymentMethodTextKey: AntiloopTextType
}

export const defaultSubscriptionInfo: SubscriptionInfoType = {
  onClickContinue: (infoType, state) =>
    createCheckoutSession(infoType, state).then((session) => (window.location.href = session.url)),
  settingsButtonTextKey: Texts.adminSubscriptionChangeToStripeButtonText,
  settingsPaymentMethodTextKey: Texts.adminSubscriptionMethodValueInvoice,
  settingsPaymentMethodTextKeyNotOwner: Texts.adminSubscriptionMethodValueCreditCardNotOwner
}

const existingSubscriptionInfo: SubscriptionInfoType = {
  ...defaultSubscriptionInfo,
  onClickContinue: (infoType, state) =>
    createStripePortalLink({ returnUrl: getStripeCallbackUrl({}) }, state).then(
      (session) => (window.location.href = session.url)
    ),
  settingsButtonTextKey: Texts.adminPaymentManageSubscriptionButtonText,
  settingsPaymentMethodTextKey: Texts.adminSubscriptionMethodValueCreditCard
}

export const SubscriptionInfo: { [property in Subscription['status']]: SubscriptionInfoType } = {
  active: existingSubscriptionInfo,
  canceled: existingSubscriptionInfo,
  incomplete: existingSubscriptionInfo,
  incomplete_expired: existingSubscriptionInfo,
  past_due: existingSubscriptionInfo,
  trialing: existingSubscriptionInfo,
  unpaid: existingSubscriptionInfo
}
