import { smallSpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { ObjectiveType } from '../ygdrasil/types/types'
import Box from './Box'
import { FlagIcon } from './Icons'
import { Props, StyledFigmaText } from './EditKeyResultsSection'

export function EditKeyResultsSectionHeader({ onCancel, objective }: Props) {
  const backgroundColor = objective?.type === ObjectiveType.TEAM ? Colors.secondary90 : Colors.primaryBase
  return (
    <Box fullWidth top bottom direction="row" spacing={smallSpacing} link onClick={onCancel as any} align="center">
      <FlagIcon backgroundColor={backgroundColor} />
      <Box left spacing={smallSpacing}>
        <StyledFigmaText textKey={Texts.rightPanelIndicatorBackToObjectiveButtonText} />
      </Box>
    </Box>
  )
}
