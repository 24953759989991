import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import { useState } from 'react'
import { smallSpacing } from '../enums/Spacings'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { DatePickerPopper } from './DatePickerPopper'
import { InitialValues } from './EditActivityDialogForm'
import FigmaImageContainer from './FigmaImageContainer'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

type Props = {
  formProps: FormikProps<InitialValues>
}

const EditActivityDialogSelectDueDateField: React.FC<Props> = ({ formProps }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  return (
    <Box top spacing={smallSpacing} width="30%">
      <Box
        pointer
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          if (dayjs(formProps.values.dueDate).isBefore(dayjs())) {
            formProps.setValues({ ...formProps.values, dueDate: dayjs().format(YYYY_MM_DD) })
          }
        }}
      >
        <TextFieldWithLabelFormik
          labelTextKey={Texts.dialogEditActivityDueDateLabel}
          placeholderTextKey={Texts.activityDueDateHelperText}
          name="dueDate"
          formProps={formProps}
          endAdornmentComponent={<FigmaImageContainer imageKey={Images.dateIcon} />}
        />
      </Box>
      <DatePickerPopper
        anchorEl={anchorEl}
        value={formProps.values.dueDate}
        onChange={(dueDate) =>
          formProps.setValues({ ...formProps.values, dueDate: !!dueDate ? dayjs(dueDate).format(YYYY_MM_DD) : '' })
        }
        onClose={() => setAnchorEl(null)}
        minDate={dayjs().format(YYYY_MM_DD)}
      />
    </Box>
  )
}

export default EditActivityDialogSelectDueDateField
