import { DEFAULT_STATE, State } from '../hooks/useAppState'
import { SearchState } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'

const IGNORED_KEYS: (keyof SearchState)[] = ['searchDates', 'selectedItemId']

export const getEmptySearchState = (searchState: SearchState): SearchState => {
  const defaultSearchState = DEFAULT_STATE.searchState
  Object.keys(searchState)
    .filter((key) => !IGNORED_KEYS.includes(key as keyof SearchState))
    .forEach((key) => (searchState[key] = defaultSearchState[key]))
  return searchState
}

export const OBJECTIVE_NAME_MAX_LENGTH = 75

export const isSearching = (searchState: SearchState) => {
  const emptyState = Object.keys(DEFAULT_STATE.searchState)
    .filter((key) => !IGNORED_KEYS.includes(key as any))
    .reduce((a, b) => {
      a[b] = DEFAULT_STATE.searchState[b]
      return a
    }, {})

  if ((searchState.leadUserIds?.length || 0) > 0) return true

  return Object.keys(emptyState).some((key) => searchState[key] !== emptyState[key])
}

export const getTextWithDots = (text = '', length = OBJECTIVE_NAME_MAX_LENGTH) => {
  if (text.length > length) return `${text.substr(0, length)} ...`
  return text
}

export function isDragEnabled(state: State) {
  return !isSearching(state.searchState)
}
