import { Box } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import { BoxProps } from './Box'

type Props = {
  opacity: number
}

/**
 @remarks
 Used in OnboardingPaginationComponent to create a pagination dot with definable opacity for onboarding steps. 
 It requires opacity.
*/

export default function OnboardingPaginationDot({ opacity }: Props) {
  return <DotBox $opacity={opacity} />
}

const DotBox = styled(Box)<{ $opacity: number } & BoxProps>`
  height: 13px;
  width: 13px;
  background-color: ${Colors.primaryBase};
  border-radius: 50%;
  display: inline-block;
  opacity: ${(props) => `${props.$opacity}%`};
`
