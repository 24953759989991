import React from 'react'
import Texts from '../ygdrasil/libs/Texts'
import { EditDatasourceMachineViewProps } from '../ygdrasil/types/EditDatasourceMachine'
import Box from './Box'
import { Container, EditDatasourceMachineViewHeader } from './EditDatasourceMachineViewHeader'

import { useAsyncFunction } from '../hooks/useAsyncFunction'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getDataForGoogleSheetDriveItem, getGoogleSheetDriveItem } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { GoogleSheetDriveItem, IdName } from '../ygdrasil/types/types'
import { EditDatasourceMachineViewEditSheetConnectionForm } from './EditDatasourceMachineViewEditGoogleDatasourceConnectionForm'

import SelectFieldWithLabel from './SelectFieldWithLabel'
import { smallSpacing } from '../enums/Spacings'
import { useState } from 'react'
import { TextFieldWithLabel } from './TextFieldWithLabel'
import { isValidData } from '../ygdrasil/libs/GoogleSheetsHelper'
import { EditDatasourceMachineViewEditSheetDatasourceForm } from './EditDatasourceMachineViewEditGoogleSheetDatasourceForm'
import { EditDatasourceMachineViewFooter } from './EditDatasourceMachineViewFooter'
import { EditDatasourceMachineViewEditSheetErrorTutorialMessage } from './EditDatasourceMachineViewEditSheetErrorTutorialMessage'

export default function EditDatasourceMachineViewEditGoogleDatasource(props: EditDatasourceMachineViewProps) {
  const { state, setMachineState } = props
  const { data } = state
  const { selectedDriveItem, selectedSheet, dataSource, connectionType } = data
  const { onClose } = props
  const driveItemId = selectedDriveItem?.id || dataSource?.driveItemId || undefined

  // drive item
  const { data: driveItem, isLoading: isLoadingSheets } = useAsyncFunction(
    (state) => getGoogleSheetDriveItem(driveItemId as string, state),
    { enabled: !!driveItemId }
  )

  // Sheet
  const sheetId = selectedSheet?.id || dataSource?.sheetId || undefined
  const setSelectedSheet = (selectedSheet: IdName) =>
    setMachineState({ ...state, data: { ...state.data, selectedSheet } })

  const _sheets = (driveItem?.sheets || []).map(({ name, id }) => ({ name, id: id.toString() }))
  const sheetOptions = _sheets.map(({ name: key, id: value }) => ({ key, value }))

  const onChangeSheet = ({ target: { value: sheetId } }) => {
    const sheet = _sheets.find(({ id }) => id === sheetId) as IdName
    setSelectedSheet(sheet)
    if (!dataSourceName) setDataSourceName(`${driveItem?.name} - ${sheet.name}`)
  }

  // data
  const {
    data: sheetData,
    isLoading: isLoadingData,
    error: getDataForGoogleSheetDriveItemError
  } = useAsyncFunction(
    (state) =>
      getDataForGoogleSheetDriveItem({ sheetId: sheetId as string, driveItemId: driveItemId as string }, state),
    { enabled: !!sheetId && !!driveItemId, key: `${sheetId}_${driveItemId}` }
  )

  let showSheetQueryError = !!driveItem && !isLoadingSheets && !sheetOptions?.length
  if (!isLoadingData && !!sheetData && !isValidData(sheetData)) showSheetQueryError = true
  if (!isLoadingData && getDataForGoogleSheetDriveItemError) showSheetQueryError = true

  const [dataSourceName, setDataSourceName] = useState('')

  const onConnectionVerified = (driveItem: GoogleSheetDriveItem) => {
    return setMachineState({
      ...state,
      data: { ...state.data, selectedDriveItem: { id: driveItem.id, name: driveItem.name } }
    })
  }

  return (
    <Container>
      <EditDatasourceMachineViewHeader
        textKey={Texts.adminIntegrationsInviteGoogleUserHeaderText}
        {...props}
        onClickBack={undefined}
      />
      <Box top left right fullWidth>
        <EditDatasourceMachineViewEditSheetConnectionForm onConnectionVerified={onConnectionVerified} {...props} />
      </Box>
      <Box fullWidth left right>
        <Box top spacing={smallSpacing}>
          <FigmaText textKey={Texts.googleSheetIntegrationStep3Label} />
        </Box>
        <Box fullWidth top spacing={smallSpacing}>
          <SelectFieldWithLabel
            fullWidth
            labelTextKey={Texts.adminIntegrationsCreateConnectionWizardStep2SheetNameLabel}
            value={sheetId || ''}
            options={sheetOptions}
            onChange={onChangeSheet}
            isLoading={isLoadingSheets}
          />
          {showSheetQueryError && (
            <EditDatasourceMachineViewEditSheetErrorTutorialMessage connectionType={connectionType} />
          )}
        </Box>
      </Box>
      <Box fullWidth left right>
        <EditDatasourceMachineViewEditSheetDatasourceForm
          {...props}
          showSheetQueryError={showSheetQueryError}
          sheetData={sheetData}
          driveItem={driveItem}
          isLoadingData={isLoadingData}
        />
      </Box>
      <Box top fullWidth right>
        <EditDatasourceMachineViewFooter />
      </Box>
    </Container>
  )
}
