// @flow
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { Firestore } from '@firebase/firestore'
import { app } from 'firebase-admin'
import { Auth } from 'firebase-admin/auth'
import { Firestore as FireStoreAdmin } from 'firebase-admin/firestore'
import { Storage } from 'firebase-admin/storage'
import { User as AuthUser, UserCredential } from 'firebase/auth'
import { drive_v2 } from 'googleapis/build/src/apis/drive/v2'
import type { defaultConfig } from '../../Config'
import { ActivityStatus } from '../enums/ActivityStatus'
import { CheckinStatus } from '../enums/CheckinStatus'
import ConnectionType from '../enums/ConnectionType'
import Currency from '../enums/Currency'
import Integration from '../enums/Integrations'
import { StrategyModel } from '../enums/StrategyModel'
import { Terms } from '../libs/TermsHelper'
import { Language } from './Antiloop'
import { PreferredLoginMethod } from './SignInTypes'

export type Config = typeof defaultConfig

export type PartialConfig = Partial<typeof defaultConfig>

export type Id = string

export interface IdName {
  id: string
  name: string
}

export type Subscription = {
  id: string
  status: 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'trialing' | 'unpaid'
  uid: string
  metadata: { orgId?: string; obpUserId?: string }
  cancel_at: {
    seconds: number
  }
}

export type Organization = BaseObject &
  CreateOrganizationRequest & {
    logoPath?: string
    businessPlan: BusinessPlan
    preferredLoginMethod?: PreferredLoginMethod
    strategyModel?: StrategyModel
    terms?: LanguageTerms
    lastUserActiveAt?: string
    enableLimitedUser?: boolean
    msalTokenCacheJSON: string // formatted as MsalTokenCache
    jiraConfig: {
      protocol?: string //'https'
      host: string //'our-big-picture.atlassian.net'
      username: string // 'anders.back@ourbigpicture.se'
      jqlQuery: string
      apiVersion?: string // '2'
      strictSSL?: boolean // true
    }
    subscription: Subscription
  }

export type LanguageTerms = {
  [language: string]: Terms
}

export type StrategicPillar = {
  name: string
  _id: string
  description?: string
}

export type BusinessPlan = {
  strategicPillars: StrategicPillar[]
  values: string[]
  swot: SWOT
}

export type SWOT = {
  strength: string
  weakness: string
  opportunity: string
  threats: string
}

export enum SWOTItem {
  Strength = 'strength',
  Weaknesses = 'weakness',
  Opportunities = 'opportunity',
  Threats = 'threats'
}

// enforces that SWOT consist of SWOTItem enum
// eslint-disable-next-line prettier/prettier
const VariableThatEnforcesTypesDontRemove: SWOT = {
  [SWOTItem.Strength]: '',
  [SWOTItem.Weaknesses]: '',
  [SWOTItem.Opportunities]: '',
  [SWOTItem.Threats]: ''
}

export type Statement = {
  name?: string
  description?: string
  richDescription?: string
  url?: string
}

export type Vision = Statement
export type Strategy = Statement

export type CreateOrganizationRequest = {
  nameOfUser: string
  domains: string[]
  name: string
  vision?: Vision
  strategy?: Strategy
  mission?: Statement
  visionDeprecated?: string
  countryCode: string
  howManyCollegues: string
  uid: string
  allowUserWithVerifiedEmailFromSameDomainToJoinOrganization?: boolean
}

export type CreateTeamRequest = {
  name: string
  organizationId: string
}

export type SearchDates = { startDate: string; endDate: string }

export type Team = BaseObject & CreateTeamRequest

export type Objective = BaseObject & CreateObjectiveRequest & Lead & ObjectiveBase

export type ObjectiveBase = { isArchived?: boolean; strategicPillarIds: string[] }
export type CreateObjectiveRequest = SearchDates & {
  parentObjectiveId?: string
  kpi: KPI
  name: string
  description?: string
  type: ObjectiveType
  unit: Unit
  teamIds: string[]
  organizationId: string
  ordo?: number
  confidences?: Confidence[]
  backgroundColor?: string
}

export enum ObjectiveType {
  TEAM = 'TEAM',
  TOP = 'TOP',
  STRATEGIC = 'STRATEGIC'
}

// order here is important, need to be ascending
export enum PeriodEnum {
  THREE_MONTHS,
  ONE_YEAR,
  THREE_YEARS
}

export type Confidence = {
  date: string
  status: CheckinStatus
}

export type KeyResult = BaseObject &
  CreateKeyResultRequest &
  Lead & {
    values?: Value[]
    confidences?: Confidence[]
    parentId: string
  }

export type Lead = {
  leadUserId?: string
  leadMetaData?: {
    notificationInterval?: LeadNotificationInterval
    assignerUserId?: string
    assignedAt?: string
  }
}

export enum LeadNotificationInterval {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export type CreateKeyResultRequest = KPI & {
  startValue: number
  description?: string
  goalValue: number
  unit: Unit
  currency?: Currency
  integration?: Integration
  dataSourceId?: string
  ordo?: number
  organizationId: string
  parentId: string
}

export type Activity = BaseObject &
  Lead & {
    name: string
    url?: string
    ordo?: number
    parentId?: string
    organizationId: string
    status: ActivityStatus
    dueDate?: string
    flagged?: boolean
    lastStatusChangeAt?: string
    isPrioritized?: boolean
    jiraIssueKey?: string
  }

export type KPI = {
  name: string
  values?: Value[]
}

export enum Unit {
  PERCENT = '%',
  NUMERICAL = '#',
  MONEY = '$',
  BOOLEAN = 'TRUE/FALSE',
  INTEGRATION = 'INTEGRATION',
  GLOBAL = 'GLOBAL'
}

export type Value = {
  value: number
  date: string
}

export enum OrgUserStatus {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETE = 'DELETE'
}

export type User = BaseObject &
  CreateUserRequest & {
    isSignedIn?: boolean
    profileImage?: string
    lastActiveAt?: string
    enableLeadNotifications?: boolean
    pushToken?: string | null
    lastSeenNotificationsAt?: string
  }

export enum OrgUserRole {
  FULL = 'FULL',
  LIMITED = 'LIMITED'
}

export type MyFocusRequest = {
  why: string
  retro: string
  date: string
  organizationId: string
  userId: string
}

export type MyFocus = BaseObject & MyFocusRequest

export type TodoRequest = {
  name: string
  done: boolean
  date: string
  organizationId: string
  userId: string
}

export type Todo = BaseObject & TodoRequest

export type OrgUserInfo = {
  status?: OrgUserStatus
  role?: OrgUserRole
}

export type CreateUserRequest = {
  name: string
  language?: Language
  objectiveIds: string[]
  organizationIds: string[]
  orgUserInfo?: {
    [orgId: string]: OrgUserInfo
  }
  email: string | null
  phone: string | null
}

export type BaseObject = {
  _id: Id
  createdAt: string
  updatedAt: string
  createdBy: Id
  updatedBy: Id
}

export type FirebaseUser = AuthUser

export type Mail = {
  to: string // 'anders.back@me.com',
  message: {
    subject: string // 'Hello from Firebase!',
    html: string // 'This is an <code>HTML</code> email body.',
  }
}
export type InvitationRequest = {
  name: string
  createdBy: string
  message?: string
  orgId: string
  email: string
  language: Language
  preferredLoginMethod: PreferredLoginMethod
}

export type ShouldOnboardRequest = {
  email: string
  uid?: string
}

export type DeleteOrganizationRequest = {
  uid: string
  orgId: string
}

export type GetOrganizationsForNonOnboardedFirebaseUserRequest = {
  uid: string
}

export type FirebaseAdmin = app.App
export type DB = Firestore

export type ObjectivesObject = { [_id: string]: Objective }

export enum Direction {
  INCREASING = 'INCREASING',
  DECREASING = 'DECREASING'
}

export type GrowthIndicatorValues = {
  [date: string]: GrowthIndicatorValue
}

export type CreateGrowthIndicatorRequest = {
  name: string
  values: GrowthIndicatorValues
  organizationId: string
  direction: Direction
}

export type GrowthIndicator = BaseObject & CreateGrowthIndicatorRequest & { isArchived?: boolean }

export type GrowthIndicatorValue = {
  date: string
  updatedBy?: string
  goal?: number
  actual?: number
}

export enum GrowthIndicatorValueType {
  GOAL = 'goal',
  ACTUAL = 'actual'
}

export type ServerOptions = { db: FireStoreAdmin; auth: Auth; storage: Storage }

export type SignInRequest = { email: string; dryRun?: boolean }

export enum SearchPeriodOptions {
  THIS_QUARTER = 'THIS_QUARTER',
  FUTURE_QUARTERS = 'FUTURE_QUARTERS',
  PREVIOUS_QUATERS = 'PREVIOUS_QUATERS'
}

export type AuthError = { code: string; credential: UserCredential; email: any }

export type CreateCustomAuthTokenRequestForInternalUser = {
  uid: string
}

export type CreateCustomAuthTokenRequest = {
  id: string
}

export type CustomAuthToken = BaseObject & {
  token: string
}

export type CreateLoginTokenRequest = BaseObject & {
  email: string
}

export type LoginWithCodeToken = CreateLoginTokenRequest & {
  code: string
}

export enum SlackChannels {
  LOGINS = 'logins',
  ERRORS = 'errors',
  ALL_LAST_ACTIVE_USERS = '7_day_active_user',
  OPS_LEAD_NOTIFICATIONS = 'ops_lead_notifications',
  OPS_ACTIVE_USERS = 'ops_active_users',
  DEBUG = 'debug',
  TRANSLATION = 'ops-translations',
  OPS_SYNC_DATA = 'ops-sync-data',
  OPS_VERIFY_DATA = 'ops_verify_data',
  WINNING_TOGETHER = 'winning-together',
  CHAT_GPT_CONVERSATIONS = 'ops_chat_gpt_conversations'
}

export type NotificationData = {
  url: string
}

export type AcquireTokenByCodeRequest = {
  code: string
  redirectUrl: string
  codeVerifier: string
  orgId: string
}

export type ConnectionRequest = {
  name: string
  connectionType: ConnectionType
  organizationId: string
  homeAccountId: string
  uid: string
}

export type DatasourceRequest = {
  name: string
  connectionId: string
  interval: string
  organizationId: string
  driveItemId: string
  sheetId: string
  lastSyncedAt?: string
  values: Value[]
}

export type Datasource = BaseObject & DatasourceRequest

export type Connection = BaseObject & ConnectionRequest

export type AcquireTokenForConnection = {
  connectionId: Id
}

export type GetDataForGoogleSheetDriveItemRequest = {
  driveItemId: string
  sheetId: string
}

export type GoogleSheetDriveItem = {
  id: string
  name: string
  owners: drive_v2.Schema$User[]
  url: string
  sheets: IdName[]
}

export type GoogleSheetDriveItemData = {
  rows: string[][]
  headers: string[]
}

export type DashboardLink = BaseObject & DashboardLinkRequest

export type DashboardLinkRequest = {
  name: string
  organizationId: Id
  includedIndicatorIds: Id[]
}

export type GetJiraIssuesReq = {
  organziationId: string
}
