import dayjs from 'dayjs'
import { getArray } from '../ygdrasil/libs/Common'
import { isInQuarter } from '../ygdrasil/libs/ObjectiveHelper'
import { Objective } from '../ygdrasil/types/types'

type RoadmapRowInfo = { visible: boolean; numberOfPeriods: number }
export const getRoadmapRowInfos = (objective: Objective, headerDates: string[]): RoadmapRowInfo[] =>
  headerDates.reduce(
    (a, headerDate, i) => {
      if (i < a.numberOfPeriods) return a
      const visible = isInQuarter(objective, headerDate)
      const numberOfPeriods = visible ? getNumberOfPeriods(objective, headerDates) : 1
      a.numberOfPeriods += numberOfPeriods
      a.res.push({ visible, numberOfPeriods })
      return a
    },
    { numberOfPeriods: 0, res: [] } as { numberOfPeriods: number; res: RoadmapRowInfo[] }
  ).res

const getNumberOfPeriods = (objective: Objective, headerDates: string[]) =>
  headerDates.filter((headerDate) => isInQuarter(objective, headerDate)).length

export const getStartAndEndDate = (dateArray: string[]) => {
  const first = dateArray[0]
  const last = dateArray[dateArray.length - 1]

  const startDate = dayjs(first).startOf('quarter').format()
  const endDate = dayjs(last).endOf('quarter').format()

  return { startDate, endDate }
}

export const isSameQuarter = (quarterDate: string): boolean => {
  return dayjs(quarterDate).isSame(dayjs(), 'quarter')
}

export function getHeaderDates(headerStartDate: string, numberOfDates: number) {
  return getArray(numberOfDates).map((x, i) => dayjs(headerStartDate).add(i, 'quarter').format())
}

export function getWidth(numberOfPeriods: number, headerDates: string[]): string {
  return `${(numberOfPeriods / headerDates.length) * 100}%`
}
