import { SelectFieldWithTextKeyAndLabelOptions } from '../components/SelectFieldWithTextKeyAndLabel'
import { SMALL_SPACING_PX } from '../enums/Spacings'
import { State } from '../hooks/useAppState'
import Currency from '../ygdrasil/enums/Currency'
import Integrations from '../ygdrasil/enums/Integrations'
import { getAllObjectivesForObjective, isStrategicObjective, _Objective } from '../ygdrasil/libs/ObjectiveHelper'
import { KeyResult, Objective, Unit } from '../ygdrasil/types/types'
import { createWithContent, EditorState } from './WYSIWYGEditor'

export type InitialValues = {
  name: string
  description: EditorState
  leadUserId?: string
  startValue: string
  goalValue: string
  unit: Unit
  currency: Currency
  integration: Integrations
  isNew?: boolean
  organizationId: string
  parentId: string
  dataSourceId?: string
}

export function getInitialValues(
  keyResult: Partial<KeyResult> | undefined,
  objective: Objective,
  state: State
): InitialValues {
  const startValue = keyResult?.startValue?.toString() || '0'
  const goalValue = keyResult?.goalValue?.toString() || '1'
  const description = createWithContent(keyResult?.description)
  return {
    name: '',
    values: [],
    unit: Unit.NUMERICAL,
    currency: Currency.SEK,
    integration: Integrations.NO_INTEGRATION,
    isNew: !keyResult,
    ...keyResult,
    startValue,
    goalValue,
    organizationId: state.selectedOrganizationId,
    parentId: objective._id,
    description
  }
}

export function shouldShowDeleteButton({ isNew }: InitialValues) {
  return !isNew
}

export const getSelectParentObjectiveOptions = (
  objective: Partial<Objective>,
  allObjectives: Objective[]
): SelectFieldWithTextKeyAndLabelOptions[] => {
  allObjectives = allObjectives.filter(({ isArchived }) => !isArchived)

  const rootObjectives = allObjectives.filter((o) => !o.parentObjectiveId)

  return rootObjectives
    .reduce((a, b) => a.concat(getAllObjectivesForObjective(b, allObjectives)), [] as _Objective[])
    .map((o) => ({
      key: o.name,
      value: o._id,
      paddingLeft: (o.level || 0) * SMALL_SPACING_PX,
      bold: isStrategicObjective(o)
    }))
}
