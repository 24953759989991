import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Mark } from '../libs/ObjectivePeriodAndTypeOptionsHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'

export const ObjectivePeriodRoadmapOptionsLabels = ({ disabled, marks }: { disabled?: boolean; marks: Mark[] }) => {
  const mappedData = marks.reduce((acc, mark) => {
    const year = dayjs(mark.date).year()
    if (!acc[year]) {
      acc[year] = []
    }
    acc[year].push(mark)
    return acc
  }, {})

  return (
    <Container fullWidth direction="row" align="flex-end" disabled={disabled}>
      {Object.entries(mappedData).map(([year, mappedMark]: any, idx, arr) => {
        const isLastYear = idx === arr.length - 1
        const hasOneMark = mappedMark.length === 1
        const nextYearArr = arr?.[idx + 1]
        const [, nextYearMarks]: any = nextYearArr || []
        const hasBorder = !isLastYear && nextYearMarks.length > 1

        return (
          <YearContainer
            key={year}
            style={{ flex: (isLastYear ? mappedMark.length - 1 : mappedMark.length) * 0.25 }}
            alignText="center"
            hasBorder={hasBorder}
          >
            {(!isLastYear || !hasOneMark) && <FigmaText textKey={Texts.cssPeriodSelectorYearText} text={year} />}
          </YearContainer>
        )
      })}
    </Container>
  )
}

const Container = styled(Box)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

const YearContainer = styled(Box)<{ hasBorder?: boolean }>`
  border-right: ${({ hasBorder }) => (hasBorder ? `1px solid ${Colors.neutralUnselected}` : 'none')};
`
