import useAppState from '../hooks/useAppState'
import { getStateForStrategicView } from '../libs/MenuHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'

export function MyActionsEmptyState() {
  const { state, setState } = useAppState()
  const onClickEmptyState = () => setState(getStateForStrategicView(state))
  return (
    <Box fullWidth align="center" justify="center" style={{ height: '50vh' }}>
      <Button textKey={Texts.myActionsEmptyStateText} buttonType="empty_state" onClick={onClickEmptyState} />
    </Box>
  )
}
