import { Menu, MenuItem } from '@mui/material'
import React from 'react'
import { asArray, asObject } from '../ygdrasil/libs/Common'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { User } from '../ygdrasil/types/types'
import { smallSpacing } from '../enums/Spacings'
import ZIndices from '../enums/Zindices'
import useAppState from '../hooks/useAppState'
import { LEAD_USER_CONATINER_SIZE } from '../libs/HardCodedSizes'
import { useUsersForOrganization } from '../ygdrasil/libs/QueryHooks'
import Box from './Box'
import { LeadUserDropdownCheckIcon } from './Icons'
import { UserWidgetViewLead } from './UserWidget'
import { useUserOptionsObject } from '../hooks/useUserOptionsObject'
import { SIZE } from './LeadSelectFieldWithLabel'
import { withStopProgagation } from '../libs/HtmlHelper'

export function LeadSelectFieldMenu({
  anchorEl,
  onClose,
  onClick,
  value
}: {
  anchorEl: HTMLElement | null
  onClose: () => void
  onClick: (leadUserId: string) => void
  value: string
}) {
  const { state } = useAppState()
  const _usersOptions = useUserOptionsObject()
  const usersOptions = asArray(_usersOptions)
  const { data: users = [] } = useUsersForOrganization(state)
  const _users = asObject(users, '_id')

  const _onClose = withStopProgagation(onClose)
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      style={{ zIndex: ZIndices.modal }}
      onClose={_onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Box fullWidth>
        {usersOptions.map(({ key, value: _id }, i) => {
          const user = _users[_id]

          const _onClick = withStopProgagation(() => onClick(_id))
          return (
            <MenuItem key={i}>
              <Box fullWidth direction="row" align="center" onClick={_onClick} justify="space-between" right>
                {user && <LeadSelectFieldMenuItem user={user} key={key} />}
                {!user && (
                  <Box spacing={`${LEAD_USER_CONATINER_SIZE * 2}px`} left>
                    <Box left spacing={smallSpacing}>
                      <FigmaText textKey={Texts.cssSelectListUserNameText} text={'-'} />
                    </Box>
                  </Box>
                )}
                <Box left visibility={value.includes(_id) ? 'visible' : 'hidden'}>
                  <LeadUserDropdownCheckIcon />
                </Box>
              </Box>
            </MenuItem>
          )
        })}
      </Box>
    </Menu>
  )
}
function LeadSelectFieldMenuItem({ user }: { user: User }) {
  return (
    <Box align="center" direction="row" fullWidth>
      <Box right spacing={smallSpacing}>
        <UserWidgetViewLead userId={user._id} size={SIZE} />
      </Box>
      <Box>
        <FigmaText textKey={Texts.cssSelectListUserNameText} text={user.name || user.email || ''} />
        {user?.email && <FigmaText textKey={Texts.cssSelectListUserEmailText} text={user.email} />}
      </Box>
    </Box>
  )
}
