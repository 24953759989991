import { InputAdornment, MenuItem, MenuProps } from '@mui/material'
import { SelectProps } from '@mui/material/Select'
import { useRef } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { IsSearchingLoader } from './AutoComplete'
import Box from './Box'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { InfoIcon } from './Icons'
import { MaterialSelectStyled } from './SelectFieldWithLabelMulti'
import Tooltip from './Tooltip'
import { LANGUAGES_OBJECT } from '../ygdrasil/libs/Consts'

type Props = SelectProps & {
  labelTextKey?: AntiloopTextType
  fullWidth?: boolean
  options: { key: string; value: any }[]
  menuProps?: Partial<MenuProps>
  isLoading?: boolean
  tooltipElement?: React.ReactElement
}

export default function SelectFieldWithLabel(props: Props) {
  const { labelTextKey, menuProps, variant = 'outlined', isLoading, tooltipElement, ...selectProps } = props
  const menuAnchorElement = useRef(null)

  return (
    <Box fullWidth>
      {labelTextKey && (
        <Box
          bottom
          spacing={tinySpacing}
          direction="row"
          align="center"
          style={{ lineHeight: labelTextKey.style.fontSize }}
        >
          <Box right spacing={smallSpacing}>
            <FigmaTextForStrategyModel textKey={labelTextKey} />
          </Box>
          {tooltipElement && (
            <Tooltip title="titleElement is defined below" titleElement={tooltipElement}>
              <Box pointer>
                <InfoIcon />
              </Box>
            </Tooltip>
          )}
        </Box>
      )}
      <MaterialSelectStyled
        {...selectProps}
        variant={variant}
        size="small"
        MenuProps={{
          ...menuProps,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        endAdornment={
          isLoading ? (
            <InputAdornment position="end">
              <IsSearchingLoader />
            </InputAdornment>
          ) : null
        }
      >
        {props.options.map(({ key, value }) => (
          <MenuItem key={key} value={value}>
            {key}
          </MenuItem>
        ))}
      </MaterialSelectStyled>
      <div ref={menuAnchorElement} />
    </Box>
  )
}
