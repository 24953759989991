import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'

export const StrategyViewObjectiveListRowDetailContainer = styled(Box)`
  background-color: ${Colors.whiteBg};
  border: 1px solid ${Colors.neutralVariant90};
  border-radius: ${minBorderRadius};
`
