import { StrategyModel } from '../ygdrasil/enums/StrategyModel'
import Term from '../ygdrasil/enums/Term'
import { Language } from '../ygdrasil/types/Antiloop'
import { Organization } from '../ygdrasil/types/types'
import Box from './Box'
import { SettingsApplicationStrategyModelTerm } from './SettingsApplicationStrategyModelTerm'

export function SettingsApplicationStrategyModelTerms({
  terms,
  language,
  strategyModel,
  shouldPadLeft
}: {
  shouldPadLeft?: boolean
  terms: Term[]
  language: Language
  strategyModel: StrategyModel
}) {
  return (
    <Box fullWidth>
      {terms.map((term, i) => {
        return (
          <SettingsApplicationStrategyModelTerm
            key={i}
            index={i}
            term={term}
            language={language}
            strategyModel={strategyModel}
            shouldPadLeft={shouldPadLeft}
          />
        )
      })}
    </Box>
  )
}
