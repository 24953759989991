import StonlyGuides from '../enums/StonlyGuides'
import useScript from '../hooks/useScript'
import config from '../Config'
import { STONLY_SCRIPT_URL } from '../ygdrasil/libs/Consts'

export function useStonly() {
  useScript(STONLY_SCRIPT_URL, config.enableStonly)
}

export const openGuidedTour = (guideId: StonlyGuides) =>
  // @ts-ignore
  window.StonlyWidget &&
  // @ts-ignore
  window.StonlyWidget('openGuidedTour', {
    guideId,
    widgetOptions: {
      widgetPlacement: 'MODAL',
      widgetFormat: 'LIGHT',
      widgetSizeType: 'MEDIUM'
    }
  })
