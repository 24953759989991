/* eslint-disable prettier/prettier */
import { CheckinStatus } from '../enums/CheckinStatus'
import { AllItems } from '../hooks/useAllItems'
import { Confidence, KeyResult, Objective, ObjectiveType, SearchDates, Value } from '../types/types'
import { getMean } from './Common'
import { YYYY_MM_DD } from './Consts'
import { DayJS } from './DayJsHelper'
import * as KeyResultHelper from './KeyResultHelper'

export type _Objective = Objective & { level?: number }

export const getChange = (o: Objective, allKeyResults: KeyResult[]) =>
  getMean(getKeyResults(o, allKeyResults).map(KeyResultHelper.getChange))

export const hasContributingKeyResults = (o: Objective, allKeyResults: KeyResult[]) =>
  getContriubutingKeyResults(o, allKeyResults).length > 0

export const getContriubutingKeyResults = (o: Objective, allKeyResults: KeyResult[]) => {
  if (o.isArchived) return []
  return allKeyResults.filter((kr) => kr.parentId === o._id).filter((kr) => !KeyResultHelper.isRetainIndicator(kr))
}

export const getCurrentRatio = (o: Objective, { allIndicators }: AllItems) =>
  getMean(getContriubutingKeyResults(o, allIndicators).map(KeyResultHelper.getCurrentRatio))
export const getKeyResults = (o: Objective, allKeyResults: KeyResult[]) =>
  allKeyResults.filter((kr) => kr.parentId === o._id)

export const getAllChildObjectives = (o: Objective, allObjectives: Objective[]) =>
  getAllObjectivesForObjective(o, allObjectives).filter((o) => o.level && o.level > 0)

export const getAllObjectivesForObjective = (_o: Objective, allObjectives: Objective[], level = 0): _Objective[] => {
  const o = { ..._o, level }
  if (!!o.parentObjectiveId && o._id === o.parentObjectiveId) return [o]
  return [
    o,
    ...getChildObjctives(o._id, allObjectives, level).reduce(
      (a, b) => a.concat(getAllObjectivesForObjective(b, allObjectives, level + 1)),
      [] as _Objective[]
    )
  ]
}

export const getAllObjectivesAndKeyResultIdsForObjective = (
  o: Objective,
  allObjectives: Objective[],
  indicators: KeyResult[]
) =>
  getAllObjectivesForObjective(o, allObjectives).reduce((a, b) => {
    a.push(b._id)
    indicators.forEach((kr) => a.push(kr._id))
    return a
  }, [] as string[])

export const getParent = (objective: Objective, allObjectives: Objective[]) =>
  allObjectives.find((o) => !!o._id && o._id === objective.parentObjectiveId)

export const getObjective = (id: string, allObjectives: Objective[]) => allObjectives.find((o) => id === o._id)

export const findObjectiveForKeyResult = ({ parentId }: KeyResult, { allObjectives }: AllItems) =>
  allObjectives.find((o) => o._id === parentId)

export const getDerivedTeamIds = (o: Objective, allObjectives: Objective[]): string[] =>
  Array.from(
    getAllObjectivesForObjective(o, allObjectives).reduce((a, o) => {
      o.teamIds.forEach((teamId) => a.add(teamId))
      return a
    }, new Set<string>())
  )

export const getChildObjctives = (parentId: string, objectives: Objective[], level?: number) =>
  objectives.reduce((a, o) => {
    const _o: _Objective = o
    if (level) _o.level = level
    if (!!parentId && o.parentObjectiveId === parentId) a.push(_o)
    return a
  }, [] as _Objective[])

export const addValueToValues = ({ value, date }: Value, _values: Value[] = []): Value[] => {
  let values = [..._values]
  const lastValue = values.find((v) => DayJS(v.date).isSame(DayJS(date), 'd'))
  // you are only allowed to add one value per day
  if (!!lastValue) lastValue.value = value
  else values.push({ date: DayJS(date).format(YYYY_MM_DD), value }) // TODO add test should format dates as YYYY_MM_DD
  values = values.sort((a, b) => a.date.localeCompare(b.date))
  return values
}

export const addCurrentConfidenceToConfidences = (status: CheckinStatus, _values: Confidence[]) => {
  const values = [..._values]
  const lastValue = values[values.length - 1]

  // you are only allowed to add one value per day
  if (!!lastValue && DayJS(lastValue.date).isSame(DayJS(), 'd')) lastValue.status = status
  else values.push({ date: DayJS().format(), status })

  return values
}

export const hasCustomDates = ({ startDate, endDate }: { startDate?: string; endDate?: string }) =>
  (startDate && isCustomStartDate(startDate)) || (endDate && isCustomEndDate(endDate))

export const isCustomStartDate = (date: string) => {
  const dayinMonth = DayJS(date).date()
  const monthInYear = DayJS(date).month()

  return !(dayinMonth === 1 && [0, 3, 6, 9].includes(monthInYear))
}

export const isCustomEndDate = (date: string) => {
  const dayinMonth = DayJS(date).date()
  const monthInYear = DayJS(date).month()
  const dayInMonths = DayJS(date).daysInMonth()

  return !(dayinMonth === dayInMonths && [2, 5, 8, 11].includes(monthInYear))
}

export const isInQuarter = ({ endDate, startDate }: Objective, startOfQuarter: string) => {
  if (DayJS(endDate).isSame(startOfQuarter, 'quarter')) return true
  if (DayJS(startDate).isSame(startOfQuarter, 'quarter')) return true
  return DayJS(startDate).isSameOrBefore(startOfQuarter) && DayJS(endDate).isSameOrAfter(startOfQuarter)
}

export const formatObjectivePeriod = ({ startDate, endDate }: Objective) => {
  const _start = DayJS(DayJS(startDate).format(YYYY_MM_DD))
  const _end = DayJS(DayJS(endDate).format(YYYY_MM_DD))

  // Exact one quarter (first day of quarter to last day of quarter) → QX YYYY
  if (
    _start.isSame(DayJS(_start).startOf('quarter'), 'day') &&
    _end.isSame(DayJS(_end).endOf('quarter'), 'day') &&
    _start.quarter() === _end.quarter() &&
    _start.year() === _end.year()
  )
    return `Q${_start.quarter()} ${_start.year()}`

  // Exact two or more quarters ( first day to last day of quarter, same year) → Q1 - Q2 YYYY
  if (
    _start.isSame(DayJS(_start).startOf('quarter'), 'day') &&
    _end.isSame(DayJS(_end).endOf('quarter'), 'day') &&
    _start.quarter() !== _end.quarter() &&
    _start.year() === _end.year()
  )
    return `Q${_start.quarter()} - Q${_end.quarter()} ${_start.year()}`

  // Exact two or more quarters ( first day to last day of quarter, different year) → Q1 Y1 - Q2 YYY2
  if (
    _start.isSame(DayJS(_start).startOf('quarter'), 'day') &&
    _end.isSame(DayJS(_end).endOf('quarter'), 'day') &&
    _start.quarter() !== _end.quarter() &&
    _start.year() !== _end.year()
  )
    return `Q${_start.quarter()} ${_start.year()} - Q${_end.quarter()} ${_end.year()}`
  if (
    _start.isSame(DayJS(_start).startOf('quarter'), 'day') &&
    _end.isSame(DayJS(_end).endOf('quarter'), 'day') &&
    _start.quarter() === _end.quarter() &&
    _start.year() !== _end.year()
  )
    return `Q${_start.quarter()} ${_start.year()} - Q${_end.quarter()} ${_end.year()}`

  // Exact one month (first day to last day of month) → MMM YYYY
  if (
    _start.isSame(DayJS(_start).startOf('month'), 'day') &&
    _end.isSame(DayJS(_end).endOf('month'), 'day') &&
    _start.month() === _end.month() &&
    _start.year() === _end.year()
  )
    return _start.format('MMM YYYY')

  // Exact two months (first day to last day of month, same year) → MMM - MMM YYYY
  if (
    _start.isSame(DayJS(_start).startOf('month'), 'day') &&
    _end.isSame(DayJS(_end).endOf('month'), 'day') &&
    _start.month() !== _end.month() &&
    _start.year() === _end.year()
  )
    return `${_start.format('MMM')} - ${_end.format('MMM YYYY')}`

  // Exact two or more months (first day to last day of month, different year) → MMM YYY1 - MMM YYY2
  if (
    _start.isSame(DayJS(_start).startOf('month'), 'day') &&
    _end.isSame(DayJS(_end).endOf('month'), 'day') &&
    _start.month() !== _end.month() &&
    _start.year() !== _end.year()
  )
    return `${_start.format('MMM YYYY')} - ${_end.format('MMM YYYY')}`
  if (
    _start.isSame(DayJS(_start).startOf('month'), 'day') &&
    _end.isSame(DayJS(_end).endOf('month'), 'day') &&
    _start.month() === _end.month() &&
    _start.year() !== _end.year()
  )
    return `${_start.format('MMM YYYY')} - ${_end.format('MMM YYYY')}`

  // Any other dates (same year) → d MMM - d MMM YYYY
  if (_start.year() === _end.year()) return `${_start.format('DD MMM')} - ${_end.format('DD MMM YYYY')}`

  // Any other dates (different years) → d MMM YYY1 - d MMM YYY2
  return `${_start.format('DD MMM YYYY')} - ${_end.format('DD MMM YYYY')}`
}

// End date of objective is before current quarter start date OR
// Start date of objective is before current quarter start date
export const isCurrentOrFutureObjective = (o: Objective, now: string) =>
  isCurrentObjective(o, now) || isFutureObjective(o, now)

// End date of objective is before current quarter start date OR
// Start date of objective is before current quarter start date
export const isPreviousObjective = (o: Objective, now: string) =>
  DayJS(o.startDate).isBefore(now, 'quarter') || DayJS(o.endDate).isBefore(now, 'quarter')

// Start date of objective is after current quarter end date OR
// End date of objective is after current quarter end date
export const isFutureObjective = (o: Objective, now: string) =>
  DayJS(o.startDate).isAfter(now, 'quarter') || DayJS(o.endDate).isAfter(now, 'quarter')

// isPreviousObjective AND end date of objective is after current quarter start date
// isFutureObjective AND start date of objective is before current quarter end date
export const isCurrentObjective = (o: Objective, now: string) => isInQuarter(o, now)

export const isInPeriod = (o: Objective, { startDate, endDate }: SearchDates) => {
  if (DayJS(o.endDate).isSameOrBefore(startDate)) return false
  if (DayJS(o.startDate).isSameOrAfter(endDate)) return false
  return true
}

type IsStrategicObjectiveParams = { type?: ObjectiveType; strategicObjective?: boolean }

export const isStrategicObjective = (o?: IsStrategicObjectiveParams): boolean => {
  if (!o) return false
  if (o.type === ObjectiveType.STRATEGIC) return true
  if (o.type === ObjectiveType.TEAM) return false

  return o.strategicObjective || false
}
