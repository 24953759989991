import { mediumSpacing, regularSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { SignInViewProps } from '../ygdrasil/types/SignInTypes'
import Box from './Box'
import SignInSignUpLayout from './SignInSignUpLayout'
import SignInSignUpWithEmailForm from './SignInSignUpWithEmailForm'
import { SignInWithAuthProviders } from './SignInWithAuthProviders'
import { SignInLink } from './SignUpLink'
import { GreyLineMobile } from './StyledComponents'

export default function SignInSignUp(props: SignInViewProps) {
  return (
    <SignInSignUpLayout headingTextKey={Texts.signupHeaderMobile} subheadingTextKey={Texts.signupInfoMobile}>
      <Box fullWidth top gap={regularSpacing} bottom align="center" justify="center">
        <SignInWithAuthProviders {...props} />
        <Box top bottom direction="row" align="center" fullWidth spacing={tinySpacing}>
          <GreyLineMobile />
          <Box left right spacing={mediumSpacing} style={{ marginRight: tinySpacing }}>
            <FigmaText textKey={Texts.allSeparatorOr} />
          </Box>
          <GreyLineMobile />
        </Box>
        <SignInSignUpWithEmailForm {...props} />
      </Box>
      <SignInLink {...props} />
    </SignInSignUpLayout>
  )
}
