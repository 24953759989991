import { useState } from 'react'
import useAppState from '../hooks/useAppState'
import { useMsal } from '../hooks/useMsal'
import { withStopProgagation } from '../libs/HtmlHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import ConnectionType from '../ygdrasil/enums/ConnectionType'
import Images from '../ygdrasil/libs/Images'
import { useConnections } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { OurDrawer } from './Drawers'
import EditDatasourceMachine from './EditDatasourceMachine'
import { SettingsIntegrationsConnectionButton } from './SettingsIntegrationsConnectionButton'
import SettingsIntegrationsConnectionsTable from './SettingsIntegrationsConnectionsTable'

export default function SettingsIntegrations() {
  const [connectionType, setConnectionType] = useState<ConnectionType>()
  const { state } = useAppState()
  const { data: connections = [] } = useConnections(state)
  const shouldShowIntegrationTable = connections.length > 0
  const onClose = withStopProgagation((e) => setConnectionType(undefined))

  const shouldShowExcel = !connections.find(
    (c) => c.connectionType === ConnectionType.EXCEL_ONLINE && c.uid === state.user?._id
  )

  const shouldShowGoogleSheet = !connections.find(
    (c) => c.connectionType === ConnectionType.GOOGLE_SHEETS && c.uid === state.user?._id
  )

  const shouldShowHeader = shouldShowExcel || shouldShowGoogleSheet

  return (
    <Box fullPadding fullWidth>
      {shouldShowHeader && <FigmaText textKey={Texts.adminIntegrationsFilesHeaderText} />}
      <Box top direction="row">
        {shouldShowExcel && (
          <SettingsIntegrationsConnectionButton
            imageKey={Images.office365}
            textKey={Texts.adminIntegrationsExcelOnlineSelectionText}
            onClick={() => setConnectionType(ConnectionType.EXCEL_ONLINE)}
          />
        )}
        {shouldShowGoogleSheet && (
          <Box left>
            <SettingsIntegrationsConnectionButton
              imageKey={Images.googleIcon}
              textKey={Texts.adminIntegrationsGoogleSheetsSelectionText}
              onClick={() => setConnectionType(ConnectionType.GOOGLE_SHEETS)}
            />
          </Box>
        )}
      </Box>
      {shouldShowIntegrationTable && (
        <Box top fullWidth>
          <FigmaText textKey={Texts.adminIntegrationsConnectionHeaderText} />
          <Box top fullWidth>
            <SettingsIntegrationsConnectionsTable />
          </Box>
        </Box>
      )}
      <OurDrawer
        open={!!connectionType}
        content={
          <EditDatasourceMachine
            onClose={onClose}
            onDataSourceUpdated={() => undefined}
            connectionType={connectionType as ConnectionType}
          />
        }
        onClose={onClose}
      />
    </Box>
  )
}
