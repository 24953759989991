import ConnectionType from '../enums/ConnectionType'
import { Connection } from '../types/types'
import { removeDuplicates } from './Common'
import * as ExcelHelper from './ExcelHelper'
import { getValuesForRows } from './GoogleSheetsHelper'

export const getValuesForConnection = (rows, { connectionType }: Connection) => {
  const values =
    connectionType === ConnectionType.EXCEL_ONLINE ? ExcelHelper.getValuesForRows(rows) : getValuesForRows(rows)

  return removeDuplicates(values, 'date')
}
