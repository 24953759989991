import styled from '@emotion/styled'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import Box from './Box'
import { IconProps } from './Icon'
import { PlayCircleIcon } from './Icons'
import { bigSpacing } from '../enums/Spacings'

const DEFAULT_PLAY_ICON_SIZE = 50

export default function VideoThumbnail({
  onClick,
  iconProps
}: {
  iconProps?: Partial<IconProps>
  onClick: () => unknown
}) {
  return (
    <Container link onClick={onClick} align="center" justify="center" top bottom>
      <Box left right spacing={bigSpacing}>
        <PlayCircleIcon size={DEFAULT_PLAY_ICON_SIZE} {...iconProps} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  height: 100%;
  background-color: black;
  border-radius: ${tinyBorderRadius};
`
