import Menu from '@mui/material/Menu'
import { MenuItem } from './MenuItem'
import { GrowthIndicator } from '../ygdrasil/types/types'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import GrowthViewGrowthIndicatorRowMenuForArchivedIndicator from './GrowthViewGrowthIndicatorRowMenuForArchivedIndicator'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { archiveGrowthIndicator } from '../ygdrasil/libs/ProcessHandler'
import { removeGrowthIndicator } from '../ygdrasil/libs/DBApiHandler'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  onClickEdit: () => unknown
  growthIndicator: GrowthIndicator
}

export default function DashboardGrowthViewGrowthIndicatorRowMenu(props: Props) {
  const { growthIndicator, anchorEl, onClose } = props
  const config = useLoggedInConfig()
  const logEvent = useLogEvent()

  const onClickDelete = () => {
    onClose()
    removeGrowthIndicator(growthIndicator)
  }

  const onClickArchive = () => {
    onClose()
    archiveGrowthIndicator({ ...growthIndicator }, logEvent)
  }

  if (growthIndicator.isArchived) return <GrowthViewGrowthIndicatorRowMenuForArchivedIndicator {...props} />

  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItem onClick={onClickArchive} text={getFigmaText(Texts.archieveButtonText)}></MenuItem>
        {config.enableDeleteIndicator && (
          <MenuItem onClick={onClickDelete} text={getFigmaText(Texts.contextMenuCommentDeleteButtonText)}></MenuItem>
        )}
      </Menu>
    </div>
  )
}
