import dayjs from 'dayjs'
import { State } from '../hooks/useAppState'
import { removeDuplicates } from '../ygdrasil/libs/Common'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { Language } from '../ygdrasil/types/Antiloop'
import { MyFocus, MyFocusRequest } from '../ygdrasil/types/types'

const WEEKLY_DATE_FORMATS = {
  sv: 'D/M',
  default: 'MMM D'
}

const EXTENDED_WEEKEND = [4, 5, 6]

export const getWeeklyDateText = (date: string, language: Language) => {
  const format = WEEKLY_DATE_FORMATS[language] || WEEKLY_DATE_FORMATS.default
  const startOfWeek = dayjs(date).startOf('week').format(format)
  const endOfWeek = dayjs(date).endOf('week').format(format)
  if (language === 'sv') return `v.${dayjs(date).week()}, ${startOfWeek} - ${endOfWeek}`
  return `${startOfWeek} - ${endOfWeek}`
}

export function getKey(date?: string) {
  return dayjs(date).startOf('week').format(YYYY_MM_DD)
}

export function shouldShowRetrospectiveField(date: string, now: string = dayjs().format()) {
  dayjs.locale('sv')
  if (dayjs(date).isBefore(now, 'week')) return true
  if (dayjs(date).isSame(now, 'week')) return EXTENDED_WEEKEND.includes(dayjs().locale('sv').weekday())
  return false
}

export function getMyFocusForMenu(
  myFocusRows: MyFocus[] = [],
  { selectedOrganizationId, user }: State,
  now?: string
): MyFocusRequest[] {
  const emptyFocus = { why: '', retro: '', organizationId: selectedOrganizationId, userId: user._id }
  return removeDuplicates(
    [
      { date: getKey(dayjs(now).add(-1, 'week').format()), ...emptyFocus },
      { date: getKey(now), ...emptyFocus },
      { date: getKey(dayjs(now).add(1, 'week').format()), ...emptyFocus },
      ...myFocusRows
    ],
    'date'
  )
}
