import { DEFAULT_STATE, State } from '../hooks/useAppState'
import Colors from '../ygdrasil/libs/Colors'
import { ALL_STRATEGIC_PILLAR } from '../ygdrasil/libs/Consts'
import { DayJS } from '../ygdrasil/libs/DayJsHelper'
import {
  formatObjectivePeriod,
  getAllObjectivesForObjective,
  getChildObjctives,
  hasCustomDates,
  isStrategicObjective
} from '../ygdrasil/libs/ObjectiveHelper'
import { inRankOrder } from '../ygdrasil/libs/RankHelper'
import { SearchState, TreeData } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { Objective, ObjectiveType } from '../ygdrasil/types/types'

export const ROADMAP_SEARCH_STATE = {
  selectedObjectiveId: undefined,
  showArchivedObjectives: false,
  strategicPillar: ALL_STRATEGIC_PILLAR._id,
  selectedItemId: undefined,
  showTeamObjectives: false,
  activityLeadUserId: undefined,
  strategicObjective: undefined,
  searchText: '',
  searchTextGrowthView: ''
}

export const formatQuaterTextForObjective = (date: string, objective: Objective) => {
  if (hasCustomDates(objective)) return formatObjectivePeriod(objective)
  return formatQuaterText(date)
}

export const formatQuaterText = (date: string) => `Q${DayJS(date).quarter()} ${DayJS(date).year()}`

export const getColor = (type: ObjectiveType, isSelected: boolean) => {
  if (isSelected) return Colors.primary90
  return type === ObjectiveType.TEAM ? Colors.background : Colors.background
}

export const getBorder = (type: ObjectiveType) => {
  return type === ObjectiveType.TEAM ? `2px solid ${Colors.secondaryBase}` : `1px solid ${Colors.primaryBase}`
}

export function getStrategicObjectivesForRoadmap(
  filteredObjectives: Objective[], // This param assumes the getObjectivesForRoadmap fucntion has been called before
  { selectedObjectiveId }: SearchState
) {
  let strategicObjectives = filteredObjectives.filter((o) => isStrategicObjective(o))

  if (selectedObjectiveId)
    strategicObjectives = strategicObjectives.filter((o) =>
      getAllObjectivesForObjective(o, filteredObjectives)
        .map((o) => o._id)
        .includes(selectedObjectiveId)
    )

  strategicObjectives = strategicObjectives.sort(inDateAndRankOrder)
  return strategicObjectives
}

export const getNonStrategicObjectivesForRoadmap = (
  filteredObjectives: Objective[], // This param assumes the getObjectivesForRoadmap fucntion has been called before
  state: State
) => {
  let { searchState } = state
  if (!searchState) searchState = DEFAULT_STATE.searchState // Dont remove, older clients has no  search state
  const { selectedObjectiveId } = searchState
  let nonStrategicObjectives = filteredObjectives.filter((o) => !o.parentObjectiveId && !isStrategicObjective(o))

  if (selectedObjectiveId && !nonStrategicObjectives.some((o) => o._id === selectedObjectiveId))
    nonStrategicObjectives = []

  nonStrategicObjectives = nonStrategicObjectives.sort(inDateAndRankOrder)
  return nonStrategicObjectives
}

export const getObjectivesForRoadmap = (allObjectives: Objective[], searchResult: TreeData, state: State) => {
  let objectives = allObjectives.filter((o) => {
    if (!state.selectedTeamId) return true
    const objectives = getAllObjectivesForObjective(o, allObjectives)
    return objectives.some((o) => state.selectedTeamId && o.teamIds.includes(state.selectedTeamId))
  })

  objectives = objectives.filter((o) => !!searchResult.items[o._id])

  return objectives
}

export function getChildObjectivesForAccordionBody(o: Objective, objectives: Objective[]) {
  return getChildObjctives(o._id, objectives).sort(inDateAndRankOrder)
}

export const inDateAndRankOrder = (a: Objective, b: Objective) => {
  const dateOrder = DayJS(a.startDate).valueOf() - DayJS(b.startDate).valueOf()
  if (dateOrder !== 0) return dateOrder
  return inRankOrder(a, b)
}

export const shouldShowEmptyStateForStrategyView = (objectives: Objective[], state: State) =>
  // eslint-disable-next-line prettier/prettier
  getStrategicObjectivesForRoadmap(objectives, state.searchState).length +
    getNonStrategicObjectivesForRoadmap(objectives, state).length ===
  0

export function getRoadmapSearchState(headerDates: string[]): Partial<SearchState> {
  return {
    ...ROADMAP_SEARCH_STATE,
    searchDates: {
      startDate: DayJS.min(headerDates.map((d) => DayJS(d).startOf('quarter'))).format(),
      endDate: DayJS.max(headerDates.map((d) => DayJS(d).endOf('quarter'))).format()
    }
  }
}
