import FigmaText from '../ygdrasil/components/FigmaText'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import FigmaTextWithLinebreaks from '../ygdrasil/components/FigmaTextWithLinebreaks'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import Colors from '../ygdrasil/libs/Colors'
import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'

export function WeAreHereToHelpMobile() {
  return (
    <Container fullWidth align="center" fullPadding spacing={smallSpacing}>
      <Box bottom spacing={smallSpacing}>
        <FigmaText textKey={Texts.emailTempalteSupportContactHeader} />
      </Box>
      <Box bottom spacing={smallSpacing}>
        <FigmaImageContainer imageKey={Images.threeHeads} />
      </Box>
      <Box bottom spacing={smallSpacing}>
        <FigmaText textKey={Texts.emailTemplateSupportContactTitle} />
      </Box>
      <Box bottom spacing={smallSpacing}>
        <FigmaTextWithLinebreaks textKey={Texts.emailTemplateSupportContact} separator={<span />} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  border: 1px solid ${Colors.primaryBase};
`
