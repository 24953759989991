import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import invariant from 'invariant'
import { UploadFolder } from '../ygdrasil/enums/UploadFolder'
import { Storage } from '../ygdrasil/hooks/useStorageUrl'
import { createUniqueId } from '../ygdrasil/libs/Common'

export function uploadFile(file: Blob, uploadFolder: UploadFolder) {
  const ext = file.type.split('/')[1]
  invariant(ext, '!ext')
  const bucketPath = `${uploadFolder}/${createUniqueId()}.${ext}`
  const storageRef = ref(Storage, bucketPath)
  return uploadBytes(storageRef, file)
    .then((snapshot) => getDownloadURL(snapshot.ref))
    .then((url) => ({ url, bucketPath }))
}
