import { Formik } from 'formik'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH } from '../libs/HardCodedSizes'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { SearchState } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import Box from './Box'
import Button from './Button'
import CheckboxFormik from './CheckboxFormik'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import FigmaTextLoader from './FigmaTextLoader'
import Modal, { ModalProps } from './Modal'

type Props = ModalProps
type InitialValues = Partial<SearchState>
export function ModalSearchObjectives(props: Props) {
  const { state, setSearchState } = useAppState()
  const { searchState } = state
  const { showArchivedObjectives, showTeamObjectivesWhenNoTeamIsSelected, leadUserIds = [] } = searchState
  const initialValues: InitialValues = {
    showArchivedObjectives,
    showTeamObjectivesWhenNoTeamIsSelected,
    leadUserIds
  }
  const { onClose } = props

  const onSubmit = (values: InitialValues) => {
    setSearchState({ ...searchState, ...values })
    onClose()
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(formProps) => {
        const { values } = formProps
        return (
          <Modal {...props}>
            <FigmaText textKey={Texts.dialogFilterHeader} />
            <Box fullWidth style={{ maxWidth: EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH }}>
              <Box fullWidth top align="center" direction="row" spacing={smallSpacing}>
                <Box right spacing={smallSpacing}>
                  <CheckboxFormik formProps={formProps} name="showArchivedObjectives" />
                </Box>
                <FigmaTextForStrategyModel textKey={Texts.filterDialogShowArchivedObjectiveCheckBoxText} />
              </Box>
              <Box fullWidth top align="center" direction="row" spacing={smallSpacing}>
                <Box right spacing={smallSpacing}>
                  <CheckboxFormik formProps={formProps} name="showTeamObjectivesWhenNoTeamIsSelected" />
                </Box>
                <FigmaTextForStrategyModel textKey={Texts.filterDialogShowTeamObjectiveCheckBoxText} />
              </Box>
              <Box top direction="row" align="center" justify="flex-end" fullWidth>
                <Box right spacing={tinySpacing}>
                  <Button
                    buttonType="secondary"
                    textKey={Texts.cancelButtonText}
                    onClick={onClose}
                    spacing={smallSpacing}
                  />
                </Box>
                <Button onClick={formProps.handleSubmit as any} spacing={smallSpacing}>
                  <FigmaTextLoader textKey={Texts.filterButtonText} />
                </Button>
              </Box>
            </Box>
          </Modal>
        )
      }}
    </Formik>
  )
}
