import dayjs from 'dayjs'
import { GrowthIndicatorValue, GrowthIndicatorValues } from '../ygdrasil/types/types'

export const getPreviousValue = ({
  value,
  values
}: {
  value: GrowthIndicatorValue
  values: GrowthIndicatorValues
}): number | undefined => {
  const date = Object.keys(values)
    .sort()
    .reverse()
    .filter((date) => dayjs(date).isBefore(value.date))[0]

  return values[date]?.goal
}
