import React from 'react'
import Texts from '../ygdrasil/libs/Texts'
import useAppState from '../hooks/useAppState'
import { getStateForListView, getStateForRoadmapView } from '../libs/StrategyViewObjectivesHeaderToggleHelper'
import Box from './Box'
import { StrategyViewObjectivesHeaderToggleButton } from './StrategyViewObjectivesHeaderToggleButton'
import { AddButtonObjective } from './AddButtonObjective'
import { smallSpacing } from '../enums/Spacings'
import { useCanEditObjective } from '../ygdrasil/libs/PermissionsHelper'

export const StrategyViewObjectivesHeaderToggle = () => {
  const { state, setState } = useAppState()
  const { selectedDashboardView } = state
  const onClickListView = () => setState(getStateForListView(state))

  const onClickRoadmap = () => setState(getStateForRoadmapView(state))

  return (
    <Box direction="row">
      {useCanEditObjective() && (
        <Box right spacing={smallSpacing} id="AddObjectiveButton">
          <AddButtonObjective />
        </Box>
      )}
      <Box direction="row" alignSelf="center">
        <StrategyViewObjectivesHeaderToggleButton
          textKey={Texts.objectivesListControlsListViewButtonTextActive}
          onClick={onClickListView}
          isSelected={selectedDashboardView === 'list'}
        />
        <Box>
          <StrategyViewObjectivesHeaderToggleButton
            textKey={Texts.objectivesListControlsRoadmapButtonTextActive}
            onClick={onClickRoadmap}
            isSelected={selectedDashboardView === 'roadmap'}
          />
        </Box>
      </Box>
    </Box>
  )
}
