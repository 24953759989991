import { bigSpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export default function ErrorBoundaryView() {
  return (
    <Box fullWidth fullPadding align="center" spacing={bigSpacing}>
      <Box>
        <FigmaImageContainer imageKey={Images.errorApplicationMaintenance} />
      </Box>
      <Box top>
        <FigmaText textKey={Texts.errorMaintenanceTitle} />
      </Box>
      <Box top>
        <FigmaText textKey={Texts.errorMaintenanceDescription} />
      </Box>
    </Box>
  )
}
