import { useEffect } from 'react'
import useAppState from './useAppState'

export default function useScript(url, enable = true) {
  const { state } = useAppState()
  useEffect(() => {
    if (!enable) return
    if (!state.user._id) return
    const script = document.createElement('script')
    script.setAttribute('src', url)
    document.head.appendChild(script)
  }, [state.user._id])
}
