/* eslint-disable react/display-name */

import invariant from 'invariant'
import { useEffect } from 'react'

import useAppState from '../hooks/useAppState'
import { MSAL_REDIRECT_URL } from '../hooks/useMsal'
import useQueryParams, { OAuth2RedirectParams } from '../ygdrasil/hooks/useQueryParams'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import ConnectionType from '../ygdrasil/enums/ConnectionType'
import { acquireTokenByCode } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { createConnection } from '../ygdrasil/libs/DBApiHandler'
import { useConnections } from '../ygdrasil/libs/QueryHooks'
import Box from './Box'
import Loader from './Loader'

export default function OAuth2RedirectPage() {
  const { code } = useQueryParams<OAuth2RedirectParams>()
  const { state } = useAppState()
  const { data: connections = [] } = useConnections(state)

  useEffect(() => {
    const { codeVerifier } = state
    invariant(code, 'code is required')
    invariant(codeVerifier, 'code is required')
    acquireTokenByCode(
      { code, redirectUrl: MSAL_REDIRECT_URL, codeVerifier, orgId: state.selectedOrganizationId },
      state
    )
      .then(({ account }) => {
        const connection = connections.find((c) => c.homeAccountId === account?.homeAccountId)
        if (!!connection) return // should not be able to create two connections

        return createConnection(
          {
            uid: state.user._id,
            name: `Office 365 (${account?.username})`,
            homeAccountId: account?.homeAccountId as string,
            connectionType: ConnectionType.EXCEL_ONLINE,
            organizationId: state.selectedOrganizationId
          },
          state
        )
      })
      .catch(captureAndNotifyError)
      .finally(() => window.close())
  }, [])

  return (
    <Box fullWidth align="center" top>
      <Loader />
    </Box>
  )
}
