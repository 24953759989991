import React from 'react'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'

export function StrategyViewObjectiveListRowIndicatorBodyFormIntegration() {
  return (
    <Box fullWidth alignText="center">
      <b>
        <FigmaTextForStrategyModel textKey={Texts.graphIndicatorIntegrationInfo} />
      </b>
    </Box>
  )
}
