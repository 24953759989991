import { useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { EventParams, OurEvent } from '../ygdrasil/libs/EventHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { CelebrationViewItemHeader } from './CelebrationViewItemHeader'
import { CelebrationViewItemLikes } from './CelebrationViewItemLikes'
import { Graph } from './Graph'
import { DashboardListArrowDown, DashboardListArrowRight } from './Icons'

export type CelebrationViewItemProps<T> = {
  event: OurEvent<T>
  index: number
}
export function CelebrationViewItem(props: CelebrationViewItemProps<Partial<EventParams>>) {
  const { event } = props
  const { params } = event
  const config = useLoggedInConfig()

  const { objective, indicator } = params || {}
  const [isExpanded, setIsExpanded] = useState(true)

  const onClick = () => setIsExpanded(!isExpanded)

  if (!indicator) return null
  if (!objective) return null

  const parentItem = { data: { data: objective } } as Item
  const item = { data: { data: indicator } } as Item

  return (
    <Box fullPadding spacing={smallSpacing} fullWidth>
      <CelebrationViewItemHeader {...props} />
      <Box fullWidth>
        <Box fullWidth top spacing={smallSpacing} direction="row" align="center" pointer onClick={onClick}>
          <Box>{isExpanded ? <DashboardListArrowDown /> : <DashboardListArrowRight />}</Box>
          <Box left spacing={tinySpacing}>
            <FigmaText textKey={Texts.cssCelebrationItemIndicatorSummary} text={indicator?.name} />
          </Box>
        </Box>
        {isExpanded && (
          <Box fullWidth top spacing={tinySpacing}>
            <Box fullWidth top spacing={smallSpacing}>
              <Graph parentItem={parentItem} item={item} />
            </Box>
          </Box>
        )}
        <CelebrationViewItemLikes {...props} />
      </Box>
    </Box>
  )
}
