import { Formik } from 'formik'
import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useUpdateUser from '../hooks/useUpdateUser'
import { PERSONAL_SETTINGS_MAX_WIDTH } from '../libs/HardCodedSizes'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaText from '../ygdrasil/components/FigmaText'
import SettingsPersonalPhoto from './SettingsPersonalPhoto'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import { en, LANGUAGES_OBJECT } from '../ygdrasil/libs/Consts'
import { Language } from '../ygdrasil/types/Antiloop'
import SelectFieldWithLabel from './SelectFieldWithLabel'
import EventCategories from '../ygdrasil/enums/EventCategories'
/*
const ValidationSchema = Yup.object().shape({
  name: PersonNameValidation('Enter first and last name')
})
*/

type InitialValues = { name: string; language: Language }

export default function SettingsPersonal() {
  const { state, setState } = useAppState()
  const updateUser = useUpdateUser()
  const { user } = state
  const languageOptions = Object.keys(LANGUAGES_OBJECT).map((code) => ({ key: LANGUAGES_OBJECT[code], value: code }))

  const initialValues = {
    name: user.name,
    language: (user.language || en) as Language
  }
  const onSubmit = ({ name, language }: InitialValues) =>
    updateUser({ ...user, name, language })
      .then(() => language !== user.language && setState({ ...state }))
      .then(() => language !== user.language && window.location.reload())
  // should rerender app for better ux (i.e set state to same state as before triggers rerender)

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(formProps) => (
        <Container fullWidth top>
          <Box bottom spacing={smallSpacing}>
            <FigmaText textKey={Texts.adminPersonalInformationSectionHeader} />
          </Box>
          <Box fullWidth>
            <TextFieldWithLabelFormik
              labelTextKey={Texts.adminUserNameLabel}
              formProps={formProps}
              name="name"
              component={TextFieldOutlined}
              fullWidth
            />
            <Box top fullWidth>
              <SelectFieldWithLabel
                labelTextKey={Texts.adminSelectApplicationLanguageLabel}
                options={languageOptions}
                fullWidth
                value={formProps.values.language}
                onChange={({ target: { value: language } }) =>
                  formProps.setValues({ ...formProps.values, language: language as Language })
                }
              />
            </Box>
            <Box top alignSelf="flex-end">
              <Button textKey={Texts.saveButtonText} onClick={formProps.handleSubmit as any} />
            </Box>
          </Box>
          <Box top>
            <Box bottom>
              <FigmaText textKey={Texts.adminProfilePictureLabel} />
            </Box>
            <SettingsPersonalPhoto />
          </Box>
        </Container>
      )}
    </Formik>
  )
}

const Container = styled(Box)`
  max-width: ${PERSONAL_SETTINGS_MAX_WIDTH}px;
`
