import { ClickAwayListener } from '@mui/material'
import { FormikProps } from 'formik'
import { useState } from 'react'
import { smallSpacing } from '../enums/Spacings'
import { NAME } from '../libs/MyActionsMyToDosHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { BLUR_KEYCODES } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import TextFieldFormik from './TextFieldFormik'
import WithTooltipOverflow from './WithTooltipOverflowExperimental'

export function MyActionsEditTodoFormName<T extends { name: string }>({
  formProps,
  onBlurName,
  isEditing
}: {
  isEditing: boolean
  formProps: FormikProps<T>
  onBlurName: (e, formProps) => Promise<FormikProps<T>['errors'] | undefined>
}) {
  const [_isEditing, setIsEditing] = useState<boolean>(isEditing)
  const { values } = formProps
  const text = values?.name

  const _onBlurName = (e) => {
    onBlurName(e, formProps).then((errors) => {
      if (!errors) setIsEditing(false)
    })
  }

  if (_isEditing)
    return (
      <ClickAwayListener onClickAway={_onBlurName}>
        <Box fullWidth>
          <TextFieldFormik
            name={NAME}
            onBlur={_onBlurName}
            formProps={formProps}
            onKeyDown={(e) => BLUR_KEYCODES.includes(e.keyCode) && _onBlurName(e)}
            fullWidth
            autoFocus
          />
        </Box>
      </ClickAwayListener>
    )

  return (
    <Box fullWidth onClick={() => setIsEditing(true)} link fullPadding={text.length === 0} spacing={smallSpacing}>
      <WithTooltipOverflow text={formProps.values.name}>
        <FigmaText textKey={Texts.cssTodoText} text={text} />
      </WithTooltipOverflow>
    </Box>
  )
}
