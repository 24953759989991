import styled from '@emotion/styled'
import { ClickAwayListener, Popper } from '@mui/material'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import dayjs from 'dayjs'
import { minBorderRadius } from '../enums/BorderRadixes'
import { ONE_REM_IN_PX } from '../enums/Spacings'
import ZIndices from '../enums/Zindices'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'

export function DatePickerPopper(props: {
  value?: string
  onChange: (value: string) => unknown
  anchorEl: HTMLElement | null
  onClose: () => unknown
  minDate?: string
}) {
  const { value, anchorEl, onClose, minDate } = props
  const onChange = (newValue) => {
    onClose()
    props.onChange(newValue)
  }

  return (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} style={{ zIndex: ZIndices.modal }}>
      <ClickAwayListener onClickAway={onClose}>
        <PopperContainer align="flex-end">
          <Box style={{ maxHeight: '322px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={dayjs(value)}
                onChange={(value) => onChange(dayjs(value).format())}
                renderInput={(params) => <TextField {...params} />}
                minDate={!!minDate ? dayjs(minDate) : undefined}
              />
            </LocalizationProvider>
          </Box>
          <ClearDateButton right pointer onClick={() => onChange('')}>
            <FigmaText textKey={Texts.calendarClearDateButtonText} />
          </ClearDateButton>
        </PopperContainer>
      </ClickAwayListener>
    </Popper>
  )
}
const PopperContainer = styled(Box)`
  background: ${Colors.whiteBg};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${minBorderRadius};
`

const ClearDateButton = styled(Box)`
  padding-bottom: ${ONE_REM_IN_PX}px;

  &:hover {
    span {
      color: ${Colors.onPrimary} !important;
    }
  }
`
