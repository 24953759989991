import ConnectionType from '../ygdrasil/enums/ConnectionType'
import { EditDatasourceMachineSteps } from '../ygdrasil/types/EditDatasourceMachine'
import { Connection, Datasource } from '../ygdrasil/types/types'

export type EditDatasourceMachineProps = {
  dataSource?: Datasource
  onDataSourceUpdated: () => unknown
  connection?: Connection
  onClose: (e) => void
  connectionType: ConnectionType
}

export function getFirstStep({ connection, connectionType }: EditDatasourceMachineProps): EditDatasourceMachineSteps {
  if (connectionType === ConnectionType.GOOGLE_SHEETS) return EditDatasourceMachineSteps.EDIT_GOOGLE_DATA_SOURCE
  return !!connection ? EditDatasourceMachineSteps.EDIT_SHEET : EditDatasourceMachineSteps.EDIT_CONNECTION
}

export const GOOGLE_SERVICE_ACCOUNT_EMAIL = 'google-sheets@our-big-picture.iam.gserviceaccount.com'
