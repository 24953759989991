import MuiDrawer from '@mui/material/Drawer'
import { CSSObject, Theme } from '@mui/material/styles'
import useShouldShowExpandedMenu from '../hooks/useShouldShowExpandedMenu'
import { MENU_WIDTH, MENU_WIDTH_COLLAPSED } from '../libs/HardCodedSizes'
import MenuExpanded from './MenuExpanded'
import MenuCollapsed from './MenuCollapsed'
import { isSafari } from 'react-device-detect'
import Box from './Box'
import { defaultSpacing } from '../enums/Spacings'
import styled from '@emotion/styled'

export default function Menu() {
  const shouldShowExpandedMenu = useShouldShowExpandedMenu()

  return (
    <Drawer variant="permanent" open={shouldShowExpandedMenu}>
      {shouldShowExpandedMenu && <MenuExpanded />}
      {!shouldShowExpandedMenu && <MenuCollapsed />}
    </Drawer>
  )
}

const SafariDrawer = styled(Box)`
  margin-right: ${defaultSpacing};
`

const Component = (isSafari ? SafariDrawer : MuiDrawer) as any // TODO: Remove this hack when Safari supports backdrop-filter

const Drawer = styled(Component, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: open ? MENU_WIDTH : MENU_WIDTH_COLLAPSED
}))
