import { useState } from 'react'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getStateForStrategicView } from '../libs/MenuHelper'
import { getMyActivitesRows } from '../libs/MyActivitiesHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import ActivityBoardKanban from './ActivityBoardKanban'
import Box from './Box'
import { MyActionsRowHeader } from './MyActionsRowHeader'

export const MyActionsRow = (props: { item: Item; level: number }) => {
  const { state, setState } = useAppState()
  const [isExpanded, setIsExpanded] = useState(true)
  const { item, level } = props
  item.isExpanded = isExpanded

  const { children } = item

  const onClickBadge = () => setIsExpanded(!isExpanded)
  const onClickHeader = () =>
    setState({ ...getStateForStrategicView(state), searchState: { ...state.searchState, selectedItemId: item.id } })

  return (
    <Box fullWidth top bottom spacing={smallSpacing}>
      <Box fullWidth link bottom spacing={smallSpacing}>
        <MyActionsRowHeader item={item} level={level} onClickBadge={onClickBadge} onClickHeader={onClickHeader} />
      </Box>
      {isExpanded && item.data.searchType === 'keyResult' && (
        <Box top spacing={smallSpacing} fullWidth key={item.id}>
          <ActivityBoardKanban item={item} enableAddActivityForCurrentUser leadUserId={state.user._id} />
        </Box>
      )}
      {isExpanded &&
        getMyActivitesRows(children).map((item) => {
          return <MyActionsRow key={item.id} item={item} level={level + 1} />
        })}
    </Box>
  )
}
