import invariant from 'invariant'
import React from 'react'
import { Objective } from '../ygdrasil/types/types'
import { minSpacing } from '../enums/Spacings'
import { getRoadmapRowInfos, getStartAndEndDate, getWidth } from '../libs/RoadmapAccordionBodyHelper'
import Box from './Box'
import { RoadmapObjectiveWithLabel } from './RoadmapObjectiveWithLabel'

type Props = {
  headerDates: string[]
  objectives: Objective[] // Roadmapaccordion body must get allObjectives from parent because accordion rerenders this component on all state changes => when searching children till be empty array for short time causing flickering
  allObjectives: Objective[]
}
export const RoadmapAccordionBody = ({ headerDates, objectives, allObjectives }: Props) => {
  const { startDate: headerStartDate, endDate: headerEndDate } = getStartAndEndDate(headerDates)

  return (
    <Box fullWidth>
      {objectives.map((objective, key) => {
        const roadmapRowInfos = getRoadmapRowInfos(objective, headerDates)
        invariant(
          roadmapRowInfos.reduce((a, b) => a + b.numberOfPeriods, 0) === headerDates.length,
          'not correct amount of lengths'
        )
        return (
          <Box spacing={minSpacing} fullPadding fullWidth key={key} direction="row">
            {roadmapRowInfos.map(({ visible, numberOfPeriods }, key) => (
              <RoadmapObjectiveWithLabel
                key={key}
                objective={objective}
                headerStartDate={headerStartDate}
                headerEndDate={headerEndDate}
                visibility={visible ? 'visible' : 'hidden'}
                width={getWidth(numberOfPeriods, headerDates)}
                allObjectives={allObjectives}
              />
            ))}
          </Box>
        )
      })}
    </Box>
  )
}
