import React from 'react'
import { AntiloopTextType } from '../types/Antiloop'
import { getFigmaText } from '../libs/TextRepository'

type Props = {
  textKey: AntiloopTextType
  className?: string
  text?: string
  separator?: React.ReactElement
}

const FigmaTextWithLinebreaks: React.FC<Props> = ({ text: _text, textKey, className, separator = <br /> }) => {
  const { style } = textKey
  const text = typeof _text === 'string' ? _text : getFigmaText(textKey)
  const texts = text.split('\n')

  return (
    <>
      {texts.map((text, key) => {
        return (
          <React.Fragment key={key}>
            <span dangerouslySetInnerHTML={{ __html: text }} className={className} style={{ ...style }} />
            {separator}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default FigmaTextWithLinebreaks
