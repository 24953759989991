import { Dayjs } from 'dayjs'
import { FormikProps } from 'formik/dist/types'
import invariant from 'invariant'
import { isNumber } from '../ygdrasil/libs/Common'
import { DayJS } from '../ygdrasil/libs/DayJsHelper'
import { hasCustomDates } from '../ygdrasil/libs/ObjectiveHelper'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Objective, ObjectiveType, SearchDates } from '../ygdrasil/types/types'
import { InitialValues } from './EditObjectiveDialogHelper'

export type OnChangePeriod = (obj: SearchDates) => unknown

export type ObjectivePeriodAndType = { type: ObjectiveType; startDate?: string; endDate: string }

export type Mark = {
  value: number
  date: string
}

export const DEFAULT_SLIDER_VALUE_START = 0
export const DEFAULT_SLIDER_VALUE_END = 1

export const YEARS_WITH_YEAR_STEP = 2

export const getMarks = (startDate: string = DayJS().format(), endDate?: string): Mark[] => {
  const yearsWithQuarterStep = getYearsWithQuarterStep(startDate)
  let value = 0
  let date = DayJS(startDate).startOf('quarter')
  const endDateInner =
    endDate ??
    DayJS(startDate)
      .add(yearsWithQuarterStep + YEARS_WITH_YEAR_STEP, 'year')
      .startOf('year')
  const marks: Mark[] = []
  while (DayJS(date).isSameOrBefore(endDateInner)) {
    marks.push({
      value,
      date: date.format()
    })
    value += endDate ? 1 : getStep(date, startDate)
    date = date.add(1, 'quarter')
  }

  return marks
}

const getStep = (date: Dayjs, startDate: string) => {
  if (date.year() < DayJS(startDate).year() + getYearsWithQuarterStep(startDate)) return 1
  return 1 / 4
}

function getYearsWithQuarterStep(startDate: string) {
  return DayJS(startDate).year() + 1 === DayJS().year() ? 3 : 2
}

export function getStartAndEndValue(marks: Mark[], seaerchDates?: SearchDates): Array<number | undefined> {
  const startDate = DayJS(seaerchDates?.startDate).startOf('quarter').format()
  const startValue = marks.find(({ date }) => DayJS(date).isSame(startDate, 'day'))?.value
  let endIndex = marks.findIndex(({ date }) => DayJS(date).endOf('quarter').isSame(seaerchDates?.endDate, 'day'))
  if (isNumber(endIndex)) {
    endIndex++
    endIndex = Math.min(endIndex, marks.length - 1)
  }
  const endValue = marks[endIndex]?.value

  return [startValue, endValue]
}

export const getSliderDescriptionTextForValue = (
  values: Array<number | undefined>,
  marks: Mark[],
  objective?: Partial<Objective>
) => {
  if (objective && hasCustomDates(objective)) return ''
  if (typeof values[0] === 'undefined') return ''
  if (typeof values[1] === 'undefined') return ''

  const startMark = marks.find(({ value: _value }) => _value === values[0])
  invariant(startMark, '!startMark')
  let endMarkIndex = marks.findIndex(({ value: _value }) => _value === values[1])
  endMarkIndex--
  endMarkIndex = Math.max(0, endMarkIndex)
  const endMark = marks[endMarkIndex]
  invariant(endMark, '!endMark')

  const startMarkLabel = `Q${DayJS(startMark.date).quarter()} ${DayJS(startMark.date).year()}`
  const endMarkLabel = `Q${DayJS(endMark.date).quarter()} ${DayJS(endMark.date).year()}`

  if (values[1] - values[0] === 1) return startMarkLabel
  return formatText(getFigmaText(Texts.dateformatPeriodSelectorFromTo), [startMarkLabel, endMarkLabel])
}

export const onChangeType = (type: ObjectiveType, formProps: FormikProps<InitialValues>) => {
  if (type === formProps.values.type) return
  const values = { ...formProps.values, type }
  formProps.setValues(values)
}
