import styled from '@emotion/styled'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import Button from './Button'
import { openEditObjectiveDrawer } from './DrawerGlobalEditObjectiveDialog'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'

export default function StrategyViewObjectiveListEmptyState() {
  const { state } = useAppState()
  const config = useLoggedInConfig()
  let textKey: AntiloopTextType = state.selectedTeamId
    ? Texts.teamviewEmptyStateButtonText
    : Texts.pageContentStrategicObjectiveEmptyText

  if (!config.enableEditObjectivesForNonLeads) textKey = Texts.emptyStateNoObjectiveUserLacksPermissions

  return (
    <Container fullWidth align="center" justify="center" fullPadding spacing={tinySpacing}>
      <FigmaTextForStrategyModel textKey={textKey} />
      {!state.selectedTeamId && config.enableEditObjectivesForNonLeads && (
        <Box top spacing={smallSpacing}>
          <Button buttonType="empty_state" onClick={() => openEditObjectiveDrawer({})}>
            <FigmaTextForStrategyModel textKey={Texts.emptyStateCreateFirstObjective} />
          </Button>
        </Box>
      )}
    </Container>
  )
}

const Container = styled(Box)``
