import Menu from '@mui/material/Menu'
import { MenuItem } from './MenuItem'
import React from 'react'
import { Objective } from '../ygdrasil/types/types'
import useVisibleObjectives from '../ygdrasil/hooks/useVisibleObjectives'
import { unArchiveObjective } from '../libs/ClientProcessHandler'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import useAppState from '../hooks/useAppState'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  objective: Objective
}

export default function StrategyViewObjectiveListObjectiveMenuForArchivedObjective({
  objective,
  anchorEl,
  onClose
}: Props) {
  const { data: allObjectives = [] } = useVisibleObjectives()
  const { state } = useAppState()
  const { data: org } = useOrganization(state)

  const onClickUnarchiveObjective = (e) => {
    e.stopPropagation() // without this line onClick method on FocusedObjective is fired => bad
    onClose()
    unArchiveObjective(objective, allObjectives)
  }

  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItem onClick={onClickUnarchiveObjective}>
          {getTextForTextKey(Texts.unarchiveObjectiveButtonText, org)}
        </MenuItem>
      </Menu>
    </div>
  )
}
