import { State } from '../hooks/useAppState'
import { DeepLinkHelperQueryParams, getQueryParams } from '../ygdrasil/hooks/useQueryParams'
import RoutePath from '../ygdrasil/libs/RoutePath'

export function getStateFromDeepLink(location: Location): Partial<State> {
  const { search } = location
  const queryParams = getQueryParams<DeepLinkHelperQueryParams>(search)
  const { showDashboard, showSettings } = queryParams
  const res = { searchState: {} } as any
  const orgId = queryParams.orgId
  const leadUserId = queryParams.leadUserId
  const selectedItemId = queryParams.selectedItemId
  const showCelebrationView = queryParams.showCelebrationView
  const showMyActivities = queryParams.showMyActivities

  // TODO should remove, use showSettings query param instead
  if (shouldShowNotifications(location)) res.showSettings = true

  // TODO WRITE TEST, shgould show dashboard when showDashboard query param is true
  if (showDashboard) res.showDashboard = true

  // TODO WRITE TEST, shgould show dashboard when showDashboard query param is true
  if (showSettings) res.showSettings = true

  if (!!orgId) res.selectedOrganizationId = orgId

  if (!!leadUserId) res.searchState.leadUserIds = [leadUserId]

  if (!!selectedItemId) res.searchState.selectedItemId = selectedItemId

  if (!!showCelebrationView) res.showCelebrationView = true

  if (!!showMyActivities) res.showMyActivities = true

  return res
}

export function shouldShowNotifications({ pathname }: Location): boolean {
  return pathname.includes(RoutePath.SETTINGS_NOTIFICATIONS)
}
