import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import useAppState from '../hooks/useAppState'
import { ONE_YEAR_IN_QUARTER } from '../ygdrasil/libs/Consts'
import { useGrowthIndicators } from '../ygdrasil/libs/QueryHooks'
import Box from './Box'
import { SuccessViewGraphView } from './SuccessViewGraphView'
import SuccessViewHeader from './SuccessViewHeader'
import SuccessViewSheetView from './SuccessViewSheetView'

export default function SuccessView() {
  const { state } = useAppState()
  const { data: growthIndicators = [] } = useGrowthIndicators(state)
  const defaultSearchDates = useMemo(() => {
    return {
      startDate: dayjs().startOf('year').format(),
      endDate: dayjs().endOf('year').format()
    }
  }, [])
  const [searchDates, setSearchDates] = useState(defaultSearchDates)
  const isGraphView = state.selectedSuccessView === 'graph'

  useEffect(() => {
    setSearchDates(defaultSearchDates)
  }, [isGraphView])

  return (
    <Box fullWidth bottom>
      <SuccessViewHeader
        onChange={setSearchDates}
        searchDates={searchDates}
        timespanRestriction={isGraphView ? undefined : ONE_YEAR_IN_QUARTER}
      />
      {isGraphView && <SuccessViewGraphView growthIndicators={growthIndicators} searchDates={searchDates} />}
      {!isGraphView && <SuccessViewSheetView searchDates={searchDates} />}
    </Box>
  )
}
