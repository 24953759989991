import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { minBorderRadius } from '../enums/BorderRadixes'
import { bigSpacing, littleSpacing, smallSpacing } from '../enums/Spacings'
import useClientWidth from '../hooks/useClientWidth'
import { TINY_GRAPH_HEIGHT, TINY_GRAPH_MIN_WIDTH } from '../libs/HardCodedSizes'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { getChunks } from '../ygdrasil/libs/Common'
import { updateObjective } from '../ygdrasil/libs/DBApiHandler'
import { getTodaysValue, getTodaysValueLabel, getValueForStrategyViewTinyGraph } from '../ygdrasil/libs/GraphHelper'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import { ActivityBoard } from './ActivityBoard'
import Box from './Box'
import Button from './Button'
import { openEditKeyResultDrawer } from './DrawerGlobalEditKeyResultDialog'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { GraphTiny } from './GraphTiny'
import { BIG_ICON_SIZE, CloseIcon, GraphTinyFlag } from './Icons'
import { EditSummaryStyledIcon, StyledTextFieldEditSummary } from './StrategyViewObjectiveListRowDetailBodyIndicator'
import { StrategyViewObjectiveListRowDetailContainer } from './StrategyViewObjectiveListRowDetailContainer'
import { StrategyViewObjectiveListRowDetailProps } from './StrategyViewObjectiveListRowDetailHelper'
import StrategyViewObjectiveListRowDetailItemTable from './StrategyViewObjectiveListRowDetailItemTable'
import WithTooltipOverflow from './WithTooltipOverflowExperimental'

const MAX_TINY_GRAPHS_IN_ONE_ROW = 3
export function StrategyViewObjectiveListRowDetailBodyObjective({
  item,
  parentItem,
  onSelectItem,
  onClickClose
}: StrategyViewObjectiveListRowDetailProps) {
  const children = item.children.filter((child) => child.data.searchType === 'keyResult')

  const hasChildren = children.length > 0
  const showKanbanBoard = hasChildren

  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const clientWidth = useClientWidth(ref) || window.innerWidth

  const chunkSize = Math.min(Math.floor(clientWidth / TINY_GRAPH_MIN_WIDTH), MAX_TINY_GRAPHS_IN_ONE_ROW)

  const graphSets = getChunks(children, chunkSize)

  const [objectiveSummary, setObjectiveSummary] = useState<string>(item?.data?.data?.name)
  const [focused, setFocused] = useState<boolean>(false)
  const handleUpdateSummary = () => {
    updateObjective({ ...(item?.data?.data as Objective), name: objectiveSummary })
  }

  useEffect(() => {
    setObjectiveSummary(item?.data?.data?.name)
  }, [item?.data?.data?.name])

  return (
    <Box fullWidth fullHeight>
      <ObjectiveListDetailBodyHeaderWrapper fullWidth bottom spacing={smallSpacing} align="center" direction="row">
        <Box fullWidth top spacing={smallSpacing} align="center" style={{ paddingLeft: bigSpacing }}>
          <StyledTextFieldEditSummary
            ref={inputRef}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                setFocused(false)
                return handleUpdateSummary()
              }
            }}
            value={objectiveSummary}
            onChange={(e) => {
              setObjectiveSummary(e.target.value)
            }}
            variant="standard"
            inputProps={{
              style: {
                textAlign: 'center',
                cursor: 'pointer',
                ...Texts.indicatorDetailPageIndicatorSummary.style
              }
            }}
            focused={focused}
            onFocus={() => setFocused(true)}
            fullWidth
            multiline
          />
          {focused && (
            <Box direction="row" fullPadding spacing={littleSpacing} gap={smallSpacing}>
              <EditSummaryStyledIcon
                onClick={() => {
                  handleUpdateSummary()
                  return setFocused(false)
                }}
              >
                <FigmaImageContainer imageKey={Images.saveIconPurple} />
              </EditSummaryStyledIcon>
              <EditSummaryStyledIcon
                onClick={() => {
                  inputRef.current?.blur()
                  setFocused(false)
                }}
              >
                <FigmaImageContainer imageKey={Images.closeIconRed} />
              </EditSummaryStyledIcon>
            </Box>
          )}
        </Box>
        <Container top left right align="flex-end" spacing={smallSpacing}>
          <Box onClick={onClickClose} pointer>
            <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
          </Box>
        </Container>
      </ObjectiveListDetailBodyHeaderWrapper>
      <Container fullPadding spacing={smallSpacing} fullWidth fullHeight ref={ref}>
        <StrategyViewObjectiveListRowDetailContainer fullWidth left right>
          <Box fullWidth top bottom spacing={smallSpacing}>
            <Box fullWidth>
              <StrategyViewObjectiveListRowDetailItemTable item={item} parentItem={parentItem} />
            </Box>
          </Box>
        </StrategyViewObjectiveListRowDetailContainer>

        {!hasChildren && (
          <Box fullWidth align="center" top spacing={bigSpacing} style={{ height: '100vh' }}>
            <Box spacing={bigSpacing} top>
              <Button
                buttonType="empty_state"
                onClick={() => openEditKeyResultDrawer({ objective: item.data.data as Objective })}
              >
                <FigmaTextForStrategyModel textKey={Texts.addIndicatorButtonText} />
              </Button>
            </Box>
          </Box>
        )}

        {graphSets.map((children, index) => {
          const maxWidth = clientWidth / children.length
          return (
            <Box fullWidth direction="row" align="center" key={index}>
              {children.map((child, key, arr) => {
                const kr = child.data.data as KeyResult
                return (
                  <InnerContainer
                    key={key}
                    spacing={smallSpacing}
                    right={arr.length - 1 !== key}
                    fullWidth
                    maxWidth={maxWidth}
                    top
                  >
                    <StrategyViewObjectiveListRowDetailContainer
                      fullPadding
                      spacing={smallSpacing}
                      fullHeight
                      fullWidth
                    >
                      <Box fullWidth>
                        <Box fullWidth onClick={() => onSelectItem && onSelectItem(child.id)} link>
                          <WithTooltipOverflow textKey={Texts.allTooltipText} text={kr.name}>
                            <FigmaText textKey={Texts.cssTinyGraphSummaryText} text={kr.name} />
                          </WithTooltipOverflow>
                        </Box>
                        <Box fullWidth direction="row" justify="space-between" align="center">
                          <FigmaText
                            textKey={Texts.cssTinyGraphActualValueText}
                            text={getTodaysValueLabel(getTodaysValue(kr).value, kr)}
                          />
                          <GoalValueContainer direction="row" align="center">
                            <Box right spacing={smallSpacing}>
                              <GraphTinyFlag />
                            </Box>
                            <FigmaText
                              textKey={Texts.cssTinyGraphGoalValue}
                              text={getValueForStrategyViewTinyGraph(kr.goalValue, kr)}
                            />
                          </GoalValueContainer>
                        </Box>
                      </Box>
                      <GraphTiny item={child} parentItem={item} />
                    </StrategyViewObjectiveListRowDetailContainer>
                  </InnerContainer>
                )
              })}
            </Box>
          )
        })}
      </Container>
      <Box spacing={smallSpacing} fullWidth top />
      {showKanbanBoard && (
        <Container fullWidth>
          <ActivityBoard item={item} parentItem={parentItem} />
        </Container>
      )}
    </Box>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.snow};
`

const GoalValueContainer = styled(Box)`
  background-color: ${Colors.neutral97};
  border-radius: ${minBorderRadius};
  padding: ${smallSpacing};
`

const InnerContainer = styled(Box)<{ maxWidth: number }>`
  height: ${TINY_GRAPH_HEIGHT}px;
  min-width: ${TINY_GRAPH_MIN_WIDTH}px;
  max-width: ${({ maxWidth }) => maxWidth}px;
`

export const ObjectiveListDetailBodyHeaderWrapper = styled(Box)`
  position: sticky;
  top: 0;
  background-color: ${Colors.whiteBg};
  z-index: 99999;
`
