import { Organization, PartialConfig, SWOTItem } from '../ygdrasil/types/types'
import {
  createBusinessPlanDrawerFormAddSwot,
  createBusinessPlanDrawerFormEditSwot
} from '../components/BusinessPlanDrawers'
import { openDrawer } from '../components/DrawerGlobalBusinessPlan'

export function openSwotDrawer(org: Organization, swotItem: SWOTItem, config: PartialConfig) {
  if (!org) return
  const drawer = org.businessPlan.swot[swotItem]
    ? createBusinessPlanDrawerFormAddSwot(swotItem)
    : createBusinessPlanDrawerFormEditSwot(swotItem)
  openDrawer(config, drawer)
}
