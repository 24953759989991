import { State } from '../../hooks/useAppState'
import { Objective, ObjectiveType } from '../types/types'
import { ALL_STRATEGIC_PILLAR, MIN_SEARCH_LENGTH, NO_STRATEGIC_PILLAR, UNASSIGNED_TEAM_ID } from './Consts'
import * as ObjectiveHelper from './ObjectiveHelper'
import { getDefaultSearchDates } from './SearchPeriodHelper'
import { SearchObject } from './StrategyViewTreeSearchHelper'

export const isMatch = (so: SearchObject, state: State): boolean | undefined => {
  const { searchState, selectedTeamId } = state
  const {
    showArchivedObjectives,
    strategicPillar,
    strategicObjective,
    showTeamObjectivesWhenNoTeamIsSelected,
    leadUserIds = [],
    searchText,
    selectedObjectiveId,
    activityLeadUserId,
    selectedIndicatorId
  } = searchState

  // söker inte på team mål, inte valt ett team.
  // Just nu: får upp team mål
  let res: boolean | undefined = undefined

  // eslint-disable-next-line prettier/prettier
  if (res !== false && !isInPeriodForState(so, state)) res = false

  // eslint-disable-next-line prettier/prettier
  if (res !== false && !showArchivedObjectives && so.isArchived) res = false

  // eslint-disable-next-line prettier/prettier
  if (
    res !== false &&
    !!selectedTeamId &&
    selectedTeamId === UNASSIGNED_TEAM_ID &&
    so.objectiveType !== ObjectiveType.TEAM
  )
    res = false

  // eslint-disable-next-line prettier/prettier
  if (
    res !== false &&
    !!selectedTeamId &&
    selectedTeamId === UNASSIGNED_TEAM_ID &&
    so.objectiveType === ObjectiveType.TEAM
  )
    res = so.teamIds.length === 0

  // eslint-disable-next-line prettier/prettier
  if (res !== false && !!selectedTeamId && selectedTeamId !== UNASSIGNED_TEAM_ID)
    res = so?.teamIds?.includes(selectedTeamId)

  // eslint-disable-next-line prettier/prettier
  if (
    res !== false &&
    !selectedTeamId &&
    so.objectiveType === ObjectiveType.TEAM &&
    !showTeamObjectivesWhenNoTeamIsSelected
  )
    res = false

  // eslint-disable-next-line prettier/prettier
  if (
    res !== false &&
    so.objectiveType === ObjectiveType.STRATEGIC &&
    !!strategicPillar &&
    ![ALL_STRATEGIC_PILLAR._id, NO_STRATEGIC_PILLAR._id]?.includes(strategicPillar) &&
    !so.strategicPillarIds?.includes(strategicPillar)
  )
    res = false

  // eslint-disable-next-line prettier/prettier
  if (
    res !== false &&
    so.objectiveType === ObjectiveType.STRATEGIC &&
    !!strategicPillar &&
    strategicPillar === NO_STRATEGIC_PILLAR._id &&
    !!so.strategicPillarIds?.[0]
  )
    res = false

  // eslint-disable-next-line prettier/prettier
  if (
    res !== false &&
    so.objectiveType !== ObjectiveType.STRATEGIC &&
    !so.parentId &&
    !!strategicPillar &&
    strategicPillar !== ALL_STRATEGIC_PILLAR._id
  )
    res = false

  // eslint-disable-next-line prettier/prettier
  if (res !== false && !!selectedObjectiveId && so.rootObjectiveId !== selectedObjectiveId) res = false

  // eslint-disable-next-line prettier/prettier
  if (res !== false && !!selectedIndicatorId && !so.indicatorIds?.includes(selectedIndicatorId)) res = false

  // eslint-disable-next-line prettier/prettier
  if (res !== false && !!strategicObjective) res = so.objectiveType === ObjectiveType.STRATEGIC

  // TODO WRITE TEST, should should not trigger on activity
  // eslint-disable-next-line prettier/prettier
  if (
    res !== false &&
    leadUserIds.length > 0 &&
    so.searchType !== 'activity' &&
    !!so.leadUserIdIncludingParent &&
    !leadUserIds.includes(so.leadUserIdIncludingParent)
  )
    res = false

  // eslint-disable-next-line prettier/prettier
  if (
    res !== false &&
    leadUserIds.length > 0 &&
    so.searchType !== 'activity' &&
    !!so.leadUserId &&
    leadUserIds.includes(so.leadUserId)
  )
    res = true

  // eslint-disable-next-line prettier/prettier
  if (res !== false && !!searchText) res = isMatchedText(so.name, searchText)

  // eslint-disable-next-line prettier/prettier
  if (res !== false && !!activityLeadUserId && so.searchType === 'activity') res = activityLeadUserId === so.leadUserId

  return res
}

export const isMatchedText = (text: string, searchText?: string): boolean =>
  !!searchText &&
  searchText.length >= MIN_SEARCH_LENGTH &&
  text?.toLowerCase().includes(searchText.trim().toLowerCase())

export const isInPeriodForState = (o: SearchObject, state) => {
  const searchDates = state.searchState.searchDates || getDefaultSearchDates(state)
  return ObjectiveHelper.isInPeriod({ startDate: o.startDate, endDate: o.endDate } as Objective, searchDates)
}
