import { BusinessPlan } from '../ygdrasil/types/types'
import { DEGREE_PER_CHAR, THREE_SIXTY } from './BusinessplanRing'

export function getDirection(degree: number) {
  return degree > 90 && degree < 270 ? -1 : 1
}

export const getRingWordProps = (
  businessPlan: BusinessPlan
): { fromDegree: number; value: string; direction: number }[] =>
  businessPlan.values
    .map((value, i, arr) => ({ value, degree: (THREE_SIXTY / arr.length) * i }))
    .map(({ value, degree }) => {
      if (!value) value = ''
      const direction = getDirection(degree)
      return { fromDegree: degree - direction * (value.length / 2) * DEGREE_PER_CHAR, value, direction }
    })
