import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, SMALL_SPACING_PX, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { ACTION_BOARD_COLUMN_MAX_WIDTH } from '../libs/HardCodedSizes'
import { getTextWithDots } from '../libs/StrategyViewObjectiveTreeHelper'
import { ACTIVITY_CARD_DONE_TEXT_MAX_LENGTH, isOverDue } from '../ygdrasil/libs/ActivityHelper'
import Colors from '../ygdrasil/libs/Colors'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import { Activity } from '../ygdrasil/types/types'
import Box from './Box'
import { OnCreateEditActivity } from './EditActivityDialogForm'
import FigmaTextWithSearchResult from './FigmaTextWithSearchResult'
import { MoreActionVert } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import StrategyViewObjectiveListKeyResultActivityListItemMenu from './StrategyViewObjectiveListKeyResultActivityListItemMenu'
import { NoWrapFigmaText } from './StyledComponents'
import WithTooltipMaxLength from './WithTooltipMaxLength'
import { onClickActivityName } from '../libs/ActivityBoardHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'

type ActivityBoardKanbanCardProps = {
  item: Item
  index: number
  onCreateEditActivity?: OnCreateEditActivity
  onDeleteActivity?: OnCreateEditActivity
  enableEditing?: boolean
}

export function ActivityBoardKanbanCardActivityDone({
  item,
  onCreateEditActivity,
  onDeleteActivity,
  enableEditing = true
}: ActivityBoardKanbanCardProps) {
  const activity = item.data.data as Activity
  const hasUrl = !!activity.url
  const { state } = useAppState()
  const { searchText } = state.searchState
  const { data: org } = useOrganization(state)
  return (
    <Container isOverDue={isOverDue(activity)} flagged={activity.flagged}>
      <Box fullWidth direction="row" justify="space-between">
        <Box link={hasUrl} onClick={() => onClickActivityName(activity, org)}>
          <WithTooltipMaxLength title={activity.name} maxLength={ACTIVITY_CARD_DONE_TEXT_MAX_LENGTH}>
            <FigmaTextWithSearchResult
              textKey={Texts.cssActivityCardSummaryText}
              text={getTextWithDots(activity.name, ACTIVITY_CARD_DONE_TEXT_MAX_LENGTH)}
              searchText={searchText}
              Component={NoWrapFigmaText}
            />
          </WithTooltipMaxLength>
        </Box>

        <Box left spacing={tinySpacing}>
          <MoreActionWithMenu
            visible={enableEditing}
            Menu={(props) => (
              <StrategyViewObjectiveListKeyResultActivityListItemMenu
                {...props}
                activity={activity}
                onCreateEditActivity={onCreateEditActivity}
                onDeleteActivity={onDeleteActivity}
              />
            )}
            MoreAction={MoreActionVert}
            isAlwaysVisible={enableEditing}
          />
        </Box>
      </Box>
    </Container>
  )
}

export const Container = styled.div<{ isDragging?: boolean; isOverDue?: boolean; flagged?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${smallSpacing};
  border-radius: ${minBorderRadius};
  background-color: ${({ flagged }) => (flagged ? Colors.error90 : Colors.whiteBg)};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: ${minBorderRadius};
  border: 1px solid ${Colors.neutral97};
  ${({ flagged }) => (flagged ? `border-left: 2px solid ${Colors.error80}` : '')};
  ${({ isOverDue }) => (isOverDue ? `border: 1px solid ${Colors.statusOverdue};` : '')};
  overflow-wrap: anywhere;
`
