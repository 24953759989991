import useAppState, { State } from '../../hooks/useAppState'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import { KeyResult, Objective, PartialConfig, User } from '../types/types'
import { Item } from './StrategyViewObjectiveListHelper'

export function shouldShowMoreAction(item: Item, config: PartialConfig, user?: User) {
  if (item.data.searchType === 'activity') return true
  if (item.data.leadUserId === user?._id) return true
  if (item.data.searchType === 'objective' && config.enableEditObjectivesForNonLeads) return true
  if (item.data.searchType === 'keyResult' && config.enableEditoIndicatorsForNonLeads) return true
  return false
}

export const useCanEditObjective = (objective?: Objective) =>
  canEditObjective(useLoggedInConfig(), useAppState().state, objective)

export const canEditObjective = (config: PartialConfig, state: State, objective?: Objective) =>
  (!!objective?.leadUserId && objective?.leadUserId === state.user._id) || config.enableEditObjectivesForNonLeads

export const useCanEditIndicator = (indicator?: KeyResult) =>
  canEditIndicator(useLoggedInConfig(), useAppState().state, indicator)

export const canEditIndicator = (config: PartialConfig, state: State, indicator?: KeyResult) =>
  (!!indicator?.leadUserId && indicator?.leadUserId === state.user._id) || config.enableEditObjectivesForNonLeads
