/* eslint-disable prefer-const */
import { useState } from 'react'
import OtpInput from 'react-otp-input'
import { minimumBorderRadius } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { onChangeText } from '../libs/SignInCodeHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { toPx } from '../ygdrasil/libs/Common'
import { NO_OF_LETTERS_IN_SIGN_IN_CODE } from '../ygdrasil/libs/Consts'
import Images from '../ygdrasil/libs/Images'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { SignInViewProps } from '../ygdrasil/types/SignInTypes'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import Loader from './Loader'
import SignInSignUpLayout from './SignInSignUpLayout'

const LETTER_WIDTH = toPx(Texts.onboardingEmailCodeInputText.style.fontSize) * 2
const LETTER_HEIGHT = LETTER_WIDTH * 1.3

export default function SignInCode(props: SignInViewProps) {
  const { signInState, onTakeMeBack, isLoading } = props
  const { data } = signInState
  const { email, loginWithCodeTokenError, code: _code = '' } = data
  let [code, setCode] = useState(_code)
  const descriptionTextKey = Texts.verifyCodeSubheading

  return (
    <SignInSignUpLayout>
      <Box fullWidth fullHeight top align="center" justify="center">
        <FigmaImageContainer imageKey={Images.veryfyCodeIllustration} />
        <Box top spacing={bigSpacing} align="center">
          <FigmaText textKey={Texts.verifyCodeHeadingMobile} />
          <Box top spacing={smallSpacing} align="center">
            <FigmaText textKey={descriptionTextKey} text={formatText(getFigmaText(descriptionTextKey), [email])} />
            <FigmaText textKey={Texts.verifyCodeSubheadingGuide} />
          </Box>
        </Box>
        <Box top direction="row" align="center">
          {isLoading && <Loader />}
          {!isLoading && (
            <OtpInput
              value={code}
              onChange={(code) => onChangeText(code, setCode, props)}
              numInputs={NO_OF_LETTERS_IN_SIGN_IN_CODE}
              isInputNum
              inputStyle={{
                width: LETTER_WIDTH,
                height: LETTER_HEIGHT,
                border: `1px solid ${Colors.borderColorLightBlue}`,
                borderRadius: minimumBorderRadius,
                marginLeft: tinySpacing,
                marginRight: tinySpacing,
                ...Texts.onboardingEmailCodeInputText.style,
                color: 'black'
              }}
            />
          )}
        </Box>
        {loginWithCodeTokenError && (
          <Box top>
            <FigmaText textKey={Texts.onboardingEmailCodeErrorTextNotValid} />
          </Box>
        )}
        <Box top>
          <FigmaText textKey={Texts.onboardingEmailCodeInfoText} />
        </Box>
        <Box top fullWidth align="center">
          <Button textKey={Texts.secondaryReturnToLoginButtonText} buttonType="secondary" onClick={onTakeMeBack} />
        </Box>
      </Box>
    </SignInSignUpLayout>
  )
}
