import React, { useState } from 'react'
import Box from './Box'
import SettingsPersonalPhotoMenu from './SettingsPersonalPhotoMenu'
import { SettingsPersonalPhotoContainer } from './SettingsPersonalPhotoContainer'

export default function SettingsPersonalPhoto() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  return (
    <Box>
      <SettingsPersonalPhotoMenu anchorEl={anchorEl} onClose={() => setAnchorEl(undefined)} />
      <Box onClick={(e) => setAnchorEl(e.currentTarget)} link>
        <SettingsPersonalPhotoContainer />
      </Box>
    </Box>
  )
}
