import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { SWOTItem } from '../ygdrasil/types/types'
import Box from './Box'
import { BusinessPlanSwotRow } from './BusinessPlanSwotRow'

export function BusinessPlanSwot() {
  const { state } = useAppState()
  const { data: o } = useOrganization(state)

  if (!o) return null
  return (
    <Box fullWidth>
      <Box fullWidth bottom spacing={smallSpacing}>
        <BusinessPlanSwotRow swotItem={SWOTItem.Strength} text={o.businessPlan.swot.strength} />
      </Box>
      <Box fullWidth bottom spacing={smallSpacing}>
        <BusinessPlanSwotRow swotItem={SWOTItem.Weaknesses} text={o.businessPlan.swot.weakness} />
      </Box>

      <Box fullWidth bottom spacing={smallSpacing}>
        <BusinessPlanSwotRow swotItem={SWOTItem.Opportunities} text={o.businessPlan.swot.opportunity} />
      </Box>

      <Box fullWidth bottom spacing={smallSpacing}>
        <BusinessPlanSwotRow swotItem={SWOTItem.Threats} text={o.businessPlan.swot.threats} />
      </Box>
    </Box>
  )
}
