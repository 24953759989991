import { StrategyModel } from '../ygdrasil/enums/StrategyModel'
import { Organization } from '../ygdrasil/types/types'

export const OrganizationConfigurationProperties: Array<keyof Organization> = [
  'allowUserWithVerifiedEmailFromSameDomainToJoinOrganization',
  'enableLimitedUser'
]

export function getFirestoreUrlForORgId(selectedOrganizationId: string) {
  return `https://console.firebase.google.com/project/our-big-picture/firestore/data/~2Forganizations~2F${selectedOrganizationId}`
}

export function isDefault(org: Organization, strategyModel: StrategyModel): boolean | undefined {
  if (!org?.strategyModel) return strategyModel === StrategyModel.DEFAULT
  return org.strategyModel === strategyModel
}
