import { Drawer } from '@mui/material'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { RequiredNameValidationDeprecated } from '../libs/ValidationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import Colors from '../ygdrasil/libs/Colors'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { logOnboardingEvent } from '../ygdrasil/libs/FirebaseHelper'
import Texts from '../ygdrasil/libs/Texts'
import { OnboardingViewProps } from '../ygdrasil/types/Onboarding'
import { Objective } from '../ygdrasil/types/types'
import BackButtonMobileOnboarding from './BackButtonMobileOnboarding'
import Box from './Box'
import Button from './Button'
import { CloseIcon } from './Icons'
import ObjectivePeriodAndTypeOptionsSlider from './ObjectivePeriodAndTypeOptionsSlider'
import OnboardingMobileWrapper from './OnboardingMobileWrapper'
import OnboardingPaginationComponent from './OnboardingPaginationComponent'
import TextFieldFormikErrorComponent from './TextFieldFormikErrorComponent'
import { TextFieldMobile } from './TextFieldMobile'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

const ValidationSchema = Yup.object().shape({
  name: RequiredNameValidationDeprecated('name'),
  endDate: RequiredNameValidationDeprecated('accomplishBy')
})

export default function OnboardingAddObjectivesMobile(props: OnboardingViewProps) {
  const { onClickBack, onPressContinue, state } = props
  const [showPeriodSelector, setShowPeriodSelector] = React.useState(false)
  const [startDate, setStartDate] = useState(dayjs().startOf('quarter').format(YYYY_MM_DD))
  const [endDate, setEndDate] = useState(dayjs().endOf('quarter').format(YYYY_MM_DD))

  const initialValues = {
    name: '',
    startDate,
    endDate
  }

  const onSubmit = ({ name, startDate, endDate: accomplishBy }: typeof initialValues) =>
    onPressContinue({
      ...state,
      data: { ...state.data, firstObjective: { name, startDate, accomplishBy: accomplishBy } }
    })

  useEffect(() => {
    logOnboardingEvent(AnalyticsEvent.ONBOARDING_STARTED, state)
  }, [])

  const onChangePeriod = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const onCloseDrawer = (formProps) => {
    setShowPeriodSelector(false)
  }

  return (
    <OnboardingMobileWrapper
      content={() => (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
          {(formProps) => (
            <Box fullWidth align="center" top spacing={smallSpacing}>
              <Drawer open={showPeriodSelector} anchor="bottom" onClose={() => onCloseDrawer(formProps)}>
                <Box fullWidth bottom>
                  <Box fullWidth fullPadding spacing={tinySpacing}>
                    <Box fullWidth onClick={() => onCloseDrawer(formProps)} align="flex-end">
                      <CloseIcon color={Colors.black} />
                    </Box>
                    <Box fullWidth fullPadding spacing={smallSpacing}>
                      <ObjectivePeriodAndTypeOptionsSlider
                        onChange={onChangePeriod}
                        searchDates={initialValues as Objective}
                      />
                    </Box>
                  </Box>
                </Box>
              </Drawer>
              <Box fullWidth align="flex-start" justify="center" top left spacing={smallSpacing}>
                <BackButtonMobileOnboarding
                  textKey={Texts.onboardingMobileBackButtonText}
                  onClickBack={() => onClickBack(state)}
                />
              </Box>
              <Box align="center" fullWidth left right spacing={smallSpacing}>
                <Box top spacing={bigSpacing}></Box>
                <Box fullWidth top spacing={bigSpacing}>
                  <TextFieldWithLabelFormik
                    labelTextKey={Texts.onboardingMobileFirstObjectiveLabel}
                    formProps={formProps}
                    name="name"
                    component={TextFieldMobile}
                    fullWidth
                    isMobile
                    isWhite
                    ErrorComponent={TextFieldFormikErrorComponent}
                  />
                </Box>
                <Box fullWidth align="center" top bottom spacing={bigSpacing}>
                  <Button buttonType="white" onClick={formProps.handleSubmit as any}>
                    <FigmaText textKey={Texts.onboardingMobileContinueButtonText} />
                  </Button>
                </Box>
                <OnboardingPaginationComponent
                  opacityFirstDot={100}
                  opacitySecondDot={100}
                  opacityThirdDot={100}
                  opacityForthDot={40}
                />
              </Box>
            </Box>
          )}
        </Formik>
      )}
    />
  )
}
