import { AllItems } from '../ygdrasil/hooks/useAllItems'
import { createUniqueId } from '../ygdrasil/libs/Common'
import { CreateOrganizationRequest, GrowthIndicator, KeyResult, Objective, Organization } from '../ygdrasil/types/types'

export const getNewOrganization = (org: Organization): Organization => ({
  ...org,
  _id: createUniqueId(),
  msalTokenCacheJSON: JSON.stringify({}),
  logoPath: undefined
})

export const getNewGrowthIndicators = (growthIndicators: GrowthIndicator[], newOrg: Organization) =>
  growthIndicators.map((gi) => ({ ...gi, organizationId: newOrg._id }))

export const getNewAllItems = (allItems: AllItems, newOrg: Organization): AllItems => {
  const { allObjectives } = allItems
  return allObjectives
    .filter((objective) => !objective.parentObjectiveId)
    .reduce(
      (a, objective) => {
        const {
          allActivities: newActivities,
          allIndicators: newIndicators,
          allObjectives: newObjectives
        } = getNewAllItemsForObjective(objective, allItems, newOrg, undefined)

        a.allIndicators = a.allIndicators.concat(newIndicators)
        a.allActivities = a.allActivities.concat(newActivities)

        a.allObjectives = a.allObjectives.concat(newObjectives)

        return a
      },
      { allActivities: [], allIndicators: [], allObjectives: [] } as AllItems
    )
}

function getNewAllItemsForObjective(
  objective: Objective,
  allItems: AllItems,
  newOrg: Organization,
  newParent?: Objective
): AllItems {
  const { allObjectives } = allItems
  const newObjectiveId = createUniqueId()
  const newObjective = {
    ...objective,
    _id: newObjectiveId,
    parentObjectiveId: newParent?._id,
    organizationId: newOrg._id,
    teamIds: [],
    leadMetaData: undefined,
    leadUserId: undefined
  } as Objective

  const { allIndicators, allActivities } = getNewIndicatorsAndActivitiviesForObjective(
    objective,
    newObjective,
    newOrg,
    allItems
  )
  return allObjectives
    .filter((o) => o.parentObjectiveId === objective._id)
    .reduce(
      (a, o) => {
        const {
          allActivities: newActivities,
          allIndicators: newIndicators,
          allObjectives: newObjectives
        } = getNewAllItemsForObjective(o, allItems, newOrg, newObjective)

        a.allIndicators = a.allIndicators.concat(newIndicators)
        a.allActivities = a.allActivities.concat(newActivities)
        a.allObjectives = a.allObjectives.concat(newObjectives)

        return a
      },
      { allActivities, allIndicators, allObjectives: [newObjective] } as AllItems
    )
}

function getNewIndicatorsAndActivitiviesForObjective(
  objective: Objective,
  newObjective: Objective,
  newOrg: Organization,
  { allActivities, allIndicators }: AllItems
): AllItems {
  return allIndicators
    .filter((indicator) => indicator.parentId === objective._id)
    .reduce(
      (a, indicator) => {
        const newIndicator = {
          ...indicator,
          parentId: newObjective._id,
          _id: createUniqueId(),
          organizationId: newOrg._id,
          leadMetaData: undefined,
          leadUserId: undefined
        } as KeyResult
        a.allIndicators.push(newIndicator)

        const activities = allActivities
          .filter((a) => a.parentId === indicator._id)
          .map((a) => ({
            ...a,
            _id: createUniqueId(),
            parentId: newIndicator._id,
            organizationId: newOrg._id,
            leadMetaData: undefined,
            leadUserId: undefined
          }))

        a.allActivities = a.allActivities.concat(activities)

        return a
      },
      { allActivities: [], allIndicators: [], allObjectives: [] } as AllItems
    )
}
