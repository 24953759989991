import { addQueryParamsToUrl, SignInPageQueryParams } from '../hooks/useQueryParams'
import { ServerFirebaseUser } from '../types/ServerTypes'
import { Organization } from '../types/types'
import { getDomain, getOrganizationsForEmail } from './Common'
import config from '../../Config'
import { COMMON_EMAIL_DOMAINS, EUROPE_WEST_1, US_CENTRAL_1 } from './Consts'

export const shouldCreateNewOrganization = (email: string, orgs: Organization[]): boolean => {
  const domain = getDomain(email)
  if (COMMON_EMAIL_DOMAINS.includes(domain)) return true

  return getOrganizationsForEmail(email, orgs).length === 0
}

export type GetExistingJoinableOrganizationsReq = {
  email: string
  orgs: Organization[]
  firebaseUser?: ServerFirebaseUser
}

export const getExistingJoinableOrganizations = ({
  email,
  orgs
}: GetExistingJoinableOrganizationsReq): Array<Organization> => {
  const domain = getDomain(email)

  if (COMMON_EMAIL_DOMAINS.includes(domain)) return []

  return getOrganizationsForEmail(email, orgs)
}

export const getClientRegion = (c: typeof config) => (c.enableLocalCloudFunctions ? undefined : EUROPE_WEST_1)

export const getServerRegion = (c: typeof config) => (c.enableLocalCloudFunctions ? US_CENTRAL_1 : EUROPE_WEST_1)

export function formatActionUrl(actionUrl: string, { email }: SignInPageQueryParams) {
  return addQueryParamsToUrl(actionUrl, { email })
}
