import dayjs from 'dayjs'
import { isInPeriod } from '../ygdrasil/libs/ObjectiveHelper'
import { Objective } from '../ygdrasil/types/types'

export function isDisabled(objective: Objective) {
  return !isInPeriod(objective || {}, {
    startDate: dayjs().startOf('quarter').format(),
    endDate: dayjs().endOf('quarter').format()
  })
}
