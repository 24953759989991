import CloudFunctionError from './CloudFunctionError'
import { captureException, captureServerError } from './SentryHelperDeprecated'

export default function onUnhandledPromiseRejection(error: any) {
  if (error.code === 'auth/cancelled-popup-request') return
  if (error.code === 'auth/popup-closed-by-user') return
  if (error.code === 'auth/provider-already-linked') return
  if (error.code === 'auth/network-request-failed') return
  if (error.code === 'auth/internal-error') return

  // eventEmitter.emit(Events.NEW_SERVER_ERROR)
  if (error instanceof CloudFunctionError) {
    const { cloudFunctionError, fnName, state } = error
    captureServerError(cloudFunctionError, fnName, state)
  } else captureException(error)
}
