import { Formik } from 'formik'
import invariant from 'invariant'
import * as Yup from 'yup'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useNotification from '../hooks/useNotification'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { updateOrganization } from '../ygdrasil/libs/DBApiHandler'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

export const SettingsMyOrganizationForm = () => {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const notificaion = useNotification()
  const config = useLoggedInConfig()

  if (!org) return null
  const domains = org.domains || []

  const initialValues = {
    name: org?.name || '',
    domains: domains.join(',')
  }

  const onSubmit = ({ name, domains: _domains }: typeof initialValues) => {
    invariant(org, '!org')
    const domains = _domains.split(',').map((text) => text.trim()) || []
    const res = { ...org, name }
    if (domains.length > 0) res.domains = domains

    return updateOrganization(res).then(() => notificaion.success())
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema} enableReinitialize>
      {(formProps) => (
        <Box fullWidth align="flex-end" bottom>
          <Box fullWidth align="center" bottom spacing={smallSpacing}>
            <Box fullWidth direction="row" align="center">
              <FigmaText textKey={Texts.settingsMyOrgOrgNameLabel} text="Id:" />
              <Box left spacing={tinySpacing}>
                <FigmaText textKey={Texts.settingsMyOrgOrgNameLabel} text={org?._id} />
              </Box>
            </Box>
            <Box bottom spacing={smallSpacing} />
            <TextFieldWithLabelFormik
              labelTextKey={Texts.settingsMyOrgOrgNameLabel}
              formProps={formProps}
              name="name"
              component={TextFieldOutlined}
              fullWidth
            />
            <Box bottom spacing={smallSpacing} />
            <Box left right spacing={tinySpacing}></Box>
            {config.enableEditDomains && (
              <TextFieldWithLabelFormik
                labelTextKey={Texts.settingsMyOrgOrgNameDomainLabel}
                formProps={formProps}
                name="domains"
                component={TextFieldOutlined}
                fullWidth
              />
            )}
          </Box>
          <Button
            textKey={Texts.saveButtonText}
            onClick={() => onSubmit(formProps.values)}
            loading={formProps.isSubmitting}
          />
        </Box>
      )}
    </Formik>
  )
}
const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation(),
  domains: RequiredTextValidation()
})
