import styled from '@emotion/styled'
import MaterialPopper from '@mui/material/Popper'
import { minBorderRadius } from '../enums/BorderRadixes'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'

type Props = {
  anchorEl?: HTMLElement
}

const Popper: React.FC<Props> = ({ anchorEl, children }) => {
  return (
    <MaterialPopper open={Boolean(anchorEl)} anchorEl={anchorEl}>
      <PopperContainer>{children}</PopperContainer>
    </MaterialPopper>
  )
}

export default Popper

const PopperContainer = styled(Box)`
  background: ${Colors.whiteBg};
  border: 1px solid ${Colors.neutralVariant};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${minBorderRadius};
`
