import { useState } from 'react'
import OtpInput from 'react-otp-input'
import { minimumBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { onChangeText } from '../libs/SignInCodeHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { toPx } from '../ygdrasil/libs/Common'
import { NO_OF_LETTERS_IN_SIGN_IN_CODE } from '../ygdrasil/libs/Consts'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { SignInViewProps } from '../ygdrasil/types/SignInTypes'
import Box from './Box'
import Button from './Button'
import Loader from './Loader'
import SignInSignUpLayout from './SignInSignUpLayout'

const LETTER_WIDTH = toPx(Texts.mobileOnboardingEmailCodeInputText.style.fontSize)
const LETTER_HEIGHT = LETTER_WIDTH * 1.2

export default function SignInCodeMobile(props: SignInViewProps) {
  const { signInState, onTakeMeBack, isLoading } = props
  const { data } = signInState
  const { email, loginWithCodeTokenError, code: _code = '' } = data
  const [code, setCode] = useState(_code)

  return (
    <SignInSignUpLayout headingTextKey={Texts.verifyCodeHeadingMobile}>
      <Box fullWidth align="center" justify="center">
        <Box gap={smallSpacing} align="center" alignText="center">
          <FigmaText
            textKey={Texts.verifyCodeSubheading}
            text={formatText(getFigmaText(Texts.verifyCodeSubheading), [email])}
          />
          <FigmaText textKey={Texts.verifyCodeSubheadingGuide} />
        </Box>
        <Box top direction="row" align="center">
          {isLoading && <Loader />}
          {!isLoading && (
            <OtpInput
              value={code}
              onChange={(code) => onChangeText(code, setCode, props)}
              numInputs={NO_OF_LETTERS_IN_SIGN_IN_CODE}
              shouldAutoFocus
              isInputNum
              inputStyle={{
                width: LETTER_WIDTH,
                height: LETTER_HEIGHT,
                border: `1px solid ${Colors.borderColorLightBlue}`,
                borderRadius: minimumBorderRadius,
                marginLeft: tinySpacing,
                marginRight: tinySpacing,
                ...Texts.onboardingEmailCodeInputText.style,
                padding: 0
              }}
            />
          )}
        </Box>
        {loginWithCodeTokenError && (
          <Box top>
            <FigmaText textKey={Texts.mobileOnboardingEmailCodeErrorTextNotValid} />
          </Box>
        )}
        <Box top alignText="center">
          <FigmaText textKey={Texts.onboardingEmailCodeInfoText} />
        </Box>
        <Box top fullWidth align="center">
          <Button textKey={Texts.secondaryReturnToLoginButtonText} buttonType="secondary" onClick={onTakeMeBack} />
        </Box>
      </Box>
    </SignInSignUpLayout>
  )
}
