import { TextFieldProps } from '@mui/material/TextField'
import React from 'react'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Box from './Box'
import FigmaText from '../ygdrasil/components/FigmaText'
import { TextFieldOutlined } from './TextFieldOutlined'
import styled from '@emotion/styled'
import { smallSpacing, tinySpacing } from '../enums/Spacings'

type Props = {
  labelTextKey: AntiloopTextType
  placeholderTextKey?: AntiloopTextType
  className?: string
  pointer?: boolean
} & TextFieldProps

export const TextFieldWithLabel = (props: Props) => {
  const { labelTextKey, placeholderTextKey, children, fullWidth, ...textFieldProps } = props
  return (
    <Box fullWidth={fullWidth} direction="column">
      <Box bottom spacing={tinySpacing}>
        <FigmaText textKey={labelTextKey} />
      </Box>
      <StyledTextField
        {...textFieldProps}
        margin="dense"
        placeholder={placeholderTextKey ? getFigmaText(placeholderTextKey) : undefined}
        fullWidth
      />
      {children}
    </Box>
  )
}

const StyledTextField = styled(TextFieldOutlined)`
  &.MuiFormControl-marginDense {
    margin-top: 0;
  }
`
