import styled from '@emotion/styled'
import { PopoverPosition } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import React, { useState } from 'react'
import { smallBorderRadius } from '../enums/BorderRadixes'
import { SMALL_SPACING_PX } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useIsExpandedTreeItem } from '../hooks/useIsExpandedTreeItem'
import { useMediaQueryRules } from '../hooks/useMediaQuery'
import { isPropValid } from '../libs/EmotionHelper'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import { shouldShowMoreAction } from '../ygdrasil/libs/PermissionsHelper'
import { useUser } from '../ygdrasil/libs/QueryHooks'
import { inRankOrder } from '../ygdrasil/libs/RankHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { inSearchTypeOrder, SearchObject } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import Box from './Box'
import { MoreActionVert } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import { StrategyViewObjectiveListItemSummary } from './StrategyViewObjectiveListItemSummary'
import { StrategyViewObjectiveListRowAddLine } from './StrategyViewObjectiveListRowAddLine'
import { StrategyViewObjectiveListRowCheckIn } from './StrategyViewObjectiveListRowCheckIn'
import { StrategyViewObjectiveListRowLead } from './StrategyViewObjectiveListRowLead'
import { StrategyViewObjectiveListRowMenu } from './StrategyViewObjectiveListRowMenu'
import { StrategyViewObjectiveListRowPeriod } from './StrategyViewObjectiveListRowPeriod'
import { StrategyViewObjectiveListRowProgress } from './StrategyViewObjectiveListRowProgress'
import { StrategyViewObjectiveListRowTeams } from './StrategyViewObjectiveListRowTeams'
import { TableBox } from './TableBox'

const COL_SPAN = 7
export const StrategyViewObjectiveListRow = (props: {
  item: Item
  items: Item[]
  level: number
  onSelectItem: (item: Item) => unknown
}) => {
  const config = useLoggedInConfig()
  const { shouldShowExtraColumns } = useMediaQueryRules()
  const { state } = useAppState()
  const { searchState } = state
  const { selectedItemId } = searchState
  const { data: user } = useUser(state.user._id)
  const { item, items, level } = props

  const onSelectItem = () => props.onSelectItem(item)

  const { isExpanded, setIsExpanded } = useIsExpandedTreeItem(item)

  const onToggleExpand = () => setIsExpanded(!isExpanded)

  const { data: o, children } = item
  const index = items.findIndex(({ id }) => item.id === id)
  const nextItem = index !== -1 ? items[index + 1] : undefined

  const [isHovering, setIsHovering] = useState(false)
  const onMouseEnter = () => setIsHovering(true)
  const onMouseLeave = () => setIsHovering(false)

  const paddingLeft = level * SMALL_SPACING_PX * 2

  const [anchorPosition, setAnchoPosition] = useState<PopoverPosition>() // { top: 0, left: 0 }

  const _shouldShowMoreAction = shouldShowMoreAction(item, config, user)

  return (
    <>
      <StyledTableRow
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onContextMenu={(e: React.MouseEvent) => {
          e.preventDefault()
          setAnchoPosition({ top: e.clientY, left: e.clientX })
        }}
        isSelected={item.id === selectedItemId}
        isListInDropDown={state.showDropDownList}
      >
        <TableCell>
          <Box
            direction="row"
            align="center"
            style={{ paddingLeft, maxWidth: !state.showDropDownList ? '44vw' : '75vw' }}
            fullWidth
            pointer
          >
            <StrategyViewObjectiveListRowMenu
              menuProps={{
                anchorPosition,
                anchorReference: 'anchorPosition',
                onClose: () => setAnchoPosition(undefined),
                open: !!anchorPosition && _shouldShowMoreAction
              }}
              item={item}
            />

            <Box direction="row" align="center" fullWidth>
              <StrategyViewObjectiveListItemSummary
                item={item}
                isExpanded={isExpanded}
                level={level}
                onClickHeader={onSelectItem}
                onClickArrow={onToggleExpand}
              />
            </Box>
          </Box>
        </TableCell>
        {shouldShowExtraColumns && (
          <>
            <TableCell>
              <TableBox onClick={onSelectItem}>
                <StrategyViewObjectiveListRowPeriod data={item.data} />
              </TableBox>
            </TableCell>
            <TableCell>
              <TableBox onClick={onSelectItem} align="center">
                <StrategyViewObjectiveListRowLead item={item} />
              </TableBox>
            </TableCell>
            <TableCell>
              <TableBox onClick={onSelectItem}>
                <StrategyViewObjectiveListRowTeams data={item.data} />
              </TableBox>
            </TableCell>
          </>
        )}
        {!state.showDropDownList && (
          <>
            <TableCell>
              <TableBox onClick={onSelectItem}>
                <StrategyViewObjectiveListRowProgress item={item} />
              </TableBox>
            </TableCell>
            <TableCell>
              <TableBox onClick={onSelectItem} align="center">
                <StrategyViewObjectiveListRowCheckIn itemData={o} />
              </TableBox>
            </TableCell>
          </>
        )}
        <TableCell>
          <TableBox>
            <MoreActionWithMenu
              visible={_shouldShowMoreAction}
              Menu={(props) => <StrategyViewObjectiveListRowMenu menuProps={props} item={item} />}
              MoreAction={MoreActionVert}
              isAlwaysVisible
            />
          </TableBox>
        </TableCell>
      </StyledTableRow>
      <tr style={{ height: 0 }}>
        <td colSpan={COL_SPAN}>
          <Box position="relative" fullWidth style={{ paddingLeft }}>
            <StrategyViewObjectiveListRowAddLine item={item} nextItem={nextItem} />
          </Box>
        </td>
      </tr>
      {isExpanded &&
        item.data.searchType === 'objective' &&
        children
          .sort(inRankOrder)
          .sort((a, b) => inSearchTypeOrder(a.data as unknown as SearchObject, b.data as unknown as SearchObject))
          .map((item) => (
            <StrategyViewObjectiveListRow
              key={item.id}
              item={item}
              items={items}
              level={level + 1}
              onSelectItem={props.onSelectItem}
            />
          ))}
    </>
  )
}

const StyledTableRow = styled(TableRow, { shouldForwardProp: isPropValid })<{
  isSelected?: boolean
  isListInDropDown?: boolean
}>`
  & .MuiTableCell-root {
    border-width: 0px;
    padding: 0px;
    border-top: 1px dotted ${Colors.primary90};
    ${({ isSelected }) => (isSelected ? `background-color: ${Colors.neutral97};` : '')}
    height: ${({ isListInDropDown }) => isListInDropDown && 'calc(38px) !important'};
  }

  :hover {
    background-color: ${Colors.neutral97};
    border-radius: ${smallBorderRadius};
  }
`
