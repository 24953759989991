import React, { useEffect } from 'react'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import { Objective } from '../ygdrasil/types/types'
import { OurDrawer } from './Drawers'
import { ReorderObjectivesDrawer } from './ReorderObjectivesDrawer'

type OpenEditReorderDrawerParams = {
  objective: Objective
}

const Event = Events.OPEN_REORDER_OBJECTIVE_DRAWER

export const openReorderObjectiveDrawer = (data: OpenEditReorderDrawerParams) => eventEmitter.emit(Event, data)

export default function DrawerGlobalReorderObjectives() {
  const [visible, setVisible] = React.useState(false)
  const [objective, setObjective] = React.useState<Objective>()
  const onClose = () => {
    setObjective(undefined)
    setVisible(false)
  }

  useEffect(() => {
    eventEmitter.on(Event, ({ objective }: OpenEditReorderDrawerParams) => {
      setObjective(objective)
      setVisible(true)
    })

    return () => {
      eventEmitter.removeAllListeners(Event)
    }
  }, [])

  if (!objective) return null

  return (
    <OurDrawer
      open={visible}
      content={<ReorderObjectivesDrawer objective={objective} onClose={onClose} />}
      onClose={onClose}
    />
  )
}
