enum EventCategories {
  UserLogIn = 'UserLogIn',
  UserCreateIndicator = 'UserCreateIndicator',
  UserEditObjective = 'UserEditObjective',
  UserCreateObjective = 'UserCreateObjective',
  UserCreateActivity = 'UserCreateActivity',
  UserEditIndicator = 'UserEditIndicator',
  UserEditActivity = 'UserEditActivity',
  UserDeleteObjective = 'UserDeleteObjective',
  UserDeleteIndicator = 'UserDeleteIndicator',
  UserDeleteActivity = 'UserDeleteActivity',
  UserChangesOrderObjective = 'UserChangesOrderObjective',
  UserChangesOrderIndicator = 'UserChangesOrderIndicator',
  UserChangesOrderActivity = 'UserChangesOrderActivity',
  UserAddIndicatorValue = 'UserAddIndicatorValue',
  UserUpdatedIndicatorValue = 'UserUpdatedIndicatorValue',
  UserCompletesActivity = 'UserCompletesActivity',
  UserInvitesColleagueFromApp = 'UserInvitesColleagueFromApp',
  UserCreatesGrowthIndicator = 'UserCreatesGrowthIndicator',
  UserInvitesColleagueFromAdmin = 'UserInvitesColleagueFromAdmin',
  UserUpdatesGrowthIndicator = 'UserUpdatesGrowthIndicator',
  UserArchivesGrowthIndicator = 'UserArchivesGrowthIndicator',
  UserCreatesTeam = 'UserCreatesTeam',
  UserUpdatesTeam = 'UserUpdatesTeam',
  UserDeletesTeam = 'UserDeletesTeam',
  UserUpdatesVision = 'UserUpdatesVision',
  UserUpdatesSWOT = 'UserUpdatesSWOT',
  UserAddFocusArea = 'UserAddFocusArea',
  UserUpdatesFocusArea = 'UserUpdatesFocusArea',
  UserDeletesFocusArea = 'UserDeletesFocusArea',
  UserAddCoreValue = 'UserAddCoreValue',
  UserEditCoreValue = 'UserEditCoreValue',
  UserDeletesCoreValue = 'UserDeletesCoreValue',
  UserUpdatesTerminology = 'UserUpdatesTerminology',
  UserDeactivatesUser = 'UserDeactivatesUser',
  UserCelebratedEvent = 'UserCelebratedEvent',
  UserOpensCelebrationDialog = 'UserOpensCelebrationDialog',

  // NOT DONE
  UserUploadsProfilePhoto = 'UserUploadsProfilePhoto',
  CustomUserChangesUserRole = 'CustomUserChangesUserRole',
  UserUsesFilter = 'UserUsesFilter',
  UserUsesRoadmap = 'UserUsesRoadmap',
  UserOpensStrategyView = 'UserOpensStrategyView',
  UserOpensTeamView = 'UserOpensTeamView',
  UserOpensBusinessPlan = 'UserOpensBusinessPlan',
  UserOpensSuccessView = 'UserOpensSuccessView',
  UserPrioritizeActivity = 'UserPrioritizeActivity',
  UserUpdatesWeeklyFocus = 'UserUpdatesWeeklyFocus',
  UserUpdatesWeeklyRetrospective = 'UserUpdatesWeeklyRetrospective'
}

export default EventCategories
