import { useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Term from '../ygdrasil/enums/Term'
import { getTerm } from '../ygdrasil/libs/TermsHelper'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import Modal, { ModalProps } from './Modal'

type Props = { onDelete: () => Promise<any>; term: Term; name: string } & ModalProps
export function ModalAreYouSure(props: Props) {
  const { onDelete, term, name, onClose } = props
  const [loading, setLoading] = useState(false)

  const _onDelete = () =>
    Promise.resolve(setLoading(true))
      .then(() => onDelete())
      .finally(() => setLoading(false))

  return (
    <Modal {...props}>
      <Box fullWidth>
        <Box fullWidth bottom>
          <Box direction="row" align="center" bottom>
            <FigmaText
              textKey={Texts.dialogDeleteConfirmationDialog}
              text={formatText(getFigmaText(Texts.dialogDeleteConfirmationDialog), [name], { boldParams: true }) + '?'}
            />
          </Box>
          <FigmaText
            textKey={Texts.dialogDeleteConfirmationText}
            text={formatText(getFigmaText(Texts.dialogDeleteConfirmationText), [getTerm(term)], { boldParams: true })}
          />
        </Box>
        <Box direction="row" align="center" justify="flex-end" fullWidth>
          <Box right spacing={tinySpacing}>
            <Button textKey={Texts.cancelButtonText} buttonType="secondary" onClick={onClose} spacing={smallSpacing} />
          </Box>
          <Button
            textKey={Texts.deleteButtonText}
            buttonType="tertiary"
            onClick={_onDelete}
            spacing={smallSpacing}
            loading={loading}
          />
        </Box>
      </Box>
    </Modal>
  )
}
