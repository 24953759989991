import React from 'react'
import useAppState from '../hooks/useAppState'
import YFigmaText, { FigmaTextProps } from '../ygdrasil/components/FigmaText'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText, Options } from '../ygdrasil/libs/TextRepository'
import { getText } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'

type Props = FigmaTextProps & Options & { Component?: React.FC<FigmaTextProps> }

const FigmaTextForStrategyModel: React.FC<Props> = (props) => {
  const { Component = YFigmaText, ...rest } = props
  const text = useFigmaTextForStrategyModel(props)
  return <Component {...rest} text={text} />
}

export const useFigmaTextForStrategyModel = (props: Props) => {
  const opts = { boldParams: props.boldParams }
  const { state } = useAppState()
  const { data: org } = useOrganization(state)

  const text = props.text || getFigmaText(props.textKey)
  return getText(text, org, opts)
}

export default FigmaTextForStrategyModel
