import { GoogleAuthProvider, MicrosoftAuthProvider } from './SignInProviders'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { SignInMethod } from 'firebase/auth'

export const LABELS = {
  [GoogleAuthProvider.providerId]: {
    radioLabel: getFigmaText(Texts.onboardingConnectAccountGoogleText),
    buttonLabel: getFigmaText(Texts.onboardingVerifyGoogleButtonText)
  },
  [MicrosoftAuthProvider.providerId]: {
    radioLabel: getFigmaText(Texts.onboardingConnectAccountMicrosoftText),
    buttonLabel: getFigmaText(Texts.onboardingVerifyMicrosoftButtonText)
  },
  default: {
    radioLabel: getFigmaText(Texts.onboardingConnectAccountEmailText),
    buttonLabel: getFigmaText(Texts.onboardingVerifyEmailButtonText)
  }
}

export const LABELS_MOBILE: typeof LABELS = {
  [GoogleAuthProvider.providerId]: {
    radioLabel: getFigmaText(Texts.onboardingMobileConnectAccountGoogleText),
    buttonLabel: getFigmaText(Texts.onboardingVerifyEmailButtonText)
  },
  [MicrosoftAuthProvider.providerId]: {
    radioLabel: getFigmaText(Texts.onboardingMobileConnectAccountMicrosoftText),
    buttonLabel: getFigmaText(Texts.onboardingVerifyEmailButtonText)
  },
  default: {
    radioLabel: getFigmaText(Texts.onboardingMobileConnectAccountEmailText),
    buttonLabel: getFigmaText(Texts.onboardingVerifyEmailButtonText)
  }
}

export function getDefaultProviderId(signInMethods: string[]) {
  return signInMethods[0] || SignInMethod.EMAIL_LINK
}
