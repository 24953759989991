import * as Yup from 'yup'
import Texts from '../ygdrasil/libs/Texts'
import { EmailValidation, RequiredTextValidation } from './ValidationHelper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import { PreferredLoginMethod } from '../ygdrasil/types/SignInTypes'

export const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation(),
  email: EmailValidation('email', getFigmaText(Texts.onboardingEmailErrorNotValidEmail)),
  message: Yup.string()
})

export const inSelectedOrder = (s) => (a, b) => {
  if (s === a) return -1
  if (s === b) return 1
  return 0
}
export const PREFERRED_LOGIN_TYPES: PreferredLoginMethod[] = [
  PreferredLoginMethod.MAIL,
  ...Object.values(PreferredLoginMethod).filter(
    (pf) => ![PreferredLoginMethod.NONE, PreferredLoginMethod.MAIL].includes(pf)
  )
]
export const PREFERRED_LOGIN_TEXT_KEYS = {
  [PreferredLoginMethod.MICROSOFT]: Texts.adminApplicationSettingsPreferedLoginOptionMicrosoft,
  [PreferredLoginMethod.GOOGLE]: Texts.adminApplicationSettingsPreferedLoginOptionGoogle,
  default: Texts.adminApplicationSettingsPreferedLoginOptionEmail
}
