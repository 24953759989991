import styled from '@emotion/styled'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'

export const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

export const LinkStyles = `
  cursor: pointer;
  :hover {
    text-decoration: underline;
  };
`

export const NoWrapFigmaText = styled(FigmaText)`
  white-space: nowrap;
`

export const NoWrapFigmaTextForStrategyModel = styled(FigmaTextForStrategyModel)`
  white-space: nowrap;
`

export const Line = styled(Flexbox)<{ color: string }>`
  height: 1px;
  width: 100%;
  background-color: ${({ color }) => color};
`

export const GreyLine = styled(Flexbox)`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${Colors.primary90};
`

export const GreyLineMobile = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${Colors.silver};
`

export const getRoundImageStyles = (size: number) => `
  height: ${size}px;
  width: ${size}px;
  border-radius: ${size / 2}px;
`

export const RoundImage = styled.img<{ size: number }>`
  ${({ size }) => getRoundImageStyles(size)}
`

/**css App.css font-weight on body tag */
export const NormalFontWight = 'font-weight: 400;'
