import Modal, { ModalProps } from './Modal'
import { ModalAddProfilePictureView } from './ModalAddProfilePictureView'

type Props = ModalProps
export function ModalAddProfilePicture(props: Props) {
  return (
    <Modal {...props} transform="10%">
      <ModalAddProfilePictureView {...props} />
    </Modal>
  )
}
