import Menu from '@mui/material/Menu'
import { MenuItem } from './MenuItem'
import React from 'react'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { ModalAddProfilePicture } from './ModalAddProfilePicture'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
}

export default function SettingsPersonalPhotoMenu({ anchorEl, onClose }: Props) {
  const [showModal, setShowModal] = React.useState(false)
  const onClick = () => {
    onClose()
    setShowModal(true)
  }

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <MenuItem onClick={onClick}>{getFigmaText(Texts.userMenuUploadPersonalPhotoButtonText)}</MenuItem>
      </Menu>
      {showModal && <ModalAddProfilePicture onClose={() => setShowModal(false)} />}
    </div>
  )
}
