import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import { findKeyResultAndObjectiveForActivity } from '../ygdrasil/libs/KeyResultHelper'
import { findObjectiveForKeyResult } from '../ygdrasil/libs/ObjectiveHelper'
import { useCanEditIndicator, useCanEditObjective } from '../ygdrasil/libs/PermissionsHelper'
import { getRankForTableView } from '../ygdrasil/libs/RankHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { Activity, KeyResult, ObjectiveType } from '../ygdrasil/types/types'
import Box from './Box'
import { openEditActivityDrawer } from './DrawerGlobalEditActivityDialog'
import { openEditKeyResultDrawer } from './DrawerGlobalEditKeyResultDialog'
import { DEFAULT_ICON_SIZE } from './Icons'
import { StrategyViewObjectiveListAddLine } from './StrategyViewObjectiveListAddLine'

const ADD_LINE_ICON_SIZE = DEFAULT_ICON_SIZE

export function StrategyViewObjectiveListRowAddLine({ item, nextItem }: { item: Item; nextItem: Item | undefined }) {
  const ordo = getRankForTableView(item.data.data, nextItem?.data.data)
  const allItems = useAllItems()

  const onClickAddObjective = () =>
    eventEmitter.emit(Events.OPEN_EDIT_OBJETIVE_DIALOG, { objective: { ordo, type: ObjectiveType.TOP } })

  const onClickAddKeyResult = () => {
    const objective = findObjectiveForKeyResult(item.data.data as KeyResult, allItems)
    if (!objective) return
    openEditKeyResultDrawer({ keyResult: { ordo }, objective })
  }

  const onClickAddActivity = () => {
    const props = findKeyResultAndObjectiveForActivity(item.data.data as Activity, allItems)
    if (!props) return
    openEditActivityDrawer(props)
  }

  return (
    <Box fullWidth position="absolute" style={{ bottom: -ADD_LINE_ICON_SIZE / 2 }}>
      {useCanEditObjective() && item.data.searchType === 'objective' && (
        <StrategyViewObjectiveListAddLine onClick={onClickAddObjective} size={ADD_LINE_ICON_SIZE} />
      )}

      {useCanEditIndicator() && item.data.searchType === 'keyResult' && (
        <StrategyViewObjectiveListAddLine onClick={onClickAddKeyResult} size={ADD_LINE_ICON_SIZE} />
      )}

      {item.data.searchType === 'activity' && (
        <StrategyViewObjectiveListAddLine onClick={onClickAddActivity} size={ADD_LINE_ICON_SIZE} />
      )}
    </Box>
  )
}
