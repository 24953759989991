import { Popper } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import { OBJECTIVE_NAME_MAX_LENGTH } from '../libs/StrategyViewObjectiveTreeHelper'
import Tooltip from './Tooltip'

export default function WithTooltipMaxLength({
  title = '',
  titleElement,
  maxLength = OBJECTIVE_NAME_MAX_LENGTH,
  children,
  alwaysVisible
}: {
  title?: string
  titleElement?: React.ReactElement
  maxLength?: number
  children: any
  alwaysVisible?: boolean
}) {
  const shouldShowTooltip = alwaysVisible || title.length > maxLength
  return (
    <Tooltip
      title={title}
      titleElement={titleElement}
      open={!shouldShowTooltip ? false : undefined}
      placement="top"
      PopperComponent={StyledPopper}
    >
      <div>{children}</div>
    </Tooltip>
  )
}

const StyledPopper = styled(Popper)`
  .MuiTooltip-tooltip {
    background-color: ${Colors.primary10};
  }
`
