import styled from '@emotion/styled'
import MaterialModal from '@mui/material/Modal'
import React from 'react'
import { smallBorderRadius } from '../enums/BorderRadixes'
import shadows from '../enums/Shadows'
import { smallSpacing } from '../enums/Spacings'
import zIndices from '../enums/Zindices'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'

export type ModalProps = { onClose: (e?: unknown) => void; transform?: string }
type Props = {
  children: React.ReactNode
} & ModalProps

export default function Modal({ children, onClose, transform }: Props) {
  return (
    <StyledModal open={true} onClose={onClose}>
      <Container fullWidth transform={transform}>
        <Box fullWidth align="center">
          <StyledBox top bottom spacing={smallSpacing}>
            <Box left right>
              {children}
            </Box>
          </StyledBox>
        </Box>
      </Container>
    </StyledModal>
  )
}

const Container = styled(Box)<{ transform?: string }>`
  transform: ${({ transform = '50%' }) => `
    translate(0, ${transform});
    -webkit-transform: translate(0, ${transform});
    `};

  z-index: ${zIndices.heaven};
`

const StyledBox = styled(Box)`
  background: ${Colors.surface};
  border-radius: ${smallBorderRadius};
  box-shadow: ${shadows.heavy};
`

const StyledModal = styled(MaterialModal)`
  &&& {
    z-index: ${zIndices.modal}!important;
  }
`
