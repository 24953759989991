import { usePreviousProps } from '@mui/utils'
import React, { useEffect, useState } from 'react'
import useAppState, { State } from '../hooks/useAppState'
import { AllItems, useAllItems } from '../ygdrasil/hooks/useAllItems'
import { updateTreeForNewItems, updateTreeForNewState } from '../ygdrasil/libs/StrategyViewTreeRootHelper'
import { DEFAULT_TREE_DATA, getSearchResultForAllItems, TreeData } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { SearchState } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'

export default function useTreeItems({ searchState: _searchState }: { searchState?: Partial<SearchState> } = {}) {
  const { state: _state } = useAppState()
  const previousState = usePreviousProps(_state) as State
  // eslint-disable-next-line prettier/prettier
  const state: State = React.useMemo(() => ({ ..._state, searchState: { ..._state.searchState, ..._searchState } }), [_state, _searchState]) 
  const allItems = useAllItems()
  const { isLoading, isLoadingActivities, isLoadingIndicators } = allItems

  const [tree, setTree] = useState<TreeData>(DEFAULT_TREE_DATA)

  const rootItems = React.useMemo(() => {
    return tree.items[tree.rootId]?.children || []
  }, [tree])

  useEffect(() => {
    if (isLoading) return
    setTree(updateTreeForNewItems(getSearchResultForAllItems(allItems, state), tree))
  }, [allItems, isLoading])

  useEffect(() => {
    if (isLoading) return
    const searchResult = getSearchResultForState(previousState, state, allItems)
    searchResult && setTree(updateTreeForNewState(searchResult, tree))
  }, [state, isLoading])

  return { tree, setTree, rootItems, isLoading, isLoadingActivities, isLoadingIndicators }
}

export const getSearchResultForState = (prevState: State, state: State, allItems: AllItems) => {
  if (prevState.shouldShowExpandedMenu !== state.shouldShowExpandedMenu) return

  return getSearchResultForAllItems(allItems, state)
}
