import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { KeyResult } from '../ygdrasil/types/types'
import AddButtonLight from './AddButtonLight'
import Box from './Box'
import { openEditActivityDrawer } from './DrawerGlobalEditActivityDialog'
import { OnCreateEditActivity } from './EditActivityDialogForm'

export function ActivityBoardKanbanAddActivityButton({
  status,
  item,
  onCreateEditActivity,
  Component = AddButtonLight,
  visible,
  fullWidth,
  leadUserId
}: {
  item: Item
  status: ActivityStatus
  onCreateEditActivity?: OnCreateEditActivity
  Component?: React.ComponentType<{ onClick: () => void }>
  visible?: boolean
  fullWidth?: boolean
  leadUserId?: string
}) {
  const { searchType } = item.data
  const keyResult = item.data.data as KeyResult
  const onClick = () => openEditActivityDrawer({ keyResult, onCreateEditActivity, activity: { status, leadUserId } })

  visible = visible && searchType === 'keyResult' // TODO WRITE TEST, should show create activity button only when searchType is keyResult
  return (
    <Box visibility={visible ? 'visible' : 'hidden'} fullWidth={fullWidth}>
      <Component onClick={onClick} />
    </Box>
  )
}
