import { useMemo } from 'react'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import { getSearchResultForAllItems } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { SearchState } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import useAppState from './useAppState'

export function useSearchResultForTree(_searchState?: Partial<SearchState>) {
  const { state } = useAppState()
  const allItems = useAllItems()
  return useMemo(() => {
    const searchState = { ...state.searchState, ..._searchState }

    return getSearchResultForAllItems(allItems, {
      ...state,
      searchState
    })
  }, [state, allItems, _searchState])
}
