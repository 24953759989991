import invariant from 'invariant'
import config from '../../Config'
import { State } from '../../hooks/useAppState'
import { isOurBigPictureUser } from '../hooks/useLoggedInConfig'
import * as SlackHelper from '../libs/SlackHelper'
import { OrgUserStatus, User } from '../types/types'
import { createAuthenticationTokenForInternalUsers } from './CloudFunctionsApiHandler'
import { signInWithCustomToken } from './FirebaseHelper'

export function getSelectedOrganizationId(user: User, state: State) {
  let selectedOrganizationId = user.organizationIds[0]

  if (user.organizationIds.includes(state.selectedOrganizationId))
    selectedOrganizationId = state.selectedOrganizationId || user.organizationIds[0]

  invariant(selectedOrganizationId, `Cant find user.organizationIds[0] for user %s`, user._id)
  return selectedOrganizationId
}

export function shouldShowDisabledView(user: User) {
  return user.organizationIds.every((orgId) => user.orgUserInfo?.[orgId]?.status === OrgUserStatus.DISABLED)
}

export function onLoggedIn(
  { user, email }: { user?: User; email?: string },
  _config: typeof config,
  { logLogin }: { logLogin: typeof SlackHelper.logLogin }
) {
  if (!user) return
  if (isOurBigPictureUser({ email } as User)) return
  if (!_config.enableLogLogins) return
  logLogin(user)
}

export const signInAsInternalUser = (uid: string, state: State) => {
  invariant(uid, 'uid is required, check your env varianbes (LOG_IN_AS_INTERNAL_USER_ID)')
  return createAuthenticationTokenForInternalUsers({ uid }, state).then((customAuthToken) =>
    signInWithCustomToken(customAuthToken.token)
  )
}
