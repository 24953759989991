import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SignInSteps } from '../ygdrasil/types/SignInTypes'
import useAppState from '../hooks/useAppState'
import { useLogout } from '../hooks/useLogout'

export default function SignUpPage() {
  const { state } = useAppState()
  const logout = useLogout()
  const history = useHistory()
  useEffect(() => {
    // TODO WRITE TEST PAIN, if you are logged in sign out of page when you access sign up page and redirect
    if (!!state.user._id) logout()
    history.replace(`/?step=${SignInSteps.SIGN_UP}`)
  }, [])

  return null
}
