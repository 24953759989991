import styled from '@emotion/styled'
import MaterialTextField, { TextFieldProps } from '@mui/material/TextField'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import Colors from '../ygdrasil/libs/Colors'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'

export type TextFieldOutlinedProps = {
  labelTextKey?: AntiloopTextType
  placeholderTextKey?: AntiloopTextType
  pointer?: boolean
  className?: string
  Component?: React.FC<TextFieldProps>
} & TextFieldProps

export const TextField = (props: TextFieldOutlinedProps) => {
  const { labelTextKey, placeholderTextKey, Component = BaseTextField, ...rest } = props

  return (
    <Component
      variant="outlined"
      size="small"
      label={labelTextKey ? getFigmaText(labelTextKey) : ''}
      placeholder={placeholderTextKey ? getFigmaText(placeholderTextKey) : undefined}
      InputLabelProps={{ shrink: false }}
      {...rest}
    />
  )
}

export const TextFieldOutlined = TextField

// Is duplicated in see TextFieldOutlinedNumberInputStyles
export const BaseTextFieldStyles = `
  & .MuiOutlinedInput-notchedOutline {
    border-radius: ${tinyBorderRadius};
    border: 1px solid ${Colors.neutralVariant80};
  }

  & legend {
    display: none;
  }

  & fieldset {
    top: 0;
  }

  background-color: white;
  cursor: pointer;
`
// Is duplicated in see BaseTextFieldStyles
export const TextFieldOutlinedNumberInputStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: tinyBorderRadius,
    border: `1px solid ${Colors.neutralVariant80}`
  },
  '& legend': {
    display: 'none'
  },

  '& fieldset': {
    top: 0
  },

  backgroundColor: 'white',
  cursor: 'pointer'
}

export const BaseTextField = styled(MaterialTextField, {
  shouldForwardProp: () => true
})`
  ${BaseTextFieldStyles}
`
