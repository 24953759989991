import React from 'react'
import styled from '@emotion/styled'
import { bigBorderRadius } from '../enums/BorderRadixes'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaText from '../ygdrasil/components/FigmaText'
import SettingsTabs from './SettingsTabs'
import UserWidget from './UserWidget'

export default function Settings() {
  return (
    <Box fullWidth direction="row" fullPadding>
      <Container fullWidth fullPadding>
        <Box bottom>
          <FigmaText textKey={Texts.adminUserListHeader} />
        </Box>
        <Box>
          <SettingsTabs />
        </Box>
      </Container>
      <Box left>
        <UserWidget />
      </Box>
    </Box>
  )
}

const Container = styled(Box)`
  background: ${Colors.whiteBg};
  border-radius: ${bigBorderRadius};
`
