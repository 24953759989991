import { AntiloopTextType } from '../types/Antiloop'
import { Organization } from '../types/types'
import { capitalize } from './Common'
import { getTerm, TermReplaceKey as _TermReplaceKey, TermReplaceKeyType } from './TermsHelper'
import { getFigmaText, makeBold, Options } from './TextRepository'

// add capitalized Term Replace Keys
export const TermReplaceKey: TermReplaceKeyType = Object.keys(_TermReplaceKey).reduce(
  (acc, key) => ({
    ...acc,
    [`$${capitalize(key.replace('$', ''))}`]: { ..._TermReplaceKey[key], isCapitalized: true }
  }),
  _TermReplaceKey
)

export const getTextForTextKey = (textKey: AntiloopTextType, org: Organization | undefined, opts?: Options): string =>
  getText(getFigmaText(textKey), org, opts)

export function getText(text: string, org?: Organization, { boldParams }: Options = {}): string {
  return Object.keys(TermReplaceKey).reduce((a, replaceKey) => {
    const termReplaceKey = TermReplaceKey[replaceKey]
    if (!termReplaceKey) return a
    const { term, termsItemKey, isCapitalized } = termReplaceKey
    let newTerm: string = getTerm(term, org, { termsItemKey }) || ''
    newTerm = isCapitalized ? capitalize(newTerm) : newTerm.toLowerCase()
    if (boldParams) newTerm = makeBold(newTerm)
    return a.replaceAll(replaceKey, newTerm)
  }, text as string)
}
