import FigmaText from '.././ygdrasil/components/FigmaText'
import Images from '.././ygdrasil/libs/Images'
import Texts from '.././ygdrasil/libs/Texts'
import FigmaTextWithLinebreaks from '../ygdrasil/components/FigmaTextWithLinebreaks'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export function SignInCodeFooter() {
  return (
    <Box top direction="row">
      <Box right>
        <FigmaImageContainer imageKey={Images.threeHeads} />
      </Box>
      <Box>
        <Box>
          <FigmaText textKey={Texts.emailTempalteSupportContactHeader} />
        </Box>
        <Box>
          <FigmaText textKey={Texts.emailTemplateSupportContactTitle} />
        </Box>
        <Box>
          <FigmaTextWithLinebreaks textKey={Texts.emailTemplateSupportContact} separator={<span />} />
        </Box>
      </Box>
    </Box>
  )
}
