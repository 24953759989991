import React from 'react'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { Team } from '../ygdrasil/types/types'
import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useVisibleObjectives from '../ygdrasil/hooks/useVisibleObjectives'
import { updateObjective } from '../ygdrasil/libs/DBApiHandler'
import { DELETE_OBJECTIVE_MODAL_MAX_WIDTH } from '../libs/HardCodedSizes'
import Box from './Box'
import Button from './Button'
import Modal, { ModalProps } from './Modal'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { removeTeam } from '../ygdrasil/libs/ProcessHandler'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'

type Props = { team: Team; onTeamRemoved: () => void } & ModalProps
export function ModalDeleteTeam(props: Props) {
  const { team, onClose, onTeamRemoved } = props
  const { state, setState } = useAppState()
  const { data: allObjectives = [] } = useVisibleObjectives()
  const { style } = Texts.dialogDeleteTeamHeader
  const [isLoading, setIsLoading] = React.useState(false)
  const logEvent = useLogEvent()

  const onClickDelete = () =>
    Promise.resolve(setIsLoading(true))
      .then(() =>
        allObjectives
          .filter(({ teamIds }) => teamIds.includes(team._id))
          .map((o) => updateObjective({ ...o, teamIds: o.teamIds.filter((id) => id !== team._id) }))
      )
      .then(() => removeTeam(team, logEvent))
      .then(() => setState({ ...state, selectedTeamId: null }))
      .then(() => onTeamRemoved && onTeamRemoved())
      .finally(() => {
        onClose()
        setIsLoading(false)
      })

  const onClickCancel = onClose
  return (
    <Modal {...props}>
      <Box fullWidth style={{ maxWidth: DELETE_OBJECTIVE_MODAL_MAX_WIDTH }}>
        <Box fullWidth bottom>
          <Box direction="row" align="center" bottom>
            <span style={style}>
              {'Delete '}
              <b>{team.name}</b>
              {'?'}
            </span>
          </Box>
          <FigmaTextForStrategyModel textKey={Texts.dialogDeleteTeamConfirmationText} />
        </Box>
        <Box direction="row" align="center" justify="flex-end" fullWidth>
          <Button
            onClick={onClickDelete}
            textKey={Texts.deleteButtonText}
            buttonType="tertiary"
            spacing={tinySpacing}
            loading={isLoading}
          />
          <Button
            onClick={onClickCancel}
            textKey={Texts.cancelButtonText}
            buttonType="secondary"
            spacing={tinySpacing}
          />
        </Box>
      </Box>
    </Modal>
  )
}
