import { NO_OF_LETTERS_IN_SIGN_IN_CODE } from '../ygdrasil/libs/Consts'
import { SignInViewProps } from '../ygdrasil/types/SignInTypes'

export const onChangeText = (
  code: string,
  setCode: (code: string) => unknown,
  { onPressContinue, signInState }: SignInViewProps
) => {
  setCode(code)

  if (code.length === NO_OF_LETTERS_IN_SIGN_IN_CODE)
    onPressContinue({
      ...signInState,
      data: { ...signInState.data, code }
    })
}
