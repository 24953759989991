import useAppState, { DEFAULT_STATE } from '../hooks/useAppState'
import { useUserOptionsObject } from '../hooks/useUserOptionsObject'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import AutoComplete from './AutoComplete'
import { useFigmaTextForStrategyModel } from './FigmaTextForStrategyModel'

const ARCHIVED = getFigmaText(Texts.archieveButtonText)
const DEBOUNCE_TIMEOUT = 500

export const StrategyViewObjectiveListChipInput = () => {
  const { state, setSearchState } = useAppState()
  const TEAM_OBJECTIVES = useFigmaTextForStrategyModel({
    textKey: Texts.rightPanelObjectiveEditObjectiveTypeOptionTeam
  })
  const { searchState } = state
  const { showArchivedObjectives, showTeamObjectivesWhenNoTeamIsSelected: showTeamObjectives, searchText } = searchState
  const chipValue: string[] = []

  if (showArchivedObjectives) chipValue.push(ARCHIVED)
  if (showTeamObjectives) chipValue.push(TEAM_OBJECTIVES)

  const onUpdateInput = ({ target: { value: searchText } }) => setSearchState({ ...searchState, searchText })

  const onDelete = (value) => {
    if (value === ARCHIVED) return setSearchState({ ...searchState, showArchivedObjectives: false })
    if (value === TEAM_OBJECTIVES)
      return setSearchState({ ...searchState, showTeamObjectivesWhenNoTeamIsSelected: false })

    setSearchState({ ...searchState, searchText: '' })
  }

  return (
    <AutoComplete
      value={chipValue}
      onUpdateInput={onUpdateInput}
      onDelete={onDelete}
      inputValue={searchText}
      debounce={DEBOUNCE_TIMEOUT}
    />
  )
}
