import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import enLocale from 'date-fns/locale/en-US'
import svLocale from 'date-fns/locale/sv'
import dayjs from 'dayjs'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { useLanguage } from '../ygdrasil/libs/useLanguage'
import { DatePickerWithFormikProps } from './DatePickerWithLabelFormik'
import { GenericErrorComponent, getError } from './TextFieldFormik'
import { TextFieldOutlined } from './TextFieldOutlined'

const localeMap = {
  en: enLocale,
  sv: svLocale
}

const MONDAY = 1

export default function DatePicker({ textfieldFormikProps, minDate, maxDate }: DatePickerWithFormikProps) {
  const { formProps, name } = textfieldFormikProps
  const language = useLanguage()
  const error = getError(formProps, name)

  // const locale = localeMap[language || 'en']
  const locale = localeMap.sv // To change when we go international!
  locale.options = { ...locale.options, weekStartsOn: MONDAY }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} locale={locale}>
      <MaterialDatePicker
        value={dayjs(formProps.values[name]).toDate()}
        minDate={dayjs(minDate).toDate()}
        maxDate={dayjs(maxDate).toDate()}
        onChange={(date) => formProps.setFieldValue(name, dayjs(date).format(YYYY_MM_DD))}
        renderInput={(params) => (
          <TextFieldOutlined
            {...params}
            onChange={textfieldFormikProps.onChange || formProps.handleChange}
            Component={TextField}
            fullWidth
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              backgroundColor: 'white',
              borderRadius: tinyBorderRadius
            }}
          />
        )}
        disableMaskedInput={true}
      />
      {error && <GenericErrorComponent formProps={formProps} name={name} />}
    </LocalizationProvider>
  )
}
