/* eslint-disable prefer-const */
import invariant from 'invariant'

// trigger

type Split = { text: string; isYellow?: boolean }
export const getSplits = (_text: string, searchText = ''): Split[] => {
  const newSplit = { text: '' }
  const _searchText = searchText.toUpperCase()
  const characters = _text.split('')

  const res = characters.reduce(
    (a, character) => {
      let { tempText } = a

      const newTempText = tempText + character
      const _newTempText = newTempText.toUpperCase()

      // found search text
      if (_newTempText === _searchText) {
        a.tempText = ''
        a.splits.push({ text: newTempText, isYellow: true })
        a.splits.push(newSplit)
        return a
      }

      // possibly found future searchText
      if (_searchText.startsWith(_newTempText)) {
        a.tempText += character
        return a
      }

      // not found searchtext
      const currentSplit = { ...a.splits.pop() } as Split
      invariant(currentSplit, '')
      currentSplit.text += a.tempText + character
      a.tempText = ''
      a.splits.push(currentSplit)
      return a
    },
    { splits: [{ ...newSplit }], tempText: '' } as { splits: Split[]; tempText: string }
  ).splits

  if (res.length > 0 && !res[res.length - 1].text) res.pop()
  if (res.length > 0 && !res[0].text) res.shift()

  return res
}
