import useAppState from '../../hooks/useAppState'
import { AllItems, useAllItems } from './useAllItems'

export const useAllVisibleItems = (): AllItems => {
  const allItems = useAllItems()

  const { state } = useAppState()
  const { searchState } = state
  if (!searchState.showArchivedObjectives) allItems.allObjectives = allItems.allObjectives.filter((o) => !o.isArchived)
  return allItems
}
