import Divider from '@mui/material/Divider'
import Menu, { MenuProps } from '@mui/material/Menu'
import React from 'react'
import useAppState from '../hooks/useAppState'
import { archiveObjective } from '../libs/ClientProcessHandler'
import { withStopProgagation } from '../libs/HtmlHelper'
import {
  createOnClickAddAlignedObjective,
  shouldEnableReordering
} from '../libs/StrategyViewObjectiveListObjectiveMenuHelper'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import useVisibleObjectives from '../ygdrasil/hooks/useVisibleObjectives'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { logEvent } from '../ygdrasil/libs/FirebaseHelper'
import { getAllChildObjectives } from '../ygdrasil/libs/ObjectiveHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Objective } from '../ygdrasil/types/types'
import { openEditKeyResultDrawer } from './DrawerGlobalEditKeyResultDialog'
import { openEditObjectiveDrawer } from './DrawerGlobalEditObjectiveDialog'
import { openReorderObjectiveDrawer } from './DrawerGlobalReorderObjectives'
import { MenuItem } from './MenuItem'
import { ModalArchiveObjective } from './ModalArchiveObjective'
import StrategyViewObjectiveListObjectiveMenuForArchivedObjective from './StrategyViewObjectiveListObjectiveMenuForArchivedObjective'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  objective: Objective
} & Partial<MenuProps>

export default function StrategyViewObjectiveListObjectiveMenu(props: Props) {
  const { state, setState } = useAppState()

  const { data: org } = useOrganization(state)
  const { objective, anchorEl, onClose } = props
  const [showModal, setShowModal] = React.useState(false)
  const { data: allObjectives = [] } = useVisibleObjectives()

  const onClickEditObjective = (e) => {
    e.stopPropagation() // Without this line onClick method on FocusedObjective is fired => bad
    onClose()
    logEvent(AnalyticsEvent.EDIT_OBJECTIVE_MENU, state)

    openEditObjectiveDrawer({ objective })
  }

  const onClickAddKeyResult = (e) => {
    e.stopPropagation() // Without this line onClick method on FocusedObjective is fired => bad
    onClose()
    logEvent(AnalyticsEvent.OBJECTIVELIST_ADDINDICATOR, state)

    openEditKeyResultDrawer({ objective })
  }

  const onClickArchiveObjective = (e) => {
    e.stopPropagation() // Without this line onClick method on FocusedObjective is fired => bad
    onClose()

    if (getAllChildObjectives(objective, allObjectives).length === 0) return archiveObjective(objective, allObjectives)
    else setShowModal(true)
  }

  const onClickReorderObjectives = (e) => {
    e.stopPropagation() // Without this line onClick method on FocusedObjective is fired => bad
    onClose()

    openReorderObjectiveDrawer({ objective })
  }

  const onClickAddAlignedObjective = withStopProgagation(
    createOnClickAddAlignedObjective(objective, { onClose, openEditObjectiveDrawer })
  )

  const onCloseArchiveObjectiveModal = (e) => {
    e.stopPropagation() // Without this line onClick method on FocusedObjective is fired => bad
    setShowModal(false)
  }

  const onClickChangeColor = (e) => {
    e.stopPropagation() // Without this line onClick method on FocusedObjective is fired => bad
    onClose()
    setState({ ...state, focusedObjectiveId: objective._id })
  }

  if (objective.isArchived) return <StrategyViewObjectiveListObjectiveMenuForArchivedObjective {...props} />

  const _onClose = withStopProgagation(onClose)

  return (
    <div>
      <Menu open={Boolean(anchorEl)} {...props} onClose={_onClose}>
        <MenuItem
          onClick={onClickEditObjective}
          text={getTextForTextKey(Texts.objectiveMenuEditObjectiveButtonText, org)}
        ></MenuItem>
        <MenuItem
          onClick={onClickAddAlignedObjective}
          text={getTextForTextKey(Texts.objectliveMenuAddAlignedObjetiveButtonText, org)}
        ></MenuItem>
        <MenuItem onClick={onClickAddKeyResult} text={getTextForTextKey(Texts.addIndicatorButtonText, org)}></MenuItem>
        {shouldEnableReordering(state) && (
          <MenuItem
            onClick={onClickReorderObjectives}
            text={getTextForTextKey(Texts.objectiveMenuReorderButtonText, org)}
          ></MenuItem>
        )}
        <MenuItem onClick={onClickChangeColor} text={getFigmaText(Texts.objectiveMenuChangeColorButtonText)}></MenuItem>
        <Divider />
        <MenuItem onClick={onClickArchiveObjective} text={getTextForTextKey(Texts.archieveButtonText, org)}></MenuItem>
      </Menu>
      {showModal && <ModalArchiveObjective onClose={onCloseArchiveObjectiveModal} objective={objective} />}
    </div>
  )
}
