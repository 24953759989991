import { capitalize } from '@mui/material'
import { getDomain, getEmailForFirebaseUser } from '../ygdrasil/libs/Common'
import { COMMON_EMAIL_DOMAINS } from '../ygdrasil/libs/Consts'
import { OnboardingState } from '../ygdrasil/types/Onboarding'

export function getOnboardingTellUsInitialValues(state: OnboardingState) {
  const { firebaseUser, data } = state
  const email = getEmailForFirebaseUser(firebaseUser)
  const isCommonEmailDomain = email ? COMMON_EMAIL_DOMAINS.includes(getDomain(email)) : false
  const defaultName = isCommonEmailDomain ? '' : email ? capitalize(getDomain(email)) : ''
  const initialValues = {
    name: data.name || defaultName,
    vision: data.vision || '',
    countryCode: '',
    howManyCollegues: ''
  }
  return initialValues
}
