import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import { GRAPH_ACTIVITY_LIST_CARD_MIN_WIDRH } from '../libs/HardCodedSizes'
import { getTextWithDots } from '../libs/StrategyViewObjectiveTreeHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { ACTIVITY_CARD_TEXT_MAX_LENGTH, isOverDue } from '../ygdrasil/libs/ActivityHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import { Activity } from '../ygdrasil/types/types'
import { Container as InnerContainer } from './ActivityBoardKanbanCardActivityDone'
import { ActivityBoardKanbanCardDueDate } from './ActivityBoardKanbanCardDueDate'
import { ActivityStatusBadge } from './ActivityStatusBadge'
import Box from './Box'
import { StrategyViewObjectiveListRowLead } from './StrategyViewObjectiveListRowLead'
import WithTooltipMaxLength from './WithTooltipMaxLength'

type Props = {
  item: Item
}

export function GraphActivityListColumnItem({ item }: Props) {
  const activity = item.data.data as Activity

  const hasUrl = !!activity.url
  const onClick = () => hasUrl && window.open(activity.url, '_blank')

  return (
    <Container>
      <InnerContainer isOverDue={isOverDue(activity)} flagged={activity.flagged}>
        <Box link={hasUrl} onClick={onClick} fullWidth>
          <WithTooltipMaxLength title={activity.name} maxLength={ACTIVITY_CARD_TEXT_MAX_LENGTH}>
            <FigmaText
              textKey={Texts.cssActivityCardSummaryText}
              text={getTextWithDots(activity.name, ACTIVITY_CARD_TEXT_MAX_LENGTH)}
            />
          </WithTooltipMaxLength>
        </Box>
        <Box direction="row" justify="space-between" align="center" fullWidth top spacing={smallSpacing}>
          <ActivityStatusBadge activity={activity} />
          <ActivityBoardKanbanCardDueDate activity={activity} />
          <StrategyViewObjectiveListRowLead item={item} />
        </Box>
      </InnerContainer>
    </Container>
  )
}

const Container = styled(Box)`
  min-width: ${GRAPH_ACTIVITY_LIST_CARD_MIN_WIDRH}px;
`
