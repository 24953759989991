import React from 'react'
import { Unit } from '../ygdrasil/types/types'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import { minBorderRadius } from '../enums/BorderRadixes'
import { RadioContainer } from './Radio'
import FigmaText from '../ygdrasil/components/FigmaText'
import { tinySpacing } from '../enums/Spacings'
import { UNIT_BUTTON_WIDTH } from '../libs/HardCodedSizes'
import { Props } from './EditKeyResultsSectionUnitButtons'

export const EditKeyResultsSectionUnitButton = (props: { unit: Unit } & Props) => {
  const { keyResult, unit } = props
  const isSelected = unit === keyResult.unit
  const textKey = isSelected ? Texts.metricButtonLabelSelected : Texts.metricButtonLabel
  const radioColor = isSelected ? Colors.white : undefined

  const onChange = () => props.onSelectUnit(unit)

  return (
    <Container align="center" alignText="center" isSelected={isSelected} fullPadding spacing={tinySpacing}>
      <RadioContainer radioColor={radioColor} checked={isSelected} onChange={onChange} />
      <Box fullWidth alignText="center" style={{ marginTop: 0 }}>
        <FigmaText textKey={textKey} text={getFigmaText(getTextKeyForUnit(unit))} />
      </Box>
    </Container>
  )
}
const Container = styled(Box)<{ isSelected: boolean }>`
  background: ${({ isSelected }) => (isSelected ? Colors.primary40 : Colors.white)};
  border: 1px solid ${Colors.primary90};
  border-radius: ${minBorderRadius};
  width: ${UNIT_BUTTON_WIDTH}px;
  height: ${(UNIT_BUTTON_WIDTH * 3) / 4}px;
`

const getTextKeyForUnit = (unit: Unit) =>
  ({
    [Unit.MONEY]: Texts.rightPanelIndicatorTypeCurrencyLabel,
    [Unit.PERCENT]: Texts.rightPanelIndicatorTypePercentageLabel,
    [Unit.NUMERICAL]: Texts.rightPanelIndicatorTypeNumericalLabel,
    [Unit.BOOLEAN]: Texts.rightPanelIndicatorTypeBooleanLabel,
    [Unit.INTEGRATION]: Texts.rightPanelIndicatorTypeIntegrationLabel,
    [Unit.GLOBAL]: Texts.rightPanelIndicatorTypeGlobalLabel
  }[unit])
