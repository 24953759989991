import invariant from 'invariant'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { ActivityBoardColumns, getActivityBoardColumns, getActivityItems, onDragEnd } from '../libs/ActivityBoardHelper'
import { updateActivity } from '../ygdrasil/libs/DBApiHandler'
import { Activity } from '../ygdrasil/types/types'
import { StrategyViewObjectiveListRowDetailProps } from './StrategyViewObjectiveListRowDetailHelper'
import dayjs from 'dayjs'
import { DEFAULT_STATE } from '../hooks/useAppState'
import { shouldShowEmptyState } from '../libs/ActivityBoardHelper'
import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import { asArray, asObject } from '../ygdrasil/libs/Common'
import { mapTreeItem, SearchObject, SearchType } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { ActivityBoardKanbanColumn } from './ActivityBoardKanbanColumn'
import { ONE_REM_IN_PX } from '../enums/Spacings'
import Box from './Box'
import { ActivityBoardKanbanEmptyState } from './ActivityBoardKanbanEmptyState'

export type ActivityBoardKanbanProps = StrategyViewObjectiveListRowDetailProps & {
  Container?: React.FC
  ColumnContainer?: React.FC<{ isMiddleColumn?: boolean }>
  enableColumnHeader?: boolean
  enableEmptyState?: boolean
  enableAddActivityForCurrentUser?: boolean
  enableFilterCompletedActivities?: boolean // Could be removed
  leadUserId?: string
}

const ActivityBoardKanban = (props: ActivityBoardKanbanProps) => {
  const { item, parentItem } = props

  const [columns, setColumns] = useState(ActivityBoardColumns)

  const _shouldShowEmptyState = shouldShowEmptyState(item, columns)

  const todoLength = item.children.filter((c) => c.data.activityStatus === ActivityStatus.TODO).length
  const inProgressLength = item.children.filter((c) => c.data.activityStatus === ActivityStatus.IN_PROGRESS).length
  const doneLength = item.children.filter((c) => c.data.activityStatus === ActivityStatus.DONE).length

  useEffect(() => {
    const activities = getActivityItems(props)
    setColumns(getActivityBoardColumns(activities))
  }, [
    // should reevaluate kanban cards when key result changes
    item.data.id,
    // should reevaluate kanban cards when activity status changes (loaaded seperately from indicators), and when activities are added/removed
    todoLength,
    inProgressLength,
    doneLength,
    //  should filter based on leadUserId
    props.leadUserId
  ])

  const onCreateEditActivity = (a: Activity) => {
    const rootObjectiveId =
      parentItem?.data.parentId || parentItem?.data.id || props.item.data.parentId || props.item.data.id
    const searchObject = {
      id: a._id,
      strategicPillarIds: [] as any,
      rootObjectiveId,
      parentId: props.item.id,
      searchType: 'activity' as SearchType,
      data: a,
      name: a.name,
      leadUserId: a.leadUserId,
      leadUserIdIncludingParent: a.leadUserId,
      activityStatus: a.status,
      ordo: a.ordo,
      createdAt: a.createdAt,
      allChildren: [] as any,
      children: [] as any,
      teamIds: [] as any,
      level: props.item.data.level + 1,
      description: undefined,
      allParents: [] as any
    } as SearchObject
    const item = mapTreeItem(searchObject, DEFAULT_STATE)
    const activities = getActivityItems(props)
    const activityItems = asArray(asObject([...activities, item], 'id'))
    setColumns(getActivityBoardColumns(activityItems))
  }

  const onDeleteActivity = (a: Activity) => {
    const activityItems = getActivityItems(props)
    setColumns(getActivityBoardColumns(activityItems.filter(({ id }) => a._id !== id)))
  }

  if (_shouldShowEmptyState) return <ActivityBoardKanbanEmptyState />

  return (
    <DragDropContext
      onDragEnd={(result) =>
        onDragEnd(result, columns, {
          setColumns,
          onDragEnd: ({ result, ordo }) => {
            const item = columns[result.source.droppableId].items[result.source.index]
            invariant(result.destination, 'Destination is not defined')
            const status = result.destination.droppableId as ActivityStatus
            const activity = item.data.data as Activity
            const _activity = { ...activity, status, ordo, lastStatusChangeAt: dayjs().format() }
            updateActivity(_activity)
            setTimeout(() => onCreateEditActivity(_activity))
          }
        })
      }
    >
      <Box fullWidth direction="row" gap={`${ONE_REM_IN_PX}px`}>
        {Object.entries(columns).map(([activityStatus, column]) => {
          return (
            <Droppable key={activityStatus} droppableId={activityStatus}>
              {(provided, snapshot) => (
                <ActivityBoardKanbanColumn
                  provided={provided}
                  snapshot={snapshot}
                  activityStatus={activityStatus}
                  props={props}
                  column={column}
                  onCreateEditActivity={onCreateEditActivity}
                  item={item}
                  onDeleteActivity={onDeleteActivity}
                />
              )}
            </Droppable>
          )
        })}
      </Box>
    </DragDropContext>
  )
}

export default ActivityBoardKanban
