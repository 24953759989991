import { Formik } from 'formik'
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import Colors from '../ygdrasil/libs/Colors'
import FigmaText from '../ygdrasil/components/FigmaText'
import { ENTER_KEYCODE } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import { Activity, Team } from '../ygdrasil/types/types'
import { bigSpacing, minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH } from '../libs/HardCodedSizes'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { createTeam, updateTeam } from '../ygdrasil/libs/ProcessHandler'

type Props = {
  onClose: () => void
  team?: Team
  onClickDeleteTeam?: () => unknown
  onCreatedTeam?: (team: Team) => unknown
}

const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation()
})

export default function ModalCreateEditTeamForm({
  onClose,
  team,
  onClickDeleteTeam: _onClickDeleteTeam,
  onCreatedTeam
}: Props) {
  const { state, setState } = useAppState()
  const logEvent = useLogEvent()
  if (!onCreatedTeam) onCreatedTeam = ({ _id }) => setState({ ...state, selectedTeamId: _id })
  const onSubmit = ({ name }) => {
    if (team) updateTeam({ ...team, name }, logEvent)
    else {
      createTeam({ name, organizationId: state.selectedOrganizationId }, state, logEvent).then(onCreatedTeam)
    }
    onClose()
  }

  const initialValues = {
    name: team?.name || ''
  }

  const headingTextKey = !!team ? Texts.editTeamHeader : Texts.createTeamHeader

  const onClickDeleteTeam = () => {
    _onClickDeleteTeam && _onClickDeleteTeam()
    onClose()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
      {(formProps) => (
        <Container fullWidth align="center">
          <Box fullWidth spacing={bigSpacing}>
            <Box bottom>
              <FigmaText textKey={headingTextKey} />
            </Box>
            <Box fullWidth bottom spacing={smallSpacing}>
              <TextFieldWithLabelFormik
                labelTextKey={Texts.editTeamNameLabel}
                formProps={formProps}
                name="name"
                component={TextFieldOutlined}
                onKeyDown={(e) => ENTER_KEYCODE === e.keyCode && formProps.handleSubmit(e)}
                fullWidth
              />
            </Box>
            <Box direction="row" justify="space-between" align="center" fullWidth top>
              <Box visibility={!!team ? 'visible' : 'hidden'}>
                <Button
                  textKey={Texts.deleteButtonText}
                  buttonType="tertiary"
                  onClick={onClickDeleteTeam}
                  spacing={minSpacing}
                />
              </Box>
              <Box direction="row" align="center">
                <Button
                  textKey={Texts.cancelButtonText}
                  buttonType="secondary"
                  onClick={onClose}
                  spacing={smallSpacing}
                />
                <StyledButton onClick={formProps.handleSubmit as any} spacing={tinySpacing}>
                  <FigmaTextLoader textKey={Texts.saveButtonText} loading={formProps.isSubmitting} />
                </StyledButton>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </Formik>
  )
}

const Container = styled(Box)`
  min-width: ${EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH};
`

const StyledButton = styled(Button)`
  background-color: ${Colors.primaryBase};
  &:hover {
    background-color: ${Colors.primary30};
  }
`
