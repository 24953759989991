import { SignInMethod } from 'firebase/auth'
import invariant from 'invariant'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import { State } from '../hooks/useAppState'

import { getProviderForProviderId } from './SignInHelper'
import { toBaseObject } from '../ygdrasil/libs/Mapper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { SignInState, SignInSteps } from '../ygdrasil/types/SignInTypes'
import { ServicesType } from './SignInMachineHelper'
import CloudFunctionError from '../ygdrasil/libs/CloudFunctionError'
import { ServerErrorCode } from '../ygdrasil/types/ServerError'

export const onPressContinue = (
  signInState: SignInState,
  { isMobile }: { isMobile: boolean },
  state: State,
  s: ServicesType
): Promise<SignInState> =>
  Promise.resolve().then(() => {
    switch (signInState.step) {
      case SignInSteps.LANDING:
        return startSignInWithCode(signInState.data.email, signInState, state, s)
      case SignInSteps.DISABLED:
        return { ...signInState, step: SignInSteps.LANDING }
      case SignInSteps.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIALS: {
        const { pendingProviderId, pendingUser } = signInState.data
        const email = pendingUser?.email
        invariant(email, '!email')
        invariant(pendingProviderId, '!pendingProviderId')

        if (pendingProviderId === SignInMethod.EMAIL_LINK) return startSignInWithCode(email, signInState, state, s)

        const provider = getProviderForProviderId(pendingProviderId)
        invariant(provider, '!provider, pendingProviderId: %s', pendingProviderId)
        return s.signInWithPopup(provider).then(() => signInState)
      }

      case SignInSteps.SIGN_UP:
        return Promise.resolve()
          .then(() => {
            if (isMobile) return true
            return s.shouldOnboardEmailSignup(signInState.data, state)
          })
          .then((_shouldOnboard) => {
            if (!_shouldOnboard) throw new Error(getFigmaText(Texts.onboardingEmailErrorNotAllowedOrg))

            return startSignInWithCode(signInState.data.email, signInState, state, s, { isSignUp: true }).then(
              (signInState) => {
                s.logEvent(AnalyticsEvent.SIGN_UP, state)
                return signInState
              }
            )
          })
          .catch((error) => {
            if (
              (error as CloudFunctionError)?.cloudFunctionError?.details?.serverErrorCode ===
              ServerErrorCode.USER_ALREADY_EXISTS
            )
              return startSignInWithCode(signInState.data.email, signInState, state, s)
            return Promise.reject(error)
          })
      case SignInSteps.ENTER_CODE: {
        const { loginWithCodeToken, code } = signInState.data
        invariant(loginWithCodeToken, 'signInState.data.loginWithCodeToken')
        invariant(code, '!code')
        return s
          .createAuthenticationTokenForLoginToken({ ...loginWithCodeToken, code }, state)
          .then(({ token }) => s.signInWithCustomToken(token))
          .then(() => signInState)
          .catch((e) => ({ ...signInState, data: { ...signInState.data, loginWithCodeTokenError: e.message } }))
      }
      default:
        throw new Error(`Unknown step: ${signInState.step}`)
    }
  })

export const onAccountExistsWithDifferentCredentialsError = (
  err: any,
  signInState: SignInState,
  state: State,
  s: ServicesType
): Promise<SignInState> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { customData } = err
  const { _tokenResponse: pendingUser } = customData
  const pendingUserCredential = s.credentialFromError(err)
  invariant(pendingUserCredential, '!pendingUserCredential')
  const _email = customData.email || signInState.data.email
  invariant(_email, '!_email')
  return s.fetchSignInMethodsForEmail(_email).then((signInMethods) => {
    const partialData = {
      email: _email,
      pendingUserCredential: pendingUserCredential.toJSON(),
      pendingUser,
      signInMethods
    }

    if (!signInMethods[0]) return startSignInWithCode(_email, signInState, state, s, partialData)
    return {
      ...signInState,
      data: {
        ...signInState.data,
        ...partialData
      },
      step: SignInSteps.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIALS
    }
  })
}

const startSignInWithCode = (
  email: string,
  signInState: SignInState,
  state: State,
  s: ServicesType,
  partialData?: Partial<SignInState['data']>
): Promise<SignInState> => {
  return s.createLoginWithCodeToken({ ...toBaseObject(state), email }, state).then((loginWithCodeToken) => ({
    ...signInState,
    data: { ...signInState.data, loginWithCodeToken: { ...loginWithCodeToken, code: '' }, ...partialData },
    step: SignInSteps.ENTER_CODE
  }))
}
