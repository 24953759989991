import invariant from 'invariant'
import { getAllOrgsForDomainQuery } from '../ygdrasil/libs/FirebaseStorageClientForWebApp'
import { getDomain } from '../ygdrasil/libs/Common'
import { FirebaseUser, Organization } from '../ygdrasil/types/types'
import useFirestoreQueryDeprecated from '../ygdrasil/hooks/useFirestoreQuery'

export const useElligebleOrganizationsForFirebaseUser = (user: FirebaseUser) => {
  const { email } = user
  invariant(email, '!email')
  const domain = getDomain(email)
  const domainQuery = useFirestoreQueryDeprecated<Organization[]>(
    getAllOrgsForDomainQuery(domain),
    'useElligebleOrganizationsForFirebaseUser'
  )

  return domainQuery
}
