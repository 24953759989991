import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { bigSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { ENTER_KEYCODE, YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { getValuesForGraph } from '../ygdrasil/libs/GraphHelper'
import { getValue, isRetainIndicator } from '../ygdrasil/libs/KeyResultHelper'
import { updateValueInIndicator } from '../ygdrasil/libs/ProcessHandler'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective, Unit, Value } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import { fireConfetti } from './Confetti'
import { DatePickerWithLabelFormik } from './DatePickerWithLabelFormik'
import { StrategyViewObjectiveListRowIndicatorBodyFormIntegration } from './StrategyViewObjectiveListRowIndicatorBodyFormIntegration'
import { TextFieldNumberInputWithLabelFormik } from './TextFieldWithLabelFormik'

export function StrategyViewObjectiveListRowIndicatorBodyForm({
  objective,
  keyResult,
  item,
  setActiveValue,
  enableLabel = true
}: {
  objective: Objective
  keyResult: KeyResult
  item: Item
  setActiveValue: (value?: Value) => unknown
  enableLabel?: boolean
}) {
  const { state } = useAppState()
  const { data: organization } = useOrganization(state)
  const { startDate, endDate } = objective
  const { startValue, goalValue } = keyResult
  const logEvent = useLogEvent()
  const values = getValuesForGraph(keyResult, objective)
  const value = getValue(keyResult)
  const { autoFocus } = item.data
  let rangeRatio = DEFAULT_VALUE_RANGE_RATIO
  if (isRetainIndicator(keyResult)) rangeRatio = RETAIN_INDICATOR_RANGE_RATIO

  const onSubmit = ({ date, value }) => {
    if (!organization) return
    updateValueInIndicator({
      _value: { date, value },
      indicator: keyResult,
      objective,
      organization,
      functions: { logEvent, fireConfetti }
    })
  }

  const minValue = Math.min(goalValue, startValue) * (1 - rangeRatio)
  const maxValue = Math.max(goalValue, startValue) * (1 + rangeRatio)

  const valueValidationMessage = formatText(getFigmaText(Texts.dialogEditIndicatorDataDateError), [minValue, maxValue])

  const ValidationSchema = Yup.object().shape({
    date: Yup.string()
      .required('Enter Date')
      // eslint-disable-next-line prettier/prettier
      .test('Before End Date', `Enter date before end date (${dayjs(endDate).format(YYYY_MM_DD)})`, (date) =>
        dayjs(date).isSameOrBefore(endDate)
      )
      // eslint-disable-next-line prettier/prettier
      .test('After Start date', `Enter date after start date (${dayjs(startDate).format(YYYY_MM_DD)})`, (date) =>
        dayjs(date).isSameOrAfter(startDate)
      )
      .test('After current date', `Cant enter future date (${dayjs().format(YYYY_MM_DD)})`, (date) =>
        dayjs(date).isSameOrBefore(dayjs().format())
      ),
    value: Yup.number().required().typeError(valueValidationMessage)
  })

  if (keyResult.unit === Unit.INTEGRATION) return <StrategyViewObjectiveListRowIndicatorBodyFormIntegration />

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ date: dayjs().format(YYYY_MM_DD), value }}
      validationSchema={ValidationSchema}
      enableReinitialize
    >
      {(formProps) => {
        const nextIndex = values.findIndex((v) => v.date === formProps.values.date) + 1
        const previndex = values.findIndex((v) => v.date === formProps.values.date) - 1

        const nextValue = values[nextIndex]
        const prevValue = values[previndex]

        const onClickPrevious = () => prevValue && onChangeDate(prevValue)
        const onClickNext = () => nextValue && onChangeDate(nextValue)
        const onChangeDate = (v: Value) => {
          setActiveValue(v)
          formProps.setValues(v)
        }

        const currentValue = values.find((v) => v.date === formProps.values.date)
        const buttonTextKey =
          !!currentValue && dayjs(currentValue.date).isBefore(dayjs(), 'day')
            ? Texts.updateButtonText
            : Texts.genericAddButtonText

        return (
          <Box fullWidth direction="row">
            <Box>
              <DatePickerWithLabelFormik
                textfieldFormikProps={{
                  name: 'date',
                  formProps,
                  labelTextKey: enableLabel ? Texts.addValueDate : undefined,
                  autoFocus: autoFocus
                }}
                maxDate={dayjs().format(YYYY_MM_DD)}
                minDate={dayjs(objective.startDate).format(YYYY_MM_DD)}
              />
              <Box fullWidth direction="row" justify="space-between" top spacing={tinySpacing}>
                <ValueLink link onClick={onClickPrevious} isActive={!!prevValue}>
                  <FigmaText textKey={Texts.graphPreviousQuickEditButtonText} />
                </ValueLink>
                <ValueLink link onClick={onClickNext} isActive={!!nextValue}>
                  <FigmaText textKey={Texts.graphNextQuickEditButtonText} />
                </ValueLink>
              </Box>
            </Box>
            <Box left>
              <Box direction="row">
                <TextFieldNumberInputWithLabelFormik
                  name="value"
                  formProps={formProps}
                  labelTextKey={enableLabel ? Texts.addValueValue : undefined}
                  autoFocus={autoFocus}
                  onKeyDown={({ keyCode }) => keyCode === ENTER_KEYCODE && formProps.handleSubmit()}
                />
                <Box left alignSelf="flex-end">
                  <Box top spacing={tinySpacing} width={bigSpacing}>
                    <Button onClick={formProps.handleSubmit as any} textKey={buttonTextKey} fullWidth />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </Formik>
  )
}
const SOMEHTING_VERY_LOW = -100000000 // used to effectivley remove limit for retain indicator

const DEFAULT_VALUE_RANGE_RATIO = 0.2
const RETAIN_INDICATOR_RANGE_RATIO = -SOMEHTING_VERY_LOW

const ValueLink = styled(Box)<{ isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`
