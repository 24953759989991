import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'

type Props = { onChange?: (isOverflowing: boolean) => unknown; style?: React.CSSProperties }
const DetectOverflow: React.FC<Props> = function ({ onChange: onChangeIsOverflowing, children, style }) {
  const ref = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverFlowing] = useState(false)

  React.useLayoutEffect(() => {
    if (!ref.current) return
    const _isOverflowing =
      ref?.current?.clientWidth < ref?.current?.scrollWidth || ref?.current?.clientHeight < ref?.current?.scrollHeight
    if (_isOverflowing !== isOverflowing) {
      setIsOverFlowing(_isOverflowing)
      onChangeIsOverflowing && onChangeIsOverflowing(_isOverflowing)
    }
  }, [ref])

  return (
    <Container style={style} ref={ref}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export default DetectOverflow
