/* eslint-disable react/display-name */
import React from 'react'
import ModalCalendly, { CalendlyUrl } from './ModalCalendlyWidget'
import SignIn from './RootPage'

// eslint-disable-next-line import/no-anonymous-default-export
export default function SandboxPage() {
  const [showModal, setShowModal] = React.useState(true)

  const onCloseCalendly = () => {
    setShowModal(false)
  }

  return (
    <>
      {showModal && <ModalCalendly url={CalendlyUrl.ScheduleCall} onClose={onCloseCalendly} />}
      <SignIn />
    </>
  )
}
