import MobileStoreButton from 'react-mobile-store-button'
import { mediumSpacing, smallSpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import FigmaTextWithLinebreaks from '../ygdrasil/components/FigmaTextWithLinebreaks'
import { SAGA_APP_STORE_URL, SAGA_PLAY_STORE_URL } from '../ygdrasil/libs/Consts'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import OnboardingMobileWrapper from './OnboardingMobileWrapper'
import UserWidget from './UserWidget'

export default function OnboardingDownloadAppMobile() {
  return (
    <OnboardingMobileWrapper
      content={() => (
        <Box fullWidth>
          <Box alignSelf="flex-end">
            <Box right top spacing={smallSpacing}>
              <UserWidget opts={{ enablePhoto: false }} />
            </Box>
          </Box>
          <Box fullWidth fullHeight left right>
            <Box top right spacing={mediumSpacing} fullWidth direction="row" align="flex-end" justify="space-between">
              <FigmaText textKey={Texts.onboardingMobileAlmostThereHeader} />
              <FigmaImageContainer imageKey={Images.imageDesktopVersion} />
            </Box>
            <Box right spacing={mediumSpacing}>
              <Box top spacing={smallSpacing}>
                <FigmaTextWithLinebreaks textKey={Texts.onboardingMobileHeadToBrowserText} />
              </Box>
            </Box>
            <Box top spacing={smallSpacing}>
              <Box direction="row">
                <Box right>
                  <FigmaImageContainer imageKey={Images.imageOurBigPictureMobile} />
                </Box>
                <Box>
                  <FigmaText textKey={Texts.mobileStopWallHeader2} />
                  <Box top>
                    <FigmaText textKey={Texts.mobileStopWallText2} />
                  </Box>
                </Box>
              </Box>
              <Box fullWidth align="center" top>
                <MobileStoreButton store="ios" url={SAGA_APP_STORE_URL} linkProps={{ title: 'iOS Store Button' }} />
                <MobileStoreButton
                  store="android"
                  url={SAGA_PLAY_STORE_URL}
                  linkProps={{ title: 'Android Store Button' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    />
  )
}
