import React from 'react'
import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import Box from './Box'
import { StrategyViewObjectiveListChipInput } from './StrategyViewObjectiveListChipInput'
import { TuneIcon } from './Icons'
import { ModalProps } from './Modal'
import Colors from '../ygdrasil/libs/Colors'

type Props = { Modal: React.FC<ModalProps>; hidden?: boolean }
export function Search(props: Props) {
  const { Modal, hidden } = props
  const [showModal, setShowModal] = React.useState(false)

  const onClickSearch = () => setShowModal(true)
  return (
    <Container direction="row" align="center" hidden={hidden}>
      {showModal && <Modal onClose={() => setShowModal(false)} />}
      <SearchFieldContainer>
        <StrategyViewObjectiveListChipInput />
      </SearchFieldContainer>
      <Box left spacing={smallSpacing} link onClick={onClickSearch}>
        <TuneIcon />
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ hidden?: boolean }>`
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  margin-left: 10px;
`

export const SearchFieldContainer = styled(Box)`
  background-color: ${Colors.whiteBg};
  padding-top: 0;
`
