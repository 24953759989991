// @flow
import React from 'react'
import { smallSpacing } from '../enums/Spacings'
import { useToggleChatBubble } from '../hooks/useToggleChatBubble'
import Term from '../ygdrasil/enums/Term'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import Colors from '../ygdrasil/libs/Colors'
import { removeIndicator, updateIndicator } from '../ygdrasil/libs/ProcessHandler'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { EditKeyResultsSection } from './EditKeyResultsSection'
import { EditObjectiveDialogContainer } from './EditObjectiveDialog'
import { EditObjectiveDialogHeaderWithLine } from './EditObjectiveDialogHeaderWithLine'
import { BIG_ICON_SIZE, CloseIcon } from './Icons'
import { ModalAreYouSure } from './ModalAreYouSure'

type EditKeyResultDialogProps = {
  keyResult?: Partial<KeyResult>
  onClose: () => void
  objective: Objective
}

function EditIndicatorDialog(props: EditKeyResultDialogProps) {
  const logEvent = useLogEvent()
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  useToggleChatBubble()
  const { onClose, objective } = props
  const { keyResult } = props

  const _onSaveKeyResult = (keyResult: KeyResult) => {
    onClose()
    return updateIndicator(keyResult, logEvent)
  }

  const _onDeleteKeyResult = (keyResult: KeyResult) => {
    onClose()
    return removeIndicator(keyResult, logEvent)
  }

  return (
    <>
      {showDeleteModal && (
        <ModalAreYouSure
          onClose={() => setShowDeleteModal(false)}
          term={Term.INDICATORS}
          name={keyResult?.name || ''}
          onDelete={() => _onDeleteKeyResult(keyResult as KeyResult)}
        />
      )}
      <EditObjectiveDialogContainer fullHeight>
        <EditObjectiveDialogHeaderWithLine
          textKey={Texts.rightPanelIndicatorHeader}
          RightComponent={() => (
            <Box onClick={() => onClose()} pointer right left spacing={smallSpacing}>
              <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
            </Box>
          )}
        />
        <EditKeyResultsSection
          objective={objective}
          keyResult={keyResult as KeyResult}
          onCancel={onClose}
          onSaveKeyResult={_onSaveKeyResult}
          onDeleteKeyResult={() => setShowDeleteModal(true)}
          showHeader={false}
          onClose={onClose}
        />
      </EditObjectiveDialogContainer>
    </>
  )
}

export default EditIndicatorDialog
