import Checkbox from '@mui/material/Checkbox'
import { Formik } from 'formik'
import React from 'react'
import styled from '@emotion/styled'
import { bigSpacing, defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import FigmaTextArray from '../ygdrasil/components/FigmaTextArray'
import Colors from '../ygdrasil/libs/Colors'
import { PRIVACY_POLICY_URL, TERMS_URL } from '../ygdrasil/libs/EmailHelper'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { OnboardingViewProps } from '../ygdrasil/types/Onboarding'
import BackButtonMobileOnboarding from './BackButtonMobileOnboarding'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import OnboardingMobileWrapper from './OnboardingMobileWrapper'
import OnboardingPaginationComponent from './OnboardingPaginationComponent'
import OnboardingYellowLine from './OnboardingYellowLine'

export default function OnboardingTermsMobile({ onClickBack, onPressContinue, state }: OnboardingViewProps) {
  const initialValues = {
    checked: true
  }
  return (
    <OnboardingMobileWrapper
      content={() => (
        <Formik initialValues={initialValues} onSubmit={() => onPressContinue(state)}>
          {(formProps) => (
            <Box fullWidth fullPadding spacing={defaultSpacing}>
              <Box fullWidth align="flex-start" justify="center">
                <BackButtonMobileOnboarding
                  textKey={Texts.onboardingMobileBackButtonText}
                  onClickBack={() => onClickBack(state)}
                />
              </Box>
              <Box fullPadding spacing={bigSpacing} align="center" fullWidth>
                <FigmaImageContainer imageKey={Images.termsAndCondition} />
              </Box>
              <Box right left>
                <Box left spacing={defaultSpacing}>
                  <FigmaText textKey={Texts.onboardingMobileFinalStepButtonText} />
                </Box>
                <Box direction="row">
                  <Box right left spacing={smallSpacing}>
                    <StyledCheckbox
                      color="default"
                      size="small"
                      checked={!!formProps.values.checked}
                      onChange={formProps.handleChange('checked')}
                    />
                  </Box>
                  <Box top spacing={tinySpacing}>
                    <FigmaTextArray
                      figmaTextPropSets={[
                        { textKey: Texts.onboardingMobileTerms1 },
                        { textKey: Texts.onboardingMobileTermsLinkPrivacy, anchorHref: PRIVACY_POLICY_URL },
                        { textKey: Texts.onboardingMobileTerms2 },
                        { textKey: Texts.onboardingMobileTermsLinkService, anchorHref: TERMS_URL }
                      ]}
                    />
                  </Box>
                </Box>
              </Box>
              <Box align="center" fullPadding spacing={bigSpacing} fullWidth>
                <Box bottom>
                  <Button
                    buttonType="white"
                    disabled={!formProps.values.checked}
                    onClick={formProps.handleSubmit as any}
                    loading={formProps.isSubmitting}
                  >
                    <FigmaText textKey={Texts.onboardingMobileApproveButtonText} />
                  </Button>
                </Box>
                <OnboardingPaginationComponent
                  opacityFirstDot={100}
                  opacitySecondDot={100}
                  opacityThirdDot={100}
                  opacityForthDot={100}
                />
              </Box>
            </Box>
          )}
        </Formik>
      )}
    />
  )
}

const StyledCheckbox = styled(Checkbox)``
