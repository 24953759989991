import styled from '@emotion/styled'
import { borderRadius } from '@mui/system'
import React, { useRef, useState } from 'react'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useSelectedTeam from '../hooks/useSelectedTeam'
import useShouldShowExpandedMenu from '../hooks/useShouldShowExpandedMenu'
import { getTextWithDots } from '../libs/StrategyViewObjectiveTreeHelper'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { Team } from '../ygdrasil/types/types'
import Box from './Box'
import { ArrowDropDown, BIG_ICON_SIZE, PeopleIcon } from './Icons'
import { MenuButton } from './MenuButton'
import MenuButtonTeamMenu from './MenuButtonTeamMenu'
import { NoWrapFigmaText } from './StyledComponents'
import WithTooltipMaxLength from './WithTooltipMaxLength'

const TEAM_NAME_MAX_LENGTH = 10

export default function MenuButtonTeam() {
  const [anchorEl, setAncorEl] = useState<HTMLElement>()
  const selectedTeam = useSelectedTeam()
  const isSelected = !!selectedTeam
  const shouldShowExpandedMenu = useShouldShowExpandedMenu()
  const inputRef = useRef<HTMLInputElement>(null)
  const onClose = () => {
    setAncorEl(undefined)
    setTimeout(() => inputRef.current?.blur())
  }

  const textKey = isSelected ? Texts.applicationMenuTeamViewButtonTextActive : Texts.applicationMenuTeamViewButtonText

  return (
    <>
      <MenuButtonTeamMenu onClose={onClose} anchorEl={anchorEl} />
      <Box
        fullWidth
        onClick={(e) => setAncorEl(e.currentTarget)}
        style={{
          background: isSelected ? Colors.primary40 : 'transparent',
          borderRadius: '4px',
          paddingLeft: '2px'
        }}
        pointer
      >
        <MenuButton
          Icon={() => <PeopleIcon color={isSelected ? Colors.white : Colors.primary20} size={BIG_ICON_SIZE} />}
          textKey={textKey}
          isSelected={isSelected}
          enableBorder={false}
        />

        {shouldShowExpandedMenu && (
          <Box fullWidth direction="row" align="center">
            <Box visibility="hidden">
              <PeopleIcon color={Colors.primary20} size={BIG_ICON_SIZE} />
            </Box>
            <Box spacing={tinySpacing} left>
              <MenuTeamButtonSelect selectedTeam={selectedTeam} />
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

const Container = styled(Box)`
  border-radius: ${tinyBorderRadius};
  cursor: pointer;
`

const EmptyStateContainer = styled(Container)`
  background-color: ${Colors.surface};
`
function MenuTeamButtonSelect({ selectedTeam }: { selectedTeam?: Team }) {
  if (!selectedTeam)
    return (
      <EmptyStateContainer fullWidth direction="row" justify="space-between" align="center" left spacing={smallSpacing}>
        <NoWrapFigmaText textKey={Texts.applicatoinMenuTeamViewSelectHelperText} />
        <ArrowDropDown />
      </EmptyStateContainer>
    )

  return (
    <Container fullWidth direction="row" justify="space-between" spacing={tinySpacing} align="center">
      <Box left right direction="row" align="center" spacing={smallSpacing} fullWidth>
        <WithTooltipMaxLength title={selectedTeam.name} maxLength={TEAM_NAME_MAX_LENGTH}>
          <NoWrapFigmaText
            textKey={Texts.applicationMenuSelectTeamActive}
            text={getTextWithDots(selectedTeam.name, TEAM_NAME_MAX_LENGTH)}
          />
        </WithTooltipMaxLength>
      </Box>
      <ArrowDropDown color={Colors.primaryBase} />
    </Container>
  )
}
