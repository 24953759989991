import { getSearchResult, getSearchResultForAllItems } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { UNASSIGNED_TEAM_ID } from '../ygdrasil/libs/Consts'
import { ObjectiveType } from '../ygdrasil/types/types'
import useAppState from './useAppState'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'

export default function useUnassignedTeamObjectives() {
  const { state } = useAppState()
  const allItems = useAllItems()
  const { allObjectives } = allItems
  const { items } = getSearchResultForAllItems(allItems, { ...state, selectedTeamId: UNASSIGNED_TEAM_ID })

  return allObjectives
    .filter(({ _id }) => !!items[_id])
    .filter(({ teamIds, type }) => teamIds.length === 0 && type === ObjectiveType.TEAM)
}
