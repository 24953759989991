import styled from '@emotion/styled'
import { useState } from 'react'
import { minBorderRadius } from '../enums/BorderRadixes'
import { minSpacing, smallSpacing } from '../enums/Spacings'
import { getTextWithDots } from '../libs/StrategyViewObjectiveTreeHelper'
import { REORDER_OBJECTIVES_MODAL_MIN_WIDTH } from '../libs/HardCodedSizes'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import Colors from '../ygdrasil/libs/Colors'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { reorderObjectives } from '../ygdrasil/libs/ProcessHandler'
import Texts from '../ygdrasil/libs/Texts'
import { Objective } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import { StrategyViewObjectiveListItemSummary } from './StrategyViewObjectiveListItemSummary'
import DragDropContext from './DragDropContext'
import { ModalProps } from './Modal'
import { NoWrapFigmaText } from './StyledComponents'

type Props = { rows: Item[] } & ModalProps

export function ReorderObjectivesDrawerView(props: Props) {
  const logEvent = useLogEvent()
  const [rows, setRows] = useState<Item[]>(props.rows)

  const onClick = (e) => props.onClose(e)

  const onDragEnd = (rows) => {
    setRows(rows)
    reorderObjectives(
      rows.map((r) => ({ ...r.data.data, ordo: r.ordo } as Objective)),
      logEvent
    )
  }

  const Component = ({ data: item }) => (
    <Box align="center" direction="row" fullWidth top bottom spacing={minSpacing}>
      <Box right fullWidth>
        <StrategyViewObjectiveListItemSummary
          item={{
            ...item,
            isExpanded: false,
            data: item.data
          }}
          FigmaTextComponent={NoWrapFigmaText}
          onClickArrow={() => ({})}
          onClickHeader={() => ({})}
          isExpanded={false}
        />
      </Box>
    </Box>
  )

  return (
    <Box fullWidth style={{ width: REORDER_OBJECTIVES_MODAL_MIN_WIDTH }} fullPadding>
      <Box fullWidth bottom>
        <Box direction="row" align="center" bottom>
          <FigmaText textKey={Texts.reorderDialogHeader} />
        </Box>
        <FigmaText textKey={Texts.reorderDialogInfo} />
      </Box>
      <Container fullPadding fullWidth>
        <DragDropContext Component={Component} rows={rows} onDragEnd={onDragEnd} />
      </Container>

      <Box direction="row" align="center" justify="flex-end" fullWidth top>
        <Button textKey={Texts.doneButtonText} onClick={onClick} spacing={smallSpacing} />
      </Box>
    </Box>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.whiteBg};
  border: 1px solid ${Colors.neutralVariant90};
  border-radius: ${minBorderRadius}px;
`
