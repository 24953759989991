import styled from '@emotion/styled'
import React from 'react'
import { useUser } from '../hooks/useAppState'
import { isPropValid } from '../libs/EmotionHelper'
import { PictureSizes } from '../ygdrasil/hooks/useUrlForProfilePicture'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import * as QueryHooks from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { getInitials } from '../ygdrasil/libs/UserWidgetHelper'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { PersonIcon } from './Icons'
import PersonalPhotoView from './PersonalPhotoView'
import UserWidgetMenu from './UserWidgetMenu'

type Options = { enablePhoto?: boolean }

export default function UserWidget({ opts, disabled }: { opts?: Options; disabled?: boolean }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const user = useUser()
  const onClick = (e) => !disabled && setAnchorEl(e.currentTarget)

  if (!user) return null
  return (
    <>
      <Box onClick={onClick}>
        <UserWidgetView userId={user._id} opts={opts} disabled={disabled} />
      </Box>
      <UserWidgetMenu anchorEl={anchorEl} onClose={() => setAnchorEl(undefined)} />
    </>
  )
}

export const UserWidgetViewLead = ({
  userId,
  opts,
  size = PictureSizes.THUMBNAIL
}: {
  userId: string
  opts?: Options
  size?: number
}) => {
  return <UserWidgetView userId={userId} textKey={Texts.allTooltipText} link={false} opts={opts} size={size} />
}

export const UserWidgetViewCelebrationItem = UserWidgetViewLead

export const UserWidgetView = ({
  userId,
  size = PictureSizes.THUMBNAIL,
  textKey = Texts.userMenuNamePrefixCss,
  link = true,
  opts,
  disabled
}: {
  userId: string
  size?: number
  textKey?: AntiloopTextType
  link?: boolean
  opts?: Options
  disabled?: boolean
}) => {
  return (
    <Container align="center" justify="center" link={link} size={size} disabled={disabled}>
      <UserWidgetContent userId={userId} size={size} textKey={textKey} opts={opts} />
    </Container>
  )
}

const UserWidgetContent = ({
  userId,
  size,
  textKey,
  opts
}: {
  userId: string
  size: number
  textKey: AntiloopTextType
  opts?: Options
}) => {
  const { data: user } = QueryHooks.useUser(userId)

  const initials = getInitials(user?.name || '')

  if (!!user?.profileImage && opts?.enablePhoto !== false) return <PersonalPhotoView user={user} size={size} />
  if (!!initials) return <FigmaText textKey={textKey} text={initials} />

  return <PersonIcon size={size} />
}

const Container = styled(Box, { shouldForwardProp: isPropValid })<{
  size: number
  $backgroundColor?: string
  disabled?: boolean
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  opacity: ${({ disabled }) => (!!disabled ? 0.5 : 1)};
  background-color: ${Colors.neutralVariant80};
`
