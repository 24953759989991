import Menu from '@mui/material/Menu'
import { MenuItem } from './MenuItem'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { updateGrowthIndicator } from '../ygdrasil/libs/ProcessHandler'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { Direction, GrowthIndicator } from '../ygdrasil/types/types'
import Box from './Box'
import { DecreasingIndicator, IncreasingIndicator } from './Icons'

type Props = {
  growthIndicator: GrowthIndicator
  anchorEl?: HTMLElement
  onClose: () => void
}

export default function GrowthViewGrowthIndicatorRowDirectionMenu({ anchorEl, onClose, growthIndicator }: Props) {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const logEvent = useLogEvent()
  const onClickIncreasing = () => onClickUpdateGrowthIndicator(Direction.INCREASING)
  const onClickDecreasing = () => onClickUpdateGrowthIndicator(Direction.DECREASING)

  const onClickUpdateGrowthIndicator = (direction: Direction) => {
    onClose()
    updateGrowthIndicator({ ...growthIndicator, direction }, logEvent)
  }
  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItem onClick={onClickIncreasing}>
          <Box direction="row" align="center">
            <Box right spacing={smallSpacing}>
              <IncreasingIndicator />
            </Box>
            {getTextForTextKey(Texts.successViewIncreaseIndicatorText, org)}
          </Box>
        </MenuItem>
        <MenuItem onClick={onClickDecreasing}>
          <Box direction="row" align="center">
            <Box right spacing={smallSpacing}>
              <DecreasingIndicator />
            </Box>
            {getTextForTextKey(Texts.successViewDecreasingIndicatorText, org)}
          </Box>
        </MenuItem>
      </Menu>
    </div>
  )
}
