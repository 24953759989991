import React from 'react'
import { ButtonProps } from '@mui/material/Button'
import ButtonSecondary from './ButtonSecondary'
import ButtonPrimary from './ButtonPrimary'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import ButtonTertiary from './ButtonTertiary'
import Loader from './Loader'
import Box from './Box'
import ButtonWhite from './ButtonWhite'
import ButtonEmptyState from './ButtonEmptyState'

type Props = {
  className?: string
  spacing?: string
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'empty_state'
  textKey?: AntiloopTextType
  loading?: boolean
} & ButtonProps
export default function Button(props: Props) {
  const { loading, buttonType, ...buttonProps } = props

  const isPrimary = !buttonType || buttonType === 'primary'
  if (loading && !isPrimary)
    // loading state in primary button is handled in ButtonPrimary.tsx
    return (
      <Box fullWidth align="center">
        <Loader />
      </Box>
    )

  if (buttonType === 'white') return <ButtonWhite {...buttonProps} />
  if (buttonType === 'secondary') return <ButtonSecondary {...buttonProps} />
  if (buttonType === 'tertiary') return <ButtonTertiary {...buttonProps} />
  if (buttonType === 'empty_state') return <ButtonEmptyState {...buttonProps} />
  return <ButtonPrimary {...buttonProps} loading={loading} />
}
