import { OAuthProvider } from 'firebase/auth'
import {
  fetchSignInMethodsForEmail,
  logEvent,
  signInWithCustomToken,
  signInWithPopup
} from '../ygdrasil/libs/FirebaseHelper'
import {
  createAuthenticationTokenForLoginToken,
  createLoginWithCodeToken,
  shouldOnboardEmailSignup
} from '../ygdrasil/libs/CloudFunctionsApiHandler'

export type ServicesType = {
  shouldOnboardEmailSignup: typeof shouldOnboardEmailSignup
  createAuthenticationTokenForLoginToken: typeof createAuthenticationTokenForLoginToken
  createLoginWithCodeToken: typeof createLoginWithCodeToken
  fetchSignInMethodsForEmail: typeof fetchSignInMethodsForEmail
  signInWithCustomToken: typeof signInWithCustomToken
  signInWithPopup: typeof signInWithPopup
  logEvent: typeof logEvent
  credentialFromError: typeof OAuthProvider.credentialFromError
}

export const SignInMachineServices = {
  shouldOnboardEmailSignup,
  createAuthenticationTokenForLoginToken,
  createLoginWithCodeToken,
  fetchSignInMethodsForEmail,
  signInWithCustomToken,
  signInWithPopup,
  logEvent,
  credentialFromError: (err) => OAuthProvider.credentialFromError(err)
}
