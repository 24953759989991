import dayjs from 'dayjs'
import { SearchDates } from '../ygdrasil/types/types'
import Box from './Box'
import { GrowthViewSearch } from './GrowthViewSearch'
import ObjectivePeriodAndTypeOptionsSlider from './ObjectivePeriodAndTypeOptionsSlider'
import { SuccessViewHeaderToggle } from './SuccessViewHeaderToggle'

export default function SuccessViewHeader(props: {
  searchDates: SearchDates
  onChange: (searchDates: SearchDates) => void
  timespanRestriction?: number
}) {
  return (
    <Box direction="row" align="center" justify="space-between" fullWidth fullPadding>
      <Box direction="row">
        <Box width="20%" style={{ minWidth: 500 }} right>
          <ObjectivePeriodAndTypeOptionsSlider
            {...props}
            sliderStartDate={dayjs().startOf('year').add(-1, 'quarter').format()}
            enableHelperText={false}
          />
        </Box>
        <GrowthViewSearch />
      </Box>
      <SuccessViewHeaderToggle />
    </Box>
  )
}
