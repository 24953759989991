import styled from '@emotion/styled'
import { Formik } from 'formik'
import invariant from 'invariant'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useNotification from '../hooks/useNotification'
import { invite } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH } from '../libs/HardCodedSizes'
import {
  inSelectedOrder,
  PREFERRED_LOGIN_TEXT_KEYS,
  PREFERRED_LOGIN_TYPES,
  ValidationSchema
} from '../libs/ModalInviteUserHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import Colors from '../ygdrasil/libs/Colors'
import { logEvent } from '../ygdrasil/libs/FirebaseHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { useLanguage } from '../ygdrasil/libs/useLanguage'
import { PreferredLoginMethod } from '../ygdrasil/types/SignInTypes'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { RadioContainer } from './Radio'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import { inviteFromApp } from '../ygdrasil/libs/ProcessHandler'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'

export default function ModalInviteUserForm({
  onClose,
  invite = inviteFromApp
}: {
  invite?: typeof inviteFromApp
  onClose: () => void
}) {
  const { state } = useAppState()
  const notificaiton = useNotification()
  const { data: organization } = useOrganization(state)
  const language = useLanguage()
  const preferredLoginTypes = PREFERRED_LOGIN_TYPES.sort(inSelectedOrder(organization?.preferredLoginMethod))
  const logEvent = useLogEvent()

  const onSubmit = ({ email, message, preferredLoginMethod, name }) => {
    invariant(organization, 'Cant find organization with id: %s', state.selectedOrganizationId)
    return invite(
      {
        orgId: state.selectedOrganizationId,
        name,
        email,
        language,
        createdBy: state.user._id,
        message,
        preferredLoginMethod
      },
      state,
      logEvent
    ).then(() => {
      onClose()
      notificaiton.success()
    })
  }

  const initialValues = {
    name: '',
    email: '',
    message: '',
    preferredLoginMethod: organization?.preferredLoginMethod || PreferredLoginMethod.MAIL
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema} enableReinitialize>
      {(formProps) => {
        const { values: v } = formProps
        const onChange = (preferredLoginMethod: PreferredLoginMethod) => () =>
          formProps.setValues({ ...formProps.values, preferredLoginMethod })
        return (
          <Container fullWidth align="center">
            <Box fullWidth align="center" spacing={bigSpacing}>
              <Box bottom>
                <FigmaText textKey={Texts.inviteDialogHeader} />
              </Box>
              <Box fullWidth bottom spacing={smallSpacing}>
                <TextFieldWithLabelFormik
                  labelTextKey={Texts.inviteDialogEmailLabel}
                  labelText={getFigmaText(Texts.onboardingMobileUserNameLabel)}
                  formProps={formProps}
                  name="name"
                  component={TextFieldOutlined}
                  fullWidth
                />
              </Box>
              <Box fullWidth bottom spacing={smallSpacing}>
                <TextFieldWithLabelFormik
                  labelTextKey={Texts.inviteDialogEmailLabel}
                  formProps={formProps}
                  name="email"
                  component={TextFieldOutlined}
                  fullWidth
                />
              </Box>
              {/** TODO REMOVE, check with eddie first
               * <Box fullWidth bottom spacing={smallSpacing}>
                <TextFieldWithLabelFormik
                  labelTextKey={Texts.inviteDialogPersonalMessageLabel}
                  formProps={formProps}
                  name="message"
                  component={TextFieldOutlined}
                  fullWidth
                  multiline
                />
              </Box>
               */}
              <Box spacing={smallSpacing} fullWidth>
                <Box bottom spacing={tinySpacing}>
                  <FigmaText
                    textKey={Texts.inviteDialogEmailLabel}
                    text={getFigmaText(Texts.adminApplicationSettingsPreferedLoginLabelText)}
                  />
                </Box>
                <PreferredLoginMethodContainer fullWidth>
                  <Box fullPadding fullWidth spacing={smallSpacing}>
                    {preferredLoginTypes.map((loginMethod, key) => (
                      <Box key={key} direction="row" align="center">
                        <RadioContainer
                          checked={v.preferredLoginMethod === loginMethod}
                          onChange={onChange(loginMethod)}
                        />
                        <Box left spacing={smallSpacing}>
                          <FigmaText
                            textKey={PREFERRED_LOGIN_TEXT_KEYS[loginMethod] || PREFERRED_LOGIN_TEXT_KEYS.default}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </PreferredLoginMethodContainer>
              </Box>
              <Box direction="row" align="center" justify="flex-end" fullWidth top>
                <Box right spacing={tinySpacing}>
                  <Button
                    onClick={onClose}
                    textKey={Texts.cancelButtonText}
                    buttonType="secondary"
                    spacing={tinySpacing}
                  />
                </Box>
                <Button onClick={formProps.handleSubmit as any} spacing={tinySpacing}>
                  <FigmaTextLoader textKey={Texts.inviteButtonText} loading={formProps.isSubmitting} />
                </Button>
              </Box>
            </Box>
          </Container>
        )
      }}
    </Formik>
  )
}

const Container = styled(Box)`
  min-width: ${EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH};
`

const PreferredLoginMethodContainer = styled(Box)`
  border-radius: ${tinyBorderRadius};
  border: 1px solid ${Colors.lightgray};
`
