import invariant from 'invariant'
import React, { CSSProperties } from 'react'
import { useLanguage } from '../libs/useLanguage'
import { AntiloopTextType } from '../types/Antiloop'

export type FigmaTextWithStyleOverridesProps<T> = {
  textKeys: { [language: string]: Required<AntiloopTextType> }
  Component: React.FC<{ style: T | undefined; onClick?: () => unknown; children?: React.ReactNode }>
  onClick?: () => unknown
  mapStyle?: <T>(style: CSSProperties) => T
  children?: React.ReactNode
}

function FigmaTextWithStyleOverrides<T>({
  textKeys,
  onClick,
  Component,
  mapStyle
}: FigmaTextWithStyleOverridesProps<T>) {
  const language = useLanguage()
  const textKey = textKeys[language] || textKeys.en
  invariant(textKey, '!textKey')
  const { style, styleOverrideTable, characterStyleOverrides } = textKey
  style.whiteSpace = 'pre'
  const newLinesplits = textKey.characters.split('\n')

  let index = -1
  return (
    <>
      {newLinesplits.map((text, i, arr) => {
        index++
        return (
          <Component key={i} style={undefined}>
            {text.split('').map((char, j) => {
              const characterOverridesStyleKey = characterStyleOverrides[index]
              index++
              const overrideStyle = styleOverrideTable[characterOverridesStyleKey]
              const onClickStyleOverride =
                overrideStyle?.textDecoration === 'UNDERLINE' ? { cursor: 'pointer' } : undefined
              const _onClick = !!onClickStyleOverride ? onClick : undefined
              let _style = { ...style, ...overrideStyle, ...onClickStyleOverride }
              if (mapStyle) _style = mapStyle(_style)
              return (
                <Component key={j} style={_style} onClick={_onClick}>
                  {char}
                </Component>
              )
            })}
          </Component>
        )
      })}
    </>
  )
}

export default FigmaTextWithStyleOverrides
