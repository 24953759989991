/* eslint-disable react/display-name */
import useAppState from '../hooks/useAppState'
import { useGrowthIndicators } from '../ygdrasil/libs/QueryHooks'

export default function SandboxLoggedInPage() {
  const { state } = useAppState()
  const { data: growthIndicators = [] } = useGrowthIndicators(state)

  return null
}
