import { useToggleChatBubble } from '../hooks/useToggleChatBubble'
import { inRankOrder } from '../ygdrasil/libs/RankHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { Objective } from '../ygdrasil/types/types'
import { ModalProps } from './Modal'
import { ReorderObjectivesDrawerView } from './ReorderObjectivesDrawerView'
import useTreeItems from './useTreeItems'

type Props = { objective: Objective } & ModalProps

export function ReorderObjectivesDrawer(props: Props) {
  const { objective } = props
  const { tree, rootItems } = useTreeItems()
  useToggleChatBubble()
  const parent = tree.items[objective.parentObjectiveId || '']
  let siblings: Item[] = []
  if (!parent) siblings = rootItems
  else siblings = parent.children

  siblings = siblings.filter((sibling) => sibling.data.searchType === 'objective').sort(inRankOrder)

  if (siblings.length === 0) return null

  return <ReorderObjectivesDrawerView rows={siblings} {...props} />
}
