/* eslint-disable prettier/prettier */
// @flow
import { collection, deleteDoc, doc, getDoc, getDocs, query, where } from '@firebase/firestore'
import { App } from 'firebase-admin/app'
import { getFirestore } from 'firebase-admin/firestore'
import EventCategories from '../enums/EventCategories'
import { ServerFirebaseUser } from '../types/ServerTypes'
import { BaseObject, DB, Id, Organization, User } from '../types/types'
import Collections from './Collections'
import { createUniqueId } from './Common'
import config from '../../Config'
import { ORGANIZATION_ID } from './Consts'
import { addItem, mapQueryResponse, setItem, toBaseObject, toUpdatedObject } from './FirebaseStorageClientBaseHelper'

export const createObject = <T>(object: T, user: ServerFirebaseUser, collection: string, db: DB): Promise<T> => setItem(collection, { ...toBaseObject(user), ...object }, db).then((res) => onSuccess(res, 'createObject', collection)).catch(e => onError(e, 'createObject', collection))

export const addObject = <T>(object: T, collection: string, db: DB): Promise<T> => Promise.resolve(addItem(collection, object, db)).then((res) => onSuccess(res, 'addObject', collection)).catch(e => onError(e, 'addObject', collection))

export const updateObject = <T extends BaseObject>(object: T, collection: string, db: DB, user?: ServerFirebaseUser, ): Promise<T> => setItem(collection, toUpdatedObject(object, user), db).then((res) => onSuccess(res, 'updateObjcet', collection)).catch(e => onError(e, 'updateObject', collection))

export const removeObject = (id: string, path: string, db: DB) => deleteDoc(doc(db, path, id)).then(() => {return}).then((res) => onSuccess(res, 'removeObject', path)).catch(e => onError(e, 'removeObject', path))

export const getAllObjects = <T>(path: Collections, db: DB): Promise<Array<T>> => getDocs(collection(db, path)).then((snapshot) => mapQueryResponse(snapshot)).then((res) => onSuccess(res, 'getAllObjects', path)).catch(e => onError(e, 'getAllObjects', path))

export const getAllObjectsWithOrganizationId = <T>(organizationId: string, collection: Collections, db: DB): Promise<Array<T>> =>  getDocs(getAllObjectsWithOrganizationIdQuery(organizationId, collection, db)).then((snapshot) => mapQueryResponse(snapshot)).then((res) => onSuccess(res, 'getAllObjectsWithOrganizationId', collection)).catch(e => onError(e, 'getAllObjectsWithOrganizationId', collection))

export const getObject = <T extends BaseObject>(_id: Id, collection: string, db: DB): Promise<T | undefined> => getDoc(getObjectQuery(db, collection, _id)).then(res => res.data() as T | undefined).then((res) => onSuccess(res, 'getObject', collection)).catch(e => onError(e, `getObject: ${_id}`, collection))

export const getAllUsersForUser = ({organizationIds}: User, db: DB): Promise<Array<User>> => getDocs(getAllUsersForUserQuery(db, organizationIds)).then((snapshot) => mapQueryResponse(snapshot)).then((res) => onSuccess(res, 'getAllUsersForUser', '')).catch(e => onError(e, 'getAllUsersForUser', Collections.USERS))

export const getAllOrgsForUser = ({organizationIds}: User, db: DB): Promise<Array<Organization>> => getDocs(getAllOrgsQuery(db, organizationIds)).then((snapshot) => mapQueryResponse(snapshot)).then((res) => onSuccess(res, 'getAllOrgsForUser', '')).catch(e => onError(e, 'getAllOrgsForUser', Collections.USERS))

export const getAllOrgsForDomain = (domain: string, db: DB): Promise<Array<Organization>> => getDocs(getAllOrgsForDomainQuery(db, domain)).then((snapshot) => mapQueryResponse(snapshot)).then((res) => onSuccess(res, 'getAllOrgsForDomain', '')).catch(e => onError(e, 'getAllOrgsForDomain', Collections.ORGANIZATIONS))

export const getAllOrgsForDomainQuery = (db: DB, domain: string) => query(collection(db, Collections.ORGANIZATIONS), where('domains', 'array-contains', domain))

export const getObjectQuery = (db: DB, path: string, _id: string) => doc(db, path, _id || createUniqueId())

export const getObjectsQuery = (db: DB, path: string) => collection(db, path)

export const getAllUsersForUserQuery = (db: DB, organizationIds: string[]) => query(collection(db, Collections.USERS), where('organizationIds', 'array-contains-any', organizationIds))

export const getAllOrgsQuery = (db: DB, organizationIds: string[]) => query(collection(db, Collections.ORGANIZATIONS), where('_id', 'in', organizationIds))

export const getAllObjectsWithOrganizationIdQuery = (organizationId: string, path: Collections, db: DB, key = ORGANIZATION_ID) => query(collection(db, path), where(key, '==', organizationId))

export const getAddIndicatorValueEvents = (organizationId: string, db: DB) => query(collection(db, Collections.EVENTS), where(ORGANIZATION_ID, '==', organizationId), where('eventCategory', '==', EventCategories.UserAddIndicatorValue))

const onError = (error: Error, name: string, path: string) => {
  error.message = `${name} ${path} ${error.message}`
  if(config.enableLogFirebaseDBError) console.error(error)
  return Promise.reject(error)
}

const onSuccess = <T>(res: T, fnName: string, path: string) => {
  if (config.enableLogFirestoreQueryResult) console.log('FirebaseStorageClientBaseHelper', 'onSuccess', fnName, path)
  return res
}
