import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import { State } from '../hooks/useAppState'
import { isOurBigPictureUser } from '../ygdrasil/hooks/useLoggedInConfig'
import { GOAL_VALUE, NO_LEAD, START_VALUE } from '../ygdrasil/libs/Consts'
import { KeyResult, OrgUserStatus, PartialConfig, Unit, User } from '../ygdrasil/types/types'
import { InitialValues } from './EditKeyResultDialogHelper'

export const ONE = '1'
export const ZERO = '0'
const HIDDEN_FIELDS: Array<keyof KeyResult> = ['description', 'leadUserId']

export const onSelectUnit = (unit: Unit, formProps: FormikProps<InitialValues>) => {
  if (unit === Unit.BOOLEAN) {
    formProps.setFieldValue(START_VALUE, ZERO)
    formProps.setFieldValue(GOAL_VALUE, ONE)
  }

  formProps.setFieldValue(`unit`, unit)
}

export function getUsersOptions(users: User[], { selectedOrganizationId }: State, _config: PartialConfig) {
  return users
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce(
      (a, user) => {
        const status = user.orgUserInfo?.[selectedOrganizationId]?.status
        if (!_config.enableOurBigPictureUsersToBeLead && isOurBigPictureUser(user)) return a
        if (!user.organizationIds.includes(selectedOrganizationId)) return a
        if (status && status !== OrgUserStatus.ACTIVE) return a
        a.push(user)
        return a
      },
      [{ _id: NO_LEAD, name: '' }] as User[]
    )
    .map(({ _id: value, name, email }) => ({ key: name || email || '', value }))
}

export const onChangeLeadUserId = (
  leadUserId: string,
  formProps: FormikProps<InitialValues>,
  state: State,
  now: string = dayjs().format()
) => {
  if (leadUserId === NO_LEAD) leadUserId = ''
  formProps.setFieldValue(`leadUserId`, leadUserId)
  formProps.setFieldValue(`leadMetaData`, getLeadMetaData(state, now))
}

export const shouldShowMore = (values?: KeyResult) => HIDDEN_FIELDS.some((key) => !!values?.[key])

export function getLeadMetaData(state: State, now: string = dayjs().format()): any {
  return {
    assignerUserId: state.user._id,
    assignedAt: dayjs(now).format()
  }
}
