import dayjs from 'dayjs'
import { Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { RegexUrl, RequiredNameValidationDeprecated } from '../libs/ValidationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { DATE_VALIDATION_MESSAGE, ENTER_KEYCODE, YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import Images from '../ygdrasil/libs/Images'
import { toBaseObject } from '../ygdrasil/libs/Mapper'
import { createActivity, removeActivity, updateActivity } from '../ygdrasil/libs/ProcessHandler'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Activity, KeyResult } from '../ygdrasil/types/types'
import { ActivityStatusBadge } from './ActivityStatusBadge'
import Box from './Box'
import { DrawerFooterButtons } from './DrawerFooterButtons'
import EditActivityDialogSelectDueDateField from './EditActivityDialogSelectDueDateField'
import EditActivityDialogSelectLeadMenu from './EditActivityDialogSelectLeadMenu'
import { EditActivityFormAutoCompleteJiraIssue } from './EditActivityFormAutoCompleteJiraIssue'
import { EditObjectiveDialogContainer } from './EditObjectiveDialog'
import FigmaImageContainer from './FigmaImageContainer'
import SelectActivityStatusMenu from './SelectActivityStatusMenu'
import { NoWrapFigmaTextForStrategyModel } from './StyledComponents'
import { GenericErrorComponent } from './TextFieldFormik'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

export type InitialValues = {
  name: string
  url: string
  status: ActivityStatus
  dueDate: string
  leadUserId?: string
  jiraIssueKey: string
}

export type OnCreateEditActivity = (activity: Activity) => void
export type CreateEditActivityFormProps = {
  onClose?: () => unknown
  keyResult: KeyResult
  activity?: Partial<Activity>
  onCreateEditActivity?: OnCreateEditActivity
  onDeleteActivity?: OnCreateEditActivity
}

export default function EditActivityDialogForm({
  onClose,
  keyResult,
  activity,
  onCreateEditActivity = () => ({}),
  onDeleteActivity
}: CreateEditActivityFormProps) {
  const [statusEl, setStatusEl] = useState<HTMLElement | null>(null)
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const isLinkedWithJira = !!org?.jiraConfig
  const logEvent = useLogEvent()
  const onSubmit = (values: typeof initialValues) => {
    const _activity: Activity = {
      ...toBaseObject(state),
      ordo: dayjs().valueOf(),
      ...activity,
      ...values,
      dueDate: values.dueDate || undefined,
      organizationId: state.selectedOrganizationId,
      parentId: keyResult._id
    }
    if (!!activity) updateActivity(_activity, logEvent)
    else createActivity(_activity, state, logEvent)

    onCreateEditActivity(_activity)
    onClose?.()
  }

  const initialValues: InitialValues = {
    name: activity?.name || '',
    url: activity?.url || '',
    status: activity?.status || ActivityStatus.TODO,
    dueDate: !!activity?.dueDate ? dayjs(activity.dueDate).format(YYYY_MM_DD) : '',
    leadUserId: activity?.leadUserId,
    jiraIssueKey: activity?.jiraIssueKey || ''
  }

  const ValidationSchema = Yup.object().shape({
    name: RequiredNameValidationDeprecated(getFigmaText(Texts.dialogEditActivitySummaryLabel)),
    url: Yup.string().matches(RegexUrl, {
      message: getFigmaText(Texts.activityDialogLinkInputValidationMessage)
    }),
    dueDate: Yup.string().test(
      'Correct Format',
      DATE_VALIDATION_MESSAGE,
      (date) => !date || (date?.length === YYYY_MM_DD.length && dayjs(date, YYYY_MM_DD).isValid())
    )
  })

  function _onDeleteActivity(): void {
    removeActivity(activity as Activity, logEvent)
    onDeleteActivity?.(activity as Activity)
    onClose?.()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
      {(formProps) => (
        <EditObjectiveDialogContainer fullWidth align="center" fullHeight>
          <Box fullWidth align="center" fullPadding fullHeight spacing={defaultSpacing}>
            {!activity?._id && (
              <Box bottom direction="row" fullWidth justify="space-between" align="center" spacing={smallSpacing}>
                <NoWrapFigmaTextForStrategyModel textKey={Texts.dialogAddActivityHeader} />
              </Box>
            )}
            <Box fullWidth>
              <TextFieldWithLabelFormik
                labelTextKey={Texts.dialogEditActivitySummaryLabel}
                formProps={formProps}
                name="name"
                component={TextFieldOutlined}
                fullWidth
                onKeyDown={(e) => e.keyCode === ENTER_KEYCODE && formProps.handleSubmit(e)}
              />
            </Box>
            <Box fullWidth direction="row" align="center" justify="space-between">
              <EditActivityDialogSelectLeadMenu formProps={formProps} />
              <Box gap={tinySpacing}>
                <FigmaText textKey={Texts.dialogEditActivityStatusLabel} />
                <Box onClick={(e) => setStatusEl(e.currentTarget)}>
                  <ActivityStatusBadge
                    isActivityDialog
                    activity={{ ...activity, status: formProps.values.status } as Activity}
                  />
                  <SelectActivityStatusMenu
                    formProps={formProps}
                    activity={activity as Activity}
                    anchorEl={statusEl}
                    onClose={() => setStatusEl(null)}
                  />
                </Box>
              </Box>
              <EditActivityDialogSelectDueDateField formProps={formProps} />
            </Box>
            <Box fullWidth top spacing={smallSpacing}>
              {isLinkedWithJira ? (
                <Box fullWidth>
                  <Box bottom spacing={tinySpacing}>
                    <FigmaText textKey={Texts.dialogEditActivityUrlLabel} text="Link" />
                  </Box>
                  <Box fullWidth direction="row" gap={tinySpacing} align="center">
                    <Box fullWidth gap={tinySpacing}>
                      <EditActivityFormAutoCompleteJiraIssue formProps={formProps} />
                      {formProps.errors['url'] && <GenericErrorComponent formProps={formProps} name="url" />}
                    </Box>
                    <FigmaImageContainer imageKey={Images.jira} />
                  </Box>
                </Box>
              ) : (
                <TextFieldWithLabelFormik
                  labelTextKey={Texts.dialogEditActivityUrlLabel}
                  formProps={formProps}
                  name="url"
                  component={TextFieldOutlined}
                  fullWidth
                />
              )}
            </Box>
          </Box>
          <DrawerFooterButtons
            onClickCancel={() => onClose && onClose()}
            onClickContinue={formProps.handleSubmit as any}
            onClickDelete={(!!activity?._id && _onDeleteActivity) || undefined}
          />
        </EditObjectiveDialogContainer>
      )}
    </Formik>
  )
}
