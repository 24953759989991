import { getGoogleIconApiUrl } from '../ygdrasil/libs/ActivityHelper'
import { Activity } from '../ygdrasil/types/types'

export function ActivityBoardKanbanCardActivityUrlIcon({ activity }: { activity: Activity }) {
  return (
    <div style={{ display: 'inline-block' }}>
      <img src={getGoogleIconApiUrl(activity)} alt={`Favicon - ${activity.url}`} />
    </div>
  )
}
