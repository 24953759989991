import dayjs from 'dayjs'
import { DEFAULT_STATE, State } from '../hooks/useAppState'
import { AllItems } from '../ygdrasil/hooks/useAllItems'
import { asObject } from '../ygdrasil/libs/Common'
import { UNASSIGNED_TEAM_ID } from '../ygdrasil/libs/Consts'
import { isInPeriodForState } from '../ygdrasil/libs/isMatch'
import { inRankOrder } from '../ygdrasil/libs/RankHelper'
import { getSearchResultForAllItems, SearchObject, SearchState } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { Objective, ObjectivesObject, ObjectiveType } from '../ygdrasil/types/types'

export const getRootObjectives = (allObjectives: Objective[]) => {
  const _allObjectives = asObject(allObjectives, '_id')
  const parents = allObjectives
    .map((o) => getRootObjective(o, _allObjectives))
    .sort((a, b) => dayjs(b.endDate).diff(dayjs(a.endDate)))

  const _parents = asObject(parents, '_id')
  return Object.keys(_parents).map((key) => _parents[key])
}

export const getRootObjective = (o: Objective, objectives: ObjectivesObject): Objective => {
  if (!o.parentObjectiveId) return o
  const parent = objectives[o.parentObjectiveId]
  if (!parent) {
    console.warn('objective', o._id, 'has', o.parentObjectiveId, 'as father but it cant be found')
    return o
  }
  return getRootObjective(parent, objectives)
}

export const getFocusedObjectivesForState = (allItems: AllItems, state: State) =>
  _getFocusedObjectivesForState(allItems, state).sort(inRankOrder)

const _getFocusedObjectivesForState = (allItems: AllItems, state: State) => {
  if (!state.selectedTeamId) return getFocusedObjectivesForTopView(allItems, state)
  return getFocusedObjectivesForTeamView(allItems, state)
}

export const getFocusedObjectivesForTopView = (allItems: AllItems, state: State) => {
  const { searchState } = state
  const _searchState: SearchState = {
    ...DEFAULT_STATE.searchState,
    strategicObjective: true,
    searchDates: searchState?.searchDates,
    strategicPillar: searchState?.strategicPillar
  }

  const { items } = getSearchResultForAllItems(allItems, { ...state, searchState: _searchState })

  return allItems.allObjectives.filter((o) => items[o._id]?.data?.isMatch === true)
}

export const getFocusedObjectivesForTeamView = (allItems: AllItems, state: State) => {
  const searchState: SearchState = { ...state.searchState, selectedItemId: undefined }
  const { items } = getSearchResultForAllItems(allItems, { ...state, searchState })
  return Object.keys(items).reduce((a, b) => {
    const item = items[b]
    if (!item) return a
    const searchObject = item.data as SearchObject
    if (searchObject.searchType !== 'objective') return a
    const children = item.children.filter((child) => items[child.id]?.data.searchType === 'objective')
    if (children.length > 0) return a
    a.push(item.data.data as Objective)
    return a
  }, [] as Objective[])
}

export const getObjectivesForObjectiveList = (allObjectives: Objective[], state: State) => {
  const { searchState } = state
  const { selectedItemId } = searchState

  allObjectives = allObjectives.filter((o) => isInPeriodForState(o as any, state))

  if (state.selectedTeamId === UNASSIGNED_TEAM_ID)
    return allObjectives.filter((o) => o.type === ObjectiveType.TEAM && o.teamIds.length === 0)
  if (selectedItemId) return allObjectives.filter((o) => o._id === selectedItemId)

  return getRootObjectives(allObjectives)
}
