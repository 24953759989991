import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Texts from '../ygdrasil/libs/Texts'
import AddButton from './AddButton'
import Box from './Box'
import DashboardGrowthViewTable from './GrowthViewTable'

export default function SuccessViewSheetView({
  searchDates
}: {
  searchDates: {
    startDate: string
    endDate: string
  }
}) {
  const { state, setState } = useAppState()
  const config = useLoggedInConfig()
  return (
    <Box fullPadding fullWidth>
      <DashboardGrowthViewTable searchDates={searchDates} />
      {config.enableEditSuccessView && (
        <Box top spacing={smallSpacing}>
          <AddButton
            labelTextKey={Texts.addIndicatorButtonText}
            onClick={() => setState({ ...state, showNewIndicatorRow: true })}
          />
        </Box>
      )}
    </Box>
  )
}
