import React, { ReactElement, useEffect } from 'react'
import styled from '@emotion/styled'
import { AntiloopTextType } from '../types/Antiloop'
import { getFigmaText } from '../libs/TextRepository'
import { captureException } from '../libs/SentryHelperDeprecated'

export type FigmaTextProps = {
  textKey: AntiloopTextType
  styleTextKey?: AntiloopTextType
  className?: string
  text?: string
  onClick?: () => unknown
  link?: boolean
  anchorHref?: string
  textTransform?: 'uppercase'
  separator?: ReactElement
}

const FigmaText: React.FC<FigmaTextProps> = (props) => {
  const { text: _text, styleTextKey, textKey, className, onClick, link, anchorHref, textTransform } = props
  let { style } = styleTextKey || textKey

  useEffect(() => {
    if (text.includes('$')) captureException(new Error(`${textKey.name} and text: ${text} not supported`))
  }, [])

  if (!style) return null
  style = mapStyle(style)

  if (link) style.fontWeight = 'bold'
  let text = typeof _text === 'string' ? _text : getFigmaText(textKey)
  if (textTransform === 'uppercase') text = text.toUpperCase()
  if (link) return <Link onClick={onClick} style={style} dangerouslySetInnerHTML={{ __html: text }} />
  if (anchorHref)
    return (
      <a href={anchorHref} style={style} dangerouslySetInnerHTML={{ __html: text }} target="_blank" rel="noreferrer" />
    )

  return <span dangerouslySetInnerHTML={{ __html: text }} className={className} style={style} />
}

const mapStyle = (style) => {
  if (style.textDecoration === 'STRIKETHROUGH') style.textDecoration = 'line-through'
  return style
}

const Link = styled.span`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`
export default FigmaText
