import dayjs from 'dayjs'
import { getPolynomialValue, getValuesForGraph, Payload } from '../libs/GraphHelper'
import { Item } from '../libs/StrategyViewObjectiveListHelper'
import { KeyResult, Objective, Value } from '../types/types'
import useRegressionResult from './useRegressionResult'

export type GraphProps = {
  parentItem: Item
  item: Item
  width?: number
  height?: number
  activeValue?: Value
  enableActivityList?: boolean
}

export const useGraph = (props: GraphProps) => {
  const { item, parentItem } = props
  const objective = parentItem.data.data as Objective
  const keyResult = item.data.data as KeyResult
  // eslint-disable-next-line prefer-const
  let { values, startValue, goalValue } = keyResult

  const result = useRegressionResult({ keyResult, objective })

  // TODO WRITE TEST PAIN end in values into useRegressionResult instead (2 on line 139)
  values = getValuesForGraph(keyResult, objective)

  const _values = values.reduce((a, { date, value }, i) => {
    const x = dayjs(date)
    const base = result ? getPolynomialValue(x, objective, result) : value
    a.push({ timestamp: x.valueOf(), value, base, top: Math.max(startValue, goalValue) - base })

    return a
  }, [] as Payload[])

  const lastValue = _values.filter(({ value }) => typeof value === 'number').find((x, i, arr) => arr.length - 1 === i) // find last element

  return { lastValue, values: _values }
}
