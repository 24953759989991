import { tinySpacing } from '../enums/Spacings'
import useResponsive from '../hooks/useResponsive'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { SignInViewProps } from '../ygdrasil/types/SignInTypes'
import Box from './Box'

export function SignUpLink({ onClickSignup }: SignInViewProps) {
  const { isMobile } = useResponsive()
  return (
    <Box
      fullWidth
      top
      bottom
      spacing={tinySpacing}
      direction={isMobile ? 'column' : 'row'}
      align="center"
      justify="center"
    >
      <FigmaText textKey={Texts.signInNewToObp} />
      <Box left spacing={tinySpacing} link onClick={onClickSignup}>
        <FigmaText textKey={Texts.signInTryForFree} />
      </Box>
    </Box>
  )
}

export function SignInLink({ onTakeMeBack }: SignInViewProps) {
  return (
    <Box fullWidth top bottom spacing={tinySpacing} direction="row" align="center" justify="center">
      <FigmaText textKey={Texts.signUpAlreadyUser} />
      <Box left spacing={tinySpacing} link onClick={onTakeMeBack}>
        <FigmaText textKey={Texts.signUpSignInLink} />
      </Box>
    </Box>
  )
}
