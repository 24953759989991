import styled from '@emotion/styled'
import React, { useState } from 'react'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'
import { DashboardListAddBox } from './Icons'
import { OpacityTransitionContainer } from './OpacityTransitionContainer'

export const StrategyViewObjectiveListAddLine = ({ size, onClick }: { size?: number; onClick?: () => unknown }) => {
  const [showLine, setShowLine] = useState(false)
  const onMouseEnter = () => setShowLine(true)
  const onMouseLeave = () => setShowLine(false)
  const _onClick = showLine ? onClick : undefined
  return (
    <OpacityTransitionContainer
      fullWidth
      pointer
      onClick={_onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box fullWidth direction="row" align="center">
        <DashboardListAddBox size={size} />
        <Line />
      </Box>
    </OpacityTransitionContainer>
  )
}

const Line = styled(Box)`
  height: 3px;
  width: 100%;
  border-bottom: 3px solid ${Colors.primaryBase};
`
