import styled from '@emotion/styled'
import { Formik } from 'formik'
import invariant from 'invariant'
import * as Yup from 'yup'
import { bigSpacing, defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useNotification from '../hooks/useNotification'
import { getNewAllItems, getNewGrowthIndicators, getNewOrganization } from '../libs/CreateOrganizationHelper'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH } from '../libs/HardCodedSizes'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import {
  updateActivity,
  updateGrowthIndicator,
  updateIndicator,
  updateObjective,
  updateOrganization,
  updateUser
} from '../ygdrasil/libs/DBApiHandler'
import { useGrowthIndicators, useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation(),
  domain: RequiredTextValidation()
})

export default function ModalCreateOrganizationForm({ onClose }: { onClose: () => void }) {
  const { state, setState } = useAppState()
  const { user } = state
  const notification = useNotification()
  const allItems = useAllItems()
  const { data: growthIndicartors = [] } = useGrowthIndicators(state)
  const { data: org } = useOrganization(state)

  const onSubmit = ({ domain, name }: typeof initialValues) => {
    invariant(org, 'org is required')
    const organization = getNewOrganization({ ...org, domains: [domain], name })
    return updateOrganization(organization)
      .then((org) => updateUser({ ...user, organizationIds: [...user.organizationIds, org._id] }).then(() => org))

      .then((org) => {
        const { allActivities, allIndicators, allObjectives } = getNewAllItems(allItems, org)
        const newGrowthIndicators = getNewGrowthIndicators(growthIndicartors, org)

        allObjectives.forEach(updateObjective)
        allIndicators.forEach(updateIndicator)
        allActivities.forEach(updateActivity)

        newGrowthIndicators.forEach(updateGrowthIndicator)

        onClose()
        notification.success()
        setTimeout(() => setState({ ...state, selectedOrganizationId: org._id }))
      })
      .catch((err) => captureAndNotifyError(err))
  }

  const initialValues = {
    name: `${org?.name} - Copy`,
    domain: ''
  }

  if (!org) return null

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
      {(formProps) => (
        <Container fullWidth align="center">
          <Box fullWidth align="center" spacing={bigSpacing}>
            <Box bottom>
              <FigmaText textKey={Texts.dialogCreateNewOrgHeader} />
            </Box>
            <Box fullWidth bottom spacing={smallSpacing}>
              <TextFieldWithLabelFormik
                labelTextKey={Texts.dialogCreateNewOrgNameLabel}
                formProps={formProps}
                name="name"
                component={TextFieldOutlined}
                fullWidth
              />
            </Box>
            <Box fullWidth bottom spacing={smallSpacing}>
              <TextFieldWithLabelFormik
                labelTextKey={Texts.dialogCreateNewOrgDomainLabel}
                formProps={formProps}
                name="domain"
                component={TextFieldOutlined}
                fullWidth
              />
            </Box>
            <Box direction="row" align="center" justify="flex-end" fullWidth>
              <Box right spacing={tinySpacing}>
                <Button
                  onClick={onClose}
                  textKey={Texts.cancelButtonText}
                  buttonType="secondary"
                  spacing={tinySpacing}
                />
              </Box>
              <Button onClick={formProps.handleSubmit as any} spacing={defaultSpacing}>
                <FigmaTextLoader textKey={Texts.okButtonText} loading={formProps.isSubmitting} />
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </Formik>
  )
}

const Container = styled(Box)`
  min-width: ${EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH};
`
