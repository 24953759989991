import { ServerErrorDetails } from '../types/ServerTypes'
import CloudFunction from '../enums/CloudFunctions'
import { State } from '../../hooks/useAppState'

export type CloudFunctionErrorType = { code: string; message: string; details?: ServerErrorDetails }

export default class CloudFunctionError extends Error {
  cloudFunctionError: CloudFunctionErrorType
  fnName: CloudFunction
  state: State

  constructor(cloudFunctionError: CloudFunctionErrorType, fnName: CloudFunction, state: State) {
    super(cloudFunctionError.message)
    this.cloudFunctionError = cloudFunctionError
    this.fnName = fnName
    this.state = state
  }
}
