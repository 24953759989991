import { JiraIssue } from '../types/Jira'

export const DEFAULT_JIRA_OPTIONS = {
  protocol: 'https',
  // host: 'our-big-picture.atlassian.net',
  // username: 'anders.back@ourbigpicture.se',
  apiVersion: '2',
  strictSSL: true
  // jqlQuery: 'summary ~ Johan AND project = Sales'
}

export const getJiraIssueUrl = (jiraIssueKey: string, { host }: { host: string }) =>
  `https://${host}/browse/${jiraIssueKey}`
