import { useEffect, useState } from 'react'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import { PartialConfig } from '../ygdrasil/types/types'
import { BusinessPlanDrawer } from './BusinessPlanDrawer'
import { BusinessPlanDrawerFormType } from './BusinessPlanDrawerForm'
import { OurDrawer } from './Drawers'

const EVENT_NAME = Events.OPEN_BUSINESS_PLAN_DRAWER

export const openDrawer = (config: PartialConfig, form?: BusinessPlanDrawerFormType) =>
  !!config?.enableEditBusinessView && eventEmitter.emit(EVENT_NAME, form)

export default function DrawerGlobalBusinessPlan() {
  const [form, setForm] = useState<BusinessPlanDrawerFormType>()
  const [visible, setVisible] = useState(false)
  const onClose = () => setVisible(false)

  useEffect(() => {
    eventEmitter.on(EVENT_NAME, (form?: BusinessPlanDrawerFormType) => {
      setVisible(true)
      setForm(form)
    })

    return () => {
      eventEmitter.removeAllListeners(EVENT_NAME)
    }
  }, [])

  return <OurDrawer open={visible} content={<BusinessPlanDrawer onClose={onClose} form={form} />} onClose={onClose} />
}
