import useAppState from '../hooks/useAppState'
import { useStorageUrl } from '../ygdrasil/hooks/useStorageUrl'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Box from './Box'
import styled from '@emotion/styled'
import { OrganizationLogoEmptyState } from './OrganizationLogoEmptyState'
import { isPropValid } from '../libs/EmotionHelper'

type Props = { onClick: (e: any) => unknown; size: number }
export default function OrganizationLogo(props: Props) {
  const { onClick, size } = props
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const { url, isLoading } = useStorageUrl(org?.logoPath)
  if (!org) return <LogoContainer size={size} />
  if (isLoading) return <LogoContainer size={size} />
  return (
    <LogoContainer align="center" justify="center" size={size}>
      <Box link onClick={onClick}>
        {url && <img src={url} alt="Uploaded Company Logo" width={`${size}px`} />}
        {!url && <OrganizationLogoEmptyState size={size} />}
      </Box>
    </LogoContainer>
  )
}

const LogoContainer = styled(Box, { shouldForwardProp: isPropValid })<{ size: number }>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`
