enum PublicAssets {
  APPLICATION_LOGO = '/applicationLogo.png',
  APPLICATION_LOGO_SMALL = '/applicationLogoSmall.png',
  THREE_HEADS = '/threeHeads.png',
  FLAG_ICON = '/flagIcon.png',
  GREEN_CHECK_ICON = '/greenCheckIcon.png',
  MOBILE_BACKGROUND = '/mobileBackgroundSaga.png'
}

export default PublicAssets
