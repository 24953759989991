import useAppState from '../hooks/useAppState'
import { getActivityItems } from '../libs/ActivityBoardHelper'
import { useUsersForOrganization } from '../ygdrasil/libs/QueryHooks'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { User } from '../ygdrasil/types/types'
import Box from './Box'
import { StrategyViewObjectiveTreeFilterUserView } from './StrategyViewObjectiveTreeFilterUserView'

export function ActivityBoardUserFilter({
  item,
  selectedUserId,
  onClick
}: {
  item: Item
  selectedUserId?: string
  onClick: (userId?: string) => unknown
}) {
  const { state } = useAppState()
  const { data: users = [] } = useUsersForOrganization(state)
  const _users = useUsersForItem(item, users)

  const _onClick = (userId?: string) => (selectedUserId !== userId ? onClick(userId) : onClick(undefined))
  return (
    // super ugly spacing her under however this is to slove the case when the filter should not be shown§
    <Box direction="row" align="center">
      {_users.map((user, key) => {
        return (
          <StrategyViewObjectiveTreeFilterUserView
            key={key}
            user={user}
            onClick={() => _onClick(user._id)}
            shouldBlur={!!selectedUserId && user._id !== selectedUserId}
          />
        )
      })}
    </Box>
  )
}

function useUsersForItem(item: Item, users: User[]): User[] {
  const leadUserId = getActivityItems({ item })
    .map((activity) => activity.data.leadUserId)
    .map((leadUserId) => users.find((user) => user._id === leadUserId) as User)

  return users.filter((user) => leadUserId.includes(user))
}
