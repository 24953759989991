import { useEffect } from 'react'
import { useStorageItem } from './useStorageItem'
import { StorageKey, StorageType } from '../libs/useLocalStorageItemHelper'
import useQueryParams, { SignInPageQueryParams } from '../ygdrasil/hooks/useQueryParams'
import { SignInState } from '../ygdrasil/types/SignInTypes'
import { DEFAULT_SIGN_IN_STATE } from '../libs/SignInHelper'
import { getDefaultSignInState } from './useISignInStateHelper'

export default function useSignInState(signInStateFromProps?: Partial<SignInState>) {
  const queryParams = useQueryParams<SignInPageQueryParams>()

  const { storageItem: localStorageItem, setStorageItem: setLocalStorageItem } = useStorageItem(
    StorageKey.SIGN_IN_STATE,
    DEFAULT_SIGN_IN_STATE,
    {
      storageType: StorageType.SESSION_STORAGE
    }
  )

  useEffect(() => {
    const defaultSignInState: SignInState = getDefaultSignInState(queryParams, localStorageItem, signInStateFromProps)
    setSignInState(defaultSignInState)
  }, [])

  const setSignInState = (signInState: SignInState) => setLocalStorageItem(signInState)

  const signInState: SignInState = localStorageItem

  return { signInState, setSignInState }
}
