import { Formik } from 'formik'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import { bigSpacing, defaultSpacing } from '../enums/Spacings'
import { logOnboardingEvent } from '../ygdrasil/libs/FirebaseHelper'
import { ONBOARDING_NAME_MOBILE_PICTURE_OPACITY, ONBOARDING_NAME_MOBILE_PICTURE_POSITION } from '../libs/HardCodedSizes'
import { RequiredNameValidationDeprecated } from '../libs/ValidationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { OnboardingViewProps } from '../ygdrasil/types/Onboarding'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import OnboardingMobileWrapper from './OnboardingMobileWrapper'
import OnboardingPaginationComponent from './OnboardingPaginationComponent'
import TextFieldFormikErrorComponent from './TextFieldFormikErrorComponent'
import { TextFieldMobile } from './TextFieldMobile'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

const ValidationSchema = Yup.object().shape({
  nameOfUser: RequiredNameValidationDeprecated('name')
})

export default function OnboardingNameMobile({ onPressContinue, state }: OnboardingViewProps) {
  const initialValues = {
    nameOfUser: state.data.nameOfUser || ''
  }

  const onSubmit = (data) => onPressContinue({ ...state, data: { ...state.data, ...data } })

  useEffect(() => {
    logOnboardingEvent(AnalyticsEvent.ONBOARDING_STARTED, state)
  }, [])

  return (
    <OnboardingMobileWrapper
      content={() => (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
          {(formProps) => (
            <Box align="center" fullWidth top spacing={defaultSpacing}>
              <Box fullWidth top spacing={bigSpacing}>
                <StyledBox position="absolute" right top spacing={bigSpacing}>
                  <FigmaImageContainer imageKey={Images.imageArrows} />
                </StyledBox>
                <CenterBox position="absolute" fullWidth>
                  <Box fullWidth align="center" top spacing={bigSpacing}>
                    <Box fullWidth fullPadding spacing={bigSpacing}>
                      <TextFieldWithLabelFormik
                        labelTextKey={Texts.onboardingMobileUserNameHelperText}
                        formProps={formProps}
                        name="nameOfUser"
                        component={TextFieldMobile}
                        fullWidth
                        isMobile
                        isWhite
                        ErrorComponent={TextFieldFormikErrorComponent}
                      />
                    </Box>
                    <Box fullWidth align="center" fullPadding spacing={bigSpacing}>
                      <Button fullWidth buttonType="white" onClick={formProps.handleSubmit as any}>
                        <FigmaText textKey={Texts.onboardingMobileContinueButtonText} />
                      </Button>
                    </Box>
                    <OnboardingPaginationComponent
                      opacityFirstDot={100}
                      opacitySecondDot={40}
                      opacityThirdDot={40}
                      opacityForthDot={40}
                    />
                  </Box>
                </CenterBox>
              </Box>
            </Box>
          )}
        </Formik>
      )}
    />
  )
}

const StyledBox = styled(Box)`
  opacity: ${ONBOARDING_NAME_MOBILE_PICTURE_OPACITY}%;
  top: ${ONBOARDING_NAME_MOBILE_PICTURE_POSITION}%;
`

const CenterBox = styled(Box)`
  top: 25%;
`
