import { Popper } from '@mui/material'
import { useRef, useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import ZIndices from '../enums/Zindices'
import { getActivityStatusColor } from '../ygdrasil/libs/ActivityHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { Activity } from '../ygdrasil/types/types'
import Box from './Box'
import { GraphActivityListColumnItem } from './GraphActivityListColumnItem'
import { ActivityIcon, DEFAULT_ICON_SIZE } from './Icons'

const MAX_ACTIVITIES_PER_COLUMN = 3
export function GraphActivityListColumn({ items }: { items: Item[] }) {
  const ref = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const onMouseEnter = (e) => setAnchorEl(ref.current)
  const onMouseLeave = () => setAnchorEl(null)
  return (
    <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ width: DEFAULT_ICON_SIZE }}>
      <Box position="relative">
        {items
          .filter((_, i) => i < MAX_ACTIVITIES_PER_COLUMN)
          .map((child: Item, index) => {
            return (
              <Box key={index} position="absolute" style={{ top: (index * DEFAULT_ICON_SIZE) / 3 }}>
                <ActivityIcon color={getActivityStatusColor(child.data.data as Activity)} />
              </Box>
            )
          })}
        <Box position="absolute" ref={ref} style={{ top: smallSpacing }} />
      </Box>

      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: ZIndices.modal }}>
        {items.map((child, key) => (
          <GraphActivityListColumnItem item={child} key={key} />
        ))}
      </Popper>
    </Box>
  )
}
