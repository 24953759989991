import ReactPlayerFile from 'react-player/file'
import ReactPlayerYoutube from 'react-player/youtube'
import ReactPlayerVimeo from 'react-player/vimeo'
import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import { VIDEO_PLAYER_HEIGHT } from '../libs/HardCodedSizes'
import Box from './Box'
import { CloseIcon } from './Icons'
import Modal from './Modal'
import { LinkStyles } from './StyledComponents'
import { isVimeo, isYoutube } from '../libs/VideoHelper'

const WIDTH = (VIDEO_PLAYER_HEIGHT * 16) / 9

type Props = { url: string; onClose: () => unknown }

export default function _ModalVideo({ url, onClose }: Props) {
  return (
    <Modal onClose={onClose}>
      <Container>
        <ModalBigWhiteCrossCloseContainer onClose={onClose} />
        <Player url={url} />
      </Container>
    </Modal>
  )
}

const Player = function ({ url }: { url: string }) {
  if (isYoutube(url)) return <ReactPlayerYoutube url={url} width="100%" height="100%" playing />
  if (isVimeo(url)) return <ReactPlayerVimeo url={url} width="100%" height="100%" playing />

  return <ReactPlayerFile url={url} width="100%" height="100%" />
}

const Container = styled(Box)`
  position: relative;
  width: ${WIDTH}px;
  height: ${VIDEO_PLAYER_HEIGHT}px;
`

const CloseIconContainer = styled.div`
  position: absolute;
  right: -100px;
  ${LinkStyles};
`

function ModalBigWhiteCrossCloseContainer({ onClose }: { onClose: () => unknown }) {
  return (
    <CloseIconContainer onClick={onClose}>
      <CloseIcon color={Colors.white} size={50} />
    </CloseIconContainer>
  )
}
