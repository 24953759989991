enum AnalyticsEvent {
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  INVITE = 'invite',
  ONBOARDING_COMPLETE = 'onboarding_complete',
  ONBOARDING_STARTED = 'ONBOARDING_STARTED',
  ONBOARDING_PAGE1_SKIP = 'ONBOARDING_PAGE1_SKIP',
  ONBOARDING_PAGE1_CONTINUE = 'ONBOARDING_PAGE1_CONTINUE',
  ONBOARDING_PAGE2_SKIP = 'ONBOARDING_PAGE2_SKIP',
  ONBOARDING_PAGE2_CONTINUE = 'ONBOARDING_PAGE2_CONTINUE',
  ONBOARDING_PAGE3_SKIP = 'ONBOARDING_PAGE3_SKIP',
  ONBOARDING_PAGE3_CONTINUE = 'ONBOARDING_PAGE3_CONTINUE',
  ONBOARDING_PAGE4_SKIP = 'ONBOARDING_PAGE4_SKIP',
  ONBOARDING_PAGE4_CONTINUE = 'ONBOARDING_PAGE4_CONTINUE',
  ONBOARDING_PAGE5_SKIP = 'ONBOARDING_PAGE5_SKIP',
  ONBOARDING_PAGE5_CONTINUE = 'ONBOARDING_PAGE5_CONTINUE',
  DASHBOARD_ADDVISION = 'DASHBOARD_ADDVISION',
  DASHBOARD_ADDMISSION = 'DASHBOARD_ADDMISSION',
  DASHBOARD_ADDOBJECTIVE = 'DASHBOARD_ADDOBJECTIVE',
  DASHBOARD_EDITOBJECTIVE = 'DASHBOARD_EDITOBJECTIVE',
  DASHBOARD_EDITVISION = 'DASHBOARD_EDITVISION',
  DASHBOARD_EDITMISSION = 'DASHBOARD_EDITMISSION',
  DASHBOARD_ADDINDICATOR = 'DASHBOARD_ADDINDICATOR',
  OBJECTIVE_ADDINDICATOR = 'OBJECTIVE_ADDINDICATOR',
  DASHBOARD_EDITINDICATOR = 'DASHBOARD_EDITINDICATOR',
  OBJECTIVE_EDITINDICATOR = 'OBJECTIVE_EDITINDICATOR',
  DASHBOARD_INVITECOLLEAGUE = 'DASHBOARD_INVITECOLLEAGUE',
  OBJECTIVELIST_EDITOBJECTIVE = 'OBJECTIVELIST_EDITOBJECTIVE',
  OBJECTIVELIST_ADDINDICATOR = 'OBJECTIVELIST_ADDINDICATOR',
  EDIT_OBJECTIVE_QUICK = 'Quick Edit - Objective',
  EDIT_OBJECTIVE_MENU = 'Menu Edit - Objective',
  EDIT_INDICATOR_QUICK = 'Quick Edit - Indicator',
  EDIT_INDICATOR_MENU = 'Menu Edit - Indicator'
}

export default AnalyticsEvent
