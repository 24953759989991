import Menu from '@mui/material/Menu'
import { MenuItem } from './MenuItem'
import { useState } from 'react'
import useAppState from '../hooks/useAppState'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import useLoggedInConfig, { isLimitedUser } from '../ygdrasil/hooks/useLoggedInConfig'
import { updateUser } from '../ygdrasil/libs/DBApiHandler'
import { deactivateUser } from '../ygdrasil/libs/ProcessHandler'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getLeadObjectsForUser } from '../ygdrasil/libs/SettingsNotificationsHelper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { OrgUserRole, OrgUserStatus, User } from '../ygdrasil/types/types'
import { ModalDeactivateUser } from './ModalDeactivateUser'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  user: User
}

export default function SettingsMyOrganizationRowMenu({ anchorEl, onClose, user }: Props) {
  const { state } = useAppState()
  const { selectedOrganizationId } = state
  const { data: { enableLimitedUser } = { enableLimitedUser: false } } = useOrganization(state)
  const [showModal, setShowModal] = useState(false)
  const allItems = useAllItems()
  const config = useLoggedInConfig()
  const logEvent = useLogEvent()
  const onCloseModal = () => {
    setShowModal(false)
    onClose()
  }

  const onClickDisable = () => {
    const leadObjects = getLeadObjectsForUser(allItems, user)
    if (leadObjects.objectives.length === 0 && leadObjects.keyResults.length === 0)
      return setStatus(OrgUserStatus.DISABLED)
    setShowModal(true)
  }

  const onClickEnable = () => setStatus(OrgUserStatus.ACTIVE)

  const setStatus = (status: OrgUserStatus) => {
    const _user = {
      ...user,
      orgUserInfo: {
        ...user.orgUserInfo,
        [selectedOrganizationId]: { ...user.orgUserInfo?.[selectedOrganizationId], status }
      }
    }

    if (status === OrgUserStatus.DISABLED) deactivateUser(_user, logEvent)
    else updateUser(_user)
    onClose()
  }

  const onChangePermissions = (role: OrgUserRole) => {
    const _user = {
      ...user,
      orgUserInfo: {
        ...user.orgUserInfo,
        [selectedOrganizationId]: { ...user.orgUserInfo?.[selectedOrganizationId], role }
      }
    }

    updateUser(_user)
    onClose()
  }

  const status = user.orgUserInfo?.[state.selectedOrganizationId]?.status
  const isEnabled = !status || status === OrgUserStatus.ACTIVE
  const isDisabled = [OrgUserStatus.DISABLED, OrgUserStatus.DELETE].includes(status as OrgUserStatus)
  const _isLimitedUser = isLimitedUser(state, user)
  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} disableAutoFocusItem>
        {isDisabled && (
          <MenuItem onClick={onClickEnable}>{getFigmaText(Texts.adminUserListEnableAccountMenuText)}</MenuItem>
        )}
        {isEnabled && (
          <MenuItem onClick={onClickDisable}>{getFigmaText(Texts.adminUserListDisableAccountMenuText)}</MenuItem>
        )}
        {!_isLimitedUser && enableLimitedUser && (
          <MenuItem onClick={() => onChangePermissions(OrgUserRole.LIMITED)}>
            {getFigmaText(Texts.adminUserListReadOnlyUserMenuText)}
          </MenuItem>
        )}
        {_isLimitedUser && enableLimitedUser && (
          <MenuItem onClick={() => onChangePermissions(OrgUserRole.FULL)}>
            {getFigmaText(Texts.adminUserListFullUserMenuText)}
          </MenuItem>
        )}
        {config.enableDeleteAccount && config.enableSuperAdmin && (
          <MenuItem>{getFigmaText(Texts.adminUserListDeleteAccountMenuText)}</MenuItem>
        )}
      </Menu>
      {showModal && (
        <ModalDeactivateUser
          user={user}
          onClose={onCloseModal}
          onClickConfirm={() => setStatus(OrgUserStatus.DISABLED)}
        />
      )}
    </div>
  )
}
