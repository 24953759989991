import { Formik } from 'formik'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import FigmaText from '../ygdrasil/components/FigmaText'
import { ENTER_KEYCODE } from '../ygdrasil/libs/Consts'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { OnboardingViewProps } from '../ygdrasil/types/Onboarding'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { ONBOARDING_MAX_WIDTH_MOBILE } from '../libs/HardCodedSizes'
import { RequiredNameValidationDeprecated } from '../libs/ValidationHelper'
import Box from './Box'
import Button from './Button'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

const ValidationSchema = Yup.object().shape({
  nameOfUser: RequiredNameValidationDeprecated(getFigmaText(Texts.onboardingUserNameError))
})

export default function OnboardingName({ onPressContinue, state }: OnboardingViewProps) {
  const initialValues = {
    nameOfUser: ''
  }

  const onSubmit = (data) => onPressContinue({ ...state, data: { ...state.data, ...data } })

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
      {(formProps) => (
        <Box fullWidth align="center">
          <Container fullWidth top align="center" spacing={bigSpacing}>
            <Box fullWidth bottom spacing={bigSpacing}>
              <FigmaText textKey={Texts.onboardingWelcomeHeader} />
            </Box>

            <Box fullWidth bottom spacing={smallSpacing}>
              <TextFieldWithLabelFormik
                labelTextKey={Texts.onboardingUserNameLabel}
                formProps={formProps}
                name="nameOfUser"
                component={TextFieldOutlined}
                fullWidth
                onKeyDown={(e) => e.keyCode === ENTER_KEYCODE && formProps.handleSubmit(e)}
              />
            </Box>
            <Box fullWidth align="center">
              <Button onClick={formProps.handleSubmit as any} fullWidth>
                <FigmaText textKey={Texts.onboardingGetStartedButtonText} />
              </Button>
            </Box>
          </Container>
        </Box>
      )}
    </Formik>
  )
}

const Container = styled(Box)`
  max-width: ${ONBOARDING_MAX_WIDTH_MOBILE};
`
