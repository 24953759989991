/* eslint-disable prefer-const */
import styled from '@emotion/styled'
import { smallBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { GraphProps } from '../ygdrasil/hooks/useGraph'
import { GRAPH_POPOVER_HEIGHT, GRAPH_POPOVER_WIDTH } from '../libs/HardCodedSizes'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'
import { GraphDeprecated } from './GraphDeprecated'

export function GraphPopover(props: GraphProps) {
  return (
    <Container top spacing={smallSpacing}>
      <GraphDeprecated {...props} width={GRAPH_POPOVER_WIDTH} />
    </Container>
  )
}

const Container = styled(Box)`
  min-width: ${GRAPH_POPOVER_WIDTH}px;
  min-height: ${GRAPH_POPOVER_HEIGHT}px;
  border: 1px solid ${Colors.primaryBase};
  background-color: ${Colors.surface};
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.12);
  border-radius: ${smallBorderRadius};
`
