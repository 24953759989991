import Box from './Box'
import { TextWithLine } from './TextWithLine'
import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { useFigmaTextForStrategyModel } from './FigmaTextForStrategyModel'

export const RoadmapNonStrategicObjectiveLine = () => {
  const text = useFigmaTextForStrategyModel({ textKey: Texts.roadmapSeparatorNonStrategicObjectives })
  return (
    <TextWithLine
      textKey={Texts.roadmapSeparatorNonStrategicObjectives}
      LineComponent={NonStrategicObjectivesLine}
      text={text}
    />
  )
}

const NonStrategicObjectivesLine = styled(Box)`
  height: 1px;
  border-bottom: 1px dashed ${Colors.primaryBase};
  width: 100%;
  align-items: center;
  flex-direction: row;
`
