import styled from '@emotion/styled'
import { useState } from 'react'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import {
  RICHTEXT_EDITOR_TOOLBAR_HEIGHT,
  RICHTEXT_LINK_POPOVER_HEIGHT,
  RICHTEXT_LINK_POPOVER_WIDTH
} from '../libs/HardCodedSizes'
import { Editor, EditorState } from '../libs/WYSIWYGEditor'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'
import { NormalFontWight } from './StyledComponents'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'

export default function RichTextEditor({
  value,
  onChange,
  onBlur,
  placeHolderTextKey
}: {
  value: EditorState
  onChange: (s: EditorState) => void
  onBlur?: () => void
  placeHolderTextKey?: AntiloopTextType
}) {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <Container isFocused={isFocused} fullWidth>
      <Editor
        editorState={value}
        toolbarClassName="rich-text-toolbar"
        wrapperClassName="demo-wrapper"
        editorClassName="rich-text-editor"
        onEditorStateChange={onChange}
        placeholder={!!placeHolderTextKey ? getFigmaText(placeHolderTextKey) : undefined}
        onBlur={onBlur}
        toolbarOnFocus
        stripPastedStyles
        toolbar={{
          options: ['inline', 'list', 'link', 'emoji'],
          inline: {
            options: ['bold', 'italic', 'underline']
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: 'custom-link-popover-class',
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink'],
            linkCallback: undefined
          }
        }}
      />
    </Container>
  )
}

const Container = styled(Box)<{ isFocused: boolean }>`
  ${NormalFontWight};
  .custom-link-popover-class {
    height: ${RICHTEXT_LINK_POPOVER_HEIGHT}px;
    left: -${RICHTEXT_LINK_POPOVER_WIDTH / 3}px;
  }

  .rdw-link-modal-target-option {
    display: none;
  }

  .rich-text-editor {
    border-radius: ${tinyBorderRadius};
    border: 1px solid ${Colors.neutralVariant80};
    padding: ${smallSpacing};
    background: ${Colors.whiteBg};

    :hover {
      border: 1px solid ${Colors.primaryBase};
    }
  }

  & .public-DraftStyleDefault-block {
    margin-top: 0;
    margin-bottom: 0;
  }

  margin-top: -${RICHTEXT_EDITOR_TOOLBAR_HEIGHT}px;
  margin-bottom: ${RICHTEXT_EDITOR_TOOLBAR_HEIGHT}px;
`
