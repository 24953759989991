import styled from '@emotion/styled'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { PERIOD_SELECTOR_MODAL_MIN_WIDTH } from '../libs/HardCodedSizes'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import { ENTER_KEYCODE } from '../ygdrasil/libs/Consts'
import { createDashboardLink, updateDashboardLink } from '../ygdrasil/libs/DBApiHandler'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { DashboardLink } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import Modal, { ModalProps } from './Modal'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation()
})

type Props = ModalProps & { dashboardLink?: Partial<DashboardLink>; onCreateUpateDashboardLink: () => void }
export function ModalEditDashboardLink(props: Props) {
  const { state, setState } = useAppState()
  const { dashboardLink, ...rest } = props
  const initialValues = { name: dashboardLink?.name || '' }
  const isNew = !dashboardLink?._id
  const headerTextKey = isNew ? Texts.createDashboardDialogHeader : Texts.updateDashboardDialogHeader

  const onSubmit = (values: typeof initialValues) =>
    Promise.resolve()
      .then(() => {
        const _dasboardLink = {
          includedIndicatorIds: [],
          ...dashboardLink,
          name: values.name,
          organizationId: state.selectedOrganizationId
        }

        if (isNew) return createDashboardLink(_dasboardLink, state)
        return updateDashboardLink(_dasboardLink as DashboardLink)
      })
      .then(({ _id: selectedDashboardLinkId }) => {
        props.onCreateUpateDashboardLink()
        setState({ ...state, selectedDashboardLinkId })
      })

  return (
    <Modal {...rest}>
      <Container>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
          enableReinitialize
        >
          {(formProps) => {
            return (
              <Box fullWidth align="center">
                <Box fullWidth align="center" spacing={bigSpacing}>
                  <Box bottom>
                    <FigmaTextForStrategyModel textKey={headerTextKey} />
                  </Box>
                  <Box fullWidth bottom spacing={smallSpacing}>
                    <TextFieldWithLabelFormik
                      labelTextKey={Texts.inviteDialogEmailLabel}
                      labelText={getFigmaText(Texts.onboardingMobileUserNameLabel)}
                      formProps={formProps}
                      name="name"
                      component={TextFieldOutlined}
                      fullWidth
                      placeholderTextKey={Texts.allInputTypeHereText}
                      onKeyDown={({ keyCode }) => keyCode === ENTER_KEYCODE && formProps.handleSubmit()}
                    />
                  </Box>

                  <Box direction="row" align="center" justify="flex-end" fullWidth top>
                    <Box right spacing={tinySpacing}>
                      <Button
                        onClick={props.onClose}
                        textKey={Texts.cancelButtonText}
                        buttonType="secondary"
                        spacing={tinySpacing}
                      />
                    </Box>
                    <Button
                      onClick={formProps.handleSubmit as any}
                      spacing={tinySpacing}
                      textKey={Texts.continueButtonText}
                    />
                  </Box>
                </Box>
              </Box>
            )
          }}
        </Formik>
      </Container>
    </Modal>
  )
}

const Container = styled(Box)`
  min-width: ${PERIOD_SELECTOR_MODAL_MIN_WIDTH}px;
`
