/* eslint-disable prettier/prettier */
import { DEFAULT_STATE, State } from "../hooks/useAppState";

const strategicViewState : Partial<State> = {
  showGrowthView: false,
  selectedTeamId: null
}

export const getStateForSettingsView = (state: State): State => ({...state, ...getDefaultState(), showSettings: true})
export const getStateForStrategicView = (state: State): State => ({...state, ...getDefaultState(), ...strategicViewState})
export const getStateForDashboard = (state: State): State => ({...state, ...getDefaultState(), showDashboard: true})

export const getStateForMyActivities = (state: State): State => ({...state, ...getDefaultState(), selectedTeamId: null, showMyActivities: true})

export const getStateForTeamView = (selectedTeamId: string, state: State): State => ({...state, ...getDefaultState(), ...strategicViewState, selectedTeamId})
export const getStateForGrowthView = (state: State): State => ({...state, ...getDefaultState(), ...strategicViewState, showGrowthView: true})
export const getStateForBusinessPlan = (state: State): State => ({...state, ...getDefaultState(), ...strategicViewState, showBusinessPlan: true})

export const isStrategicView = (state: State) => {
  const strategicViewState = getStateForStrategicView(state)
  return Object.keys(strategicViewState)
  .filter(key => typeof state[key] !== 'object')
  .every(key => state[key] === strategicViewState[key])
} 

export const getDefaultState = (): Partial<State> => {
  const {user, firebaseUser, selectedDashboardView, selectedOrganizationId, selectedTeamId, showVisionAndMission, shouldShowExpandedMenu, ...defaultState} = DEFAULT_STATE
  return defaultState
}