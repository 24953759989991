import styled from '@emotion/styled'
import { bigSpacing, defaultSpacing, mediumSpacing, regularSpacing, tinySpacing } from '../enums/Spacings'
import { ONBOARDING_MAX_WIDTH_USP_DESKTOP } from '../libs/HardCodedSizes'
import { getSignUpUSPTexts } from '../libs/SignInHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { SignInViewProps } from '../ygdrasil/types/SignInTypes'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import SignInSignUpLayout from './SignInSignUpLayout'
import SignInSignUpWithEmailForm from './SignInSignUpWithEmailForm'
import { SignInWithAuthProviders } from './SignInWithAuthProviders'
import { SignInLink } from './SignUpLink'
import { GreyLineMobile } from './StyledComponents'

export default function SignInSignUp(props: SignInViewProps) {
  const signUpUSPTexts = getSignUpUSPTexts()
  return (
    <SignInSignUpLayout headingTextKey={Texts.signupHeader} subheadingTextKey={Texts.signupInfo}>
      <Box fullWidth direction="row" top gap={bigSpacing} bottom align="center" justify="center">
        <ContentLeftBox align="center" fullPadding spacing={regularSpacing}>
          <FigmaImageContainer imageKey={Images.signUpIllustration} />
          <Box fullWidth>
            {!!signUpUSPTexts &&
              signUpUSPTexts.map(({ textKey }, index) => (
                <Box key={index} direction="row" align="center" gap={tinySpacing}>
                  <FigmaImageContainer imageKey={Images.greenCheckIcon} />
                  <FigmaText textKey={textKey} />
                </Box>
              ))}
          </Box>
        </ContentLeftBox>
        <Box>
          <SignInWithAuthProviders {...props} />
          <Box top bottom direction="row" align="center" fullWidth spacing={tinySpacing}>
            <GreyLineMobile />
            <Box left right spacing={mediumSpacing} style={{ marginRight: tinySpacing }}>
              <FigmaText textKey={Texts.allSeparatorOr} />
            </Box>
            <GreyLineMobile />
          </Box>
          <Box fullWidth top bottom spacing={tinySpacing}>
            <SignInSignUpWithEmailForm {...props} />
          </Box>
        </Box>
      </Box>
      <SignInLink {...props} />
    </SignInSignUpLayout>
  )
}

const ContentLeftBox = styled(Box)`
  width: ${ONBOARDING_MAX_WIDTH_USP_DESKTOP};
  background-color: ${Colors.neutral97};
  border-radius: ${regularSpacing};
  padding: ${defaultSpacing} ${regularSpacing};
`
