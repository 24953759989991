import React from 'react'
import ConnectionType from '../enums/ConnectionType'
import { Printable } from '../libs/TextRepository'
import { Connection, Datasource, IdName, Value } from './types'

export enum EditDatasourceMachineSteps {
  EDIT_CONNECTION,
  EDIT_SHEET,
  EDIT_GOOGLE_DATA_SOURCE,
  VERIFY_DATA
}

export type EditDatasourceMachineType = {
  [EditDatasourceMachineSteps.EDIT_CONNECTION]: React.FC<EditDatasourceMachineViewProps>
  [EditDatasourceMachineSteps.EDIT_SHEET]: React.FC<EditDatasourceMachineViewProps>
  [EditDatasourceMachineSteps.EDIT_GOOGLE_DATA_SOURCE]: React.FC<EditDatasourceMachineViewProps>
  [EditDatasourceMachineSteps.VERIFY_DATA]: React.FC<EditDatasourceMachineViewProps>
}

export type EditDatasourceMachineState = {
  data: {
    connectionName?: string
    connection?: Connection
    dataSource?: Partial<Datasource>
    selectedDriveItem?: IdName
    selectedSheet?: IdName
    rows: any[]
    values: Value[]
    connectionType: ConnectionType
  }
  step: EditDatasourceMachineSteps
  isLoading: boolean
}

export type EditDatasourceMachineViewProps = {
  state: EditDatasourceMachineState
  onPressContinue: (state: EditDatasourceMachineState) => void
  onPressSkip: (state: EditDatasourceMachineState) => void
  onClickBack: (e) => void
  onClose: (e) => void
  setMachineState: (state: EditDatasourceMachineState) => void
  isValidData: (rows: Printable[][]) => boolean
}
