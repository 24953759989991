import styled from '@emotion/styled'
import Box from './Box'

export const OpacityTransitionContainer = styled(Box)<{ delay?: number }>`
  opacity: 0;
  transition: opacity 0s linear;

  :hover {
    opacity: 1;
    transition: opacity 0.5s linear;
    transition-delay: ${({ delay }) => delay || 0.5}s;
  }
`
