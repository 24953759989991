import styled from '@emotion/styled'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import { logEvent } from '../ygdrasil/libs/FirebaseHelper'
import { findObjectiveForKeyResult } from '../ygdrasil/libs/ObjectiveHelper'
import { shouldShowMoreAction } from '../ygdrasil/libs/PermissionsHelper'
import { useUser } from '../ygdrasil/libs/QueryHooks'
import { Item, ItemData } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import { openEditKeyResultDrawer } from './DrawerGlobalEditKeyResultDialog'
import { openEditObjectiveDrawer } from './DrawerGlobalEditObjectiveDialog'
import { MEDIUM_ICON_SIZE } from './Icons'
import { MoreActionVert } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import { StrategyViewObjectiveListItemSummaryDescriptionInfo } from './StrategyViewObjectiveListItemSummaryDescriptionInfo'
import { StrategyViewObjectiveListRowCheckIn } from './StrategyViewObjectiveListRowCheckIn'
import { StrategyViewObjectiveListRowLead } from './StrategyViewObjectiveListRowLead'
import { StrategyViewObjectiveListRowMenu } from './StrategyViewObjectiveListRowMenu'
import { shouldShowPeriodColumn, StrategyViewObjectiveListRowPeriod } from './StrategyViewObjectiveListRowPeriod'
import { StrategyViewObjectiveListRowProgress } from './StrategyViewObjectiveListRowProgress'
import { shouldShowTeamsColumn, StrategyViewObjectiveListRowTeams } from './StrategyViewObjectiveListRowTeams'

export type onSelectItem = (id: string, item?: Partial<ItemData>) => unknown

export const StrategyViewObjectiveListRowDetailItemTableRow = (props: { item: Item; parentItem?: Item }) => {
  const config = useLoggedInConfig()
  const { state } = useAppState()
  const { data: user } = useUser(state.user._id)
  const { item, parentItem } = props
  const allItems = useAllItems()
  const objective =
    item.data.searchType === 'keyResult' ? findObjectiveForKeyResult(item.data.data as KeyResult, allItems) : undefined

  const { data } = item

  const _shouldShowMoreAction = shouldShowMoreAction(item, config, user)

  const onClickEditItem = (e) => {
    if (item.data.searchType === 'keyResult') return onClickEditKeyResult(e)
    return onClickEditObjective(e)
  }

  const onClickEditKeyResult = (e) => {
    if (!objective) return
    openEditKeyResultDrawer({ objective, keyResult: item.data.data as KeyResult })
    logEvent(AnalyticsEvent.EDIT_INDICATOR_MENU, state)
  }

  const onClickEditObjective = (e) => {
    logEvent(AnalyticsEvent.EDIT_OBJECTIVE_MENU, state)
    openEditObjectiveDrawer({ objective: item.data.data as Objective })
  }

  return (
    <StyledTableRow>
      {shouldShowPeriodColumn(data) && (
        <TableCell>
          <TableBox fullWidth align="center">
            <StrategyViewObjectiveListRowPeriod data={data} />
          </TableBox>
        </TableCell>
      )}
      <TableCell>
        <TableBox fullWidth align="center">
          <StrategyViewObjectiveListItemSummaryDescriptionInfo
            item={item}
            enableAlwaysShowIcon
            iconSize={MEDIUM_ICON_SIZE}
          />
        </TableBox>
      </TableCell>
      <TableCell>
        <TableBox fullWidth align="center">
          <StrategyViewObjectiveListRowLead item={item} />
        </TableBox>
      </TableCell>
      {shouldShowTeamsColumn(data) && (
        <TableCell>
          <TableBox fullWidth align="center">
            <StrategyViewObjectiveListRowTeams data={data} />
          </TableBox>
        </TableCell>
      )}
      <TableCell>
        <TableBox align="center">
          <StrategyViewObjectiveListRowProgress item={item} parentItem={parentItem} />
        </TableBox>
      </TableCell>
      <TableCell>
        <TableBox>
          <Box left>
            <StrategyViewObjectiveListRowCheckIn itemData={data} />
          </Box>
        </TableBox>
      </TableCell>
      <TableCell>
        <Box fullWidth fullHeight align="flex-end">
          <Box direction="row" align="center" top bottom gap={smallSpacing} justify="flex-end" spacing={smallSpacing}>
            <Button
              textKey={Texts.contextMenuCommentEditButtonText}
              spacing={smallSpacing}
              onClick={(e) => onClickEditItem(e)}
            />
            <MoreActionWithMenu
              visible={_shouldShowMoreAction}
              Menu={(props) => <StrategyViewObjectiveListRowMenu menuProps={props} item={item} />}
              MoreAction={MoreActionVert}
              isAlwaysVisible
            />
          </Box>
        </Box>
      </TableCell>
    </StyledTableRow>
  )
}

const StyledTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    border-width: 0px;
    padding: 0px;
    border-top: 0.25px solid ${Colors.secondary50};
  }
`

const TableBoxStyles = `
  cursor: pointer;
  padding-top: ${smallSpacing};
  padding-bottom: ${smallSpacing};
  padding-right: ${tinySpacing};
  padding-left: ${tinySpacing};
`

export const TableBox = styled(Box)`
  ${TableBoxStyles}
`

export const TableHeaderBox = styled.span`
  ${TableBoxStyles}
`
