import { isValidJson } from '../libs/useLocalStorageItemHelper'

export function getInitialItem<T>(stored: string | null, defaultValue: T): T {
  const initial = !!stored && isValidJson(stored) ? JSON.parse(stored) : {}
  return { ...defaultValue, ...initial }
}

export function formatLocalStorageItem(_localStorageItem: any, ignoredKeys: string[]): any {
  return Object.keys(_localStorageItem)
    .filter((key) => !ignoredKeys.includes(key))
    .reduce((a, b) => {
      a[b] = _localStorageItem[b]
      return a
    }, {})
}
