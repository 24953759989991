import TableCell from '@mui/material/TableCell'
import { useState } from 'react'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import { useDatasources } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { Connection } from '../ygdrasil/types/types'
import Box from './Box'
import { canUseConnection } from '../libs/SettingsIntegrationsHelper'
import { DashboardListArrowDown, DashboardListArrowRight } from './Icons'
import { MoreActionVert } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import SettingsIntegrationsConnectionMenu from './SettingsIntegrationsConnectionMenu'
import { StyledTableRow } from './SettingsIntegrationsConnectionsTable'
import { SettingsIntegrationsDatasourceTableRow } from './SettingsIntegrationsDatasourceTableRow'
import FigmaImageContainer from './FigmaImageContainer'
import Images from '../ygdrasil/libs/Images'
import ConnectionType from '../ygdrasil/enums/ConnectionType'
import { smallSpacing } from '../enums/Spacings'
import { NoWrapFigmaText } from './StyledComponents'
import { TableBox } from './TableBox'

export const SettingsIntegrationsConnectionsTableRow = ({ connection }: { connection: Connection }) => {
  const { state } = useAppState()
  const [isExpanded, setIsExpanded] = useState(true)
  const { data: dataSources = [] } = useDatasources(state)

  const _dataSources = dataSources.filter((ds) => ds.connectionId === connection._id)
  const hasChildren = !!_dataSources[0]

  const onDataSourceUpdated = () => setIsExpanded(true)

  const shouldShowMenu = canUseConnection(connection, state)
  const imageKey = connection.connectionType === ConnectionType.EXCEL_ONLINE ? Images.office365 : Images.googleIcon

  return (
    <>
      <StyledTableRow>
        <TableCell>
          <TableBox onClick={() => hasChildren && setIsExpanded(!isExpanded)}>
            <Box fullWidth align="center" direction="row">
              <Box
                style={{
                  opacity: hasChildren ? 1 : 0.3
                }}
              >
                {isExpanded && hasChildren ? <DashboardListArrowDown /> : <DashboardListArrowRight />}
              </Box>
              <TableBox direction="row" align="center" left spacing={smallSpacing}>
                <FigmaImageContainer imageKey={imageKey} />
                <Box left spacing={smallSpacing}>
                  <NoWrapFigmaText textKey={Texts.office365Connection} text={connection.name} />
                </Box>
              </TableBox>
            </Box>
          </TableBox>
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          {shouldShowMenu && (
            <MoreActionWithMenu
              visible
              Menu={(props) => (
                <SettingsIntegrationsConnectionMenu
                  {...props}
                  connection={connection}
                  onDataSourceUpdated={onDataSourceUpdated}
                />
              )}
              MoreAction={MoreActionVert}
              isAlwaysVisible
            />
          )}
        </TableCell>
      </StyledTableRow>
      {isExpanded &&
        hasChildren &&
        _dataSources.map((ds, key) => (
          <SettingsIntegrationsDatasourceTableRow connection={connection} dataSource={ds} key={key} />
        ))}
    </>
  )
}
