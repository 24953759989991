import React from 'react'
import { Activity, KeyResult, Objective } from '../ygdrasil/types/types'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { MenuProps } from './MoreActionWithMenu'
import StrategyViewObjectiveListObjectiveMenu from './StrategyViewObjectiveListObjectiveMenu'
import StrategyViewObjectiveListKeyResultListItemMenu from './StrategyViewObjectiveListKeyResultListItemMenu'
import StrategyViewObjectiveListKeyResultActivityListItemMenu from './StrategyViewObjectiveListKeyResultActivityListItemMenu'

export function StrategyViewObjectiveListRowMenu({
  menuProps,
  item
}: {
  menuProps: React.PropsWithChildren<MenuProps>
  item: Item
}) {
  if (item.data.searchType === 'activity')
    return (
      <StrategyViewObjectiveListKeyResultActivityListItemMenu {...menuProps} activity={item.data.data as Activity} />
    )
  if (item.data.searchType === 'keyResult')
    return <StrategyViewObjectiveListKeyResultListItemMenu {...menuProps} keyResult={item.data.data as KeyResult} />
  return <StrategyViewObjectiveListObjectiveMenu {...menuProps} objective={item.data.data as Objective} />
}
