import { StorageKey } from '../libs/useLocalStorageItemHelper'
import { getObjectOrBoolForJSON } from '../ygdrasil/libs/Common'
import { Language } from '../ygdrasil/types/Antiloop'
import { State } from './useAppState'

// use ygdrasil use language instead
export const _getLanguageCode = (): Language | undefined => {
  if (typeof window === 'undefined') return
  const json = window.localStorage.getItem(StorageKey.STATE)
  if (!json) return
  const state = getObjectOrBoolForJSON(json) as State | boolean
  if (!state) return

  return (state as State).user.language
}

export function _getDefaultLanguageCode(_navigator?: Navigator): Language | undefined {
  if (!_navigator && typeof navigator === 'undefined') return
  _navigator = _navigator || navigator
  return _navigator.language?.substr(0, 2) as Language | undefined
}
