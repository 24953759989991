import { SMALL_SPACING, SMALL_SPACING_PX } from '../enums/Spacings'

export const ONBOARDING_MAX_WIDTH_MOBILE_PX = 320
export const ONBOARDING_MAX_WIDTH_MOBILE = `${ONBOARDING_MAX_WIDTH_MOBILE_PX}px`
export const ONBOARDING_MAX_WIDTH_USP_DESKTOP = 380
export const ONBOARDING_BUTTON_HEIGHT = '40px'

export const MENU_ARROW_DOWN_SIZE = 20 // taken from figma but increased it a bit

export const DELETE_OBJECTIVE_MODAL_MAX_WIDTH = '250px'

export const EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH = '350px'
export const REORDER_OBJECTIVES_MODAL_MIN_WIDTH = '450px'
export const STRIPE_CALLBACK_MODAL_MAX_WIDTH = 450

export const PERIOD_SELECTOR_MODAL_MIN_WIDTH = 500

export const HEADER_ACTION_CONTAINERSIZE = '24px'

export const NOTIFICATION_WIDTH = '486px'

export const GRAPH_POPOVER_HEIGHT = 170
export const GRAPH_POPOVER_WIDTH = 430

export const GRAPH_MARGINS = { right: 10, top: 10 }

export const PROGRESS_STATUS_CIRCLE_SIZE = '11px'

export const DRAWER_WIDTH = 650
export const DRAWER_WIDTH_STRING = `${DRAWER_WIDTH}px`

export const OBJECTIVE_DIALOG_KEY_RESULT_BOX_WIDTH = '75px'

export const FOCUSED_OBJECTIVE_MIN_HEIGHT = '70px'

export const FOCUSED_OBJECTIVE_MAX_HEIGHT = '300px'

export const FOCUES_OBJECTIVE_SMALL_WIDTH = 200
export const FOCUES_OBJECTIVE_BIG_WIDTH = 300

export const VIDEO_PLAYER_HEIGHT = 500

export const GROWTH_INDICATOR_VALUE_INPUT_WIDTH = 50

export const MUI_LINE_HEIGHT = 1.43

export const PERSONAL_SETTINGS_MAX_WIDTH = 350

export const SUBSCRIPTION_SETTINGS_WIDTH = 500

export const LEAD_USER_CONATINER_SIZE = SMALL_SPACING_PX / SMALL_SPACING // about 16 as in figma

export const ROADMAP_MAX_HEIGHT = 500

export const ROADMAP_OBJECTIVE_NAME_LENGTH = 75

export const YELLOW_BOX_HEIGHT = 33

export const ONBOARDING_NAME_MOBILE_PICTURE_OPACITY = 20
export const ONBOARDING_NAME_MOBILE_PICTURE_POSITION = 6.5

export const MOBILE_YELLOW = '#F2C002' // For some reason this color is not picked up by figma bot

export const MENU_WIDTH = 250
export const MENU_WIDTH_COLLAPSED = 50

export const UNIT_BUTTON_WIDTH = 100 // taken from figma

export const PROGRESS_BAR_HEIGHT = 11
export const PROGRESS_BAR_HEIGHT_KEY_RESULT = 7

export const SEARCH_INPUT_SIZE = 150

export const RICHTEXT_LINK_POPOVER_HEIGHT = 200

export const RICHTEXT_EDITOR_TOOLBAR_HEIGHT = 40
export const RICHTEXT_LINK_POPOVER_WIDTH = 235

export const INDICATOR_DASHBOARD_SELECT_WIDTH = 450

export const ACTION_BOARD_COLUMN_MIN_WIDTH = 150
export const ACTION_BOARD_COLUMN_MAX_WIDTH = 250

export const TIDIO_CHAT_PADDING = `${SMALL_SPACING_PX * 10}px` // used to get space for tidio chat icon

export const DASHBOARD_INDICATOR_GRAPH_WIDTH = 600
export const TINY_GRAPH_HEIGHT = 200
export const TINY_GRAPH_MIN_WIDTH = 200

export const StrategyViewObjectiveListRowDetailBodyIndicator_MAX_GRAPH_WIDTH = 1000
export const StrategyViewObjectiveListRowDetailBodyIndicator_GRAPH_HEIGHT = 250
export const StrategyViewObjectiveListRowDetailBodyIndicator_FULL_SCREEN_GRAPH_HEIGHT = 500

export const GRAPH_ACTIVITY_LIST_CARD_MIN_WIDRH = ACTION_BOARD_COLUMN_MAX_WIDTH

export const WEEKLY_DATE_MENU_WIDTH = 150

export const TODO_HEADER_ITEM_WIDTH = 80

export const BAR_CHART_BAR_SIZE_FACTOR = 0.22 // 22% of the width of the chart
export const BAR_CHART_WIDTH = 450
export const BAR_CHART_HEIGHT = '250px'
export const BAR_CHART_MARGIN = 20
export const BAR_CHART_EDIT_FIELD_WIDTH = '100px'

export const MIN_WIDTH_MODAL_ADD_PICTURE_BUTTON_CONTAINER = 200

export const ACITIVITY_STATUS_MENU_ITEM_FALLBACK_HEIGHT = 28
export const MY_FOCUS_PRIORITIZED_ACTIVITY_HEIGHT = '110px'
