import React from 'react'
import styled from '@emotion/styled'

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Colors from '../ygdrasil/libs/Colors'
import { isPropValid } from '../libs/EmotionHelper'

type Props = { $loaderColor?: string } & CircularProgressProps

export default function Loader(props: Props) {
  return <StyledCircularProgress {...props} />
}

const StyledCircularProgress = styled(CircularProgress, { shouldForwardProp: isPropValid })<{ $loaderColor?: string }>`
  color: ${({ $loaderColor }) => ($loaderColor ? $loaderColor : Colors.primaryBase)};
`
