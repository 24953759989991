import React from 'react'
import useAppState, { State } from '../hooks/useAppState'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { useOrganization, useTeams } from '../ygdrasil/libs/QueryHooks'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { BusinessPlan } from './BusinessPlan'
import { MyActions } from './MyActions'
import { PageHeader } from './PageHeader'
import { StrategyViewObjectives } from './StrategyViewObjectives'
import SuccessView from './SuccessView'

export function StrategyView() {
  const { state } = useAppState()
  const { Component, headerTextKey, headerText } = useMainRoute(state)
  return (
    <Box align="center" fullWidth fullHeight fullPadding>
      <Box fullWidth>
        {headerTextKey && <PageHeader headerTextKey={headerTextKey} headerText={headerText} />}
        <Component />
      </Box>
    </Box>
  )
}

const useMainRoute = (state: State): { Component: React.FC; headerTextKey?: AntiloopTextType; headerText?: string } => {
  const { data: teams = [] } = useTeams(state)
  const { data: org } = useOrganization(state)
  const { showBusinessPlan, showGrowthView, selectedTeamId, showMyActivities } = state
  switch (true) {
    case showBusinessPlan:
      return DashboardRoutes.BusinessPlan
    case showGrowthView:
      return DashboardRoutes.GrowthView
    case showMyActivities:
      return DashboardRoutes.MyActivities
    case !!selectedTeamId:
      return {
        ...DashboardRoutes.TeamView,
        headerText: formatText(getTextForTextKey(Texts.pageHeaderTeamView, org), [
          ' ' + teams.find(({ _id }) => _id === selectedTeamId)?.name || ''
        ])
      }
    default:
      return DashboardRoutes.StrategyView
  }
}

const DashboardRoutes = {
  BusinessPlan: {
    headerTextKey: Texts.pageHeaderBusinessPlan,
    Component: BusinessPlan
  },
  GrowthView: {
    headerTextKey: Texts.pageHeaderSuccessView,
    Component: SuccessView
  },
  StrategyView: {
    headerTextKey: Texts.pageHeaderStrategyView,
    Component: StrategyViewObjectives
  },
  TeamView: {
    headerTextKey: Texts.pageHeaderTeamView,
    Component: StrategyViewObjectives
  },
  MyActivities: {
    headerTextKey: Texts.pageHeaderStrategyView,
    headerText: getFigmaText(Texts.applicationMenuMyFocusButtonText),
    Component: MyActions
  }
}
