import styled from '@emotion/styled'
import { useState } from 'react'
import { defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import {
  addStar,
  changeAddressInAddressbarWithoutReloading,
  getAllIndicatorsForDashboard,
  getIndicatorsForDashboard,
  getObjectivesForDashboard,
  getUrlForDashboardLink
} from '../ygdrasil/libs/DashboardHelper'
import { DASHBOARD_INDICATOR_GRAPH_WIDTH, INDICATOR_DASHBOARD_SELECT_WIDTH } from '../libs/HardCodedSizes'
import { getStateForStrategicView } from '../libs/MenuHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { addQueryParamsToUrl, DeepLinkHelperQueryParams } from '../ygdrasil/hooks/useQueryParams'
import Colors from '../ygdrasil/libs/Colors'
import { removeDashboardLink, updateDashboardLink } from '../ygdrasil/libs/DBApiHandler'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { useDashboardLinks, useOrganization } from '../ygdrasil/libs/QueryHooks'
import { TreeItem } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import Texts from '../ygdrasil/libs/Texts'
import { DashboardLink, Id } from '../ygdrasil/types/types'
import Box from './Box'
import { CelebrationViewContainer } from './CelebrationView'
import { IndicatorDashboardCelebrations } from './DashboardCelebrations'
import { IndicatorDashboardEmptyState } from './DashboardEmptyState'
import { DashboardIndicator } from './DashboardIndicator'
import IndicatorDashboardMenu from './DashboardMenu'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { MoreActionVert } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import SelectFieldWithLabel from './SelectFieldWithLabel'
import useTreeItems from './useTreeItems'
import { minBorderRadius, tinyBorderRadius } from '../enums/BorderRadixes'
import { createUniqueId } from '../ygdrasil/libs/Common'
import shadows from '../enums/Shadows'

const NONE = 'NONE'

export default function IndicatorDashboard() {
  const { state } = useAppState()
  const { data: dashboardLinks = [], isLoading } = useDashboardLinks(state)

  if (isLoading) return null

  return <IndicatorDashboardView dashboardLinks={dashboardLinks} />
}

function IndicatorDashboardView({ dashboardLinks }: { dashboardLinks: DashboardLink[] }) {
  const { state, setState } = useAppState()
  const { tree } = useTreeItems()
  const { data: org } = useOrganization(state)
  const config = useLoggedInConfig()

  const [excludedIndicatorIds, setExcludedIndicatorIds] = useState<string[]>([])

  const { selectedDashboardLinkId } = state
  const selectedDahboardLink = dashboardLinks.find((d) => d._id === selectedDashboardLinkId)

  const isEditingDashboardLink = excludedIndicatorIds.length > 0

  const dashboardLinkOptions = dashboardLinks.reduce(
    (a, d) => {
      const key = addStar(d.name, isEditingDashboardLink && d._id === selectedDashboardLinkId)
      a.push({ key, value: d._id })
      return a
    },
    [
      {
        key: addStar(
          getTextForTextKey(Texts.dashboardSelectHelperText, org),
          isEditingDashboardLink && isEditingDashboardLink && !selectedDashboardLinkId
        ),
        value: NONE
      }
    ]
  )

  const objectives = getObjectivesForDashboard(tree, state)
  const indicators = getAllIndicatorsForDashboard(tree, state, selectedDahboardLink, excludedIndicatorIds)

  const includedIndicatorIds = indicators.map((i) => i.id)
  const partialDashboardLink: Partial<DashboardLink> = {
    ...selectedDahboardLink,
    includedIndicatorIds
  } as DashboardLink

  const onChangeDashboardLinkId = ({ target: { value } }) =>
    setState({ ...state, selectedDashboardLinkId: value as string })

  const onClickCopyMenuLink = () => navigator.clipboard.writeText(getUrlForDashboardLink(partialDashboardLink, state))

  const onClickDeleteDashboard = () => {
    if (!selectedDahboardLink) return
    removeDashboardLink(selectedDahboardLink)
    setExcludedIndicatorIds([])
    setState({ ...state, selectedDashboardLinkId: undefined })
  }

  const onClickShowAllIndicators = () => {
    setExcludedIndicatorIds([])
    setState({ ...state, selectedDashboardLinkId: undefined })
  }

  const onClickSaveDashboard = () => {
    if (!selectedDahboardLink) return

    return updateDashboardLink(partialDashboardLink as DashboardLink).then(() => setExcludedIndicatorIds([]))
  }

  const onCreateUpateDashboardLink = () => {
    setExcludedIndicatorIds([])
  }

  const onRemoveIndicatorFromView = (id: Id): void => setExcludedIndicatorIds([...excludedIndicatorIds, id])

  const onClickHeader = (i: TreeItem) => {
    setState({
      ...getStateForStrategicView(state),
      selectedDashboardView: 'list', // TODO WRITE TEST, should set go to strategy view
      searchState: {
        ...state.searchState,
        selectedIndicatorId: i.id, // TODO WRITE TEST, should set indicator id
        selectedItemId: i.id // TODO WRITE TEST, should set selected item id
      }
    })

    const url = addQueryParamsToUrl<DeepLinkHelperQueryParams>(window.location.pathname, {
      selectedItemId: i.id
    })

    changeAddressInAddressbarWithoutReloading(url)
  }

  return (
    <Container fullWidth>
      <Box fullWidth direction="row" justify="space-between">
        <Box fullWidth>
          <Box fullWidth justify="center" direction="row" align="center">
            <Box top>
              <FigmaTextForStrategyModel textKey={Texts.dashboardSelectLabel} />
              <Box fullWidth justify="center" direction="row" align="center">
                <SelectFieldContainer>
                  <SelectFieldWithLabel
                    value={selectedDahboardLink?._id || NONE}
                    options={dashboardLinkOptions}
                    onChange={onChangeDashboardLinkId}
                  />
                </SelectFieldContainer>
                {config.enableEditDashboard && (
                  <Box left spacing={tinySpacing}>
                    <MoreActionWithMenu
                      visible
                      Menu={(props) => (
                        <IndicatorDashboardMenu
                          {...props}
                          onClickCopyMenuLink={onClickCopyMenuLink}
                          onClickShowAllIndicators={onClickShowAllIndicators}
                          onDeleteDashboard={onClickDeleteDashboard}
                          onClickSaveDashboard={onClickSaveDashboard}
                          dashboardLink={partialDashboardLink}
                          isEditingDashboardLink={isEditingDashboardLink}
                          onCreateUpateDashboardLink={onCreateUpateDashboardLink}
                        />
                      )}
                      MoreAction={MoreActionVert}
                      isAlwaysVisible
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {indicators.length === 0 && <IndicatorDashboardEmptyState />}
          {objectives.map((o, key) => {
            const indicators = getIndicatorsForDashboard(o, selectedDahboardLink, excludedIndicatorIds)
            const shouldShowObjective = indicators.length > 0

            if (!shouldShowObjective) return null

            return (
              <Container key={key} fullPadding align="center" fullWidth>
                <Box top fullWidth align="center">
                  <FigmaText textKey={Texts.cssDashboardObjectiveTextPurple} text={o.data.name} />
                  <IndicatorRowContainer direction="row" justify="center" fullWidth top>
                    {indicators.map((i) => (
                      <IndicatorContainer key={createUniqueId()} fullWidth>
                        <DashboardIndicator
                          item={i}
                          parentItem={o}
                          onClickHeader={() => onClickHeader(i)}
                          onClose={() => onRemoveIndicatorFromView(i.id)}
                        />
                      </IndicatorContainer>
                    ))}
                  </IndicatorRowContainer>
                </Box>
              </Container>
            )
          })}
        </Box>
        <IndicatorDashboardCelebrations />
      </Box>
    </Container>
  )
}

export const CelebrationsContainer = styled(CelebrationViewContainer)`
  border-left: 1px solid ${Colors.primary90};
  box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.05);
`

const Container = styled(Box)`
  background-color: ${Colors.whiteBg};
`

const IndicatorRowContainer = styled(Box)`
  flex-wrap: wrap;
  gap: ${defaultSpacing};
`

const IndicatorContainer = styled(Box)`
  max-width: ${DASHBOARD_INDICATOR_GRAPH_WIDTH}px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: ${tinyBorderRadius};

  & .close-icon {
    visibility: hidden;
  }

  &:hover .close-icon {
    visibility: visible;
  }
`

const SelectFieldContainer = styled(Box)`
  background-color: ${Colors.whiteBg};
  min-width: ${INDICATOR_DASHBOARD_SELECT_WIDTH}px;
`
