import { Objective } from '../ygdrasil/types/types'
import useAppState from './useAppState'

export default function useIsSelectedObjective(objective: Objective) {
  const { state, setSearchState } = useAppState()
  const { searchState } = state
  const isSelected = objective._id === searchState.selectedObjectiveId
  const toggleObjective = () =>
    setSearchState({ ...searchState, selectedObjectiveId: isSelected ? undefined : objective._id }) // look a bit complext to support toggle
  return { isSelected, toggleObjective }
}
