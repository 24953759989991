import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { useLanguage } from '../ygdrasil/libs/useLanguage'
import { getSelectedOrganizationId } from '../ygdrasil/libs/useLoginHelper'
import Box from './Box'
import { getFirestoreUrlForORgId, OrganizationConfigurationProperties } from '../libs/SettingsApplicationHelper'
import SettingsApplicationTabs from './SettingsApplicationStrategyModelTabs'
import FigmaImageContainer from './FigmaImageContainer'
import Images from '../ygdrasil/libs/Images'
import Tooltip from './Tooltip'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import FigmaTextWithLinebreaks from '../ygdrasil/components/FigmaTextWithLinebreaks'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'

const _OrganizationConfigurationProperties = OrganizationConfigurationProperties.map((property) => `• ${property}`)

export default function SettingsApplication() {
  const language = useLanguage()
  const config = useLoggedInConfig()

  return (
    <Box fullWidth top>
      {config.enableSuperAdmin && (
        <Box top>
          <SettingsApplicationFirebaseLink />
        </Box>
      )}

      <Box top>
        <FigmaText textKey={Texts.applicationSettingStrategyModelHeader} />
        <Box top spacing={tinySpacing}>
          <Box top>
            <SettingsApplicationTabs language={language} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
function SettingsApplicationFirebaseLink() {
  const { state } = useAppState()
  const { selectedOrganizationId } = state
  return (
    <Box direction="row" align="center">
      <FigmaText textKey={Texts.adminCompanyConfigurationLabel} />
      <Box left link onClick={() => window.open(getFirestoreUrlForORgId(selectedOrganizationId), '_blank')}>
        <FigmaText textKey={Texts.adminCompanyConfigurationLink} />
      </Box>
      <Tooltip
        title={'_'}
        titleElement={
          <Box>
            <FigmaTextWithLinebreaks
              textKey={Texts.allTooltipText}
              text={getFigmaText(Texts.adminCompanyConfigurationInfo)}
              separator={<span />}
            />
          </Box>
        }
      >
        <Box left pointer>
          <FigmaImageContainer imageKey={Images.infoTooltip} />
        </Box>
      </Tooltip>
    </Box>
  )
}
