import React from 'react'
import { tinySpacing } from '../enums/Spacings'
import { DELETE_OBJECTIVE_MODAL_MAX_WIDTH } from '../libs/HardCodedSizes'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import useVisibleObjectives from '../ygdrasil/hooks/useVisibleObjectives'
import { getAllObjectivesForObjective } from '../ygdrasil/libs/ObjectiveHelper'
import { removeObjective } from '../ygdrasil/libs/ProcessHandler'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Objective } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import Modal, { ModalProps } from './Modal'

type Props = { objective: Objective } & ModalProps
export function ModalDeleteObjective(props: Props) {
  const { objective: o, onClose } = props
  const { data: allObjectives = [] } = useVisibleObjectives()
  const { style } = Texts.dialogDeleteObjectiveHeader
  const [isLoading, setIsLoading] = React.useState(false)
  const logEvent = useLogEvent()

  const onClickDelete = (e) => {
    e.stopPropagation() // Without this line onClick method on FocusedObjective is fired => bad
    Promise.resolve(setIsLoading(true))
      .then(() =>
        Promise.all(
          getAllObjectivesForObjective(o, allObjectives)
            .reverse()
            .map((o) => removeObjective(o, logEvent))
        )
      )
      .finally(() => {
        onClose(e)
        setIsLoading(false)
      })
  }

  const onClickCancel = onClose
  return (
    <Modal {...props}>
      <Box fullWidth style={{ maxWidth: DELETE_OBJECTIVE_MODAL_MAX_WIDTH }}>
        <Box fullWidth bottom>
          <Box direction="row" align="center" bottom>
            <span style={style}>
              {getFigmaText(Texts.deleteButtonText)} <b>{o.name}</b>
              {'?'}
            </span>
          </Box>
          <FigmaTextForStrategyModel textKey={Texts.dialogDeleteObjectiveConfirmationText} />
        </Box>
        <Box direction="row" align="center" justify="flex-end" fullWidth>
          <Button
            onClick={onClickCancel}
            buttonType="secondary"
            textKey={Texts.cancelButtonText}
            spacing={tinySpacing}
          />
          <Box left spacing={tinySpacing}>
            <Button
              onClick={onClickDelete}
              textKey={Texts.deleteButtonText}
              buttonType="tertiary"
              spacing={tinySpacing}
              loading={isLoading}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
