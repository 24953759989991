import React, { useEffect, useRef, useState } from 'react'
import FigmaText, { FigmaTextProps } from '../ygdrasil/components/FigmaText'
import { mapCssHeightToNumber } from '../ygdrasil/libs/Common'
import Box from './Box'
import Loader from './Loader'

type Props = FigmaTextProps & { loading?: boolean }
const FigmaTextLoader: React.FC<Props> = (props) => {
  const { loading, textKey } = props
  const { style } = textKey
  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const textHeight = mapCssHeightToNumber(textKey.style.fontSize)

  useEffect(() => {
    ref.current && setHeight(ref.current.clientHeight)
  })

  if (loading)
    return (
      <Box position="relative">
        <Box visibility="hidden" ref={ref}>
          <FigmaText {...props} />
        </Box>
        <Box position="absolute" style={{ top: (height - textHeight) / 2, left: 0, right: 0, margin: 'auto' }}>
          <Loader $loaderColor={textKey.style.color} size={style.fontSize} />
        </Box>
      </Box>
    )

  return <FigmaText {...props} />
}

export default FigmaTextLoader
