import styled from '@emotion/styled'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getRootObjective, getWidth } from '../libs/FocusedObjectiveHelper'
import { useObjectives } from '../ygdrasil/libs/QueryHooks'
import { groupBy } from '../ygdrasil/libs/Common'
import Texts from '../ygdrasil/libs/Texts'
import { Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { FocusedObjective } from './FocusedObjective'
import { TextWithLineAndDots } from './TextWithLine'

const SELF = 'SELF'
export const FocusedObjectivesTeamView = ({
  objectives,
  wrapperWidth
}: {
  objectives: Objective[]
  wrapperWidth: string
}) => {
  const { state } = useAppState()
  const { data: allObjectives = [] } = useObjectives(state)
  const _objectives = objectives.map((o) => {
    let rootObjective: Objective | undefined = getRootObjective(o, allObjectives)
    if (rootObjective._id === o._id) rootObjective = undefined
    return {
      ...o,
      strategicParentObjectiveId: rootObjective?._id || SELF,
      strategicParentObjective: rootObjective
    }
  })

  const __objectives = groupBy(_objectives, 'strategicParentObjectiveId')
  const ___objectives = Object.keys(__objectives).map(
    (strategicParentObjectiveId) => __objectives[strategicParentObjectiveId]
  )

  return (
    <Box fullWidth style={{ width: wrapperWidth, overflow: 'hidden' }}>
      <InnerContainer fullWidth direction="row" right style={{ overflow: 'auto' }}>
        {___objectives.map((objectives, key, arr) => {
          const text = objectives[0]?.strategicParentObjective?.name || SELF
          return (
            <Box spacing={tinySpacing} bottom key={key}>
              <Box style={{ display: objectives[0]?.strategicParentObjectiveId !== SELF ? 'flex' : 'none' }} fullWidth>
                <TextWithLineAndDots
                  textKey={Texts.cssStrategicGoalsTextForTeamObjective}
                  text={text}
                  enableDots={objectives.length > 1}
                  maxWidth={getWidth(arr.length)}
                />
              </Box>
              <Box direction="row" right fullHeight gap={smallSpacing}>
                {objectives.map((o, key) => (
                  <FocusedObjective key={key} objective={o} index={key} numberOfObjectives={_objectives.length} />
                ))}
              </Box>
            </Box>
          )
        })}
      </InnerContainer>
    </Box>
  )
}

const InnerContainer = styled(Box)`
  flex-wrap: wrap;
  overflow: auto;
`
