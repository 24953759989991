import React, { useEffect, useState } from 'react'
import { DragDropContext as ReactDnDDragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { getRankForTreeView, inRankOrder, RankedObject } from '../ygdrasil/libs/RankHelper'
import { TreeSourcePosition } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { captureException } from '../ygdrasil/libs/SentryHelperDeprecated'

const reorder = <T extends DraggableObject>(
  startIndex: number,
  toIndex: number,
  { rows: list, onRowUpdated }: Props<T>
) => {
  const prevIndex = Math.max(toIndex - 1, 0)

  const ordo = getRankForTreeView(
    list[prevIndex],
    list[toIndex],
    { index: startIndex } as TreeSourcePosition,
    { index: toIndex } as TreeSourcePosition,
    list[toIndex - 1],
    list[toIndex + 1]
  )

  list[startIndex].ordo = ordo

  onRowUpdated && onRowUpdated(list[startIndex])

  return list.sort(inRankOrder)
}

function DraggableRow({ row, index, Component }) {
  return (
    <Draggable draggableId={row.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Component data={row} />
        </div>
      )}
    </Draggable>
  )
}

type DraggableObject = RankedObject

type Props<T extends DraggableObject> = {
  onDragEnd?: (rows: T[]) => void
  onRowUpdated?: (row: T) => void
  rows: T[]
  Component: React.FC<{
    data: T
  }>
  enableReInitialize?: boolean
}

function DragDropContext<T extends DraggableObject>(props: Props<T>) {
  const { rows: _rows, Component, onDragEnd, enableReInitialize = false } = props
  const [rows, setRows] = useState(_rows || [])
  const [isTouched, setIsTouched] = useState(false)

  useEffect(() => {
    if (enableReInitialize) return setRows(_rows)
    !isTouched && setRows(_rows)
  }, [_rows, isTouched])

  function _onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    try {
      const __rows = reorder(result.source.index, result.destination.index, props)

      onDragEnd && onDragEnd(__rows)
      setRows(__rows)
      setIsTouched(true)
    } catch (e) {
      captureException(e)
    }
  }

  return (
    <ReactDnDDragDropContext onDragEnd={_onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {rows.map((row: any, index: number) => (
              <DraggableRow row={row} index={index} key={row.id || row._id} Component={Component} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </ReactDnDDragDropContext>
  )
}

export default DragDropContext
