import useAppState from '../hooks/useAppState'
import { User } from '../ygdrasil/types/types'
import { StrategyViewObjectiveTreeFilterUserView } from './StrategyViewObjectiveTreeFilterUserView'

export function StrategyViewObjectiveTreeFilterUser({ user }: { user: User }): JSX.Element {
  const { state, setSearchState } = useAppState()
  const { searchState } = state
  const { leadUserIds } = searchState
  const isSelected = leadUserIds?.includes(user._id)
  const onClick = () =>
    setSearchState({ ...searchState, leadUserIds: isSelected ? [] : [user._id], selectedItemId: undefined })

  return (
    <StrategyViewObjectiveTreeFilterUserView
      user={user}
      onClick={onClick}
      shouldBlur={!isSelected && !!leadUserIds?.[0]}
    />
  )
}
