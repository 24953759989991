import React from 'react'
import { ItemData } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts, { DeprecatedTexts } from '../ygdrasil/libs/Texts'
import Box from './Box'
import { CheckInProgress } from './CheckinProgress'
import { smallSpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getCurrentRatio } from '../ygdrasil/libs/ObjectiveHelper'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import { office365 } from '../libs/ImagesRepository'
import { getProgressText } from '../ygdrasil/libs/KeyResultHelper'

export function StrategyViewObjectiveListRowProgressObjective({ itemData }: { itemData: ItemData }) {
  const allItems = useAllItems()
  const { allObjectives } = allItems

  const objective = allObjectives.find((o) => o._id === itemData.data._id)
  if (!objective) return null
  const ratio = getCurrentRatio(objective, allItems)
  const text = getProgressText(ratio)
  return (
    <Box fullWidth direction="row">
      <CheckInProgress confidences={objective.confidences || []} enableHead={false} objective={objective} />
      <Box left spacing={smallSpacing} visibility={ratio > 0 ? 'visible' : 'hidden'}>
        <FigmaText textKey={DeprecatedTexts.objectivesListProgressNumber} text={text} />
      </Box>
    </Box>
  )
}
