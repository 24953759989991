import styled from '@emotion/styled'
import React from 'react'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import ButtonBase from './ButtonBase'
type Props = { className?: string; spacing?: string; textKey?: AntiloopTextType }

const ButtonSecondary: React.FC<Props> = (props) => {
  const { children, className, textKey = Texts.cancelButtonText, ...buttonProps } = props

  return (
    <StyledButton className={className} {...buttonProps} variant="outlined">
      <Box align="center" fullWidth>
        {children || (
          <FigmaText
            textKey={Texts.cssGenericDialogButtonSecondaryButtonText}
            text={getFigmaText(textKey)}
            textTransform="uppercase"
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<{ spacing?: string }>`
  border: 1px solid ${Colors.white};
  border-radius: ${tinyBorderRadius};
`

export default ButtonSecondary
