import { TextField, TextFieldProps } from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import Box from './Box'
import { GenericErrorComponent } from './TextFieldFormik'
import { TextFieldOutlined, TextFieldOutlinedNumberInputStyles, TextFieldOutlinedProps } from './TextFieldOutlined'

type Props = TextFieldOutlinedProps & { formProps: FormikProps<any>; name: string }
export function TextFieldOutlinedNumberInputFormik(props: Props) {
  const { formProps, ...rest } = props
  const { name } = props
  return (
    <Box fullWidth>
      <TextFieldOutlined
        {...rest}
        value={formProps.values[props.name]}
        onChange={(event) => formProps.setFieldValue(props.name, event.target.value)}
        InputProps={{
          //@ts-ignore
          inputComponent: NumericFormatCustom
        }}
        Component={TextField}
        sx={TextFieldOutlinedNumberInputStyles}
      />
      {formProps.errors[name] && <GenericErrorComponent formProps={formProps} name={name} />}
    </Box>
  )
}

export function TextFieldOutlinedNumberInputForGrowthView(props: TextFieldProps) {
  return (
    <TextField
      variant="outlined"
      {...props}
      InputProps={{
        //@ts-ignore
        inputComponent: NumericFormatCustom
      }}
    />
  )
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator=" "
      decimalSeparator=","
      valueIsNumericString
    />
  )
})
