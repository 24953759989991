import { useState } from 'react'
import ModalCalendly, { CalendlyUrl } from './ModalCalendlyWidget'
import SignIn from './RootPage'

export default function PageScheduleACall() {
  const [showModal, setShowModal] = useState(true)

  const onCloseCalendly = () => {
    setShowModal(false)
  }

  return (
    <>
      {showModal && <ModalCalendly url={CalendlyUrl.ScheduleCall} onClose={onCloseCalendly} />}
      <SignIn />
    </>
  )
}
