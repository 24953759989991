import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import { smallSpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { Organization } from '../ygdrasil/types/types'
import Box from './Box'
import CheckboxFormik from './CheckboxFormik'

export function SettingsSuperAdminOrgRow({
  formProps,
  org
}: {
  formProps: FormikProps<{ userId: string }>
  org: Organization
}): JSX.Element {
  return (
    <Box fullWidth bottom align="center" direction="row" spacing={smallSpacing}>
      <Box right spacing={smallSpacing}>
        <CheckboxFormik formProps={formProps} name={org._id} />
      </Box>
      <FigmaText textKey={Texts.allSelectItemText} text={org.name} />
      {org.lastUserActiveAt && (
        <FigmaText
          textKey={Texts.allSelectItemText}
          text={' , (Active ' + dayjs().diff(dayjs(org.lastUserActiveAt), 'days') + ' days ago)'}
        />
      )}
    </Box>
  )
}
