import { Popper, styled } from '@mui/material'
import React from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { hasContent } from '../libs/WYSIWYGEditor'
import Colors from '../ygdrasil/libs/Colors'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { DEFAULT_ICON_SIZE, InfoIcon } from './Icons'
import { RichText } from './RichText'

export function StrategyViewObjectiveListItemSummaryDescriptionInfo({
  item,
  enableAlwaysShowIcon = false,
  iconSize = DEFAULT_ICON_SIZE
}: {
  item: Item
  enableAlwaysShowIcon?: boolean
  iconSize?: number
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const { description } = item.data

  const onMousLeave = () => setAnchorEl(undefined)
  const onMouseEnter = (e) => setAnchorEl(e.currentTarget)

  if (enableAlwaysShowIcon && !hasContent(description)) return <InfoIcon size={iconSize} />
  if (!hasContent(description)) return null

  return (
    <Box spacing={tinySpacing} link onMouseEnter={onMouseEnter} onMouseLeave={onMousLeave}>
      <InfoIcon size={iconSize} />
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} placement="bottom-start">
        <Box fullPadding spacing={smallSpacing}>
          <Container fullPadding spacing={smallSpacing}>
            <RichText fallbackTextKey={Texts.allTooltipTextWysiwig} richText={description} fallbackText={description} />
          </Container>
        </Box>
      </Popper>
    </Box>
  )
}

const Container = styled(Box)`
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25));
  background: ${Colors.whiteBg};
`
