import React from 'react'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Tooltip from './Tooltip'

import styled from '@emotion/styled'
import useClientWidth from '../hooks/useClientWidth'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'

const CANVAS = document.createElement('canvas')

const WithTooltipOverflow: React.FC<{
  offset?: number
  text: string
  textKey?: AntiloopTextType
}> = (props) => {
  const { text, textKey = Texts.allTooltipText, children, offset = 0 } = props
  const textWidth = getTextWidth(text, textKey)
  const ref = React.useRef<HTMLDivElement>(null)
  const _maxWidth = useClientWidth(ref)
  const maxWidth = Math.max(0, _maxWidth || 0) - offset

  const isOverflowing = !!maxWidth && textWidth > maxWidth

  return (
    <Tooltip title={isOverflowing ? text : ''} placement="top">
      <Container ref={ref}>{children}</Container>
    </Tooltip>
  )
}

const Container = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: inherit;
`

export default WithTooltipOverflow

const getTextWidth = (text: string, textKey: AntiloopTextType) => {
  const canvas = CANVAS
  const context = canvas.getContext('2d')
  if (!context) return 0

  const { fontSize, fontFamily, fontWeight } = textKey.style
  context.font = `${fontWeight} ${fontSize} ${fontFamily}`
  const metrics = context.measureText(text)
  return metrics.width
}
