import React, { useEffect } from 'react'
import { getDownloadURL, ref, getStorage } from 'firebase/storage'

export const Storage = getStorage()

export function useStorageUrl(path?: string) {
  const [url, setUrl] = React.useState('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  useEffect(() => {
    if (!path) return setUrl('')
    setIsLoading(true)
    getDownloadURL(ref(Storage, path))
      .then(setUrl)
      .finally(() => setIsLoading(false))
  }, [path])

  return { url, setUrl, isLoading }
}
