/* eslint-disable prettier/prettier */
import { ErrorSharp, FlagOutlined, People } from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import { default as AddBox, default as AddBoxIcon } from '@mui/icons-material/AddBox'
import _AnnouncementIcon from '@mui/icons-material/Announcement'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import BarChartIcon from '@mui/icons-material/BarChart'
import _ChatIcon from '@mui/icons-material/Chat'
import Check from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Clear from '@mui/icons-material/Clear'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import EditSharpIcon from '@mui/icons-material/EditSharp'
import Error from '@mui/icons-material/Error'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FlagSharpIcon from '@mui/icons-material/Flag'
import _HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import _HighlightOff from '@mui/icons-material/HighlightOff'
import _InfoIcon from '@mui/icons-material/Info'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import _KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import _KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import Link from '@mui/icons-material/Link'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Person from '@mui/icons-material/Person'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import PlayCircle from '@mui/icons-material/PlayCircleFilled'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PostAddIcon from '@mui/icons-material/PostAdd'
import MaterialSearchIcon from '@mui/icons-material/Search'
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltRounded'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutralRounded'
import StreetviewIcon from '@mui/icons-material/Streetview'
import _TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingDownRounded from '@mui/icons-material/TrendingDownRounded'
import _TrendingFlat from '@mui/icons-material/TrendingFlat'
import _TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingUpRounded from '@mui/icons-material/TrendingUpRounded'
import _TuneIcon from '@mui/icons-material/Tune'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { MENU_ARROW_DOWN_SIZE } from '../libs/HardCodedSizes'
import Colors from '../ygdrasil/libs/Colors'
import Icon, { DEFAULT_ICON_SIZE as _DEFAULT_ICON_SIZE, IconProps } from './Icon'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CelebrationIcon from '@mui/icons-material/Celebration';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';




export const DEFAULT_ICON_SIZE = _DEFAULT_ICON_SIZE // 16
export const BIG_ICON_SIZE = 24
export const MEDIUM_ICON_SIZE = 20

const TRENDING_ICON_SIZE = 13

export const ScreenshotMonitor = (props: Partial<IconProps>) => (<Icon component={ScreenshotMonitorIcon} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const DashboardListAddBox = (props: Partial<IconProps>) => (<Icon component={AddBoxIcon} color={Colors.primaryBase} {...props} />)
export const DashboardListArrowRight = (props: Partial<IconProps>) => (<Icon component={ArrowRightIcon} color={Colors.primaryBase} size={BIG_ICON_SIZE} {...props} />)
export const DashboardListArrowDown = (props: Partial<IconProps>) => (<Icon component={ArrowDropDownIcon} color={Colors.primaryBase} size={BIG_ICON_SIZE} {...props} />)
export const DashboardListArrowUp = (props: Partial<IconProps>) => (<Icon component={ArrowDropUpIcon} color={Colors.primaryBase} size={BIG_ICON_SIZE} {...props} />)

export const MenuArrowDownward = (props: Partial<IconProps>) => (<Icon component={ArrowDownward} color={Colors.primary40} backgroundColor={Colors.white} {...props} />)
export const MenuArrowUpward = (props: Partial<IconProps>) => (<Icon component={ArrowUpward} color={Colors.primary40} backgroundColor={Colors.white} {...props} />)
export const PersonAdd = (props: Partial<IconProps>) => (<Icon component={PersonAddAltIcon} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const MoreVert = (props: Partial<IconProps>) => (<Icon component={MoreVertIcon} color={Colors.neutralVariant50} size={BIG_ICON_SIZE} {...props} />)
export const MoreVertWhite = (props: Partial<IconProps>) => (<Icon component={MoreVertIcon} color={Colors.whiteBg} size={BIG_ICON_SIZE} {...props} />)
export const Streetview = (props: Partial<IconProps>) => (<Icon component={StreetviewIcon} color={Colors.secondary40} {...props} />)
export const Visibility = (props: Partial<IconProps>) => (<Icon component={VisibilityIcon} size={BIG_ICON_SIZE} color={Colors.primaryBase} {...props} />)
export const ArrowDropDown = (props: Partial<IconProps>) => (<Icon component={ArrowDropDownIcon} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const IncreaseIcon = (props: Partial<IconProps>) => (<Icon component={TrendingUpRounded} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const CheckinStatusFeelsGood = (props: Partial<IconProps>) => (<Icon component={SentimentSatisfiedAltIcon} color={Colors.good} size={BIG_ICON_SIZE} {...props} />)
export const CheckinStatusDontKnow = (props: Partial<IconProps>) => (<Icon component={SentimentSatisfiedAltIcon} color={Colors.neutral80} size={BIG_ICON_SIZE} {...props} />)
export const CheckinStatusAtRisk = (props: Partial<IconProps>) => (<Icon component={SentimentNeutralIcon} color={Colors.risk} size={BIG_ICON_SIZE} {...props} />)
export const CheckinStatusNotAchievable = (props: Partial<IconProps>) => (<Icon component={SentimentDissatisfiedRoundedIcon} color={Colors.bad} size={BIG_ICON_SIZE} {...props} />)
export const IncreasingIndicator = (props: Partial<IconProps>) => (<Icon component={TrendingUpRounded} color={Colors.primaryBase} backgroundColor={Colors.white} size={20} {...props} />)
export const DecreasingIndicator = (props: Partial<IconProps>) => (<Icon component={TrendingDownRounded} color={Colors.primaryBase} backgroundColor={Colors.white} size={20} {...props} />)
export const TrendingFlat = (props: Partial<IconProps>) => (<Icon component={_TrendingFlat} backgroundColor={Colors.white} color={Colors.primaryBase} size={TRENDING_ICON_SIZE} {...props} />)
export const TrendingUp = (props: Partial<IconProps>) => (<Icon component={_TrendingUpIcon} backgroundColor={Colors.white} color={Colors.primaryBase} size={TRENDING_ICON_SIZE} {...props} />)
export const TrendingDown = (props: Partial<IconProps>) => (<Icon component={_TrendingDownIcon} color={Colors.neutralUnselected} size={TRENDING_ICON_SIZE} {...props} />)
export const ArrowBack = (props: Partial<IconProps>) => (<Icon component={ArrowBackIosRoundedIcon} backgroundColor={Colors.white} color={Colors.primaryBase} {...props} />)
export const ArrowForward = (props: Partial<IconProps>) => (<Icon component={ArrowForwardIosRounded} backgroundColor={Colors.white}  color={Colors.primaryBase} {...props} />)
export const TuneIcon = (props: Partial<IconProps>) => (<Icon component={_TuneIcon} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const InfoIcon = (props: Partial<IconProps>) => (<Icon component={_InfoIcon} color={Colors.white} backgroundColor={Colors.primaryBase} {...props} />)
export const LinkIcon = (props: Partial<IconProps>) => (<Icon component={Link} color={Colors.black} {...props} />)
export const KeyboardArrowLeftIcon = (props: Partial<IconProps>) => (<Icon component={_KeyboardArrowLeftIcon} size={MENU_ARROW_DOWN_SIZE} color={Colors.primaryBase} {...props} />)
export const KeyboardArrowRightIcon = (props: Partial<IconProps>) => (<Icon component={_KeyboardArrowRightIcon} size={MENU_ARROW_DOWN_SIZE} color={Colors.primaryBase} {...props} />)
export const PlayCircleIcon = (props: Partial<IconProps>) => (<Icon component={PlayCircle} color={Colors.white} {...props} />)
export const FlagIcon = (props: Partial<IconProps>) => (<Icon component={FlagSharpIcon} backgroundColor={Colors.primaryBase} color={Colors.white} size={20} {...props} />)
export const AddIcon = (props: Partial<IconProps>) => (<Icon component={Add} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const ErrorIcon = (props: Partial<IconProps>) => (<Icon component={ErrorSharp} backgroundColor={Colors.white} color={Colors.bad} size={BIG_ICON_SIZE} {...props} />)
export const AnnouncementIcon = (props: Partial<IconProps>) => (<Icon component={_AnnouncementIcon} backgroundColor={Colors.white} color={Colors.bad} size={BIG_ICON_SIZE} {...props} />)
export const TeamMenuEditIcon = (props: Partial<IconProps>) => (<Icon component={EditSharpIcon} color={Colors.primaryBase} {...props} />)
export const TeamMenuAddIcon = (props: Partial<IconProps>) => (<Icon component={AddBox}  color={Colors.primaryBase} {...props} />)
export const SearchIcon = (props: Partial<IconProps>) => (<Icon component={MaterialSearchIcon} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const PersonIcon = (props: Partial<IconProps>) => (<Icon component={Person} backgroundColor={Colors.neutralVariant80} color={Colors.white} {...props} />)
export const CloseIcon = (props: Partial<IconProps>) => (<Icon color={Colors.bad} component={CloseSharpIcon} {...props} />)
export const ExpandMoreIcon = (props: Partial<IconProps>) => (<Icon color={Colors.bad} component={ExpandMore} {...props} />)
export const RoadmapExpandMoreIcon = (props: Partial<IconProps>) => (<Icon color={Colors.white} component={ExpandMore} size={BIG_ICON_SIZE} {...props} />)
export const RoadmapExpandLessIcon = (props: Partial<IconProps>) => (<Icon color={Colors.white} component={ExpandLess} size={BIG_ICON_SIZE} {...props} />)
export const NotificationCheckIcon = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} backgroundColor={Colors.primaryBase} color={Colors.white} component={Check} {...props} />)
export const LeadUserDropdownCheckIcon = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} color={Colors.primaryBase} component={Check} {...props} />)
export const NotificationErrorIcon = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} backgroundColor={Colors.bad} color={Colors.white} component={Error} {...props} />)
export const ClearIcon = (props: Partial<IconProps>) => (<Icon color={Colors.primaryBase} component={Clear} {...props} />)
export const HighlightOff = (props: Partial<IconProps>) => (<Icon color={Colors.black} component={_HighlightOff} {...props} />)
export const PostAdd = (props: Partial<IconProps>) => <Icon color={Colors.black} component={PostAddIcon} {...props} />
export const MoreHoriz = (props: Partial<IconProps>) => (<Icon size={20} component={MoreHorizIcon} backgroundColor={Colors.white} color={Colors.primaryBase} {...props} />)
export const KeyboardArrowRightIconWithBackground = (props: Partial<IconProps>) => (<Icon component={_KeyboardArrowRightIcon} backgroundColor={Colors.primaryBase} size={20} {...props} color={Colors.white} />)
export const KeyboardArrowDownIconWithBackground = (props: Partial<IconProps>) => (<Icon component={KeyboardArrowDown} backgroundColor={Colors.primaryBase} {...props} size={20} color={Colors.white} />)
export const KeyboardArrowUpIconWithBackground = (props: Partial<IconProps>) => (<Icon component={KeyboardArrowUp} backgroundColor={Colors.primaryBase} {...props} size={20} color={Colors.white} />)
export const PeopleIcon = (props: Partial<IconProps>) => (<Icon component={People} color={Colors.white} {...props} />)
export const MenuUnassignedGoalsIcon = (props: Partial<IconProps>) => (<Icon component={FlagOutlined} color={Colors.primaryBase} size={BIG_ICON_SIZE} {...props} />)
export const DashboardListGoalsIcon = (props: Partial<IconProps>) => (<Icon component={FlagOutlined} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const MenuDashboardIcon = (props: Partial<IconProps>) => (<Icon component={DashboardIcon} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const MenuBarChartIcon = (props: Partial<IconProps>) => (<Icon component={BarChartIcon} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const OnboardingArrowLeftIcon = (props: Partial<IconProps>) => (<Icon component={_KeyboardArrowLeftIcon} size={MENU_ARROW_DOWN_SIZE} color={Colors.primaryBase} {...props} />)
export const HelpOutlineIcon = (props: Partial<IconProps>) => (<Icon component={_HelpOutlineIcon} size={TRENDING_ICON_SIZE} color={Colors.neutralUnselected} {...props} />)
export const ChatIcon = (props: Partial<IconProps>) => (<Icon component={_ChatIcon} size={TRENDING_ICON_SIZE} color={Colors.neutralUnselected} {...props} />)
export const MenuExpandIcon = (props: Partial<IconProps>) => (<Icon component={ArrowForwardRoundedIcon} color={Colors.white} backgroundColor={Colors.primaryBase} {...props} />)
export const MenuCollapseIcon = (props: Partial<IconProps>) => (<Icon component={ArrowBackRoundedIcon} color={Colors.white} backgroundColor={Colors.primaryBase} {...props} />)
export const InsertLink = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} component={InsertLinkIcon} color={Colors.primaryBase} {...props} />)
export const CheckCircleRounded = (props: Partial<IconProps>) => (<Icon component={CheckCircleRoundedIcon} color={Colors.good} backgroundColor={Colors.white} {...props} />)
export const ErrorRounded = (props: Partial<IconProps>) => (<Icon component={ErrorRoundedIcon} color={Colors.bad} backgroundColor={Colors.white} {...props} />)
export const Celebrate = (props: Partial<IconProps>) => (<Icon color={Colors.black} component={CelebrationIcon} {...props} />)
export const OpenInFull = (props: Partial<IconProps>) => (<Icon component={OpenInFullIcon} color={Colors.primaryBase} {...props} />)
export const CloseFullScreen = (props: Partial<IconProps>) => (<Icon component={CloseFullscreenIcon} color={Colors.primaryBase}  {...props} />)
export const CloseDetailViewIcon = (props: Partial<IconProps>) => (<Icon component={CancelRoundedIcon} color={Colors.neutral} backgroundColor={Colors.whiteBg} size={BIG_ICON_SIZE} {...props} />)
export const GraphTinyFlag = (props: Partial<IconProps>) => (<Icon component={FlagCircleIcon} color={Colors.primaryBase} backgroundColor={Colors.whiteBg} size={BIG_ICON_SIZE} {...props} />)
export const ActivityIcon = (props: Partial<IconProps>) => (<Icon component={CheckCircleRoundedIcon} color={Colors.primaryBase} backgroundColor={Colors.white} {...props} />)
export const MenuActivityBoardIcon = (props: Partial<IconProps>) => (<Icon component={CheckCircleOutlineIcon} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const MyFocusActivityCheckIcon = (props: Partial<IconProps>) => (<Icon component={CheckCircleIcon} color={Colors.neutralVariant60} border={Colors.neutralVariant80} size={BIG_ICON_SIZE} {...props} />)
export const MyFocusActivityInProgressCheckIcon = (props: Partial<IconProps>) => (<Icon component={CheckCircleIcon} color={Colors.statusInProgress} backgroundColor={Colors.whiteBg} size={BIG_ICON_SIZE} {...props} />)
export const MyFocusActivityDoneCheckIcon = (props: Partial<IconProps>) => (<Icon component={CheckCircleIcon} color={Colors.statusDone} size={BIG_ICON_SIZE} {...props} />)
export const MyActionsMyFocusCheckIcon = (props: Partial<IconProps>) => (<Icon component={CheckCircleIcon} color={Colors.neutral80} size={BIG_ICON_SIZE} {...props} />)
export const MyActionsMyFocusDoneCheckIcon = (props: Partial<IconProps>) => (<Icon component={CheckCircleIcon} color={Colors.primaryBase} size={BIG_ICON_SIZE} {...props} />)
export const MyActionsMyFocusDropDownIcon = (props: Partial<IconProps>) => (<Icon component={ArrowDropDownIcon} color={Colors.primaryBase} size={BIG_ICON_SIZE} {...props} />)
export const PageHeaderButtonMyTodosIcon = (props: Partial<IconProps>) => (<Icon component={CheckCircleRoundedIcon} color={Colors.whiteBg } backgroundColor={Colors.primaryBase} size={BIG_ICON_SIZE} {...props} />)