import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'
import { openIntegrationsTutorial } from '../libs/StonlyHelper'
import ConnectionType from '../ygdrasil/enums/ConnectionType'

export function EditDatasourceMachineViewEditSheetErrorTutorialMessage({
  connectionType
}: {
  connectionType: ConnectionType
}) {
  return (
    <Box onClick={() => openIntegrationsTutorial(connectionType)}>
      <FigmaTextWithStyleOverrides
        textKeys={{ en: Texts.adminIntegrationsCreateConnectionWizardStep2SheetErrorMessageTutorial }}
      />
    </Box>
  )
}
