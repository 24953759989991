/* eslint-disable react/display-name */
import { Client, ClientOptions } from '@microsoft/microsoft-graph-client'
import { useEffect, useState } from 'react'
import { acquireTokenForConnection } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { captureException } from '../ygdrasil/libs/SentryHelperDeprecated'
import { Connection } from '../ygdrasil/types/types'
import useAppState from './useAppState'

type Query<T> = { data?: T; isLoading: boolean; error?: Error }

export const useMsalGraphQuery = <T>(opts: { url?: string; connection?: Connection; mapData?: (obj) => T }) => {
  const { mapData = (obj) => obj.value, url, connection } = opts
  const { state } = useAppState()
  const [query, setQuery] = useState<Query<T>>({ isLoading: false })

  useEffect(() => {
    if (!url) return
    if (!connection) return
    const clientOptions: ClientOptions = {
      authProvider: {
        getAccessToken: () =>
          acquireTokenForConnection({ connectionId: connection._id }, state).then(({ accessToken }) => accessToken)
      }
    }

    setQuery({ ...query, isLoading: true })
    Client.initWithMiddleware(clientOptions)
      .api(url)
      .get()
      .then((obj) => setQuery({ ...query, data: mapData(obj), isLoading: false, error: undefined }))
      .catch((error) => {
        setQuery({ ...query, isLoading: false, error, data: undefined })
        captureException(error)
      })
  }, [connection, url])

  return query
}
