import { FormikProps } from 'formik'
import { createWithContent, createWithText, EditorState, stringify } from '../libs/WYSIWYGEditor'
import EventCategories from '../ygdrasil/enums/EventCategories'
import { createUniqueId } from '../ygdrasil/libs/Common'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { Organization, StrategicPillar, SWOT, SWOTItem, Vision } from '../ygdrasil/types/types'
import { BusinessPlanDrawerFormType, FormRowType } from './BusinessPlanDrawerForm'

type VisionType = Vision & { _richDescription: EditorState }

const BUSINESS_PLAN_DRAWER_FORM_VISION = {
  headerTextKey: Texts.rightPanelHeaderVision,
  rows: [
    { labelTextKey: Texts.rightPanelShortVersion, name: 'name' },
    { labelTextKey: Texts.rightPanelLongVersion, name: '_richDescription', type: FormRowType.RICH_TEXT },
    { labelTextKey: Texts.rightPanelLonkToInfo, name: 'url', type: FormRowType.URL }
  ],
  onSubmit: (vision: VisionType, org: Organization) => {
    return {
      ...org,
      vision: {
        ...vision,
        _richDescription: undefined,
        richDescription: stringify(vision._richDescription)
      }
    }
  },
  getIntitialValues: (org: Organization): VisionType => {
    return {
      name: '',
      description: '',
      url: '',
      ...org.vision,
      _richDescription: org.vision?.richDescription
        ? createWithContent(org.vision?.richDescription)
        : createWithText(org.vision?.description || '')
    } as VisionType
  },
  eventCategory: EventCategories.UserUpdatesVision
}

export const BUSINESS_PLAN_DRAWER_FORM_ADD_VISION: BusinessPlanDrawerFormType = BUSINESS_PLAN_DRAWER_FORM_VISION

export const BUSINESS_PLAN_DRAWER_FORM_EDIT_VISION: BusinessPlanDrawerFormType = BUSINESS_PLAN_DRAWER_FORM_VISION

export const createBusienssPlanDrawerFormAddValue: (index: number) => BusinessPlanDrawerFormType = (index: number) => ({
  headerTextKey: Texts.rightPanelHeaderValues,
  rows: [{ labelTextKey: Texts.rightPanelValueDescriptionLabel, name: 'value', type: FormRowType.REQUIRED_TEXT }],
  onSubmit: ({ value }: any, org: Organization) => {
    const values = org.businessPlan.values || []
    if (values.some((v) => v === value)) return org
    values[index] = value
    return { ...org, businessPlan: { ...org.businessPlan, values } }
  },
  getIntitialValues: (): object => ({ value: '' }),
  eventCategory: EventCategories.UserAddCoreValue
})

export const createBusienssPlanDrawerFormEditValue: (index: number) => BusinessPlanDrawerFormType = (
  index: number
) => ({
  ...createBusienssPlanDrawerFormAddValue(index),
  rows: [{ labelTextKey: Texts.rightPanelEditValueDescriptionLabel, name: 'value', type: FormRowType.REQUIRED_TEXT }],
  getIntitialValues: (org: Organization): object => ({ value: org.businessPlan.values[index] || '' }),
  onDelete: (org: Organization) => {
    const values = org.businessPlan.values.filter((val, i) => i !== index)
    return { ...org, businessPlan: { ...org.businessPlan, values } }
  },
  eventCategory: EventCategories.UserEditCoreValue,
  removeEventCategory: EventCategories.UserDeletesCoreValue
})

export const createBusienssPlanDrawerFormAddStrategicPillar: (index: number) => BusinessPlanDrawerFormType = (
  index: number
) => ({
  headerTextKey: Texts.rightPanelHeaderStrategicPillars,
  rows: [
    {
      labelTextKey: Texts.rightPanelStrategicPillarNewDescriptionLabel,
      name: 'name',
      type: FormRowType.REQUIRED_TEXT
    },
    {
      labelTextKey: Texts.rightPanelStrategicPillarExtendedDescriptionLabel,
      name: 'description',
      multiline: true
    }
  ],
  onSubmit: ({ name, description }: any, org: Organization) => {
    const strategicPillars = org.businessPlan.strategicPillars || []
    if (strategicPillars.some((v) => v.name === name)) return org
    strategicPillars[index] = { name, _id: createUniqueId(), description }
    return { ...org, businessPlan: { ...org.businessPlan, strategicPillars } }
  },
  getIntitialValues: (): object => ({
    name: '',
    description: ''
  }),
  eventCategory: EventCategories.UserAddFocusArea,
  suggestionConfig: {
    onNewSuggestion: (name: string, formProps: FormikProps<object>) => {
      formProps.setFieldValue('name', name)
    },
    isVisible: ({ values: { description } }) => (description?.length || 0) > 0
  }
})

export const createBusienssPlanDrawerFormEditStrategicPillar: (index: number) => BusinessPlanDrawerFormType = (
  index: number
) => ({
  ...createBusienssPlanDrawerFormAddStrategicPillar(index),
  rows: [
    {
      labelTextKey: Texts.rightPanelEditStrategicPillarDescriptionLabel,
      name: 'name',
      type: FormRowType.REQUIRED_TEXT
    },
    {
      labelTextKey: Texts.rightPanelStrategicPillarExtendedDescriptionLabel,
      name: 'description',
      multiline: true
    }
  ],
  getIntitialValues: (org: Organization): object => ({
    name: org.businessPlan.strategicPillars[index]?.name || '',
    description: org.businessPlan.strategicPillars[index]?.description || ''
  }),
  onSubmit: ({ name, description }: any, org: Organization) => {
    const strategicPillars = org.businessPlan.strategicPillars || []
    strategicPillars[index] = { ...strategicPillars[index], name, description }
    return { ...org, businessPlan: { ...org.businessPlan, strategicPillars } }
  },
  onDelete: (org: Organization) => {
    const strategicPillar = org.businessPlan.strategicPillars[index]
    // TODO WRITE TEST should emit ON_DELETE_STRATEGIC_PILLAR when deleting strategic pillar
    eventEmitter.emit(Events.ON_DELETE_STRATEGIC_PILLAR, strategicPillar)
    const strategicPillars = org.businessPlan.strategicPillars.filter(({ _id }, i) => strategicPillar._id !== _id)
    return { ...org, businessPlan: { ...org.businessPlan, strategicPillars } }
  },
  eventCategory: EventCategories.UserUpdatesFocusArea,
  removeEventCategory: EventCategories.UserDeletesFocusArea
})

export const createBusinessPlanDrawerFormAddSwot: (swotItem: SWOTItem) => BusinessPlanDrawerFormType = (
  swotItem: SWOTItem
) => {
  const { headerTextKey, labelTextKey } = getTextKeysForSwotItem(swotItem)
  return {
    headerTextKey,
    rows: [{ labelTextKey, name: swotItem, multiline: true, type: FormRowType.RICH_TEXT }],
    getIntitialValues: (org: Organization) => ({ [swotItem]: createWithContent(org.businessPlan.swot[swotItem]) }),
    onSubmit: (swot: SWOT, org: Organization): Organization => {
      // eslint-disable-next-line prettier/prettier
      swot = Object.keys(swot).reduce((a, key) => {
        a[key] = stringify(swot[key])
        return a
      }, {} as SWOT)
      return {
        ...org,
        businessPlan: { ...org.businessPlan, swot: { ...org.businessPlan.swot, ...swot } }
      }
    },
    eventCategory: EventCategories.UserUpdatesSWOT
  }
}

export const createBusinessPlanDrawerFormEditSwot: (swotItem: SWOTItem) => BusinessPlanDrawerFormType = (
  swotItem: SWOTItem
) => ({
  ...createBusinessPlanDrawerFormAddSwot(swotItem),
  removeEventCategory: EventCategories.UserUpdatesSWOT,
  onDelete: (org: Organization) => ({
    ...org,
    businessPlan: { ...org.businessPlan, swot: { ...org.businessPlan.swot, [swotItem]: undefined } }
  })
})
function getTextKeysForSwotItem(swotItem: SWOTItem): {
  headerTextKey: AntiloopTextType
  labelTextKey: AntiloopTextType
} {
  switch (swotItem) {
    case SWOTItem.Strength:
      return {
        headerTextKey: Texts.rightPanelSwotStrenghHeader,
        labelTextKey: Texts.rightPanelSwotEditStrengthLabel
      }
    case SWOTItem.Weaknesses:
      return {
        headerTextKey: Texts.rightPanelSwotEditWeaknessesHeader,
        labelTextKey: Texts.rightPanelSwotEditWeaknessesLabel
      }
    case SWOTItem.Opportunities:
      return {
        headerTextKey: Texts.rightPanelSwotEditOpportunityHeader,
        labelTextKey: Texts.rightPanelSwotEditOpportunityLabel
      }
    case SWOTItem.Threats:
      return {
        headerTextKey: Texts.rightPanelSwotEditThreatsHeader,
        labelTextKey: Texts.rightPanelSwotEditThreatsLabel
      }
    default:
      throw new Error('Unknown SwotItem: ' + swotItem)
  }
}
