import React, { useEffect, useState } from 'react'
import { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import Texts from '../ygdrasil/libs/Texts'
import { Activity } from '../ygdrasil/types/types'
import Box from './Box'
import { smallSpacing } from '../enums/Spacings'
import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { ActivityBoardKanbanAddActivityButton } from './ActivityBoardKanbanAddActivityButton'
import { ActivityBoardKanbanCard } from './ActivityBoardKanbanCard'
import * as ActivityBoardKanbanEmptyState from './ActivityBoardKanbanEmptyState'
import Button from './Button'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { NoWrapFigmaText } from './StyledComponents'
import FigmaText from '../ygdrasil/components/FigmaText'
import { ActivityBoardKanbanProps } from './ActivityBoardKanban'
import { ActivityBoardColumnType } from '../libs/ActivityBoardHelper'
import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import dayjs from 'dayjs'

const MAX_COMPLETED_ACTIVITIES_LENGTH = 4

type Props = {
  provided: DroppableProvided
  activityStatus: string
  props: ActivityBoardKanbanProps
  column: ActivityBoardColumnType<Item>
  onCreateEditActivity: (a: Activity) => void
  item: Item
  onDeleteActivity: (a: Activity) => void
  snapshot: DroppableStateSnapshot
}

export function ActivityBoardKanbanColumn({
  provided,
  snapshot,
  activityStatus,
  props,
  column,
  onCreateEditActivity,
  item,
  onDeleteActivity
}: Props): React.ReactElement<HTMLElement, string | React.JSXElementConstructor<any>> {
  const {
    ColumnContainer = ActivityBoardKanbanEmptyState.ActivityBoardColumnContainer,
    enableColumnHeader = true,
    enableEmptyState = true,
    enableFilterCompletedActivities = true
  } = props

  const [onHover, setOnHover] = useState<boolean>()

  const activities = column.items
  const today = dayjs()
  const daysDifference = (lastUpdatedDate) => {
    return today.diff(lastUpdatedDate, 'day')
  }

  const [shouldShowShowAllButton, setShouldShowShowAllButton] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (shouldShowShowAllButton !== undefined) return
    if (
      enableFilterCompletedActivities &&
      activityStatus === ActivityStatus.DONE &&
      activities.length > MAX_COMPLETED_ACTIVITIES_LENGTH
    )
      setShouldShowShowAllButton(true)
  }, [activities])

  const shouldFilter = shouldShowShowAllButton === true

  const filteredActivities = activities.filter((item) => {
    const activity = item.data.data as Activity
    if (activity.status === ActivityStatus.DONE && daysDifference(activity.updatedAt) > 7) return false
    return true
  })

  let activitiesToRender = activities

  if (shouldFilter) activitiesToRender = filteredActivities.slice(0, MAX_COMPLETED_ACTIVITIES_LENGTH)

  const hasFitleredActivities = activitiesToRender.length < activities.length

  // TODO WRITE TEST, should fitler activities if required by user and we have more activites than MAX_COMPLETED_ACTIVITIES_LENGTH
  const _shouldShowShowAllButton = shouldShowShowAllButton === true && hasFitleredActivities

  const { isDraggingOver } = snapshot
  const onClickShowAll = () => setShouldShowShowAllButton(false)

  const getColumnHeader = () => {
    switch (activityStatus) {
      case ActivityStatus.TODO:
        return `linear-gradient(to left, ${Colors.burlywood}, ${Colors.chocolate})`
      case ActivityStatus.IN_PROGRESS:
        return `linear-gradient(to left, ${Colors.mediumturquoise}, ${Colors.darkblue})`
      case ActivityStatus.DONE:
        return `linear-gradient(to left, ${Colors.goodDark}, ${Colors.secondary20})`
      default:
        return ''
    }
  }

  return (
    <ColumnContainer
      ref={provided.innerRef}
      {...provided.droppableProps}
      isMiddleColumn={activityStatus === ActivityStatus.IN_PROGRESS}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      {enableColumnHeader && (
        <ColumnHeader
          fullWidth
          fullPadding
          spacing={smallSpacing}
          align="flex-start"
          justify="center"
          $color={getColumnHeader()}
        >
          <NoWrapFigmaText textKey={Texts.cssStatusPanelHeaderText} text={column.title} />
        </ColumnHeader>
      )}
      {activitiesToRender.map((item, index) => (
        <Box key={`${item.id}${index}`} fullWidth left right spacing={smallSpacing}>
          <ActivityBoardKanbanCard
            item={item}
            index={index}
            onCreateEditActivity={onCreateEditActivity}
            onDeleteActivity={onDeleteActivity}
          />
          {index < activities.length - 1 && <Box fullWidth bottom spacing={smallSpacing} />}
        </Box>
      ))}

      {[ActivityStatus.TODO, ActivityStatus.IN_PROGRESS].includes(activityStatus as ActivityStatus) && (
        <Box fullWidth fullPadding spacing={smallSpacing}>
          <ActivityBoardKanbanAddActivityButton
            status={activityStatus as ActivityStatus}
            item={item}
            onCreateEditActivity={onCreateEditActivity}
            visible={onHover && !isDraggingOver}
            fullWidth
            leadUserId={props.leadUserId}
            Component={({ onClick }) => (
              <Box fullWidth pointer onClick={onClick}>
                <FigmaTextForStrategyModel textKey={Texts.activityColumnActionText} />
              </Box>
            )}
          />
        </Box>
      )}

      {_shouldShowShowAllButton && (
        <Box fullWidth alignText="center" top bottom spacing={smallSpacing} link onClick={onClickShowAll}>
          <FigmaText textKey={Texts.actionBoardShowAllDoneActivitiesForIndicator} />
        </Box>
      )}

      {enableEmptyState && activityStatus === ActivityStatus.TODO && activities.length === 0 && (
        <Box fullWidth fullPadding>
          <ActivityBoardKanbanAddActivityButton
            {...props}
            status={activityStatus as ActivityStatus}
            onCreateEditActivity={onCreateEditActivity}
            Component={(props) => (
              <Button buttonType="empty_state" {...props}>
                <FigmaTextForStrategyModel textKey={Texts.actionBoardCreateFirstAction} />
              </Button>
            )}
          />
        </Box>
      )}
      {provided.placeholder}
    </ColumnContainer>
  )
}

const ColumnHeader = styled(Box)<{ $color: string }>`
  margin-bottom: 12px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-image-source: ${({ $color }) => $color};
`
