import React from 'react'
import styled from '@emotion/styled'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { minBorderRadius } from '../enums/BorderRadixes'
import { minSpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'
import FigmaText from '../ygdrasil/components/FigmaText'
import { NoWrapFigmaText } from './StyledComponents'

export const StrategyViewObjectivesHeaderToggleButton = ({
  textKey,
  onClick,
  isSelected
}: {
  textKey: AntiloopTextType
  onClick: () => unknown
  isSelected: boolean
}) => {
  const Container = isSelected ? SelectedButtonContainer : UnSelectedButtonContainer
  return (
    <Container left right align="center" onClick={onClick}>
      <Box spacing={minSpacing}>
        <NoWrapFigmaText textKey={textKey} />
      </Box>
    </Container>
  )
}

const UnSelectedButtonContainer = styled(Box)`
  background: 'transparent';
  cursor: pointer;
`

const SelectedButtonContainer = styled(UnSelectedButtonContainer)`
  background: ${Colors.whiteBg};
  border: 1px solid ${Colors.primary10};
  border-radius: ${minBorderRadius};
`
