import styled from '@emotion/styled'
import { useState } from 'react'
import { bigSpacing, defaultSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { PERIOD_SELECTOR_MODAL_MIN_WIDTH } from '../libs/HardCodedSizes'
import { MODAL_SLIDER_START_DATE } from '../libs/ModalSelectPeriodHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getDefaultSearchDates } from '../ygdrasil/libs/SearchPeriodHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import Modal, { ModalProps } from './Modal'
import { ObjectivePeriodAndTypeOptionsCustomForm } from './ObjectivePeriodAndTypeOptionsCustomForm'
import ObjectivePeriodAndTypeOptionsSlider from './ObjectivePeriodAndTypeOptionsSlider'
import { GreyLine } from './StyledComponents'

type Props = ModalProps
export function ModalSelectPeriod(props: Props) {
  const { onClose } = props
  const { state, setSearchState } = useAppState()
  const { searchState } = state
  const { searchDates = getDefaultSearchDates(state) } = state.searchState

  const [startDate, setStartDate] = useState(state.searchState.searchDates?.startDate as string)
  const [endDate, setEndDate] = useState(searchDates.endDate)

  const onSubmit = () => {
    onClose()
    setSearchState({ ...searchState, searchDates: { startDate, endDate } })
  }

  const onChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const setCustomStartDate = (startDate: string): unknown => setStartDate(startDate)

  const setCustomEndDate = (endDate: string): unknown => setEndDate(endDate)

  return (
    <Modal {...props}>
      <Container fullWidth align="center">
        <Box fullPadding>
          <FigmaText textKey={Texts.selectPeriodDialogHeader} />
        </Box>
        <GreyLine />
        <Box fullWidth top>
          <Box fullWidth right left spacing={bigSpacing}>
            <ObjectivePeriodAndTypeOptionsSlider
              searchDates={{ startDate, endDate }}
              onChange={onChange}
              sliderStartDate={MODAL_SLIDER_START_DATE}
            />
          </Box>
        </Box>
        <Box top fullWidth bottom>
          <ObjectivePeriodAndTypeOptionsCustomForm
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={setCustomStartDate}
            onChangeEndDate={setCustomEndDate}
          />
        </Box>
        <Box direction="row" align="center" justify="flex-end" fullWidth>
          <Box right spacing={tinySpacing}>
            <Button
              onClick={onClose}
              textKey={Texts.cancelButtonText}
              buttonType="secondary"
              spacing={defaultSpacing}
            />
          </Box>
          <Button onClick={onSubmit} spacing={defaultSpacing}>
            <FigmaText textKey={Texts.saveButtonText} />
          </Button>
        </Box>
      </Container>
    </Modal>
  )
}

const Container = styled(Box)`
  min-width: ${PERIOD_SELECTOR_MODAL_MIN_WIDTH}px;
`
