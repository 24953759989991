// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const shadows = {
  heavy: '-7px 28px 33px rgba(0, 0, 0, 0.1)',
  gazelleBoxSoft: '0px 0px 4px rgba(120, 120, 122, 0.4)',
  gazelleBoxHeavy: '0px 0px 6px rgba(120, 120, 122, 0.4)',
  gazelleSettings: '0px 8px 25px rgba(120, 120, 122, 0.4)',
  gazelleContentArea: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
  infoCard: '0px 1px 0px rgba(0, 0, 0, 0.1)',
  deep: '-25px 45px 35px rgba(0, 0, 0, 0.2)',
  medium: '-4px 30px 30px rgba(0, 0, 0, 0.15)',
  soft: '0px 2px 8px rgba(0, 0, 0, 0.3)'
}

export default shadows
