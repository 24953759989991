import styled from '@emotion/styled'
import { SMALL_SPACING_RADII } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { isSearching } from '../libs/StrategyViewObjectiveTreeHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'

export function DashboardObjectiveListItemActivitiesBadge({ item }: { item: Item }) {
  const { children } = item
  const { state } = useAppState()
  const _isSearching = isSearching(state.searchState)

  const noOfChildren = children.length

  if (noOfChildren === 0) return null

  const textkey = _isSearching
    ? Texts.objectiveListIndicatorActivityNumberOverlayTextSearch
    : Texts.objectiveListIndicatorActivityNumberOverlayText

  return (
    <ActivityLinksNumberContainer align="center" justify="center" isSearching={_isSearching}>
      <FigmaText textKey={textkey} text={noOfChildren.toString()} />
    </ActivityLinksNumberContainer>
  )
}

const ACTIVITY_LINKS_NUMBER_CONTAINER_RADII = SMALL_SPACING_RADII
const ACTIVITY_LINKS_NUMBER_CONTAINER_SIZE = ACTIVITY_LINKS_NUMBER_CONTAINER_RADII * 2
const ActivityLinksNumberContainer = styled(Box)<{ isSearching?: boolean }>`
  position: absolute;
  background-color: ${({ isSearching }) => (isSearching ? Colors.highlight : Colors.primaryBase)};
  border: 1px solid ${Colors.primaryBase};
  height: ${ACTIVITY_LINKS_NUMBER_CONTAINER_SIZE}px;
  width: ${ACTIVITY_LINKS_NUMBER_CONTAINER_SIZE}px;
  margin-left: ${ACTIVITY_LINKS_NUMBER_CONTAINER_SIZE}px;
  margin-top: ${ACTIVITY_LINKS_NUMBER_CONTAINER_SIZE}px;
  border-radius: ${ACTIVITY_LINKS_NUMBER_CONTAINER_RADII}px;
`
