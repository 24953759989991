import dayjs from 'dayjs'
import React from 'react'
import useAppState, { useUser } from '../hooks/useAppState'
import useTidio from '../hooks/useTidio'
import { loadLocale } from '../libs/DayJsHelper'
import { useEvents } from '../ygdrasil/hooks/useEvents'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { isOurBigPictureOrg, isOurBigPictureUser } from '../ygdrasil/hooks/useLoggedInConfig'
import useQueryParams, { UpdateValueInIndicatorQueryParams } from '../ygdrasil/hooks/useQueryParams'
import {
  applicationNotificationColleagueCelebrated,
  getTextForUnseenCelebrationEvent
} from '../ygdrasil/libs/CelebrationHelper'
import { getEvent, getUser, updateObjective, updateUser } from '../ygdrasil/libs/DBApiHandler'
import eventEmitter, { Events, upsertListener } from '../ygdrasil/libs/EventEmitter'
import { OurEvent, UserAddIndicatorValueParams, UserCelebratedEventParams } from '../ygdrasil/libs/EventHelper'
import { celebrateEvent } from '../ygdrasil/libs/ProcessHandler'
import { useObjectives, useOrganization } from '../ygdrasil/libs/QueryHooks'
import { logActiveOurBigPictureUser, logActiveUser } from '../ygdrasil/libs/SlackHelper'
import { StrategicPillar } from '../ygdrasil/types/types'

export default function StrategyViewOnMount() {
  const user = useUser()
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const { data: allObjectives = [] } = useObjectives(state)
  const { unseenEvents } = useEvents()
  const { celebratedEventId } = useQueryParams<UpdateValueInIndicatorQueryParams>()
  const logEvent = useLogEvent()
  useTidio()

  React.useEffect(() => {
    getUser(user._id).then((user) => user && updateUser({ ...user, lastActiveAt: dayjs().format() }))
  }, [])

  React.useEffect(() => {
    loadLocale(user.language)
  }, [user])

  React.useEffect(() => {
    if (!celebratedEventId) return
    if (!user) return
    getEvent<UserAddIndicatorValueParams>(celebratedEventId).then((event: OurEvent<UserAddIndicatorValueParams>) =>
      celebrateEvent({ event, indicator: event.params.indicator, objective: event.params.objective, user, logEvent })
    )
  }, [celebratedEventId, user])

  React.useEffect(() => {
    upsertListener(
      Events.NEW_ACTIVE_USER,
      // TODO WRITE TEST, should not log active user if ourbigpicture user
      (event: OurEvent<UserAddIndicatorValueParams>) => {
        const { indicator } = event.params
        !isOurBigPictureUser(state.user) && logActiveUser(indicator, org, user)
        isOurBigPictureUser(state.user) && isOurBigPictureOrg(org) && logActiveOurBigPictureUser(event, user)
      }
    )

    // TODO WRITE TEST, should clean objectives when removing objective
    upsertListener(Events.ON_DELETE_STRATEGIC_PILLAR, (sp: StrategicPillar) =>
      allObjectives
        .filter(({ strategicPillarIds }) => strategicPillarIds?.includes(sp._id))
        .forEach((o) =>
          updateObjective({ ...o, strategicPillarIds: o.strategicPillarIds.filter((spId) => spId !== sp._id) })
        )
    )
  }, [org, user, allObjectives])

  React.useEffect(() => {
    const event: OurEvent<UserCelebratedEventParams> = unseenEvents[0]
    if (!event) return

    eventEmitter.emit(Events.NEW_NOTIFICATION, {
      textKey: applicationNotificationColleagueCelebrated,
      text: getTextForUnseenCelebrationEvent(event, org)
    })
  }, [unseenEvents])

  return null
}
