import styled from '@emotion/styled'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { RequiredNameValidationDeprecated } from '../libs/ValidationHelper'
import { MIN_CONTAINER_WIDTH, ONBOARDING_ACCOMPLISH_BY_DEFAULT_VALUE } from '../ygdrasil/libs/Consts'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import Texts from '../ygdrasil/libs/Texts'
import { OnboardingViewProps, TimeFrameOption } from '../ygdrasil/types/Onboarding'
import Box from './Box'
import Button from './Button'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

const ValidationSchema = Yup.object().shape({
  name: RequiredNameValidationDeprecated('name'),
  timeFrame: RequiredNameValidationDeprecated('time frame'),
  accomplishBy: RequiredNameValidationDeprecated('accomplish by')
})

export default function OnboardingAddObjectives({ onPressContinue, onPressSkip, state }: OnboardingViewProps) {
  const [isLoading, setIsLoading] = React.useState(false)
  const initialValues = {
    name: getTextForTextKey(Texts.onboardingFirstObjectiveHelperText, undefined),
    timeFrame: TimeFrameOption.LONG_TERM,
    accomplishBy: ONBOARDING_ACCOMPLISH_BY_DEFAULT_VALUE
  }

  const onSubmit = (firstObjective) => onPressContinue({ ...state, data: { ...state.data, firstObjective } })
  const onClickSkip = () =>
    Promise.resolve(setIsLoading(true))
      .then(() => onPressSkip(state))
      .finally(() => setIsLoading(false))

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
      {(formProps) => (
        <Box fullWidth align="center">
          <Box fullWidth top align="center" spacing={bigSpacing}>
            <Box fullWidth align="center" bottom spacing={bigSpacing}>
              <FigmaTextForStrategyModel textKey={Texts.onboardingMobileFirstObjectiveHeader} />
            </Box>
            <ObjectiveContainer bottom spacing={bigSpacing} direction="row" align="center" justify="center" width="30%">
              <Box right flexGrow={1}>
                <TextFieldWithLabelFormik
                  labelTextKey={Texts.onboardingMobileFirstObjectiveLabel}
                  formProps={formProps}
                  name="name"
                  component={TextFieldOutlined}
                  fullWidth
                />
              </Box>
            </ObjectiveContainer>
            <Box
              fullWidth
              align="center"
              alignText="center"
              link
              onClick={() => onClickSkip()}
              bottom
              spacing={smallSpacing}
            >
              <FigmaTextLoader textKey={Texts.onboardingMobileSkipForNow} loading={isLoading} />
            </Box>
            <Box fullWidth align="center">
              <Button
                onClick={formProps.handleSubmit as any}
                loading={formProps.isSubmitting}
                textKey={Texts.onboardingMobileNextButtonText}
              ></Button>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  )
}

const ObjectiveContainer = styled(Box)`
  min-width: ${MIN_CONTAINER_WIDTH}px;
`
