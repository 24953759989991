import { Popper } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { GraphProps } from '../ygdrasil/hooks/useGraph'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Objective } from '../ygdrasil/types/types'
import Tooltip from './Tooltip'

export function KeyResultSliderPopper(props: {
  anchorEl?: HTMLElement
  item: Item
  parentItem: Item
  GraphComponent: React.FC<GraphProps>
}) {
  const { anchorEl, item, parentItem, GraphComponent } = props

  const objective = parentItem.data.data as Objective
  if (objective.isArchived) return null

  if (anchorEl && dayjs(objective.startDate).isAfter(dayjs()))
    return (
      <Tooltip open title={getFigmaText(Texts.graphEmptyText)}>
        <span />
      </Tooltip>
    )

  return (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
      <GraphComponent item={item} parentItem={parentItem} />
    </Popper>
  )
}
