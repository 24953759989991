import { FormikProps } from 'formik'
import React from 'react'
import { InitialValues } from '../libs/EditObjectiveDialogHelper'
import { formatValues } from '../libs/ObjectivePeriodAndTypeOptionsCustomFormHelper'
import { ObjectivePeriodAndTypeOptionsProps } from './ObjectivePeriodAndTypeOptions'
import { ObjectivePeriodAndTypeOptionsCustomForm } from './ObjectivePeriodAndTypeOptionsCustomForm'

type Props = ObjectivePeriodAndTypeOptionsProps

export function ObjectivePeriodAndTypeOptionsCustomFormFormik(props: Props) {
  const { formProps } = props
  const { startDate, endDate } = props.formProps.values

  const onSubmit = ({ startDate, endDate }, formProps: FormikProps<InitialValues>) =>
    formProps.validateForm({ ...formProps.values, startDate, endDate }).then((errors) => {
      console.log('errors', errors)
      if (Object.keys(errors).length > 0) return
      props.formProps.setValues(formatValues({ startDate, endDate }, formProps))
    })

  const onChangeStartDate = (startDate: string) => onSubmit({ ...formProps.values, startDate }, formProps)
  const onChangeEndDate = (endDate: string) => onSubmit({ ...formProps.values, endDate }, formProps)

  return (
    <ObjectivePeriodAndTypeOptionsCustomForm
      startDate={startDate}
      endDate={endDate}
      onChangeStartDate={onChangeStartDate}
      onChangeEndDate={onChangeEndDate}
    />
  )
}
