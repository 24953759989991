import { ALL_STRATEGIC_PILLAR } from '../ygdrasil/libs/Consts'
import { Organization, StrategicPillar } from '../ygdrasil/types/types'

export function shouldShowStrategicPillars(org?: Organization) {
  return hasStrategicPillars(org)
}

export function shouldShowStrategicPillarsInDashboard(org?: Organization) {
  return shouldShowStrategicPillars(org) && (org?.businessPlan?.strategicPillars?.length || 0) > 1
}

export function getStrategiPillarFilters(org?: Organization): StrategicPillar[] {
  const strategicPillars = org?.businessPlan?.strategicPillars || []
  return [ALL_STRATEGIC_PILLAR, ...strategicPillars]
}

export const hasStrategicPillars = (org?: Organization) => !!org?.businessPlan?.strategicPillars[0]
