import CloudFunction from '../enums/CloudFunctions'
import { State } from '../../hooks/useAppState'
import CloudFunctionError, { CloudFunctionErrorType } from './CloudFunctionError'
import { functions } from '../../libs/Firebase'
import { httpsCallable } from '@firebase/functions'

export const callFunction = (cloudfunction: CloudFunction, state: State, req?: any) =>
  httpsCallable(
    functions,
    cloudfunction
  )(req)
    .then(({ data }) => Promise.resolve(console.log('Success', cloudfunction)).then(() => data))
    .catch(onErrorFactory(cloudfunction, state)) as Promise<any>

const onErrorFactory = (functionName: CloudFunction, state: State) => (err: CloudFunctionErrorType) =>
  Promise.reject(new CloudFunctionError(err, functionName, state))
