import { Formik } from 'formik'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import Term from '../ygdrasil/enums/Term'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { DefaultTerms, getTerm, TermsItem, TermsItemKey } from '../ygdrasil/libs/TermsHelper'
import Texts from '../ygdrasil/libs/Texts'
import { Language } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import Button from './Button'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import * as Yup from 'yup'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import invariant from 'invariant'
import { updateTerminology } from '../ygdrasil/libs/ProcessHandler'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'

export function SettingsApplicationStrategyModelTermForm({
  term,
  language,
  onClose
}: {
  term: Term
  language: Language
  onClose: () => unknown
}) {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const logEvent = useLogEvent()

  const singular = getTerm(term, org, { language, termsItemKey: TermsItemKey.SINGULAR })
  invariant(singular, '!sinular')
  const plural = getTerm(term, org, { language, termsItemKey: TermsItemKey.PLURAL })
  const initilValues: TermsItem<string> = {
    singular,
    plural
  }

  const ValidationSchema = Yup.object().shape({
    singular: RequiredTextValidation(),
    plural: plural ? RequiredTextValidation() : Yup.object()
  })

  const onSubmit = (termItem: TermsItem<string>) => {
    if (!org) return
    onClose()

    return updateTerminology(
      {
        ...org,
        terms: { ...org?.terms, [language]: { ...org?.terms?.[language], [term]: termItem } }
      },
      logEvent
    )
  }

  return (
    <Box fullWidth>
      <Formik
        enableReinitialize
        initialValues={initilValues}
        onSubmit={(values) => {
          onSubmit(values)
        }}
        validationSchema={ValidationSchema}
      >
        {(formProps) => (
          <>
            <Box direction="row" spacing={tinySpacing} fullWidth>
              <TextFieldWithLabelFormik
                name={TermsItemKey.SINGULAR}
                labelTextKey={Texts.applicationSettingCustomizableSingularLabel}
                formProps={formProps}
                component={TextFieldOutlined}
                placeholderTextKey={[DefaultTerms[term].singular]}
                fullWidth
              />
              {plural && (
                <Box left spacing={tinySpacing} fullWidth>
                  <TextFieldWithLabelFormik
                    name={TermsItemKey.PLURAL}
                    labelTextKey={Texts.applicationSettingCustomizablePluralLabel}
                    formProps={formProps}
                    component={TextFieldOutlined}
                    placeholderTextKey={[DefaultTerms[term].plural]}
                    fullWidth
                  />
                </Box>
              )}
            </Box>
            <Box top fullWidth direction="row">
              <Button textKey={Texts.saveButtonText} onClick={() => formProps.handleSubmit()} spacing={smallSpacing} />
              <Box left spacing={smallSpacing}>
                <Button buttonType="secondary" onClick={onClose} spacing={tinySpacing} />
              </Box>
            </Box>
          </>
        )}
      </Formik>
    </Box>
  )
}
