import { smallSpacing, SMALL_SPACING_PX, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { DashboardObjectiveListItemActivitiesBadge } from './DashboardObjectiveListItemActivitiesBadge'
import FigmaTextWithSearchResult from './FigmaTextWithSearchResult'
import { DashboardListArrowDown, DashboardListArrowRight, DashboardListGoalsIcon } from './Icons'
import { StrategyViewObjectiveListItemSummaryDescriptionInfo } from './StrategyViewObjectiveListItemSummaryDescriptionInfo'
import { StrategyViewObjectiveListItemSummaryItem } from './StrategyViewObjectiveListItemSummaryItem'

export function StrategyViewObjectiveListItemSummary({
  item,
  FigmaTextComponent = FigmaTextWithSearchResult,
  level = 0,
  enableDescription = true,
  onClickHeader,
  onClickArrow,
  isExpanded
}: {
  item: Item
  level?: number
  FigmaTextComponent?: typeof FigmaTextWithSearchResult
  enableDescription?: boolean
  onClickHeader: () => unknown
  onClickArrow: () => unknown
  isExpanded: boolean | null
}) {
  const { data: o } = item
  const { state } = useAppState()
  const { searchState } = state
  const { searchText } = searchState

  if (o.searchType === 'activity') return null

  if (o.searchType === 'keyResult')
    return (
      <Box width="inherit" onClick={onClickHeader}>
        <Box direction="row" align="center" width="inherit">
          <Box right spacing={smallSpacing}>
            <DashboardListGoalsIcon />
            <DashboardObjectiveListItemActivitiesBadge item={item} />
          </Box>
          <Box right spacing={tinySpacing} width="inherit">
            <StrategyViewObjectiveListItemSummaryItem
              item={o}
              level={level}
              FigmaTextComponent={FigmaTextComponent}
              searchText={searchText}
              textKey={Texts.cssObjectiveListIndicatorRowSummaryText}
            />
          </Box>
          {enableDescription && <StrategyViewObjectiveListItemSummaryDescriptionInfo item={item} />}
        </Box>
      </Box>
    )

  return (
    <Box fullWidth direction="row" align="center" width="inherit">
      <Box
        style={{ marginLeft: -SMALL_SPACING_PX / 2, minWidth: '24px', opacity: item.hasChildren ? 1 : 0.3 }}
        onClick={onClickArrow}
      >
        {isExpanded && item.hasChildren ? <DashboardListArrowDown /> : <DashboardListArrowRight />}
      </Box>
      <Box right spacing={smallSpacing} width="inherit" onClick={onClickHeader}>
        <StrategyViewObjectiveListItemSummaryItem
          item={o}
          level={level}
          FigmaTextComponent={FigmaTextComponent}
          searchText={searchText}
          textKey={Texts.cssObjectiveListObjectiveSummaryText}
        />
      </Box>
      {enableDescription && <StrategyViewObjectiveListItemSummaryDescriptionInfo item={item} />}
    </Box>
  )
}
