import React from 'react'
import styled from '@emotion/styled'
import { Objective } from '../ygdrasil/types/types'
import { minBorderRadius } from '../enums/BorderRadixes'
import useIsSelectedObjective from '../hooks/useSelectedObjective'
import Colors from '../ygdrasil/libs/Colors'
import { HEADER_ACTION_CONTAINERSIZE } from '../libs/HardCodedSizes'
import Box from './Box'
import { MoreHoriz, MoreVert, MoreVertWhite } from './Icons'
import { IconProps } from './Icon'

export type MoreActionProps = {
  Component?: React.FC<Partial<IconProps>>
  visible?: boolean
  onClick?: (e: any) => void
  backgroundColor?: string
  color?: string
}

export const MoreActionRoadmapStrategicObjectiveHeader = (props: MoreActionProps & { objective: Objective }) => {
  const { isSelected } = useIsSelectedObjective(props.objective)
  return (
    <MoreAction
      {...props}
      backgroundColor={isSelected ? Colors.primaryBase : Colors.white}
      color={isSelected ? Colors.white : Colors.primaryBase}
    />
  )
}

export function MoreAction({ backgroundColor, onClick, visible, color }: MoreActionProps) {
  return (
    <Container
      direction="row"
      align="center"
      justify="center"
      onClick={onClick}
      link
      backgroundColor={backgroundColor}
      visible={visible}
    >
      <MoreHoriz backgroundColor={backgroundColor} color={color || Colors.white} />
    </Container>
  )
}

export function MoreActionVert({ onClick, visible, Component = MoreVert }: MoreActionProps) {
  return (
    <BaseContainer direction="row" align="center" justify="center" onClick={onClick} link visible={visible}>
      <Component />
    </BaseContainer>
  )
}

export const MoreActionVerWhite = (props: MoreActionProps) => <MoreActionVert {...props} Component={MoreVertWhite} />

const BaseContainer = styled(Box)<{
  backgroundColor?: string
  visible?: boolean
}>`
  width: ${HEADER_ACTION_CONTAINERSIZE};
  height: ${HEADER_ACTION_CONTAINERSIZE};
  ${({ visible }) => (!visible ? 'visibility: hidden;' : '')}
`

const Container = styled(BaseContainer)<{
  backgroundColor?: string
  visible?: boolean
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || Colors.primaryBase};
  border-radius: ${minBorderRadius};
`
