import React from 'react'
import { DotProps } from 'recharts'
import Colors from '../ygdrasil/libs/Colors'

const SIZE = 24
const R = SIZE / 2

export default function GraphFlagIcon(props: DotProps) {
  const { cx = 0, cy = 0 } = props
  return (
    <svg
      fill={Colors.primaryBase}
      x={cx - R}
      y={cy - R}
      xmlns="http://www.w3.org/2000/svg"
      height={SIZE}
      viewBox={`0 0 ${SIZE} ${SIZE}`}
      width={SIZE}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z" />
    </svg>
  )
}
