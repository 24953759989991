import styled from '@emotion/styled'
import Tab from '@mui/material/Tab'
import MaterialTabs from '@mui/material/Tabs'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import useAppState from '../hooks/useAppState'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'

type TabType = {
  tabKey?: number
  labelElement: React.ReactElement
  labelSelectedElement: React.ReactElement
  contentElement: React.ReactElement
  enable?: boolean
  default?: boolean
  onChangeTab?: () => unknown
}
type Props = { tabs: TabType[] }

export default function Tabs({ tabs: _tabs }: Props) {
  const history = useHistory()
  const { state, setState } = useAppState()
  const defaultIndex = _tabs.findIndex((t, index) => t.tabKey === state.settingsTabKey || t.default)
  const [value, setValue] = useState(defaultIndex > -1 ? defaultIndex.toString() : '0')
  const _value = parseInt(value)
  const tabs = _tabs.filter((t) => t.enable !== false)

  const handleChange = (event, newValue) => {
    setValue(newValue.toString())
    setState({ ...state, settingsTabKey: newValue })
    const onChangeTab = tabs[newValue]?.onChangeTab
    onChangeTab && onChangeTab()
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search])

  if (tabs.length === 1) return tabs[0].contentElement

  return (
    <Wrapper fullWidth>
      <MaterialTabs value={_value} onChange={handleChange} aria-label="card-account">
        {tabs.map(({ labelElement, labelSelectedElement }, i) => (
          <Tab label={_value === i ? labelSelectedElement : labelElement} {...a11yProps(i)} key={i} className="tab" />
        ))}
      </MaterialTabs>
      <Container fullWidth>
        {tabs.map(({ contentElement }, i) => (
          <TabPanel value={_value} index={i} key={i}>
            {contentElement}
          </TabPanel>
        ))}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(Box)<{ tabWidth?: string }>`
  .MuiTabs-indicator {
    background-color: ${Colors.gray};
  }

  .MuiTab-root {
    background-color: ${Colors.whiteBg};
    white-space: nowrap;
    align-items: center;
  }

  .Mui-selected {
    background-color: ${Colors.primaryBase};
  }
`

const Container = styled(Box)`
  position: relative;
`

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      fullWidth
      hidden={value !== index}
      id={`card-account-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `card-account-tabpanel-${index}`
  }
}
