import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState, { DEFAULT_STATE, State } from '../hooks/useAppState'
import useVisibleObjectives from '../ygdrasil/hooks/useVisibleObjectives'
import { SearchType } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { updateIndicator, updateObjective } from '../ygdrasil/libs/DBApiHandler'
import { DELETE_OBJECTIVE_MODAL_MAX_WIDTH } from '../libs/HardCodedSizes'
import FigmaText from '../ygdrasil/components/FigmaText'
import { findObjectiveForKeyResult } from '../ygdrasil/libs/ObjectiveHelper'
import { getLeadObjectsForUser } from '../ygdrasil/libs/SettingsNotificationsHelper'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Objective, User } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import Modal, { ModalProps } from './Modal'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { useAllVisibleItems } from '../ygdrasil/hooks/useAllVisibleItems'

type Props = { user: User; onClickConfirm?: () => unknown } & ModalProps
type LeadObject = { name: string; _id: string; type: SearchType }
export function ModalDeactivateUser(props: Props) {
  const { state, setState } = useAppState()
  const { user, onClose } = props
  const allItems = useAllVisibleItems()
  const leadObjects = getLeadObjectsForUser(allItems, user)
  const _leadobjects: LeadObject[] = [
    ...leadObjects.objectives.map(({ name, _id }) => ({ name, type: 'objective' as SearchType, _id })),
    ...leadObjects.keyResults.map(({ name, _id }) => ({ name, type: 'keyResult' as SearchType, _id }))
  ]

  const onClickConfirm = () => {
    onClose()
    leadObjects.objectives.forEach((o) => updateObjective({ ...o, leadUserId: undefined, leadMetaData: undefined }))
    leadObjects.keyResults.forEach((kr) => {
      const o = findObjectiveForKeyResult(kr, allItems)
      if (!o) return
      updateIndicator({ ...kr, leadUserId: undefined, leadMetaData: undefined })
    })
    props.onClickConfirm && props.onClickConfirm()
  }

  const onClickCancel = onClose
  const onClickLeadObject = ({ _id, type }: LeadObject) => {
    if (type !== 'objective') return
    onClose()
    const newState = {
      ...state,
      showSettings: false,
      selectedDashboardView: 'list'
    } as State
    const searchState = { ...DEFAULT_STATE.searchState }

    searchState.selectedObjectiveId = _id

    setState({ ...newState, searchState })
  }

  return (
    <Modal {...props}>
      <Box fullWidth fullPadding spacing={smallSpacing}>
        <Box fullWidth bottom>
          <Box bottom spacing={smallSpacing}>
            <FigmaText
              textKey={Texts.confirmUserDeactivationHeader}
              text={formatText(getFigmaText(Texts.confirmUserDeactivationHeader), [user.name])}
            />
          </Box>
          <Box bottom spacing={smallSpacing}>
            <FigmaText textKey={Texts.confirmUserDeactivationText1} />
          </Box>
          <Box bottom spacing={smallSpacing}>
            {_leadobjects.map((o) => (
              <Box onClick={() => onClickLeadObject(o)} key={o._id} pointer={o.type === 'objective'}>
                <FigmaText
                  textKey={Texts.confirmUserDeactivationLink}
                  text={formatText(getFigmaText(Texts.confirmUserDeactivationLink), [o.name])}
                />
              </Box>
            ))}
          </Box>
          <Box bottom spacing={smallSpacing}>
            <FigmaTextForStrategyModel textKey={Texts.confirmUserDeactivationText2} />
          </Box>
          <FigmaText textKey={Texts.confirmUserDeactivationText3} />
        </Box>
        <Box direction="row" align="center" justify="flex-end" fullWidth>
          <Box right spacing={smallSpacing}>
            <Button
              buttonType="secondary"
              spacing={tinySpacing}
              onClick={onClickCancel}
              textKey={Texts.cancelButtonText}
            />
          </Box>
          <Button onClick={onClickConfirm} textKey={Texts.confirmButtonText} spacing={tinySpacing} />
        </Box>
      </Box>
    </Modal>
  )
}
