import formatDistance from 'date-fns/formatDistance'
import { sv, enGB } from 'date-fns/locale'
import { EventParams, OurEvent } from '../libs/EventHelper'
import { getLanguageCode } from '../libs/useLanguage'
import { User } from '../types/types'

export const useDistanceTextForEvent = (
  event: OurEvent<Partial<EventParams>>,
  { language = getLanguageCode() }: User
): string => {
  const locale = { sv, en: enGB }[language]
  return formatDistance(
    new Date(),
    new Date(event.createdAt),
    { locale } // Pass the locale as an option
  )
}
