import { Dayjs, OpUnitType } from 'dayjs'
import type { AxisDomain } from 'recharts/types/util/types'
import type { Result } from 'regression'
import { Language } from '../types/Antiloop'
import { KeyResult, Objective, Unit, Value } from '../types/types'
import Colors from './Colors'
import * as Common from './Common'
import { getArray } from './Common'
import { TEN_PERCENT, TWENTY_PERCENT } from './Consts'
import { DayJS } from './DayJsHelper'
import { ASCENDING, DESCENDING, getDecimals, getDirection } from './KeyResultHelper'
import { addValueToValues } from './ObjectiveHelper'

const Y_AXIS_TICKS = 2
const ONE_THOUSAND = 1000
const ONE_MILLION = 1000 * 1000
const OFFSET = 1 // Case Okt you end up with 2 ticks called 1 Okt on x axis without this
const ONE_MONTH = (31 * 24 + OFFSET) * 60 * 60 * 1000
const ONE_QUARTER = 3 * ONE_MONTH
const HALF_YEAR = 6 * ONE_MONTH
const YEAR = 12 * ONE_MONTH
export const RETAIN_INTERVAL = 0.2

export enum GraphType {
  GOOD,
  BAD,
  ORANGE,
  GRAY,
  PURPLE
}

export type Payload = { timestamp: number; value?: number; base: number; top: number }

type GraphDuration = {
  formatFn: (date: Dayjs, lang: Language) => string
  numberOfTicks: number
  diff: number
  getStartDate: (date: string) => Dayjs
  name: string
}

const now = DayJS()
const formatFunctionMonth = (date: Dayjs, lang: Language) =>
  DayJS(date).format(GRAPH_DATE_FORMAT_MONTH[lang] || GRAPH_DATE_FORMAT_MONTH.default)

const formatFunctionYear = (date: Dayjs, lang: Language) =>
  DayJS(date).format(GRAPH_DATE_FORMAT_YEAR[lang] || GRAPH_DATE_FORMAT_YEAR.default)

const formatFunctionHalfYear = (date: Dayjs, lang: Language) =>
  DayJS(date).format(GRAPH_DATE_FORMAT_HALF_YEAR[lang] || GRAPH_DATE_FORMAT_HALF_YEAR.default)

const GRAPH_DATE_FORMAT_MONTH: { [lang: string]: string } = {
  en: 'MMM 1',
  sv: '1 MMM',
  defailt: '1 MMM'
}

const GRAPH_DATE_FORMAT_HALF_YEAR: { [lang: string]: string } = {
  en: 'YYYY MMM',
  sv: 'YYYY MMM',
  defailt: 'YYYY MMM'
}

const GRAPH_DATE_FORMAT_YEAR: { [lang: string]: string } = {
  en: 'YYYY',
  sv: 'YYYY',
  defailt: 'YYYY'
}

export const Durations = {
  month: {
    name: 'month',
    getStartDate: (date) => DayJS(date).startOf('month'),
    formatFn: formatFunctionMonth,
    diff: ONE_MONTH,
    numberOfTicks: 4
  },
  sixMonths: {
    name: 'sixMonths',
    getStartDate: (date) => DayJS(date).startOf('month'),
    formatFn: formatFunctionMonth,
    diff: ONE_MONTH,
    numberOfTicks: 7
  },
  quarter: {
    name: 'quarter',
    getStartDate: (date) => DayJS(date).startOf('quarter'),
    formatFn: formatFunctionMonth,
    diff: ONE_QUARTER,
    numberOfTicks: 5
  },
  sixQuaters: {
    name: 'quarter',
    getStartDate: (date) => DayJS(date).startOf('quarter'),
    formatFn: formatFunctionMonth,
    diff: ONE_QUARTER,
    numberOfTicks: 7
  },
  halfYear: {
    name: 'halfYear',
    getStartDate: (date) => DayJS(date).startOf('quarter'),
    formatFn: formatFunctionHalfYear,
    diff: HALF_YEAR,
    numberOfTicks: 5
  },
  year: {
    name: 'year',
    getStartDate: (date) => DayJS(date).startOf('year'),
    formatFn: formatFunctionYear,
    diff: YEAR,
    numberOfTicks: 5
  }
}

// Use this Hristo and Nadya if you want. If you think letting the graph free (open source sensible defaults) then test it out!
export const getDuration = ({ endDate, startDate }: Objective): GraphDuration => {
  const months = DayJS(endDate).add(1, 'quarter').diff(DayJS(startDate), 'month')
  if (months <= 5) return { ...Durations.month, numberOfTicks: months + 2 }
  if (months <= 6) return Durations.sixMonths
  if (months <= 12) return Durations.quarter
  if (months <= 18) return Durations.sixQuaters
  if (months <= 24) return Durations.halfYear
  return { ...Durations.year, numberOfTicks: Math.max(5, Math.ceil(months / 12)) }
}

export const getDiffForUnitOfTime = (unitOfTime: OpUnitType) =>
  DayJS(now).endOf(unitOfTime).valueOf() - DayJS(now).startOf(unitOfTime).valueOf()

export const getColorForGraphType = (type: GraphType) => {
  if (type === GraphType.GRAY) return Colors.neutralUnselected
  if (type === GraphType.BAD) return Colors.bad
  if (type === GraphType.ORANGE) return Colors.risk
  if (type === GraphType.PURPLE) return Colors.primaryBase
  return Colors.good
}

export const getGraphType = (now: Dayjs, keyResult: KeyResult, objective: Objective) => {
  const { values = [], startValue, goalValue } = keyResult

  const baselineValue = getBaselineValue(now, keyResult, objective)
  const maxValue = Math.max(Math.abs(startValue), Math.abs(goalValue))
  const redDelta = maxValue * TWENTY_PERCENT
  const orangeDelta = maxValue * TEN_PERCENT
  const { value: lastValue } = values[values.length - 1] || { value: startValue }
  const delta = Math.abs(lastValue - baselineValue)
  const isBetterThanBaseLine = Math.sign(lastValue - baselineValue) * getDirection(keyResult) === 1

  if (isBetterThanBaseLine) return GraphType.GOOD
  if (delta <= orangeDelta) return GraphType.GOOD
  if (delta <= redDelta) return GraphType.ORANGE
  return GraphType.BAD
}

export const getTodaysValue = ({ values = [], startValue }: KeyResult): Value => {
  const value = values[values.length - 1]?.value
  return {
    date: DayJS().format(),
    value: typeof value === 'number' && !isNaN(value) ? value : startValue
  }
}

// Use this one Hristo, Nadya
export const getYAxisLabelForValue = (_value: number, kr: KeyResult) => {
  const { value, suffix } = getNumberPartsForValue(_value, kr)
  return `${value}${suffix}`
}

export const getValueForGraphTooltip = getYAxisLabelForValue
export const getValueForStrategyViewTinyGraph = getYAxisLabelForValue
export const getValueForSagaDashboardListRow = getYAxisLabelForValue
export const getValueTextInEditObjectiveDialog = getYAxisLabelForValue

export const getValueTextGrowthView = (value: number) =>
  getYAxisLabelForValue(value, { unit: Unit.NUMERICAL, startValue: value, goalValue: value } as KeyResult)

export const getTodaysValueLabel = (_value: number, kr: KeyResult) => {
  const { value, suffix } = getNumberPartsForValue(_value, kr, { withCurrencySuffix: true })
  return `${value} ${suffix}`
}

export const getValueForSagaCelebrationScreen = getTodaysValueLabel

export const getNumberPartsForValue = (
  value: number,
  kr: KeyResult,
  { withCurrencySuffix }: { withCurrencySuffix?: boolean } = {}
): { value: string; suffix: string } => {
  const { unit, currency } = kr
  const currencySuffix = withCurrencySuffix ? currency : ''
  switch (unit) {
    case Unit.BOOLEAN:
      return { value: value.toFixed(), suffix: '' }
    case Unit.PERCENT:
      return { value: formatValue(value, kr), suffix: '%' }
    case Unit.MONEY: {
      if (Math.abs(value) > ONE_MILLION)
        return { value: formatValue(value / ONE_MILLION, kr), suffix: `M${currencySuffix}` }
      if (Math.abs(value) > ONE_THOUSAND)
        return { value: formatValue(value / ONE_THOUSAND, kr), suffix: `k${currencySuffix}` }
      return { value: formatValue(value, kr), suffix: '' }
    }
    default: {
      if (Math.abs(value) > ONE_MILLION) return { value: formatValue(value / ONE_MILLION, kr), suffix: 'M' }
      if (Math.abs(value) > ONE_THOUSAND) return { value: formatValue(value / ONE_THOUSAND, kr), suffix: 'k' }

      return { value: formatValue(value, kr), suffix: '' }
    }
  }
}

const formatValue = (value: number, kr: KeyResult) => {
  if (value === 0) return '0'
  let decimals = Math.max(Common.getDecimals(value), getDecimals(kr))
  decimals = Math.min(decimals, 2)
  return value.toFixed(decimals)
}

// The ticks for the y axis graph. // Use this one Hristo, Nadya
export const getYAxisTicks = (kr: KeyResult, o: Objective) => {
  const { startValue, goalValue, values = [] } = kr

  const __values = values.filter(
    ({ date }) => DayJS(date).isSameOrAfter(o.startDate) && DayJS(date).isSameOrBefore(o.endDate)
  )
  const _values = [startValue, ...__values.map(({ value }) => (!isNaN(value) ? value : null)), goalValue].filter(
    (i) => i !== null
  ) as number[]
  const min = Math.min(..._values)
  const max = Math.max(..._values)
  const diff = (max - min) / Y_AXIS_TICKS
  const isDescending = getDirection(kr) === DESCENDING
  const direction = isDescending ? -1 : 1
  const ticks = getArray(Y_AXIS_TICKS + 1).map((x, i) => startValue + direction * diff * i)
  if (isDescending) return ticks.reverse()
  return ticks
}

export const getYAxisTicksForRetainIndicator = (kr: KeyResult) => {
  const { goalValue: value } = kr
  const values = (kr.values || []).map(({ value }) => value)
  const m = Math.min(value * (1 - RETAIN_INTERVAL), Math.min(...values))
  const yMax = Math.max(value * (1 + RETAIN_INTERVAL), Math.max(...values))
  const k = (yMax - m) / Y_AXIS_TICKS
  const ticks = getArray(Y_AXIS_TICKS + 1).map((x, i) => m + k * i)
  if (getDirection(kr) === DESCENDING) return ticks.reverse()
  return ticks
}

export const getBaselineValue = (x: Dayjs, { startValue, goalValue }: KeyResult, objective: Objective) => {
  const { endDate, startDate } = objective
  const k = (goalValue - startValue) / DayJS(endDate).diff(startDate, 'milliseconds')
  const m = startValue
  return k * x.diff(startDate, 'milliseconds') + m
}

export const getPolynomialValue = (x: Dayjs, objective: Objective, { predict }: Result) =>
  predict(getX(x, objective))[1]

// pretty self explanatory // Use this one Hristo, Nadya
export function getValuesForGraph(
  keyResult: KeyResult,
  objective: Objective,
  { enableAddTodaysValue } = { enableAddTodaysValue: true }
) {
  const { startDate } = objective
  let { values } = keyResult

  values = (values || []).filter((v) => Common.isNumber(v.value)).filter((v) => DayJS(v.date).isSameOrAfter(startDate))

  // add start value
  let { startValue } = keyResult
  const firstValue = values[0]
  if (firstValue && DayJS(firstValue.date).isSame(startDate, 'day')) startValue = firstValue.value

  values = [{ date: startDate, value: startValue }, ...values]

  // add todays value
  if (enableAddTodaysValue && !keyResult.dataSourceId) values = addValueToValues(getTodaysValue(keyResult), values)
  return values
}

// Value for the flag icon // Use this one Hristo, Nadya
export function getGoalValue(keyResult: KeyResult): number {
  const { startValue, goalValue } = keyResult
  const direction = getDirection(keyResult)
  if (direction === ASCENDING) return keyResult.goalValue
  return (startValue - goalValue) * 0.1 + goalValue
}

// Get you min and max values for th y axis // Use this one Hristo, Nadya
export function getYAxisDomain(values: Payload[], { startValue, goalValue }: KeyResult, o: Objective): AxisDomain {
  const numbers = [
    ...values
      .filter(
        ({ timestamp }) => DayJS(timestamp).isSameOrAfter(o.startDate) && DayJS(timestamp).isSameOrBefore(o.endDate)
      )
      .reduce(
        (a, b) => {
          a.push(b.base)
          b.value && a.push(b.value)
          return a
        },
        [startValue, goalValue] as number[]
      )
  ]
  const max = Math.max(...numbers)
  const min = Math.min(...numbers)

  const diff = max - min

  const res = [min - diff * 0.1, max + diff * 0.1]
  return res
}

export function getX(x: Dayjs, { startDate, endDate }: Objective): number {
  return x.diff(startDate, 'milliseconds') / DayJS(endDate).diff(startDate, 'milliseconds')
}
