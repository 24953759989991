import styled from '@emotion/styled'
import { useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import useQueryParams, { StripeCheckoutCallbackQueryParams } from '../ygdrasil/hooks/useQueryParams'
import Colors from '../ygdrasil/libs/Colors'
import { changeAddressInAddressbarWithoutReloading } from '../ygdrasil/libs/DashboardHelper'
import Images from '../ygdrasil/libs/Images'
import RoutePath from '../ygdrasil/libs/RoutePath'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import { BIG_ICON_SIZE, CloseIcon } from './Icons'
import Modal, { ModalProps } from './Modal'
import { SettingsTabKey } from './SettingsTabs'
import { STRIPE_CALLBACK_MODAL_MAX_WIDTH } from '../libs/HardCodedSizes'
import { SignInCodeFooter } from './SignInCodeFooter'

export const ModalStripeCallbackGlobalComponent = () => {
  const { stripeSuccess } = useQueryParams<StripeCheckoutCallbackQueryParams>()
  const [showModal, setShowModal] = useState(!!stripeSuccess)
  const { state, setState } = useAppState()

  const onClose = () => {
    setState({ ...state, showSettings: true, settingsTabKey: SettingsTabKey.SUBSCRIPTION })
    setShowModal(false)
    changeAddressInAddressbarWithoutReloading(RoutePath.ROOT)
  }

  if (!showModal) return null

  return (
    <Modal onClose={onClose} transform="10%">
      <Container top bottom spacing={tinySpacing} align="center">
        <Box pointer alignSelf="flex-end" onClick={onClose}>
          <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
        </Box>
        {stripeSuccess === 'true' ? (
          <ModalStripeCallbackSuccess onClose={onClose} />
        ) : (
          <ModalStripeCallbackError onClose={onClose} />
        )}
      </Container>
    </Modal>
  )
}

export const ModalStripeCallbackError = (props: ModalProps) => {
  return (
    <Box fullWidth align="center" fullPadding>
      <Box>
        <FigmaImageContainer imageKey={Images.undrawCancelRePkdm} />
      </Box>
      <Box top fullWidth alignText="center">
        <FigmaText textKey={Texts.adminPaymentErrorMessage} />
      </Box>
      <Box top>
        <Button textKey={Texts.adminPaymentTryAgainAfterError} onClick={props.onClose} />
      </Box>
      <Box top>
        <SignInCodeFooter />
      </Box>
    </Box>
  )
}

export const ModalStripeCallbackSuccess = (props: ModalProps) => {
  return (
    <Box fullWidth align="center" fullPadding>
      <Box>
        <FigmaImageContainer imageKey={Images.undrawCreditCardReBlml} />
      </Box>
      <Box top>
        <FigmaText textKey={Texts.adminPaymentSuccessCardTitle} />
      </Box>
      <Box top spacing={smallSpacing}>
        <FigmaText textKey={Texts.adminPaymentSuccessCardText} />
      </Box>
      <Box top>
        <Button textKey={Texts.adminPaymentManageSubscriptionButtonText} onClick={props.onClose} />
      </Box>
    </Box>
  )
}

const Container = styled(Box)`
  max-width: ${STRIPE_CALLBACK_MODAL_MAX_WIDTH}px;
`
