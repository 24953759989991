import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { formatKeyResultsMessage } from '../libs/EditObjectiveDialogKeyResultRowSuggestionsHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getArray } from '../ygdrasil/libs/Common'
import { getSuggestKeyResultConversation } from '../ygdrasil/libs/ConversationHelper'
import Texts from '../ygdrasil/libs/Texts'
import { Objective } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { useConversationQueryResponse } from '../hooks/useConversationQuery'
import { useEffect } from 'react'
import { continueConversation } from '../ygdrasil/libs/CloudFunctionsApiHandler'

export const EditObjectiveDialogKeyResultRowSuggestions = ({
  objective,
  onClickSuggestion
}: {
  objective: Objective
  onClickSuggestion: (suggestion: string) => unknown
}) => {
  const { state } = useAppState()
  const conversation = getSuggestKeyResultConversation(objective, state)
  const c = useConversationQueryResponse(conversation._id)

  const suggestions = formatKeyResultsMessage(c?.response || '')

  useEffect(() => {
    continueConversation(conversation, state)
  }, [])

  return (
    <Box fullWidth fullPadding spacing={smallSpacing}>
      <FigmaText textKey={Texts.aiKeyResultSuggestionHeader} />
      <Box top spacing={smallSpacing} fullWidth>
        {getArray(3)
          .map((x, i) => '')
          .map((_, i) => {
            const suggestion = suggestions[i]
            const loading = !suggestion
            return (
              <Box
                key={i}
                fullWidth
                direction="row"
                justify="space-between"
                align="center"
                bottom
                spacing={smallSpacing}
              >
                <Box width="80%">
                  <Box fullWidth>
                    <FigmaTextLoader textKey={Texts.cssaiKeyResultText} text={suggestion} loading={loading} />
                  </Box>
                </Box>
                <Button
                  textKey={Texts.aiKeyResultSuggestionUseTextButton}
                  onClick={() => onClickSuggestion(suggestion)}
                  spacing={smallSpacing}
                />
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}
