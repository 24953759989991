import styled from '@emotion/styled'
import useAppState from '../hooks/useAppState'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { Arrow } from './BusinessPlanRingArrow'
import { BusinessplanRingInternal } from './BusinessplanRingInternal'
import Button from './Button'
import { openDrawer } from './DrawerGlobalBusinessPlan'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { useState } from 'react'

export const THREE_SIXTY = 360
export const DEGREE_PER_CHAR = 4
const ARROW_THRESHOLD = 3
export const BUSINESS_PLAN_RING_FONT_SIZE_SCALE_FACTOR = 5

export default function BusinessplanRing() {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const config = useLoggedInConfig()
  const [innerFontSize, setInnerFontSize] = useState<number>(0)
  const strategiPillars = org?.businessPlan.strategicPillars || []
  const hasStrategicPillars = strategiPillars.length > 0
  const imageKey = strategiPillars.length > ARROW_THRESHOLD ? Images.imageArrowLong : Images.imageArrowShort

  return (
    <Box fullWidth align="center">
      {org?.businessPlan && (
        <Box fullWidth position="relative" onClick={() => openDrawer(config)} pointer>
          <BusinessplanRingInternal businessPlan={org.businessPlan} setInnerFontSize={setInnerFontSize} />
          <Box fullWidth fullHeight align="center" justify="center" position="absolute">
            {!hasStrategicPillars && (
              <Button
                buttonType="empty_state"
                textKey={Texts.businessPlanCallToActionAddFirstStrategicPillarButtonText}
              >
                <FigmaTextForStrategyModel textKey={Texts.businessPlanCallToActionAddFirstStrategicPillarButtonText} />
              </Button>
            )}
            {hasStrategicPillars && (
              <ArrowContainer fullWidth fullHeight align="center" justify="center">
                {strategiPillars.map((strategicPillar, key) => (
                  <Arrow
                    key={key}
                    strategicPillar={strategicPillar}
                    imageKey={imageKey}
                    innerFontSize={innerFontSize}
                  />
                ))}
              </ArrowContainer>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

const ArrowContainer = styled(Box)`
  transform: rotate(-30deg);
`
