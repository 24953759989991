import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { defaultSpacing, mediumSpacing, regularSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import { OUR_BIG_PICTURE_WEBSITE_URL } from '../ygdrasil/libs/Consts'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType, Language } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import FigmaImage from './FigmaImage'
import FigmaImageContainer from './FigmaImageContainer'
import SelectFieldWithLabel from './SelectFieldWithLabel'

export const LANGUAGES_OBJECT = { sv: 'Svenska', en: 'English' }
export const getLanguageOptions = (obj) =>
  Object.keys(obj).map((code) => ({
    key: LANGUAGES_OBJECT[code],
    value: code
  }))

type Props = {
  children: React.ReactNode
  headingTextKey?: AntiloopTextType
  subheadingTextKey?: AntiloopTextType
}

const SignInSignUpLayout: React.FC<Props> = ({ children, headingTextKey, subheadingTextKey }) => {
  const { state, setState } = useAppState()
  const [currentLanguage, setCurrentLanguage] = useState<Language | undefined>(state.user.language)
  const languageOptions = getLanguageOptions(LANGUAGES_OBJECT)
  const showTitlesWrapper = !!headingTextKey || !!subheadingTextKey

  useEffect(() => {
    setState({ ...state, user: { ...state.user, language: currentLanguage as Language } })
  }, [currentLanguage, state.user.language])

  return (
    <Box fullWidth fullPadding align="center" gap={mediumSpacing} spacing={defaultSpacing} fullHeight>
      <Box link onClick={() => window.open(OUR_BIG_PICTURE_WEBSITE_URL, '_blank')}>
        <FigmaImageContainer imageKey={Images.newCompanyLogo} />
      </Box>
      {showTitlesWrapper && (
        <Box fullWidth align="center" gap={regularSpacing} spacing={defaultSpacing} alignText="center">
          {!!headingTextKey && <FigmaText textKey={headingTextKey} />}
          {!!subheadingTextKey && <FigmaText textKey={subheadingTextKey} />}
        </Box>
      )}
      <Box fullWidth align="center">
        {children}
      </Box>
      <Box>
        <StyledSelectFieldWithLabel
          startAdornment={
            <FigmaImage imageKey={Images.languageIcon} imageStyle={{ width: mediumSpacing, height: mediumSpacing }} />
          }
          options={languageOptions}
          fullWidth
          value={currentLanguage}
          onChange={({ target: { value: language } }) => {
            setCurrentLanguage(language as Language)
          }}
          renderValue={(value) => (
            <Box fullWidth align="center">
              <FigmaText textKey={Texts.languageLabelChosen} text={LANGUAGES_OBJECT[value as string]} />
            </Box>
          )}
        />
      </Box>
    </Box>
  )
}

export default SignInSignUpLayout

export const TextFieldStyles = `
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  
  & .MuiSelect-icon {
    display: none;
  }

  cursor: pointer;
`
const StyledSelectFieldWithLabel = styled(SelectFieldWithLabel)`
  ${TextFieldStyles}
`
