import { getFeatureFlagsFromUrl } from './ygdrasil/libs/Common'
import { PartialConfig } from './ygdrasil/types/types'

let env: string | undefined = (import.meta as any).env.VITE_ENVIRONMENT

if (process.env.NODE_ENV === 'test') env = 'test'
if (!env) env = 'prod'

export const defaultConfig = {
  name: 'default',
  appUrl: 'https://app.ourbigpicture.se',
  sagaUrl: 'saga://',
  stripeProductId: 'prod_NoWcnMi1CgWeic',
  enableRefreshTemplateStrategies: false,
  port: 5000,
  localCloudFunctionsPort: 5001,
  sentryDsn: 'https://5d81ae1376f545b886051b7e3e1e0636@o720664.ingest.sentry.io/5781297',
  sentryDsnSaga: 'https://5119c0ecc570497299ce7e484c4563a3@o720664.ingest.sentry.io/6233258',
  slackWebHookUrl: 'https://hooks.slack.com/services/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',
  enableSentry: false,
  enableLocalCloudFunctions: false,
  enableLoggingOfConfig: false,
  enableLogErrorOnServer: false,
  enableLocalFirestore: false,
  enableLocalAuth: false,
  enableAnalytics: false,
  enableAnalyticsEmitter: false,
  enableDevMenu: false,
  enableIntegrations: false,
  enableSlack: false,
  enableExperimentalFocusedObjectives: true,
  enableLoggedInSandbox: false,
  enableDeleteIndicator: false,
  enableAlwaysResizeImage: true,
  enableTidio: false,
  enableSuperAdmin: true,
  enableOurBigPictureUsersToBeLead: false,
  enableExperimentalObjectiveTypeAndPeriod: false,
  enableDeleteAccount: false,
  enableStonly: false,
  enableAppSvgImports: false,
  enableResizeImage: true,
  enableSagaLoggedInSandbox: false,
  enableSagaSandbox: false,
  enableDefaultBusinessPlan: true,
  enableSetFirestoreUndefinedProperties: true,
  enableLogFirebaseDBError: true,
  enableSagaDebugInfo: false,
  enableSecurityStep: false,
  enableMockAuth: false,
  enableEditDomains: false,
  enableLogLogins: false,
  enableLogFirestoreQueryResult: false,
  enableShowId: false,
  enableEventToast: false,
  enableGoogleSheetIntegrationSelectField: false,
  enabledActivityBoard: true,
  enableIndicatorConfidenceProgress: false,
  enableChatGPT: false,
  enableMyTodos: false,
  enableSwitchControlSuccessGraphView: true,
  enableNewCelebrationsGraphs: false,
  enableSubscriptionJsonView: false,
  // permissions
  enableEditObjectivesForNonLeads: false,
  enableEditoIndicatorsForNonLeads: false,
  enableEditOrganizationLogo: false,
  enableEditBusinessView: false,
  enableEditTeam: false,
  enableInviteUsers: false,
  enableApplicationSettings: false,
  enableMyOrganization: false,
  enableEditSuccessView: false,
  enableEditDashboard: false
}

export const local: PartialConfig = {
  ...defaultConfig,
  name: 'local',
  appUrl: 'http://localhost:5173',
  enableSentry: false,
  enableAnalyticsEmitter: false,
  enableDevMenu: true,
  enableLoggedInSandbox: false,
  enableDeleteIndicator: true,
  enableOurBigPictureUsersToBeLead: true,
  enableIndicatorConfidenceProgress: false,
  enableMyTodos: true,
  enableTidio: true,
  enableSwitchControlSuccessGraphView: true,
  enableNewCelebrationsGraphs: true
}

// used in saga to connect to prod auth
const localProd: PartialConfig = {
  ...local,
  name: 'localProd',
  appUrl: defaultConfig.appUrl
}

// used to connect to local version of cloud functions
const localDev: PartialConfig = {
  ...local,
  name: 'localDev',
  enableLoggingOfConfig: false,
  enableLocalCloudFunctions: true,
  enableLocalFirestore: false,
  enableLocalAuth: false,
  enableLogErrorOnServer: true,
  enableAnalyticsEmitter: false
}

const test: PartialConfig = {
  ...defaultConfig,
  name: 'test',
  enableLoggingOfConfig: false,
  enableSetFirestoreUndefinedProperties: false,
  enableLogFirebaseDBError: false,
  enableMockAuth: true
}

export const prod: PartialConfig = {
  ...defaultConfig,
  name: 'prod',
  enableAnalytics: true,
  enableDevMenu: false,
  enableSlack: true,
  enableTidio: true,
  enableSuperAdmin: false,
  enableStonly: true,
  enableLogLogins: true,
  enableLogFirestoreQueryResult: false,
  enableSentry: true
}

export const adminUser: PartialConfig = {
  name: 'adminUser',
  enableEditObjectivesForNonLeads: true,
  enableEditoIndicatorsForNonLeads: true,
  enableEditOrganizationLogo: true,
  enableEditBusinessView: true,
  enableEditTeam: true,
  enableInviteUsers: true,
  enableApplicationSettings: true,
  enableMyOrganization: true,
  enableEditSuccessView: true,
  enableIntegrations: true,
  enableEditDashboard: true
}

export const ourBigPictureUser: PartialConfig = {
  name: 'ourBigPictureUser',
  enableIntegrations: true,
  enableDevMenu: true,
  enableSuperAdmin: true,
  enableOurBigPictureUsersToBeLead: true,
  enableDeleteAccount: true,
  enableSagaDebugInfo: true,
  enableEditDomains: true,
  enableGoogleSheetIntegrationSelectField: false,
  enableSwitchControlSuccessGraphView: true,
  enableNewCelebrationsGraphs: true,
  enableSubscriptionJsonView: true
}

const configs = { local, localDev, localProd, prod, test, ourBigPictureUser }

let config = configs[env] || prod

config = { ...config, ...getFeatureFlagsFromUrl() }

// eslint-disable-next-line no-console
if (config.enableLoggingOfConfig) console.log('Running app with config', config)

export default config as typeof defaultConfig
