import { BoxProps } from '@mui/material'
import React from 'react'
import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useShouldShowExpandedMenu from '../hooks/useShouldShowExpandedMenu'
import { getStateForGrowthView } from '../libs/MenuHelper'
import { useGrowthIndicators, useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import { MenuBarChartIcon } from './Icons'
import { MenuButton } from './MenuButton'

const MenuButtonGrowthView: React.FC<BoxProps> = function () {
  const { state, setState } = useAppState()
  const { showGrowthView: isSelected } = state
  const { data: growthIndicators = [], isLoading } = useGrowthIndicators(state)
  const shouldExpandMenu = useShouldShowExpandedMenu()

  const onClick = () => {
    if (state.showGrowthView) return
    setState(getStateForGrowthView(state))
  }

  // TODO WRTIE TEST, should show empty state button wrt growthindicators
  const shouldShowEmptyState = !isLoading && growthIndicators.length === 0 && shouldExpandMenu
  return (
    <Box fullWidth>
      <MenuButton
        onClick={onClick}
        Icon={MenuBarChartIcon}
        textKey={Texts.applicationMenuSuccessButtonText}
        isSelected={isSelected}
      />
      {shouldShowEmptyState && (
        <Box top spacing={tinySpacing} fullWidth>
          <Button
            buttonType="empty_state"
            onClick={onClick}
            textKey={Texts.applicationMenuAddFirstSuccessMetricButtonText}
            spacing={tinySpacing}
          />
        </Box>
      )}
    </Box>
  )
}

export default MenuButtonGrowthView
