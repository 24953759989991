import { SMALL_SPACING_PX } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { TIDIO_CHAT_PADDING } from '../libs/HardCodedSizes'
import { SearchState } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import Box from './Box'
import StrategyViewObjectiveList from './StrategyViewObjectiveList'
import { StrategyViewObjectiveTreeFilter } from './StrategyViewObjectiveTreeFilter'

type Props = {
  hideSerchBar?: boolean
  onlyShowIfSelectedObjective?: boolean
  searchState?: Partial<SearchState>
  hideDrawer?: boolean
}

export default function StrategyViewObjectiveTree(props: Props) {
  const { onlyShowIfSelectedObjective, searchState: _searchState, hideDrawer } = props

  const { state } = useAppState()
  const { showListView } = state
  const searchState: SearchState = { ...state.searchState, ..._searchState }

  if (onlyShowIfSelectedObjective && !searchState.selectedObjectiveId) return null

  return (
    <Box fullWidth bottom={!state.showDropDownList} spacing={TIDIO_CHAT_PADDING}>
      {!state.showDropDownList && showListView && !hideDrawer && <StrategyViewObjectiveTreeFilter {...props} />}
      <StrategyViewObjectiveList searchState={searchState} hideDrawer={hideDrawer} />
    </Box>
  )
}
