import React, { useEffect } from 'react'
import { OnboardingViewProps } from '../ygdrasil/types/Onboarding'
import Box from './Box'
import Loader from './Loader'

export default function OnboardingAddObjectives({ onPressContinue, state }: OnboardingViewProps) {
  useEffect(() => {
    onPressContinue(state)
  }, [])
  return (
    <Box align="center" fullWidth alignText="center" top>
      <Loader />
    </Box>
  )
}
