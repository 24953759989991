import dayjs from 'dayjs'
import { CheckinStatus } from '../ygdrasil/enums/CheckinStatus'
import { Confidence, Objective } from '../ygdrasil/types/types'

export function getConfidencesForCheckInProgress(
  confidences: Confidence[],
  objective: Objective,
  now = dayjs().format()
) {
  confidences = [{ date: objective.startDate, status: CheckinStatus.DONT_KNOW }, ...confidences].filter(
    ({ date }) => dayjs(date).isSameOrAfter(objective.startDate) && dayjs(date).isSameOrBefore(objective.endDate)
  )

  const last = confidences[confidences.length - 1]
  return [...confidences, { ...last, date: now }]
}
