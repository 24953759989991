export enum StorageKey {
  STATE = 'STATE',
  SIGN_IN_STATE = 'SIGN_IN_STATE',
  SESSION = 'SESSION'
}

export enum StorageType {
  LOCAL_STORAGE,
  SESSION_STORAGE
}
// eslint-disable-next-line prettier/prettier
export const isValidJson = (str) => { try { JSON.parse(str); } catch (e) { return false; } return true; };
export type Options = {
  ignoredKeys?: string[]
  storageType?: StorageType
}

export const DEFAULT_OPTIONS = {
  ignoredKeys: [],
  storageType: StorageType.LOCAL_STORAGE
}
