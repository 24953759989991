import styled from '@emotion/styled'
import Popper from '@mui/material/Popper'
import dayjs from 'dayjs'
import { useState } from 'react'
import { minBorderRadius } from '../enums/BorderRadixes'
import { bigSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { YYYYMMDD_HHmm } from '../ygdrasil/libs/Consts'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Datasource } from '../ygdrasil/types/types'
import Box from './Box'
import { CheckCircleRounded } from './Icons'
import { NoWrapFigmaText } from './StyledComponents'

export function SettingsIntegrationsDatasourceTableRowLastSyncedAt({ ds }: { ds: Datasource }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { values = [] } = ds
  const onMouseEnter = (e) => values.length > 0 && setAnchorEl(e.target)
  const onMouseLeave = () => setAnchorEl(null)

  const noOfHiddenValues = values.length - MAX_VALUES
  return (
    <>
      <Box align="center" direction="row" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <CheckCircleRounded />
        <Box left spacing={tinySpacing}>
          <NoWrapFigmaText textKey={Texts.office365Connection} text={dayjs(ds.lastSyncedAt).format(YYYYMMDD_HHmm)} />
        </Box>
      </Box>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <PopperContainer fullPadding spacing={tinySpacing}>
          {values
            .sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf())
            .filter((x, i) => i < MAX_VALUES)
            .map((value, key) => (
              <Box key={key} direction="row" bottom spacing={tinySpacing} justify="space-between">
                <Box right spacing={bigSpacing}>
                  <FigmaText textKey={Texts.integrationDataOverlayDataPointDate} text={value.date} />
                </Box>
                <Box>
                  <FigmaText textKey={Texts.integrationDataOverlayDataPointValue} text={value.value.toString()} />
                </Box>
              </Box>
            ))}
          {noOfHiddenValues > 0 && (
            <FigmaText
              textKey={Texts.integrationDataOverlayBottomInfo}
              text={formatText(getFigmaText(Texts.integrationDataOverlayBottomInfo), [` ${noOfHiddenValues}`])}
            />
          )}
        </PopperContainer>
      </Popper>
    </>
  )
}
const MAX_VALUES = 5
const PopperContainer = styled(Box)`
  background: ${Colors.whiteBg};

  border: 1px solid ${Colors.neutralVariant};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${minBorderRadius};
`
