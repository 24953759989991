import React from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { isVideo } from '../libs/VideoHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { DeprecatedTexts } from '../ygdrasil/libs/Texts'
import { Statement } from '../ygdrasil/types/types'
import Box from './Box'
import ModalVideo from './ModalVideo'
import VideoThumbnail from './VideoThumbnail'
import { RichText } from './RichText'

export function MenuStatementContent({
  statement: s,
  onClickVideo,
  onClickContent
}: {
  statement: Statement
  onClickVideo?: () => unknown
  onClickContent?: () => unknown
}) {
  const [showVideoModal, setShowVideoModal] = React.useState(false)
  const _onClickVideo = () => {
    setShowVideoModal(true)
    onClickVideo && onClickVideo()
  }
  return (
    <Box top spacing={smallSpacing} fullWidth>
      {showVideoModal && s.url && <ModalVideo onClose={() => setShowVideoModal(false)} url={s.url} />}
      <Box onClick={onClickContent}>
        <FigmaText textKey={DeprecatedTexts.cssApplicationMenuVisionHeader} text={s.name} />
      </Box>
      {s.description && (
        <Box top spacing={tinySpacing} onClick={onClickContent}>
          <RichText
            richText={s.richDescription}
            fallbackTextKey={DeprecatedTexts.cssApplicationMenuVisionDescription}
            fallbackText={s.description}
          />
        </Box>
      )}
      {isVideo(s.url) && (
        <Box fullWidth top spacing={smallSpacing}>
          <VideoThumbnail onClick={_onClickVideo} />
        </Box>
      )}
    </Box>
  )
}
