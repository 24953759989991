import { Formik, FormikProps } from 'formik'
import { smallSpacing } from '../enums/Spacings'
import useAppState, { State } from '../hooks/useAppState'
import { getKey, shouldShowRetrospectiveField } from '../libs/MyActionsMyFocusHelper'
import { EditorState, createWithContent, stringify } from '../libs/WYSIWYGEditor'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { toBaseObject } from '../ygdrasil/libs/Mapper'
import { updateWeeklyFocus, updateWeeklyFocusRetrospecive } from '../ygdrasil/libs/ProcessHandler'
import Texts from '../ygdrasil/libs/Texts'
import { MyFocus } from '../ygdrasil/types/types'
import Box from './Box'
import { RichTextEditorFormik } from './RichTextEditorFormik'
import { useMyFocusRows } from '../hooks/useMyFocusRows'

export function MyActionsMyFocusForm(props: { date: string }) {
  const { state } = useAppState()
  const date = getKey(props.date)
  const logEvent = useLogEvent()
  const myFocusRow = useMyFocusRows().find((r) => r.date === date)
  const initialValues = {
    why: createWithContent(myFocusRow?.why),
    retro: createWithContent(myFocusRow?.retro),
    date
  }

  const onBlurWhy = ({ values }: FormikProps<typeof initialValues>) =>
    updateWeeklyFocus({
      myFocus: formatMyFocus(values, state, myFocusRow),
      logEvent
    })

  const onBlurRetrospective = ({ values }: FormikProps<typeof initialValues>) =>
    updateWeeklyFocusRetrospecive({
      myFocus: formatMyFocus(values, state, myFocusRow),
      logEvent
    })

  return (
    <Formik initialValues={initialValues} onSubmit={() => Promise.resolve()} enableReinitialize>
      {(formProps) => (
        <Box top spacing={smallSpacing} fullWidth>
          <FigmaText textKey={Texts.myFocusThisWeekDescriptionHeader} />
          <Box spacing={smallSpacing} fullWidth>
            <RichTextEditorFormik
              name="why"
              formProps={formProps}
              onBlur={() => onBlurWhy(formProps)}
              placeHolderTextKey={Texts.myFocusThisWeekWhyIsThisImportantPlaceholderText}
            />
          </Box>

          {shouldShowRetrospectiveField(date) && (
            <Box spacing={smallSpacing} top fullWidth>
              <FigmaText textKey={Texts.myFocusThisWeekRetrospectiveHeader} />
              <Box spacing={smallSpacing} fullWidth>
                <RichTextEditorFormik
                  name="retro"
                  formProps={formProps}
                  onBlur={() => onBlurRetrospective(formProps)}
                  placeHolderTextKey={Texts.myFocusThisWeekRetrospectiveHeaderPlaceHolderText}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Formik>
  )
}

function formatMyFocus(
  values: { why: EditorState; retro: EditorState; date: string },
  state: State,
  myFocusRow?: MyFocus
): MyFocus {
  const { selectedOrganizationId: organizationId, user } = state
  return {
    ...toBaseObject(state),
    ...myFocusRow,
    why: stringify(values.why),
    retro: stringify(values.retro),
    date: values.date,
    organizationId,
    userId: user._id
  }
}
