//@flow
import { State } from '../../hooks/useAppState'
import {
  CreateObjectiveRequest,
  FirebaseUser,
  Id,
  Objective,
  ObjectiveBase,
  ObjectiveType,
  Organization,
  Team,
  Unit,
  User
} from '../types/types'
import { createUniqueId, getEmailForFirebaseUser } from './Common'
import { DEFAULT_BUSINESSPLAN } from './Consts'
import { DayJS } from './DayJsHelper'

export const toBaseObject = ({ user: { _id: userId } }: State, id: Id = createUniqueId(), now = DayJS().format()) => ({
  _id: id,
  createdAt: now,
  updatedAt: now,
  createdBy: userId,
  updatedBy: userId
})

export const toCreateObjectiveRequest = (
  req: Partial<Objective>,
  { selectedOrganizationId: organizationId }: State
): CreateObjectiveRequest => ({
  teamIds: [],
  name: '',
  type: ObjectiveType.TOP,
  unit: Unit.NUMERICAL,
  kpi: {
    name: '',
    values: []
  },
  startDate: DayJS().startOf('quarter').format(),
  endDate: DayJS().endOf('quarter').format(),
  ordo: DayJS().valueOf(),
  ...req,
  organizationId
})

export const toCreateTeamRequest = (team: Partial<Team>, state: State): Team => ({
  ...toBaseObject(state),
  name: '',
  organizationId: state.selectedOrganizationId,
  ...team
})

export const toUser = (user: FirebaseUser, _user?: Partial<User>): User => ({
  objectiveIds: [],
  organizationIds: [],
  orgUserInfo: {},
  createdAt: DayJS().format(),
  updatedAt: DayJS().format(),
  createdBy: user.uid,
  updatedBy: user.uid,
  ..._user,
  _id: user.uid,
  name: _user?.name || user.displayName || '',
  email: getEmailForFirebaseUser(user) || null,
  phone: user.phoneNumber || null
})

export const toOrganization = (org: Organization): Organization => ({
  ...org,
  businessPlan: org?.businessPlan || DEFAULT_BUSINESSPLAN
})

export const toObjective = (obj: any): ObjectiveBase => {
  //@ts-ignore
  if (obj.strategicPillars) return obj
  if (!obj.strategicPillar) return { ...obj, strategicPillars: [] }
  return { ...obj, strategicPillars: [obj.strategicPillar] }
}
