/* eslint-disable react-hooks/rules-of-hooks */
import invariant from 'invariant'
import React, { useState } from 'react'
import Box from './Box'
import OnboardingAddObjectivesMobile from './OnboardingAddObjectivesMobile'
import OnboardingNameMobile from './OnboardingNameMobile'
import OnboardingTellUsMobile from './OnboardingTellUsMobile'
import OnboardingTermsMobile from './OnboardingTermsMobile'
import SignInCode from './SignInCode'
import SignInCodeMobile from './SignInCodeMobile'
import SignInExistsWithDifferentCredentialsMobile from './SignInExistsWithDifferentCredentialsMobile'
import SignInSignupMobile from './SignInSignupMobile'

const _SignInExistsWithDifferentCredentialsMobile = () => (
  //@ts-ignore
  <SignInExistsWithDifferentCredentialsMobile
    signInState={
      {
        data: {
          signInMethods: ['google', 'microsoft.com'],
          pendingUserCredential: {},
          pendingUser: { displayName: 'Anders' }
        }
      } as any
    }
  />
)

const OnboardingViewsMobile = [
  SignInCodeMobile,
  SignInCode,

  OnboardingTermsMobile,
  OnboardingAddObjectivesMobile,
  OnboardingTellUsMobile,
  _SignInExistsWithDifferentCredentialsMobile,

  OnboardingNameMobile,
  SignInSignupMobile
]

const SandboxOnboarding = () => {
  const [step, setStep] = useState(0)
  const component = OnboardingViewsMobile[step] as any
  invariant(component, `Cant find Step for %s`, step)

  const props = {
    signInState: { data: {} },
    state: {
      firebaseUser: { providerData: [] },
      data: { email: '', pendingUserCredential: {} as any, pendingUser: undefined, signInMethods: [] }
    } as any
  }

  return <Box fullWidth>{React.createElement(component, props)}</Box>
}

export default SandboxOnboarding
