import { State } from '../../hooks/useAppState'
import { AntiloopTextType } from '../types/Antiloop'
import { BaseObject, Objective } from '../types/types'
import { PREVIOUS_OUTPUT, XXX } from './Consts'
import { toBaseObject } from './Mapper'
import { formatText } from './TextRepository'
import Texts from './Texts'

export enum ConversationStatus {
  IDLE = 'IDLE',
  WAITING_ON_RESPONSE = 'WAITING_ON_RESPONSE',
  PROCESSING_RESPONSE = 'PROCESSING_RESPONSE',
  DONE = 'DONE',
  FAILED = 'FAILED',
  HOLD = 'HOLD'
}

export enum ConversationType {
  SUGGEST_OBJECTIVE_NAME = 'SUGGEST_OBJECTIVE_NAME',
  SUGGEST_KEYRESULT_NAME = 'SUGGEST_KEYRESULT_NAME',
  SUGGEST_KEYRESULTS = 'SUGGEST_KEYRESULTS',
  SUGGEST_STRATEGIC_PILLAR = 'SUGGEST_STRATEGIC_PILLAR'
}

export enum MessageType {
  HOLD = 'HOLD'
}

export type Conversation = BaseObject & {
  status: ConversationStatus
  type: ConversationType
  requests: Message[]
  responses: Message[]
  step: number
  _id: string
  organizationId: string
  errorMessage?: string
}

export type ConversationResponse = Conversation & {
  response: string
}

export type Message = {
  text: string
  type?: MessageType
}

export const ConversationTextKeys: { [property in ConversationType]: AntiloopTextType[] } = {
  [ConversationType.SUGGEST_KEYRESULTS]: [Texts.editIndicatorChatGptSuggestion],
  [ConversationType.SUGGEST_OBJECTIVE_NAME]: [Texts.editObjectiveChatGptSuggestion],
  [ConversationType.SUGGEST_KEYRESULT_NAME]: [
    Texts.editIndicatorChatGptSuggestion,
    Texts.editIndicatorChatGptSuggestion2
  ],
  [ConversationType.SUGGEST_STRATEGIC_PILLAR]: [
    Texts.editStrategicPillarChatGptSuggestion,
    Texts.editObjectiveChatGptSuggestion2
  ]
}

export const Messages: { [property in ConversationType]: Message[] } = {
  [ConversationType.SUGGEST_KEYRESULTS]: [
    { text: `Give me 3 suggestions for key results for this objective: "${XXX}"` }
  ],
  [ConversationType.SUGGEST_KEYRESULT_NAME]: [
    {
      text: `Hjälp mig att förbättra den här beskrivnigen: "${XXX}" utifrån SMART definitionen. Exkludera SMART definitionen. Max 70 tecken`
    }
  ],
  [ConversationType.SUGGEST_OBJECTIVE_NAME]: [
    {
      text: `Förbättra den här målbeskrivningen utifrån att den ska vara inspirerande, actionorienterad och max 70 tecken lång "${XXX}"`
    }
  ],
  [ConversationType.SUGGEST_STRATEGIC_PILLAR]: [
    {
      text: `Föreslå en kortare beskrivning på max 25 tecken utifrån den här strategiska pelaren “${XXX}”`,
      type: MessageType.HOLD
    },
    { text: 'Bra. Föreslå en ny beskrivning på max 25 tecken.' }
  ]
}

const getDefaultConversation = (state: State) => ({
  status: ConversationStatus.IDLE,
  responses: [],
  step: 0,
  ...toBaseObject(state)
})

export const getSuggestKeyResultConversation = ({ name }: Objective, state: State): Conversation => ({
  ...getDefaultConversation(state),
  organizationId: state.selectedOrganizationId,
  type: ConversationType.SUGGEST_KEYRESULTS,
  requests: Messages[ConversationType.SUGGEST_KEYRESULTS].map((message) => ({
    ...message,
    text: formatText(message.text, [name])
  }))
})

export const getSuggestObjectiveNameConversation = (name: string, state: State): Conversation => ({
  ...getDefaultConversation(state),
  organizationId: state.selectedOrganizationId,
  type: ConversationType.SUGGEST_OBJECTIVE_NAME,
  requests: Messages[ConversationType.SUGGEST_OBJECTIVE_NAME].map((message) => ({
    ...message,
    text: formatMessage(message, name)
  }))
})

export const getSuggestKeyresultNameConversation = (name: string, state: State): Conversation => ({
  ...getDefaultConversation(state),
  organizationId: state.selectedOrganizationId,
  type: ConversationType.SUGGEST_KEYRESULT_NAME,
  requests: Messages[ConversationType.SUGGEST_KEYRESULT_NAME].map((message) => ({
    ...message,
    text: formatMessage(message, name)
  }))
})

export const getSuggestStrategicPillarConversation = (name: string, state: State): Conversation => ({
  ...getDefaultConversation(state),
  organizationId: state.selectedOrganizationId,
  type: ConversationType.SUGGEST_STRATEGIC_PILLAR,
  requests: Messages[ConversationType.SUGGEST_STRATEGIC_PILLAR].map((message) => ({
    ...message,
    text: formatMessage(message, name)
  }))
})
function formatMessage(message: Message, name: string): string {
  return message.text.includes(XXX) ? formatText(message.text, [name]) : message.text
}
