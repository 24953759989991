/* eslint-disable prettier/prettier */
// @flow
import { State } from '../../hooks/useAppState'
import { Activity, Connection, ConnectionRequest, CreateGrowthIndicatorRequest, CreateObjectiveRequest, CreateTeamRequest, CreateUserRequest, CustomAuthToken, DashboardLink, DashboardLinkRequest, Datasource, DatasourceRequest, GrowthIndicator, Id, KeyResult, MyFocus, MyFocusRequest, Objective, Organization, Team, Todo, TodoRequest, User } from '../types/types'
import Collections from './Collections'
import { OBJECTIVES, ORGANIZATIONS, TEAM, USERS } from './Consts'
import { CreateEventRequest, OurEvent } from './EventHelper'
import * as Repository from './FirebaseStorageClientForWebApp'

export const createObjective = (objective: CreateObjectiveRequest, state: State): Promise<Objective> => Repository.createObject(objective, OBJECTIVES, state) as Promise<Objective>
export const updateObjective = (objective: Objective): Promise<Objective> => Repository.updateObject(objective, OBJECTIVES)
export const removeObjective = (objective: Objective): Promise<void> => Repository.removeObject(objective, OBJECTIVES)
export const getObjective = (id: Id): Promise<Objective | undefined> => Repository.getObject(id, Collections.OBJECTIVES) as  Promise<Objective | undefined>

export const createTeam = (team: CreateTeamRequest, state: State): Promise<Team> => Repository.createObject(team, TEAM, state) as Promise<Team>
export const updateTeam = (team: Team): Promise<Team> => Repository.updateObject(team, TEAM)
export const removeTeam = (team: Team): Promise<void> => Repository.removeObject(team, TEAM)
export const getTeam = (id: Id): Promise<Team | undefined> => Repository.getObject(id, TEAM) as Promise<Team | undefined>
export const getAllTeams = (state: State): Promise<Array<Team>> => Repository.getAllObjectsForSelectedOrganization(state, TEAM) as Promise<Array<Team>>

export const createOrganization = (org: Partial<Organization>, state): Promise<Organization> => Repository.createObject(org, ORGANIZATIONS, state) as Promise<Organization>
export const getOrganization = (id: Id): Promise<Organization | undefined> => Repository.getObject(id, ORGANIZATIONS) as Promise<Organization | undefined>
export const updateOrganization = (organization: Organization): Promise<Organization> => Repository.updateObject(organization, ORGANIZATIONS)

export const getUser = (id: Id): Promise<User | undefined> => Repository.getObject(id, USERS) as Promise<User | undefined>
export const updateUser = (user: User): Promise<User | undefined> => Repository.updateObject(user, USERS)
export const createUser = (user: CreateUserRequest, state: State): Promise<User> => Repository.createObject(user, USERS, state) as Promise<User>

export const createGrowthIndicator = (growthIndicator: CreateGrowthIndicatorRequest, state: State): Promise<GrowthIndicator> => Repository.createObject(growthIndicator, Collections.GROWTH_INDICATORS, state) as Promise<GrowthIndicator>
export const updateGrowthIndicator = (growthIndicator: GrowthIndicator): Promise<GrowthIndicator> => Repository.updateObject(growthIndicator, Collections.GROWTH_INDICATORS)
export const removeGrowthIndicator = (growthIndicator: GrowthIndicator): Promise<void> => Repository.removeObject(growthIndicator, Collections.GROWTH_INDICATORS)

export const getCustomAuthToken = (id: Id): Promise<CustomAuthToken | undefined> => Repository.getObject(id, Collections.CUSTOM_AUTH_TOKENS) as Promise<CustomAuthToken | undefined>

export const createIndicator = (indicator: KeyResult, state: State): Promise<KeyResult> => Repository.createObject(indicator, Collections.INDICATORS, state)
export const updateIndicator = (indicator: KeyResult): Promise<KeyResult> => Repository.updateObject(indicator, Collections.INDICATORS)
export const removeIndicator = (indicator: KeyResult): Promise<void> => Repository.removeObject(indicator, Collections.INDICATORS)
export const getIndicator = (id: Id) => Repository.getObject(id, Collections.INDICATORS)

export const createActivity = (a: Activity, state: State): Promise<Activity> => Repository.createObject(a, Collections.ACTIVITIES, state)
export const updateActivity = (a: Activity): Promise<Activity> => Repository.updateObject(a, Collections.ACTIVITIES)
export const removeActivity = (a: Activity): Promise<void> => Repository.removeObject(a, Collections.ACTIVITIES)
export const getActivity = (id: Id) => Repository.getObject(id, Collections.ACTIVITIES)

export const createEvent = <T>(event: CreateEventRequest<T>, state: State): Promise<OurEvent<T>> => Repository.createObject(event, Collections.EVENTS, state) as Promise<OurEvent<T>>
export const updateEvent = <T>(event: OurEvent<T>): Promise<OurEvent<T>> => Repository.updateObject(event, Collections.EVENTS)
export const getEvent = <T>(id: Id) => Repository.getObject(id, Collections.EVENTS) as Promise<OurEvent<T>>

export const createDatasource = (obj: DatasourceRequest, state: State): Promise<Datasource> => Repository.createObject(obj, Collections.DATA_SOURCES, state) as Promise<Datasource>
export const updateDatasource = (obj: Datasource): Promise<Datasource> => Repository.updateObject(obj, Collections.DATA_SOURCES)
export const removeDatasource = (obj: Datasource): Promise<void> => Repository.removeObject(obj, Collections.DATA_SOURCES)
export const getDataSource = (id: Id) => Repository.getObject(id, Collections.DATA_SOURCES)

export const createConnection = (obj: ConnectionRequest, state: State): Promise<Connection> => Repository.createObject(obj, Collections.CONNECTIONS, state) as Promise<Connection>
export const updateConnection = (obj: Connection): Promise<Connection> => Repository.updateObject(obj, Collections.CONNECTIONS)
export const removeConnection = (obj: Connection): Promise<void> => Repository.removeObject(obj, Collections.CONNECTIONS)
export const getConnection = (id: Id) => Repository.getObject(id, Collections.CONNECTIONS)
export const getConnections = (state: State): Promise<Connection[]> => Repository.getAllObjectsForSelectedOrganization(state, Collections.CONNECTIONS) as Promise<Connection[]>

export const createDashboardLink = (obj: DashboardLinkRequest, state: State): Promise<DashboardLink> => Repository.createObject(obj, Collections.DASHBOARD_LINKS, state) as Promise<DashboardLink>
export const updateDashboardLink = (obj: DashboardLink): Promise<DashboardLink> => Repository.updateObject(obj, Collections.DASHBOARD_LINKS)
export const removeDashboardLink = (obj: DashboardLink): Promise<void> => Repository.removeObject(obj, Collections.DASHBOARD_LINKS)
export const getDashboardLink = (id: Id) => Repository.getObject(id, Collections.DASHBOARD_LINKS)
export const getDashboardLinks = (state: State): Promise<DashboardLink[]> => Repository.getAllObjectsForSelectedOrganization(state, Collections.DASHBOARD_LINKS) as Promise<DashboardLink[]>

export const createMyFocus = (obj: MyFocusRequest, state: State): Promise<MyFocus> => Repository.createObject(obj, Collections.MY_FOCUS, state) as Promise<MyFocus>
export const updateMyFocus = (obj: MyFocus): Promise<MyFocus> => Repository.updateObject(obj, Collections.MY_FOCUS)
export const removeMyFocus = (obj: MyFocus): Promise<void> => Repository.removeObject(obj, Collections.MY_FOCUS)
export const getMyFocus = (id: Id) => Repository.getObject(id, Collections.MY_FOCUS)
export const getMyFocuss = (state: State): Promise<DashboardLink[]> => Repository.getAllObjectsForSelectedOrganization(state, Collections.MY_FOCUS) as Promise<DashboardLink[]>

export const createTodo = (obj: TodoRequest, state: State): Promise<Todo> => Repository.createObject(obj, Collections.TODOS, state) as Promise<Todo>
export const updateTodo = (obj: Todo): Promise<Todo> => Repository.updateObject(obj, Collections.TODOS)
export const removeTodo = (obj: Todo): Promise<void> => Repository.removeObject(obj, Collections.TODOS)
export const getTodo = (id: Id) => Repository.getObject(id, Collections.TODOS)
export const getTodos = (state: State): Promise<Todo[]> => Repository.getAllObjectsForSelectedOrganization(state, Collections.TODOS) as Promise<Todo[]>
