import React from 'react'
import FigmaText, { FigmaTextProps } from './FigmaText'

const FigmaTextArray: React.FC<{
  figmaTextPropSets: FigmaTextProps[]
}> = ({ figmaTextPropSets }) => {
  return (
    <div>
      {figmaTextPropSets.map((props, key) => (
        <span key={key}>
          <FigmaText {...props} />
        </span>
      ))}
    </div>
  )
}

export default FigmaTextArray
