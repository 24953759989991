import React from 'react'
import FigmaText from '../ygdrasil/components/FigmaText'
import { toHtml } from '../libs/WYSIWYGEditor'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import styled from '@emotion/styled'
import { NormalFontWight } from './StyledComponents'

export function RichText({
  richText,
  fallbackText,
  fallbackTextKey
}: {
  richText?: string
  fallbackText?: string
  fallbackTextKey: AntiloopTextType
}) {
  const __html = toHtml(richText)
  if (__html) return <Container className="richtext" dangerouslySetInnerHTML={{ __html }}></Container>
  return <FigmaText textKey={fallbackTextKey} text={fallbackText || ''} />
}

const Container = styled.div`
  ${NormalFontWight}
  p {
    margin-bottom: 0;
  }
`
