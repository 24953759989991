import React from 'react'
import useAppState from '../hooks/useAppState'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import { logEvent } from '../ygdrasil/libs/FirebaseHelper'
import type { inviteFromApp } from '../ygdrasil/libs/ProcessHandler'
import Box from './Box'
import { PersonAdd } from './Icons'
import { ModalInviteUser } from './ModalInviteUser'

export default function InviteUserAddIcon({ invite }: { invite?: typeof inviteFromApp }) {
  const [showModal, setShowModal] = React.useState(false)
  const { state } = useAppState()

  return (
    <>
      <Box
        onClick={() => {
          logEvent(AnalyticsEvent.DASHBOARD_INVITECOLLEAGUE, state)
          setShowModal(true)
        }}
        link
      >
        <PersonAdd />
      </Box>
      {showModal && <ModalInviteUser invite={invite} onClose={() => setShowModal(false)} />}
    </>
  )
}
