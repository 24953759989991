import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { reorderIndicators } from '../ygdrasil/libs/ProcessHandler'
import { useIndicators } from '../ygdrasil/libs/QueryHooks'
import { inRankOrder } from '../ygdrasil/libs/RankHelper'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import DragDropContext from './DragDropContext'
import {
  EditObjectiveDialogAddIndicatorButton,
  EditObjectiveDialogAddIndicatorEmptyStateButton
} from './EditObjectiveDialog'
import { EditObjectiveDialogKeyResultRow } from './EditObjectiveDialogKeyResultRow'

export function EditObjectiveDialogIndicators({
  objective,
  onClickAddKeyResult,
  onClickKeyResult
}: {
  objective?: Partial<Objective>
  onClickAddKeyResult: () => unknown
  onClickKeyResult: (indicator: KeyResult) => unknown
}) {
  if (!objective)
    return (
      <Box fullWidth top spacing={smallSpacing} align="center">
        <EditObjectiveDialogAddIndicatorEmptyStateButton onClick={onClickAddKeyResult} />
      </Box>
    )

  return (
    <EditObjectiveDialogIndicatorsView
      objective={objective as Objective}
      onClickAddKeyResult={onClickAddKeyResult}
      onClickKeyResult={onClickKeyResult}
    />
  )
}
function EditObjectiveDialogIndicatorsView({
  objective,
  onClickAddKeyResult,
  onClickKeyResult
}: {
  objective: Objective
  onClickAddKeyResult: () => unknown
  onClickKeyResult: (indicator: KeyResult) => unknown
}) {
  const { state } = useAppState()
  const logEvent = useLogEvent()
  let { data: _indicators } = useIndicators(state)
  _indicators = _indicators?.filter((i) => i.parentId === objective._id) || []
  const indicators = _indicators.sort(inRankOrder)
  const hasIndicators = indicators.length > 0

  return (
    <Box fullWidth>
      <DragDropContext
        rows={indicators}
        Component={({ data: keyResult }) => (
          <EditObjectiveDialogKeyResultRow keyResult={keyResult} onClickKeyResult={onClickKeyResult} />
        )}
        onDragEnd={(indicators) => reorderIndicators(indicators, logEvent)}
      />
      <Box fullWidth top spacing={smallSpacing} align="center">
        {hasIndicators && <EditObjectiveDialogAddIndicatorButton onClick={onClickAddKeyResult} />}
        {!hasIndicators && <EditObjectiveDialogAddIndicatorEmptyStateButton onClick={onClickAddKeyResult} />}
      </Box>
    </Box>
  )
}
