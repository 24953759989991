import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { DRAWER_WIDTH } from '../libs/HardCodedSizes'
import Colors from '../ygdrasil/libs/Colors'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { useTodos } from '../ygdrasil/libs/QueryHooks'
import { inRankOrder } from '../ygdrasil/libs/RankHelper'
import Box from './Box'
import { BIG_ICON_SIZE, CloseIcon } from './Icons'
import { MyTodosHeader } from './MyTodosHeader'
import { MyTodosList } from './MyTodosList'
import { LinkStyles } from './StyledComponents'

export function MyTodos({ onClose }) {
  const { state } = useAppState()
  const { data: allTodos = [] } = useTodos(state)
  const [activeDayOffset, setActiveDayOffset] = useState(0)
  const date = dayjs().add(activeDayOffset, 'day').format(YYYY_MM_DD)
  const todos = allTodos
    .filter((t) => t.userId === state.user._id)
    .filter((t) => t.date === date)
    .sort(inRankOrder)

  return (
    <Container fullWidth left right bottom fullHeight position="relative">
      <CloseIconContainer onClick={onClose} position="absolute" style={{ top: tinySpacing, right: tinySpacing }}>
        <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
      </CloseIconContainer>
      <Box fullWidth top>
        <MyTodosHeader activeDayOffset={activeDayOffset} onClickDate={setActiveDayOffset} />
        <Box direction="row" fullWidth top spacing={smallSpacing}>
          <MyTodosList todos={todos} date={date} setActiveDayOffset={setActiveDayOffset} />
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  width: ${DRAWER_WIDTH}px;
  border: 1px solid ${Colors.secondaryBase};
`

const CloseIconContainer = styled(Box)`
  ${LinkStyles}
`
