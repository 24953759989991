import invariant from 'invariant'
import React from 'react'
import { DotProps } from 'recharts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { KeyResult } from '../ygdrasil/types/types'
import { SMALL_SPACING_RADII } from '../enums/Spacings'
import { getColorForGraphType, getTodaysValueLabel, GraphType, Payload } from '../ygdrasil/libs/GraphHelper'
import Texts from '../ygdrasil/libs/Texts'
import { createUniqueId } from '../ygdrasil/libs/Common'

type Props = {
  cx?: number
  cy?: number
  dataKey?: string
  payload: Payload
  lastPayload: Payload
  type: GraphType
  keyResult: KeyResult
  valueLabelTextKey?: AntiloopTextType
}
export const GraphDot: React.FC<Props> = (props: Props) => {
  const { payload, lastPayload, type, keyResult, valueLabelTextKey = Texts.graphActualValueText, ...dotProps } = props
  const { dataKey, ...circleProps } = dotProps

  const { fontFamily, fontSize, fontWeight, letterSpacing, color: fill } = valueLabelTextKey.style
  const textStyle = { fontFamily, fontSize, fontWeight, letterSpacing, fill }

  const { cx: x, cy: y } = dotProps
  const { timestamp } = payload
  if (timestamp === lastPayload.timestamp && !!x && !!y) {
    invariant(typeof payload.value === 'number', '!value')
    return (
      <svg key={createUniqueId()}>
        <circle
          {...circleProps}
          r={type === GraphType.PURPLE ? 3 : SMALL_SPACING_RADII}
          fill={getColorForGraphType(type)}
          strokeWidth={0}
          key={createUniqueId()}
        />
        <text x={x + SMALL_SPACING_RADII * 2} y={y + SMALL_SPACING_RADII / 2} style={textStyle}>
          {getTodaysValueLabel(payload.value, keyResult)}
        </text>
      </svg>
    )
  }
  return <span key={createUniqueId()} />
}
