import styled from '@emotion/styled'
import React from 'react'
import { smallSpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { toPx } from '../ygdrasil/libs/Common'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { GreyLine, Line, NoWrapFigmaText } from './StyledComponents'
import WithTooltipOverflow from './WithTooltipOverflow'

type Props = { textKey: AntiloopTextType; text?: string; LineComponent?: React.FC }
export const TextWithLine = ({ textKey, LineComponent = GreyLine, text }: Props) => (
  <Box direction="row" fullWidth align="center">
    <LineComponent />
    <Box left right spacing={smallSpacing} minWidth="auto">
      <NoWrapFigmaText textKey={textKey} text={text} />
    </Box>
    <LineComponent />
  </Box>
)

export const TextWithLineRight = ({ textKey, LineComponent = GreyLine }: Props) => (
  <Box direction="row" fullWidth align="center">
    <Box right spacing={smallSpacing} minWidth="auto">
      <NoWrapFigmaText textKey={textKey} />
    </Box>
    <LineComponent />
  </Box>
)

export const TextWithLineAndDots = ({
  textKey,
  text,
  color = Colors.primaryBase,
  enableDots,
  maxWidth
}: {
  textKey: AntiloopTextType
  text?: string
  color?: string
  enableDots?: boolean
  maxWidth?: number
}) => {
  const size = toPx(textKey.style.fontSize)
  const DotElement = enableDots ? <Dot color={color} size={size / 2} /> : undefined
  const LineElement = enableDots ? <Line color={color} /> : undefined
  return (
    <Box direction="row" fullWidth align="center" justify="center">
      {DotElement}
      {LineElement}
      <Box left right spacing={smallSpacing} minWidth="auto" fullWidth alignText="center" style={{ maxWidth }}>
        <WithTooltipOverflow text={text} ellipsisTextKey={textKey}>
          <FigmaText textKey={textKey} text={text} />
        </WithTooltipOverflow>
      </Box>
      {LineElement}
      {DotElement}
    </Box>
  )
}

const Dot = styled(Box)<{ color: string; size: number }>`
  padding: ${({ size }) => size / 2}px;
  border-radius: ${({ size }) => size / 2}px;
  background-color: ${Colors.primaryBase};
`
