import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import { SelectFieldWithTextKeyAndLabelOptions } from '../components/SelectFieldWithTextKeyAndLabel'
import { SMALL_SPACING_PX } from '../enums/Spacings'
import { State } from '../hooks/useAppState'
import { createUniqueId } from '../ygdrasil/libs/Common'
import { ALL_STRATEGIC_PILLAR, NO_LEAD, NO_STRATEGIC_PILLAR, START_VALUE } from '../ygdrasil/libs/Consts'
import { getValueTextInEditObjectiveDialog } from '../ygdrasil/libs/GraphHelper'
import { isMatch } from '../ygdrasil/libs/isMatch'
import { getValue } from '../ygdrasil/libs/KeyResultHelper'
import { getAllObjectivesForObjective, isStrategicObjective, _Objective } from '../ygdrasil/libs/ObjectiveHelper'
import { getSearchObjectForObjective } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective, ObjectiveType, Organization, PartialConfig, Unit } from '../ygdrasil/types/types'
import { ObjectivePeriodAndType } from './ObjectivePeriodAndTypeOptionsHelper'
import { shouldShowStrategicPillars } from './StrategyViewObjectivesHeaderStrategiPillarsHelper'
import { createWithContent, EditorState } from './WYSIWYGEditor'

export type InitialValues = {
  _id: string
  name: string
  description: EditorState
  type: ObjectiveType
  startDate: string
  endDate: string
  teamIds: string[]
  parentObjectiveId?: string
  leadUserId?: string
  keyResults: KeyResult[]
  strategicPillarIds: string[]
  isNew?: boolean
}

export const HIDDEN_OBJECTIVE_FIELDS: Array<keyof Objective> = ['description', 'parentObjectiveId', 'leadUserId']

export function useInitialValues(
  allObjectives: Objective[],
  state: State,
  objective?: Partial<Objective>
): InitialValues {
  const isNew = !objective?._id
  const name = ''
  const description = createWithContent(objective?.description)
  let parentObjectiveId: string | undefined = undefined
  const startDate = dayjs().startOf('quarter').format()
  const endDate = dayjs().endOf('quarter').format()
  let teamIds: string[] = []

  let type = objective?.type || ObjectiveType.STRATEGIC

  const { selectedItemId } = state.searchState
  const selectedObjective = allObjectives.find((o) => o._id === selectedItemId)

  // base data objectives kommer in här med ObjectiveType.TOP och strategObjective = true
  if (isStrategicObjective(selectedObjective) && !objective) {
    parentObjectiveId = selectedItemId
    type = ObjectiveType.TOP
  }

  const strategicPillarIds = getDefaultStrategicPillarIds(state)

  if (objective?.parentObjectiveId && isStrategicObjective(objective)) type = ObjectiveType.TOP

  if (!!state.selectedTeamId) {
    type = ObjectiveType.TEAM
    teamIds = [state.selectedTeamId]
  }

  const initialValues = {
    _id: createUniqueId(),
    isNew,
    name,
    description,
    type,
    startDate,
    endDate,
    teamIds,
    leadUserId: undefined,
    keyResults: [],
    ordo: dayjs().valueOf(),
    strategicPillarIds,
    parentObjectiveId
  }

  if (objective) return { ...initialValues, ...objective, description }

  return initialValues
}

export const getKeyResultValueText = (keyResult: KeyResult, name: string): string => {
  const _value = getValue(keyResult)
  const value =
    name === START_VALUE
      ? getValueTextInEditObjectiveDialog(_value, keyResult)
      : getValueTextInEditObjectiveDialog(keyResult.goalValue, keyResult)
  if (typeof value === 'undefined') return ''
  switch (keyResult.unit) {
    case Unit.BOOLEAN:
      return value === '1'
        ? getFigmaText(Texts.indicatorListItemTodayTrue)
        : getFigmaText(Texts.indicatorListItemTodayFalse)
    default:
      return value.toString()
  }
}

export function getDefaultStrategicPillarIds({ searchState: { strategicPillar } }: State) {
  return strategicPillar && ![NO_STRATEGIC_PILLAR._id, ALL_STRATEGIC_PILLAR._id].includes(strategicPillar)
    ? [strategicPillar]
    : []
}

export function onChangeParentObjective(formProps: FormikProps<InitialValues>, parentObjectiveId: unknown): void {
  const { values } = formProps
  if (!!parentObjectiveId && values.type === ObjectiveType.STRATEGIC) formProps.values.type = ObjectiveType.TOP
  return formProps.setValues({ ...formProps.values, parentObjectiveId: parentObjectiveId as string })
}

export const onSelectPeriodAndType = (obj: ObjectivePeriodAndType, formProps: FormikProps<InitialValues>) => {
  const values = { ...formProps.values, ...obj }
  delete (values as any).strategicObjective
  formProps.setValues(values)
}

export const getSelectParentObjectiveOptions = (
  objective: InitialValues,
  allObjectives: Objective[],
  config?: PartialConfig
): SelectFieldWithTextKeyAndLabelOptions[] => {
  allObjectives = allObjectives
    .filter(({ _id }) => _id !== objective?._id)
    .filter(({ type }) => type !== ObjectiveType.TEAM)
    .filter(({ isArchived }) => !isArchived)
    .filter(({ endDate }) => !objective?.endDate || dayjs(endDate).isSameOrAfter(objective.endDate, 'day'))

  const strategicObjectives = allObjectives.filter((o) => isStrategicObjective(o))

  return strategicObjectives
    .reduce((a, b) => a.concat(getAllObjectivesForObjective(b, allObjectives)), [] as _Objective[])
    .map((o) => ({
      key: config?.enableShowId ? o._id : o.name,
      value: o._id,
      paddingLeft: (o.level || 0) * SMALL_SPACING_PX,
      bold: isStrategicObjective(o)
    }))
}

export function shouldShowStrategicPillarField(intitialValues: InitialValues, org?: Organization) {
  return isStrategicObjective(intitialValues) && shouldShowStrategicPillars(org)
}

export const onChangeLeadUserId = (
  leadUserId: string,
  formProps: FormikProps<any>,
  state: State,
  now: string = dayjs().format()
) => {
  if (leadUserId === NO_LEAD) leadUserId = ''
  formProps.setFieldValue('leadUserId', leadUserId)
  formProps.setFieldValue('leadMetaData', { assignerUserId: state.user._id, assignedAt: dayjs(now).format() })
}

export function shouldShowHiddenObjectiveNotification(objective: Objective, state: State, allObjectives: Objective[]) {
  if (state.selectedDashboardView === 'roadmap') return false
  const searchObject = getSearchObjectForObjective(objective, allObjectives, [], [])
  return isMatch(searchObject, state) === false
}

export const useShouldShowMore = (values?: Partial<Objective>) =>
  HIDDEN_OBJECTIVE_FIELDS.some((key) => !!values?.[key]) || !!values?.strategicPillarIds?.[0]
