import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import Images from '../ygdrasil/libs/Images'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaTextLoader from './FigmaTextLoader'

export function SuggestionButtonView({
  textKey,
  onClick,
  isLoading,
  text
}: {
  textKey: AntiloopTextType
  text?: string
  onClick?: () => unknown
  isLoading?: boolean
}) {
  return (
    <ConversationContainer fullWidth spacing={smallSpacing} fullPadding pointer onClick={onClick} align="center">
      <Box direction="row" align="center" justify="space-between" fullWidth>
        <Box right spacing={smallSpacing}>
          <FigmaImageContainer imageKey={Images.chatGpt} />
        </Box>
        <Box multiline justify="center">
          <FigmaTextLoader textKey={textKey} text={text} loading={isLoading} />
        </Box>
        <Box />
      </Box>
    </ConversationContainer>
  )
}

const ConversationContainer = styled(Box)`
  background-color: ${Colors.secondary90};
  border-radius: ${minBorderRadius};
`
