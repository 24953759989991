import { useState } from 'react'
import useAppState from '../hooks/useAppState'
import { useLogoutWithLoading } from '../hooks/useLogoutWithLoading'
import useNotification from '../hooks/useNotification'
import { createUser } from '../libs/ClientProcessHandler'
import { mapCreateOrgRequest } from '../libs/OnboardingHelper'
import { getSagaRedirectUrlForExperimental } from '../libs/SignInHelper'
import useQueryParams, { PageSignInSagaQueryParams } from '../ygdrasil/hooks/useQueryParams'
import { createAuthenticationToken } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import Texts from '../ygdrasil/libs/Texts'
import { CreateCustomAuthTokenRequest, FirebaseUser, User } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import LoadingPage from './LoadingPage'
import { DEFAULT_ONBOARDING_STATE } from './OnboardingMachine'
import RootPage from './RootPage'

export function PageSignInSaga(): JSX.Element {
  const [_loading, setLoading] = useState(false)
  const [showTakeMeBackButton, setShowTakeMeBack] = useState(false)

  const params = useQueryParams<PageSignInSagaQueryParams>()
  const __loading = useLogoutWithLoading()
  const notification = useNotification()
  const loading = _loading || __loading
  const { state } = useAppState()
  const redirectUrl = getSagaRedirectUrlForExperimental(params.id as string)
  const onLogin = (res: { user?: User; firebaseUser: FirebaseUser }) => {
    const { user, firebaseUser } = res
    if (!params.id) return Promise.resolve(notification.error())
    const { id } = params
    const req = { id } as CreateCustomAuthTokenRequest
    const onboardingState = { ...DEFAULT_ONBOARDING_STATE, firebaseUser }
    return Promise.resolve(setLoading(true))
      .then((): unknown => !user && createUser(mapCreateOrgRequest(firebaseUser, onboardingState), firebaseUser, state))
      .then(() => createAuthenticationToken(req, state))
      .then(() => {
        setShowTakeMeBack(true)
      })
  }

  if (showTakeMeBackButton && !!redirectUrl)
    return (
      <Box fullWidth align="center" fullPadding>
        <Button textKey={Texts.continueButtonText} onClick={() => (window.location.href = redirectUrl)} />
      </Box>
    )

  if (loading) return <LoadingPage />

  return <RootPage onLogin={onLogin} enableAppleAuthProvider enableOnboarding={false} />
}
