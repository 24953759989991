import { Draggable } from 'react-beautiful-dnd'
import { ActivityBoardKanbanCardProps, ActivityBoardKanbanCardActivity } from './ActivityBoardKanbanCardActivity'

export const ActivityBoardKanbanCard = (props: ActivityBoardKanbanCardProps) => {
  const { item, index } = props
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <ActivityBoardKanbanCardActivity {...props} />
        </div>
      )}
    </Draggable>
  )
}
