import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material'
import { FormikProps } from 'formik'
import { useMemo } from 'react'
import { bigSpacing, littleSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getTextWithDots } from '../libs/StrategyViewObjectiveTreeHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { asObject, toPx } from '../ygdrasil/libs/Common'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { InitialValues } from './EditActivityDialogForm'
import { StyledRadio } from './Radio'
import { useJiraIssues } from '../libs/ReactQueryHooks'
import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'

const LABEL_TEXT_LENGTH = 75

export const EditActivityFormAutoCompleteJiraIssue = ({ formProps }: { formProps: FormikProps<InitialValues> }) => {
  const { state } = useAppState()
  const { data: jiraIssues = [], isLoading } = useJiraIssues(state.selectedOrganizationId, state)

  const _jiraIssues = useMemo(() => asObject(jiraIssues, 'key'), [jiraIssues])

  const getLabel = (jiraIssueKey: string) =>
    getTextWithDots(
      `${_jiraIssues[jiraIssueKey]?.key || ''} ${_jiraIssues[jiraIssueKey]?.summary || ''}`,
      LABEL_TEXT_LENGTH
    )

  const onUpdateInput = (value: string) => {
    const isJiraIssue = !!_jiraIssues[value]
    formProps.setFieldValue('jiraIssueKey', isJiraIssue ? value : '', false)
    formProps.setFieldValue('url', !isJiraIssue ? value : '')
  }

  const onDelete = () => {
    formProps.setFieldValue('jiraIssueKey', '')
    formProps.setFieldValue('url', '')
  }

  return (
    <Autocomplete
      disableClearable
      multiple
      handleHomeEndKeys
      sx={{ '.MuiOutlinedInput-root': { flex: '100%' }, height: 'auto' }}
      value={[formProps.values.jiraIssueKey || formProps.values.url]}
      getOptionLabel={(jiraIssueKey) => getLabel(jiraIssueKey)}
      options={jiraIssues.map((i) => i.key) || ([] as any)}
      freeSolo
      onChange={(event, [prev, next]) => onUpdateInput(next)}
      renderOption={(option: any, jiraIssue, state) => (
        <Box
          fullWidth
          direction="row"
          align="center"
          fullPadding
          spacing={tinySpacing}
          link
          {...option}
          onClick={(e) => {
            option.onClick(e)
            onUpdateInput(jiraIssue)
          }}
        >
          <Box spacing={smallSpacing} right>
            <StyledRadio checked={state.selected} radioColor={Colors.primary40} />
          </Box>
          <FigmaText textKey={Texts.userMenuSettingsButtonText} text={getLabel(jiraIssue)} />
        </Box>
      )}
      renderTags={(value, getTagProps) => {
        return value
          .filter((i) => !!i)
          .map((chipValue: string, index: number) => (
            <Box key={`${chipValue}-${index}`} direction="row" style={{ flexWrap: 'wrap' }} gap={littleSpacing}>
              <StyledChip
                clickable
                label={!getLabel(chipValue).trim() ? chipValue : getLabel(chipValue)}
                {...getTagProps({ index })}
                onDelete={onDelete}
              />
            </Box>
          ))
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Box direction="row">
                {isLoading ? (
                  <Box right spacing={bigSpacing}>
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                ) : null}
                {params.InputProps.endAdornment}
              </Box>
            )
          }}
        />
      )}
    />
  )
}

const StyledChip = styled(Chip)`
  background: ${Colors.primary90};
  border-radius: ${minBorderRadius};
  font-family: ${Texts.cSsInvolvedTeamNameText.style.fontFamily};
  font-size: ${Texts.cSsInvolvedTeamNameText.style.fontSize};
  font-weight: ${Texts.cSsInvolvedTeamNameText.style.fontWeight};
  cursor: pointer;
  height: ${toPx(Texts.cSsInvolvedTeamNameText.style.fontSize) * 2}px;
`
