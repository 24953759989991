import { bigSpacing } from '../enums/Spacings'
import FigmaTextWithLinebreaks from '../ygdrasil/components/FigmaTextWithLinebreaks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { useFigmaTextForStrategyModel } from './FigmaTextForStrategyModel'

export function CelebrationViewEmptyState() {
  const text = useFigmaTextForStrategyModel({ textKey: Texts.celebrationEmptyState })
  return (
    <Box spacing={bigSpacing} fullPadding alignText="center" fullWidth>
      <Box spacing={bigSpacing} top fullWidth>
        <FigmaTextWithLinebreaks textKey={Texts.celebrationEmptyState} text={text} />
      </Box>
    </Box>
  )
}
