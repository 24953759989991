import { TIDIO_SCRIPT_URL } from '../ygdrasil/libs/Consts'

//@ts-ignore
export const showChatBubble = () => typeof tidioChatApi !== 'undefined' && tidioChatApi.display()

//@ts-ignore
export const hideChatBubble = () => typeof tidioChatApi !== 'undefined' && tidioChatApi.hide()

//@ts-ignore
export const openChat = () => typeof tidioChatApi !== 'undefined' && tidioChatApi.open()

export const loadChatBubble = () => {
  const script = document.createElement('script')
  script.setAttribute('src', TIDIO_SCRIPT_URL)
  document.head.appendChild(script)
}
