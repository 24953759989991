import useAppState from '../hooks/useAppState'
import { useSearchResultForTree } from '../hooks/useSearchResultForTree'
import {
  getObjectivesForRoadmap,
  getRoadmapSearchState,
  shouldShowEmptyStateForStrategyView
} from '../libs/RoadmapHelper'
import useVisibleObjectives from '../ygdrasil/hooks/useVisibleObjectives'
import RoadmapEmptyState from './RoadmapEmptyState'
import { RoadmapNonStrategicObjectives } from './RoadmapNonStrategicObjectives'
import { RoadmapStrategicObjectives } from './RoadmapStrategicObjectives'

export function RoadmapTopObjectives(props: { headerDates: string[] }) {
  const { headerDates } = props
  const { state } = useAppState()
  const { data: allObjectives } = useVisibleObjectives()

  const searchState = getRoadmapSearchState(headerDates)
  const searchResult = useSearchResultForTree({ ...searchState, strategicPillar: state.searchState.strategicPillar })
  const objectives = getObjectivesForRoadmap(allObjectives, searchResult, state)

  return (
    <>
      <RoadmapStrategicObjectives objectives={objectives} headerDates={headerDates} allObjectives={allObjectives} />
      <RoadmapNonStrategicObjectives objectives={objectives} headerDates={headerDates} allObjectives={allObjectives} />
      {shouldShowEmptyStateForStrategyView(objectives, state) && <RoadmapEmptyState />}
    </>
  )
}
