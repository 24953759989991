import React from 'react'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { openGuidedTour } from '../libs/Stonly'
import { openChat } from '../libs/TidioHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaImage from './FigmaImage'
import { ChatIcon, HelpOutlineIcon } from './Icons'
import { GreyLine } from './StyledComponents'
import { useStonlyWelcomeGuide } from './useStonlyWelcomeGuide'

export default function MenuContactSupport() {
  const guide = useStonlyWelcomeGuide()

  return (
    <Box fullWidth>
      <GreyLine />
      <Box direction="row" fullWidth spacing={smallSpacing} top align="center">
        <Box fullWidth>
          <Box fullWidth direction="row" align="center" onClick={() => openGuidedTour(guide)} pointer>
            <Box spacing={smallSpacing}>
              <HelpOutlineIcon />
            </Box>
            <Box left spacing={tinySpacing} id="GettingStartedMenu">
              <FigmaText textKey={Texts.applicationMenuFooterGettingStartedButtonText} />
            </Box>
          </Box>
          <Box fullWidth direction="row" align="center">
            <Box spacing={smallSpacing}>
              <ChatIcon />
            </Box>
            <Button variant="text" spacing={tinySpacing} onClick={openChat}>
              <FigmaText textKey={Texts.applicationMenuFooterChatWithUsButtonText} />
            </Button>
          </Box>
        </Box>
        <Box width="20%">
          <FigmaImage imageKey={Images.applicationLogo} />
        </Box>
      </Box>
    </Box>
  )
}
