import { InputAdornment } from '@mui/material'
import React, { useRef, useState } from 'react'
import { smallSpacing } from '../enums/Spacings'
import { useUserOptionsObject } from '../hooks/useUserOptionsObject'
import { LEAD_USER_CONATINER_SIZE } from '../libs/HardCodedSizes'
import { NO_LEAD } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { ArrowDropDown } from './Icons'
import { LeadSelectFieldMenu } from './LeadSelectFieldMenu'
import { TextFieldWithLabel } from './TextFieldWithLabel'
type Props = {
  value: string
  onChangeLeadUserId: (leadUserId: string) => unknown
  style?: React.CSSProperties
}

export const SIZE = LEAD_USER_CONATINER_SIZE * 2

export default function LeadSelectFieldWithLabel({ value, onChangeLeadUserId, style }: Props) {
  const _usersOptions = useUserOptionsObject()
  const [anchorEl, setAncorEl] = useState<HTMLElement | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const onClose = () => {
    setAncorEl(null)
    setTimeout(() => inputRef.current?.blur())
  }

  const onClick = (leadUserId: string) => {
    onChangeLeadUserId(leadUserId)
    onClose()
  }

  return (
    <Box fullWidth bottom spacing={smallSpacing} style={style}>
      <Box fullWidth onClick={(e) => setAncorEl(e.currentTarget)}>
        <TextFieldWithLabel
          fullWidth
          labelTextKey={Texts.rightPanelLeadLabel}
          placeholderTextKey={Texts.rightPanelAssignedLeadSelectionHelperText}
          value={_usersOptions[value]?.key || _usersOptions[NO_LEAD]?.key}
          inputRef={inputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDown />
              </InputAdornment>
            )
          }}
          inputProps={{ style: { cursor: 'pointer' } }}
        ></TextFieldWithLabel>
      </Box>
      <LeadSelectFieldMenu {...{ anchorEl, onClose, onClick, value }} />
    </Box>
  )
}
