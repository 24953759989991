import styled from '@emotion/styled'
import React from 'react'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import ButtonBase from './ButtonBase'

type Props = { className?: string; spacing?: string; textKey?: AntiloopTextType }
const ButtonTertiary: React.FC<Props> = (props) => {
  const { children, className, textKey, ...buttonProps } = props

  return (
    <StyledButton className={className} {...buttonProps}>
      <Box align="center" fullWidth link>
        {children || (
          <FigmaText
            textKey={Texts.cssGenericDialogButtonTertiaryButtonText}
            text={textKey ? getFigmaText(textKey) : undefined}
            textTransform="uppercase"
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<{ spacing?: string }>``

export default ButtonTertiary
