import styled from '@emotion/styled'
import { FormikProps } from 'formik'
import { bigSpacing, minSpacing, smallSpacing, SMALL_SPACING_PX, tinySpacing } from '../enums/Spacings'
import { InitialValues } from '../libs/EditObjectiveDialogHelper'
import { getStartDateForEditObjectiveDialog } from '../libs/ModalSelectPeriodHelper'
import { OnChangePeriod, onChangeType } from '../libs/ObjectivePeriodAndTypeOptionsHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { ObjectiveType } from '../ygdrasil/types/types'
import Box from './Box'
import { EditObjectiveDialogStrategicObjective } from './EditObjectiveDialogStrategicObjective'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { ObjectivePeriodAndTypeOptionsCustomFormFormik } from './ObjectivePeriodAndTypeOptionsCustomFormFormik'
import ObjectivePeriodAndTypeOptionsSlider from './ObjectivePeriodAndTypeOptionsSlider'
import { RadioContainer } from './Radio'

export type ObjectivePeriodAndTypeOptionsProps = {
  formProps: FormikProps<InitialValues>
}

export default function ObjectivePeriodAndTypeOptions(props: ObjectivePeriodAndTypeOptionsProps) {
  const { formProps } = props

  const onChangePeriod: OnChangePeriod = (obj) => {
    formProps.setValues({ ...formProps.values, ...obj })
  }

  return (
    <Container fullWidth right left spacing={smallSpacing}>
      <Box fullWidth>
        <Box fullWidth>
          <Box direction="row" align="center" fullWidth>
            <Box direction="row" align="center">
              <Box>
                <RadioContainer
                  onChange={() => onChangeType(ObjectiveType.STRATEGIC, formProps)}
                  checked={formProps.values.type === ObjectiveType.STRATEGIC}
                />
              </Box>
              <FigmaTextForStrategyModel textKey={Texts.rightPanelObjectiveEditObjectiveStrategicObjectiveLabel} />
            </Box>
            <Box direction="row" align="center" left>
              <Box>
                <RadioContainer
                  onChange={() => onChangeType(ObjectiveType.TOP, formProps)}
                  checked={formProps.values.type === ObjectiveType.TOP}
                />
              </Box>
              <FigmaTextForStrategyModel textKey={Texts.rightPanelObjectiveEditObjectiveTypeOptionCompany} />
            </Box>
            <Box direction="row" align="center" left>
              <Box>
                <RadioContainer
                  onChange={() => onChangeType(ObjectiveType.TEAM, formProps)}
                  checked={formProps.values.type === ObjectiveType.TEAM}
                />
              </Box>
              <FigmaTextForStrategyModel textKey={Texts.rightPanelObjectiveEditObjectiveTypeOptionTeam} />
            </Box>
          </Box>
          <Box fullWidth top>
            <Box fullWidth right left spacing={bigSpacing}>
              <ObjectivePeriodAndTypeOptionsSlider
                searchDates={formProps.values}
                onChange={onChangePeriod}
                sliderStartDate={getStartDateForEditObjectiveDialog(formProps.values)}
              />
            </Box>
          </Box>
        </Box>
        <Box top fullWidth bottom>
          <ObjectivePeriodAndTypeOptionsCustomFormFormik formProps={formProps} />
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background: ${Colors.whiteBg};
`
