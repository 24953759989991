import * as QueryHooks from '../libs/QueryHooks'
import useAppState from '../../hooks/useAppState'

export default function useVisibleObjectives() {
  const { state } = useAppState()
  const { searchState } = state
  const query = QueryHooks.useObjectives(state)
  let { data = [] } = query
  if (!searchState.showArchivedObjectives) data = data.filter((o) => !o.isArchived)
  return { ...query, data }
}
