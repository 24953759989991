import styled from '@emotion/styled'
import { InputAdornment, MenuItem, MenuProps } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import MaterialSelect, { SelectProps } from '@mui/material/Select'
import { useRef } from 'react'
import { tinySpacing } from '../enums/Spacings'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { IsSearchingLoader } from './AutoComplete'
import Box from './Box'
import { StyledCheckboxIcon } from './CheckboxFormik'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { BaseTextFieldStyles } from './TextFieldOutlined'

type Props = SelectProps & {
  labelTextKey: AntiloopTextType
  labelText?: string
  fullWidth?: boolean
  options: { key: string; value: any }[]
  menuProps?: Partial<MenuProps>
  renderValue: (seleted: string[]) => JSX.Element
  isLoading?: boolean
}
export default function SelectFieldWithLabelMulti(props: Props) {
  const { labelTextKey, labelText, menuProps, isLoading = false, ...selectProps } = props
  const values = selectProps.value as string[]
  const menuAnchorElement = useRef(null)
  const options = props.options.sort((a, b) => a.key.localeCompare(b.key))
  return (
    <Box fullWidth>
      <Box bottom spacing={tinySpacing}>
        <FigmaTextForStrategyModel textKey={labelTextKey} text={labelText} />
      </Box>
      <MaterialSelectStyled
        {...selectProps}
        multiple
        size="small"
        variant="outlined"
        margin="dense"
        label={getFigmaText(labelTextKey)}
        MenuProps={{
          ...menuProps,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        renderValue={props.renderValue}
        endAdornment={
          isLoading && (
            <InputAdornment position="end">
              <IsSearchingLoader />
            </InputAdornment>
          )
        }
      >
        {options.map(({ key, value }) => (
          <MenuItem key={key} value={value}>
            <Checkbox checked={values.some((_value) => _value === value)} checkedIcon={<StyledCheckboxIcon />} />
            <ListItemText primary={key} />
          </MenuItem>
        ))}
      </MaterialSelectStyled>
      <div ref={menuAnchorElement} />
    </Box>
  )
}

export const MaterialSelectStyled = styled(MaterialSelect)`
  ${BaseTextFieldStyles}
`
