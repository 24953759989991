import React from 'react'
import styled from '@emotion/styled'
import { tinySpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'
import { ArrowBack, ArrowForward } from './Icons'

export function ArrowContainer({ onClick, direction }: { direction: 'back' | 'forward'; onClick: () => void }) {
  return (
    <Container fullPadding spacing={tinySpacing} link onClick={onClick}>
      {direction === 'forward' ? <ArrowForward /> : <ArrowBack />}
    </Container>
  )
}
const Container = styled(Box)`
  background-color: ${Colors.whiteBg};
`
