import styled from '@emotion/styled'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import {
  StrategyViewObjectiveListRowDetailItemTableRow,
  TableHeaderBox,
  TableBox
} from './StrategyViewObjectiveListRowDetailItemTableRow'
import { shouldShowPeriodColumn, StrategyViewObjectiveListRowPeriodHeader } from './StrategyViewObjectiveListRowPeriod'
import { shouldShowTeamsColumn, StrategyViewObjectiveListRowTeamsHeader } from './StrategyViewObjectiveListRowTeams'

export default function StrategyViewObjectiveListRowDetailItemTable({
  item,
  parentItem
}: {
  item: Item
  parentItem?: Item
}) {
  return (
    <Table>
      <TableHead>
        <StyledTableRow>
          {shouldShowPeriodColumn(item.data) && (
            <TableCell align="center">
              <TableHeaderBox>
                <StrategyViewObjectiveListRowPeriodHeader
                  data={item.data}
                  styleTextKey={Texts.indicatorDetailPageDescriptionColumnLabel}
                />
              </TableHeaderBox>
            </TableCell>
          )}
          <TableCell align="center">
            <TableHeaderBox>
              <FigmaText textKey={Texts.indicatorDetailPageDescriptionColumnLabel} />
            </TableHeaderBox>
          </TableCell>
          <TableCell align="center">
            <TableHeaderBox>
              <FigmaText textKey={Texts.indicatorDetailPageLeadColumnLabel} />
            </TableHeaderBox>
          </TableCell>
          {shouldShowTeamsColumn(item.data) && (
            <TableCell align="center">
              <TableHeaderBox>
                <StrategyViewObjectiveListRowTeamsHeader
                  data={item.data}
                  styleTextKey={Texts.indicatorDetailPageDescriptionColumnLabel}
                />
              </TableHeaderBox>
            </TableCell>
          )}
          <TableCell>
            <TableHeaderBox>
              <FigmaText textKey={Texts.indicatorDetailPageProgressColumnLabel} />
            </TableHeaderBox>
          </TableCell>
          <TableCell>
            <TableHeaderBox>
              <FigmaText textKey={Texts.indicatorDetailPageConfidenceColumnLabel} />
            </TableHeaderBox>
          </TableCell>
          <TableCell width="60%">
            <TableBox fullWidth></TableBox>
          </TableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        <StrategyViewObjectiveListRowDetailItemTableRow item={item} parentItem={parentItem} />
      </TableBody>
    </Table>
  )
}

const StyledTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    border-bottom: 0.25px solid ${Colors.neutral40};
    padding: 0px;
  }
`
