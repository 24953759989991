import EventEmitterPkg from 'events'

export enum Events {
  OPEN_TODOS_DRAWER = 'OPEN_TODOS_DRAWER',
  OPEN_REORDER_OBJECTIVE_DRAWER = 'OPEN_REORDER_OBJECTIVE_DRAWER',
  OPEN_EDIT_OBJETIVE_DIALOG = 'OPEN_EDIT_OBJETIVE_DIALOG',
  OPEN_EDIT_KEY_RESULT_DIALOG = 'OPEN_EDIT_KEY_RESULT_DIALOG',
  OPEN_EDIT_ACTIVITY_RESULT_DIALOG = 'OPEN_EDIT_ACTIVITY_RESULT_DIALOG',
  NEW_SERVER_ERROR = 'NEW_SERVER_ERROR',
  NEW_ANALYTICS_EVENT = 'NEW_ANALYTICS_EVENT',
  NEW_SEARCH_TEXT = 'NEW_SEARCH_TEXT',
  OPEN_BUSINESS_PLAN_DRAWER = 'OPEN_BUSINESS_PLAN_DRAWER', // Should only Be Thrown from DrawerGlobalBusinessPlan
  NEW_ACTIVE_USER = 'NEW_ACTIVE_USER',
  ON_DELETE_STRATEGIC_PILLAR = 'ON_DELETE_STRATEGIC_PILLAR',
  NEW_NOTIFICATION = 'NEW_NOTIFICATION'
}

const eventEmitter = new EventEmitterPkg.EventEmitter()

export const upsertListener = (eventName: Events, listener: (...args: any[]) => void) => {
  eventEmitter.removeAllListeners(eventName)
  eventEmitter.on(eventName, listener)
}

export default eventEmitter

export type EventEmitter = typeof eventEmitter
