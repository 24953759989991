import styled from '@emotion/styled'
import { ClickAwayListener } from '@mui/material'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { minBorderRadius } from '../enums/BorderRadixes'
import { littleSpacing, tinySpacing } from '../enums/Spacings'
import { BAR_CHART_EDIT_FIELD_WIDTH, BAR_CHART_HEIGHT, BAR_CHART_MARGIN, BAR_CHART_WIDTH } from '../libs/HardCodedSizes'
import {
  calculateDragIndicatorValueChange,
  EditableIndicatorDirectionEnum,
  EditableIndicatorPropertyEnum,
  fillMissingData,
  formatYAxisLabel,
  getActualBarSize,
  getCalculatedDragIndicatorValues,
  getCurrentEditableValue,
  getCurrentXAxisDataKey,
  getCustomizedDotData,
  getDayStringsOfMonthsBetween,
  getNewCurrentEditableIndicator,
  getXAxisData,
  getXAxisLabel,
  isActualGreen,
  SuccessViewGraphBarData,
  SuccessViewGraphEditType
} from '../libs/SuccessViewBarChartHelper'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import Colors from '../ygdrasil/libs/Colors'
import { BLUR_KEYCODES, ESC_KEYCODE, ONE_YEAR_AND_HALF_IN_MONTHS } from '../ygdrasil/libs/Consts'
import { updateGrowthIndicator } from '../ygdrasil/libs/ProcessHandler'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { GrowthIndicator, GrowthIndicatorValue, SearchDates } from '../ygdrasil/types/types'
import Box from './Box'
import { FigmaTextWithTooltipOverflow } from './FigmaTextWithTooltipOverflow'
import { BarShape, CustomizedDot, CustomizedTooltip } from './SuccessViewGraphViewBarChartComponents'
import { TextFieldOutlinedNumberInputForGrowthView } from './TextFieldOutlinedNumberInput'

type Props = {
  growthIndicator: GrowthIndicator
  searchDates: SearchDates
  editType: string
}

export type EditableIndicator = {
  height?: number
} & Partial<SuccessViewGraphBarData>

const SuccessViewGraphViewBarChart: React.FC<Props> = ({ growthIndicator, searchDates, editType }) => {
  const logEvent = useLogEvent()
  const lastEditableIndicatorDirection = useRef<EditableIndicatorDirectionEnum>(EditableIndicatorDirectionEnum.UP)
  const [currentEditableProp, setCurrentEditableProp] = useState<EditableIndicatorPropertyEnum | null>(null)
  const [previousEditableIndicator, setPreviousEditableIndicator] = useState<EditableIndicator | null>(null)
  const [currentEditableIndicator, setCurrentEditableIndicator] = useState<EditableIndicator | null>(null)
  const [currentUpdatedIndicator, setCurrentUpdatedIndicator] = useState<EditableIndicator | null>(null)
  const [rerenderDefaultValue, setRerenderDefaultValue] = useState(false)
  const [growthIndicatorValues, setGrowthIndicatorValues] = useState({})
  const [showEditField, setShowEditField] = useState(false)
  const [triggerSave, setTriggerSave] = useState(false)
  const { startDate, endDate } = searchDates
  const { name, values, isArchived } = growthIndicator
  const isDragging = editType === SuccessViewGraphEditType.DRAG
  const currentEditableValue = getCurrentEditableValue(currentEditableProp, currentEditableIndicator)
  const monthsBetween = getDayStringsOfMonthsBetween(new Date(startDate), new Date(endDate))
  const { filledData, dataMin, dataMax } = fillMissingData(monthsBetween, growthIndicatorValues)
  const actualBarSize = getActualBarSize(monthsBetween)
  const xAxisDataKey = getCurrentXAxisDataKey(filledData)
  const formattedXAxisData = getXAxisData(filledData, xAxisDataKey)

  const { dataMaxHistory, dataMinHistory } =
    useMemo(() => {
      return {
        dataMaxHistory: dataMax,
        dataMinHistory: dataMin
      }
    }, [previousEditableIndicator, showEditField, monthsBetween]) || {}

  useEffect(() => {
    setGrowthIndicatorValues(values)
  }, [values])

  useEffect(() => {
    if (isDragging) return
    if (!currentEditableIndicator) return setRerenderDefaultValue(false)
    setRerenderDefaultValue(false)
    const timeout = setTimeout(() => {
      setRerenderDefaultValue(true)
    }, 1)
    return () => timeout && clearTimeout(timeout)
  }, [currentEditableIndicator])

  const handleEsc = useCallback(
    (event) => {
      if (event.keyCode === ESC_KEYCODE) {
        const { date } = currentEditableIndicator || {}
        const growthIndicatorValuesCopy = cloneDeep(growthIndicatorValues)
        if (!date || !currentEditableProp || !previousEditableIndicator?.[currentEditableProp]) return

        growthIndicatorValuesCopy[date] = { ...previousEditableIndicator }
        setGrowthIndicatorValues(growthIndicatorValuesCopy)
        setShowEditField(false)
        resetEditableIndicator()
      }
    },
    [currentEditableIndicator, currentEditableProp, previousEditableIndicator, growthIndicatorValues]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [handleEsc])

  useEffect(() => {
    if (!triggerSave) return
    const hasChangedValues =
      previousEditableIndicator?.actual !== currentUpdatedIndicator?.actual ||
      previousEditableIndicator?.goal !== currentUpdatedIndicator?.goal

    if (triggerSave && hasChangedValues && isDragging && !!currentEditableIndicator) {
      updateGrowthIndicator({ ...growthIndicator, values: growthIndicatorValues }, logEvent).then(() => {
        setShowEditField(false)
        resetEditableIndicator()
      })
    } else {
      resetEditableIndicator()
    }
    setTriggerSave(false)
  }, [triggerSave])

  const resetEditableIndicator = () => {
    if (showEditField && !isDragging) return
    setCurrentEditableProp(null)
    setCurrentEditableIndicator(null)
    setPreviousEditableIndicator(null)
  }

  const handleMouseMove = (nextState, event) => {
    event.preventDefault()
    const isDown = event.movementY > 0
    const isSwitchingDirection =
      isDown !== (lastEditableIndicatorDirection.current === EditableIndicatorDirectionEnum.DOWN)

    if (!currentEditableIndicator?.date || event?.movementY === 0 || !currentEditableProp) return
    lastEditableIndicatorDirection.current = isDown
      ? EditableIndicatorDirectionEnum.DOWN
      : EditableIndicatorDirectionEnum.UP

    if (isSwitchingDirection) {
      return handleSwitchDirection(event, isDown, nextState)
    }

    const indicatorValueChange = calculateDragIndicatorValueChange(dataMaxHistory, dataMinHistory, nextState, event)

    const growthIndicatorValuesCopy = getCalculatedDragIndicatorValues(
      indicatorValueChange,
      currentEditableIndicator,
      growthIndicatorValues,
      currentEditableProp
    )
    const newCurrentEditableIndicator = getNewCurrentEditableIndicator(
      growthIndicatorValuesCopy,
      currentEditableIndicator,
      currentEditableProp
    )
    if (!newCurrentEditableIndicator) return
    setCurrentUpdatedIndicator(newCurrentEditableIndicator)
    setGrowthIndicatorValues(growthIndicatorValuesCopy)
  }

  const handleSwitchDirection = (event, isDown, nextState) => {
    const { date } = currentEditableIndicator || {}
    if (!currentEditableProp || !date) return
    const indicatorValueChange = calculateDragIndicatorValueChange(dataMaxHistory, dataMinHistory, nextState, event)

    const growthIndicatorValuesCopy = getCalculatedDragIndicatorValues(
      indicatorValueChange,
      currentEditableIndicator,
      growthIndicatorValues,
      currentEditableProp
    )

    const newCurrentEditableIndicator = getNewCurrentEditableIndicator(
      growthIndicatorValuesCopy,
      currentEditableIndicator,
      currentEditableProp
    )
    if (!newCurrentEditableIndicator) return
    setCurrentUpdatedIndicator(newCurrentEditableIndicator)
    return setGrowthIndicatorValues(growthIndicatorValuesCopy)
  }

  const handleEditIndicator = (e) => {
    const { date } = currentEditableIndicator || {}
    const growthIndicatorValuesCopy = cloneDeep(growthIndicatorValues)
    if (!date) return
    if (!growthIndicatorValuesCopy[date]) {
      growthIndicatorValuesCopy[date] = { ...currentEditableIndicator }
    }

    growthIndicatorValuesCopy[date][currentEditableProp] = Number(e.target.value.split(' ').join(''))

    updateGrowthIndicator({ ...growthIndicator, values: growthIndicatorValuesCopy }, logEvent).then(() =>
      setShowEditField(false)
    )
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowEditField(false)
        isDragging ? setTriggerSave(true) : resetEditableIndicator()
      }}
    >
      <Wrapper align="center" alignText="center">
        <IndicatorSummary fullWidth fullPadding spacing={littleSpacing} justify="center">
          <FigmaTextWithTooltipOverflow
            textKey={isArchived ? Texts.cssSuccessViewIndicatorTextArchieved : Texts.monthlyGraphHeaderText}
            text={name}
            textTransform="uppercase"
          />
        </IndicatorSummary>
        <Box alignSelf="flex-end">
          {showEditField && !isDragging && rerenderDefaultValue && (
            <Box top spacing={tinySpacing}>
              <StyledTextFieldOutlined
                autoFocus
                label={
                  currentEditableProp === EditableIndicatorPropertyEnum.GOAL
                    ? getFigmaText(Texts.successEditGoalsLabel)
                    : getFigmaText(Texts.successEditActualsLabel)
                }
                defaultValue={currentEditableValue}
                onKeyDown={(e) => BLUR_KEYCODES.includes(e.keyCode) && handleEditIndicator(e)}
              />
            </Box>
          )}
        </Box>
        <ResponsiveContainer>
          <ComposedChart
            data={filledData}
            margin={{
              top: BAR_CHART_MARGIN,
              right: BAR_CHART_MARGIN,
              bottom: BAR_CHART_MARGIN
            }}
            onMouseMove={isDragging && currentEditableIndicator ? handleMouseMove : undefined}
            onMouseLeave={() => (isDragging ? setTriggerSave(true) : resetEditableIndicator())}
            onMouseUp={() => (isDragging ? setTriggerSave(true) : undefined)}
          >
            <CartesianGrid stroke={Colors.whitesmoke} />
            <XAxis
              tickLine={false}
              dataKey={xAxisDataKey}
              interval={0}
              tickFormatter={(value, idx) => getXAxisLabel(xAxisDataKey, value)}
              ticks={[...formattedXAxisData]}
              style={{ ...Texts.graphXAxisLabel.style, textTransform: 'uppercase' }}
              angle={-45}
              textAnchor="end"
            />
            <YAxis
              tickLine={false}
              domain={[dataMinHistory, dataMaxHistory]}
              tickFormatter={(value) => formatYAxisLabel(value)}
              type="number"
              scale="linear"
              dataKey="goal"
            />
            <Line
              dataKey="goal"
              connectNulls
              type="linear"
              dot={false}
              strokeDasharray="5 5"
              stroke={Colors.primary70}
            />
            <Tooltip
              content={(props) => {
                return (
                  <CustomizedTooltip
                    {...props}
                    currentEditableProp={currentEditableProp}
                    direction={growthIndicator.direction}
                    filledData={filledData}
                  />
                )
              }}
            />
            <Line
              dataKey="goal"
              type="monotone"
              stroke={Colors.primaryBase}
              activeDot={false}
              dot={(props) => {
                const moreThanYearAndHalf = monthsBetween.length > ONE_YEAR_AND_HALF_IN_MONTHS
                if (moreThanYearAndHalf) return <></>
                return (
                  <CustomizedDot
                    {...props}
                    key={props.payload?.date ?? props?.index}
                    onClick={() => (isDragging ? undefined : setShowEditField(true))}
                    isDragging={isDragging}
                    isActive={
                      props.payload?.date === currentEditableIndicator?.date &&
                      currentEditableProp === EditableIndicatorPropertyEnum.GOAL
                    }
                    handleMouseDown={() => {
                      setCurrentEditableProp(EditableIndicatorPropertyEnum.GOAL)
                      const data = getCustomizedDotData(props)
                      setCurrentEditableIndicator(data)
                      setPreviousEditableIndicator(data)
                    }}
                    handleMouseUp={() => (isDragging ? setTriggerSave(true) : resetEditableIndicator())}
                  />
                )
              }}
              strokeWidth={1.5}
              isAnimationActive={false}
            />
            <ReferenceLine y={0} stroke={Colors.lightgray} />
            <Bar
              dataKey="actual"
              barSize={actualBarSize}
              onClick={() => (isDragging ? undefined : setShowEditField(true))}
              onMouseDown={(data) => {
                setCurrentEditableProp(EditableIndicatorPropertyEnum.ACTUAL)
                setPreviousEditableIndicator(data)
                setCurrentEditableIndicator(data)
              }}
              onMouseUp={() => (isDragging ? setTriggerSave(true) : undefined)}
              isAnimationActive={false}
              shape={(props) => (
                <BarShape
                  {...props}
                  key={props.payload?.date ?? props?.index}
                  isDragging={isDragging}
                  isActive={
                    props.payload?.date === currentEditableIndicator?.date &&
                    currentEditableProp === EditableIndicatorPropertyEnum.ACTUAL
                  }
                />
              )}
            >
              {filledData.map((entry, index) => {
                const { isGreenActual } = isActualGreen(
                  { goal: entry.goal, actual: entry.actual } as GrowthIndicatorValue,
                  growthIndicator.direction,
                  filledData,
                  index
                )
                return <Cell key={`actual-${index}`} fill={isGreenActual ? Colors.good : Colors.bad} />
              })}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </Wrapper>
    </ClickAwayListener>
  )
}

export default SuccessViewGraphViewBarChart

const Wrapper = styled(Box)`
  width: ${BAR_CHART_WIDTH}px;
  height: ${BAR_CHART_HEIGHT};
  * {
    user-select: none;
  }
`

const StyledTextFieldOutlined = styled(TextFieldOutlinedNumberInputForGrowthView)`
  .MuiInputBase-input {
    width: ${BAR_CHART_EDIT_FIELD_WIDTH};
    padding: ${tinySpacing};
    font-size: 14px;
    color: ${Colors.primaryBase};
  }
`
const IndicatorSummary = styled(Box)`
  height: 32px;
  background-color: ${Colors.primary90};
  border-radius: ${minBorderRadius};
`
