import { GoogleSheetDriveItemData } from '../types/types'
import { DATE, VALUE, YYYY_MM_DD } from './Consts'
import { DayJS } from './DayJsHelper'

export function isValidData(data?: GoogleSheetDriveItemData) {
  if (!data) return false
  const { rows, headers } = data

  if (!rows) return false
  if (headers[0] !== DATE) return false
  if (headers[1] !== VALUE) return false

  const hasCorrectDates = rows.every(([date, value], i) => i === 0 || DayJS(date, YYYY_MM_DD, true).isValid())
  if (!hasCorrectDates) return false

  const hasCorrectValues = rows.every(([date, value], i) => i === 0 || (typeof value === 'string' && value.length > 0))
  if (!hasCorrectValues) return false

  return true
}

export const getValuesForRows = (rows: GoogleSheetDriveItemData['rows']) =>
  rows
    .filter(([date, value]) => DayJS(date, YYYY_MM_DD, true).isValid() && typeof parseFloat(value) === 'number')
    .map(([date, value]) => ({ date: DayJS(date).format(YYYY_MM_DD), value: parseFloat(value) }))
