import styled from '@emotion/styled'
import { useMemo } from 'react'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { tinySpacing } from '../enums/Spacings'
import { isActualGreen, SuccessViewGraphBarData } from '../libs/SuccessViewBarChartHelper'
import Colors from '../ygdrasil/libs/Colors'
import { Direction, GrowthIndicatorValue } from '../ygdrasil/types/types'
import Box from './Box'

export const CustomizedDot = ({ handleMouseDown, handleMouseUp, isDragging, onClick, isActive, ...props }) => {
  const { cx, cy, stroke, height = 1 } = props

  const isGrayedOut = !cy
  const grayedOutCy = height + 18

  const radius = useMemo(() => {
    if (isGrayedOut) return 6
    if (isActive) return 4
    return 3
  }, [isActive, isGrayedOut])

  return (
    <>
      {isActive && (
        <circle cx={cx} cy={isGrayedOut ? grayedOutCy : cy} r={12} strokeWidth={0} fill={Colors.primary95} />
      )}
      <circle
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={onClick}
        style={{ cursor: isDragging ? 'ns-resize' : 'pointer' }}
        cx={cx}
        cy={isGrayedOut ? grayedOutCy : cy}
        r={radius}
        stroke={isGrayedOut ? Colors.neutralVariant90 : stroke}
        strokeWidth={isGrayedOut ? 3 : 1.5}
        fill="white"
      />
    </>
  )
}

export const BarShape = ({ isActive, isDragging, ...props }) => {
  const { x, y, width, height, fill } = props
  const calculatedY = height > 0 ? y : y - Math.abs(height)
  const isNull = props.actual === null
  const heightCalc = Math.abs(height) <= 1 ? 2 : Math.abs(height)

  return (
    <>
      <rect
        style={{ cursor: isDragging ? 'ns-resize' : 'pointer' }}
        x={x}
        y={calculatedY}
        width={width}
        height={isNull ? Math.abs(height) : heightCalc}
        fill={fill}
        rx={2}
        ry={2}
      />
      {isActive && <circle cx={x + width / 2} cy={y} r={width} strokeWidth={4} stroke={fill} fill="white" />}
    </>
  )
}

export const CustomizedTooltip = ({ currentEditableProp, ...props }) => {
  const { active, payload, direction, filledData } = props
  const { payload: payloadData } = payload[0] || {}
  if (!payloadData) return null
  if (!filledData) return null

  const index = filledData.findIndex((item) => item.date === payloadData?.date)

  const { linearValue } = isActualGreen(
    { goal: payloadData?.goal, actual: payloadData?.actual } as GrowthIndicatorValue,
    direction as Direction,
    filledData as SuccessViewGraphBarData[],
    index
  )

  const atcualGoal = payloadData.goal === 0 ? '0' : payloadData.goal //to handle case when goal is 0

  if (active && payload && payload.length) {
    return (
      <Container spacing={tinySpacing} fullPadding>
        {!!currentEditableProp && <Box>{payloadData?.[currentEditableProp]}</Box>}
        {!currentEditableProp && (
          <>
            <Box>Goal: {atcualGoal || `(${linearValue ? linearValue : '-'})`}</Box>
            <Box>Actual: {payloadData.actual || 0}</Box>
          </>
        )}
      </Container>
    )
  }
  return null
}

const Container = styled(Box)`
  background-color: ${Colors.primary95};
  border: 1px solid ${Colors.whitesmoke};
  border-radius: ${tinyBorderRadius};
  color: ${Colors.slategray};
`
