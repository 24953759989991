import { User } from '../types/types'

const baseUrl =
  'https://firebasestorage.googleapis.com/v0/b/our-big-picture.appspot.com/o/profile_pictures%2Fprofile_pictures%2Fthumbnails%2F'

export enum PictureSizes {
  THUMBNAIL = 40,
  PROFILE_PICTURE = 200
}

export const useUrlForProfilePicture = (size: number, { profileImage }: User) => {
  const pictureSize =
    Object.values(PictureSizes).find((pictureSize) => pictureSize === size) || PictureSizes.PROFILE_PICTURE
  return `${baseUrl}${profileImage
    ?.replace('.png', '')
    .replace('profile_pictures/', '')}_${pictureSize}x${pictureSize}.png?alt=media`
}
