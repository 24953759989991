import { CSSProperties } from 'react'
import { defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import { ButtonContainer } from './EditKeyResultsSection'

export function DrawerFooterButtons({
  onClickContinue,
  onClickDelete,
  onClickCancel,
  style
}: {
  onClickContinue: () => unknown
  onClickDelete?: () => unknown
  onClickCancel: (formProps: any) => unknown
  style?: CSSProperties
}) {
  return (
    <ButtonContainer fullPadding style={style}>
      <Box justify="space-between" fullWidth direction="row">
        <Box>
          {onClickDelete && (
            <Button
              textKey={Texts.deleteButtonText}
              onClick={onClickDelete}
              buttonType="tertiary"
              spacing={tinySpacing}
            />
          )}
        </Box>

        <Box direction="row" align="center">
          <Button
            textKey={Texts.cancelButtonText}
            onClick={onClickCancel}
            buttonType="secondary"
            spacing={smallSpacing}
          />
          <Box left>
            <Button spacing={defaultSpacing} onClick={onClickContinue}>
              <FigmaText textKey={Texts.saveButtonText} />
            </Button>
          </Box>
        </Box>
      </Box>
    </ButtonContainer>
  )
}
