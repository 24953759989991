import { tinySpacing } from '../enums/Spacings'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { InfoIcon } from './Icons'
import Popper from './Popper'
import FigmaImageContainer from './FigmaImageContainer'
import Images from '../ygdrasil/libs/Images'
import React from 'react'

export function MyActionsMyFocusEmptyState() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  return (
    <Box fullWidth direction="row" align="center">
      <FigmaTextForStrategyModel textKey={Texts.myFocusThisWeekEmptyText} />
      <Box
        left
        spacing={tinySpacing}
        link
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(undefined)}
      >
        <InfoIcon />
      </Box>
      <Popper anchorEl={anchorEl}>
        <FigmaImageContainer imageKey={Images.helperImageForPrioritizedAction} />
      </Popper>
    </Box>
  )
}
