import dayjs from 'dayjs'
import { Todo } from '../ygdrasil/types/types'

export const NAME = 'name'

export function shouldShowCopyAllTodosToTodayButton(date: string, todos: Todo[]) {
  return dayjs().isAfter(date, 'day') && todos.length > 0
}

export function shouldShowAddTodoButton(date: string) {
  return dayjs().isSame(date, 'day') || dayjs().isBefore(date, 'day')
}
