import { Breakpoint, useTheme } from '@mui/material/styles'
import _useMediaQuery from '@mui/material/useMediaQuery'

type Breakpoints = {
  [key in Breakpoint]: boolean
}

const BREAK_POINTS: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl']

export type BreakPointRule = {
  shouldShowExpandedMenu: boolean
  shouldShowExtraColumns: boolean
}

const BREAK_POINT_RULES: { [key in Breakpoint]: BreakPointRule } = {
  xs: { shouldShowExpandedMenu: false, shouldShowExtraColumns: false },
  sm: { shouldShowExpandedMenu: false, shouldShowExtraColumns: false },
  md: { shouldShowExpandedMenu: false, shouldShowExtraColumns: false },
  lg: { shouldShowExpandedMenu: true, shouldShowExtraColumns: false },
  xl: { shouldShowExpandedMenu: true, shouldShowExtraColumns: true }
}

export default function useMediaQuery(breakPoint: Breakpoint) {
  const theme = useTheme()
  return _useMediaQuery(theme.breakpoints.up(breakPoint))
}

export const useIsBigScreen = () => useMediaQuery('lg')

export const useMediaQueryRules = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const breakPoints = BREAK_POINTS.reduce((a, key) => ({ ...a, [key]: useMediaQuery(key) }), {} as Breakpoints)

  // TODO WRUTE TESTS shold apply rule if correct media query
  // TODO WRUTE TESTS shold not apply rule if no media queries match

  return Object.keys(breakPoints)
    .filter((key) => breakPoints[key as Breakpoint])
    .reduce(
      (a, key) => ({
        shouldShowExpandedMenu: a.shouldShowExpandedMenu || BREAK_POINT_RULES[key]?.shouldShowExpandedMenu,
        shouldShowExtraColumns: a.shouldShowExtraColumns || BREAK_POINT_RULES[key]?.shouldShowExtraColumns
      }),
      {
        shouldShowExpandedMenu: false,
        shouldShowExtraColumns: false
      } as BreakPointRule
    )
}

// see https://mui.com/material-ui/customization/breakpoints/

/**
 * 
xs, extra-small: 0px
sm, small: 600px
md, medium: 1000px
lg, large: 1200px
xl, extra-large: 1536px
 * 
 */
