import styled from '@emotion/styled'
import { regularBorderRadius } from '../enums/BorderRadixes'
import shadows from '../enums/Shadows'
import { littleSpacing, smallSpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { NoWrapFigmaText } from './StyledComponents'

export function FilterOffButton({
  onClick,
  textKey = Texts.clearFilterButtonTextPurple
}: {
  textKey?: AntiloopTextType
  onClick?: () => unknown
}) {
  return (
    <FilterOff direction="row" align="center" onClick={onClick} link alignSelf="flex-start" gap={littleSpacing}>
      <NoWrapFigmaText textKey={textKey} />
      <FigmaImageContainer imageKey={Images.closeIconPurple} />
    </FilterOff>
  )
}
const FilterOff = styled(Box)`
  background-color: ${Colors.background};
  border-radius: ${regularBorderRadius};
  box-shadow: ${shadows.gazelleBoxSoft};
  border: 1.5px solid ${Colors.primary20};
  padding: ${littleSpacing} ${smallSpacing};
`
