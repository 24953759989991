import { PreferredLoginMethod } from '../ygdrasil/types/SignInTypes'

export function shouldShowMicrosoft(preferredLoginMethod: PreferredLoginMethod) {
  return [PreferredLoginMethod.NONE, PreferredLoginMethod.MICROSOFT, PreferredLoginMethod.MAIL].includes(
    preferredLoginMethod
  )
}
export function shouldShowGoogle(preferredLoginMethod: PreferredLoginMethod) {
  return [PreferredLoginMethod.NONE, PreferredLoginMethod.GOOGLE, PreferredLoginMethod.MAIL].includes(
    preferredLoginMethod
  )
}

export function shouldShowMail(preferredLoginMethod: PreferredLoginMethod) {
  return [PreferredLoginMethod.NONE, PreferredLoginMethod.MAIL].includes(preferredLoginMethod)
}
