import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Mark } from '../libs/ObjectivePeriodAndTypeOptionsHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'

export const ObjectivePeriodAndTypeOptionsLabels = ({ disabled, marks }: { disabled?: boolean; marks: Mark[] }) => {
  const prevYearMarks = marks.filter(({ date }) => dayjs(date).year() === dayjs().year() - 1)
  const thisYearMarks = marks.filter(({ date }) => dayjs(date).year() === dayjs().year())
  const nextYearMarks = marks.filter(({ date }) => dayjs(date).year() === dayjs().year() + 1)
  const numberOfMarks = thisYearMarks.length + nextYearMarks.length + 2

  return (
    <Container fullWidth direction="row" align="flex-end" disabled={disabled}>
      {prevYearMarks.length > 0 && (
        <YearContainer width={`${(prevYearMarks.length * 100) / numberOfMarks}%`}>
          <ObjectivePeriodAndTypeOptionsQuatersLabels marks={prevYearMarks} />
        </YearContainer>
      )}
      <YearContainer width={`${(thisYearMarks.length * 100) / numberOfMarks}%`}>
        <ObjectivePeriodAndTypeOptionsQuatersLabels marks={thisYearMarks} />
      </YearContainer>
      <YearContainer width={`${(nextYearMarks.length * 100) / numberOfMarks}%`}>
        <ObjectivePeriodAndTypeOptionsQuatersLabels marks={nextYearMarks} />
      </YearContainer>

      <YearContainer width={`${(1 * 100) / numberOfMarks}%`} fullHeight justify="flex-end" alignText="center">
        <FigmaText textKey={Texts.cssPeriodSelectorYearText} text={dayjs().add(2, 'year').year().toString()} />
      </YearContainer>
      <Box width={`${(1 * 100) / numberOfMarks}%`} fullHeight justify="flex-end" alignText="center">
        <FigmaText textKey={Texts.cssPeriodSelectorQuarterText} text={dayjs().add(3, 'year').year().toString()} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

const YearContainer = styled(Box)`
  border-right: 1px solid ${Colors.neutralUnselected};
`

function ObjectivePeriodAndTypeOptionsQuatersLabels({ marks }: { marks: Mark[] }) {
  return (
    <Box fullWidth>
      <Box fullWidth align="center">
        <FigmaText textKey={Texts.cssPeriodSelectorYearText} text={dayjs(marks[0]?.date).year().toString()} />
      </Box>
      <Box direction="row" fullWidth justify="space-around">
        {marks.map((mark, key) => (
          <Box key={key}>
            <FigmaText key={key} textKey={Texts.cssPeriodSelectorQuarterText} text={`Q${dayjs(mark.date).quarter()}`} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
