import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { onClickActivityName } from '../libs/ActivityBoardHelper'
import { getTextWithDots } from '../libs/StrategyViewObjectiveTreeHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import { ACTIVITY_CARD_TEXT_MAX_LENGTH, isOverDue } from '../ygdrasil/libs/ActivityHelper'
import Colors from '../ygdrasil/libs/Colors'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import { Activity } from '../ygdrasil/types/types'
import { ActivityBoardKanbanCardActivityDone } from './ActivityBoardKanbanCardActivityDone'
import { ActivityBoardKanbanCardActivityUrlIcon } from './ActivityBoardKanbanCardActivityUrlIcon'
import { ActivityBoardKanbanCardDueDate } from './ActivityBoardKanbanCardDueDate'
import Box from './Box'
import { OnCreateEditActivity } from './EditActivityDialogForm'
import FigmaTextWithSearchResult from './FigmaTextWithSearchResult'
import { MoreActionVert } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import StrategyViewObjectiveListKeyResultActivityListItemMenu from './StrategyViewObjectiveListKeyResultActivityListItemMenu'
import { StrategyViewObjectiveListRowLead } from './StrategyViewObjectiveListRowLead'
import WithTooltipMaxLength from './WithTooltipMaxLength'

export type ActivityBoardKanbanCardProps = {
  item: Item
  index: number
  onCreateEditActivity?: OnCreateEditActivity
  onDeleteActivity?: OnCreateEditActivity
  enableEditing?: boolean
  DoneComponent?: React.FC<ActivityBoardKanbanCardProps>
}

export function ActivityBoardKanbanCardActivity(props: ActivityBoardKanbanCardProps) {
  const { item, DoneComponent = ActivityBoardKanbanCardActivityDone } = props
  const activity = item.data.data as Activity

  if (activity.status === ActivityStatus.DONE) return <DoneComponent {...props} />
  return <ActivityBoardKanbanCardActivityView {...props} />
}

export function ActivityBoardKanbanCardActivityView(props: ActivityBoardKanbanCardProps) {
  const { item, onCreateEditActivity, onDeleteActivity, enableEditing = true } = props
  const activity = item.data.data as Activity
  const hasUrl = !!activity.url || !!activity.jiraIssueKey

  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const { searchText } = state.searchState
  return (
    <Container
      isOverDue={isOverDue(activity)}
      flagged={activity.flagged}
      fullWidth
      fullPadding
      fullHeight
      spacing={smallSpacing}
      justify="space-between"
    >
      <Box link={hasUrl} onClick={() => onClickActivityName(activity, org)} fullWidth direction="row">
        <WithTooltipMaxLength
          title={activity.name}
          alwaysVisible={hasUrl}
          titleElement={
            <Box direction="row" align="center">
              <Box right spacing={tinySpacing}>
                <ActivityBoardKanbanCardActivityUrlIcon activity={activity} />
              </Box>
              <FigmaText textKey={Texts.allTooltipText} text={activity.name} />
            </Box>
          }
          maxLength={ACTIVITY_CARD_TEXT_MAX_LENGTH}
        >
          <FigmaTextWithSearchResult
            textKey={Texts.cssActivityCardSummaryText}
            text={getTextWithDots(activity.name, 45)}
            searchText={searchText}
          />
        </WithTooltipMaxLength>
      </Box>
      <Box direction="row" justify="space-between" align="center" fullWidth top spacing={smallSpacing}>
        <StrategyViewObjectiveListRowLead
          item={item}
          onSelectLeadUserId={(i) => onCreateEditActivity?.(i.data.data as Activity)}
        />
        <ActivityBoardKanbanCardDueDate activity={activity} onEditActivity={onCreateEditActivity} />
        <Box left spacing={tinySpacing}>
          <MoreActionWithMenu
            visible={enableEditing}
            Menu={(props) => (
              <StrategyViewObjectiveListKeyResultActivityListItemMenu
                {...props}
                activity={activity}
                onCreateEditActivity={onCreateEditActivity}
                onDeleteActivity={onDeleteActivity}
              />
            )}
            MoreAction={MoreActionVert}
            isAlwaysVisible={enableEditing}
          />
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isDragging?: boolean; isOverDue?: boolean; flagged?: boolean }>`
  border-radius: ${minBorderRadius};
  background-color: ${({ flagged }) => (flagged ? Colors.error90 : Colors.whiteBg)};
  border: 1px solid ${Colors.neutral97};
  ${({ flagged }) => (flagged ? `border-left: 2px solid ${Colors.error80}` : '')};
  ${({ isOverDue }) =>
    isOverDue ? `box-shadow: 0px 0px 1px ${Colors.bad}` : `box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);`};
  overflow-wrap: anywhere;
`
