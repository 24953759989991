import dayjs from 'dayjs'
import { State } from '../../hooks/useAppState'
import EventCategories from '../enums/EventCategories'
import { UseLogEventFunction } from '../hooks/useLogEvent'
import {
  Activity,
  CreateGrowthIndicatorRequest,
  CreateObjectiveRequest,
  CreateTeamRequest,
  GrowthIndicator,
  InvitationRequest,
  KeyResult,
  MyFocus,
  Objective,
  Organization,
  Team,
  User,
  Value
} from '../types/types'
import * as CloudFunctionsApiHandler from './CloudFunctionsApiHandler'
import * as DBApiHandler from './DBApiHandler'
import type { EventEmitter } from './EventEmitter'
import eventEmitter, { Events } from './EventEmitter'
import { EventParams, OurEvent, UserAddIndicatorValueParams, UserCelebratedEventParams } from './EventHelper'
import { getValue } from './KeyResultHelper'
import { addValueToValues } from './ObjectiveHelper'
import { getEventCategoryForUpdateValueEvent, getNewLikes } from './ProcessHandlerHelper'
import { captureException } from './SentryHelperDeprecated'
import Texts from './Texts'

export const createObjective = (
  objective: CreateObjectiveRequest,
  state: State,
  logEvent: UseLogEventFunction
): Promise<Objective> =>
  DBApiHandler.createObjective(objective, state).then((objective) => {
    logEvent(EventCategories.UserCreateObjective, { objective })
    return objective
  })

export const updateObjective = (objective: Objective, logEvent: UseLogEventFunction): Promise<Objective> => {
  logEvent(EventCategories.UserEditObjective, { objective })
  return DBApiHandler.updateObjective(objective)
}

export const removeObjective = (objective: Objective, logEvent: UseLogEventFunction): Promise<void> => {
  logEvent(EventCategories.UserDeleteObjective, { objective })
  return DBApiHandler.removeObjective(objective)
}

export const createTeam = (team: CreateTeamRequest, state: State, logEvent: UseLogEventFunction): Promise<Team> =>
  DBApiHandler.createTeam(team, state).then((team) => {
    logEvent(EventCategories.UserCreatesTeam, { team })
    return team
  })
export const updateTeam = (team: Team, logEvent: UseLogEventFunction): Promise<Team> => {
  logEvent(EventCategories.UserUpdatesTeam, { team })
  return DBApiHandler.updateTeam(team)
}

export const removeTeam = (team: Team, logEvent: UseLogEventFunction): Promise<void> => {
  logEvent(EventCategories.UserDeletesTeam, { team })
  return DBApiHandler.removeTeam(team)
}

export const createGrowthIndicator = (
  growthIndicator: CreateGrowthIndicatorRequest,
  state: State,
  logEvent: UseLogEventFunction
): Promise<GrowthIndicator> =>
  DBApiHandler.createGrowthIndicator(growthIndicator, state).then((growthIndicator) => {
    logEvent(EventCategories.UserCreatesGrowthIndicator, { growthIndicator })
    return growthIndicator
  })

export const archiveGrowthIndicator = (
  growthIndicator: GrowthIndicator,
  logEvent: UseLogEventFunction
): Promise<GrowthIndicator> => {
  logEvent(EventCategories.UserArchivesGrowthIndicator, { growthIndicator })
  return DBApiHandler.updateGrowthIndicator({ ...growthIndicator, isArchived: true })
}

export const updateGrowthIndicator = (
  growthIndicator: GrowthIndicator,
  logEvent: UseLogEventFunction
): Promise<GrowthIndicator> => {
  logEvent(EventCategories.UserUpdatesGrowthIndicator, { growthIndicator })
  return DBApiHandler.updateGrowthIndicator(growthIndicator)
}

export const createIndicator = (
  indicator: KeyResult,
  state: State,
  logEvent: UseLogEventFunction
): Promise<KeyResult> =>
  DBApiHandler.createIndicator(indicator, state).then((indicator) => {
    logEvent(EventCategories.UserCreateIndicator, { indicator })
    return indicator
  })

export const updateIndicator = (indicator: KeyResult, logEvent: UseLogEventFunction): Promise<KeyResult> => {
  logEvent(EventCategories.UserEditIndicator, { indicator })
  return DBApiHandler.updateIndicator(indicator)
}

export const removeIndicator = (indicator: KeyResult, logEvent: UseLogEventFunction): Promise<void> => {
  logEvent(EventCategories.UserDeleteIndicator, { indicator })
  return DBApiHandler.removeIndicator(indicator)
}

export const createActivity = (activity: Activity, state: State, logEvent: UseLogEventFunction): Promise<Activity> =>
  DBApiHandler.createActivity(activity, state).then((activity) => {
    logEvent(EventCategories.UserCreateActivity, { activity })
    return activity
  })

export const updateActivity = (activity: Activity, logEvent: UseLogEventFunction): Promise<Activity> => {
  logEvent(EventCategories.UserEditActivity, { activity })
  return DBApiHandler.updateActivity(activity)
}

export const removeActivity = (activity: Activity, logEvent: UseLogEventFunction) => {
  logEvent(EventCategories.UserDeleteActivity, { activity })
  return DBApiHandler.removeActivity(activity)
}

export const reorderObjectives = (objectives: Objective[], logEvent: UseLogEventFunction): Promise<Objective[]> => {
  logEvent(EventCategories.UserChangesOrderObjective, {})
  return Promise.all(objectives.map(DBApiHandler.updateObjective))
}

export const reorderIndicators = (indicators: KeyResult[], logEvent: UseLogEventFunction): Promise<KeyResult[]> => {
  logEvent(EventCategories.UserChangesOrderIndicator, {})
  return Promise.all(indicators.map(DBApiHandler.updateIndicator))
}

export const reorderActivities = (activity: Activity, logEvent: UseLogEventFunction): Promise<Activity> => {
  logEvent(EventCategories.UserChangesOrderActivity, {})
  return DBApiHandler.updateActivity(activity)
}

type UpdateValueInIndicatorFunctions = {
  logEvent: UseLogEventFunction
  fireConfetti?: () => unknown
}

export function updateValueInIndicator({
  _value,
  indicator,
  objective,
  functions,
  organization
}: {
  _value: Value
  indicator: KeyResult
  objective: Objective
  organization: Organization
  functions: UpdateValueInIndicatorFunctions
}): KeyResult {
  const { logEvent, fireConfetti } = functions
  const { date, value } = _value
  const prevValue = getValue(indicator)

  indicator = {
    ...indicator,
    values: addValueToValues({ value: parseFloat(value as any), date }, indicator.values)
  }

  DBApiHandler.updateIndicator(indicator).catch((e) => captureException(e))

  if (value !== prevValue && fireConfetti) fireConfetti()

  const eventCategory = getEventCategoryForUpdateValueEvent(indicator, date)
  const params: UserAddIndicatorValueParams = { indicator, prevValue, objective }

  logEvent(eventCategory, params, {
    onEventLogged: (event) => eventEmitter.emit(Events.NEW_ACTIVE_USER, event)
  })

  DBApiHandler.updateOrganization({ ...organization, lastUserActiveAt: dayjs().format() })

  return indicator
}

export const completeActivity = (activity: Activity, logEvent: UseLogEventFunction): Promise<Activity> => {
  logEvent(EventCategories.UserCompletesActivity, { activity })
  return DBApiHandler.updateActivity(activity)
}

export const inviteFromApp = (req: InvitationRequest, state: State, logEvent: UseLogEventFunction) => {
  logEvent(EventCategories.UserInvitesColleagueFromApp, {})
  return CloudFunctionsApiHandler.invite(req, state)
}

export const inviteFromAdmin = (req: InvitationRequest, state: State, logEvent: UseLogEventFunction) => {
  logEvent(EventCategories.UserInvitesColleagueFromAdmin, {})
  return CloudFunctionsApiHandler.invite(req, state)
}

export const updateTerminology = (org: Organization, logEvent: UseLogEventFunction) => {
  logEvent(EventCategories.UserUpdatesTerminology, {})
  return DBApiHandler.updateOrganization(org)
}

export const deactivateUser = (user: User, logEvent: UseLogEventFunction) => {
  logEvent(EventCategories.UserDeactivatesUser, {})
  return DBApiHandler.updateUser(user)
}

export const uploadProfilePhoto = (user: User, logEvent: UseLogEventFunction) => {
  logEvent(EventCategories.UserUploadsProfilePhoto, {})
  return DBApiHandler.updateUser(user)
}

export const celebrateEvent = (args: {
  event: OurEvent<Partial<EventParams>>
  indicator: KeyResult
  objective: Objective
  user: User
  logEvent: UseLogEventFunction
  eventEmitter?: EventEmitter
}) => {
  const { event, indicator, objective, user, logEvent, eventEmitter: _evenEmitter = eventEmitter } = args
  const params: UserCelebratedEventParams = {
    indicator,
    objective,
    eventId: event._id,
    celebratedBy: user.name,
    eventCreatedBy: event.createdBy
  }
  const likes = getNewLikes(event.likes, user)

  if (likes[user._id]) {
    logEvent(EventCategories.UserCelebratedEvent, params) // Only log if the user has not liked the event before
    eventEmitter.emit(Events.NEW_NOTIFICATION, { textKey: Texts.applicationNotificationCelebratedUpdate })
  }

  return DBApiHandler.updateEvent({ ...event, likes: likes })
}

export const prioritizeActivity = (activity: Activity, logEvent: UseLogEventFunction) => {
  return DBApiHandler.updateActivity({ ...activity, isPrioritized: !activity.isPrioritized }).then((activity) => {
    logEvent(EventCategories.UserPrioritizeActivity, { activity })
    return activity
  })
}

export const updateWeeklyFocus = ({ myFocus, logEvent }: { myFocus: MyFocus; logEvent: UseLogEventFunction }) => {
  logEvent(EventCategories.UserUpdatesWeeklyFocus)
  return DBApiHandler.updateMyFocus(myFocus)
}

export const updateWeeklyFocusRetrospecive = ({
  myFocus,
  logEvent
}: {
  myFocus: MyFocus
  logEvent: UseLogEventFunction
}) => {
  logEvent(EventCategories.UserUpdatesWeeklyRetrospective)
  return DBApiHandler.updateMyFocus(myFocus)
}
