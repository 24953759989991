import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopImageType, AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export default function ContinueWithButton({
  onLogin,
  textKey,
  imageKey,
  colorVariant = 'transparent',
  text
}: {
  onLogin?: () => void
  textKey: AntiloopTextType
  imageKey: AntiloopImageType
  colorVariant?: 'white' | 'transparent'
  text?: string
}) {
  return (
    <ContinueWithButtonContainerNew
      direction="row"
      align="center"
      justify="center"
      fullWidth
      fullPadding
      spacing={smallSpacing}
      onClick={onLogin}
      colorVariant={colorVariant}
    >
      <FigmaImageContainer imageKey={imageKey} />
      <Box left spacing={smallSpacing}>
        <FigmaText styleTextKey={Texts.loginContinueWithGoogleNew} textKey={textKey} text={text} />
      </Box>
    </ContinueWithButtonContainerNew>
  )
}

const ContinueWithButtonContainerNew = styled(Box)<{ colorVariant: 'white' | 'transparent' }>`
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${(props) => (props.colorVariant === 'white' ? Colors.white : Colors.gray6)};
  border-radius: ${minBorderRadius};
`
