import { isNumber, toPx } from '../ygdrasil/libs/Common'
import { minSpacing, SMALL_SPACING_PX } from '../enums/Spacings'
import { getValueTextGrowthView } from '../ygdrasil/libs/GraphHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { NoWrapFigmaText } from './StyledComponents'
import { GrowthIndicator, GrowthIndicatorValue, GrowthIndicatorValues } from '../ygdrasil/types/types'
import Colors from '../ygdrasil/libs/Colors'
import styled from '@emotion/styled'
import {
  TrendingDown as TrendingDownIcon,
  TrendingFlat as TrendingFlatIcon,
  TrendingUp as TrendingUpIcon
} from './Icons'
import { MUI_LINE_HEIGHT } from '../libs/HardCodedSizes'
import { isGreen } from '../libs/GrowthViewGrowthIndicatorValueActualHelper'
import { getPreviousValue } from '../libs/GrowthViewGrowthIndicatorValueGoalHelper'

type Props = {
  value: GrowthIndicatorValue
  values: GrowthIndicatorValues
  growthIndicator: GrowthIndicator
}

export const GrowthViewGrowthIndicatorValueActual = ({ value, growthIndicator, values }: Props) => {
  const { direction } = growthIndicator

  const previousGoalValue = getPreviousValue({ value, values })

  if (!isNumber(value.actual) && !!previousGoalValue && isNumber(previousGoalValue) && previousGoalValue === value.goal)
    return <TrendingFlat />

  if (
    !isNumber(value.actual) &&
    !!previousGoalValue &&
    !!value?.goal &&
    isNumber(previousGoalValue) &&
    previousGoalValue > value.goal
  )
    return <TrendinDown />

  if (
    !isNumber(value.actual) &&
    !!previousGoalValue &&
    !!value?.goal &&
    isNumber(previousGoalValue) &&
    previousGoalValue < value.goal
  )
    return <TrendinUp />

  if (typeof value.actual !== 'number') return <InvisibleArrow />

  const text = getValueTextGrowthView(value.actual)

  return (
    <Box direction="row" align="center">
      <NoWrapFigmaText textKey={Texts.cssSuccessEditGoalText} text={text} />
      <Box left spacing={minSpacing}>
        {!!value.goal && (isGreen(value, direction) ? <GreenDot /> : <RedDot />)}
      </Box>
    </Box>
  )
}

const DOT_SIZE = SMALL_SPACING_PX * 0.7

const Dot = styled(Box)`
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  border-radius: ${DOT_SIZE / 2}px;
`

const RedDot = styled(Dot)`
  background-color: ${Colors.bad};
`

const GreenDot = styled(Dot)`
  background-color: ${Colors.good};
`

const ArrowBox = styled(Box)`
  height: ${toPx(Texts.cssSuccessEditActualsText.style.fontSize) * MUI_LINE_HEIGHT}px;
`

const TrendinDown = () => (
  <ArrowBox fullWidth align="center" justify="center">
    <TrendingDownIcon />
  </ArrowBox>
)
const TrendinUp = () => (
  <ArrowBox fullWidth align="center" justify="center">
    <TrendingUpIcon />
  </ArrowBox>
)

const TrendingFlat = () => (
  <ArrowBox fullWidth align="center" justify="center">
    <TrendingFlatIcon color={Colors.white} />
  </ArrowBox>
)

const InvisibleArrow = () => (
  <ArrowBox fullWidth align="center" justify="center" style={{ visibility: 'hidden' }}>
    <TrendingFlatIcon />
  </ArrowBox>
)
