import { mapTreeItem, TreeData } from './StrategyViewTreeSearchHelper'
import { State } from '../../hooks/useAppState'
import { inRankOrder } from './RankHelper'
import { Item } from './StrategyViewObjectiveListHelper'
import { DashboardLink } from '../types/types'

export function getObjectivesForDashboard(tree: TreeData, state: State): Item[] {
  return Object.keys(tree.items)
    .filter((id) => tree.items[id]?.data.searchType === 'objective')
    .map((key) => mapTreeItem(tree.items[key]?.data, state))
    .sort(inRankOrder)
    .sort((a, b) => a.data.level - b.data.level)
}

export function getAllIndicatorsForDashboard(
  tree,
  state: State,
  selectedDahboardLink?: DashboardLink,
  excludedIndicatorIds: string[] = []
): Item[] {
  return getObjectivesForDashboard(tree, state)
    .filter((item) => !!tree.items[item.id])
    .map((item) => getIndicatorsForDashboard(item, selectedDahboardLink, excludedIndicatorIds))
    .reduce((acc, items: Item[]) => [...acc, ...items], [] as Item[])
}

export function getIndicatorsForDashboard(
  item: Item,
  selectedDahboardLink?: DashboardLink,
  excludedIndicatorIds: string[] = []
): Item[] {
  const includedIndicatorIds = selectedDahboardLink?.includedIndicatorIds

  if (!item.children) return []

  return item.children
    .filter(({ data }) => data.searchType === 'keyResult')
    .filter((kr) => !includedIndicatorIds || includedIndicatorIds.includes(kr.id))
    .filter((kr) => !excludedIndicatorIds.includes(kr.id))
    .sort(inRankOrder)
}

export function addStar(text: string, shoudAddStar: boolean) {
  return `${text}${shoudAddStar ? '*' : ''}`
}

export function getUrlForDashboardLink(dashboardLink: Partial<DashboardLink>, state: State, _window = window) {
  const showDashboardCelebrations = !!state?.showDashboardCelebrations ? 'true' : 'false'
  const baseurl = `${_window.location.origin}/?showDashboard=true&showDashboardCelebrations=${showDashboardCelebrations}`
  if (!dashboardLink?._id) return baseurl
  return `${baseurl}&selectedDashboardLinkId=${dashboardLink?._id}`
}

export function changeAddressInAddressbarWithoutReloading(url: string) {
  window.history.pushState('', '', url)
}
