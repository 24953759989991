import React from 'react'
import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import FigmaText, { FigmaTextProps } from '../ygdrasil/components/FigmaText'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import { getSplits } from '../libs/FigmaTextWithSearchResultHelper'
import Box from './Box'

export type FigmaTextWithSearchResultProps = {
  searchText?: string
  Component?: React.FC<FigmaTextWithSearchResultProps>
} & FigmaTextProps

const FigmaTextWithSearchResult: React.FC<FigmaTextWithSearchResultProps> = ({
  text: _text,
  searchText,
  Component = FigmaText,
  ...figmaTextProps
}) => {
  const text = typeof _text === 'string' ? _text : getFigmaText(figmaTextProps.textKey)
  if (!searchText) return <Component {...figmaTextProps} text={text} />
  if (!text.includes(searchText)) return <Component {...figmaTextProps} text={text} />
  const splits = getSplits(text, searchText)
  const elements = splits.map(({ text, isYellow }, key) => {
    text = text.replaceAll(' ', '&nbsp;')
    if (isYellow) return <StyledFigmaText key={key} {...figmaTextProps} text={text} />
    return <Component key={key} {...figmaTextProps} text={text} />
  }, [] as JSX.Element[])

  return <Container direction="row">{elements}</Container>
}

const Container = styled(Box)`
  display: inline-block;
`

const StyledFigmaText = styled(FigmaText)`
  background-color: ${Colors.highlight};
`

export default FigmaTextWithSearchResult
