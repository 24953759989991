import React, { useEffect } from 'react'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import { Objective } from '../ygdrasil/types/types'
import { OurDrawer } from './Drawers'
import EditObjectiveDialog from './EditObjectiveDialog'

export type OpenEditObjectiveDialogEventParams = {
  objective?: Partial<Objective>
}

export const openEditObjectiveDrawer = (data: OpenEditObjectiveDialogEventParams) =>
  eventEmitter.emit(Events.OPEN_EDIT_OBJETIVE_DIALOG, data)

export default function DrawerGlobalEditObjectiveDialog() {
  const [visible, setVisible] = React.useState(false)
  const [objective, setObjective] = React.useState<Partial<Objective>>()

  const onCloseEditObjectiveDrawer = () => {
    setObjective(undefined)
    setVisible(false)
  }

  useEffect(() => {
    eventEmitter.on(Events.OPEN_EDIT_OBJETIVE_DIALOG, ({ objective }: OpenEditObjectiveDialogEventParams) => {
      setObjective(objective)
      setVisible(true)
    })

    return () => {
      eventEmitter.removeAllListeners(Events.OPEN_EDIT_OBJETIVE_DIALOG)
    }
  }, [])

  return (
    <OurDrawer
      open={visible}
      content={<EditObjectiveDialog onClose={onCloseEditObjectiveDrawer} objective={objective} />}
      onClose={onCloseEditObjectiveDrawer}
    />
  )
}
