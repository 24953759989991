enum Collections {
  USERS = 'users',
  OBJECTIVES = 'objectives',
  INDICATORS = 'indicators',
  ACTIVITIES = 'activities',
  ORGANIZATIONS = 'organizations',
  TEAM = 'team',
  MAIL = 'mail',
  INVITATIONS = 'invitations',
  GROWTH_INDICATORS = 'growth_indicators',
  CUSTOM_AUTH_TOKENS = 'custom_auth_tokens',
  LOGIN_WITH_CODE_TOKEN = 'login_with_code_token',
  EVENTS = 'events',
  DATA_SOURCES = 'data_sources',
  CONNECTIONS = 'connections',
  DASHBOARD_LINKS = 'dashboard_links',
  CONVERSATIONS = 'conversations',
  MY_FOCUS = 'my_focus',
  TODOS = 'todos'
}

export default Collections
