/* eslint-disable react/jsx-pascal-case */
import MaterialMenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import React, { ReactElement } from 'react'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { smallSpacing } from '../enums/Spacings'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Box from './Box'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'

type Props = MenuItemProps & {
  button?: boolean
  textKey?: AntiloopTextType
  text?: string
  leftElement?: ReactElement
  rightElement?: ReactElement
  topElement?: ReactElement
  className?: string
}

export const MenuItem: React.VFC<Props> = (props: Props) => {
  const {
    textKey = Texts.userMenuSettingsButtonText,
    leftElement,
    text,
    button,
    topElement,
    rightElement,
    className,
    children,
    ...menuProps
  } = props
  return (
    <MaterialMenuItem {...menuProps}>
      <Box>
        <Box align="center" direction="row">
          <Box right spacing={!!leftElement ? smallSpacing : '0'}>
            {leftElement}
          </Box>
          <Box className={className}>
            {topElement}
            {children || <FigmaText textKey={textKey} text={text} />}
          </Box>
          <Box>{rightElement}</Box>
        </Box>
      </Box>
    </MaterialMenuItem>
  )
}
