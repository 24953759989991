import React from 'react'
import { useHistory } from 'react-router-dom'
import { shouldOnboard } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import { getStateFromDeepLink } from '../libs/DeepLinkHelper'
import { getStateForStrategicView } from '../libs/MenuHelper'
import { SignInPageProps } from '../libs/SignInHelper'
import EventCategories from '../ygdrasil/enums/EventCategories'
import EventTypes from '../ygdrasil/enums/EventTypes'
import { _logEvent } from '../ygdrasil/hooks/useLogEvent'
import { getEmailForFirebaseUser } from '../ygdrasil/libs/Common'
import config from '../Config'
import { getUser } from '../ygdrasil/libs/DBApiHandler'
import RoutePath from '../ygdrasil/libs/RoutePath'
import { captureException, setSentryUser } from '../ygdrasil/libs/SentryHelperDeprecated'
import * as SlackHelper from '../ygdrasil/libs/SlackHelper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { getSelectedOrganizationId, onLoggedIn, shouldShowDisabledView } from '../ygdrasil/libs/useLoginHelper'
import { FirebaseUser, Organization } from '../ygdrasil/types/types'
import useAppState, { DEFAULT_STATE } from './useAppState'
import { useLogout } from './useLogout'
import useNotification from './useNotification'
import useQueryParams, { addQueryParamsToUrl, SignInPageQueryParams } from '../ygdrasil/hooks/useQueryParams'
import { onAuthStateChanged, signOut } from '@firebase/auth'
import { auth } from '../libs/Firebase'
import { changeAddressInAddressbarWithoutReloading } from '../ygdrasil/libs/DashboardHelper'

type Props = SignInPageProps & {
  onFinally: () => unknown
}

export default function useLogin(props: Props) {
  const { onFinally, enableAppleAuthProvider = false } = props
  const enableOnboarding = props.enableOnboarding !== undefined ? props.enableOnboarding : true
  const { state, setState } = useAppState()
  const history = useHistory()
  const notification = useNotification()
  const [loading, setLoading] = React.useState(true)
  const queryParams = useQueryParams<SignInPageQueryParams>()
  const { invitationToken: _invitationToken } = queryParams
  const onUserIsDisabled = useLogout(() => history.push(RoutePath.USER_IS_DISABLED))
  const onOnboardingNotAvailable = useLogout(() =>
    // TODO WRITE TEST Should not show back button in saga
    history.push(enableAppleAuthProvider ? RoutePath.ONBOARDING_NOT_AVAILABLE_SAGA : RoutePath.ONBOARDING_NOT_AVAILABLE)
  )
  const logout = useLogout()

  const onLogin = (firebaseUser: FirebaseUser) =>
    getUser(firebaseUser.uid).then((user) => {
      const email = getEmailForFirebaseUser(firebaseUser)
      setSentryUser({ email, id: firebaseUser.uid })
      setState({ ...state, firebaseUser })

      if (!email) return Promise.reject(new Error(`Cant find email on firebaseUser: ${firebaseUser.uid}`))

      //TODO WRITE TEST, should redirect to onboarding not available screen if onboarding is not available
      if (!user && !enableOnboarding) return onOnboardingNotAvailable()

      if (props.onLogin) return props.onLogin({ firebaseUser, user }) // used in saga

      return Promise.resolve()
        .then(() => {
          if (!user)
            return Promise.resolve()
              .then(() => shouldOnboard({ email, uid: firebaseUser.uid }, state))
              .then((res) => {
                if (res) return history.push(addQueryParamsToUrl(RoutePath.ONBOARDING, queryParams))
                const errorMessage = getFigmaText(Texts.onboardingEmailErrorNotAllowedOrg)
                captureException(new Error(`${email} - ${errorMessage}`))
                notification.error(undefined, errorMessage)
                return signOut(auth)
              })
              .finally(() => setLoading(false))

          if (shouldShowDisabledView(user)) {
            return onUserIsDisabled()
          }

          return Promise.resolve()
            .then(() => getSelectedOrganizationId(user, state))
            .then((selectedOrganizationId) => {
              let _state = getStateForStrategicView(state)
              const stateFromDeepLink = getStateFromDeepLink(window.location)

              _state = {
                ..._state,
                selectedOrganizationId,
                ...stateFromDeepLink,
                searchState: { ...state.searchState, ...stateFromDeepLink.searchState },
                user
              }

              setState(_state)
              // This is an exception
              _logEvent(
                EventCategories.UserLogIn,
                {
                  eventType: EventTypes.Web,
                  user,
                  org: { _id: selectedOrganizationId } as Organization
                },
                _state
              )

              changeAddressInAddressbarWithoutReloading(RoutePath.ROOT)
            })
        })
        .then(() => onLoggedIn({ user, email }, config, { logLogin: SlackHelper.logLogin }))
        .catch((e) => {
          logout()
          return Promise.reject(e)
        })
    })

  React.useEffect(() => {
    onAuthStateChanged(
      auth,
      (user) =>
        Promise.resolve()
          .then(() => {
            if (!user && state !== DEFAULT_STATE) return logout()
            if (!user) return
            return Promise.resolve(setLoading(true))
              .then(() => onLogin(user))
              .finally(() => onFinally())
          })
          .catch(captureException)
          .finally(() => setLoading(false)),
      captureException
    )
  }, [])

  return loading
}
