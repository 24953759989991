import useAppState, { State } from '../../hooks/useAppState'
import _config, { adminUser, ourBigPictureUser } from '../../Config'
import { OUR_BIG_PICTURE_DOMAIN } from '../libs/Consts'
import { useUser } from '../libs/QueryHooks'
import { Config, Organization, OrgUserRole, User } from '../types/types'

export default function useLoggedInConfig(): Config {
  const { state } = useAppState()
  const { data: user } = useUser(state.user._id)
  const config = _config
  return getLoggedInConfig(config, state, user ?? state.user)
}

export function getLoggedInConfig(config: Config, state: State, user?: User): Config {
  if (isOurBigPictureUser(user)) config = { ...config, ...ourBigPictureUser }
  if (!isLimitedUser(state, user)) config = { ...config, ...adminUser }
  return config
}

export function isLimitedUser(state: State, user?: User) {
  if (!user) return true
  if (user?.orgUserInfo?.[state.selectedOrganizationId]?.role === OrgUserRole.LIMITED) return true
  return false
}

export function isOurBigPictureUser(user?: User) {
  return user?.email?.endsWith(OUR_BIG_PICTURE_DOMAIN) || false
}

export function isOurBigPictureOrg(org?: Organization) {
  return org?.domains?.some((domeain) => domeain.endsWith(OUR_BIG_PICTURE_DOMAIN)) || false
}
