import styled from '@emotion/styled'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ONE_REM_IN_PX } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import { BusinessPlan } from '../ygdrasil/types/types'
import Box from './Box'
import { DEGREE_PER_CHAR, BUSINESS_PLAN_RING_FONT_SIZE_SCALE_FACTOR } from './BusinessplanRing'
import { getRingWordProps } from './BusinessplanRingInternalHelper'

type RingProps = { businessPlan: BusinessPlan; setInnerFontSize: React.Dispatch<React.SetStateAction<number>> }

export const BusinessplanRingInternal: React.FC<RingProps> = ({ businessPlan, setInnerFontSize }) => {
  const [fontSize, setFontSize] = useState<number>(ONE_REM_IN_PX)
  const refRing = useRef<HTMLDivElement>(null)
  const refCutout = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = () => {
      const ringSize = refRing.current?.clientWidth || 0
      const cutoutSize = refCutout.current?.clientWidth || 0
      const calcFontSize = (ringSize - cutoutSize) / BUSINESS_PLAN_RING_FONT_SIZE_SCALE_FACTOR
      setFontSize(calcFontSize)
      setInnerFontSize(calcFontSize)
    }
    window.addEventListener('resize', handleResize, false)
    handleResize()
    return () => window.removeEventListener('resize', handleResize, false)
  }, [])

  const ringWords = useMemo(() => getRingWordProps(businessPlan), [businessPlan])

  return (
    <Container fullWidth fullHeight position="relative">
      <Box className="ring" ref={refRing} />
      <Box className="cutout" ref={refCutout} />
      {ringWords.map(({ value, fromDegree, direction }) =>
        value.split('').map((char, i) => (
          <span
            style={{
              transform: `rotate(${fromDegree + direction * i * DEGREE_PER_CHAR}deg)`,
              font: `${fontSize / 1.25}px Roboto Mono`,
              top: `${fontSize / 1.25}px`,
              height: `calc(50% - ${fontSize / 1.25}px)`
            }}
            key={i}
            className="stick"
          >
            <div style={direction === -1 ? { transform: 'rotate(180deg)' } : undefined}>{char}</div>
          </span>
        ))
      )}
    </Container>
  )
}

const Container = styled(Box)`
  position: relative;
  aspect-ratio: 1;

  .stick {
    color: white;
    position: absolute;
    width: 0px;
    transform-origin: bottom center;
    right: 50%;
  }

  .ring {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: ${Colors.primary30};
  }

  .cutout {
    width: 80%;
    height: 80%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background-color: white;
    position: absolute;
    border-radius: 50%;
  }
`
