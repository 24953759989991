import Divider from '@mui/material/Divider'
import Menu, { MenuProps } from '@mui/material/Menu'
import invariant from 'invariant'
import React from 'react'
import useAppState from '../hooks/useAppState'
import { withStopProgagation } from '../libs/HtmlHelper'
import { removeDatasource } from '../ygdrasil/libs/DBApiHandler'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Connection, Datasource } from '../ygdrasil/types/types'
import { OurDrawer } from './Drawers'
import EditDatasourceMachine from './EditDatasourceMachine'
import { MenuItem } from './MenuItem'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  dataSource: Datasource
  connection: Connection
  onSyncDataSource: () => unknown
  onDataSourceUpdated: () => unknown
} & Partial<MenuProps>

export default function SettingsIntegrationsDatasourceMenu(props: Props) {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)

  const { dataSource, anchorEl, onClose, connection, onDataSourceUpdated, onSyncDataSource, ...rest } = props
  const [showDrawer, setShowDrawer] = React.useState(false)

  const onClickEditDataSource = withStopProgagation(() => {
    onClose()
    setShowDrawer(true)
  })

  const onClickDeleteDatasource = withStopProgagation(() => {
    onClose()
    invariant(org, '!org')
    removeDatasource(dataSource)
  })

  const onCloseDrawer = withStopProgagation(() => {
    setShowDrawer(false)
  })

  const _onClose = withStopProgagation(() => {
    props.onClose()
  })

  const onClickSync = withStopProgagation(() => {
    props.onClose()
    onSyncDataSource()
  })

  return (
    <div>
      <Menu {...rest} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={_onClose}>
        <MenuItem onClick={onClickSync}>{getFigmaText(Texts.adminIntegrationTableContextMenuSync)}</MenuItem>
        <MenuItem
          onClick={onClickEditDataSource}
          textKey={Texts.adminIntegrationTableContextMenuEditDataSource}
        ></MenuItem>
        <Divider />
        <MenuItem
          onClick={onClickDeleteDatasource}
          textKey={Texts.adminIntegrationTableContextMenuDeleteDataSource}
        ></MenuItem>
      </Menu>
      <OurDrawer
        open={showDrawer}
        content={
          <EditDatasourceMachine
            onClose={onCloseDrawer}
            connection={connection}
            dataSource={dataSource}
            onDataSourceUpdated={onDataSourceUpdated}
            connectionType={connection.connectionType}
          />
        }
        onClose={onCloseDrawer}
      />
    </div>
  )
}
