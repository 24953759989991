import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import shadows from '../enums/Shadows'
import { minSpacing, tinySpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'
import { AddIcon } from './Icons'

export default function AddButtonLight(props: { onClick?: () => void }) {
  const { onClick } = props
  return (
    <Container link onClick={onClick} fullPadding spacing={minSpacing}>
      <Box spacing={tinySpacing} left>
        <Box right spacing={tinySpacing}>
          <AddIcon color={Colors.neutralVariant80} />
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.neutral99};
  border-radius: ${minBorderRadius};
  :hover {
    box-shadow: ${shadows.soft};
  }
`
