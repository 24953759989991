import * as Auth from 'firebase/auth'
import AnalyticsEvent from '../enums/AnalyticsEvent'
import { State } from '../../hooks/useAppState'
import eventEmitter, { Events } from './EventEmitter'
import { auth } from '../../libs/Firebase'
import { OnboardingState } from '../types/Onboarding'
import { getDomain } from './Common'
import config from '../../Config'
import { OUR_BIG_PICTURE_DOMAIN } from './Consts'

export const signInWithPopup = (provider: Auth.AuthProvider) => Auth.signInWithPopup(auth, provider)

export const signInWithRedirect = (provider: Auth.AuthProvider) => Auth.signInWithRedirect(auth, provider)

export const signInWithEmailLink = (email: string, emailLink: string | undefined) =>
  Auth.signInWithEmailLink(auth, email, emailLink)

export const isSignInWithEmailLink = (emailLink: string) => Auth.isSignInWithEmailLink(auth, emailLink)

export const sendSignInLinkToEmail = (email: string, actionCodeSettings: Auth.ActionCodeSettings) =>
  Auth.sendSignInLinkToEmail(auth, email, actionCodeSettings)

export const fetchSignInMethodsForEmail = (email: string) => Auth.fetchSignInMethodsForEmail(auth, email)

export const signInWithCustomToken = (customToken: string) => Auth.signInWithCustomToken(auth, customToken)

export const logOnboardingEvent = (
  eventName: AnalyticsEvent,
  state: OnboardingState,
  eventParams?: { [key: string]: any }
) => _logEvent(eventName, state.firebaseUser.email || '', eventParams)

export const logEvent = (eventName: AnalyticsEvent, state: State, eventParams?: { [key: string]: any }) =>
  _logEvent(eventName, state.user.email || '', eventParams)

export const _logEvent = (eventName: AnalyticsEvent, email: string, eventParams?: { [key: string]: any }) => {
  // TODO READD AGAIN
  // if (config.enableAnalytics) return analytics.logEvent(eventName as string, eventParams)

  if (!config.enableAnalyticsEmitter) return

  if (!!email && getDomain(email) === OUR_BIG_PICTURE_DOMAIN)
    return eventEmitter.emit(
      Events.NEW_ANALYTICS_EVENT,
      `GA eventName: ${eventName} ignoring event because user has ourbigpicture domain in email`
    )

  return eventEmitter.emit(Events.NEW_ANALYTICS_EVENT, `GA eventName: ${eventName}`)
}

// used by mimer
export const DefaultFirebaseAppOptions = {
  storageBucket: 'our-big-picture.appspot.com'
}
