enum EventTypes {
  MobileAndroid = 'MobileAndroid',
  MobileIPhone = 'MobileIPhone',
  QuickAdd = 'QuickAdd',
  RightClick = 'RightClick',
  Menu = 'Menu',
  Full = 'Full',
  Limitited = 'Limitited',
  Standard = 'Standard',
  OKR = 'OKR',
  Custom = 'Custom',
  Web = 'Web'
}

export default EventTypes
