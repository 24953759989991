import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import regression from 'regression'
import { getValuesForGraph, getX } from '../libs/GraphHelper'
import { KeyResult, Objective } from '../types/types'

const REGRESSION_ORDER = 3

export default function useRegressionResult({ keyResult, objective }: { keyResult: KeyResult; objective: Objective }) {
  const [state, setState] = useState<regression.Result>()

  useEffect(() => {
    const values = getValuesForGraph(keyResult, objective)

    const data = values.map(({ date, value }) => [getX(dayjs(date), objective), value] as regression.DataPoint)
    setState(regression.polynomial(data, { order: REGRESSION_ORDER }))
  }, [keyResult, objective])

  return state
}
