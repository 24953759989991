import Switch from '@mui/material/Switch/Switch'
import invariant from 'invariant'
import useAppState from '../hooks/useAppState'
import { updateIndicator, updateObjective, updateUser } from '../ygdrasil/libs/DBApiHandler'
import { useUser } from '../ygdrasil/libs/QueryHooks'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getLeadObjectsForUser, hasEnabledNotifications } from '../ygdrasil/libs/SettingsNotificationsHelper'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult } from '../ygdrasil/types/types'
import Box from './Box'
import { FlagIcon, KeyboardArrowRightIconWithBackground } from './Icons'
import { SettingsNotificationRow } from './SettingsNotificationRow'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { useAllVisibleItems } from '../ygdrasil/hooks/useAllVisibleItems'

export default function SettingsNotifications() {
  const { state } = useAppState()
  const { data: user = state.user } = useUser(state.user._id)
  const allVisibleItems = useAllVisibleItems()
  const { allIndicators, allObjectives } = allVisibleItems
  const { objectives, keyResults } = getLeadObjectsForUser(allVisibleItems, user)

  const onSelectLeadNotificationIntervalForKeyResult = (notificationInterval, keyResult: KeyResult) => {
    const objective = allObjectives.find((o) => keyResult.parentId === o._id)
    invariant(objective, '!objective')
    return updateIndicator({ ...keyResult, leadMetaData: { ...keyResult.leadMetaData, notificationInterval } })
  }

  const onChangeEnableNotifications = (e, enableLeadNotifications) => updateUser({ ...user, enableLeadNotifications })

  const _hasEnabledNotifications = hasEnabledNotifications(user)

  const hasLeadObjectivesOrKeyResults = objectives.length + keyResults.length > 0

  return (
    <Box top fullWidth>
      <Box direction="row" align="center">
        <Box right>
          <FigmaText textKey={Texts.adminNotificationActivateNotificiationsLabel} />
        </Box>
        <Switch color="primary" checked={_hasEnabledNotifications} onChange={onChangeEnableNotifications} />
      </Box>
      <Box top>
        <FigmaText textKey={Texts.adminNotificationNotificationFrequencyLabel} />
      </Box>
      <Box fullWidth top style={!_hasEnabledNotifications ? { opacity: 0.5, pointerEvents: 'none' } : undefined}>
        {!hasLeadObjectivesOrKeyResults && (
          <Box width="50%">
            <Box bottom>
              <FigmaText textKey={Texts.adminNotificationEmptyStateHeader}></FigmaText>
            </Box>
            <FigmaTextForStrategyModel textKey={Texts.adminNotificationEmptyStateText} />
          </Box>
        )}
        {objectives.map((o, key) => (
          <SettingsNotificationRow
            object={o}
            key={key}
            Icon={KeyboardArrowRightIconWithBackground}
            onSelectInterval={(notificationInterval) =>
              updateObjective({ ...o, leadMetaData: { ...(o.leadMetaData as any), notificationInterval } })
            }
          />
        ))}
        {keyResults.map((kr, key) => (
          <SettingsNotificationRow
            object={kr}
            key={key}
            Icon={FlagIcon}
            onSelectInterval={(interval) => onSelectLeadNotificationIntervalForKeyResult(interval, kr)}
          />
        ))}
      </Box>
    </Box>
  )
}
