import React from 'react'
import FigmaText from '../ygdrasil/components/FigmaText'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { bigSpacing } from '../enums/Spacings'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export default function StrategyViewObjectiveListEmptySearchState() {
  return (
    <Box fullWidth align="center" direction="row" justify="center">
      <FigmaImageContainer imageKey={Images.search} />
      <Box spacing={bigSpacing}>
        <FigmaText textKey={Texts.seearchNoResultText} />
      </Box>
    </Box>
  )
}
