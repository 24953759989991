import dayjs from 'dayjs'
import { DEFAULT_STATE, State } from '../hooks/useAppState'
import { removeDuplicates } from '../ygdrasil/libs/Common'
import { TreeData } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { User } from '../ygdrasil/types/types'

const MAX_USERS = 10

export function getUsersForFilterButtons(users: { [id: string]: User }, tree: TreeData) {
  return removeDuplicates(
    Object.keys(tree.items)
      .filter((id) => !!tree.items[id]?.data?.data?.leadUserId)
      .map((id) => tree.items[id]?.data?.data?.leadUserId)
      .map((userId) => users[userId as string])
      .filter((user) => !!user),
    '_id'
  )
    .sort((a, b) => dayjs(a.lastActiveAt).valueOf() - dayjs(b.lastActiveAt).valueOf())
    .filter((x, i) => i < MAX_USERS)
    .sort((a, b) => (b.profileImage || '')?.localeCompare(a.profileImage || ''))
}

// TODO WRITE TEST, should return searchState with only user filter related fields
export function getSearchStateForUserFilterButtons({ searchState }: State) {
  const { selectedObjectiveId, strategicPillar, searchDates } = searchState
  return {
    ...DEFAULT_STATE.searchState,
    selectedObjectiveId,
    strategicPillar,
    searchDates
  }
}
