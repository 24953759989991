import { Sd } from '@mui/icons-material'
import Divider from '@mui/material/Divider'
import Menu, { MenuProps } from '@mui/material/Menu'
import { MenuItem } from './MenuItem'
import invariant from 'invariant'
import React from 'react'
import useAppState from '../hooks/useAppState'
import { withStopProgagation } from '../libs/HtmlHelper'
import { removeConnection, removeDatasource } from '../ygdrasil/libs/DBApiHandler'
import { useDatasources, useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Connection } from '../ygdrasil/types/types'
import { OurDrawer } from './Drawers'
import EditDatasourceMachine from './EditDatasourceMachine'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  connection: Connection
  onDataSourceUpdated: () => unknown
} & Partial<MenuProps>

export default function SettingsIntegrationsConnectionMenu(props: Props) {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)

  const { connection, anchorEl, onClose, onDataSourceUpdated, ...rest } = props
  const [showDrawer, setShowDrawer] = React.useState(false)
  const { data: dataSources = [] } = useDatasources(state)

  const onClickAddNewDataSource = withStopProgagation(() => {
    onClose()
    setShowDrawer(true)
  })

  const onClickDeleteConnection = withStopProgagation(() => {
    onClose()
    invariant(org, '!org')
    Promise.all(dataSources.filter((ds) => ds.connectionId === connection._id).map((ds) => removeDatasource(ds))).then(
      () => removeConnection(connection)
    )
  })

  const onCloseDrawer = withStopProgagation(() => {
    setShowDrawer(false)
  })

  const _onClose = withStopProgagation(() => {
    props.onClose()
  })

  return (
    <div>
      <Menu {...rest} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={_onClose}>
        <MenuItem
          onClick={onClickAddNewDataSource}
          textKey={Texts.adminIntegrationTableContextMenuAddNewDatasource}
        ></MenuItem>
        <Divider />
        <MenuItem
          onClick={onClickDeleteConnection}
          textKey={Texts.adminIntegrationTableContextMenuDeleteConnection}
        ></MenuItem>
      </Menu>
      <OurDrawer
        open={showDrawer}
        content={
          <EditDatasourceMachine
            onClose={(e) => onCloseDrawer(e)}
            connection={connection}
            onDataSourceUpdated={() => onDataSourceUpdated()}
            connectionType={connection.connectionType}
          />
        }
        onClose={onCloseDrawer}
      />
    </div>
  )
}
