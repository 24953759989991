import styled from '@emotion/styled'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { defaultSpacing, littleSpacing, regularSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useResponsive from '../hooks/useResponsive'
import { ONBOARDING_BUTTON_HEIGHT } from '../libs/HardCodedSizes'
import { EmailValidation } from '../libs/ValidationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { ENTER_KEYCODE } from '../ygdrasil/libs/Consts'
import Images from '../ygdrasil/libs/Images'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { SignInSteps, SignInViewProps } from '../ygdrasil/types/SignInTypes'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import { SignInSignupAgreeCheckbox } from './SignInSignupAgreeCheckbox'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'

const ValidationSchema = Yup.object().shape({
  email: EmailValidation('email', getFigmaText(Texts.onboardingEmailErrorNotValidEmail)),
  agreed: Yup.bool().oneOf([true], 'Field must be checked')
})

export default function SignInSignUpWithEmailForm(props: SignInViewProps) {
  const { signInState, isLoading, onTakeMeBack } = props
  const { step } = signInState
  const isSignUp = step === SignInSteps.SIGN_UP
  const isSignIn = step === SignInSteps.LANDING
  const { email } = signInState.data
  const initialValues = { email, agreed: true }
  const { isMobile } = useResponsive()

  const onSubmit = ({ email }) => {
    props.onPressContinue({ ...props.signInState, data: { ...signInState.data, email } })
  }

  return (
    <Formik validationSchema={ValidationSchema} onSubmit={onSubmit} initialValues={initialValues}>
      {(formProps) => (
        <Box fullWidth align="center" gap={smallSpacing}>
          <Box
            fullWidth
            direction={isMobile ? 'column' : 'row'}
            justify="space-between"
            align="flex-start"
            gap={regularSpacing}
          >
            <TextFieldWithLabelFormik
              labelTextKey={Texts.textFieldEmailLabel}
              formProps={formProps}
              name="email"
              fullWidth
              component={TextFieldOutlined}
              onKeyDown={(e) => e.keyCode === ENTER_KEYCODE && formProps.handleSubmit(e)}
            />

            <StyledButtonWrapper fullWidth={isMobile} top spacing={isMobile ? tinySpacing : defaultSpacing}>
              <StyledButton
                onClick={formProps.handleSubmit as any}
                disabled={formProps.touched && !!formProps.errors.agreed}
                loading={isLoading}
                fullWidth
                textKey={Texts.continueButtonTextWhite}
                sx={{ backgroundColor: '#12059E' }}
                spacing={isMobile ? undefined : defaultSpacing}
                endIcon={<FigmaImageContainer imageKey={Images.arrowRightWhite} />}
              />
            </StyledButtonWrapper>
          </Box>
          {isSignUp && <SignInSignupAgreeCheckbox />}
          {isSignIn && (
            <SignInDescription fullWidth fullPadding direction="row" gap={tinySpacing} spacing={littleSpacing}>
              <FigmaText textKey={Texts.signInDescription} text="🪄" />
              <FigmaText textKey={Texts.signInDescription} />
            </SignInDescription>
          )}
        </Box>
      )}
    </Formik>
  )
}

const StyledButton = styled(Button)`
  height: ${ONBOARDING_BUTTON_HEIGHT};
  div {
    width: fit-content !important;
  }
`

const StyledButtonWrapper = styled(Box)`
  min-width: auto;
`

const SignInDescription = styled(Box)`
  background-color: ${Colors.neutral97};
  border-radius: ${tinyBorderRadius};
`
