import styled from '@emotion/styled'
import React from 'react'
import { minBorderRadius } from '../enums/BorderRadixes'
import { minSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useShouldShowExpandedMenu from '../hooks/useShouldShowExpandedMenu'
import Colors from '../ygdrasil/libs/Colors'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { IconProps } from './Icon'
import Tooltip from './Tooltip'

export function MenuButton({
  onClick,
  isSelected,
  textKey,
  Icon,
  enableBorder = true
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  isSelected?: boolean
  textKey: AntiloopTextType
  Icon: React.FC<Partial<IconProps>>
  enableBorder?: boolean
}) {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const shouldShowExpandedMenu = useShouldShowExpandedMenu()
  const tooltipTitle = !shouldShowExpandedMenu ? getTextForTextKey(textKey, org) : ''

  const cssTextKey = isSelected
    ? Texts.applicationMenuStrategyButtonTextActive
    : Texts.applicationMenuStrategyButtonText
  return (
    <Tooltip title={tooltipTitle} placement="right">
      <Container
        isSelected={isSelected}
        onClick={onClick}
        align="center"
        fullWidth
        direction="row"
        pointer={!!onClick}
        fullPadding
        spacing={minSpacing}
      >
        <Icon color={isSelected ? Colors.white : undefined} />
        {shouldShowExpandedMenu && (
          <Box left spacing={smallSpacing}>
            <FigmaTextForStrategyModel styleTextKey={cssTextKey} textKey={textKey} />
          </Box>
        )}
      </Container>
    </Tooltip>
  )
}
const Container = styled(Box)<{ isSelected?: boolean; enableBorder?: boolean }>`
  background: ${({ isSelected }) => (isSelected ? Colors.primary40 : 'transparent')};
  ${({ enableBorder }) =>
    enableBorder ? `border: 1px solid ${({ isSelected }) => (isSelected ? Colors.primary80 : 'transparent')};` : ''}
  border-radius: ${minBorderRadius};
`
