import React from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { isVideo } from '../libs/VideoHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { BUSINESS_PLAN_DRAWER_FORM_EDIT_VISION } from './BusinessPlanDrawers'
import Button from './Button'
import { openDrawer } from './DrawerGlobalBusinessPlan'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { DEFAULT_ICON_SIZE, InsertLink, Visibility } from './Icons'
import ModalVideo from './ModalVideo'
import { RichText } from './RichText'
import VideoThumbnail from './VideoThumbnail'

export function BusinessPlanVision() {
  const { state } = useAppState()
  const { data: o } = useOrganization(state)
  const [showVideoModal, setShowVideoModal] = React.useState(false)
  const url = o?.vision?.url
  const config = useLoggedInConfig()
  const _onClickVideo = () => {
    if (isVideo(url)) return setShowVideoModal(true)
    window.open(url, '_blank')
  }

  const onClickContent = () => {
    config.enableEditBusinessView && openDrawer(config, BUSINESS_PLAN_DRAWER_FORM_EDIT_VISION)
  }
  const description = o?.vision?.description
  const richDescription = o?.vision?.richDescription

  return (
    <Box fullWidth>
      {showVideoModal && o?.vision?.url && <ModalVideo onClose={() => setShowVideoModal(false)} url={o.vision.url} />}
      <Box fullWidth onClick={onClickContent} pointer align="center" justify="center">
        {!!o?.vision && (
          <Box fullWidth>
            <Box align="center" fullWidth direction="row" bottom spacing={smallSpacing}>
              <Visibility size={DEFAULT_ICON_SIZE} />
              <Box left spacing={smallSpacing}>
                <FigmaTextForStrategyModel textKey={Texts.pageHeaderVision} />
              </Box>
            </Box>
            <Box onClick={onClickContent}>
              <FigmaText textKey={Texts.applicationMenuVisionHeaderDark} text={o.vision.name} />
            </Box>
            <Box top spacing={tinySpacing} onClick={onClickContent}>
              <RichText
                richText={richDescription}
                fallbackText={description}
                fallbackTextKey={Texts.applicationMenuVisionDescriptionDark}
              />
            </Box>
          </Box>
        )}
        {!o?.vision && <Button buttonType="empty_state" textKey={Texts.applicationMenuAddFirstVisionButtonText} />}
      </Box>
      {!!url && (
        <Box fullWidth top spacing={smallSpacing}>
          {isVideo(url) ? (
            <VideoThumbnail onClick={_onClickVideo} />
          ) : (
            <Box link onClick={() => window.open(url, '_blank')}>
              <InsertLink />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
