import styled from '@emotion/styled'
import { mediumBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getActivityItems } from '../libs/ActivityBoardHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import Colors from '../ygdrasil/libs/Colors'
import { TreeItem } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import Texts from '../ygdrasil/libs/Texts'
import { useLanguage } from '../ygdrasil/libs/useLanguage'
import { Activity } from '../ygdrasil/types/types'
import { ActivityBoardKanbanCardActivity, ActivityBoardKanbanCardActivityView } from './ActivityBoardKanbanCardActivity'
import Box from './Box'
import {
  BIG_ICON_SIZE,
  MyActionsMyFocusDropDownIcon,
  MyFocusActivityCheckIcon,
  MyFocusActivityDoneCheckIcon,
  MyFocusActivityInProgressCheckIcon
} from './Icons'
import { MyActionsMyFocusForm } from './MyActionsMyFocusForm'

import dayjs from 'dayjs'
import { useState } from 'react'
import ZIndices from '../enums/Zindices'
import { MY_FOCUS_PRIORITIZED_ACTIVITY_HEIGHT } from '../libs/HardCodedSizes'
import { getWeeklyDateText } from '../libs/MyActionsMyFocusHelper'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import useQueryParams, { WeeklyFocusParams } from '../ygdrasil/hooks/useQueryParams'
import { ActivityStatusInfo } from '../ygdrasil/libs/ActivityHelper'
import { getArray } from '../ygdrasil/libs/Common'
import { updateActivity } from '../ygdrasil/libs/ProcessHandler'
import { IconProps } from './Icon'
import { MyActionsMyFocusEmptyState } from './MyActionsMyFocusEmptyState'
import MyActionsMyFocusWeeklyMenu from './MyActionsMyFocusWeeklyMenu'

const NO_OF_PRIORITIZED_ACTIVITES = 3

const ActivityStatusCheckComponent: { [property in ActivityStatus]: React.FC<Partial<IconProps>> } = {
  [ActivityStatus.TODO]: MyFocusActivityCheckIcon,
  [ActivityStatus.IN_PROGRESS]: MyFocusActivityInProgressCheckIcon,
  [ActivityStatus.DONE]: MyFocusActivityDoneCheckIcon
}

export function MyActionsMyFocus({ items }: { items: TreeItem[] }) {
  const { state } = useAppState()
  const language = useLanguage()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const { date: _date } = useQueryParams<WeeklyFocusParams>()
  const [date, setDate] = useState(dayjs(_date).format())
  const leadUserId = state.user._id
  const logEvent = useLogEvent()
  const proritizedItems = items
    .reduce((a, item) => a.concat(getActivityItems({ item, leadUserId })), [] as TreeItem[])
    .filter((item) => !!(item.data.data as Activity).isPrioritized)
    .filter((x, i) => i < NO_OF_PRIORITIZED_ACTIVITES)

  const weeklyDateLabel = getWeeklyDateText(date, language)

  const onClickWeeklyDate = (e) => setAnchorEl(e.currentTarget)
  return (
    <Container fullWidth fullPadding>
      <MyActionsMyFocusWeeklyMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        onClick={(date) => setDate(date)}
      />
      <Box fullWidth direction="row" justify="space-between">
        <FigmaText textKey={Texts.myFocusThisWeekHeader} />
        <Box onClick={onClickWeeklyDate} direction="row" align="center" link>
          <FigmaText textKey={Texts.weeklyFocusDropDownLabel} text={weeklyDateLabel} />
          <Box left spacing={tinySpacing}>
            <MyActionsMyFocusDropDownIcon />
          </Box>
        </Box>
      </Box>
      <Box top spacing={smallSpacing} fullWidth>
        {proritizedItems.length === 0 && <MyActionsMyFocusEmptyState />}
        <Box fullWidth direction="row" align="center" justify="space-between">
          {getArray(NO_OF_PRIORITIZED_ACTIVITES).map((key) => {
            const item = proritizedItems[key]
            if (!item) return <Box key={key} width={`${100 / 3}%`} />
            const activity = item.data.data as Activity
            const Component = ActivityStatusCheckComponent[activity.status]
            const nextStatus = ActivityStatusInfo[activity.status].nextStatus
            return (
              <ActivityCard key={key} position="relative">
                <Box
                  pointer
                  position="absolute"
                  style={{ right: -BIG_ICON_SIZE / 4, top: -BIG_ICON_SIZE / 2 }}
                  onClick={() => !!nextStatus && updateActivity({ ...activity, status: nextStatus }, logEvent)}
                  zIndex={ZIndices.focus}
                >
                  <Component />
                </Box>
                <ActivityBoardKanbanCardActivity
                  item={item}
                  index={0}
                  DoneComponent={ActivityBoardKanbanCardActivityView}
                />
              </ActivityCard>
            )
          })}
        </Box>
        <MyActionsMyFocusForm date={date} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.secondary90};
  border: 1px solid ${Colors.secondary80};
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.05);
  border-radius: ${mediumBorderRadius};
`
const ActivityCard = styled(Box)`
  width: 30%;
  height: ${MY_FOCUS_PRIORITIZED_ACTIVITY_HEIGHT};
  max-height: ${MY_FOCUS_PRIORITIZED_ACTIVITY_HEIGHT};
  min-height: ${MY_FOCUS_PRIORITIZED_ACTIVITY_HEIGHT};
`
