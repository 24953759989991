import { getHeaderSuffixText } from '../ygdrasil/libs/CelebrationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import EventCategories from '../ygdrasil/enums/EventCategories'
import { EventParams } from '../ygdrasil/libs/EventHelper'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import { CelebrationViewItemProps } from './CelebrationViewItem'
import FigmaImageContainer from './FigmaImageContainer'

export const CelebrationViewItemHeaderSuffix = (props: CelebrationViewItemProps<Partial<EventParams>>) => {
  const { eventCategory, params } = props.event

  if (eventCategory === EventCategories.UserCelebratedEvent)
    return <FigmaImageContainer imageKey={Images.celebrationIcon} />

  return <FigmaText textKey={Texts.cssCelebrationItemFromToValue} text={getHeaderSuffixText(params)} />
}
