import MaterialRadio, { RadioProps } from '@mui/material/Radio'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'
import styled from '@emotion/styled'

const SIZE = 20

type Props = RadioProps & { radioColor?: string }

export function RadioContainer(props: Props) {
  return (
    <Box align="center" justify="center">
      <Radio {...props} />
    </Box>
  )
}

export function Radio(props: Props) {
  const { radioColor, ...rest } = props
  const color = radioColor || Colors.primaryBase
  return (
    <MaterialRadio
      sx={{
        color,
        '&.Mui-checked': {
          color
        }
      }}
      disableRipple
      disableFocusRipple
      size="small"
      {...rest}
    />
  )
}

export const StyledRadio = styled(Radio)`
  padding: 0px;
`
