import invariant from 'invariant'
import { OnboardingState, OnboardingStep } from '../ygdrasil/types/Onboarding'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import * as OnboardingHelper from '../libs/OnboardingHelper'
import { getExistingJoinableOrganizations } from '../ygdrasil/libs/Helper'
import { getEmailForFirebaseUser } from '../ygdrasil/libs/Common'
import { Organization } from '../ygdrasil/types/types'
import { logOnboardingEvent } from '../ygdrasil/libs/FirebaseHelper'

export enum OnboardingStateTransitionType {
  SKIP,
  NEXT
}
export function sendOnboardingAnalyticsEvent(state: OnboardingState, type: OnboardingStateTransitionType): void {
  const { step } = state
  const analyticsEvent = OnboardingEvents[step]?.[type]
  invariant(analyticsEvent, `Cant find analytics event for ${step} ${type}`)
  logOnboardingEvent(analyticsEvent, state)
}

const OnboardingEvents = {
  [OnboardingStep.NAME]: {
    [OnboardingStateTransitionType.SKIP]: AnalyticsEvent.ONBOARDING_PAGE1_SKIP,
    [OnboardingStateTransitionType.NEXT]: AnalyticsEvent.ONBOARDING_PAGE1_CONTINUE
  },
  [OnboardingStep.TELL_US]: {
    [OnboardingStateTransitionType.SKIP]: AnalyticsEvent.ONBOARDING_PAGE2_SKIP,
    [OnboardingStateTransitionType.NEXT]: AnalyticsEvent.ONBOARDING_PAGE2_CONTINUE
  },
  [OnboardingStep.ADD_OBJECTIVES]: {
    [OnboardingStateTransitionType.SKIP]: AnalyticsEvent.ONBOARDING_PAGE3_SKIP,
    [OnboardingStateTransitionType.NEXT]: AnalyticsEvent.ONBOARDING_PAGE3_CONTINUE
  },
  [OnboardingStep.DONE]: {
    [OnboardingStateTransitionType.SKIP]: AnalyticsEvent.ONBOARDING_PAGE4_SKIP,
    [OnboardingStateTransitionType.NEXT]: AnalyticsEvent.ONBOARDING_PAGE4_CONTINUE
  },
  [OnboardingStep.TERMS]: {
    [OnboardingStateTransitionType.SKIP]: AnalyticsEvent.ONBOARDING_PAGE5_SKIP,
    [OnboardingStateTransitionType.NEXT]: AnalyticsEvent.ONBOARDING_PAGE5_CONTINUE
  }
}

export function getElligbleOrgs(firebaseUser, orgs: Organization[]) {
  const email = getEmailForFirebaseUser(firebaseUser)
  invariant(email, '!email uid: %s', firebaseUser.uid)
  const elligebleOrgs = getExistingJoinableOrganizations({ email, orgs })
  return elligebleOrgs
}

export function getStepAfterName(firebaseUser, elligebleOrgs: Organization[]) {
  return OnboardingHelper.shouldCreateNewOrganization(firebaseUser, elligebleOrgs)
    ? OnboardingStep.TELL_US
    : OnboardingStep.DONE
}
