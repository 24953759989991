import styled from '@emotion/styled'
import { Popper } from '@mui/material'
import MaterialTooltip, { TooltipProps } from '@mui/material/Tooltip'
import React from 'react'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'

type Props = TooltipProps & { title: string; titleElement?: React.ReactElement }
const Tooltip: React.FC<Props> = (props) => {
  const { titleElement, title, children, ...restProps } = props
  if (title === '') return props.children
  return (
    <MaterialTooltip
      {...restProps}
      title={titleElement || <FigmaText textKey={Texts.allTooltipText} text={title} />}
      PopperComponent={StyledPopper}
    >
      <div>{children}</div>
    </MaterialTooltip>
  )
}

const StyledPopper = styled(Popper)`
  .MuiTooltip-tooltip {
    background-color: ${Colors.primary10};
  }
`

export default Tooltip
