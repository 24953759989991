import { useHistory } from 'react-router'
import FigmaText from '../ygdrasil/components/FigmaText'
import Images from '../ygdrasil/libs/Images'
import RoutePath from '../ygdrasil/libs/RoutePath'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import OnboardingMobileWrapper from './OnboardingMobileWrapper'
import { WeAreHereToHelpMobile } from './WeAreHereToHelpMobile'

export default function PageOnboardingNotAvailalbe({ onClickBack }: { onClickBack: () => unknown | undefined | null }) {
  if (onClickBack === undefined) onClickBack = () => history.push(RoutePath.ROOT)
  const history = useHistory()

  return (
    <OnboardingMobileWrapper
      onClickBack={onClickBack}
      content={() => (
        <Box fullWidth align="center" alignText="center" fullHeight justify="space-between">
          <Box />
          <Box fullWidth fullPadding>
            <FigmaText textKey={Texts.stopWallNoAccountMobile} />
            <Box top fullWidth align="center">
              <FigmaImageContainer imageKey={Images.imageDesktopVersion} />
            </Box>
            <Box top fullWidth align="center">
              <FigmaText textKey={Texts.stopWallNoAccountMobileInfo} />
            </Box>
          </Box>
          <WeAreHereToHelpMobile />
        </Box>
      )}
    />
  )
}
