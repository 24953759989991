import styled from '@emotion/styled'
import invariant from 'invariant'
import { useState } from 'react'
import useAppState from '../hooks/useAppState'

import { FormikProps } from 'formik'
import { useToggleChatBubble } from '../hooks/useToggleChatBubble'
import { onPostUpdateOrganization } from '../libs/BusinessPlanDrawerHelper'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { DRAWER_WIDTH_STRING, DRAWER_WIDTH } from '../libs/HardCodedSizes'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { updateOrganization } from '../ygdrasil/libs/DBApiHandler'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Box from './Box'
import { BusinessPlanDrawerContent } from './BusinessPlanDrawerContent'
import { BusinessPlanDrawerForm, BusinessPlanDrawerFormType } from './BusinessPlanDrawerForm'
import { ModalCloseEditDialog } from './ModalCloseEditDialog'

export type Props = {
  onClose: () => void
  form?: BusinessPlanDrawerFormType
}

export function BusinessPlanDrawer(props: Props) {
  const logEvent = useLogEvent()
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const [formProps, setFormProps] = useState<BusinessPlanDrawerFormType | undefined>(props.form)
  const [showModalCloseEditDialog, setShowModalCloseEditDialog] = useState(false)
  useToggleChatBubble()

  const onSubmit = (values) => {
    invariant(formProps, '!formProps')
    invariant(org, 'org')

    updateOrganization(formProps.onSubmit(values, org)).catch(captureAndNotifyError)
    logEvent(formProps.eventCategory, {})
    onPostUpdateOrganization(props, setFormProps)
  }

  const onDelete = () => {
    invariant(formProps, '!formProps')
    invariant(org, 'org')
    invariant(formProps.onDelete, '!formProps.onDelete')
    if (!formProps.onDelete) return
    const newOrg = formProps.onDelete(org)
    updateOrganization(newOrg).catch(captureAndNotifyError)
    formProps.removeEventCategory && logEvent(formProps.removeEventCategory, {})
    onPostUpdateOrganization(props, setFormProps)
  }

  const onCancel = (formProps: FormikProps<object>) => {
    if (!formProps?.dirty) return onPostUpdateOrganization(props, setFormProps)
    setShowModalCloseEditDialog(true)
  }

  function onClickCloseInCloseEditDialog(): any {
    return () => onPostUpdateOrganization(props, setFormProps)
  }

  if (!org) return null

  return (
    <Container fullHeight>
      {showModalCloseEditDialog && (
        <ModalCloseEditDialog
          onClose={() => setShowModalCloseEditDialog(false)}
          onClickCloseDialog={onClickCloseInCloseEditDialog()}
        />
      )}
      {!formProps && <BusinessPlanDrawerContent onOpenForm={setFormProps} onClose={props.onClose} />}
      {formProps && (
        <BusinessPlanDrawerForm
          {...formProps}
          onCancel={(formProps) => onCancel(formProps)}
          onSubmit={onSubmit as any}
          initialValues={formProps.getIntitialValues(org)}
          onDelete={formProps.onDelete ? (onDelete as any) : undefined}
        />
      )}
    </Container>
  )
}

const Container = styled(Box)`
  max-width: ${DRAWER_WIDTH}px;
`
