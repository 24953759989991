import React from 'react'
import styled from '@emotion/styled'
import { AntiloopImageType } from '../ygdrasil/types/Antiloop'

import FigmaImage from './FigmaImage'
import Loader from './Loader'

const FigmaImageContainer: React.FC<{
  imageKey: AntiloopImageType
  onClick?: () => void
  className?: string
  imageStyle?: { [name: string]: string | number }
  fullWidth?: boolean
  isLoading?: boolean
  width?: string
}> = (props) => {
  const { imageKey, fullWidth, imageStyle, className, onClick, isLoading } = props
  const { width, height } = imageKey
  const aspectRatio = (width / height).toString()
  let _width = width
  if (fullWidth) _width = '100%'
  if (props.width) _width = props.width

  if (isLoading) return <Loader size={width} />
  return (
    <Wrapper
      onClick={onClick}
      className={className}
      style={{
        maxWidth: _width,
        maxHeight: height
      }}
    >
      {imageKey && (
        <FigmaImage imageKey={imageKey} imageStyle={{ ...imageStyle, aspectRatio: aspectRatio, width: _width }} />
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
`
export default FigmaImageContainer
