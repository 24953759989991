import styled from '@emotion/styled'
import dayjs from 'dayjs'
import invariant from 'invariant'
import { useRef } from 'react'
import { ComposedChart, Line, ReferenceDot, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { smallSpacing, SMALL_SPACING_RADII, tinySpacing } from '../enums/Spacings'
import { GRAPH_MARGINS, GRAPH_POPOVER_WIDTH } from '../libs/HardCodedSizes'
import FigmaText from '../ygdrasil/components/FigmaText'
import { GraphProps, useGraph } from '../ygdrasil/hooks/useGraph'
import Colors from '../ygdrasil/libs/Colors'
import { getArray } from '../ygdrasil/libs/Common'
import {
  getDuration,
  getGoalValue,
  getYAxisDomain,
  getYAxisLabelForValue,
  getYAxisTicks,
  GraphType
} from '../ygdrasil/libs/GraphHelper'
import Texts from '../ygdrasil/libs/Texts'
import { useLanguage } from '../ygdrasil/libs/useLanguage'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { CheckInProgress } from './CheckinProgress'
import GraphActivityList from './GraphActivityList'
import { GraphDot } from './GraphDot'
import GraphFlagIcon from './GraphFlagIcon'
import { GraphTooltip } from './GraphTooltip'
import WithTooltipOverflow from './WithTooltipOverflow'

type Props = {
  onClickHeader?: () => unknown
  showIndicatorSummary?: boolean
}

export const Graph = (props: GraphProps & Props) => {
  const language = useLanguage()

  const ref = useRef<HTMLDivElement>(null)

  const checkInRef = useRef<HTMLDivElement>(null)
  const { width = GRAPH_POPOVER_WIDTH, activeValue, enableActivityList, showIndicatorSummary } = props
  const { item, parentItem, onClickHeader } = props
  const objective = parentItem.data.data as Objective
  const keyResult = item.data.data as KeyResult
  const height = props.height || Math.round(width / 3)

  const { confidences = [] } = keyResult
  const { endDate, startDate } = objective
  const { formatFn, diff, getStartDate, numberOfTicks } = getDuration(objective)
  const { lastValue, values } = useGraph(props)
  const isEmptyState = (keyResult.values || []).length === 0
  const emptyStateTextKey = dayjs(startDate).isAfter(dayjs()) ? Texts.graphEmptyText : Texts.graphNoDataText
  if (!lastValue) return null
  invariant(typeof lastValue.value === 'number', 'Bad input: lastValue.value')

  const activeLineStroke = activeValue ? Colors.primaryBase : 'transparent'
  const _goalValue: number = getGoalValue(keyResult)

  return (
    <Container ref={ref} fullWidth bwidth={width} height={height} position="relative">
      {isEmptyState && (
        <Box position="absolute" fullWidth alignText="center" fullHeight align="center" justify="center">
          <FigmaText textKey={emptyStateTextKey} />
        </Box>
      )}
      <Box fullWidth left>
        <Box fullWidth>
          <Box fullWidth ref={checkInRef}>
            <CheckInProgress confidences={confidences} objective={objective} enableHead />
          </Box>
        </Box>
        {enableActivityList && (
          <Box top spacing={tinySpacing} fullWidth>
            <GraphActivityList item={item} parentItem={parentItem} />
          </Box>
        )}
      </Box>
      {!!showIndicatorSummary && (
        <Box fullWidth direction="row" align="center" justify="space-between">
          <IndicatorSummary onClick={onClickHeader} link={!!onClickHeader} left>
            <WithTooltipOverflow ellipsisTextKey={Texts.cssIndicatorGraphSummaryText} text={item.data.name}>
              <FigmaText textKey={Texts.cssIndicatorGraphSummaryText} text={item.data.name} />
            </WithTooltipOverflow>
          </IndicatorSummary>
          <Box>
            <FigmaText textKey={Texts.indicatorValueMobile} text={lastValue.value.toString()} />
          </Box>
        </Box>
      )}

      <Box fullWidth style={{ opacity: isEmptyState ? 0.5 : 1 }}>
        <ResponsiveContainer width="100%" height={height}>
          <ComposedChart data={values} margin={GRAPH_MARGINS}>
            <XAxis
              dataKey="timestamp"
              domain={['dataMin', 'dataMax']}
              type="number"
              tickFormatter={(timestamp) => formatFn(dayjs(timestamp), language)}
              scale="linear"
              ticks={getArray(numberOfTicks).map((x, i) => getStartDate(startDate).valueOf() + i * diff)}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              type="number"
              dataKey="base"
              domain={getYAxisDomain(values, keyResult, objective)}
              scale="linear"
              tickFormatter={(value) => getYAxisLabelForValue(value, keyResult)}
              ticks={getYAxisTicks(keyResult, objective)}
              tickLine={false}
              axisLine={false}
              tick={false}
            />

            <ReferenceLine x={dayjs(activeValue?.date).valueOf()} stroke={activeLineStroke} strokeDasharray="3 3" />
            <ReferenceLine y={activeValue?.value} stroke={activeLineStroke} strokeDasharray="3 3" />
            <ReferenceDot
              x={dayjs(activeValue?.date).valueOf()}
              y={activeValue?.value}
              shape={(props) => <circle {...props} r={SMALL_SPACING_RADII} fill={Colors.primaryBase} strokeWidth={0} />}
            />

            <ReferenceDot x={dayjs(endDate).valueOf()} y={_goalValue} shape={GraphFlagIcon} />
            <defs>
              <linearGradient id="grad" x1="100%" x2="0%">
                <stop offset="0" stopColor={Colors.primaryBase} />
                <stop offset="1" stopColor={Colors.whiteBg} />
              </linearGradient>
            </defs>
            <Line
              type="monotone"
              dataKey="value"
              stroke={'url(#grad)'}
              strokeWidth={2}
              dot={(props) => (
                <GraphDot lastPayload={lastValue} type={GraphType.PURPLE} keyResult={keyResult} {...props} />
              )}
            />
            {!isEmptyState && <Tooltip content={(props) => <GraphTooltip {...props} color={Colors.primaryBase} />} />}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Container>
  )
}

const LabelStyle = Texts.graphXAxisLabel.style
const Container = styled(Box)<{ bwidth: number; height: number }>`
  min-width: ${({ bwidth }) => bwidth}px;
  min-height: ${({ height }) => height}px;
  & .recharts-cartesian-axis-tick {
    font-family: ${LabelStyle.fontFamily};
    font-size: ${LabelStyle.fontSize};
    font-weight: ${LabelStyle.fontWeight};
    letter-spacing: ${LabelStyle.letterSpacing};
    color: ${LabelStyle.color};
  }
`
const IndicatorSummary = styled(Box)`
  padding-top: ${smallSpacing};
`
