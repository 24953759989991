import { State } from '../hooks/useAppState'

export function getStateForRoadmapView(state: State): State {
  return {
    ...state,
    selectedDashboardView: 'roadmap',
    searchState: {
      ...state.searchState,
      selectedItemId: undefined // TODO WRITE TEST, should not open detail view when switching to roadmap view
    }
  }
}

export function getStateForListView(state: State): State {
  return {
    ...state,
    selectedDashboardView: 'list'
  }
}
