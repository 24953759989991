import React, { useEffect } from 'react'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import { OurDrawer } from './Drawers'
import EditActivityDialog from './EditActivityDialog'
import { CreateEditActivityFormProps } from './EditActivityDialogForm'

export const openEditActivityDrawer = (props: CreateEditActivityFormProps) =>
  eventEmitter.emit(Events.OPEN_EDIT_ACTIVITY_RESULT_DIALOG, props)

export default function DrawerGlobalEditKeyResultDialog() {
  const [visible, setVisible] = React.useState(false)
  const [props, setProps] = React.useState<CreateEditActivityFormProps>()
  const onCloseEditObjectiveDrawer = () => {
    setProps(undefined)
    setVisible(false)
  }

  useEffect(() => {
    eventEmitter.on(Events.OPEN_EDIT_ACTIVITY_RESULT_DIALOG, (props: CreateEditActivityFormProps) => {
      setProps(props)
      setVisible(true)
    })

    return () => {
      eventEmitter.removeAllListeners(Events.OPEN_EDIT_ACTIVITY_RESULT_DIALOG)
    }
  }, [])

  if (!props) return null

  return (
    <OurDrawer
      open={visible}
      content={<EditActivityDialog {...props} onClose={onCloseEditObjectiveDrawer} />}
      onClose={onCloseEditObjectiveDrawer}
    />
  )
}
