import { AuthProvider, linkWithCredential, OAuthCredential, UserCredential } from 'firebase/auth'
import { isIOS } from 'react-device-detect'
import config from '../Config'
import { addQueryParamsToUrl } from '../ygdrasil/hooks/useQueryParams'
import { ACTION_CODE_SETTINGS } from '../ygdrasil/libs/EmailHelper'
import onUnhandledPromiseRejection from '../ygdrasil/libs/onUnhandledPromiseRejection'
import RoutePath from '../ygdrasil/libs/RoutePath'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { PreferredLoginMethod, SignInState, SignInSteps } from '../ygdrasil/types/SignInTypes'
import { FirebaseUser, User } from '../ygdrasil/types/types'
import { GoogleAuthProvider, MicrosoftAuthProvider } from './SignInProviders'

export type SignInPageProps = {
  onLogin?: (res: { firebaseUser: FirebaseUser; user?: User }) => Promise<unknown>
  signInState?: Partial<SignInState>
  enableOnboarding?: boolean
  enableAppleAuthProvider?: boolean
}

export const getProviderForProviderId = (providerId: string): AuthProvider | undefined => {
  if (providerId === GoogleAuthProvider.providerId) return GoogleAuthProvider
  if (providerId === MicrosoftAuthProvider.providerId) return MicrosoftAuthProvider
}

export function getUrlToResendPage(email: any): string {
  return addQueryParamsToUrl(RoutePath.RESEND_SECURE_LINK, { email })
}

export function onSignInWithEmailLink(
  userCredential: UserCredential,
  invitationToken?: string,
  _window: Window = window
) {
  let url = ACTION_CODE_SETTINGS.url
  if (invitationToken) url = addQueryParamsToUrl(url, { invitationToken: invitationToken as string })
  _window.location.href = url

  return userCredential
}

const Functions = { linkWithCredential }
export const onAuthStateChanged = (user: FirebaseUser | null, signInState: SignInState, functions = Functions) => {
  const { linkWithCredential } = functions
  const { pendingUserCredential } = signInState.data

  if (!user) return
  if (!pendingUserCredential) return

  const _pendingUserCredential = OAuthCredential.fromJSON(pendingUserCredential)

  if (!_pendingUserCredential) return
  const isLinkedWithProvider = user.providerData.some(
    (userInfo) => userInfo && userInfo.providerId === _pendingUserCredential.providerId
  )
  if (!isLinkedWithProvider) linkWithCredential(user, _pendingUserCredential).catch(onUnhandledPromiseRejection)
}

export const DEFAULT_SIGN_IN_STATE: SignInState = {
  data: {
    email: '',
    signInMethods: []
  },
  preferredLoginMethod: PreferredLoginMethod.MAIL,
  step: SignInSteps.LANDING
}

export function getSagaRedirectUrlForExperimental(id: string): string | undefined {
  if (isIOS) return config.sagaUrl
  const url = RoutePath.ANDROID_LOGIN.replace('/', '')

  return `intent://${url}#Intent;scheme=saga;package=com.responsive.saga;end`
}

export const getSignUpUSPTexts = (): { textKey: AntiloopTextType }[] => [
  {
    textKey: Texts.signUpUsp1
  },
  {
    textKey: Texts.signUpUsp2
  },
  {
    textKey: Texts.signUpUsp3
  }
]
