/* eslint-disable react-hooks/rules-of-hooks */
import { useHistory } from 'react-router'
import useFirebaseUser from '../hooks/useFirebaseUser'
import RoutePath from '../ygdrasil/libs/RoutePath'
import LoadingPage from './LoadingPage'
import OnboardingMachine from './OnboardingMachine'

const OnboardingPage = () => {
  const firebaseUser = useFirebaseUser()
  const history = useHistory()

  if (!firebaseUser?.uid || !firebaseUser?.email) {
    history.push(RoutePath.ROOT)
    return <LoadingPage />
  }

  return <OnboardingMachine firebaseUser={firebaseUser} />
}

export default OnboardingPage
