import styled from '@emotion/styled'
import invariant from 'invariant'
import React, { useState } from 'react'
import { Crop } from 'react-image-crop'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useUpdateUser from '../hooks/useUpdateUser'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { MIN_WIDTH_MODAL_ADD_PICTURE_BUTTON_CONTAINER } from '../libs/HardCodedSizes'
import { uploadFile } from '../libs/uploadFile'
import { ReactImageCrop } from '../ReactImageCrop'
import FigmaText from '../ygdrasil/components/FigmaText'
import EventCategories from '../ygdrasil/enums/EventCategories'
import { UploadFolder } from '../ygdrasil/enums/UploadFolder'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { useStorageUrl } from '../ygdrasil/hooks/useStorageUrl'
import { ONE_SECOND } from '../ygdrasil/libs/Consts'
import { useUser } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import AddButton from './AddButton'
import Box from './Box'
import Button from './Button'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'
import { ModalProps } from './Modal'
import { SettingsPersonalPhotoContainer } from './SettingsPersonalPhotoContainer'

type Props = ModalProps
const UPLOAD_DELAY = ONE_SECOND * 10
export const ModalAddProfilePictureView = ({ onClose }: Props) => {
  const [loading, setLoading] = React.useState(false)
  const [file, setFile] = React.useState<Blob>()
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const [imgSrc, setImgSrc] = React.useState('' as string | undefined)
  const [imgFileSize, setImageFileSize] = React.useState<number>()
  const [cropLoadedRes, setImageCropLoadedResult] = useState<any>()
  const [completedCrop, setCompletedCrop] = useState<Crop>()
  const actualFileSize = getActualFileSize(cropLoadedRes, imgFileSize, completedCrop)
  const { state } = useAppState()
  const { data: user = state.user } = useUser(state.user._id)
  const { url, setUrl } = useStorageUrl(user.profileImage)
  const logEvent = useLogEvent()
  const updateUser = useUpdateUser()

  const onFileChange = (file: Blob | undefined) => {
    if (!file) return
    setFile(file)

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const base64Image = reader.result
      setImageFileSize(getSizeInBytes(base64Image))
      setImgSrc(reader.result?.toString() || '')
    })
    reader.readAsDataURL(file)
  }

  const onClickSave = () => {
    return Promise.resolve(setLoading(true)).then(() => {
      invariant(canvasRef.current, 'canvasRef.current')
      return new Promise<Blob>((resolve, reject) => {
        if (!canvasRef.current) return reject(new Error('!canvasRef.current'))
        canvasRef.current.toBlob((blob) => {
          if (!blob) reject(new Error('Failed to create blob'))
          else resolve(blob)
        })
      }).then((blob) => onShouldUploadBlob(blob))
    })
  }

  const onShouldUploadBlob = (blob: Blob) =>
    Promise.resolve(setLoading(true))
      .then(() => uploadFile(blob, UploadFolder.PROFILE_PICTURES))
      .then(({ url, bucketPath }) => {
        updateUser({ ...user, profileImage: bucketPath })
        logEvent(EventCategories.UserUploadsProfilePhoto, {})
      })
      .then(() => onClose())
      .catch((err) => captureAndNotifyError(err))
      .finally(() => setLoading(false))

  return (
    <Box fullWidth fullPadding>
      <canvas ref={canvasRef} style={{ visibility: 'hidden', position: 'absolute' }} />
      <Box bottom>
        <FigmaText textKey={Texts.dialogEditLogoHeader} />
      </Box>
      <Box direction="row" fullWidth bottom>
        {imgSrc && (
          <ReactImageCrop
            imgSrc={imgSrc}
            canvasRef={canvasRef}
            onCompletedCrop={(crop) => {
              setCompletedCrop(crop)
            }}
            onLoadCrop={(res) => setImageCropLoadedResult(res)}
          />
        )}
        {!imgSrc && <SettingsPersonalPhotoContainer url={url} />}

        <ButtonContainer left bottom>
          <Box bottom>
            <FigmaTextWithStyleOverrides textKeys={{ en: Texts.dialogEditLogoInfoRecommendedSize }} />
            <FigmaText textKey={Texts.dialogEditLogoInfoRecommendedValue} />
          </Box>
          <Box bottom>
            <Box spacing={bigSpacing}>
              <label htmlFor={FILE_INPUT_REF}>
                <AddButton labelTextKey={Texts.dialogEditLogoUploadFileButtonText} onClick={() => ({})} />
              </label>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                id={FILE_INPUT_REF}
                onChange={(event) => onFileChange(event.currentTarget.files?.[0])}
              />
            </Box>
          </Box>
        </ButtonContainer>
      </Box>

      <Box direction="row" align="center" justify="flex-end" fullWidth>
        <Box right spacing={tinySpacing}>
          <Button textKey={Texts.cancelButtonText} buttonType="secondary" spacing={smallSpacing} onClick={onClose} />
        </Box>
        <Button spacing={smallSpacing} onClick={onClickSave} loading={loading} textKey={Texts.saveButtonText} />
      </Box>
    </Box>
  )
}
const FILE_INPUT_REF = 'filInputRef'

const Container = styled(Box)`
  max-width: 50vw;
  max-height: 80vh;
`

const ButtonContainer = styled(Box)`
  min-width: ${MIN_WIDTH_MODAL_ADD_PICTURE_BUTTON_CONTAINER}px;
`

const StyledFigmaTextWithLinebreaks = styled(FigmaTextWithStyleOverrides)`
  white-space: nowrap;
`

function getSizeInBytes(base64String) {
  // The string length is in characters, not bytes, so we need to convert it
  const byteCharacters = atob(base64String.split(',')[1])
  const byteCount = byteCharacters.length
  return byteCount
}

const getActualFileSize = (cropLoadedRes?, imgFileSize?: number, crop?: Crop) => {
  if (!imgFileSize || !crop) return
  const fileSizePerPx = imgFileSize / (cropLoadedRes.width * cropLoadedRes.height)
  return fileSizePerPx * crop.width * crop.height
}
