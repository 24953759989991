import { Datasource } from '../types/types'

export const ALL_EXCEL_FILES_QUERY = `https://graph.microsoft.com/v1.0/me/drive/root/search(q='.xlsx')`

export const getQueryForSheetsForDriveItemId = (id: string) => `/me/drive/items('${id}')/workbook/worksheets`

export const getQueryForDataSource = (ds: Datasource) => getQueryForDriveItemAndSheetData(ds.driveItemId, ds.sheetId)

export const getQueryForDriveItemAndSheetData = (driveItemId: string, sheetsId: string) =>
  `/me/drive/items('${driveItemId}')/workbook/worksheets('${sheetsId}')/usedRange`
