import { BoxProps } from '@mui/material'
import React from 'react'
import useAppState from '../hooks/useAppState'
import { getStateForDashboard } from '../libs/MenuHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { MenuDashboardIcon } from './Icons'
import { MenuButton } from './MenuButton'

const MenuButtonDashboard: React.FC<BoxProps> = function () {
  const { state, setState } = useAppState()
  const { showDashboard } = state

  const onClick = () => setState(getStateForDashboard(state))

  return (
    <Box id="DashboardButton" fullWidth>
      <MenuButton
        onClick={onClick}
        isSelected={showDashboard}
        textKey={Texts.applicationMenuDashboardButtonText}
        Icon={MenuDashboardIcon}
      />
    </Box>
  )
}

export default MenuButtonDashboard
