import { mediumSpacing, regularSpacing, tinySpacing } from '../enums/Spacings'
import useResponsive from '../hooks/useResponsive'
import { shouldShowMail } from '../libs/SignInWithAuthProvidersHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { SignInViewProps } from '../ygdrasil/types/SignInTypes'
import Box from './Box'
import SignInSignUpLayout from './SignInSignUpLayout'
import SignInSignUpWithEmailForm from './SignInSignUpWithEmailForm'
import { SignInWithAuthProviders } from './SignInWithAuthProviders'
import { SignUpLink } from './SignUpLink'
import { GreyLineMobile } from './StyledComponents'

export default function SignInLanding(props: SignInViewProps) {
  const { isMobile } = useResponsive()
  const headingTextkey = isMobile ? Texts.signInHeadingMobile : Texts.signInHeading
  const subheadingTextKey = isMobile ? Texts.signInSubheadingMobile : Texts.signInSubheading
  const { preferredLoginMethod } = props.signInState
  const _shouldShowMail = shouldShowMail(preferredLoginMethod)

  return (
    <SignInSignUpLayout headingTextKey={headingTextkey} subheadingTextKey={subheadingTextKey}>
      <Box bottom>
        <SignInWithAuthProviders {...props} />
        {_shouldShowMail && (
          <>
            <Box top bottom direction="row" align="center" fullWidth spacing={regularSpacing}>
              <GreyLineMobile />
              <Box left right spacing={mediumSpacing} style={{ marginRight: tinySpacing }}>
                <FigmaText textKey={Texts.allSeparatorOr} />
              </Box>
              <GreyLineMobile />
            </Box>
            <Box fullWidth top bottom spacing={tinySpacing}>
              <SignInSignUpWithEmailForm {...props} />
            </Box>
          </>
        )}
      </Box>
      <SignUpLink {...props} />
    </SignInSignUpLayout>
  )
}
