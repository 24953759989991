import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import eventEmitter from '../ygdrasil/libs/EventEmitter'
import Box from './Box'
import Celebration from './Celebration'
import { openMyTodosDrawer } from './GlobalComponents'
import { PageHeaderButtonMyTodosIcon } from './Icons'
import InviteUserAddIcon from './InviteUserAddIcon'
import PageHeaderButtonsMyTodos from './PageHeaderButtonsMyTodos'

export function PageHeaderButtons() {
  const config = useLoggedInConfig()
  return (
    <Box direction="row" align="center">
      <Box left>
        <Celebration />
      </Box>
      <Box left spacing={smallSpacing} pointer>
        <PageHeaderButtonsMyTodos />
      </Box>
      {config.enableInviteUsers && (
        <Box left spacing={smallSpacing}>
          <InviteUserAddIcon />
        </Box>
      )}
    </Box>
  )
}
