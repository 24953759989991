import styled from '@emotion/styled'
import { minimumBorderRadius } from '../enums/BorderRadixes'
import { minSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import EventCategories from '../ygdrasil/enums/EventCategories'
import Colors from '../ygdrasil/libs/Colors'
import { EventParams } from '../ygdrasil/libs/EventHelper'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { CelebrationViewItemProps } from './CelebrationViewItem'
import { CelebrationViewItemHeaderSuffix } from './CelebrationViewItemHeaderSuffix'
import { UserWidgetViewCelebrationItem } from './UserWidget'
import { useUser } from '../ygdrasil/libs/QueryHooks'
import { useDistanceTextForEvent } from '../ygdrasil/hooks/useDistanceTextForEvent'

export function CelebrationViewItemHeader(props: CelebrationViewItemProps<Partial<EventParams>>) {
  const { state, setSearchState } = useAppState()
  const { data: user = state.user } = useUser(state.user._id)
  const { event } = props
  const { params, eventCategory } = event
  const { objective, celebratedBy } = params || {}
  const headerTextKey =
    event.eventCategory === EventCategories.UserAddIndicatorValue
      ? Texts.cssCelebrationItemHeader
      : Texts.celebrationItemXxxCelebratedText

  const onClickObjectiveName = () => setSearchState({ ...state.searchState, selectedItemId: objective?._id })

  return (
    <Box fullWidth justify="space-between" direction="row">
      <Box>
        <CelebrationViewItemHeaderContainer eventCategory={eventCategory}>
          <FigmaText
            textKey={Texts.cssCelebrationItemHeader}
            text={formatText(getFigmaText(headerTextKey), [celebratedBy])}
          />
        </CelebrationViewItemHeaderContainer>
        <Box top spacing={minSpacing} onClick={onClickObjectiveName} link>
          <FigmaText textKey={Texts.cssCelebrationItemObjectiveSummary} text={objective?.name} />
        </Box>
      </Box>
      <Box align="center" direction="row">
        <FigmaText
          styleTextKey={Texts.cssCelebrationItemDaysAgo}
          textKey={Texts.celebrationItemDaysAgo}
          text={formatText(getFigmaText(Texts.celebrationItemDaysAgo, user.language), [
            useDistanceTextForEvent(event, user)
          ])}
        />
        <Box left spacing={tinySpacing}>
          <UserWidgetViewCelebrationItem userId={event.createdBy} />
        </Box>
        <Box left spacing={tinySpacing}>
          <CelebrationViewItemHeaderSuffix {...props} />
        </Box>
      </Box>
    </Box>
  )
}

const CelebrationViewItemHeaderContainer = styled(Box)<{ eventCategory: EventCategories }>`
  background-color: ${({ eventCategory }) =>
    eventCategory === EventCategories.UserCelebratedEvent ? Colors.tertiaryBase : Colors.primaryBase};
  padding: ${minSpacing} ${tinySpacing};
  border-radius: ${minimumBorderRadius};
`
