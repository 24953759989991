import { initializeApp } from '@firebase/app'
import { initializeFirestore } from '@firebase/firestore'
import { getFunctions } from '@firebase/functions'
import { getAuth } from 'firebase/auth'
import config from '../Config'

import { DefaultFirebaseAppOptions } from '../ygdrasil/libs/FirebaseHelper'
import { getClientRegion } from '../ygdrasil/libs/Helper'

// Configure Firebase.
export const app = initializeApp({
  apiKey: 'AIzaSyCq9-gMhP4nLyrS3OTrXVZT5Pb1bhFFpBI',
  authDomain: 'app.ourbigpicture.se',
  projectId: 'our-big-picture',
  messagingSenderId: '569484956472',
  appId: '1:569484956472:web:6f3b6ee901dc2a28a927cd',
  measurementId: 'G-DKPYPB7DHR',
  ...DefaultFirebaseAppOptions
})

export const db = initializeFirestore(app, { ignoreUndefinedProperties: config.enableSetFirestoreUndefinedProperties })

export const auth = getAuth(app)

export const functions = getFunctions(app, getClientRegion(config))
