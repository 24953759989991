import React from 'react'
import { minSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getColor } from '../libs/FocusedObjectiveHelper'
import { getChildObjectivesForAccordionBody, getStrategicObjectivesForRoadmap } from '../libs/RoadmapHelper'
import { Objective } from '../ygdrasil/types/types'
import Box from './Box'
import RoadmapAccordion from './RoadmapAccordion'
import { RoadmapAccordionBody } from './RoadmapAccordionBody'
import { RoadmapStrategicObjectiveHeader } from './RoadmapStrategicObjectiveHeader'

export function RoadmapStrategicObjectives(props: {
  objectives: Objective[]
  headerDates: string[]
  allObjectives: Objective[]
}) {
  const { state } = useAppState()
  const { searchState } = state
  const { objectives, headerDates, allObjectives } = props

  const strategicObjectives = getStrategicObjectivesForRoadmap(objectives, searchState)

  if (strategicObjectives.length === 0) return null

  return (
    <Box fullWidth>
      {strategicObjectives.map((o, index, arr) => {
        const type = o.type
        const numberOfObjectives = arr.length
        const isSelected = searchState.selectedItemId === o._id

        const childObjectives = getChildObjectivesForAccordionBody(o, objectives)
        return (
          <RoadmapAccordion
            key={index}
            Header={({ isExpanded, onToggleExpand }) => (
              <Box fullWidth spacing={minSpacing} top bottom>
                <RoadmapStrategicObjectiveHeader
                  headerDates={headerDates}
                  objective={o}
                  isExpanded={isExpanded}
                  onToggleExpand={onToggleExpand}
                  style={{ backgroundColor: getColor({ type, index, numberOfObjectives, isSelected }) }}
                />
              </Box>
            )}
            Body={() => (
              <RoadmapAccordionBody
                headerDates={headerDates}
                objectives={childObjectives}
                allObjectives={allObjectives}
              />
            )}
          />
        )
      })}
    </Box>
  )
}
