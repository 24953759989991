import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import DrawerGlobal from './DrawerGlobal'
import DrawerGlobalBusinessPlan from './DrawerGlobalBusinessPlan'
import DrawerGlobalEditActivityDialog from './DrawerGlobalEditActivityDialog'
import DrawerGlobalEditKeyResultDialog from './DrawerGlobalEditKeyResultDialog'
import DrawerGlobalEditObjectiveDialog from './DrawerGlobalEditObjectiveDialog'
import DrawerGlobalReorderObjectives from './DrawerGlobalReorderObjectives'
import { ModalStripeCallbackGlobalComponent } from './ModalStripeCallback'
import { MyTodos } from './MyTodos'
import NotificationGlobal from './NotificationGlobal'

export default function GlobalComponents() {
  return (
    <>
      <DrawerGlobalEditObjectiveDialog />
      <DrawerGlobalBusinessPlan />
      <DrawerGlobalEditKeyResultDialog />
      <DrawerGlobalEditActivityDialog />
      <DrawerGlobalReorderObjectives />
      {/** DONT USE ABOVE COMPONENTS USE GENERIC <DrawerGlobal /> !!!!! */}
      <DrawerGlobal ContentComponent={MyTodos} event={Events.OPEN_TODOS_DRAWER} />
      <NotificationGlobal />
      <ModalStripeCallbackGlobalComponent />
    </>
  )
}

export const openMyTodosDrawer = () => eventEmitter.emit(Events.OPEN_TODOS_DRAWER)
