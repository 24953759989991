import styled from '@emotion/styled'
import { TextField } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { useEffect, useRef, useState } from 'react'
import { minBorderRadius } from '../enums/BorderRadixes'
import { defaultSpacing, littleSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import {
  StrategyViewObjectiveListRowDetailBodyIndicator_FULL_SCREEN_GRAPH_HEIGHT,
  StrategyViewObjectiveListRowDetailBodyIndicator_GRAPH_HEIGHT,
  StrategyViewObjectiveListRowDetailBodyIndicator_MAX_GRAPH_WIDTH
} from '../libs/HardCodedSizes'
import Colors from '../ygdrasil/libs/Colors'
import { updateIndicator } from '../ygdrasil/libs/DBApiHandler'
import Images from '../ygdrasil/libs/Images'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult } from '../ygdrasil/types/types'
import { ActivityBoard } from './ActivityBoard'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { BIG_ICON_SIZE, CloseDetailViewIcon, CloseIcon } from './Icons'
import Modal from './Modal'
import { StrategyViewObjectiveListRowDetailBodyIndicatorGraph } from './StrategyViewObjectiveListRowDetailBodyIndicatorGraph'
import { ObjectiveListDetailBodyHeaderWrapper } from './StrategyViewObjectiveListRowDetailBodyObjective'
import { StrategyViewObjectiveListRowDetailContainer } from './StrategyViewObjectiveListRowDetailContainer'
import StrategyViewObjectiveListRowDetailItemTable from './StrategyViewObjectiveListRowDetailItemTable'

export const MAX_GRAPH_WIDTH = StrategyViewObjectiveListRowDetailBodyIndicator_MAX_GRAPH_WIDTH
export const CLOSED_GRAPH_HEIGHT = StrategyViewObjectiveListRowDetailBodyIndicator_GRAPH_HEIGHT
export const OPEN_GRAPH_HEIGHT = StrategyViewObjectiveListRowDetailBodyIndicator_FULL_SCREEN_GRAPH_HEIGHT

export function StrategyViewObjectiveListRowDetailBodyIndicator({
  item,
  parentItem,
  onClickClose
}: {
  parentItem: Item
  item: Item
  onClickClose?: () => void
}) {
  const [showModal, setShowModal] = useState(false)
  const onClickToogleExpand = () => setShowModal(!showModal)
  const [indicatorSummary, setIndicatorSummary] = useState<string>(item?.data?.data?.name)
  const inputRef = useRef<HTMLInputElement>(null)
  const [focused, setFocused] = useState<boolean>(false)
  const handleUpdateSummary = () => {
    updateIndicator({ ...(item?.data?.data as KeyResult), name: indicatorSummary })
  }

  useEffect(() => {
    setIndicatorSummary(item?.data?.data?.name)
  }, [item?.data?.data?._id])

  return (
    <>
      <Box fullWidth>
        <ObjectiveListDetailBodyHeaderWrapper fullWidth bottom spacing={smallSpacing} align="center" direction="row">
          <Box fullWidth top spacing={smallSpacing} align="center" style={{ paddingLeft: defaultSpacing }}>
            <StyledTextFieldEditSummary
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  setFocused(false)
                  return handleUpdateSummary()
                }
              }}
              value={indicatorSummary}
              onChange={(e) => {
                setIndicatorSummary(e.target.value)
              }}
              variant="standard"
              inputProps={{
                style: {
                  textAlign: 'center',
                  cursor: 'pointer',
                  ...Texts.indicatorDetailPageIndicatorSummary.style
                }
              }}
              onFocus={() => setFocused(true)}
              fullWidth
              multiline
            />
            {focused && (
              <Box direction="row" fullPadding spacing={littleSpacing} gap={smallSpacing}>
                <EditSummaryStyledIcon
                  onClick={() => {
                    handleUpdateSummary()
                    return setFocused(false)
                  }}
                >
                  <FigmaImageContainer imageKey={Images.saveIconPurple} />
                </EditSummaryStyledIcon>
                <EditSummaryStyledIcon
                  onClick={() => {
                    inputRef.current?.blur()
                    setFocused(false)
                  }}
                >
                  <FigmaImageContainer imageKey={Images.closeIconRed} />
                </EditSummaryStyledIcon>
              </Box>
            )}
          </Box>
          <Container top left right align="flex-end" spacing={smallSpacing}>
            <Box onClick={onClickClose} pointer>
              <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
            </Box>
          </Container>
        </ObjectiveListDetailBodyHeaderWrapper>
        <Container fullWidth left right bottom spacing={smallSpacing} align="center">
          <StrategyViewObjectiveListRowDetailContainer fullWidth left right>
            <Box fullWidth top bottom spacing={smallSpacing}>
              <Box fullWidth>
                <StrategyViewObjectiveListRowDetailItemTable item={item} />
              </Box>
            </Box>
          </StrategyViewObjectiveListRowDetailContainer>
          <Box fullWidth top spacing={smallSpacing}>
            <StrategyViewObjectiveListRowDetailContainer fullWidth spacing={smallSpacing} fullPadding>
              <StrategyViewObjectiveListRowDetailBodyIndicatorGraph
                item={item}
                parentItem={parentItem}
                open={false}
                onClickToogleExpand={onClickToogleExpand}
              />
            </StrategyViewObjectiveListRowDetailContainer>
          </Box>
        </Container>
        <Box top fullWidth spacing={smallSpacing} />
        <Container fullWidth left right spacing={tinySpacing}>
          <ActivityBoard item={item} parentItem={parentItem} />
        </Container>
      </Box>
      {showModal && (
        <Modal onClose={onClickToogleExpand} transform="10%">
          <Box width="75vw" position="relative">
            <CloseIconContainer position="absolute" pointer onClick={onClickToogleExpand}>
              <CloseDetailViewIcon size={BIG_ICON_SIZE} />
            </CloseIconContainer>
            <StrategyViewObjectiveListRowDetailBodyIndicatorGraph item={item} parentItem={parentItem} open />
          </Box>
        </Modal>
      )}
    </>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.snow};
`

const CloseIconContainer = styled(Box)`
  top: ${-BIG_ICON_SIZE}px;
  right: ${-BIG_ICON_SIZE * 1.5}px;
`

export const EditSummaryStyledIcon = styled(Box)`
  padding: ${tinySpacing};
  background-color: ${Colors.neutral95};
  border-radius: ${minBorderRadius};
  cursor: pointer;

  &:hover {
    background-color: ${Colors.neutral90};
  }
`

export const StyledTextFieldEditSummary = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white'
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: 'white'
    }
  }
})(TextField)
