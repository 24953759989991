import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import useAppState from './useAppState'

export default function useClientWindowApi() {
  //@ts-ignore
  window.api = {}

  //@ts-ignore
  return window.api
}
