import { FormikProps } from 'formik'
import useAppState from '../hooks/useAppState'
import { InitialValues } from '../libs/EditKeyResultDialogHelper'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import { useDatasources } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'
import SelectFieldWithLabel from './SelectFieldWithLabel'
import { SettingsTabKey } from './SettingsTabs'

const NONE = 'None'

export function SelectDatasourceWithLabel<T>({
  formProps,
  onClose
}: {
  onClose: () => unknown
  formProps: FormikProps<InitialValues>
}) {
  const { state, setState } = useAppState()
  const { data: dataSoureces = [] } = useDatasources(state)
  const config = useLoggedInConfig()
  const hasNoDatasources = dataSoureces.length === 0
  const firstOptionText = hasNoDatasources ? getFigmaText(Texts.integrationDropDownEmptyText) : 'None'
  const options = dataSoureces.reduce(
    (a, dataSource) => {
      a.push({ key: dataSource.name, value: dataSource._id })
      return a
    },
    [{ key: firstOptionText, value: NONE } as { key: string; value: string | undefined }]
  )

  const onClickOpenSettings = () => {
    setState({ ...state, settingsTabKey: SettingsTabKey.INTEGRATIONS, showSettings: true })
    onClose()
  }

  return (
    <Box fullWidth>
      <SelectFieldWithLabel
        labelTextKey={Texts.rightPanelIndicatorIntegrationSelectLabel}
        name="dataSourceId"
        disabled={!config.enableIntegrations}
        fullWidth
        onChange={({ target: { value } }) => {
          if (value === NONE) return formProps.setFieldValue(`dataSourceId`, undefined)
          formProps.setFieldValue(`dataSourceId`, value)
        }}
        value={formProps.values.dataSourceId || NONE}
        options={options}
        tooltipElement={
          <Box pointer onClick={onClickOpenSettings} fullWidth>
            <FigmaTextWithStyleOverrides textKeys={{ en: Texts.integrationDropDownGuideText }} />
          </Box>
        }
      />
    </Box>
  )
}
