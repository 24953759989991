// @flow
import styled from '@emotion/styled'
import { Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import ZIndices from '../enums/Zindices'
import useAppState, { DEFAULT_STATE } from '../hooks/useAppState'
import useNotification from '../hooks/useNotification'
import { useToggleChatBubble } from '../hooks/useToggleChatBubble'
import {
  getSelectParentObjectiveOptions,
  InitialValues,
  onChangeLeadUserId,
  onChangeParentObjective,
  shouldShowHiddenObjectiveNotification,
  shouldShowStrategicPillarField,
  useInitialValues
} from '../libs/EditObjectiveDialogHelper'
import { DRAWER_WIDTH_STRING } from '../libs/HardCodedSizes'
import { KeyResultValidation, RequiredNameValidationDeprecated } from '../libs/ValidationHelper'
import { stringify } from '../libs/WYSIWYGEditor'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import useVisibleObjectives from '../ygdrasil/hooks/useVisibleObjectives'
import Colors from '../ygdrasil/libs/Colors'
import { ENTER_KEYCODE, SUGGESTABLE_NAME_LENGTH } from '../ygdrasil/libs/Consts'
import { getSuggestObjectiveNameConversation } from '../ygdrasil/libs/ConversationHelper'
import Images from '../ygdrasil/libs/Images'
import { toBaseObject, toCreateObjectiveRequest } from '../ygdrasil/libs/Mapper'
import { createObjective, removeIndicator, updateIndicator, updateObjective } from '../ygdrasil/libs/ProcessHandler'
import { useOrganization, useTeams } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective, Team } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import { ChipInputStrategicPillarsList } from './ChipInputStrategicPillarsList'
import { ChipInputTeamList } from './ChipInputTeamList'
import { DrawerFooterButtons } from './DrawerFooterButtons'
import { EditKeyResultsSection } from './EditKeyResultsSection'
import { EditObjectiveDialogHeaderWithLine } from './EditObjectiveDialogHeaderWithLine'
import { EditObjectiveDialogIndicators } from './EditObjectiveDialogIndicators'
import { EditObjectiveDialogKeyResultRowSuggestions } from './EditObjectiveDialogKeyResultRowSuggestions'
import { EditObjectiveDialogShowMore } from './EditObjectiveDialogShowMore'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { BIG_ICON_SIZE, CloseIcon } from './Icons'
import LeadSelectFieldWithLabel from './LeadSelectFieldWithLabel'
import { ModalCloseEditDialog } from './ModalCloseEditDialog'
import { ModalCreateEditTeam } from './ModalCreateEditTeam'
import { ModalDeleteObjective } from './ModalDeleteObjective'
import { NewObjectiveNotification, NewObjectiveNotificationHidden } from './Notifications'
import ObjectivePeriodAndTypeOptions from './ObjectivePeriodAndTypeOptions'
import { RichTextEditorFormik } from './RichTextEditorFormik'
import SelectFieldWithLabelMulti from './SelectFieldWithLabelMulti'
import SelectFieldWithTextKeyAndLabel from './SelectFieldWithTextKeyAndLabel'
import { LinkStyles } from './StyledComponents'
import { SuggestionButton } from './SuggestionButton'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import Tooltip from './Tooltip'

const ValidationSchema = Yup.object().shape({
  name: RequiredNameValidationDeprecated('name'),
  keyResults: Yup.array().of(KeyResultValidation)
})

type EditObjectiveDialogProps = {
  objective?: Partial<Objective>
  onClose: () => void
}

function EditObjectiveDialog(props: EditObjectiveDialogProps) {
  const [showChatGPTSuggestions, setShowChatGPTSuggestions] = useState(false)
  const { objective, onClose: _onClose } = props
  const logEvent = useLogEvent()
  const { state, setSearchState } = useAppState()
  const { data: org } = useOrganization(state)
  const strategicPillars = org?.businessPlan?.strategicPillars || []
  const { searchState } = state
  const { data: allObjectives = [], isLoading: isLoadingObjectives } = useVisibleObjectives()
  const initialValues = useInitialValues(allObjectives, state, objective)
  const [selectedKeyResult, setSelectedKeyResult] = useState<Partial<KeyResult> | undefined>()
  const { data: teams = [] } = useTeams(state)
  const { info } = useNotification()
  const [showDeleteObjectiveModal, setShowDeleteObjectiveModal] = React.useState(false)
  const [showMore, setShowMore] = useState<boolean>()

  const [showModalCloseEditDialog, setShowModalCloseEditDialog] = React.useState(false)

  const [showEditKeyresultSection, setShowEditKeyresultSection] = React.useState(false)

  const teamOptions = teams.map(({ name: key, _id: value }) => ({ key, value }))

  const onClose = (formProps) => {
    if (!formProps?.dirty) return _onClose()
    setShowModalCloseEditDialog(true)
  }

  const onSaveObjective = (values: InitialValues) => {
    const { name, description } = values

    _onClose()
    if (!name) return

    const _objective = { ...objective, ...values, name, description: stringify(description) }

    // TODO WRITE TEST PAIN Should not set state when updating objectives (bad ux in new dashboard list)
    if (!_objective.isNew) return updateObjective(_objective as Objective, logEvent)
    delete _objective.isNew

    if (searchState.selectedItemId)
      setSearchState({ ...searchState, selectedItemId: undefined, selectedObjectiveId: undefined }) // TODO WRITE TEST PAIN, should clear selectedItemId when creating objectives

    createObjective(toCreateObjectiveRequest(_objective, state), state, logEvent).then((o: Objective) => {
      const { startDate, endDate } = o
      const props = {
        component: NewObjectiveNotification,
        objective: o,
        onClickText: () => setSearchState({ ...searchState, selectedItemId: o._id })
      }

      if (shouldShowHiddenObjectiveNotification(o, state, allObjectives)) {
        props.component = NewObjectiveNotificationHidden
        props.onClickText = () => setSearchState({ ...DEFAULT_STATE.searchState, searchDates: { startDate, endDate } })
      }

      info(props)
    })
  }

  const onClickKeyResult = (keyResult: KeyResult): unknown => {
    if (!keyResult._id) return //TODO we dont support editing keyResults not saved to db as its to complex at the moment
    setSelectedKeyResult(keyResult)
    setShowEditKeyresultSection(true)
  }

  const onClickDeleteObjective = () => setShowDeleteObjectiveModal(true)

  const onDeleteObjective = () => {
    setShowDeleteObjectiveModal(false)
    _onClose()
  }

  const onClickAddKeyResult = () => {
    setShowEditKeyresultSection(true)
  }

  const onSaveKeyResult = (keyResult: KeyResult) => {
    onCloseEditKeyResultSection()
    updateIndicator(keyResult, logEvent)
  }

  const onDeleteKeyResult = (keyResult: KeyResult) => {
    onCloseEditKeyResultSection()
    removeIndicator(keyResult as KeyResult, logEvent)
  }

  const onCloseEditKeyResultSection = () => {
    setShowEditKeyresultSection(false)
    setSelectedKeyResult(undefined)
  }

  // eslint-disable-next-line prettier/prettier
  useToggleChatBubble()

  if (isLoadingObjectives) return null

  return (
    <>
      {showDeleteObjectiveModal && objective?._id && (
        <ModalDeleteObjective onClose={onDeleteObjective} objective={objective as Objective} />
      )}
      <Formik onSubmit={onSaveObjective} validationSchema={ValidationSchema} initialValues={initialValues}>
        {(formProps) => {
          const topObjectiveOptions = getSelectParentObjectiveOptions(formProps.values, allObjectives)

          const values = { ...toBaseObject(state), ...formProps.values } as unknown as Partial<Objective>
          if (showEditKeyresultSection)
            return (
              <EditObjectiveDialogContainer fullHeight>
                <EditKeyResultsSection
                  objective={values}
                  keyResult={selectedKeyResult as KeyResult}
                  onCancel={onCloseEditKeyResultSection}
                  onSaveKeyResult={(keyResult) => onSaveKeyResult(keyResult)}
                  onDeleteKeyResult={() => onDeleteKeyResult(selectedKeyResult as KeyResult)}
                  onClose={_onClose}
                />
              </EditObjectiveDialogContainer>
            )

          const onClickChatGPT = () => setShowChatGPTSuggestions(!showChatGPTSuggestions)

          const onClickSuggestion = (name: string): void => {
            setShowChatGPTSuggestions(false)
            setSelectedKeyResult({ name })
            setShowEditKeyresultSection(true)
          }

          return (
            <EditObjectiveDialogContainer fullHeight>
              <Box fullWidth fullPadding>
                {showModalCloseEditDialog && (
                  <ModalCloseEditDialog
                    onClose={() => setShowModalCloseEditDialog(false)}
                    onClickCloseDialog={_onClose}
                  />
                )}
                <EditObjectiveDialogStyledCloseIconContainer onClick={() => onClose(formProps)}>
                  <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
                </EditObjectiveDialogStyledCloseIconContainer>
                <EditObjectiveDialogHeaderWithLine textKey={Texts.rightPanelObjectiveEditObjectiveHeader} />
                <Box bottom spacing={smallSpacing} fullWidth>
                  <TextFieldWithLabelFormik
                    name="name"
                    labelTextKey={Texts.rightPanelObjectiveEditObjectiveShortSummaryLabel}
                    component={TextFieldOutlined}
                    formProps={formProps}
                    placeholderTextKey={Texts.rightPanelObjectiveEditObjectiveShortSummaryHelperText}
                    fullWidth
                    onKeyDown={({ keyCode }) => keyCode === ENTER_KEYCODE && formProps.handleSubmit()}
                  />
                </Box>
                {!!objective?.name && objective.name.length > SUGGESTABLE_NAME_LENGTH && (
                  <Box bottom spacing={smallSpacing} fullWidth>
                    <SuggestionButton
                      initialConversation={getSuggestObjectiveNameConversation(formProps.values.name, state)}
                      onNewSuggestion={(name) => formProps.setFieldValue('name', name)}
                      enableRerunConversation
                    />
                  </Box>
                )}

                {(showMore || !!initialValues?.description) && (
                  <Box
                    bottom
                    spacing={smallSpacing}
                    fullWidth
                    zIndex={ZIndices.high /** link drop down in rich text editor needs this */}
                  >
                    <RichTextEditorFormik
                      name="description"
                      labelTextKey={Texts.rightPanelObjectiveEditObjectiveLongerDescriptionLabel}
                      formProps={formProps}
                    />
                  </Box>
                )}
                <Box fullWidth bottom spacing={smallSpacing}>
                  <ObjectivePeriodAndTypeOptions formProps={formProps} />
                </Box>
                <Box bottom spacing={smallSpacing} fullWidth>
                  {teams.length > 0 && (
                    <SelectFieldWithLabelMulti
                      labelTextKey={Texts.rightPanelObjectiveEditObjectiveInvolvedTeamsLabel}
                      options={teamOptions}
                      value={formProps.values.teamIds}
                      renderValue={(teamIds) => <ChipInputTeamList teamIds={teamIds} teams={teams} />}
                      onChange={({ target: { value: teamIds } }) => formProps.setFieldValue('teamIds', teamIds)}
                    />
                  )}
                  {teams.length === 0 && (
                    <Box fullWidth>
                      <Box bottom spacing={smallSpacing}>
                        <FigmaTextForStrategyModel textKey={Texts.rightPanelObjectiveEditObjectiveInvolvedTeamsLabel} />
                      </Box>
                      <Box alignSelf="center">
                        <EditObjectiveDialogAddTeamButton
                          onCreatedTeam={(team) => formProps.setFieldValue('teamIds', [team._id])}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>

                {showMore && shouldShowStrategicPillarField(formProps.values, org) && (
                  <Box bottom spacing={smallSpacing} fullWidth>
                    <SelectFieldWithLabelMulti
                      labelTextKey={Texts.rightPanelEditStrategicPillarDescriptionLabel}
                      options={strategicPillars.map(({ _id, name }) => ({ key: name, value: _id }))}
                      value={formProps.values.strategicPillarIds}
                      renderValue={(strategicPillarIds) => (
                        <ChipInputStrategicPillarsList strategicPillarIds={strategicPillarIds} />
                      )}
                      onChange={({ target: { value: strategicPillarIds } }) =>
                        formProps.setFieldValue('strategicPillarIds', strategicPillarIds)
                      }
                    />
                  </Box>
                )}
                {((showMore && topObjectiveOptions.length > 0) || !!initialValues.parentObjectiveId) && (
                  <Box bottom spacing={smallSpacing} fullWidth>
                    <SelectFieldWithTextKeyAndLabel
                      placeholderTextKey={Texts.selectComponentHelperText}
                      labelTextKey={Texts.rightPanelObjectiveEditObjectiveLinkedObjectiveLabel}
                      options={[
                        { key: 'None', value: '' },
                        ...getSelectParentObjectiveOptions(formProps.values, allObjectives)
                      ]}
                      value={formProps.values.parentObjectiveId || ''}
                      onChange={({ target: { value: parentObjectiveId } }) =>
                        onChangeParentObjective(formProps, parentObjectiveId)
                      }
                    />
                  </Box>
                )}
                {(showMore || initialValues.leadUserId) && (
                  <Box bottom width="50%">
                    <LeadSelectFieldWithLabel
                      value={formProps.values.leadUserId || ''}
                      onChangeLeadUserId={(leadUserId) => onChangeLeadUserId(leadUserId, formProps, state)}
                    />
                  </Box>
                )}
                <Box fullWidth bottom>
                  <EditObjectiveDialogShowMore expanded={showMore as boolean} setExpanded={setShowMore} />
                </Box>
                <EditObjectiveDialogHeaderWithLine
                  textKey={Texts.rightPanelObjectiveEditObjectiveIndicatorListHeader}
                  RightComponent={() => (
                    <Box onClick={onClickChatGPT} pointer>
                      <Tooltip title={getFigmaText(Texts.aiKeyResultSuggestions)}>
                        <Box>
                          {showChatGPTSuggestions && <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />}
                          {!showChatGPTSuggestions && <FigmaImageContainer imageKey={Images.chatGpt} />}
                        </Box>
                      </Tooltip>
                    </Box>
                  )}
                />

                {showChatGPTSuggestions && (
                  <EditObjectiveDialogKeyResultRowSuggestions
                    objective={values as Objective}
                    onClickSuggestion={onClickSuggestion}
                  />
                )}
                {!showChatGPTSuggestions && (
                  <EditObjectiveDialogIndicators
                    objective={values}
                    onClickAddKeyResult={onClickAddKeyResult}
                    onClickKeyResult={onClickKeyResult}
                  />
                )}
              </Box>
              <DrawerFooterButtons
                onClickCancel={() => onClose(formProps)}
                onClickDelete={(objective?._id && onClickDeleteObjective) || undefined}
                onClickContinue={() => formProps.handleSubmit()}
                style={{ visibility: 'hidden' }}
              />
              <DrawerFooterButtons
                onClickCancel={() => onClose(formProps)}
                onClickDelete={(objective?._id && onClickDeleteObjective) || undefined}
                onClickContinue={() => formProps.handleSubmit()}
                style={{ position: 'fixed', bottom: 0 }}
              />
            </EditObjectiveDialogContainer>
          )
        }}
      </Formik>
    </>
  )
}

export const EditObjectiveDialogContainer = styled(Box)`
  width: ${DRAWER_WIDTH_STRING};
`

const EditObjectiveDialogStyledCloseIconContainer = styled.div`
  position: absolute;
  top: ${tinySpacing};
  right: ${tinySpacing};
  ${LinkStyles}
`

export const DashedLine = styled(Box)`
  border-top: 1px dashed ${Colors.neutralVariant80};
`

export default EditObjectiveDialog

function EditObjectiveDialogAddTeamButton({ onCreatedTeam }: { onCreatedTeam: (team: Team) => unknown }) {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Button
        textKey={Texts.applicationMenuAddFirstTeamButtonText}
        buttonType="empty_state"
        onClick={() => setShowModal(true)}
      />
      {showModal && <ModalCreateEditTeam onClose={() => setShowModal(false)} onCreatedTeam={onCreatedTeam} />}
    </>
  )
}

export function EditObjectiveDialogAddIndicatorButton({ onClick }: { onClick: () => void }) {
  return (
    <Button buttonType="empty_state" textKey={Texts.addIndicatorButtonText} onClick={onClick} spacing={smallSpacing}>
      <FigmaTextForStrategyModel textKey={Texts.addIndicatorButtonText} />
    </Button>
  )
}

export function EditObjectiveDialogAddIndicatorEmptyStateButton({ onClick }: { onClick: () => void }) {
  return (
    <Button buttonType="empty_state" textKey={Texts.addTheFirstIndicator} onClick={onClick} spacing={smallSpacing}>
      <FigmaTextForStrategyModel textKey={Texts.addTheFirstIndicator} />
    </Button>
  )
}
