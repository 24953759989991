import { FormikProps } from 'formik'
import { ActivityBoardKanbanProps } from '../components/ActivityBoardKanban'
import { InitialValues } from '../components/EditActivityDialogForm'
import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import { ActivityStatusInfo } from '../ygdrasil/libs/ActivityHelper'
import { groupBy } from '../ygdrasil/libs/Common'
import { getJiraIssueUrl } from '../ygdrasil/libs/JiraHelper'
import { getRankForActivityBoard, inRankOrder } from '../ygdrasil/libs/RankHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { Activity, KeyResult } from '../ygdrasil/types/types'

export type ActivityBoardColumnType<T> = {
  title: string
  items: T[]
}

type ActivityBoardColumnsType<T> = {
  [key: string]: ActivityBoardColumnType<T>
}

export const ActivityBoardColumns: ActivityBoardColumnsType<Item> = Object.values(ActivityStatus).reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      title: getFigmaText(ActivityStatusInfo[key].textKey as AntiloopTextType),
      items: []
    }
  }),
  {} as ActivityBoardColumnsType<Item>
)

export const getActivityBoardColumns = (items: Item[]) => {
  const _activities = groupBy(
    items.map((a) => ({ ...a, status: (a.data.data as Activity).status || ActivityStatus.TODO })),
    'status'
  )

  return Object.keys(_activities).reduce(
    (acc, key) => ({
      ...acc,
      [key]: { title: ActivityBoardColumns[key].title, items: _activities[key].sort(inRankOrder) }
    }),
    ActivityBoardColumns as ActivityBoardColumnsType<Item>
  )
}

type Functions<T> = {
  setColumns: (any) => void
  onDragEnd: ({ result, ordo }: { result; ordo: number }) => unknown
}

export function onDragEnd<T>(result, columns, { setColumns, onDragEnd }: Functions<T>) {
  if (!result.destination) return
  const { source, destination } = result

  const sourceItem = columns[source.droppableId].items[source.index]
  const nextItem = columns[destination.droppableId].items[destination.index + 1]
  const destinationItem = columns[destination.droppableId].items[destination.index]
  const previousItem = columns[destination.droppableId].items[destination.index - 1]

  const ordo = getRankForActivityBoard(
    source.index,
    destination.index,
    sourceItem,
    destinationItem,
    previousItem,
    nextItem
  )

  onDragEnd({ result, ordo })

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.items]
    const destItems = [...destColumn.items]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems
      }
    })
  } else {
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    })
  }
}

export function getActivityItems({ item, leadUserId }: ActivityBoardKanbanProps): Item[] {
  if (item.data.searchType === 'keyResult')
    return item.children.filter((child) => {
      if (child.data.searchType !== 'activity') return false
      if (!!leadUserId && child.data.data.leadUserId !== leadUserId) return false
      return true
    })

  if (item.data.searchType === 'objective')
    return item.children
      .filter((item) => item.data.searchType === 'keyResult')
      .reduce((a, child) => a.concat(getActivityItems({ item: child, leadUserId })), [] as Item[])
  return []
}

export function shouldShowEmptyState(item: Item, columns: ActivityBoardColumnsType<Item>) {
  return (
    item.data.searchType === 'objective' &&
    Object.keys(columns).reduce((acc, status) => acc + columns[status].items.length, 0) === 0
  )
}

export const getKeyResultParentForItem = (item: Item): KeyResult | undefined => {
  const { data, children } = item
  if (data.searchType === 'activity') return
  if (data.searchType === 'keyResult') return item.data.data as KeyResult

  if (children.length > 1) return
  if (children.length === 0) return

  const child = children[0]

  return child.data.searchType === 'keyResult' ? (child.data.data as KeyResult) : undefined
}

export const onClickActivityName = (activity, org) => {
  const hasUrl = !!activity.url || !!activity.jiraIssueKey
  if (!!activity.jiraIssueKey)
    return org && window.open(getJiraIssueUrl(activity.jiraIssueKey as string, org.jiraConfig), '_blank')
  return hasUrl && window.open(activity.url, '_blank')
}

export const mapActivityStatusItems = (formProps: FormikProps<InitialValues>) =>
  Object.values(ActivityStatus).map((status, key) => {
    const isSelected = formProps.values.status === status

    let activityStatusInfo = ActivityStatusInfo[status] || ActivityStatusInfo[ActivityStatus.TODO]

    if (isSelected === false) activityStatusInfo = { ...activityStatusInfo, ...ActivityStatusInfo.isNotSelected }

    return { activityStatusInfo, status }
  })
