// @flow
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { minBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { PROGRESS_BAR_HEIGHT } from '../libs/HardCodedSizes'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { getProgressText } from '../ygdrasil/libs/KeyResultHelper'
import { DeprecatedTexts } from '../ygdrasil/libs/Texts'
import Box from './Box'
import { Flexbox } from './StyledComponents'

type Props = LinearProgressProps & { value: number; backgroundColor?: string; height?: number }
export default function LinearProgressWithLabel(props: Props) {
  const { backgroundColor, value, height, ...linearProgressProps } = props
  const width = `${value}%`
  const text = getProgressText(value)
  return (
    <Box direction="row" align="center" fullWidth>
      <Box position="relative" align="center" direction="row" fullWidth>
        <Box position="absolute" fullWidth>
          <GreyLine />
        </Box>
        <Box width={width}>
          <StyledLinearProgress
            backgroundColor={backgroundColor}
            variant="determinate"
            value={100}
            height={height}
            {...linearProgressProps}
          />
        </Box>
      </Box>
      <Box left spacing={smallSpacing}>
        <FigmaText textKey={DeprecatedTexts.objectivesListProgressNumber} text={text} />
      </Box>
    </Box>
  )
}

export const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (name) => ['variant'].includes(name as string) || isPropValid(name)
})<{
  backgroundColor?: string
  height?: number
}>`
  background-color: ${Colors.neutral90};
  height: ${({ height }) => height || PROGRESS_BAR_HEIGHT}px;
  border-radius: ${minBorderRadius};
  & .MuiLinearProgress-barColorPrimary {
    background-color: ${({ backgroundColor }) => backgroundColor || Colors.primaryBase};
  }
`

const GreyLine = styled(Flexbox)`
  height: 1px;
  width: 100%;
  background: ${Colors.neutralVariant80};
`
