import { _getDefaultLanguageCode, _getLanguageCode } from '../../hooks/useLanguage'
import { Language } from '../types/Antiloop'
import { en, LANGUAGES } from './Consts'

export const getLanguageCode = (): Language => _getLanguageCode() || getDefaultLanguageCode()

export const useLanguage = getLanguageCode

export function getDefaultLanguageCode(): Language {
  const defaultLanguageCode = _getDefaultLanguageCode()
  if (!defaultLanguageCode) return en
  if (LANGUAGES.includes(defaultLanguageCode)) return defaultLanguageCode
  return en
}
