import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { NAME } from '../libs/MyActionsMyToDosHelper'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import Colors from '../ygdrasil/libs/Colors'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { createTodo, removeTodo, updateTodo } from '../ygdrasil/libs/DBApiHandler'
import Images from '../ygdrasil/libs/Images'
import { toBaseObject } from '../ygdrasil/libs/Mapper'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { Todo } from '../ygdrasil/types/types'
import Box from './Box'
import CheckboxFormik from './CheckboxFormik'
import FigmaImageContainer from './FigmaImageContainer'
import { MyActionsEditTodoFormName } from './MyActionsEditTodoFormName'
import Tooltip from './Tooltip'

const TOMORROW = dayjs().add(1, 'day').format(YYYY_MM_DD)
const TODAY = dayjs().format(YYYY_MM_DD)

const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation(getFigmaText(Texts.genericInputErrorText))
})

export function MyActionsMyTodosEditTodoForm({ todo }: { todo?: Todo }) {
  const { state } = useAppState()
  const initialValues = {
    name: todo?.name || '',
    done: todo?.done || false
  }

  const onBlurName = (e, formProps: FormikProps<typeof initialValues>) => {
    formProps.handleBlur(NAME)(e)

    return formProps.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) return Promise.resolve(errors)
      onEditTodo(formProps.values)
    })
  }

  function onEditTodo(values: typeof initialValues) {
    updateTodo({
      ...toBaseObject(state),
      date: dayjs().format(YYYY_MM_DD),
      ...todo,
      ...values,
      organizationId: state.selectedOrganizationId,
      userId: state.user._id
    })
  }

  const moveTodoTooltipTextKey =
    todo?.date === TODAY ? Texts.myTodosActionPushToTommorow : Texts.myTodosActionPushToToday

  const onClickCopyTodoToToday = (t: Todo) => moveTodo(t)
  const onClickCopyTodoToTomorrow = (t: Todo) => moveTodo(t, TOMORROW)

  const onClickCopyTodo = todo?.date === TODAY ? onClickCopyTodoToTomorrow : onClickCopyTodoToToday

  const moveTodo = (t: Todo, date: string = TODAY) =>
    createTodo(
      {
        name: t.name,
        done: t.done,
        date,
        organizationId: state.selectedOrganizationId,
        userId: state.user._id
      },
      state
    ).then(() => removeTodo(t))

  const onRemoveTodo = (todo: Todo) => removeTodo(todo)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => Promise.resolve()}
      enableReinitialize
      validationSchema={ValidationSchema}
    >
      {(formProps) => {
        return (
          <Container fullWidth direction="row" align="center" justify="space-between" fullPadding spacing={tinySpacing}>
            <Box direction="row" fullWidth align="center">
              <Box right spacing={smallSpacing}>
                <CheckboxFormik
                  formProps={formProps}
                  name="done"
                  onChange={(done) => onEditTodo({ ...formProps.values, done })}
                  disabled={Object.keys(formProps.errors).length > 0}
                />
              </Box>
              <Box bottom spacing={minSpacing} fullWidth>
                <MyActionsEditTodoFormName
                  formProps={formProps}
                  onBlurName={onBlurName}
                  isEditing={!!todo && !todo._id}
                />
              </Box>
            </Box>
            <Box direction="row" align="center">
              <Box right spacing={smallSpacing}>
                <Tooltip title={getFigmaText(moveTodoTooltipTextKey)}>
                  <Box fullPadding spacing={minSpacing} pointer onClick={() => todo && onClickCopyTodo(todo)}>
                    <FigmaImageContainer imageKey={Images.loginIcon} />
                  </Box>
                </Tooltip>
              </Box>
              <Tooltip title={getFigmaText(Texts.myTodosActionDeleteToDo)}>
                <Box
                  pointer
                  onClick={() => {
                    todo && onRemoveTodo(todo)
                  }}
                  fullPadding
                  spacing={minSpacing}
                >
                  <FigmaImageContainer imageKey={Images.deleteIcon} />
                </Box>
              </Tooltip>
            </Box>
          </Container>
        )
      }}
    </Formik>
  )
}

const Container = styled(Box)`
  :hover {
    background-color: ${Colors.neutral95};
  }
`
