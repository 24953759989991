import styled from '@emotion/styled'
import React from 'react'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { uploadFile } from '../libs/uploadFile'
import FigmaText from '../ygdrasil/components/FigmaText'
import { UploadFolder } from '../ygdrasil/enums/UploadFolder'
import { useStorageUrl } from '../ygdrasil/hooks/useStorageUrl'
import Colors from '../ygdrasil/libs/Colors'
import { COMPANY_LOGO_SIZE_BIG } from '../ygdrasil/libs/Consts'
import { updateOrganization } from '../ygdrasil/libs/DBApiHandler'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import AddButton from './AddButton'
import Box from './Box'
import Button from './Button'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'
import Modal, { ModalProps } from './Modal'

const FILE_INPUT_REF = 'filInputRef'

type Props = ModalProps
export function ModalAddLogo(props: Props) {
  const { onClose } = props
  const [logoPath, setLogoPath] = React.useState('')
  const { state } = useAppState()
  const { data: o } = useOrganization(state)
  const { url, setUrl } = useStorageUrl(o?.logoPath)

  const onFileChange = (file: Blob | undefined) => {
    if (!file) return

    uploadFile(file, UploadFolder.ORGANIZATION_LOGOS)
      .then(({ url, bucketPath }) => {
        setLogoPath(bucketPath)
        setUrl(url)
      })
      .catch((err) => captureAndNotifyError(err))
  }

  const onSave = () => {
    o && updateOrganization({ ...o, logoPath })
    onClose()
  }

  return (
    <Modal {...props}>
      <Box fullWidth>
        <Box top bottom>
          <FigmaText textKey={Texts.dialogEditLogoHeader} />
        </Box>
        <Box direction="row">
          <LogoContainer align="center" justify="center">
            {url && <img src={url} alt="Uploaded Company Logo" />}
          </LogoContainer>
          <Box left bottom>
            <Box bottom>
              <FigmaTextWithStyleOverrides textKeys={{ en: Texts.dialogEditLogoInfoRecommendedSize }} />
              <FigmaText textKey={Texts.dialogEditLogoInfoRecommendedValue} />
            </Box>
            <Box bottom>
              <Box spacing={bigSpacing}>
                <label htmlFor={FILE_INPUT_REF}>
                  <AddButton labelTextKey={Texts.dialogEditLogoUploadFileButtonText} onClick={() => ({})} />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={FILE_INPUT_REF}
                  onChange={(event) => {
                    return onFileChange(event.currentTarget.files?.[0])
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box direction="row" align="center" justify="flex-end" fullWidth>
          <Box right spacing={tinySpacing}>
            <Button onClick={onClose} textKey={Texts.cancelButtonText} buttonType="secondary" spacing={smallSpacing} />
          </Box>
          <Button spacing={smallSpacing} onClick={onSave}>
            <FigmaText textKey={Texts.saveButtonText} />
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

const BORDER_WIDTH = 1
const LogoContainer = styled(Box)`
  background: ${Colors.surface};
  border: ${BORDER_WIDTH}px solid ${Colors.primary90};
  height: ${COMPANY_LOGO_SIZE_BIG + 2 * BORDER_WIDTH}px;
  width: ${COMPANY_LOGO_SIZE_BIG + 2 * BORDER_WIDTH}px;
`
