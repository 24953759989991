/* eslint-disable react-hooks/rules-of-hooks */
import invariant from 'invariant'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import { bigSpacing } from '../enums/Spacings'
import useAppState, { DEFAULT_STATE } from '../hooks/useAppState'
import { useElligebleOrganizationsForFirebaseUser } from '../hooks/useElligebleOrganizationsForFirebaseUser'
import useIsMobile from '../hooks/useIsMobile'
import useQueryParams from '../ygdrasil/hooks/useQueryParams'
import { logOnboardingEvent } from '../ygdrasil/libs/FirebaseHelper'
import * as OnboardingHelper from '../libs/OnboardingHelper'
import {
  getElligbleOrgs,
  getStepAfterName,
  OnboardingStateTransitionType,
  sendOnboardingAnalyticsEvent
} from '../libs/OnboardingPageHelper'
import { captureException } from '../ygdrasil/libs/SentryHelperDeprecated'
import RoutePath from '../ygdrasil/libs/RoutePath'
import { OnboardingState, OnboardingStep, OnboardingViewProps, OnboardingViewsType } from '../ygdrasil/types/Onboarding'
import Box from './Box'
import Loader from './Loader'
import OnboardingAddObjectives from './OnboardingAddObjectives'
import OnboardingAddObjectivesMobile from './OnboardingAddObjectivesMobile'
import OnboardingDone from './OnboardingDone'
import OnboardingName from './OnboardingName'
import OnboardingNameMobile from './OnboardingNameMobile'
import OnboardingTellUs from './OnboardingTellUs'
import OnboardingTellUsMobile from './OnboardingTellUsMobile'
import OnboardingTermsMobile from './OnboardingTermsMobile'
import { FirebaseUser } from '../ygdrasil/types/types'

export const DEFAULT_ONBOARDING_STATE = {
  data: {
    nameOfUser: '',
    name: '',
    vision: '',
    countryCode: '',
    howManyCollegues: '',
    user: {} as any
  },
  step: OnboardingStep.NAME
}

const OnboardingViewsDesktop: OnboardingViewsType = {
  [OnboardingStep.NAME]: OnboardingName,
  [OnboardingStep.TELL_US]: OnboardingTellUs,
  [OnboardingStep.ADD_OBJECTIVES]: OnboardingAddObjectives,
  [OnboardingStep.DONE]: OnboardingDone,
  [OnboardingStep.TERMS]: OnboardingTermsMobile
}

const OnboardingViewsMobile: OnboardingViewsType = {
  [OnboardingStep.NAME]: OnboardingNameMobile,
  [OnboardingStep.TELL_US]: OnboardingTellUsMobile,
  [OnboardingStep.ADD_OBJECTIVES]: OnboardingAddObjectivesMobile,
  [OnboardingStep.DONE]: OnboardingDone, // OnboardingDoneMobile // TODO, reenable mobile step when you have a good stop wall
  [OnboardingStep.NAME]: OnboardingNameMobile,
  [OnboardingStep.TERMS]: OnboardingTermsMobile
}

const OnboardingMachine = ({ firebaseUser }: { firebaseUser: FirebaseUser }) => {
  const { state: appState, setState } = useAppState()
  const history = useHistory()
  const isMobile = useIsMobile()
  const OnboardingViews = isMobile ? OnboardingViewsMobile : OnboardingViewsDesktop
  const [onboardingState, setOnboardingState] = React.useState<OnboardingState>()

  const { uid } = firebaseUser
  invariant(uid, 'User is not logged in')

  const { data: orgs = [], isLoading: isLoadingOrganizations } = useElligebleOrganizationsForFirebaseUser(firebaseUser)

  useEffect(() => {
    if (!firebaseUser) return
    if (isLoadingOrganizations) return

    const state: OnboardingState = {
      ...DEFAULT_ONBOARDING_STATE,
      firebaseUser,
      data: { ...DEFAULT_ONBOARDING_STATE.data, user: firebaseUser }
    }

    setOnboardingState(state)
  }, [firebaseUser, isLoadingOrganizations])

  if (!onboardingState)
    return (
      <Box fullWidth fullPadding spacing={bigSpacing} align="center">
        <Loader />
      </Box>
    )

  const onPressContinue = (state: OnboardingState) => {
    const elligebleOrgs = getElligbleOrgs(firebaseUser, orgs)

    return Promise.resolve().then((): any => {
      invariant(firebaseUser, 'Cant find firebase user')
      switch (true) {
        case state.step === OnboardingStep.NAME:
          return setOnboardingState({ ...state, step: getStepAfterName(firebaseUser, elligebleOrgs) })
        case state.step === OnboardingStep.ADD_OBJECTIVES && isMobile:
          return setOnboardingState({ ...state, step: OnboardingStep.TERMS })
        case state.step === OnboardingStep.DONE:
        case state.step === OnboardingStep.TERMS:
        case state.step === OnboardingStep.ADD_OBJECTIVES:
          return OnboardingHelper.onboardUser(state, firebaseUser, DEFAULT_STATE)
            .then((newState) => setState({ ...appState, ...newState }))
            .then(() => history.push(RoutePath.ROOT))
            .then(() => logOnboardingEvent(AnalyticsEvent.ONBOARDING_COMPLETE, state))
        default:
          return setOnboardingState({ ...state, step: state.step + 1 })
      }
    })
  }

  const onboardingViewProps: React.PropsWithoutRef<OnboardingViewProps> = {
    state: onboardingState,
    onPressContinue: (state: OnboardingState) =>
      onPressContinue(state)
        .then(() => sendOnboardingAnalyticsEvent(state, OnboardingStateTransitionType.NEXT))
        .catch(captureException),
    onPressSkip: (state: OnboardingState) =>
      onPressContinue(state)
        .then(() => sendOnboardingAnalyticsEvent(state, OnboardingStateTransitionType.SKIP))
        .catch(captureException),
    onClickBack: (state) =>
      Promise.resolve()
        .then(() => {
          switch (state.step) {
            case OnboardingStep.TELL_US:
              return { ...state, step: OnboardingStep.NAME }
            case OnboardingStep.ADD_OBJECTIVES:
              return { ...state, step: OnboardingStep.TELL_US }
            case OnboardingStep.TERMS:
              return { ...state, step: OnboardingStep.ADD_OBJECTIVES }

            default:
              return state
          }
        })
        .then((state) => setOnboardingState(state))
  }

  const component = OnboardingViews[onboardingViewProps.state.step]
  invariant(component, `Cant find Onboarding Step for %s`, onboardingViewProps.state.step)

  return React.createElement(component, onboardingViewProps)
}

export default OnboardingMachine
