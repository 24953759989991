import { smallSpacing } from '../enums/Spacings'
import { openChat } from '../libs/TidioHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { InfoIcon } from './Icons'

export function EditDatasourceMachineViewFooter() {
  return (
    <Box fullWidth direction="row" align="center" justify="flex-end">
      <InfoIcon />
      <Box left spacing={smallSpacing} link onClick={openChat}>
        <FigmaText textKey={Texts.needHelpInDialogText} />
      </Box>
    </Box>
  )
}
