import EventCategories from '../enums/EventCategories'
import { createEvent } from '../libs/DBApiHandler'
import { EventParams, mapEvent, OurEvent } from '../libs/EventHelper'
import { useOrganization } from '../libs/QueryHooks'

import useAppState, { State, useUser } from '../../hooks/useAppState'
import { captureException } from '../libs/SentryHelperDeprecated'

type LogEventFunctions = {
  onEventLogged: <T>(event: OurEvent<T>) => unknown
}

export type UseLogEventFunction = (
  catagory: EventCategories,
  params?: Partial<EventParams>,
  functions?: LogEventFunctions
) => void

export default function useLogEvent(): UseLogEventFunction {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const user = useUser()

  return (eventCategory: EventCategories, params?: Partial<EventParams>, functions?: LogEventFunctions) => {
    if (!org) return
    setTimeout(() =>
      _logEvent(eventCategory, { ...params, org, user }, state).then((event) => functions?.onEventLogged(event as any))
    )
  }
}

export const _logEvent = (eventCategory: EventCategories, params: EventParams, state: State) =>
  createEvent(mapEvent(eventCategory, { ...params }), state).catch(captureException)
