import { InputAdornment } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { defaultSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { SEARCH_INPUT_SIZE } from '../libs/HardCodedSizes'
import * as Common from '../ygdrasil/libs/Common'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Chip from './Chip'
import { SearchIcon } from './Icons'
import Loader from './Loader'

type Props = {
  value: string[]
  inputValue: string
  onDelete: (value: string) => unknown
  onUpdateInput: (value: any) => any
  debounce?: number
  multiple?: boolean
}

const SEARCH_TIMEOUT = 500

export default function AutoComplete(props: Props) {
  const { state } = useAppState()
  const { searchText } = state.searchState
  const { value, inputValue, onUpdateInput, onDelete, debounce = 0, multiple = true } = props
  const [inputValueState, setInputValueState] = useState(inputValue)
  const _onUpdateInput = Common.debounce(onUpdateInput, debounce)
  const [isSearching, setIsSearching] = useState(false)

  const onChange = (e) => {
    const { value = '' } = e.target
    setInputValueState(value)

    setIsSearching(true)
    setTimeout(() => setIsSearching(false), SEARCH_TIMEOUT)

    _onUpdateInput(e)
  }

  useEffect(() => {
    if (searchText === '') setInputValueState('')
  }, [searchText])

  return (
    <Autocomplete
      multiple={multiple}
      freeSolo
      inputValue={inputValueState}
      value={value}
      options={value}
      PopperComponent={() => null}
      disableClearable
      getOptionLabel={(option) => option}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            text={option}
            {...getTagProps({ index })}
            key={index}
            handleDelete={() => onDelete(option)}
            textKey={Texts.chipInputTextArchived}
          />
        ))
      }
      style={{ minWidth: SEARCH_INPUT_SIZE }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onChange}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">{isSearching ? <IsSearchingLoader /> : <SearchIcon />}</InputAdornment>
            )
          }}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 }
          }}
        />
      )}
    />
  )
}

export function IsSearchingLoader() {
  return (
    <Box spacing={tinySpacing} right>
      <Loader size={defaultSpacing} />
    </Box>
  )
}
