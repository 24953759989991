import FigmaTexts from '../assets/i18n/figma.json'
import Svtexts from '../assets/i18n/sv.json'
import { AntiloopTextType, Language } from '../types/Antiloop'
import { sv } from './Consts'
import { getLanguageCode } from './useLanguage'
const XXX = 'XXX'
export type Printable = string | number | undefined

export type Options = {
  boldParams?: boolean
  boldParamsIndex?: number
  splitCharacter?: string
}

export const getFigmaText = (textKey: AntiloopTextType, language: Language = getLanguageCode()): string => {
  switch (language) {
    case sv:
      return textKey?.texts?.sv || Svtexts.figma[textKey.name] || ''
    default:
      return textKey?.texts?.eng || FigmaTexts.figma[textKey.name] || textKey.characters || ''
  }
}

export const formatText = (
  text: string,
  params: Printable[],
  { boldParams, boldParamsIndex, splitCharacter = XXX }: Options = {}
): string => {
  const _params = params
    .map((p) => p?.toString() || '')
    .map((p, i) => {
      if (boldParams) return makeBold(p)
      if (boldParamsIndex === i) return makeBold(p)
      return p
    })

  return text
    .split(splitCharacter)
    .reduce((a, b, i) => ({ res: a.res + b + (_params[a.i] || '').toString(), i: i + 1 }), { res: '', i: 0 }).res
}

export function makeBold(p: string): string {
  return `<b>${p}</b>`
}
