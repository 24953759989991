import React from 'react'
import { defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { DELETE_OBJECTIVE_MODAL_MAX_WIDTH } from '../libs/HardCodedSizes'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaText from '../ygdrasil/components/FigmaText'
import Modal, { ModalProps } from './Modal'

type Props = { onClickCloseDialog } & ModalProps
export function ModalCloseEditDialog(props: Props) {
  const { onClickCloseDialog, onClose: onCloseModal } = props

  const _onClickCloseDialog = () => {
    onCloseModal()
    onClickCloseDialog()
  }

  const onClickCancel = onCloseModal
  return (
    <Modal {...props}>
      <Box fullWidth style={{ maxWidth: DELETE_OBJECTIVE_MODAL_MAX_WIDTH }}>
        <Box fullWidth>
          <FigmaText textKey={Texts.dialogCloseConfirmationHeader} />
        </Box>
        <Box fullWidth>
          <FigmaText textKey={Texts.dialogCloseConfirmationText} />
        </Box>
        <Box direction="row" align="center" justify="flex-end" fullWidth top>
          <Button
            onClick={onClickCancel}
            textKey={Texts.cancelButtonText}
            buttonType="secondary"
            spacing={tinySpacing}
          />
          <Box left spacing={tinySpacing}>
            <Button onClick={_onClickCloseDialog} spacing={defaultSpacing} textKey={Texts.okButtonText}></Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
