import styled from '@emotion/styled'
import { FormHelperText, InputAdornment } from '@mui/material'
import { Field, FieldAttributes, FormikProps, getIn } from 'formik'
import React from 'react'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { TextFieldOutlined } from './TextFieldOutlined'

export type ErrorComponentProps = { formProps: FormikProps<any>; name: string }

export type TextFieldFormikProps = FieldAttributes<any> & {
  name: string
  formProps: FormikProps<any>
  labelText?: string
  component?: React.ReactNode
  placeholderTextKey?: AntiloopTextType
  adormentTextKey?: AntiloopTextType
  isMobile?: boolean
  ErrorComponent?: React.FC<ErrorComponentProps>
  isWhite?: boolean
  autoFocus?: boolean
  rows?: number
  InputProps?: any
  endAdornmentComponent?: React.ReactNode
}

const TextFieldFormik: React.FC<TextFieldFormikProps> = ({
  name,
  fullWidth,
  placeholderTextKey,
  adormentTextKey,
  onChange,
  value,
  onBlur,
  className,
  formProps,
  defaultValue,
  children,
  select,
  type,
  onFocus,
  disabled,
  component,
  onKeyDown,
  multiline,
  rows,
  isMobile = false,
  isWhite = false,
  autoFocus = false,
  InputProps,
  labelText,
  ErrorComponent = GenericErrorComponent,
  endAdornmentComponent
}) => {
  const error = getError(formProps, name)

  return (
    <StyledWrapper fullWidth={fullWidth}>
      <Field
        select={select}
        component={component || TextFieldOutlined}
        error={error}
        size="small"
        label={(labelText && getFigmaText(labelText)) || ''}
        fullWidth={fullWidth}
        value={value || getIn(formProps.values, name)}
        className={className}
        disabled={disabled}
        placeholder={(!!placeholderTextKey && !value && getFigmaText(placeholderTextKey)) || ''}
        InputProps={
          InputProps || {
            endAdornment: (adormentTextKey || endAdornmentComponent) && (
              <>
                {!!adormentTextKey && (
                  <InputAdornment position="end">
                    <FigmaText textKey={adormentTextKey} />
                  </InputAdornment>
                )}
                {!!endAdornmentComponent && <InputAdornment position="end">{endAdornmentComponent}</InputAdornment>}
              </>
            )
          }
        }
        onChange={onChange || formProps.handleChange(name)}
        onBlur={onBlur || formProps.handleBlur(name)}
        defaultValue={defaultValue}
        name={name}
        type={type}
        onKeyDown={onKeyDown}
        multiline={multiline}
        isMobile={isMobile}
        isWhite={isWhite}
        onFocus={onFocus}
        autoFocus={autoFocus}
        rows={rows}
      >
        {children}
      </Field>
      {error && <ErrorComponent formProps={formProps} name={name} />}
    </StyledWrapper>
  )
}
const StyledWrapper = styled(Box)<{ fullWidth?: boolean }>`
  ${(props) => props.fullWidth && 'width: 100%;'}
`

export default TextFieldFormik

export function getError(formProps: any, name: any) {
  return !!formProps.errors && getIn(formProps.touched, name) && getIn(formProps.errors, name)
}

export function GenericErrorComponent(props: TextFieldFormikProps) {
  return <FormHelperText error>{getIn(props.formProps.errors, props.name)}</FormHelperText>
}
