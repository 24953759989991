import React from 'react'
import { minSpacing } from '../enums/Spacings'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaText from '../ygdrasil/components/FigmaText'
import { RoadmapObjectiveAddObjectiveButtonContainer } from './RoadmapObjectiveAddObjectiveButtonContainer'
import useAppState from '../hooks/useAppState'

export default function RoadmapEmptyState() {
  const { state } = useAppState()
  return (
    <Box fullWidth direction="row" align="center">
      <Box fullPadding spacing={minSpacing} width="25%">
        <RoadmapObjectiveAddObjectiveButtonContainer fullWidth />
      </Box>
      <Box width="50%" align="center" visibility={!!state.selectedTeamId ? 'hidden' : 'visible'}>
        <FigmaText textKey={Texts.roadmapEmptyStateText} />
      </Box>
      <Box width="25%" />
    </Box>
  )
}
