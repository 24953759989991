import React, { useEffect } from 'react'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import { OurDrawer } from './Drawers'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import EditIndicatorDialog from './EditKeyResultDialog'

type OpenEditKeyResultDialogEventParams = {
  objective: Objective
  keyResult?: Partial<KeyResult>
}

export const openEditKeyResultDrawer = (data: OpenEditKeyResultDialogEventParams) =>
  eventEmitter.emit(Events.OPEN_EDIT_KEY_RESULT_DIALOG, data)

export default function DrawerGlobalEditKeyResultDialog() {
  const [visible, setVisible] = React.useState(false)
  const [objective, setObjective] = React.useState<Objective>()

  const [keyResult, setKeyResult] = React.useState<Partial<KeyResult>>()
  const onCloseEditObjectiveDrawer = () => {
    setObjective(undefined)
    setKeyResult(undefined)
    setVisible(false)
  }

  useEffect(() => {
    eventEmitter.on(
      Events.OPEN_EDIT_KEY_RESULT_DIALOG,
      ({ objective, keyResult }: OpenEditKeyResultDialogEventParams) => {
        setObjective(objective)
        setKeyResult(keyResult)
        setVisible(true)
      }
    )

    return () => {
      eventEmitter.removeAllListeners(Events.OPEN_EDIT_KEY_RESULT_DIALOG)
    }
  }, [])

  if (!objective) return null

  return (
    <OurDrawer
      open={visible}
      content={<EditIndicatorDialog onClose={onCloseEditObjectiveDrawer} objective={objective} keyResult={keyResult} />}
      onClose={onCloseEditObjectiveDrawer}
    />
  )
}
