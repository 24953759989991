import { smallSpacing } from '../enums/Spacings'
import { withStopProgagation } from '../libs/HtmlHelper'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { MenuArrowDownward, MenuArrowUpward } from './Icons'
import { TextWithLineRight } from './TextWithLine'

type Props = {
  expanded: boolean
  setExpanded: (expanded: boolean) => unknown
  expandedTextKey?: AntiloopTextType
  textKey?: AntiloopTextType
}
export function EditObjectiveDialogShowMore({
  expanded,
  setExpanded,
  textKey = Texts.objectiveExpandButtonButtonText,
  expandedTextKey = Texts.objectiveCollapseButtonText
}: Props) {
  if (expanded) textKey = expandedTextKey
  const Icon = expanded ? MenuArrowUpward : MenuArrowDownward

  const onClick = withStopProgagation(() => setExpanded(!expanded))
  return (
    <Box fullWidth direction="row" align="center" link onClick={onClick}>
      <Icon />
      <Box left fullWidth spacing={smallSpacing}>
        <TextWithLineRight textKey={textKey} />
      </Box>
    </Box>
  )
}
