import dayjs from 'dayjs'
import { isOverDue } from '../ygdrasil/libs/ActivityHelper'
import { DD_MMM } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import { Activity } from '../ygdrasil/types/types'
import { NoWrapFigmaText } from './StyledComponents'

export function FigmaTextForDueDate({ activity }: { activity: Activity }) {
  const { dueDate } = activity
  const textKey = isOverDue(activity) ? Texts.cssActivityCardDueDateTextIsDue : Texts.cssActivityCardDueDateText
  const text = dayjs(dueDate).format(DD_MMM)

  if (!dueDate) return <NoWrapFigmaText textKey={Texts.activityCardNoDueDateText} />

  return <NoWrapFigmaText textKey={textKey} text={text} />
}
