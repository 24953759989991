import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { TODO_HEADER_ITEM_WIDTH } from '../libs/HardCodedSizes'
import FigmaText, { FigmaTextProps } from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { getArray } from '../ygdrasil/libs/Common'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'

const MENU_ITEMS_LENGTH = 5
export function MyTodosHeader(props: { activeDayOffset: number; onClickDate: (activeDayOffset: number) => void }) {
  const { activeDayOffset, onClickDate } = props
  const days = getArray(MENU_ITEMS_LENGTH).map((_, key) => key - 2 + activeDayOffset)

  return (
    <Box fullWidth>
      <Box fullPadding spacing={smallSpacing}>
        <FigmaText textKey={Texts.myTodosHeaderText} />
      </Box>
      <Container left direction="row" align="center" justify="space-around" fullPadding spacing={tinySpacing} fullWidth>
        <Box left onClick={() => onClickDate(activeDayOffset - 1)} link>
          <FigmaText textKey={Texts.myTodosDateTextYesterday} text="<" />
        </Box>
        {days.map((day, key) => {
          const isActiveDay = activeDayOffset === day
          const styleTextKey = isActiveDay ? Texts.cssMyTodosActiveDay : undefined
          return (
            <HeaderItemContainer key={key} left link onClick={() => onClickDate(day)}>
              <HeaderItemTextContainer day={day}>
                <FigmaText {...getTextPropsForDay(day)} styleTextKey={styleTextKey} />
              </HeaderItemTextContainer>
            </HeaderItemContainer>
          )
        })}
        <Box left onClick={() => onClickDate(activeDayOffset + 1)} link>
          <FigmaText textKey={Texts.myTodosDateTextYesterday} text=">" />
        </Box>
      </Container>
    </Box>
  )
}

const DATE_FORMAT = 'D/M'
const getTextPropsForDay = (day: number): FigmaTextProps => {
  switch (true) {
    case day < -2:
    case day === -2:
      return { textKey: Texts.cssMyTodosNoneActiveDay, text: dayjs().add(day, 'day').format(DATE_FORMAT) }
    case day === -1:
      return { textKey: Texts.myTodosDateTextYesterday }
    case day === 0:
      return { textKey: Texts.myTodosDateTextToday }
    case day === 1:
      return { textKey: Texts.myTodosDateTextTomorrow, text: getFigmaText(Texts.cssMyTodosNoneActiveDay) } // Should be tomorrow see https://ourbigpicture-group.slack.com/archives/C022UMGLSR3/p1683610535855649
    case day === 2:
    case day > -2:
      return { textKey: Texts.cssMyTodosNoneActiveDay, text: dayjs().add(day, 'day').format(DATE_FORMAT) }
    default:
      throw new Error(`Invalid day: ${day}`)
  }
}

const Container = styled(Box)`
  background-color: ${Colors.secondary95};
  border-radius: ${tinyBorderRadius};
`
const HeaderItemContainer = styled(Box)`
  width: ${TODO_HEADER_ITEM_WIDTH}px;
`

const HeaderItemTextContainer = styled(Box)<{ day: number }>`
  ${({ day }) => (day === 0 ? `border-top: 2px solid ${Colors.secondaryBase}` : '')};
`
