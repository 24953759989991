import invariant from 'invariant'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { EditDatasourceMachineViewProps } from '../ygdrasil/types/EditDatasourceMachine'
import Box from './Box'
import Button from './Button'
import { EditDatasourceMachineViewFooter } from './EditDatasourceMachineViewFooter'
import { Container, EditDatasourceMachineViewHeader } from './EditDatasourceMachineViewHeader'
import EditDatasourceMachineViewVerifyDataTable from './EditDatasourceMachineViewVerifyDataTable'

export default function EditDatasourceMachineViewVerifyData(props: EditDatasourceMachineViewProps) {
  const { onPressContinue, onClose, state } = props
  const {
    data: { connection, dataSource }
  } = props.state
  invariant(connection, 'connection is required')

  const buttonTextKey = !dataSource?._id
    ? Texts.adminIntegrationCreateDataSourceButtonText
    : Texts.adminIntegrationTableContextMenuEditDataSource

  return (
    <Container>
      <EditDatasourceMachineViewHeader
        textKey={Texts.adminIntegrationsCreateConnectionWizardStep3HeaderText}
        {...props}
      />
      <Box fullWidth left right>
        <Box top>
          <FigmaText
            textKey={Texts.adminIntegrationsCreateConnectionWizardStep3ConnectionName}
            text={`${dataSource?.name || ''}`}
          />
        </Box>
        <Box top width="50%">
          <EditDatasourceMachineViewVerifyDataTable {...props} />
        </Box>
        <Box top fullWidth align="flex-end" bottom>
          <Button textKey={buttonTextKey} onClick={() => onPressContinue(state)} loading={state.isLoading} />
          <Box top>
            <EditDatasourceMachineViewFooter />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
