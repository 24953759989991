import { useEffect } from 'react'
import { isLinkedInApp } from '../libs/IndexHelper'
import { useStonly } from '../libs/Stonly'

export default function OnAppMounted() {
  useStonly()
  useEffect(() => {
    if (!isLinkedInApp()) return
    window.open('https://app.ourbigpicture.se', '_blank')
  }, [])

  return null
}
