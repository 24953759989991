import React from 'react'
import styled from '@emotion/styled'
import { ObjectiveType } from '../ygdrasil/types/types'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import useAppState from '../hooks/useAppState'
import Colors from '../ygdrasil/libs/Colors'
import { getColor } from '../libs/RoadmapHelper'
import { AddButtonObjective } from './AddButtonObjective'
import Box, { BoxProps } from './Box'

export const RoadmapObjectiveAddObjectiveButtonContainer = (props: BoxProps) => {
  const { state } = useAppState()
  const type = state.selectedTeamId ? ObjectiveType.TEAM : ObjectiveType.STRATEGIC
  return (
    <Container top bottom align="center" justify="center" type={type} {...props}>
      <AddButtonObjective />
    </Container>
  )
}

const Container = styled(Box)<{ type: ObjectiveType }>`
  border-radius: ${tinyBorderRadius};
  background-color: ${Colors.whiteBg};
  cursor: pointer;
  border: 1px dashed ${({ type }) => getColor(type, false)};
`
