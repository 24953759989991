import dayjs from 'dayjs'
import { getMean } from './Common'
import { TreeDestinationPosition, TreeSourcePosition } from './StrategyViewTreeSearchHelper'

export type RankedObject = { ordo?: number; createdAt: string; _id?: string }
export type OptionalRankedObject = RankedObject | undefined

export const inRankOrder = <T extends RankedObject>(a: T, b: T) => {
  const aOrdo = getOrdoForRankedObject<T>(a)
  const bOrdo = getOrdoForRankedObject<T>(b)
  return aOrdo - bOrdo
}

export function getRankForActivityBoard(
  sourceIndex: number,
  destinationIndex: number,
  sourceObject: RankedObject,
  destinationObject: OptionalRankedObject,
  previosDestinationObject: OptionalRankedObject,
  nextDestinationObject: OptionalRankedObject,
  now: number = dayjs().valueOf()
): number {
  return getRankForTreeView(
    sourceObject,
    destinationObject || ({ ordo: now } as RankedObject),
    { index: sourceIndex, parentId: '' },
    { index: destinationIndex, parentId: '' },
    previosDestinationObject,
    nextDestinationObject,
    now
  )
}

export function getRankForTreeView(
  sourceObject: RankedObject,
  destinationObject: RankedObject,
  source: TreeSourcePosition,
  destination: TreeDestinationPosition,
  previosDestinationObject: OptionalRankedObject,
  nextDestinationObject: OptionalRankedObject,
  now: number = dayjs().valueOf()
): number {
  if (source.parentId !== destination.parentId) return getOrdoForRankedObject(sourceObject)

  if (source.index < (destination?.index as number) && nextDestinationObject)
    return getOrdoBetweenRankedObjects(destinationObject, nextDestinationObject, now)

  if (source.index < (destination?.index as number) && !nextDestinationObject) return now

  if (source.index > (destination?.index as number) && previosDestinationObject)
    return getOrdoBetweenRankedObjects(previosDestinationObject, destinationObject, now)

  if (source.index > (destination?.index as number) && !previosDestinationObject)
    return getOrdoBetweenRankedObjects({ ordo: 0 } as RankedObject, destinationObject, now)

  if (source.index === (destination?.index as number)) return getOrdoForRankedObject(sourceObject)

  throw new Error(`Invalid state: sourceobjectId: ${sourceObject._id} destinationObject: ${destinationObject._id} }`)
}

const DEFAULT_RANKED_OBJECT: RankedObject = {
  ordo: dayjs().valueOf(),
  createdAt: dayjs().format()
}

export const getRankForTableView = (
  source: RankedObject,
  dest: RankedObject = DEFAULT_RANKED_OBJECT,
  now: number = dayjs().valueOf()
): number => getOrdoBetweenRankedObjects(source, dest, now)

export function getOrdoBetweenRankedObjects(source: RankedObject, dest: RankedObject, now: number = dayjs().valueOf()) {
  return getMean([getOrdoForRankedObject(source), getOrdoForRankedObject(dest)]) || now
}

export function getOrdoForRankedObject<T extends RankedObject>(rankedObject: T): number {
  if (typeof rankedObject.ordo === 'number') return rankedObject.ordo

  return dayjs(rankedObject.createdAt).valueOf()
}
