
import creatingAccountImageBusinessPlanImg from '../ygdrasil/assets/images/creatingAccountImageBusinessPlan.png'
export const creatingAccountImageBusinessPlan = creatingAccountImageBusinessPlanImg 
import creatingAccountImageRoadmapImg from '../ygdrasil/assets/images/creatingAccountImageRoadmap.png'
export const creatingAccountImageRoadmap = creatingAccountImageRoadmapImg 
import creatingAccountImage1ActiveImg from '../ygdrasil/assets/images/creatingAccountImage1Active.png'
export const creatingAccountImage1Active = creatingAccountImage1ActiveImg 
import creatingAccountImage3ActiveImg from '../ygdrasil/assets/images/creatingAccountImage3Active.png'
export const creatingAccountImage3Active = creatingAccountImage3ActiveImg 
import creatingAccountImage2NotActiveImg from '../ygdrasil/assets/images/creatingAccountImage2NotActive.png'
export const creatingAccountImage2NotActive = creatingAccountImage2NotActiveImg 
import creatingAccountImageStrategyViewImg from '../ygdrasil/assets/images/creatingAccountImageStrategyView.png'
export const creatingAccountImageStrategyView = creatingAccountImageStrategyViewImg 
import creatingAccountImage1NotActiveImg from '../ygdrasil/assets/images/creatingAccountImage1NotActive.png'
export const creatingAccountImage1NotActive = creatingAccountImage1NotActiveImg 
import creatingAccountImage2ActiveImg from '../ygdrasil/assets/images/creatingAccountImage2Active.png'
export const creatingAccountImage2Active = creatingAccountImage2ActiveImg 
import {ReactComponent as newCompanyLogoImg} from '../ygdrasil/assets/images/newCompanyLogo.svg'
export const newCompanyLogo = newCompanyLogoImg 
import {ReactComponent as signUpIllustrationImg} from '../ygdrasil/assets/images/signUpIllustration.svg'
export const signUpIllustration = signUpIllustrationImg 
import {ReactComponent as greenCheckIconImg} from '../ygdrasil/assets/images/greenCheckIcon.svg'
export const greenCheckIcon = greenCheckIconImg 
import {ReactComponent as arrowRightWhiteImg} from '../ygdrasil/assets/images/arrowRightWhite.svg'
export const arrowRightWhite = arrowRightWhiteImg 
import {ReactComponent as languageIconImg} from '../ygdrasil/assets/images/languageIcon.svg'
export const languageIcon = languageIconImg 
import {ReactComponent as veryfyCodeIllustrationImg} from '../ygdrasil/assets/images/veryfyCodeIllustration.svg'
export const veryfyCodeIllustration = veryfyCodeIllustrationImg 
import jiraImg from '../ygdrasil/assets/images/jira.png'
export const jira = jiraImg 
import {ReactComponent as dropDownIconImg} from '../ygdrasil/assets/images/dropDownIcon.svg'
export const dropDownIcon = dropDownIconImg 
import {ReactComponent as undrawCancelRePkdmImg} from '../ygdrasil/assets/images/undrawCancelRePkdm.svg'
export const undrawCancelRePkdm = undrawCancelRePkdmImg 
import {ReactComponent as undrawCreditCardReBlmlImg} from '../ygdrasil/assets/images/undrawCreditCardReBlml.svg'
export const undrawCreditCardReBlml = undrawCreditCardReBlmlImg 
import arrowImg from '../ygdrasil/assets/images/arrow.png'
export const arrow = arrowImg 
import visaMasterCardImg from '../ygdrasil/assets/images/visaMasterCard.png'
export const visaMasterCard = visaMasterCardImg 
import helperImageForPrioritizedActionImg from '../ygdrasil/assets/images/helperImageForPrioritizedAction.png'
export const helperImageForPrioritizedAction = helperImageForPrioritizedActionImg 
import helperImageMarkAsPrioritizedImg from '../ygdrasil/assets/images/helperImageMarkAsPrioritized.png'
export const helperImageMarkAsPrioritized = helperImageMarkAsPrioritizedImg 
import {ReactComponent as loginIconImg} from '../ygdrasil/assets/images/loginIcon.svg'
export const loginIcon = loginIconImg 
import {ReactComponent as deleteIconImg} from '../ygdrasil/assets/images/deleteIcon.svg'
export const deleteIcon = deleteIconImg 
import {ReactComponent as closeIconPurpleImg} from '../ygdrasil/assets/images/closeIconPurple.svg'
export const closeIconPurple = closeIconPurpleImg 
import arrowToLeftImg from '../ygdrasil/assets/images/arrowToLeft.png'
export const arrowToLeft = arrowToLeftImg 
import {ReactComponent as saveIconPurpleImg} from '../ygdrasil/assets/images/saveIconPurple.svg'
export const saveIconPurple = saveIconPurpleImg 
import {ReactComponent as closeIconRedImg} from '../ygdrasil/assets/images/closeIconRed.svg'
export const closeIconRed = closeIconRedImg 
import {ReactComponent as newMenuCelebrationsImg} from '../ygdrasil/assets/images/newMenuCelebrations.svg'
export const newMenuCelebrations = newMenuCelebrationsImg 
import mobileCelebrationEmptyStateImg from '../ygdrasil/assets/images/mobileCelebrationEmptyState.png'
export const mobileCelebrationEmptyState = mobileCelebrationEmptyStateImg 
import microsoftExcelImg from '../ygdrasil/assets/images/microsoftExcel.png'
export const microsoftExcel = microsoftExcelImg 
import googleSheetsImg from '../ygdrasil/assets/images/googleSheets.png'
export const googleSheets = googleSheetsImg 
import legalNoticeImageImg from '../ygdrasil/assets/images/legalNoticeImage.png'
export const legalNoticeImage = legalNoticeImageImg 
import checkboxCheckedIconImg from '../ygdrasil/assets/images/checkboxCheckedIcon.png'
export const checkboxCheckedIcon = checkboxCheckedIconImg 
import {ReactComponent as googleIconImg} from '../ygdrasil/assets/images/googleIcon.svg'
export const googleIcon = googleIconImg 
import creatingAccountImage4ActiveImg from '../ygdrasil/assets/images/creatingAccountImage4Active.png'
export const creatingAccountImage4Active = creatingAccountImage4ActiveImg 
import creatingAccountImage3NotActiveImg from '../ygdrasil/assets/images/creatingAccountImage3NotActive.png'
export const creatingAccountImage3NotActive = creatingAccountImage3NotActiveImg 
import oopsImg from '../ygdrasil/assets/images/oops.png'
export const oops = oopsImg 
import {ReactComponent as office365Img} from '../ygdrasil/assets/images/office365.svg'
export const office365 = office365Img 
import emailSmallerNoShadowImg from '../ygdrasil/assets/images/emailSmallerNoShadow.png'
export const emailSmallerNoShadow = emailSmallerNoShadowImg 
import securityImg from '../ygdrasil/assets/images/security.png'
export const security = securityImg 
import searchImg from '../ygdrasil/assets/images/search.png'
export const search = searchImg 
import loginBackgroundImg from '../ygdrasil/assets/images/loginBackground.png'
export const loginBackground = loginBackgroundImg 
import responsiveTribesLogoImg from '../ygdrasil/assets/images/responsiveTribesLogo.png'
export const responsiveTribesLogo = responsiveTribesLogoImg 
import bottomBgImg from '../ygdrasil/assets/images/bottomBg.png'
export const bottomBg = bottomBgImg 
import undrawHelloRe3Evm1Img from '../ygdrasil/assets/images/undrawHelloRe3Evm1.png'
export const undrawHelloRe3Evm1 = undrawHelloRe3Evm1Img 
import mobileLoginBgImg from '../ygdrasil/assets/images/mobileLoginBg.png'
export const mobileLoginBg = mobileLoginBgImg 
import errorApplicationMaintenanceImg from '../ygdrasil/assets/images/errorApplicationMaintenance.png'
export const errorApplicationMaintenance = errorApplicationMaintenanceImg 
import threeHeadsImg from '../ygdrasil/assets/images/threeHeads.png'
export const threeHeads = threeHeadsImg 
import {ReactComponent as notificationsOffImg} from '../ygdrasil/assets/images/notificationsOff.svg'
export const notificationsOff = notificationsOffImg 
import {ReactComponent as monthlyImg} from '../ygdrasil/assets/images/monthly.svg'
export const monthly = monthlyImg 
import {ReactComponent as weeklyIconImg} from '../ygdrasil/assets/images/weeklyIcon.svg'
export const weeklyIcon = weeklyIconImg 
import {ReactComponent as dailyIconImg} from '../ygdrasil/assets/images/dailyIcon.svg'
export const dailyIcon = dailyIconImg 
import {ReactComponent as imageTraditionalRoadmapImg} from '../ygdrasil/assets/images/imageTraditionalRoadmap.svg'
export const imageTraditionalRoadmap = imageTraditionalRoadmapImg 
import flagIconImg from '../ygdrasil/assets/images/flagIcon.png'
export const flagIcon = flagIconImg 
import {ReactComponent as imageArrowShortImg} from '../ygdrasil/assets/images/imageArrowShort.svg'
export const imageArrowShort = imageArrowShortImg 
import {ReactComponent as imageArrowLongImg} from '../ygdrasil/assets/images/imageArrowLong.svg'
export const imageArrowLong = imageArrowLongImg 
import checkinPocalImg from '../ygdrasil/assets/images/checkinPocal.png'
export const checkinPocal = checkinPocalImg 
import {ReactComponent as powerSettingsNewImg} from '../ygdrasil/assets/images/powerSettingsNew.svg'
export const powerSettingsNew = powerSettingsNewImg 
import splashImg from '../ygdrasil/assets/images/splash.png'
export const splash = splashImg 
import passwordImageImg from '../ygdrasil/assets/images/passwordImage.png'
export const passwordImage = passwordImageImg 
import termsAndConditionImg from '../ygdrasil/assets/images/termsAndCondition.png'
export const termsAndCondition = termsAndConditionImg 
import imageDesktopVersionImg from '../ygdrasil/assets/images/imageDesktopVersion.png'
export const imageDesktopVersion = imageDesktopVersionImg 
import appStoreIconImg from '../ygdrasil/assets/images/appStoreIcon.png'
export const appStoreIcon = appStoreIconImg 
import mobileBackgroundSagaImg from '../ygdrasil/assets/images/mobileBackgroundSaga.png'
export const mobileBackgroundSaga = mobileBackgroundSagaImg 
import imageArrowsImg from '../ygdrasil/assets/images/imageArrows.png'
export const imageArrows = imageArrowsImg 
import imageOurBigPictureMobileImg from '../ygdrasil/assets/images/imageOurBigPictureMobile.png'
export const imageOurBigPictureMobile = imageOurBigPictureMobileImg 
import {ReactComponent as newMenuStrategyImg} from '../ygdrasil/assets/images/newMenuStrategy.svg'
export const newMenuStrategy = newMenuStrategyImg 
import mobileErrorApplicationMaintenanceImg from '../ygdrasil/assets/images/mobileErrorApplicationMaintenance.png'
export const mobileErrorApplicationMaintenance = mobileErrorApplicationMaintenanceImg 
import applicationLogoImg from '../ygdrasil/assets/images/applicationLogo.png'
export const applicationLogo = applicationLogoImg 
import applicationLogoSmallImg from '../ygdrasil/assets/images/applicationLogoSmall.png'
export const applicationLogoSmall = applicationLogoSmallImg 
import logoWhiteImg from '../ygdrasil/assets/images/logoWhite.png'
export const logoWhite = logoWhiteImg 
import flagOutlineImg from '../ygdrasil/assets/images/flagOutline.png'
export const flagOutline = flagOutlineImg 
import {ReactComponent as iconCalendarImg} from '../ygdrasil/assets/images/iconCalendar.svg'
export const iconCalendar = iconCalendarImg 
import {ReactComponent as dateIconImg} from '../ygdrasil/assets/images/dateIcon.svg'
export const dateIcon = dateIconImg 
import {ReactComponent as optionsIconImg} from '../ygdrasil/assets/images/optionsIcon.svg'
export const optionsIcon = optionsIconImg 
import {ReactComponent as leadDoneIconImg} from '../ygdrasil/assets/images/leadDoneIcon.svg'
export const leadDoneIcon = leadDoneIconImg 
import {ReactComponent as grayEditIconImg} from '../ygdrasil/assets/images/grayEditIcon.svg'
export const grayEditIcon = grayEditIconImg 
import {ReactComponent as deleteIconRedImg} from '../ygdrasil/assets/images/deleteIconRed.svg'
export const deleteIconRed = deleteIconRedImg 
import {ReactComponent as confidenceControlYesIconImg} from '../ygdrasil/assets/images/confidenceControlYesIcon.svg'
export const confidenceControlYesIcon = confidenceControlYesIconImg 
import {ReactComponent as confidenceControlMaybeIconImg} from '../ygdrasil/assets/images/confidenceControlMaybeIcon.svg'
export const confidenceControlMaybeIcon = confidenceControlMaybeIconImg 
import {ReactComponent as confidenceControlDontKnowIconImg} from '../ygdrasil/assets/images/confidenceControlDontKnowIcon.svg'
export const confidenceControlDontKnowIcon = confidenceControlDontKnowIconImg 
import {ReactComponent as confidenceControlNoIconImg} from '../ygdrasil/assets/images/confidenceControlNoIcon.svg'
export const confidenceControlNoIcon = confidenceControlNoIconImg 
import infoTooltipImg from '../ygdrasil/assets/images/infoTooltip.png'
export const infoTooltip = infoTooltipImg 
import checkIconFilledImg from '../ygdrasil/assets/images/checkIconFilled.png'
export const checkIconFilled = checkIconFilledImg 
import checkIconUnfilledImg from '../ygdrasil/assets/images/checkIconUnfilled.png'
export const checkIconUnfilled = checkIconUnfilledImg 
import mailIconImg from '../ygdrasil/assets/images/mailIcon.png'
export const mailIcon = mailIconImg 
import {ReactComponent as celebrationIconImg} from '../ygdrasil/assets/images/celebrationIcon.svg'
export const celebrationIcon = celebrationIconImg 
import iconOpenInFullImg from '../ygdrasil/assets/images/iconOpenInFull.png'
export const iconOpenInFull = iconOpenInFullImg 
import {ReactComponent as iconExpandImg} from '../ygdrasil/assets/images/iconExpand.svg'
export const iconExpand = iconExpandImg 
import chatGptImg from '../ygdrasil/assets/images/chatGpt.png'
export const chatGpt = chatGptImg 
import arrowBackImg from '../ygdrasil/assets/images/arrowBack.png'
export const arrowBack = arrowBackImg 
import appleIconImg from '../ygdrasil/assets/images/appleIcon.png'
export const appleIcon = appleIconImg 