import React from 'react'
import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { Objective, ObjectiveType } from '../ygdrasil/types/types'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useIsSelectedObjective from '../hooks/useSelectedObjective'
import { getRoadmapRowInfos, getWidth } from '../libs/RoadmapAccordionBodyHelper'
import { getColor } from '../libs/RoadmapHelper'
import Box from './Box'
import StrategyViewObjectiveListObjectiveMenu from './StrategyViewObjectiveListObjectiveMenu'
import FigmaTextWithSearchResult from './FigmaTextWithSearchResult'
import { RoadmapExpandLessIcon, RoadmapExpandMoreIcon } from './Icons'
import { MoreActionRoadmapStrategicObjectiveHeader } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import WithTooltipOverflow from './WithTooltipOverflow'

export const RoadmapStrategicObjectiveHeader = ({
  objective,
  isExpanded,
  onToggleExpand,
  style,
  headerDates
}: {
  onToggleExpand: () => unknown
  isExpanded: boolean
  objective: Objective
  style?: React.CSSProperties
  headerDates: string[]
}) => {
  const { state } = useAppState()
  const { searchState } = state
  const { isSelected, toggleObjective } = useIsSelectedObjective(objective)
  const textKey = isSelected ? Texts.cssRoadmapStrategicTitleHeader : Texts.cssRoadmapStrategicTitleHeader
  const [showMoreAction, setShowMoreAction] = React.useState(false)

  const onMouseEnter = () => setShowMoreAction(true)
  const onMouseLeave = () => setShowMoreAction(false)

  const roadmapRowInfos = getRoadmapRowInfos(objective, headerDates)

  return (
    <Box fullWidth direction="row">
      {roadmapRowInfos.map(({ visible, numberOfPeriods }, key, arr) => {
        return (
          <Container
            width={getWidth(numberOfPeriods, headerDates)}
            direction="row"
            isSelected={isSelected}
            type={objective.type}
            align="center"
            style={style}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            key={key}
            visibility={visible ? 'visible' : 'hidden'}
          >
            <Box direction="row" align="center" fullWidth>
              <Box right left spacing={smallSpacing} onClick={onToggleExpand} link>
                {isExpanded && <RoadmapExpandLessIcon color={Colors.white} />}
                {!isExpanded && <RoadmapExpandMoreIcon color={Colors.white} />}
              </Box>
              <Box fullPadding spacing={tinySpacing} onClick={toggleObjective} fullWidth>
                <WithTooltipOverflow text={objective.name} ellipsisTextKey={textKey}>
                  <FigmaTextWithSearchResult
                    textKey={textKey}
                    text={objective.name}
                    searchText={searchState.searchText}
                  />
                </WithTooltipOverflow>
              </Box>
            </Box>
            <Box right>
              <MoreActionWithMenu
                visible={showMoreAction}
                MoreAction={(props) => <MoreActionRoadmapStrategicObjectiveHeader {...props} objective={objective} />}
                Menu={(props) => <StrategyViewObjectiveListObjectiveMenu objective={objective} {...props} />}
              />
            </Box>
          </Container>
        )
      })}
    </Box>
  )
}
const Container = styled(Box)<{ isSelected; type: ObjectiveType }>`
  background-color: ${({ type, isSelected }) => getColor(type, isSelected)};
  border-radius: ${tinyBorderRadius};
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
`
