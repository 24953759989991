import styled from '@emotion/styled'
import { smallBorderRadius } from '../enums/BorderRadixes'
import { mediumSpacing, minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { openSwotDrawer } from '../libs/BusinessPlanHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import { toPx } from '../ygdrasil/libs/Common'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { SWOTItem } from '../ygdrasil/types/types'
import Box from './Box'
import { RichText } from './RichText'

export function BusinessPlanSwotRow({ swotItem, text }: { swotItem: SWOTItem; text: string }) {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const config = useLoggedInConfig()
  const onClick = () => org && openSwotDrawer(org, swotItem, config)
  return (
    <Box
      direction="row"
      onClick={onClick}
      pointer
      style={{ borderTop: `1px dotted ${Colors.primaryBase}` }}
      fullWidth
      top
      spacing={smallSpacing}
    >
      <Box top spacing={tinySpacing}>
        <LetterContainer align="center" justify="center">
          <FigmaText textKey={Texts.swotCharacter} text={swotItem[0].toUpperCase()} />
        </LetterContainer>
      </Box>
      <Box left fullWidth spacing={mediumSpacing}>
        <Box top spacing={minSpacing}>
          {swotItem === SWOTItem.Strength && (
            <FigmaText textKey={Texts.editSwotStrengthHeader} text={getFigmaText(Texts.editSwotStrengthHeader)} />
          )}
          {swotItem === SWOTItem.Weaknesses && (
            <FigmaText textKey={Texts.editSwotStrengthHeader} text={getFigmaText(Texts.editSwotWeaknesseHeader)} />
          )}
          {swotItem === SWOTItem.Opportunities && (
            <FigmaText textKey={Texts.editSwotStrengthHeader} text={getFigmaText(Texts.editSwotOpportunitiesHeader)} />
          )}
          {swotItem === SWOTItem.Threats && (
            <FigmaText textKey={Texts.editSwotStrengthHeader} text={getFigmaText(Texts.editSwotThreatsHeader)} />
          )}
        </Box>
        <Box>
          <RichText fallbackTextKey={Texts.cssEditSwotEdit} richText={text} fallbackText={text} />
        </Box>
      </Box>
    </Box>
  )
}
const SIZE = toPx(Texts.swotCharacter.style.fontSize) * 1.3

const LetterContainer = styled(Box)`
  border-radius: ${smallBorderRadius};
  background: ${Colors.primary30};
  width: ${SIZE * 1.3}px;
  height: ${SIZE * 1.3}px;
`
