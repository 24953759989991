import { FormikProps } from 'formik'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import { GrowthIndicator, GrowthIndicatorValueType } from '../ygdrasil/types/types'
import { tinySpacing } from '../enums/Spacings'
import { getNewValues } from '../libs/GrowthViewGrowthIndicatorValueTextFieldFormikHelper'
import { GROWTH_INDICATOR_VALUE_INPUT_WIDTH } from '../libs/HardCodedSizes'
import Texts from '../ygdrasil/libs/Texts'
import { InitialValues } from './GrowthViewGrowthIndicatorRow'
import TextFieldFormik from './TextFieldFormik'
import { TextFieldOutlinedNumberInputForGrowthView } from './TextFieldOutlinedNumberInput'
import { updateGrowthIndicator } from '../ygdrasil/libs/ProcessHandler'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { BLUR_KEYCODES } from '../ygdrasil/libs/Consts'

type Props = {
  date: string
  formProps: FormikProps<InitialValues>
  type: GrowthIndicatorValueType
  growthIndicator: GrowthIndicator
  onBlur: () => unknown
  headerDates: string[]
  ref?: any
}

export default function GrowthViewGrowthIndicatorValueTextFieldFormik(props: Props) {
  const logEvent = useLogEvent()
  const { type, date, formProps, growthIndicator, onBlur: _onBlur, ref } = props
  const name = type
  const textKey =
    type === GrowthIndicatorValueType.ACTUAL ? Texts.successEditActualsLabel : Texts.successViewGoalHeaderLabel

  const onBlur = (e) => {
    formProps.handleBlur(name)(e)
    formProps.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) return
      _onBlur()

      const growthIndicatorValue = formProps.values.values[date]
      let value: number | null = parseFloat(formProps.values[name])

      if (!(name in formProps.values)) return

      if (isNaN(value)) {
        value = null
      }

      Promise.resolve().then(() => {
        const values = getNewValues(growthIndicatorValue, name, date, growthIndicator, value)

        return updateGrowthIndicator(
          {
            ...growthIndicator,
            ...formProps.values,
            values
          },
          logEvent
        )
      })
    })
  }

  useEffect(() => {
    formProps.setFieldValue(
      name,
      formProps.values.values[date]?.[name] === undefined ? '' : formProps.values.values[date]?.[name]
    )
  }, [])

  return (
    <TextFieldFormik
      ref={ref}
      name={name}
      labelText={textKey}
      formProps={formProps}
      component={StyledTextFieldOutlined}
      onBlur={onBlur}
      onKeyDown={(e) => {
        if (e.code === 'Escape') {
          return _onBlur()
        }
        if (e.code === 'Enter' || e.code === 'Tab') {
          return onBlur(e)
        }
        return BLUR_KEYCODES.includes(e.code) && onBlur(e)
      }}
      autoFocus
      tabIndex={0}
    />
  )
}

const StyledTextFieldOutlined = styled(TextFieldOutlinedNumberInputForGrowthView)`
  .MuiInputBase-input {
    width: ${GROWTH_INDICATOR_VALUE_INPUT_WIDTH}px;
    padding: ${tinySpacing};
    font-size: 12px;
    padding-top: 12px;
  }
  * {
    border-color: transparent !important;
    text-align: center;
  }
`
