// @flow
import { createTheme } from '@mui/material'
import Colors from '../ygdrasil/libs/Colors'

const DefaultMaterialTheme = {
  palette: {
    primary: {
      main: Colors.primaryBase,
      dark: Colors.primary10
    },
    primaryDark: {
      main: Colors.primary10,
      dark: Colors.primaryBase
    },
    secondary: {
      main: Colors.secondaryBase
    },
    white: {
      main: Colors.whiteBg
    }
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#000'
      }
    }
  }
}

const theme = createTheme(DefaultMaterialTheme)

export default theme
