import Texts from '../assets/texts.json'

export const HardCodedTexts = {
  midHeadingAppInfo: {
    name: 'midHeadingAppInfo',
    figmaTextKeyName: '',
    pageName: '',
    characters: 'App information',
    style: {
      fontFamily: 'Inter',
      fontSize: '8px',
      fontWeight: 600,
      letterSpacing: '-0.5px',
      lineHeight: '19.2px',
      color: 'rgba(27, 28, 31, 1)'
    }
  }
}

export const DeprecatedTexts = {
  cssSuccessViewMonthlyText: {
    name: 'cssSuccessViewMonthlyText',
    figmaTextKeyName: '',
    characters: 'Feb',
    pageName: '',
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  cssRoadmapQuarterText: {
    name: 'cssRoadmapQuarterText',
    figmaTextKeyName: '',
    characters: 'Q3 2021',
    pageName: '',
    style: {
      fontFamily: 'Manrope',
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '0.10000000149011612px',
      color: 'rgba(138, 118, 255, 1)'
    }
  },
  indicatorValueTextGoalDeprecated: {
    name: 'indicatorValueTextGoal',
    figmaTextKeyName: '',
    characters: '195k',
    pageName: '',
    style: {
      fontFamily: 'Manrope',
      fontSize: '10px',
      fontWeight: 400,
      letterSpacing: '0.10000000149011612px',
      color: 'rgba(0,0,0, 1)'
    }
  },
  longTerm: {
    name: 'longTerm',
    figmaTextKeyName: '',
    characters: 'Long term',
    pageName: '',
    style: {
      fontFamily: 'Manrope',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  quarterly: {
    name: 'quarterly',
    figmaTextKeyName: '',
    characters: 'Quarterly',
    pageName: '',
    style: {
      fontFamily: 'Mtexrope',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  objectiveTypeDialogTeamObjective: {
    name: 'objectiveTypeDialogTeamObjective',
    figmaTextKeyName: '',
    characters: 'Team Objective',
    pageName: '',
    style: {
      fontFamily: 'Manrope',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.10000000149011612px',
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  objectiveTypeDialogTopObjective: {
    name: 'objectiveTypeDialogTopObjective',
    figmaTextKeyName: '',
    characters: 'Top Objective',
    pageName: '',
    style: {
      fontFamily: 'Manrope',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.10000000149011612px',
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  cssApplicationMenuVisionHeader: {
    name: 'cssApplicationMenuVisionHeader',
    characters: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.10000000149011612px',
      color: 'rgba(27, 27, 30, 1)'
    },
    pageName: 'New base',
    figmaTextKeyName: 'TXT_cssApplicationMenuVisionHeader'
  },
  cssApplicationMenuVisionDescription: {
    name: 'cssApplicationMenuVisionDescription',
    characters:
      'Curabitur pharetra, risus quis iaculis euismod, erat erat interdum leo, non vulputate dui risus eu risus.',
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.4000000059604645px',
      color: 'rgba(96, 93, 102, 1)'
    },
    pageName: 'New base',
    figmaTextKeyName: 'TXT_cssApplicationMenuVisionDescription'
  },
  allApplicationMenuStrategicPillarsHeader: {
    name: 'allApplicationMenuStrategicPillarsHeader',
    characters: 'Strategic pillars',
    style: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0.10000000149011612px',
      color: 'rgba(27, 27, 30, 1)'
    },
    pageName: 'New base',
    figmaTextKeyName: 'txt_AllApplicationMenu_Strategic_Pillars_Header'
  },
  objectivesListProgressNumber: {
    name: 'objectivesListProgressNumber',
    characters: '%',
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 1)'
    },
    pageName: 'New base',
    figmaTextKeyName: 'TXT_objectivesListProgressNumber'
  }
}

export default Texts
