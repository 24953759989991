import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { smallBorderRadius } from '../enums/BorderRadixes'
import { tinySpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { getValueForGraphTooltip } from '../ygdrasil/libs/GraphHelper'
import { KeyResult, Unit } from '../ygdrasil/types/types'
import Box from './Box'

export const GraphTooltip = (props) => {
  const { payload, color = Colors.good } = props

  if (payload && payload.length) {
    const timestamp = payload[0]?.payload?.timestamp
    const value = payload[0]?.value
    if (!timestamp || !value) return null

    // Cant pass whole keyresult object to graph therefor keyresult is approximated to this
    const keyResult = { unit: Unit.NUMERICAL, startValue: value, goalValue: value } as KeyResult
    return (
      <Container spacing={tinySpacing} fullPadding>
        <span>Date: {dayjs(timestamp).format(YYYY_MM_DD)}</span>
        <span style={{ color: color }}>Value: {getValueForGraphTooltip(value, keyResult)}</span>
      </Container>
    )
  }

  return null
}

const Container = styled(Box)`
  background-color: ${Colors.whiteBg};
  border: 1px solid ${Colors.whitesmoke};
  border-radius: ${smallBorderRadius};
`
