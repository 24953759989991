import { PopupModal } from 'react-calendly'
import { ModalProps } from './Modal'

type Props = ModalProps & { url: CalendlyUrl }

export default function ModalCalendly({ onClose, url }: Props) {
  return <PopupModal open url={url} onModalClose={onClose} rootElement={document.getElementById('root') as any} />
}

export enum CalendlyUrl {
  ScheduleCall = `https://calendly.com/obp-meeting-per-egnell/30min`,
  ScheduleDemo = `https://calendly.com/obp-meeting-per-egnell/produkt-presentation-diskussion`
}
