import dayjs from 'dayjs'
import invariant from 'invariant'
import { getRankForTreeView } from './RankHelper'
import {
  SearchObject,
  TreeData,
  TreeDestinationPosition,
  TreeItem,
  TreeSourcePosition
} from './StrategyViewTreeSearchHelper'

export const updateTreeForNewItems = (newTree: TreeData, oldTree: TreeData): TreeData => {
  newTree.items = Object.keys(newTree.items).reduce((a, key) => {
    a[key] = newTree.items[key]
    if (oldTree.items[key]?.isExpanded) a[key].isExpanded = true
    if (oldTree.items[key]?.data.isSelected) a[key].data.isSelected = true
    return a
  }, {} as Record<string, TreeItem>)

  return newTree
}

export const updateTreeForNewState = (newTree: TreeData, oldTree: TreeData): TreeData => {
  newTree.items = Object.keys(newTree.items).reduce((a, key) => {
    a[key] = newTree.items[key]
    if (oldTree.items[key]?.data.isSelected) a[key].data.isSelected = true
    return a
  }, {} as Record<string, TreeItem>)

  return newTree
}

export const getDataWithNewRank = (
  source: TreeSourcePosition,
  destination: TreeDestinationPosition,
  tree: TreeData,
  now: number = dayjs().valueOf()
): { newOrdo: number; newParentId: string; searchObject: SearchObject } | undefined => {
  if (destination.parentId !== source.parentId) return //should not allow reparenting

  const destinationIndex = destination.index || 0
  const sourceItemId = tree.items[source.parentId].children[source.index]?.id
  const destinationItemId = tree.items[destination.parentId].children[destinationIndex]?.id
  const previousDestinationItemId: string | undefined =
    tree.items[destination.parentId].children[destinationIndex - 1]?.id
  const nextDestinationItemId: string | undefined = tree.items[destination.parentId].children[destinationIndex + 1]?.id

  invariant(typeof sourceItemId !== undefined, '!sourceItemId')
  invariant(typeof destinationItemId !== undefined, '!destinationItemId')

  const sourceItem = getSearchObject(sourceItemId, tree)
  const destinationItem = getSearchObject(destinationItemId, tree)
  const previousDestinationItem = findSearchObject(previousDestinationItemId, tree)
  const nextDestinationItem = findSearchObject(nextDestinationItemId, tree)
  const parentItem = getSearchObject(source.parentId, tree)

  if (sourceItem.searchType !== destinationItem.searchType) return // cant set keyResult among objectives etc

  const newOrdo = getRankForTreeView(
    sourceItem.data,
    destinationItem.data,
    source,
    destination,
    previousDestinationItem?.data,
    nextDestinationItem?.data,
    now
  )

  return {
    newOrdo,
    newParentId: (destination?.parentId || source.parentId) as string,
    searchObject: { ...sourceItem, parentId: destination.parentId as string }
  }
}

const getSearchObject = (id: string, tree: TreeData): SearchObject => {
  const item = findSearchObject(id, tree)

  invariant(item, '!item %s', id)
  return item
}
const findSearchObject = (id: string, { items }: TreeData): SearchObject | undefined =>
  items[id]?.data as SearchObject | undefined
