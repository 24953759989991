import config from '../Config'
import { State } from '../hooks/useAppState'
import * as Stripe from '../stripe'
import { StripeCheckoutCallbackQueryParams, addQueryParamsToUrl } from '../ygdrasil/hooks/useQueryParams'
import { OrgSubscriptionInfoType } from '../ygdrasil/libs/OrgSubscriptionInfo'
import RoutePath from '../ygdrasil/libs/RoutePath'
import { Subscription } from '../ygdrasil/types/types'
import { app } from './Firebase'

const payments = Stripe.getStripePayments(app, {
  productsCollection: 'products',
  customersCollection: 'users'
})

const DEFAULT_SESSION_CREATE_PARAMS = {
  automatic_tax: true,
  trial_from_plan: false,
  success_url: getStripeCallbackUrl({ stripeSuccess: 'true' }),
  cancel_url: getStripeCallbackUrl({ stripeSuccess: 'false' })
} as Stripe.SessionCreateParams

export const createCheckoutSession = (
  { stripePriceId }: OrgSubscriptionInfoType,
  state: State,
  params = DEFAULT_SESSION_CREATE_PARAMS
) =>
  Stripe.getProduct(payments, config.stripeProductId)
    .then((product) => Stripe.getPrice(payments, product.id, stripePriceId))
    .then((price) =>
      Stripe.createCheckoutSession(payments, {
        ...params,
        price: price.id,
        metadata: { orgId: state.selectedOrganizationId, obpUserId: state.user._id } as Subscription['metadata']
      })
    )

export function getStripeCallbackUrl(params: StripeCheckoutCallbackQueryParams): string | undefined {
  return addQueryParamsToUrl<StripeCheckoutCallbackQueryParams>(`${config.appUrl}${RoutePath.ROOT}`, {
    showSettings: 'true',
    ...params
  })
}
