import React, { useContext } from 'react'
import { SettingsTabKey } from '../components/SettingsTabs'
import { StorageKey } from '../libs/useLocalStorageItemHelper'
import { ALL_STRATEGIC_PILLAR } from '../ygdrasil/libs/Consts'
import { DayJS } from '../ygdrasil/libs/DayJsHelper'
import { SearchState } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { getDefaultLanguageCode } from '../ygdrasil/libs/useLanguage'
import { FirebaseUser, User } from '../ygdrasil/types/types'
import { useSessionState } from './useSessionState'
import { useStorageItem } from './useStorageItem'

export const IGNORED_KEYS = ['shouldShowExpandedMenu', 'showDashboard'] as (keyof State)[]

export type State = {
  user: User
  selectedTeamId: string | null
  selectedOrganizationId: string
  firebaseUser?: FirebaseUser
  showCelebrationView?: boolean
  showVisionAndMission?: boolean
  showGrowthView?: boolean
  showBusinessPlan?: boolean
  showSettings?: boolean
  showNewIndicatorRow?: boolean
  selectedDashboardView: 'roadmap' | 'list'
  selectedSuccessView: 'graph' | 'sheet'
  searchState: SearchState
  shouldShowExpandedMenu?: boolean | null
  settingsTabKey?: SettingsTabKey
  codeVerifier?: string
  showListView: boolean
  showDropDownList: boolean
  // Dashboard
  showDashboard?: boolean
  showMyActivities?: boolean
  showDashboardCelebrations?: boolean
  selectedDashboardLinkId?: string
  focusedObjectiveId: string | null
}

export const DEFAULT_STATE: State = {
  user: {
    _id: '',
    createdAt: DayJS().format(),
    updatedAt: DayJS().format(),
    createdBy: '',
    updatedBy: '',
    objectiveIds: [] as string[],
    name: '',
    isSignedIn: false,
    organizationIds: [],
    orgUserInfo: {},
    email: null,
    phone: null,
    language: getDefaultLanguageCode()
  },
  selectedDashboardView: 'list',
  selectedSuccessView: 'graph',
  firebaseUser: undefined,
  selectedOrganizationId: '',
  selectedTeamId: null,
  showVisionAndMission: true,
  showGrowthView: false,
  showDashboard: false,
  showMyActivities: false,
  showBusinessPlan: false,
  showSettings: undefined,
  shouldShowExpandedMenu: null,
  showListView: true,
  showDropDownList: false,
  searchState: {
    strategicPillar: ALL_STRATEGIC_PILLAR._id,
    selectedItemId: undefined,
    searchText: '',
    searchTextGrowthView: '',
    searchDates: {
      startDate: DayJS().startOf('quarter').format(),
      endDate: DayJS().endOf('quarter').format()
    },
    // TODO WRITE TEST, should show Clear filter button when selectedObjectiveId is set
    selectedObjectiveId: undefined
  },
  focusedObjectiveId: null
}

export type AppContext = {
  state: State
  setState: (state: State) => unknown
  setSearchState: (searchState: SearchState) => unknown
}
export const Context = React.createContext<AppContext>({
  state: DEFAULT_STATE,
  setState: () => ({}),
  setSearchState: () => ({})
})

export const useAppStateContext = () => {
  const { storageItem: state, setStorageItem: setState } = useStorageItem(StorageKey.STATE, DEFAULT_STATE, {
    ignoredKeys: IGNORED_KEYS
  })

  const session = useSessionState(state)

  if (!state.searchState) state.searchState = DEFAULT_STATE.searchState // Dont remove, old clients crash without this

  const setSearchState = (searchState: SearchState) => setState({ ...state, searchState })

  return { state: { ...state, ...session }, setState, setSearchState }
}

export const useUser = () => useAppState().state.user

export const useUserId = () => useAppState().state.user._id

export default function useAppState() {
  return useContext(Context)
}
