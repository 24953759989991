import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import { User } from '../ygdrasil/types/types'
import Box from './Box'
import Tooltip from './Tooltip'
import { UserWidgetView } from './UserWidget'

export function StrategyViewObjectiveTreeFilterUserView({
  user,
  onClick,
  shouldBlur
}: {
  user: User
  onClick: () => unknown
  shouldBlur?: boolean
}): JSX.Element {
  return (
    <Container onClick={onClick} shouldBlur={shouldBlur} pointer>
      <Tooltip title={user.name}>
        <UserWidgetView userId={user._id} link={false} />
      </Tooltip>
    </Container>
  )
}
const Container = styled(Box)<{ shouldBlur?: boolean }>`
  opacity: ${({ shouldBlur }) => (shouldBlur ? 0.2 : 1)};
`
