import dayjs from 'dayjs'
import { State } from '../../hooks/useAppState'
import EventCategories from '../enums/EventCategories'
import { Organization, Unit, Value } from '../types/types'
import { groupBy } from './Common'
import type { EventParams, OurEvent, UserAddIndicatorValueParams, UserCelebratedEventParams } from './EventHelper'
import { getText } from './FigmaTextForStrategyModelHelper'
import { formatText, getFigmaText } from './TextRepository'
import Texts from './Texts'

export const MAX_LIKED_NUMBER = 3

export const applicationNotificationColleagueCelebrated = Texts.applicationNotificationColleagueCelebrated

export const MAX_CELEBRATION_EVENTS = 10

export const hasUnseenNotifications = <T>(events: OurEvent<T>[], s: State): boolean => {
  const { user } = s
  const { lastSeenNotificationsAt } = user

  if (!lastSeenNotificationsAt) return true
  return getEventsForCelebrationView(events, s).some((e) => dayjs(e.createdAt).isAfter(dayjs(lastSeenNotificationsAt)))
}

export const getEventsForCelebrationView = <T>(events: OurEvent<T>[], { user }: State): OurEvent<T>[] => {
  events = events
    .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())
    .filter((e) => !!e.indicatorId)
    .filter((e) => [EventCategories.UserAddIndicatorValue].includes(e.eventCategory))
  /*.filter((e) => {
      if (e.eventCategory === EventCategories.UserCelebratedEvent && e.createdBy === user._id) return false
      return true
    })*/

  const _events = groupBy(events, 'indicatorId')
  events = Object.keys(_events).map((key) => _events[key][0])

  return events.filter((x, i) => i < MAX_CELEBRATION_EVENTS)
}

export const getUnseenCelebrationEventsCreatedByLoggedInUser = (
  events: OurEvent<Partial<EventParams>>[],
  { user }: State
): OurEvent<UserCelebratedEventParams>[] => {
  const { lastSeenNotificationsAt } = user
  return events
    .filter((e) => dayjs(e.createdAt).isAfter(dayjs(lastSeenNotificationsAt || 0)))
    .filter((e) => e.eventCategory === EventCategories.UserCelebratedEvent)
    .filter((e) => e.params.eventCreatedBy === user._id)
    .filter((e) => !!e.indicatorId)
    .sort(
      (a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
    ) as OurEvent<UserCelebratedEventParams>[]
}

// @hristo @nadya use this one
export const getLikedByText = (event?: OurEvent<unknown>) => {
  const likedByNames = getAllLikedByNames(event?.likes || {})

  if (!event) return getFigmaText(Texts.celebrationItemCelebratingEmptyText)

  if (likedByNames.length === 0) return getFigmaText(Texts.celebrationItemCelebratingEmptyText)

  if (likedByNames.length > MAX_LIKED_NUMBER)
    return formatText(getFigmaText(Texts.celebrationItemCelebratingMoreThan3), [
      likedByNames.filter((x, i) => i < MAX_LIKED_NUMBER).join(', ')
    ])

  return likedByNames.join(', ')
}

// @hristo @nagya use this one
export const getLikedByNumber = (likes?: OurEvent<unknown>['likes']) => Object.keys(likes || {}).length

export function getAllLikedByNames(likes: OurEvent<unknown>['likes'] = {}) {
  return Object.keys(likes).map((key) => likes[key].name)
}

export function getTextForUnseenCelebrationEvent(event: OurEvent<UserCelebratedEventParams>, org?: Organization) {
  const { params } = event
  const { celebratedBy, indicator } = params

  const textKey = applicationNotificationColleagueCelebrated
  const text = getText(formatText(getFigmaText(textKey), [celebratedBy, indicator.name]), org)
  return text
}

// @hristo @nagya use this one
export function getHeaderSuffixText(params: Partial<EventParams>, formatOptions: any = { boldParamsIndex: 1 }) {
  const { indicator, prevValue } = params as UserAddIndicatorValueParams
  const { values = [] } = indicator || {}
  const value = values[values.length - 1] as Value
  let _value: string = value?.value.toString()
  let _prevValue: any = prevValue?.toString()

  if (indicator.unit === Unit.BOOLEAN) {
    // eslint-disable-next-line prettier/prettier
    _prevValue =
      _prevValue === '1' ? getFigmaText(Texts.indicatorBooleanTrue) : getFigmaText(Texts.indicatorBooleanFalse)
    _value = _value === '1' ? getFigmaText(Texts.indicatorBooleanTrue) : getFigmaText(Texts.indicatorBooleanFalse)
  }

  const text = formatText(getFigmaText(Texts.cssCelebrationItemFromToValue), [_prevValue, _value], formatOptions)

  return text
}
