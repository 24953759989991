export enum ServerErrorCode {
  INVITATION_TOKEN_EXPIRED = 'INVITATION_TOKEN_EXPIRED',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  NO_CONNECTION = 'NO_CONNECTION'
}

export default class ServerError extends Error {
  serverErrorCode: ServerErrorCode
  constructor(message: string, serverErrorCode: ServerErrorCode) {
    super(message)
    this.serverErrorCode = serverErrorCode
  }
}
