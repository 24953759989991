import dayjs from 'dayjs'
import invariant from 'invariant'
import { DEFAULT_STATE, State } from '../hooks/useAppState'
import { getEmailForFirebaseUser } from '../ygdrasil/libs/Common'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { createObjective } from '../ygdrasil/libs/DBApiHandler'
import * as ServerHelper from '../ygdrasil/libs/Helper'
import { toCreateObjectiveRequest } from '../ygdrasil/libs/Mapper'
import { OnboardingState } from '../ygdrasil/types/Onboarding'
import { CreateOrganizationRequest, FirebaseUser, ObjectiveType, Organization } from '../ygdrasil/types/types'
import { createUser } from './ClientProcessHandler'

export const onboardUser = (
  onboardingState: OnboardingState,
  firebaseUser: FirebaseUser,
  state: State
): Promise<State> => {
  const req = mapCreateOrgRequest(firebaseUser, onboardingState)

  const {
    data: { firstObjective: objective }
  } = onboardingState

  return createUser(req, firebaseUser, state).then((user) => {
    const orgId: string = user.organizationIds[0]
    invariant(orgId, `Cant find first org for user %s`, user._id)
    const state = { ...DEFAULT_STATE, selectedOrganizationId: orgId, user }
    if (!objective) return state
    return createObjective(
      toCreateObjectiveRequest(
        {
          name: objective.name,
          startDate: objective.startDate || dayjs().startOf('quarter').format(YYYY_MM_DD),
          endDate: objective.accomplishBy,
          type: ObjectiveType.STRATEGIC
        },
        state
      ),
      state
    ).then(() => state)
  })
}

export const shouldCreateNewOrganization = (user: FirebaseUser, elligebleOrgs: Organization[]): boolean => {
  const email = getEmailForFirebaseUser(user)
  if (!email) return false
  invariant(email, `Cant find email on user %s`, user.uid)
  return ServerHelper.shouldCreateNewOrganization(email, elligebleOrgs)
}
export function mapCreateOrgRequest(firebaseUser: FirebaseUser, onboardingState: OnboardingState) {
  const { uid } = firebaseUser
  const email = getEmailForFirebaseUser(firebaseUser)
  const {
    data: { name, vision: _vision, countryCode, howManyCollegues, firstObjective: objective, nameOfUser }
  } = onboardingState
  invariant(email, `Cant find email on user %s`, firebaseUser?.uid)
  const vision = _vision ? { name: _vision } : undefined
  const req: CreateOrganizationRequest = {
    nameOfUser,
    name,
    vision,
    countryCode,
    howManyCollegues,
    uid,
    domains: []
  }

  return req
}
