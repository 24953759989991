import React, { useState } from 'react'
import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, SMALL_SPACING_PX, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useIsSelectedObjective from '../hooks/useSelectedObjective'
import { getTextWithDots } from '../libs/StrategyViewObjectiveTreeHelper'
import { ROADMAP_OBJECTIVE_NAME_LENGTH } from '../libs/HardCodedSizes'
import { getColor, getBorder } from '../libs/RoadmapHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { getCurrentRatio, getParent } from '../ygdrasil/libs/ObjectiveHelper'
import Texts from '../ygdrasil/libs/Texts'
import { Objective, ObjectiveType } from '../ygdrasil/types/types'
import Box from './Box'
import StrategyViewObjectiveListObjectiveMenu from './StrategyViewObjectiveListObjectiveMenu'
import FigmaTextWithSearchResult from './FigmaTextWithSearchResult'
import { MoreAction } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import WithTooltipOverflow from './WithTooltipOverflow'
import shadows from '../enums/Shadows'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'

export const RoadmapObjective = ({
  objective,
  allObjectives
}: {
  objective: Objective
  allObjectives: Objective[]
}) => {
  const { state } = useAppState()
  const { searchState } = state
  const { searchText } = searchState
  const [showMoreAction, setShowMoreAction] = useState(false)
  const { isSelected, toggleObjective } = useIsSelectedObjective(objective)
  const allItems = useAllItems()
  const ratio = getCurrentRatio(objective, allItems)

  return (
    <Container
      spacing={smallSpacing}
      onMouseEnter={() => setShowMoreAction(true)}
      onMouseLeave={() => setShowMoreAction(false)}
      type={objective.type}
      onClick={toggleObjective}
      isSelected={isSelected}
      fullWidth
    >
      <Box fullWidth direction="row" align="stretch">
        <Box spacing="1px" fullPadding>
          <LeftContainer isSelected={isSelected} type={objective.type} fullHeight />
        </Box>
        <Box top left spacing={smallSpacing} fullWidth justify="space-between">
          <Box multiline spacing={tinySpacing} bottom fullWidth>
            <Box fullWidth right spacing={smallSpacing} link>
              <WithTooltipOverflow text={objective.name} ellipsisTextKey={Texts.cssRoadmapObjectiveTitle}>
                <FigmaTextWithSearchResult
                  textKey={Texts.cssRoadmapObjectiveTitle}
                  text={getTextWithDots(objective.name, ROADMAP_OBJECTIVE_NAME_LENGTH)}
                  searchText={searchText}
                />
              </WithTooltipOverflow>
            </Box>
          </Box>
        </Box>
        <Box right spacing={smallSpacing} alignSelf="center">
          <MoreActionWithMenu
            visible={showMoreAction}
            Menu={(props) => <StrategyViewObjectiveListObjectiveMenu objective={objective} {...props} />}
            MoreAction={(props) => <MoreAction {...props} />}
          />
        </Box>
      </Box>
      <ProgressLine width={`${ratio}%`} />
    </Container>
  )
}

const Container = styled(Box)<{ type: ObjectiveType; isSelected: boolean }>`
  border: ${({ type }) => getBorder(type)};
  border-radius: ${minBorderRadius};
  background-color: ${({ type, isSelected }) => getColor(type, isSelected)};
  box-shadow: ${shadows.gazelleBoxSoft};
  cursor: pointer;
`

const ParentNameContainer = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const LeftContainer = styled(Box)<{ type: ObjectiveType; isSelected: boolean }>`
  background-color: ${({ type, isSelected }) => getColor(type, isSelected)};
  width: ${SMALL_SPACING_PX / 1.5}px;
`

const ProgressLine = styled(Box)`
  /* GoodColorLight */
  height: ${SMALL_SPACING_PX / 4}px;
  background: ${Colors.good};
  border-radius: 1px;
`
