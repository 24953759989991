import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import { defaultSpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Images from '../ygdrasil/libs/Images'
import { AntiloopImageType, AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export const SettingsIntegrationsConnectionButton = ({
  imageKey,
  textKey,
  onClick
}: {
  textKey: AntiloopTextType
  imageKey: AntiloopImageType
  onClick?: () => unknown
}) => {
  return (
    <Container align="center" alignText="center" top bottom spacing={defaultSpacing} fullHeight link onClick={onClick}>
      <FigmaImageContainer imageKey={imageKey} />
      <Box top>
        <FigmaText textKey={textKey} />
      </Box>
    </Container>
  )
}
const Container = styled(Box)<{ isSelected?: boolean }>`
  background: ${({ isSelected }) => (isSelected ? Colors.primary40 : Colors.white)};
  border: 1px solid ${Colors.primary90};
  border-radius: ${minBorderRadius};
  width: ${Images.microsoftExcel.width * 1.5}px;
`
