import styled from '@emotion/styled'
import { Formik } from 'formik'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from '../ygdrasil/components/FigmaText'
import { A_COLOR_THAT_NEEDS_TO_BE_IN_FIGMA } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import { TextFieldOutlined } from './TextFieldOutlined'
import Button from './Button'
import { getGoogleSheetDriveItem } from '../ygdrasil/libs/CloudFunctionsApiHandler'
import useAppState from '../hooks/useAppState'
import { useState } from 'react'
import { CheckCircleRounded, ErrorRounded } from './Icons'
import * as Yup from 'yup'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import { GoogleSheetDriveItem } from '../ygdrasil/types/types'
import { GOOGLE_SERVICE_ACCOUNT_EMAIL } from '../libs/EditDatasourceMachineHelper'
import { EditDatasourceMachineViewProps } from '../ygdrasil/types/EditDatasourceMachine'

const ValidationSchema = Yup.object().shape({
  driveItemId: RequiredTextValidation()
})

type Props = EditDatasourceMachineViewProps & {
  onConnectionVerified?: (driveItemId: GoogleSheetDriveItem) => unknown
}

export function EditDatasourceMachineViewEditSheetConnectionForm({ onConnectionVerified, state: machineState }: Props) {
  const { data } = machineState
  const { dataSource, connection } = data
  const { state } = useAppState()
  const [hasVerifiedConnection, setHasVerifiedConnection] = useState<boolean>()
  const initialValues = {
    driveItemId: dataSource?.driveItemId || ''
  }

  const onSubmit = ({ driveItemId }: any) =>
    Promise.resolve()
      .then(() => getGoogleSheetDriveItem(driveItemId, state))
      .then((driveItem) => {
        if (!driveItem) return setHasVerifiedConnection(false)

        setHasVerifiedConnection(true)
        onConnectionVerified && onConnectionVerified(driveItem)
      })
      .catch(() => setHasVerifiedConnection(false))

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
      {(formProps) => (
        <Box fullWidth>
          <Box fullWidth>
            <Box top spacing={tinySpacing} fullWidth>
              <FigmaText textKey={Texts.googleSheetIntegrationStep1Label} />
            </Box>

            <Box top spacing={smallSpacing} fullWidth>
              <MessageContainer fullPadding spacing={tinySpacing} fullWidth>
                <Box right left>
                  <FigmaText textKey={Texts.googleSheetIntegrationAccountLabel} text={GOOGLE_SERVICE_ACCOUNT_EMAIL} />
                </Box>
              </MessageContainer>
            </Box>
            <Box top spacing={smallSpacing} fullWidth>
              <FigmaText textKey={Texts.googleSheetIntegrationStep2Label} />
            </Box>
            <Box top spacing={smallSpacing} fullWidth>
              <FigmaText textKey={Texts.adminGoogleIntegrationDocumentIdLabel} />
              <Box spacing={tinySpacing} top fullWidth>
                <TextFieldWithLabelFormik
                  formProps={formProps}
                  placeholderTextKey={Texts.adminGoogleIntegrationDocumentIdHelperText}
                  component={TextFieldOutlined}
                  name="driveItemId"
                  fullWidth
                />
              </Box>
              {hasVerifiedConnection !== true && (
                <Box spacing={smallSpacing} top fullWidth align="flex-end">
                  <Button
                    textKey={Texts.adminGoogleIntegrationVerifyConnectionButtonText}
                    spacing={smallSpacing}
                    onClick={formProps.handleSubmit as any}
                    loading={formProps.isSubmitting}
                  />
                </Box>
              )}
              <Box spacing={smallSpacing} top fullWidth>
                {hasVerifiedConnection === true && <SuccessMessage />}
                {hasVerifiedConnection === false && <ErrorMessage />}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  )
}
const MessageContainer = styled(Box)`
  background-color: ${A_COLOR_THAT_NEEDS_TO_BE_IN_FIGMA};
  border-radius: ${tinyBorderRadius};
`

function SuccessMessage() {
  return (
    <MessageContainer fullPadding spacing={smallSpacing} fullWidth>
      <Box fullWidth direction="row" align="center" justify="center">
        <Box right spacing={tinySpacing}>
          <CheckCircleRounded />
        </Box>
        <FigmaText textKey={Texts.googleSheetIntegrationSuccessfulConnectionText} />
      </Box>
    </MessageContainer>
  )
}

function ErrorMessage() {
  return (
    <MessageContainer fullPadding spacing={smallSpacing} fullWidth>
      <Box fullWidth direction="row" align="center" justify="center">
        <Box right spacing={tinySpacing}>
          <ErrorRounded />
        </Box>
        <FigmaText textKey={Texts.googleSheetIntegrationFailedConnectionText} />
      </Box>
    </MessageContainer>
  )
}
