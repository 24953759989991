import styled from '@emotion/styled'
import { minimumBorderRadius, tinyBorderRadius } from '../enums/BorderRadixes'
import { tinySpacing } from '../enums/Spacings'
import { ActivityStatus } from '../ygdrasil/enums/ActivityStatus'
import { ActivityStatusInfo, isOverDue } from '../ygdrasil/libs/ActivityHelper'
import Colors from '../ygdrasil/libs/Colors'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { Activity } from '../ygdrasil/types/types'
import Box from './Box'
import { NoWrapFigmaText } from './StyledComponents'

type Props = {
  activity: Activity
  isSelected?: boolean
  onClick?: () => void
  isActivityDialog?: boolean
}

export function ActivityStatusBadge({ activity, isSelected, onClick, isActivityDialog }: Props) {
  const { status } = activity
  let activityStatusInfo = ActivityStatusInfo[status] || ActivityStatusInfo[ActivityStatus.TODO]
  if (isSelected === false) activityStatusInfo = { ...activityStatusInfo, ...ActivityStatusInfo.isNotSelected }
  const { color, textKey, styleTextKey } = activityStatusInfo
  return (
    <Container color={color} fullPadding spacing={tinySpacing} onClick={onClick} isActivityDialog={isActivityDialog}>
      <NoWrapFigmaText styleTextKey={styleTextKey} textKey={textKey as AntiloopTextType} />
    </Container>
  )
}
const Container = styled(Box)<{ color?: string; isSelected?: boolean; isActivityDialog?: boolean }>`
  border: 1px solid ${Colors.neutral90};
  border-radius: ${({ isActivityDialog }) => (isActivityDialog ? minimumBorderRadius : tinyBorderRadius)};
  background-color: ${({ color }) => color};
`
