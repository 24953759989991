import Drawer, { DrawerProps } from './Drawer'

export const OurDrawer = (props: DrawerProps) => {
  return (
    <Drawer
      {...props}
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') return
        props.onClose && props.onClose(event, reason)
      }}
    />
  )
}
