import { styled } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import MaterialTableRow from '@mui/material/TableRow'
import invariant from 'invariant'
import { useEffect } from 'react'
import { slack } from '../libs/SlackHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { getValuesForConnection } from '../ygdrasil/libs/DatasourceHelper'
import Texts from '../ygdrasil/libs/Texts'
import { EditDatasourceMachineViewProps } from '../ygdrasil/types/EditDatasourceMachine'
import { SlackChannels, Value } from '../ygdrasil/types/types'

export default function EditDatasourceMachineViewVerifyDataTable(props: EditDatasourceMachineViewProps) {
  const {
    state: {
      data: { rows, connection, dataSource }
    }
  } = props

  invariant(connection, '!connection')

  const values: Value[] = getValuesForConnection(rows, connection)

  useEffect(() => {
    if (values.length === rows.length)
      slack(
        `Successfully fetched ${values.length} values for datasource ${dataSource?._id}`,
        SlackChannels.OPS_VERIFY_DATA
      )
    else
      slack(
        `Failed to fetch values for datasource ${dataSource?._id}
input: ${JSON.stringify(rows)}
output; ${JSON.stringify(values)}`,
        SlackChannels.OPS_VERIFY_DATA
      )
  }, [])
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FigmaText textKey={Texts.adminIntegrationTestTableCellHeaderId} />
          </TableCell>
          <TableCell>
            <FigmaText textKey={Texts.adminIntegrationTestTableCellHeaderDate} />
          </TableCell>
          <TableCell>
            <FigmaText textKey={Texts.adminIntegrationTestTableCellHeaderValue} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.map(({ date, value }, key) => (
          <TableRow key={key}>
            <TableCell>
              <FigmaText textKey={Texts.adminIntegrationTestTableCellHeaderId} text={(key + 1).toString()} />
            </TableCell>
            <TableCell>
              <FigmaText textKey={Texts.adminIntegrationTestTableCellHeaderDate} text={date} />
            </TableCell>
            <TableCell>
              <FigmaText textKey={Texts.adminIntegrationTestTableCellHeaderValue} text={value.toString()} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const TableRow = styled(MaterialTableRow)`
  & .MuiTableCell-root {
    padding: 0px;
  }
`
