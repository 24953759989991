/* eslint-disable prettier/prettier */
import { Objective, Organization } from '../ygdrasil/types/types'
import React from 'react'
import Notificaiton, { NotificationProps } from './Notification'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { _ErrorNotification } from './ErrorNotificaiton'
import FigmaTextWithStyleOverrides from '../components/FigmaTextWithStyleOverrides'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'

export const ErrorNotificaiton = _ErrorNotification
export const NewObjectiveNotification = (props: Partial<NotificationProps> & { objective: Objective; org?: Organization }) => <Notificaiton {...props} textKey={Texts.applicationNotificationNewObjectiveCreatedText} text={formatText(getTextForTextKey(Texts.applicationNotificationNewObjectiveCreatedText, props.org), [props.objective.name], { boldParams: true })} />
export const NewObjectiveNotificationHidden = (props: Partial<NotificationProps> & { objective: Objective; }) => <Notificaiton {...props} textKey={Texts.applicationNotificationNewObjectiveCreatedButHiddenText} element={<FigmaTextWithStyleOverrides textKeys={{en: Texts.applicationNotificationNewObjectiveCreatedButHiddenText, sv: Texts.applicationNotificationNewObjectiveCreatedButHiddenTextSwe}}/>} />
export const SuccessNotification = (props: Partial<NotificationProps>) => <Notificaiton textKey={Texts.applicationNotificationNewObjectiveCreatedText2} text="👍" {...props}/>
