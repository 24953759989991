import { CheckinStatus } from '../enums/CheckinStatus'
import Colors from './Colors'
import Texts from './Texts'
import { AntiloopTextType } from '../types/Antiloop'

type CheckInStatusContentItem = { textKey: AntiloopTextType; shortTextKey: AntiloopTextType; color: string }
const CHECK_IN_STATUS: { [checkInStatus: string]: CheckInStatusContentItem } = {
  [CheckinStatus.DONT_KNOW]: {
    textKey: Texts.checkinStatusOptionUnknown,
    shortTextKey: Texts.checkinStatusOptionUnknownShort,
    color: Colors.neutral90
  },
  [CheckinStatus.FEELS_GOOD]: {
    textKey: Texts.checkinStatusOptionOk,
    shortTextKey: Texts.checkinStatusOptionOkShort,
    color: Colors.good
  },
  [CheckinStatus.AT_RISK]: {
    textKey: Texts.checkinStatusOptionAtRisk,
    shortTextKey: Texts.checkinStatusOptionAtRiskShort,
    color: Colors.risk
  },
  [CheckinStatus.NOT_ACHIEVABLE]: {
    textKey: Texts.checkinStatusOptionProbablyNotArchievable,
    shortTextKey: Texts.checkinStatusOptionProbablyNotArchievableShort,
    color: Colors.bad
  }
}

export const getColorForCheckInStatus = (checkInStatus: CheckinStatus = CheckinStatus.DONT_KNOW) =>
  getCheckInStatusContentItem(checkInStatus).color

export const getTextKeyForCheckInStatus = (checkInStatus: CheckinStatus = CheckinStatus.DONT_KNOW) =>
  getCheckInStatusContentItem(checkInStatus).textKey

export const getShortTextKeyForCheckInStatus = (checkInStatus: CheckinStatus = CheckinStatus.DONT_KNOW) =>
  getCheckInStatusContentItem(checkInStatus).shortTextKey

export const getCheckInStatusContentItem = (checkInStatus: CheckinStatus = CheckinStatus.DONT_KNOW) =>
  CHECK_IN_STATUS[checkInStatus] || CHECK_IN_STATUS[CheckinStatus.DONT_KNOW]
