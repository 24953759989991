import dayjs from 'dayjs'
import EventCategories from '../enums/EventCategories'
import { KeyResult, User } from '../types/types'
import { OurEvent } from './EventHelper'

// named the first param new indicator because function assume that the new value is in the enwIndicator param
export function getEventCategoryForUpdateValueEvent(newIndicator: KeyResult, date: any) {
  const { values = [] } = newIndicator
  const lastValue = values[values.length - 1]

  if (dayjs(date).isBefore(lastValue?.date || 0)) return EventCategories.UserUpdatedIndicatorValue

  return EventCategories.UserAddIndicatorValue
}

export const getNewLikes = <T>(likes: OurEvent<T>['likes'], user: User) => {
  const res = { ...likes }

  if (res[user._id]) {
    delete res[user._id]
    return res
  }

  return { ...likes, [user._id]: { likedAt: dayjs().format(), name: user.name } }
}
