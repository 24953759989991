import { asObject } from '../ygdrasil/libs/Common'
import { getUsersOptions } from '../libs/EditKeyResultsSectionHelper'
import { useUsersForOrganization } from '../ygdrasil/libs/QueryHooks'
import useAppState from './useAppState'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'

export function useUserOptionsObject() {
  const { state } = useAppState()
  const { data: users = [] } = useUsersForOrganization(state)
  const config = useLoggedInConfig()
  const userOptions = getUsersOptions(users, state, config)
  const _userOptions = asObject(userOptions, 'value')
  return _userOptions
}
