// @flow
import { MsalProvider } from '@azure/msal-react'
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'
import StylesProvider from '@mui/styles/StylesProvider'
import { SnackbarProvider } from 'notistack'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Context, useAppStateContext } from '../hooks/useAppState'
import { MsalPublicClientApplication } from '../hooks/useMsal'
import '../libs/Fonts'
import theme from '../libs/theme'
import { SNACK_BAR_PROPS } from '../ygdrasil/libs/Consts'
import RoutePath from '../ygdrasil/libs/RoutePath'
import ErrorBoundaryProvider from './ErrorBoundaryProvider'
import GlobalComponents from './GlobalComponents'
import OAuth2RedirectPage from './OAuth2RedirectPage'
import OnAppMounted from './OnAppMounted'
import OnboardingPage from './OnboardingPage'
import PageOnboardingNotAvailalbe from './PageOnboardingNotAvailalbe'
import PageScheduleACall from './PageScheduleACall'
import { PageSignInSaga } from './PageSignInSaga'
import RootPage from './RootPage'
import SandboxOnboarding from './SandboxOnboarding'
import SandboxPage from './SandboxPage'
import SignInDisabled from './SignInDisabled'
import SignUpPage from './SignUpPage'

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } })

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const context = useAppStateContext()
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundaryProvider>
        <MsalProvider instance={MsalPublicClientApplication}>
          <StylesProvider injectFirst>
            <SnackbarProvider {...SNACK_BAR_PROPS}>
              <Context.Provider value={context}>
                <BrowserRouter>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <OnAppMounted />
                      <GlobalComponents />
                      <Switch>
                        <Route exact path={RoutePath.ROOT} component={RootPage} />
                        <Route exact path={RoutePath.SETTINGS_NOTIFICATIONS} component={RootPage} />
                        <Route exact path={RoutePath.VERIFY_ACTION_CODE} component={RootPage} />
                        <Route exact path={RoutePath.SANDBOX} component={SandboxPage} />
                        <Route exact path={RoutePath.SCHEDULE_A_CALL} component={PageScheduleACall} />
                        <Route exact path={RoutePath.ONBOARDING} component={OnboardingPage} />
                        <Route exact path={RoutePath.ONBOARDING_NOT_AVAILABLE} component={PageOnboardingNotAvailalbe} />
                        <Route
                          exact
                          path={RoutePath.ONBOARDING_NOT_AVAILABLE_SAGA}
                          component={(props) => <PageOnboardingNotAvailalbe {...props} onClickBack={null} />}
                        />
                        <Route exact path={RoutePath.USER_IS_DISABLED} component={SignInDisabled} />
                        <Route exact path={RoutePath.SIGN_IN_SAGA} component={PageSignInSaga} />
                        <Route exact path={RoutePath.SIGN_UP} component={() => <SignUpPage />} />
                        <Route exact path={RoutePath.SANDBOX_ONBOARDING} component={SandboxOnboarding} />
                        <Route exact path={RoutePath.REDIRECT} component={OAuth2RedirectPage} />
                      </Switch>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </BrowserRouter>
              </Context.Provider>
            </SnackbarProvider>
          </StylesProvider>
        </MsalProvider>
      </ErrorBoundaryProvider>
    </QueryClientProvider>
  )
}

export default App
