import MaterialButton, { ButtonProps } from '@mui/material/Button'
import React from 'react'
import styled from '@emotion/styled'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import FigmaTextLoader from './FigmaTextLoader'

type Props = {
  className?: string
  spacing?: string
  textKey?: AntiloopTextType
  onClick?: (e: any) => unknown
  loading?: boolean
}

const ButtonWhite: React.FC<Props> = (props) => {
  const { className, textKey, children, loading, ...buttonProps } = props
  return (
    <StyledButton className={className} {...buttonProps} pointer>
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader
            textKey={Texts.onboardingMobileContinueButtonText}
            text={textKey ? getFigmaText(textKey) : undefined}
            loading={loading}
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(Box)<{ spacing?: string }>`
  padding-left: ${({ spacing }) => spacing || bigSpacing};
  padding-right: ${({ spacing }) => spacing || bigSpacing};
  padding-top: ${tinySpacing};
  padding-bottom: ${tinySpacing};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  background-color: ${Colors.whiteBg};
  border: 1px solid ${Colors.primaryBase};
  border-radius: ${tinyBorderRadius};
`

export default ButtonWhite
