// @flow
import React from 'react'
import { useToggleChatBubble } from '../hooks/useToggleChatBubble'

import EditActivityDialogForm, { CreateEditActivityFormProps } from './EditActivityDialogForm'
import { EditObjectiveDialogContainer } from './EditObjectiveDialog'

function EditActivityDialog(props: CreateEditActivityFormProps) {
  useToggleChatBubble()

  return (
    <EditObjectiveDialogContainer fullHeight>
      <EditActivityDialogForm {...props} />
    </EditObjectiveDialogContainer>
  )
}

export default EditActivityDialog
