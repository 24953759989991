import React from 'react'
import { getAllChildObjectives } from '../ygdrasil/libs/ObjectiveHelper'
import { Objective } from '../ygdrasil/types/types'
import { tinySpacing } from '../enums/Spacings'
import useVisibleObjectives from '../ygdrasil/hooks/useVisibleObjectives'
import { archiveObjective } from '../libs/ClientProcessHandler'
import { DELETE_OBJECTIVE_MODAL_MAX_WIDTH } from '../libs/HardCodedSizes'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaText from '../ygdrasil/components/FigmaText'
import FigmaTextLoader from './FigmaTextLoader'
import Modal, { ModalProps } from './Modal'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import useAppState from '../hooks/useAppState'

type Props = { objective: Objective } & ModalProps
export function ModalArchiveObjective(props: Props) {
  const { objective: o, onClose } = props
  const { data: allObjectives = [] } = useVisibleObjectives()
  const [isLoading, setIsLoading] = React.useState(false)
  const childObjectives = getAllChildObjectives(o, allObjectives)
  const { state } = useAppState()
  const { data: org } = useOrganization(state)

  const onClickArchive = (e) => {
    e.stopPropagation() // Without this line onClick method on FocusedObjective is fired => bad
    Promise.resolve(setIsLoading(true))
      .then(() => archiveObjective(o, allObjectives))
      .finally(() => {
        onClose(e)
        setIsLoading(false)
      })
  }

  const onClickCancel = onClose
  return (
    <Modal {...props}>
      <Box fullWidth style={{ maxWidth: DELETE_OBJECTIVE_MODAL_MAX_WIDTH }}>
        <Box fullWidth bottom>
          <FigmaText
            textKey={Texts.dialogArchiveChildrenText}
            text={formatText(getTextForTextKey(Texts.dialogArchiveChildrenText, org), [childObjectives.length])}
          />
          <FigmaText textKey={Texts.dialogArchiveChildrenConfirm} />
        </Box>
        <Box direction="row" align="center" justify="flex-end" fullWidth>
          <Button
            textKey={Texts.cancelButtonText}
            onClick={onClickCancel}
            buttonType="secondary"
            spacing={tinySpacing}
          />
          <Box left spacing={tinySpacing}>
            <Button onClick={onClickArchive} spacing={tinySpacing}>
              <FigmaTextLoader textKey={Texts.onboardingContinueButtonText} loading={isLoading} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
