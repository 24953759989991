import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import React from 'react'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { SearchDates } from '../ygdrasil/types/types'
import { InitialValues } from './EditObjectiveDialogHelper'

export function formatValues(
  { startDate, endDate }: SearchDates,
  formProps: FormikProps<InitialValues>
): React.SetStateAction<InitialValues> {
  return {
    ...formProps.values,
    startDate: dayjs(startDate).format(YYYY_MM_DD),
    endDate: dayjs(endDate).format(YYYY_MM_DD)
  }
}
