import { useState } from 'react'
import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import { formatObjectivePeriod } from '../ygdrasil/libs/ObjectiveHelper'
import { getDefaultSearchDates } from '../ygdrasil/libs/SearchPeriodHelper'
import Texts from '../ygdrasil/libs/Texts'
import { Objective } from '../ygdrasil/types/types'
import Box from './Box'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import { ModalSelectPeriod } from './ModalSelectPeriod'

type Props = { onModalOpen?: () => unknown; onModalClose?: () => unknown }
export default function StrategyViewObjectivesHeader({ onModalOpen, onModalClose }: Props) {
  const [showModal, setShowModal] = useState(false)
  const { state } = useAppState()
  const { searchState } = state
  const { searchDates = getDefaultSearchDates(state) } = searchState
  const textKey = !!state.selectedTeamId
    ? Texts.pageContentTeamObjectivesSubHeader
    : Texts.pageContentStrategicObjectivesSubHeader
  const periodKey = Texts.dateformat2Y

  const onClose = () => {
    setShowModal(false)
    onModalClose && onModalClose()
  }
  const onOpen = () => {
    setShowModal(true)
    onModalOpen && onModalOpen()
  }
  return (
    <Box fullWidth direction="row" justify="space-between">
      {showModal && <ModalSelectPeriod onClose={onClose} />}
      {state.selectedDashboardView !== 'roadmap' && (
        <Box fullWidth>
          <Box direction="row" fullWidth justify="flex-start">
            <FigmaTextForStrategyModel textKey={textKey} />
            <Box spacing={tinySpacing} left link onClick={onOpen}>
              <FigmaText textKey={periodKey} text={formatObjectivePeriod(searchDates as Objective)} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
