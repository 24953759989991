import { StrategyViewObjectiveListRowDetailBodyIndicator } from './StrategyViewObjectiveListRowDetailBodyIndicator'
import { StrategyViewObjectiveListRowDetailBodyObjective } from './StrategyViewObjectiveListRowDetailBodyObjective'
import { StrategyViewObjectiveListRowDetailProps } from './StrategyViewObjectiveListRowDetailHelper'

export function StrategyViewObjectiveListRowDetailBody(props: StrategyViewObjectiveListRowDetailProps) {
  const { item, parentItem, onClickClose } = props
  if (item.data.searchType === 'objective') return <StrategyViewObjectiveListRowDetailBodyObjective {...props} />
  if (item.data.searchType === 'keyResult' && parentItem)
    return (
      <StrategyViewObjectiveListRowDetailBodyIndicator
        item={item}
        parentItem={parentItem}
        onClickClose={onClickClose}
      />
    )

  return null
}
