import styled from '@emotion/styled'
import { useContext } from 'react'
import { smallBorderRadius } from '../enums/BorderRadixes'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import Images from '../ygdrasil/libs/Images'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import StrategyViewObjectiveTree from './StrategyViewObjectiveTree'
import shadows from '../enums/Shadows'
import { defaultSpacing, littleSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { SizeContext } from './StrategyViewObjectivesFocusedObjectivesView'
import useTreeItems from './useTreeItems'

const StrategyViewObjectiveListTreeDropDown: React.FC = () => {
  const { setSize } = useContext(SizeContext)
  const { state, setState } = useAppState()
  const { tree } = useTreeItems()

  const selectedTreeItemId = state.searchState.selectedItemId
  const currentTreeItem = !!selectedTreeItemId && tree.items[selectedTreeItemId]
  if (!currentTreeItem) return null

  return (
    <Box
      fullWidth
      position="relative"
      ref={(currRef) => {
        if (currRef) {
          setSize(currRef.getBoundingClientRect().bottom)
        }
      }}
      style={{ zIndex: 9999 }}
    >
      <Wrapper
        fullPadding
        spacing={littleSpacing}
        fullWidth
        direction="row"
        align="center"
        justify="space-between"
        onClick={() => setState({ ...state, showDropDownList: !state.showDropDownList })}
      >
        <FigmaText textKey={Texts.selectComponentHelperText} text={currentTreeItem.data.data.name} />
        <FigmaImageContainer imageKey={Images.dropDownIcon} />
      </Wrapper>
      {state.showDropDownList && (
        <DropDownContainer fullWidth fullPadding position="absolute">
          <StrategyViewObjectiveTree hideDrawer />
        </DropDownContainer>
      )}
    </Box>
  )
}

export default StrategyViewObjectiveListTreeDropDown

const Wrapper = styled(Box)`
  background-color: ${Colors.whiteBg};
  border-radius: ${smallBorderRadius};
  border: 1px solid ${Colors.neutralVariant80};
  margin-top: ${defaultSpacing};
`

const DropDownContainer = styled(Box)`
  background-color: ${Colors.whiteBg};
  border-radius: ${smallBorderRadius};
  box-shadow: ${shadows.soft};
  top: 66px;
  left: 0;
`
