import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import Shadows from '../enums/Shadows'
import { minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import { NoWrapFigmaText } from './StyledComponents'

export const SuccessViewHeaderToggleButton = ({
  textKey,
  onClick,
  isSelected
}: {
  textKey: AntiloopTextType
  onClick: () => unknown
  isSelected: boolean
}) => {
  const Container = isSelected ? SelectedButtonContainer : UnSelectedButtonContainer
  const styleTextKey = isSelected ? Texts.successViewGraphViewButton : Texts.successViewSheetViewButton
  return (
    <Container left right align="center" onClick={onClick}>
      <Box spacing={minSpacing}>
        <NoWrapFigmaText styleTextKey={styleTextKey} textKey={textKey} />
      </Box>
    </Container>
  )
}

const UnSelectedButtonContainer = styled(Box)`
  background: 'transparent';
  cursor: pointer;
  padding: ${smallSpacing} ${tinySpacing};
`

const SelectedButtonContainer = styled(UnSelectedButtonContainer)`
  background: ${Colors.primaryBase};
  border-radius: ${minBorderRadius};
  box-shadow: ${Shadows.gazelleBoxSoft};
`
