import { styled } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useContext, useState } from 'react'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import shadows from '../enums/Shadows'
import useAppState, { State } from '../hooks/useAppState'
import { useMediaQueryRules } from '../hooks/useMediaQuery'
import useResponsive from '../hooks/useResponsive'
import { isSearching } from '../libs/StrategyViewObjectiveTreeHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { SearchState } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import Drawer from './Drawer'
import FigmaTextForStrategyModel from './FigmaTextForStrategyModel'
import Loader from './Loader'
import StrategyViewObjectiveListEmptySearchState from './StrategyViewObjectiveListEmptySearchState'
import StrategyViewObjectiveListEmptyState from './StrategyViewObjectiveListEmptyState'
import { StrategyViewObjectiveListRow } from './StrategyViewObjectiveListRow'
import { StrategyViewObjectiveListRowDetail } from './StrategyViewObjectiveListRowDetail'
import { StrategyViewObjectiveListRowPeriodHeader } from './StrategyViewObjectiveListRowPeriod'
import { StrategyViewObjectiveListRowTeamsHeader } from './StrategyViewObjectiveListRowTeams'
import { SizeContext } from './StrategyViewObjectivesFocusedObjectivesView'
import { TableBox } from './TableBox'
import useTreeItems from './useTreeItems'

export default function StrategyViewObjectiveList({
  searchState: _searchState,
  hideDrawer
}: {
  searchState?: Partial<SearchState>
  hideDrawer?: boolean
}) {
  const { size } = useContext(SizeContext)
  const { state, setState } = useAppState()
  const [showDrawer, setShowDrawer] = useState(false)
  const { tree, rootItems, isLoading } = useTreeItems({ searchState: _searchState } as State)
  const { searchState, showListView } = state

  const isEmptyState = Object.keys(tree.items).length <= 1
  const { shouldShowExtraColumns } = useMediaQueryRules()

  const _isSeaching = isSearching(searchState)
  const { isTablet } = useResponsive()

  if (isLoading && isEmptyState)
    return (
      <Box fullWidth align="center">
        <Loader />
      </Box>
    )

  if (isEmptyState && _isSeaching) return <StrategyViewObjectiveListEmptySearchState />
  if (isEmptyState) return <StrategyViewObjectiveListEmptyState />

  const onSelectItem = (item: Item) => {
    let itemId = item.id

    // TODO WRITE TEST, should set first keyResult as selectedItemId if only one keyResult
    if (
      item.data.searchType === 'objective' &&
      item.children.length === 1 &&
      item.children[0].data.searchType === 'keyResult'
    )
      itemId = item.children[0].id

    setState({
      ...state,
      searchState: {
        ...searchState,
        selectedItemId: itemId
      },
      showListView: isTablet ? false : true,
      showDropDownList: false
    })

    setShowDrawer(true)
  }

  return (
    <>
      {!hideDrawer && (
        <Drawer
          open={!!searchState.selectedItemId && showDrawer}
          content={
            <StrategyViewObjectiveListRowDetail
              tree={tree}
              onSelectItem={onSelectItem}
              onClickClose={() => {
                setShowDrawer(false)
                setState({ ...state, showListView: true })
              }}
            />
          }
          variant="persistent"
          onClose={() => {
            setShowDrawer(false)
            setState({ ...state, showListView: true })
          }}
          sx={{
            '& .MuiPaper-root': {
              top: isTablet ? `${size + 16}px` : '80px',
              left: isTablet ? 74 : undefined,
              height: 'calc(100% - 80px)',
              borderTop: `1px solid ${Colors.neutral80}`,
              borderTopLeftRadius: tinyBorderRadius,
              boxShadow: shadows.heavy,
              position: hideDrawer || showListView ? 'fixed' : 'static'
            }
          }}
          anchor={!state.showListView ? 'left' : 'right'}
        />
      )}
      {(hideDrawer || showListView) && (
        <Box fullWidth direction="row">
          <Table>
            {!state.showDropDownList && (
              <TableHead>
                <StyledTableRow>
                  <TableCell width="44%">
                    <FigmaTextForStrategyModel textKey={Texts.objectiveListHeaderObjectiveSumary} />
                  </TableCell>
                  {shouldShowExtraColumns && (
                    <>
                      <TableCell width="13%">
                        <TableBox>
                          <StrategyViewObjectiveListRowPeriodHeader />
                        </TableBox>
                      </TableCell>
                      <TableCell width="5%" align="center">
                        <TableBox>
                          <FigmaText textKey={Texts.objectiveListHeaderObjectiveLead} />
                        </TableBox>
                      </TableCell>
                      <TableCell width="15%">
                        <TableBox>
                          <StrategyViewObjectiveListRowTeamsHeader />
                        </TableBox>
                      </TableCell>
                    </>
                  )}
                  <TableCell width="10%">
                    <TableBox>
                      <FigmaText textKey={Texts.objectiveListHeaderObjectiveProgress} />
                    </TableBox>
                  </TableCell>
                  <TableCell width="10%">
                    <TableBox alignText="center">
                      <FigmaText textKey={Texts.objectiveListHeaderObjectiveConfidence} />
                    </TableBox>
                  </TableCell>
                  <TableCell width="3%"></TableCell>
                </StyledTableRow>
              </TableHead>
            )}
            <TableBody>
              {rootItems.map((item) => (
                <StrategyViewObjectiveListRow
                  key={item.id}
                  items={rootItems}
                  item={item}
                  level={0}
                  onSelectItem={onSelectItem}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  )
}

const StyledTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    border-bottom: 0.25px solid ${Colors.secondary50};
    padding: 0px;
  }
`
