import Texts from '../ygdrasil/libs/Texts'
import { EditDatasourceMachineViewProps } from '../ygdrasil/types/EditDatasourceMachine'
import Box from './Box'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'
import SelectFieldWithLabel from './SelectFieldWithLabel'
import { smallSpacing } from '../enums/Spacings'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import { EVERY_DAY } from '../ygdrasil/libs/Consts'
import { Formik } from 'formik'
import Button from './Button'
import * as Yup from 'yup'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import { isValidData } from '../ygdrasil/libs/GoogleSheetsHelper'
import invariant from 'invariant'
import { GoogleSheetDriveItem } from '../ygdrasil/types/types'
import { captureAndNotifyError } from '../libs/ErrorHelper'

type Props = EditDatasourceMachineViewProps & {
  sheetData: any
  showSheetQueryError: boolean
  driveItem?: GoogleSheetDriveItem
  isLoadingData?: boolean
}

export function EditDatasourceMachineViewEditSheetDatasourceForm(props: Props) {
  const { state, onPressContinue, sheetData, isLoadingData } = props
  const { data } = state
  const { selectedDriveItem, selectedSheet, dataSource } = data

  const sheetId = selectedSheet?.id || dataSource?.sheetId
  const driveItemId = selectedDriveItem?.id || dataSource?.driveItemId

  const isDisabled = !!sheetId && !!driveItemId && !isValidData(sheetData) && !isLoadingData

  const onSubmit = ({ name }) =>
    Promise.resolve()
      .then(() => {
        invariant(sheetId, 'sheetId is required')
        invariant(driveItemId, 'driveItemId is required')

        return onPressContinue({
          ...state,
          data: {
            ...state.data,
            dataSource: {
              ...state.data.dataSource,
              name,
              driveItemId,
              sheetId
            },
            rows: sheetData?.rows || []
          }
        })
      })
      .catch((err) => {
        console.log(state)
        captureAndNotifyError(err)
      })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: dataSource?.name || `${selectedDriveItem?.name || ''} - ${selectedSheet?.name || ''}`,
        interval: EVERY_DAY
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        name: RequiredTextValidation()
      })}
    >
      {(formProps) => (
        <Box fullWidth>
          <Box fullWidth>
            <Box top spacing={smallSpacing}>
              <FigmaTextWithStyleOverrides textKeys={{ en: Texts.googleSheetIntegrationStep4Label }} />
            </Box>
            <Box fullWidth top spacing={smallSpacing}>
              <TextFieldWithLabelFormik
                fullWidth
                labelTextKey={Texts.adminIntegrationsCreateConnectionWizardStep2DataSourceNameLabel}
                name="name"
                formProps={formProps}
              />
            </Box>
          </Box>
          <Box fullWidth>
            <Box top spacing={smallSpacing}>
              <FigmaTextWithStyleOverrides textKeys={{ en: Texts.googleSheetIntegrationStep5Label }} />
            </Box>
            <Box fullWidth top>
              <SelectFieldWithLabel
                fullWidth
                name="interval"
                value={EVERY_DAY}
                labelTextKey={Texts.adminIntegrationsCreateConnectionWizardStep2SyncCadenceLabelLabel}
                options={[{ key: EVERY_DAY, value: EVERY_DAY }]}
              />
            </Box>
          </Box>
          <Box fullWidth top align="flex-end">
            <Button
              textKey={Texts.continueButtonText}
              onClick={formProps.handleSubmit as any}
              spacing={smallSpacing}
              loading={isLoadingData}
              disabled={isDisabled}
            />
          </Box>
        </Box>
      )}
    </Formik>
  )
}
