import { StrategyModel } from '../enums/StrategyModel'
import Term from '../enums/Term'
import { AntiloopTextType, Language } from '../types/Antiloop'
import { Organization } from '../types/types'
import { getFigmaText } from './TextRepository'
import Texts, { HardCodedTexts } from './Texts'
import { getLanguageCode } from './useLanguage'

type Options = {
  language: Language
  termsItemKey: TermsItemKey
}

export type AntiloopTerms<T> = {
  [Property in Term]: TermsItem<T>
}

export type Terms = {
  [languageCode: string]: TermsItem<string>
}

export type TermsItem<T> = {
  singular: T
  plural?: T
}

export enum TermsItemKey {
  SINGULAR = 'singular',
  PLURAL = 'plural'
}

const DEFAULT_OPTIONS = { termsItemKey: TermsItemKey.SINGULAR }

export const getTerm = (
  term: Term,
  org?: Organization,
  opts: Partial<Options> = DEFAULT_OPTIONS
): string | undefined => {
  const { termsItemKey, language = getLanguageCode() } = { ...DEFAULT_OPTIONS, ...opts }
  const defaultTermTextKey = DefaultTerms[term][termsItemKey]

  if (!defaultTermTextKey) return
  const defaultTermText = getFigmaText(defaultTermTextKey, language)

  if (!org) return defaultTermText
  switch (org.strategyModel) {
    case StrategyModel.CUSTOM:
      return org.terms?.[language]?.[term]?.[termsItemKey] || defaultTermText
    case StrategyModel.OKR:
      if (!OkrTerms[term][termsItemKey]) return
      return getFigmaText(OkrTerms[term][termsItemKey] as AntiloopTextType, language)
    default:
      return defaultTermText
  }
}

export const DefaultTerms: AntiloopTerms<AntiloopTextType> = {
  [Term.VISION]: {
    singular: Texts.applicationSettingCustomizableFieldVision
  },
  [Term.STRATEGIC_PILLAR]: {
    singular: Texts.applicationSettingCustomizableFieldStrategicPillar,
    plural: Texts.applicationSettingCustomizableFieldStrategicPillarPlural
  },
  [Term.STRATEGIC_GOALS]: {
    singular: Texts.applicationSettingCustomizableFieldStrategicGoals,
    plural: Texts.applicationSettingCustomizableFieldStrategicGoalsPlural
  },
  [Term.OBJECTIVES]: {
    singular: Texts.applicationSettingCustomizableFieldObjectives,
    plural: Texts.applicationSettingCustomizableFieldObjectivesPlural
  },
  [Term.INDICATORS]: {
    singular: Texts.applicationSettingCustomizableFieldIndicators,
    plural: Texts.applicationSettingCustomizableFieldIndicatorsPlural
  },
  [Term.ACTIVITY]: {
    singular: Texts.applicationSettingCustomizableFieldActivity,
    plural: Texts.applicationSettingCustomizableFieldActivityPlural
  },
  [Term.BUSINESS_PLAN]: {
    singular: Texts.applicationSettingCustomizableFieldBusinessPlan
  },
  [Term.SUCCESS_VIEW]: {
    singular: Texts.applicationSettingCustomizableFieldSuccessView
  },
  [Term.STRATEGY_VIEW]: {
    singular: Texts.applicationSettingCustomizableFieldStrategyView
  },
  [Term.DASHBOARD]: {
    singular: Texts.applicationSettingCustomizableFieldDashboard
  },
  [Term.ACTIVITYBOARD]: {
    singular: Texts.applicationSettingCustomizableFieldActionBoard
  }
}

const OkrTerms: AntiloopTerms<AntiloopTextType> = {
  ...DefaultTerms,
  [Term.INDICATORS]: {
    singular: Texts.applicationSettingCustomizableFieldIndicatorsOkr,
    plural: Texts.applicationSettingCustomizableFieldIndicatorsOkrPlural
  }
}

export type TermReplaceKeyType = {
  [replaceKey: string]: {
    term: Term
    termsItemKey: TermsItemKey
    isCapitalized?: boolean
  }
}

export const TermReplaceKey: TermReplaceKeyType = {
  $vision: { term: Term.VISION, termsItemKey: TermsItemKey.SINGULAR },
  $strategicpillars: { term: Term.STRATEGIC_PILLAR, termsItemKey: TermsItemKey.PLURAL },
  $strategicpillar: { term: Term.STRATEGIC_PILLAR, termsItemKey: TermsItemKey.SINGULAR },
  $strategicgoals: { term: Term.STRATEGIC_GOALS, termsItemKey: TermsItemKey.PLURAL },
  $strategicgoal: { term: Term.STRATEGIC_GOALS, termsItemKey: TermsItemKey.SINGULAR },
  $objective: { term: Term.OBJECTIVES, termsItemKey: TermsItemKey.SINGULAR },
  $objectives: { term: Term.OBJECTIVES, termsItemKey: TermsItemKey.PLURAL },
  $indicator: { term: Term.INDICATORS, termsItemKey: TermsItemKey.SINGULAR },
  $indicators: { term: Term.INDICATORS, termsItemKey: TermsItemKey.PLURAL },
  $activity: { term: Term.ACTIVITY, termsItemKey: TermsItemKey.SINGULAR },
  $activities: { term: Term.ACTIVITY, termsItemKey: TermsItemKey.PLURAL },
  $businessplan: { term: Term.BUSINESS_PLAN, termsItemKey: TermsItemKey.SINGULAR },
  $successview: { term: Term.SUCCESS_VIEW, termsItemKey: TermsItemKey.SINGULAR },
  $strategyview: { term: Term.STRATEGY_VIEW, termsItemKey: TermsItemKey.SINGULAR },
  $dashboard: { term: Term.DASHBOARD, termsItemKey: TermsItemKey.SINGULAR },
  $activityboard: { term: Term.ACTIVITYBOARD, termsItemKey: TermsItemKey.SINGULAR }
}
