import qs from 'query-string'
import { SignInSteps } from '../types/SignInTypes'

export type SignInPageQueryParams = {
  email?: string
  invitationToken?: string
  preferredLoginMethod?: string
  step?: SignInSteps
}

export type PageSignInSagaQueryParams = { id: string }

export type DeepLinkHelperQueryParams = {
  leadUserId?: string
  checkInKeyResultId?: string
  orgId?: string
  selectedItemId?: string
  showSettings?: string
  showDashboard?: string
} & UpdateValueInIndicatorQueryParams &
  WeeklyFocusParams

export type UpdateValueInIndicatorQueryParams = {
  showCelebrationView?: boolean
  celebratedEventId?: string
}

export type UseSessionStateQueryParams = {
  showDashboard?: string
  selectedDashboardLinkId?: string
  showDashboardCelebrations?: string
  showMyActivities?: string
  showSettings?: string
}

export type OAuth2RedirectParams = {
  code?: string
}

export type WeeklyFocusParams = {
  orgId?: string
  date?: string
  showMyActivities?: string
}

export type StripeCheckoutCallbackQueryParams = {
  stripeSuccess?: string // "false" | "true"
  showSettings?: string
}

type AllAvailableQueryParams = Partial<
  SignInPageQueryParams &
    PageSignInSagaQueryParams &
    DeepLinkHelperQueryParams &
    UseSessionStateQueryParams &
    UpdateValueInIndicatorQueryParams &
    WeeklyFocusParams &
    StripeCheckoutCallbackQueryParams
>

export default function useQueryParams<T>(search: string = window.location.search): T {
  // eslint-disable-next-line prettier/prettier
  const queryParams = (parse(search) as unknown) as T
  return queryParams as T
}

export const getQueryParams = useQueryParams

export const parse = (text: string) => {
  text = text.split('?')[1]
  return qs.parse(decodeURIComponent(text))
}

export function addQueryParamsToUrl<T extends AllAvailableQueryParams>(url: string, queryParams: T) {
  const keys = Object.keys(queryParams).filter((key) => !!queryParams[key])
  if (keys.length === 0) return url

  return keys.reduce((a, key, i, arr) => a + `${key}=${queryParams[key]}${i === arr.length - 1 ? '' : '&'}`, `${url}?`)
}

export const parseEmailQueryParam = (url: string) => {
  const suffix = url.split('email=')[1]
  if (!suffix) return ''
  return suffix.split('&')[0]
}
