import React from 'react'
import { Item, ItemData } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { StrategyViewObjectiveListRowProgressObjective } from './StrategyViewObjectiveListRowProgressObjective'
import { StrategyViewObjectiveListRowProgressKeyResult } from './StrategyViewObjectiveListRowProgressKeyResult'

export function StrategyViewObjectiveListRowProgress({ item, parentItem }: { item: Item; parentItem?: Item }) {
  return (
    <>
      {item.data.searchType === 'objective' && <StrategyViewObjectiveListRowProgressObjective itemData={item.data} />}
      {item.data.searchType === 'keyResult' && (
        <StrategyViewObjectiveListRowProgressKeyResult item={item} parentItem={parentItem} />
      )}
    </>
  )
}
