enum Term {
  VISION = 'VISION',
  STRATEGIC_PILLAR = 'STRATEGIC_PILLAR',
  STRATEGIC_GOALS = 'STRATEGIC_GOALS',
  OBJECTIVES = 'OBJECTIVES',
  INDICATORS = 'INDICATORS',
  BUSINESS_PLAN = 'BUSINESS_PLAN',
  SUCCESS_VIEW = 'SUCCESS_VIEW',
  STRATEGY_VIEW = 'STRATEGY_VIEW',
  ACTIVITY = 'ACTIVITY',
  DASHBOARD = 'DASHBOARD',
  ACTIVITYBOARD = 'ACTIVITYBOARD'
}

export default Term
