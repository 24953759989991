import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import { useTeams } from '../ygdrasil/libs/QueryHooks'
import { ItemData } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { ChipInputTeamList } from './ChipInputTeamList'

export function StrategyViewObjectiveListRowTeams({ data }: { data: ItemData }) {
  const { state } = useAppState()
  const { data: teams = [] } = useTeams(state)

  if (shouldShowTeamsColumn(data) === false) return null
  return <ChipInputTeamList teams={teams} teamIds={data.teamIds} enableInitials />
}

export function StrategyViewObjectiveListRowTeamsHeader({
  data,
  styleTextKey = Texts.objectiveListHeaderObjectivePeriod
}: {
  styleTextKey?: AntiloopTextType
  data?: ItemData
}) {
  if (shouldShowTeamsColumn(data) === false) return null
  return <FigmaText textKey={Texts.objectiveListHeaderObjectiveTeams} styleTextKey={styleTextKey} />
}

export const shouldShowTeamsColumn = (data?: ItemData) => {
  if (!data) return true // TODO should show Teams column for main table
  if (data.searchType === 'keyResult') return false
  return true
}
