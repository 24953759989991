import invariant from 'invariant'
import { useEffect, useState } from 'react'
import { ConversationResponse, ConversationStatus } from '../ygdrasil/libs/ConversationHelper'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import * as QueryHooks from '../ygdrasil/libs/QueryHooks'
import { Id } from '../ygdrasil/types/types'

const LOADING_STATUSES: Array<ConversationStatus> = [
  ConversationStatus.IDLE,
  ConversationStatus.WAITING_ON_RESPONSE,
  ConversationStatus.PROCESSING_RESPONSE
]

export const useConversationQueryResponse = (conversationId: Id): ConversationResponse | undefined => {
  const query = QueryHooks.useConversation(conversationId)
  const [conversationResponse, setConversationResponse] = useState<ConversationResponse>()

  useEffect(() => {
    if (!query.data?.responses) return
    const response = query.data?.responses.find((x, i, arr) => i === arr.length - 1)?.text || ''

    if (
      conversationResponse?.status === ConversationStatus.PROCESSING_RESPONSE &&
      conversationResponse.response.length > response.length
    )
      return

    setConversationResponse({ ...query.data, response })
  }, [query])

  useEffect(() => {
    if (query.data?.status !== ConversationStatus.FAILED) return
    if (!conversationResponse) return
    const message = query.data?.errorMessage
    invariant(message, 'No error message')
    eventEmitter.emit(Events.NEW_SERVER_ERROR)
    setConversationResponse({ ...conversationResponse, errorMessage: message })
  }, [conversationResponse?.errorMessage, query.error])

  return conversationResponse
}

export const isLoadingConversation = (c?: ConversationResponse) => !!c && LOADING_STATUSES.includes(c.status)
