export enum StripePriceIds {
  SpecialOBPPrice = 'price_1NCyE9JE34UKAJjp8yczqeEj', // 5 kr i månaden
  StartPaket100 = 'price_1N8pyZJE34UKAJjpjDspStVV', // 100 kr i månaden
  StartPaket495 = 'price_1N8pvDJE34UKAJjpc20hR2bl', // 495 kr i månaden
  StandardPaket1225 = 'price_1N2tYrJE34UKAJjpvsMwA378' // 1225 kr i månaden
}

export type OrgSubscriptionInfoType = {
  name: string
  users: number
  perUser: number
  invoiceText: string
  monthlySupport: string
  stripePriceId: StripePriceIds
}

export const DefaultOrgSubscriptionInfo: OrgSubscriptionInfoType = {
  name: 'Default',
  users: 5,
  perUser: 99,
  invoiceText: 'Our Big Picture - Start - Upp till 5 användare',
  monthlySupport: 'No',
  stripePriceId: StripePriceIds.StandardPaket1225
}

export const OrgSubscriptionInfo: { [orgId: string]: OrgSubscriptionInfoType } = {
  '02b5ei80z': {
    name: 'Responsive Tribes',
    users: 5,
    perUser: 99,
    invoiceText: 'anAweomeInvoiceText',
    monthlySupport: 'No',
    stripePriceId: StripePriceIds.SpecialOBPPrice
  },
  bodkiy2ma: {
    name: 'Made Perfect',
    users: 5,
    perUser: 99,
    invoiceText: 'Our Big Picture - Start - Upp till 5 användare',
    monthlySupport: 'No',
    stripePriceId: StripePriceIds.StartPaket495
  },
  u8w7q6hjm: {
    name: 'ProX',
    users: 9,
    perUser: 245,
    invoiceText: 'Our Big Picture - Standard - Upp till 9 användare',
    monthlySupport: 'No',
    stripePriceId: StripePriceIds.StandardPaket1225
  },
  '0zx8vqkhl': {
    name: 'Wasa',
    users: 5,
    perUser: 99,
    invoiceText: 'Our Big Picture - Start - Upp till 5 användare',
    monthlySupport: 'No',
    stripePriceId: StripePriceIds.StartPaket495
  },
  p37rxcohc: {
    name: 'LightLab',
    users: 14,
    perUser: 100,
    invoiceText: 'Our Big Picture - Standard - Upp till 14 användare',
    monthlySupport: 'No',
    stripePriceId: StripePriceIds.StartPaket100
  },
  k75j5kt8l: {
    name: 'ABC Revision',
    users: 6,
    perUser: 250,
    invoiceText: 'Our Big Picture - Standard - Upp till 6 användare',
    monthlySupport: 'No',
    stripePriceId: StripePriceIds.StandardPaket1225
  },
  '59g7mm7t5': {
    name: 'Kvänum',
    users: 24,
    perUser: 100,
    invoiceText: 'Our Big Picture - Standard - Upp till 24 användare',
    monthlySupport: 'Yes',
    stripePriceId: StripePriceIds.StartPaket100
  },
  l7m6x6d4a: {
    name: 'Tielman',
    users: 5,
    perUser: 245,
    invoiceText: 'Our Big Picture - Standard - Upp till 5 användare',
    monthlySupport: 'No',
    stripePriceId: StripePriceIds.StandardPaket1225
  },
  aam6jsm8t: {
    name: 'Klarna',
    users: 5,
    perUser: 245,
    invoiceText: 'Our Big Picture - Standard - Upp till 5 användare',
    monthlySupport: 'No',
    stripePriceId: StripePriceIds.SpecialOBPPrice
  }
}
