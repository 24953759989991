import styled from '@emotion/styled'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import MaterialTableRow from '@mui/material/TableRow'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import { inviteFromAdmin } from '../ygdrasil/libs/ProcessHandler'
import { useOrganization, useUsersForOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import InviteUserAddIcon from './InviteUserAddIcon'
import { SettingsMyOrganizationForm } from './SettingsMyOrganizationForm'
import { SettingsMyOrganizationRow } from './SettingsMyOrganizationRow'

export default function SettingsMyOrganization() {
  const config = useLoggedInConfig()
  const { state } = useAppState()
  const { data: { enableLimitedUser } = { enableLimitedUser: false } } = useOrganization(state)
  const { selectedOrganizationId } = state
  let { data: users = [] } = useUsersForOrganization(state)
  users = users.filter((u) => u.organizationIds.includes(selectedOrganizationId))

  return (
    <Box top>
      <SettingsMyOrganizationForm />
      {config.enableInviteUsers && (
        <Box fullWidth direction="row" justify="flex-end" bottom>
          <Box direction="row" align="center">
            <Box spacing={smallSpacing} right>
              <FigmaText textKey={Texts.settingsUsersListInviteButtonText} />
            </Box>
            <InviteUserAddIcon invite={inviteFromAdmin} />
          </Box>
        </Box>
      )}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <FigmaText textKey={Texts.adminUserListColumnCellUsersText} />
              </TableCell>
              <TableCell>
                <FigmaText textKey={Texts.adminUserListColumnCellCreatedText} />
              </TableCell>
              <TableCell>
                <FigmaText textKey={Texts.adminUserListColumnCellLastActiveText} />
              </TableCell>
              {enableLimitedUser && (
                <TableCell>
                  <FigmaText textKey={Texts.adminUserListColumnCellPermissionsText} />
                </TableCell>
              )}
              <TableCell>
                <FigmaText textKey={Texts.adminUserListColumnCellStatusText} />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, key) => (
              <SettingsMyOrganizationRow key={key} user={user} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const TableRow = styled(MaterialTableRow)`
  background-color: ${Colors.whiteBg};
`
