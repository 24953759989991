import styled from '@emotion/styled'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { minSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getStrategiPillarFilters } from '../libs/StrategyViewObjectivesHeaderStrategiPillarsHelper'
import Colors from '../ygdrasil/libs/Colors'
import { ALL_STRATEGIC_PILLAR } from '../ygdrasil/libs/Consts'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { StrategicPillar } from '../ygdrasil/types/types'
import Box from './Box'
import { NoWrapFigmaText } from './StyledComponents'

export function StrategyViewObjectivesHeaderStrategiPillars() {
  const { state, setSearchState } = useAppState()
  const { searchState } = state
  const { strategicPillar = ALL_STRATEGIC_PILLAR } = searchState
  const { data: org } = useOrganization(state)

  const spFilters = getStrategiPillarFilters(org)

  const onClickFilter = ({ _id: strategicPillar }: StrategicPillar) =>
    setSearchState({ ...searchState, selectedItemId: '', strategicPillar })
  return (
    <Box align="center" direction="row">
      <Box spacing={tinySpacing} top direction="row" align="center" style={{ flexWrap: 'wrap' }}>
        {spFilters.map((spFilter, i) => (
          <Box key={i} spacing={tinySpacing} left right>
            <Box top bottom spacing={minSpacing}>
              {spFilter._id === strategicPillar ? (
                <SelectedStrategicPillarContainer
                  spacing={tinySpacing}
                  top
                  bottom
                  onClick={() => onClickFilter(spFilter)}
                  pointer
                >
                  <Box left right>
                    <NoWrapFigmaText textKey={Texts.strategyViewPillarFilterSelectedText} text={spFilter.name} />
                  </Box>
                </SelectedStrategicPillarContainer>
              ) : (
                <UnselectedStrategicPillarContainer
                  spacing={tinySpacing}
                  top
                  bottom
                  onClick={() => onClickFilter(spFilter)}
                  pointer
                >
                  <Box left right>
                    <NoWrapFigmaText textKey={Texts.strategyViewPillarFilterUnselectedText} text={spFilter.name} />
                  </Box>
                </UnselectedStrategicPillarContainer>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const SelectedStrategicPillarContainer = styled(Box)`
  background-color: ${Colors.primaryBase};
  border-radius: ${tinyBorderRadius};
`

const UnselectedStrategicPillarContainer = styled(Box)`
  background-color: transparent;
  border: 1px dashed grey;
  border-radius: ${tinyBorderRadius};
`
