enum CloudFunction {
  getOrganizationsForNonOnboardedFirebaseUser = 'getOrganizationsForNonOnboardedFirebaseUser',
  throwError = 'throwError',
  throwServerError = 'throwServerError',
  shouldOnboard = 'shouldOnboard',
  shouldOnboardEmailSignup = 'shouldOnboardEmailSignup',
  deleteAccount = 'deleteAccount',
  deleteOrganization = 'deleteOrganization',
  invite = 'invite',
  createAuthenticationToken = 'createAuthenticationToken',
  createAuthenticationTokenForLoginToken = 'createAuthenticationTokenForLoginToken',
  createLoginWithCodeToken = 'createLoginWithCodeToken',
  acquireTokenByCode = 'acquireTokenByCode',
  acquireTokenForConnection = 'acquireTokenForConnection',
  syncDataForDatasource = 'syncDataForDatasource',
  getDataForGoogleSheetDriveItem = 'getDataForGoogleSheetDriveItem',
  getGoogleSheetDriveItem = 'getGoogleSheetDriveItem',
  getChaptGPTAnswer = 'getChaptGPTAnswer',
  continueConversation = 'continueConversation',
  createAuthenticationTokenForInternalUsers = 'createAuthenticationTokenForInternalUsers',
  getJiraIssues = 'getJiraIssues',
  createStripePortalLink = 'ext-firestore-stripe-payments-t8kj-createPortalLink'
}

export default CloudFunction
