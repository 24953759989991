import dayjs from 'dayjs'
import { ActivityStatus } from '../enums/ActivityStatus'
import { AntiloopTextType } from '../types/Antiloop'
import { Activity, Objective } from '../types/types'
import Colors from './Colors'
import { YYYY_MM_DD } from './Consts'
import { Item } from './StrategyViewObjectiveListHelper'
import Texts from './Texts'

export const ACTIVITY_CARD_TEXT_MAX_LENGTH = 50
export const ACTIVITY_CARD_DONE_TEXT_MAX_LENGTH = 15

type ActiviryStatusInfo = {
  textKey?: AntiloopTextType
  styleTextKey?: AntiloopTextType
  color: string
  nextStatus?: ActivityStatus
  blackTextKey?: AntiloopTextType
}

export const ActivityStatusInfo: {
  [key: string]: ActiviryStatusInfo
} = {
  [ActivityStatus.TODO]: {
    textKey: Texts.cssGraphActivityStatusTextTodo,
    color: Colors.statusTodo,
    nextStatus: ActivityStatus.IN_PROGRESS
  },
  [ActivityStatus.IN_PROGRESS]: {
    textKey: Texts.cssGraphActivityStatusTextInProgress,
    color: Colors.statusInProgress,
    nextStatus: ActivityStatus.DONE
  },
  [ActivityStatus.DONE]: {
    textKey: Texts.cssGraphActivityStatusTextDone,
    color: Colors.statusDone,
    nextStatus: ActivityStatus.TODO,
    blackTextKey: Texts.cssGraphActivityStatusTextDoneBlack
  },
  overDue: {
    textKey: Texts.cssGraphActivityStatusTextInProgress,
    color: Colors.statusOverdue
  },
  isNotSelected: {
    styleTextKey: Texts.cssGraphActivityStatusTextTodo,
    color: Colors.whiteBg
  }
}

export function isOverDue(item: Activity): boolean | undefined {
  if (!item.dueDate) return false
  if (item.status === ActivityStatus.DONE) return false
  return dayjs(item.dueDate).isBefore(dayjs())
}

export function isDone(activity: Activity) {
  // TODO @Anders ts-ignore is bad
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return activity.status === ActivityStatus.DONE || activity.done === true
}

export function getActivityStatusColor(activity: Activity): string | undefined {
  const statusInfo = ActivityStatusInfo[activity.status || ActivityStatus.TODO]
  if (isOverDue(activity)) return ActivityStatusInfo.overDue.color
  return statusInfo?.color || ActivityStatusInfo[ActivityStatus.TODO].color
}

export const getRatioLastChangeDateRatio = (date: string, objective: Objective) => {
  const { startDate, endDate } = objective
  const interval = dayjs(endDate).valueOf() - dayjs(startDate).valueOf()
  const x = dayjs(date).valueOf() - dayjs(startDate).valueOf()

  const ratio = x / interval

  if (ratio > 1) return 1
  if (ratio < 0) return 0
  return ratio
}

export function getLastDateChangeForActivity(a: Activity): string {
  return dayjs(a.lastStatusChangeAt || a.createdAt).format(YYYY_MM_DD)
}

export function getActivityItemsForGraphList(item: Item) {
  return item.children
    .map((child) => {
      const a = child.data.data as Activity
      let dueDate = a.status === ActivityStatus.DONE ? a.lastStatusChangeAt || a.updatedAt || a.createdAt : a.dueDate
      if (dueDate) dueDate = dayjs(dueDate).format(YYYY_MM_DD)
      return {
        ...child,
        dueDate
      }
    })
    .filter((child) => !!child.dueDate)
}

// TODO WRITE TEST should handle urls without https scheme
export function getGoogleIconApiUrl(activity: Activity): string | undefined {
  const url = activity.url?.startsWith('http') ? activity.url : `https://${activity.url}`
  return `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=16`
}
