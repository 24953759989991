import { useState } from 'react'
import { smallSpacing, SMALL_SPACING_PX, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getTermText } from '../libs/SettingsApplicationStrategyModelTermHelper'
import { StrategyModel } from '../ygdrasil/enums/StrategyModel'
import Term from '../ygdrasil/enums/Term'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { Language } from '../ygdrasil/types/Antiloop'
import Box from './Box'
import Button from './Button'
import { Row } from './SettingsApplicationStrategyModelTab'
import { SettingsApplicationStrategyModelTermForm } from './SettingsApplicationStrategyModelTermForm'
import { NoWrapFigmaText } from './StyledComponents'

export function SettingsApplicationStrategyModelTerm({
  shouldPadLeft,
  term,
  language,
  strategyModel,
  index
}: {
  index: number
  shouldPadLeft?: boolean
  term: Term
  language: Language
  strategyModel: StrategyModel
}): JSX.Element {
  const { state } = useAppState()
  const { data: org } = useOrganization(state)
  const [isEditing, setIsEditing] = useState(false)
  const _shouldPadLeft = !isEditing && shouldPadLeft
  const showCustomizeButton = !isEditing && strategyModel === StrategyModel.CUSTOM

  const text = getTermText(term, org, language)

  return (
    <Box style={{ paddingLeft: _shouldPadLeft ? index * SMALL_SPACING_PX : 0 }} bottom spacing={tinySpacing} fullWidth>
      <Row spacing={smallSpacing} fullPadding fullWidth>
        <Box direction="row" align="center" fullWidth>
          <NoWrapFigmaText textKey={Texts.cssTxtApplicationSettingCustomizableFieldText} text={text} />
          <Box fullWidth align="flex-end" visibility={showCustomizeButton ? 'visible' : 'hidden'}>
            <Button
              textKey={Texts.applicationSettingCustomizeButtonText}
              spacing={tinySpacing}
              onClick={() => setIsEditing(true)}
            />
          </Box>
        </Box>
        {isEditing && (
          <Box top>
            <SettingsApplicationStrategyModelTermForm
              term={term}
              language={language}
              onClose={() => setIsEditing(false)}
            />
          </Box>
        )}
      </Row>
    </Box>
  )
}
