import styled from '@emotion/styled'
import { FormikProps } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { minBorderRadius } from '../enums/BorderRadixes'
import { minSpacing, smallSpacing, SMALL_SPACING_PX } from '../enums/Spacings'
import {
  canEditActual,
  getUniqueActualTabIndex,
  getUniqueGoalTabIndex
} from '../libs/GrowthViewGrowthIndicatorValueHelper'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import { GrowthIndicator, GrowthIndicatorValue, GrowthIndicatorValueType } from '../ygdrasil/types/types'
import Box from './Box'
import { InitialValues } from './GrowthViewGrowthIndicatorRow'
import { GrowthViewGrowthIndicatorValueActual } from './GrowthViewGrowthIndicatorValueActual'
import { GrowthViewGrowthIndicatorValueGoal } from './GrowthViewGrowthIndicatorValueGoal'
import GrowthViewGrowthIndicatorValueTextFieldFormik from './GrowthViewGrowthIndicatorValueTextFieldFormik'

type Props = {
  value?: GrowthIndicatorValue
  nextValue?: GrowthIndicatorValue
  date: string
  growthIndicator: GrowthIndicator
  formProps: FormikProps<InitialValues>
  headerDates: string[]
  indicatorInputs?: any
  rowIndex?: number
  elementsLength?: number
  elementIndex?: number
  setCurrentSelectedInput?: React.Dispatch<React.SetStateAction<number | null>>
  currentSelectedInput?: number | null
}

export default function GrowthViewGrowthIndicatorValue(props: Props) {
  const {
    growthIndicator,
    date,
    formProps,
    headerDates,
    indicatorInputs,
    rowIndex,
    elementsLength,
    elementIndex,
    setCurrentSelectedInput,
    currentSelectedInput
  } = props
  let { value } = props
  const { values } = formProps.values
  const [editingGrowthValueType, setEditingGrowthValueType] = React.useState<GrowthIndicatorValueType | undefined>()
  const [isValueClicked, setIsValueClicked] = useState<boolean>(false)
  const [renderManually, setRenderManually] = useState<boolean>(false)
  const inputRef = useRef<any>(null)
  const emptyActual = value?.actual
  const config = useLoggedInConfig()
  const actualTabIndex = getUniqueActualTabIndex(rowIndex, elementIndex, elementsLength)
  const goalTabIndex = getUniqueGoalTabIndex(rowIndex, elementIndex, elementsLength)
  const isActual = editingGrowthValueType === GrowthIndicatorValueType.ACTUAL

  const onBlur = () => {
    setEditingGrowthValueType(undefined)
  }

  const handleActual = () => {
    if (!setCurrentSelectedInput) return
    if (isValueClicked && !canEditActual(date)) {
      const goalValueIndex = (Number(rowIndex) * 2 + 1) * Number(elementsLength) + Number(elementIndex) + 1
      setIsValueClicked(false)
      return setCurrentSelectedInput(goalValueIndex)
    }
    if (!canEditActual(date)) {
      const firstGoalIndex = (Number(rowIndex) * 2 + 1) * Number(elementsLength) + 1
      return setCurrentSelectedInput(firstGoalIndex)
    }
    indicatorInputs?.current[actualTabIndex]?.focus()
    inputRef?.current?.focus()
    config.enableEditSuccessView && setEditingGrowthValueType(GrowthIndicatorValueType.ACTUAL)
  }

  const handleGoal = () => {
    indicatorInputs?.current[goalTabIndex]?.focus()
    inputRef?.current?.focus()
    config.enableEditSuccessView && setEditingGrowthValueType(GrowthIndicatorValueType.GOAL)
  }

  const onClickActual = () => {
    !!setCurrentSelectedInput && setCurrentSelectedInput(actualTabIndex)
    setIsValueClicked(true)
    setRenderManually(!renderManually)
  }

  const onClickGoal = () => {
    !!setCurrentSelectedInput && setCurrentSelectedInput(goalTabIndex)
    setRenderManually(!renderManually)
  }

  useEffect(() => {
    if (currentSelectedInput === actualTabIndex) {
      handleActual()
    } else if (currentSelectedInput === goalTabIndex) {
      handleGoal()
    }
  }, [currentSelectedInput, renderManually])

  if (!value) value = { date }

  if (editingGrowthValueType)
    return (
      <ValueContainer
        fullWidth
        align="center"
        top
        bottom
        spacing={smallSpacing}
        isActual={isActual}
        isEdit={!!editingGrowthValueType}
      >
        <GrowthViewGrowthIndicatorValueTextFieldFormik
          growthIndicator={growthIndicator}
          type={editingGrowthValueType}
          date={date}
          formProps={formProps}
          onBlur={onBlur}
          headerDates={headerDates}
          ref={inputRef}
        />
      </ValueContainer>
    )

  return (
    <ValueContainer fullWidth align="center">
      <ActualValueContainer
        onClick={onClickActual}
        link
        align="center"
        tabIndex={0}
        emptyActual={emptyActual}
        ref={(ref) => {
          if (!ref || !indicatorInputs?.current) return
          indicatorInputs.current[actualTabIndex] = ref
        }}
      >
        <GrowthViewGrowthIndicatorValueActual value={value} growthIndicator={growthIndicator} values={values} />
      </ActualValueContainer>
      <GoalValueContainer
        onClick={onClickGoal}
        link
        align="center"
        tabIndex={0}
        ref={(ref) => {
          if (!ref || !indicatorInputs?.current) return
          indicatorInputs.current[goalTabIndex] = ref
        }}
      >
        <GrowthViewGrowthIndicatorValueGoal value={value} values={values} />
      </GoalValueContainer>
    </ValueContainer>
  )
}

export const ValueContainer = styled(Box)<{ isActual?: boolean; isEdit?: boolean }>`
  margin-top: ${({ isEdit }) => !isEdit && smallSpacing};
  margin-bottom: ${({ isEdit }) => !isEdit && smallSpacing};
  min-height: ${SMALL_SPACING_PX * 3}px;
  border-radius: ${minBorderRadius};
  background-color: ${({ isActual, isEdit }) => isEdit && (isActual ? Colors.whiteBg : Colors.primary90)};
`
const GoalValueContainer = styled(Box)`
  background-color: ${Colors.primary90};
  padding: 0 ${smallSpacing};
  width: 100%;
  margin-top: ${minSpacing};
`
const ActualValueContainer = styled(Box)<{ emptyActual?: number }>`
  border: ${({ emptyActual }) => (emptyActual ? '0px' : '1px dotted ' + Colors.neutral80)};
  border-radius: ${minBorderRadius};
  min-width: 25px;
  min-height: 22px;
`
