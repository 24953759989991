import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import { ItemData } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { findObjectiveForKeyResult } from '../ygdrasil/libs/ObjectiveHelper'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import { CheckInStatusForKeyResult, CheckInStatusForObjective } from './CheckinStatus'

export function StrategyViewObjectiveListRowCheckIn({ itemData: o }: { itemData: ItemData }) {
  const allItems = useAllItems()
  return (
    <>
      {o.searchType === 'objective' && <CheckInStatusForObjective objective={o.data as Objective} />}
      {o.searchType === 'keyResult' && (
        <CheckInStatusForKeyResult
          keyResult={o.data as KeyResult}
          objective={findObjectiveForKeyResult(o.data as KeyResult, allItems) as Objective}
        />
      )}
    </>
  )
}
