enum RoutePath {
  // Common
  ROOT = '/',

  // Freja
  SIGN_UP = '/sign_up',
  RESEND_SECURE_LINK = '/resend_secure_link',
  SANDBOX = '/sandbox',
  ONBOARDING = '/onboarding',
  VERIFY_ACTION_CODE = '/verify-email',
  USER_IS_DISABLED = '/user-is-disabled',
  ONBOARDING_NOT_AVAILABLE = '/onboarding-not-available',
  ONBOARDING_NOT_AVAILABLE_SAGA = '/onboarding-not-available_saga',
  SANDBOX_ONBOARDING = '/sandbox-onboarding',
  SETTINGS_NOTIFICATIONS = '/settings_notifications',
  SIGN_IN_SAGA = '/sign_in_saga',
  REDIRECT = '/redirect',
  SCHEDULE_A_CALL = '/schedule-a-call',

  DASHBOARD = '/dashboard',
  STRIPE_CALLBACK = '/stripe-callback',

  // Saga
  OBJECTIVE = '/objective',
  KEY_RESULT = '/keyresult',
  AUTH_TOKEN_DEPRECATED = '/auth_token', // used to sign in androids in saga
  ANDROID_LOGIN = '/android_login',
  INDICATOR_UPDATE = '/indicator_update',
  EVENT_CELEBRATION = '/event_celebration'
}

export default RoutePath
