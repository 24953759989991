import useAppState from '../hooks/useAppState'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import AutoComplete from './AutoComplete'

const ARCHIVED = getFigmaText(Texts.chipInputTextArchived)

export const GrowthViewChipInput = () => {
  const { state, setSearchState } = useAppState()
  const { searchState } = state
  const { showArchivedGrowthIndicators } = searchState
  const value: string[] = []

  if (showArchivedGrowthIndicators) value.push(ARCHIVED)

  const onUpdateInput = ({ target: { value: searchTextGrowthView } }) => {
    setSearchState({ ...searchState, searchTextGrowthView })
  }

  const onDelete = (value) => {
    if (value === ARCHIVED) return setSearchState({ ...searchState, showArchivedGrowthIndicators: false })
    setSearchState({ ...searchState, searchTextGrowthView: '' })
  }

  return (
    <AutoComplete
      value={value}
      onUpdateInput={onUpdateInput}
      onDelete={onDelete}
      inputValue={searchState.searchTextGrowthView}
    />
  )
}
