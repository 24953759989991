export const ONE_REM_IN_PX = 16
export const SMALL_SPACING = 0.7
export const SMALL_SPACING_PX = SMALL_SPACING * ONE_REM_IN_PX
export const SMALL_SPACING_RADII = SMALL_SPACING_PX / 2

export const tinySpacing = '0.35rem'
export const littleSpacing = '0.5rem'
export const smallSpacing = `${SMALL_SPACING}rem`
export const regularSpacing = '1rem'
export const defaultSpacing = '1.5rem'
export const mediumSpacing = '2.5rem'
export const bigSpacing = '3rem'
export const minSpacing = '2px'
