import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { asObject } from '../ygdrasil/libs/Common'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import { Organization, StrategicPillar } from '../ygdrasil/types/types'
import Box from './Box'
import { StyledChip } from './ChipInputTeamList'

const DEFAULT_ORG = { businessPlan: { strategicPillars: [] as StrategicPillar[] } }
export const ChipInputStrategicPillarsList = ({ strategicPillarIds }: { strategicPillarIds: string[] }) => {
  const { state } = useAppState()
  const { data: orgainzation = DEFAULT_ORG as Organization } = useOrganization(state)
  const { businessPlan } = orgainzation
  const validStrategicPillars = asObject(businessPlan.strategicPillars, '_id')

  return (
    <Box direction="row">
      {strategicPillarIds
        .filter((spId) => validStrategicPillars[spId])
        .map((sp, key, arr) => (
          <Box key={key} right spacing={smallSpacing}>
            <StyledChip key={key} label={validStrategicPillars[sp]?.name} />
          </Box>
        ))}
    </Box>
  )
}
