import React from 'react'
import { Objective } from '../ygdrasil/types/types'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getNonStrategicObjectivesForRoadmap } from '../libs/RoadmapHelper'
import Box from './Box'
import { RoadmapAccordionBody } from './RoadmapAccordionBody'
import { RoadmapNonStrategicObjectiveLine } from './RoadmapNonStrategicObjectiveLine'

export function RoadmapNonStrategicObjectives(props: {
  headerDates: string[]
  objectives: Objective[]
  allObjectives: Objective[]
}) {
  const { headerDates, objectives, allObjectives } = props
  const { state } = useAppState()

  const nonStrategicObjectives = getNonStrategicObjectivesForRoadmap(objectives, state)

  if (nonStrategicObjectives.length === 0) return null

  return (
    <Box fullWidth>
      <Box bottom top fullWidth spacing={smallSpacing}>
        <RoadmapNonStrategicObjectiveLine />
      </Box>
      <RoadmapAccordionBody
        headerDates={headerDates}
        objectives={nonStrategicObjectives}
        allObjectives={allObjectives}
      />
    </Box>
  )
}
