import { inRankOrder } from '../ygdrasil/libs/RankHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { inSearchTypeOrder, SearchObject } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'

export function getMyActivitesRows(children: Item[]) {
  return children
    .filter((item) => ['keyResult', 'objective'].includes(item.data.searchType))
    .filter((item) => !!item.data.hasMatchedChild)
    .sort(inRankOrder)
    .sort((a, b) => inSearchTypeOrder(a.data as unknown as SearchObject, b.data as unknown as SearchObject))
}
