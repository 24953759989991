import invariant from 'invariant'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useNotification from '../hooks/useNotification'
import {
  getAllLikedByNames,
  getLikedByNumber,
  getLikedByText,
  MAX_LIKED_NUMBER
} from '../ygdrasil/libs/CelebrationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { EventParams, UserAddIndicatorValueParams } from '../ygdrasil/libs/EventHelper'
import Images from '../ygdrasil/libs/Images'
import { celebrateEvent } from '../ygdrasil/libs/ProcessHandler'
import * as QueryHooks from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { CelebrationViewItemProps } from './CelebrationViewItem'
import { DEFAULT_ICON_SIZE } from './Icon'
import Tooltip from './Tooltip'
import { useState } from 'react'

export function CelebrationViewItemLikes(props: CelebrationViewItemProps<Partial<EventParams>>) {
  const { event: _event } = props
  const { params } = _event
  const { eventId } = params
  const { data: event } = QueryHooks.useEvent<UserAddIndicatorValueParams>(eventId || _event._id)
  const { state } = useAppState()
  const { user } = state
  const logEvent = useLogEvent()
  const [isLoading, setIsLoading] = useState(false)

  const likedByNumber = getLikedByNumber(event?.likes)
  const likedByText = getLikedByText(event)

  const onClickCelebrate = () => {
    invariant(event, 'event')
    const { objective, indicator } = event.params
    Promise.resolve(setIsLoading(true))
      .then(() => celebrateEvent({ indicator, objective, event: event, user, logEvent }))
      .finally(() => setIsLoading(false))
  }

  return (
    <Box fullWidth direction="row" align="center" top spacing={smallSpacing} left link onClick={onClickCelebrate}>
      <FigmaText textKey={Texts.cssCelebrationItemCelebratingNumber} text={likedByNumber.toString()} />
      <Box left spacing={smallSpacing}>
        <FigmaImageContainer imageKey={{ ...Images.celebrationIcon, width: DEFAULT_ICON_SIZE }} isLoading={isLoading} />
      </Box>
      <Tooltip title={likedByNumber > MAX_LIKED_NUMBER ? getAllLikedByNames(event?.likes).join('\n') : ''}>
        <Box left spacing={smallSpacing}>
          <FigmaText textKey={Texts.cssCelebrationItemCelebratingText} text={likedByText} />
        </Box>
      </Tooltip>
    </Box>
  )
}
