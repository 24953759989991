import styled from '@emotion/styled'
import { superBigBorderRadius, tinyBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import { KeyResult } from '../ygdrasil/types/types'
import Box from './Box'
import { Graph } from './Graph'
import { BIG_ICON_SIZE, CloseIcon } from './Icons'

export function DashboardIndicator({
  item,
  parentItem,
  onClickHeader,
  onClose
}: {
  parentItem: Item
  item: Item
  onClickHeader?: () => unknown
  onClose?: () => unknown
}): JSX.Element {
  const keyResult = item.data.data as KeyResult
  const isEmptyState = (keyResult.values || []).length === 0
  return (
    <GraphWrapper fullWidth top right bottom isEmptyState={isEmptyState} position="relative">
      <CloseIconWrapper position="absolute" pointer>
        {!!onClose && (
          <Box>
            <Box pointer onClick={onClose}>
              <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
            </Box>
          </Box>
        )}
      </CloseIconWrapper>
      <GraphContainer fullWidth>
        <Graph item={item} parentItem={parentItem} onClickHeader={onClickHeader} showIndicatorSummary />
      </GraphContainer>
    </GraphWrapper>
  )
}

const GraphWrapper = styled(Box)<{ isEmptyState: boolean }>`
  background-color: ${({ isEmptyState }) => (isEmptyState ? Colors.ghostwhite : Colors.surface)};
  filter: ${({ isEmptyState }) => isEmptyState && `grayscale(1)`};
  border-radius: ${tinyBorderRadius};
`
const CloseIconWrapper = styled(Box)`
  background-color: ${Colors.whiteBg};
  border-radius: ${superBigBorderRadius};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  right: -${smallSpacing};
  top: -${smallSpacing};
  z-index: 999;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);

    background-color: ${Colors.neutral95};
    svg {
      fill: ${Colors.neutral30};
    }
  }
  svg {
    fill: ${Colors.neutral70};
  }
`
const GraphContainer = styled(Box)`
  border-radius: ${tinyBorderRadius};
`
