import * as Yup from 'yup'
import { URL_REGEXP } from '../ygdrasil/libs/Consts'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'

export const RegexUrl = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/

const getMessage = (message?: string) => {
  if (typeof message === 'string') return message
  return `${getFigmaText(Texts.genericInputErrorText)}`
}

export const RequiredTextValidation = (message = getMessage()) => {
  return Yup.string().required(message).min(2, message)
}

// TODO remove use RequiredTextValidation
export const RequiredNameValidationDeprecated = (name: string, message?: string) => {
  const _message = getMessage(message)
  return Yup.string().required(_message).min(2, _message)
}

// TODO remove use RequiredTextValidation
export const TextValidationDeprecated = (name: string, message?: string) => {
  const _message = getMessage(message)
  return Yup.string().min(2, _message)
}

export const NumberValidation = (message: string) => {
  const _message = getMessage(message)
  return Yup.number().typeError(_message)
}

export const RequiredNumberValidation = (message: string) => {
  const _message = getMessage(message)
  return Yup.number().typeError(_message).required(_message)
}

export const EmailValidation = (name: string, message?: string) => {
  const _message = getMessage(message)
  return Yup.string().required(_message).typeError(_message).email(_message)
}

export const UrlValidation = (message = 'Enter Url') => Yup.string().matches(URL_REGEXP, { message })

export const KeyResultValidation = Yup.object().shape({
  name: RequiredTextValidation(),
  startValue: RequiredNumberValidation(getFigmaText(Texts.rightPanelIndicatorStartValueError)),
  goalValue: RequiredNumberValidation(getFigmaText(Texts.rightPanelIndicatorEndValueError))
})
