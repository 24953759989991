import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixes'
import { ONE_REM_IN_PX } from '../enums/Spacings'
import { ActivityBoardColumns } from '../libs/ActivityBoardHelper'
import { ACTION_BOARD_COLUMN_MIN_WIDTH } from '../libs/HardCodedSizes'
import FigmaText from '../ygdrasil/components/FigmaText'
import Colors from '../ygdrasil/libs/Colors'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'

export const ActivityBoardKanbanEmptyState = () => {
  const columns = ActivityBoardColumns

  return (
    <Box fullWidth gap={`${ONE_REM_IN_PX}px`}>
      <InnerContainer position="absolute" fullHeight direction="row">
        {Object.entries(columns).map(([activityStatus, column], index) => {
          return (
            <ActivityBoardColumnContainer key={activityStatus}>
              <Box fullWidth align="center">
                <FigmaText textKey={Texts.cssStatusPanelHeaderText} text={column.title} textTransform="uppercase" />
              </Box>
            </ActivityBoardColumnContainer>
          )
        })}
      </InnerContainer>
    </Box>
  )
}

const InnerContainer = styled(Box)`
  opacity: 0.2;
`

const DefaultColumnContainer = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: ${ACTION_BOARD_COLUMN_MIN_WIDTH}px;
`

export const ActivityBoardColumnContainer = styled(DefaultColumnContainer)`
  background: ${Colors.ghostwhite};
  box-shadow: 0px 0px 4px rgba(134, 134, 134, 0.4);
  border-radius: ${minBorderRadius};
  overflow: hidden;
`
