import React from 'react'
import type { inviteFromApp } from '../ygdrasil/libs/ProcessHandler'
import Modal, { ModalProps } from './Modal'
import ModalInviteUserForm from './ModalInviteUserForm'

type Props = ModalProps & { invite?: typeof inviteFromApp }
export function ModalInviteUser(props: Props) {
  return (
    <Modal {...props}>
      <ModalInviteUserForm invite={props.invite} onClose={props.onClose} />
    </Modal>
  )
}
