/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-restricted-globals */
import Menu from '@mui/material/Menu'
import React from 'react'
import { LeadNotificationInterval } from '../ygdrasil/types/types'
import FigmaImageContainer from './FigmaImageContainer'
import { MenuItem } from './MenuItem'
import { LeadNotificationIntervalTypes } from '../ygdrasil/libs/SettingsNotificationsHelper'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  onSelect?: (LeadNotificationInterval) => unknown
}

export default function SettingsNotificationsRowMenu({ anchorEl, onClose, onSelect: _onSelect }: Props) {
  const onSelectInterval = (checkinStatus: LeadNotificationInterval) => {
    onClose()
    _onSelect && _onSelect(checkinStatus)
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} disableAutoFocusItem>
      {Object.keys(LeadNotificationIntervalTypes)
        .map((key) => ({ key, val: LeadNotificationIntervalTypes[key] }))
        .map(({ key, val: { textKey, imageKey } }, index) => (
          <MenuItem
            key={index}
            onClick={(e) => onSelectInterval(key as LeadNotificationInterval)}
            textKey={textKey}
            leftElement={<FigmaImageContainer imageKey={imageKey} />}
          ></MenuItem>
        ))}
    </Menu>
  )
}
