import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import { DRAWER_WIDTH_STRING } from '../libs/HardCodedSizes'
import FigmaTextWithLinebreaks from '../ygdrasil/components/FigmaTextWithLinebreaks'
import Colors from '../ygdrasil/libs/Colors'
import Images from '../ygdrasil/libs/Images'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { EditDatasourceMachineViewProps } from '../ygdrasil/types/EditDatasourceMachine'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { BIG_ICON_SIZE, CloseIcon, OnboardingArrowLeftIcon } from './Icons'

type Props = {
  textKey: AntiloopTextType
} & Partial<EditDatasourceMachineViewProps>

export function EditDatasourceMachineViewHeader({ textKey, onClose, onClickBack }: Props) {
  return (
    <Box fullWidth>
      <Box fullWidth direction="row" align="center" justify="space-between" fullPadding spacing={smallSpacing}>
        <Box onClick={onClickBack} pointer visibility={!!onClickBack ? 'visible' : 'hidden'}>
          <OnboardingArrowLeftIcon color={Colors.black} size={BIG_ICON_SIZE} />
        </Box>
        <Box onClick={onClose} pointer>
          <CloseIcon color={Colors.black} size={BIG_ICON_SIZE} />
        </Box>
      </Box>
      <Box direction="row" align="center">
        <FigmaImageContainer imageKey={Images.microsoftExcel} />
        <Box left>
          <FigmaTextWithLinebreaks textKey={textKey} separator={<span />} />
        </Box>
      </Box>
    </Box>
  )
}

export const Container = styled(Box)`
  width: ${DRAWER_WIDTH_STRING};
`
