import PublicAssets from '../enums/PublicAssets'
import config from '../../Config'
import RoutePath from './RoutePath'

export const PRIVACY_POLICY_URL = 'https://www.ourbigpicture.se/privacy-policy'
export const TERMS_URL = 'https://www.ourbigpicture.se/terms-of-service'

export const getUrlToPublicAsset = (asset: PublicAssets) => `${config.appUrl}${asset}`

export const minSpacing = 2
export const tinySpacing = 10
export const smallSpacing = 20
export const mediumSpacing = 30
export const bigSpacing = 40

export const tinyBorderRadius = 4

export const ROOT_URL = `${config.appUrl}${RoutePath.ROOT}`

export const ACTION_CODE_SETTINGS = {
  url: `${config.appUrl}${RoutePath.ROOT}`,
  handleCodeInApp: true
}

export function formatCode(_code: string) {
  return _code
    .split('')
    .map((char, i, arr) => (i + 1 === arr.length / 2 ? `${char}-` : char))
    .join('')
}
