import Menu, { MenuProps } from '@mui/material/Menu'
import { MenuItem } from './MenuItem'
import useAppState from '../hooks/useAppState'
import { withStopProgagation } from '../libs/HtmlHelper'
import AnalyticsEvent from '../ygdrasil/enums/AnalyticsEvent'
import { useAllItems } from '../ygdrasil/hooks/useAllItems'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { logEvent } from '../ygdrasil/libs/FirebaseHelper'
import { findObjectiveForKeyResult } from '../ygdrasil/libs/ObjectiveHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult } from '../ygdrasil/types/types'
import { openEditActivityDrawer } from './DrawerGlobalEditActivityDialog'
import { openEditKeyResultDrawer } from './DrawerGlobalEditKeyResultDialog'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  keyResult: KeyResult
} & Partial<MenuProps>

export default function StrategyViewObjectiveListKeyResultListItemMenu(props: Props) {
  const { keyResult, anchorEl, onClose: _, ...rest } = props
  const { state } = useAppState()
  const { searchState } = state
  const { data: org } = useOrganization(state)
  const allItems = useAllItems()
  const objective = findObjectiveForKeyResult(keyResult, allItems)
  // TODO WRITE TEST, should not show edit activity bitton when item is not selected
  const enableEditButton = searchState.selectedItemId === keyResult._id

  const onClose = withStopProgagation(props.onClose)

  const onClickEditActivity = (e) => {
    e.stopPropagation()
    props.onClose()
    openEditActivityDrawer({ keyResult })
  }

  const onClickEditKeyResult = (e) => {
    e.stopPropagation()
    props.onClose()
    if (!objective) return
    openEditKeyResultDrawer({ objective, keyResult })
    logEvent(AnalyticsEvent.EDIT_INDICATOR_MENU, state)
  }

  if (!objective) return null

  return (
    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose} {...rest}>
      <MenuItem
        onClick={onClickEditKeyResult}
        text={getTextForTextKey(Texts.indicatorMenuEditIndicatorButtonText, org)}
      ></MenuItem>
      {enableEditButton && (
        <MenuItem
          onClick={onClickEditActivity}
          text={getTextForTextKey(Texts.indicatorMenuConnectActivityButtonText, org)}
        ></MenuItem>
      )}
    </Menu>
  )
}
