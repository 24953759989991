// @flow
import styled from '@emotion/styled'
import { TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { defaultSpacing, littleSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { getDateFromHeaderDate } from '../libs/GrowthViewGrowthIndicatorValueHelper'
import { NumberValidation, RequiredTextValidation } from '../libs/ValidationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import {
  CreateGrowthIndicatorRequest,
  Direction,
  GrowthIndicator,
  GrowthIndicatorValueType
} from '../ygdrasil/types/types'
import Box from './Box'
import GrowthViewGrowthIndicatorRowDirectionMenu from './GrowthViewGrowthIndicatorRowDirectionMenu'
import DashboardGrowthViewGrowthIndicatorRowMenu from './GrowthViewGrowthIndicatorRowMenu'
import { GrowthViewGrowthIndicatorRowName } from './GrowthViewGrowthIndicatorRowName'
import GrowthViewGrowthIndicatorValue from './GrowthViewGrowthIndicatorValue'
import { DecreasingIndicator, IncreasingIndicator } from './Icons'
import { MoreActionVert } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'

type Props = {
  growthIndicator: Partial<GrowthIndicator>
  headerDates: string[]
  indicatorInputs?: React.MutableRefObject<{
    [key: number]: HTMLDivElement
  }>
  rowIndex?: number
  setCurrentSelectedInput?: React.Dispatch<React.SetStateAction<number | null>>
  currentSelectedInput?: number | null
}

export type InitialValues = CreateGrowthIndicatorRequest

export const NAME = 'name'
const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation(),
  [GrowthIndicatorValueType.ACTUAL]: NumberValidation('Enter Actual'),
  [GrowthIndicatorValueType.GOAL]: NumberValidation('Enter Goal')
})

function DashboardGrowthViewGrowthIndicatorRow(props: Props) {
  const { headerDates, indicatorInputs, rowIndex, setCurrentSelectedInput, currentSelectedInput } = props
  const { state } = useAppState()
  const [_, setShowMoreAction] = React.useState(false)
  const [isEditing, setIsEditing] = React.useState(false)
  const [directionMenuAnchorEl, setDirectionMenuAnchorEl] = React.useState<HTMLElement>()
  const config = useLoggedInConfig()
  const onClickDirectionIcon = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    config.enableEditSuccessView && setDirectionMenuAnchorEl(e.target as any)

  const onClickEdit = () => setIsEditing(true)

  const { growthIndicator } = props
  // eslint-disable-next-line prefer-const
  let { name = '', values = {}, direction = Direction.INCREASING, isArchived } = growthIndicator

  const initialValues: InitialValues = { name, values, organizationId: state.selectedOrganizationId, direction }

  return (
    <>
      <GrowthViewGrowthIndicatorRowDirectionMenu
        growthIndicator={growthIndicator as GrowthIndicator}
        anchorEl={directionMenuAnchorEl}
        onClose={() => setDirectionMenuAnchorEl(undefined)}
      />
      <Formik
        onSubmit={console.log}
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {(formProps) => {
          return (
            <StyledTableRow
              onMouseEnter={() => setShowMoreAction(true)}
              onMouseLeave={() => setShowMoreAction(false)}
              $isArchived={isArchived}
            >
              <GrowthViewTableCell>
                <GrowthViewGrowthIndicatorRowName
                  formProps={formProps}
                  growthIndicator={growthIndicator}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  onBlur={() => setIsEditing(false)}
                  onClickAway={() => setIsEditing(false)}
                />
              </GrowthViewTableCell>
              <DirectionGrowthViewTableCell>
                <Box fullWidth align="center">
                  <Box onClick={onClickDirectionIcon} link>
                    {!direction && <IncreasingIndicator />}
                    {direction === Direction.INCREASING && <IncreasingIndicator />}
                    {direction === Direction.DECREASING && <DecreasingIndicator />}
                  </Box>
                </Box>
              </DirectionGrowthViewTableCell>
              <GrowthViewTableCell>
                <Box
                  fullWidth
                  align="flex-end"
                  justify="flex-end"
                  right
                  spacing={littleSpacing}
                  style={{ marginTop: defaultSpacing }}
                >
                  <FigmaText textKey={Texts.successViewGoalHeaderLabel} />
                </Box>
              </GrowthViewTableCell>
              {headerDates.map((headerDate, i) => {
                const { values } = formProps.values
                const date = getDateFromHeaderDate(dayjs(headerDate))
                const previousDate = getDateFromHeaderDate(dayjs(headerDate).add(-1, 'month'))
                const nextDate = getDateFromHeaderDate(dayjs(headerDate).add(1, 'month'))
                const showLeftBorder = dayjs(date).year() !== dayjs(previousDate).year()

                const value = values[date]
                const nextValue = values[nextDate]
                return (
                  <GrowthViewTableCell key={i} $showLeftBorder={showLeftBorder}>
                    <Box fullWidth align="center">
                      <GrowthViewGrowthIndicatorValue
                        date={date}
                        value={value}
                        nextValue={nextValue}
                        growthIndicator={growthIndicator as GrowthIndicator}
                        formProps={formProps}
                        headerDates={headerDates}
                        indicatorInputs={indicatorInputs}
                        rowIndex={rowIndex}
                        elementsLength={headerDates.length}
                        elementIndex={i}
                        setCurrentSelectedInput={setCurrentSelectedInput}
                        currentSelectedInput={currentSelectedInput}
                      />
                    </Box>
                  </GrowthViewTableCell>
                )
              })}
              <GrowthViewTableCell>
                {config.enableEditSuccessView && (
                  <MoreActionWithMenu
                    visible
                    isAlwaysVisible
                    Menu={(props) => (
                      <DashboardGrowthViewGrowthIndicatorRowMenu
                        {...props}
                        growthIndicator={growthIndicator as GrowthIndicator}
                        onClickEdit={onClickEdit}
                      />
                    )}
                    MoreAction={MoreActionVert}
                  />
                )}
              </GrowthViewTableCell>
            </StyledTableRow>
          )
        }}
      </Formik>
    </>
  )
}

const StyledTableRow = styled(TableRow)<{ $isArchived?: boolean }>`
  background-color: ${({ $isArchived }) => ($isArchived ? Colors['surface+2'] : Colors.white)};
`

export const GrowthViewTableCell = styled(TableCell)<{ $verticalBottomAlign?: boolean; $showLeftBorder?: boolean }>`
  padding: 0;
  border-bottom: 1px solid ${Colors.neutral90};

  ${({ $verticalBottomAlign }) => ($verticalBottomAlign ? 'vertical-align: bottom;' : '')};
  ${({ $showLeftBorder }) => ($showLeftBorder ? `border-left: 1px dashed ${Colors.primaryBase}` : '')};
`

const DirectionGrowthViewTableCell = styled(GrowthViewTableCell)`
  border-left: 1px solid ${Colors.surface};
  border-right: 1px solid ${Colors.surface};
`

export default DashboardGrowthViewGrowthIndicatorRow
