import styled from '@emotion/styled'
import { smallBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useIsSelectedObjective from '../hooks/useSelectedObjective'
import { getColor, getTextKeyForPeriodText, getWidth } from '../libs/FocusedObjectiveHelper'
import { FOCUSED_OBJECTIVE_MAX_HEIGHT, FOCUSED_OBJECTIVE_MIN_HEIGHT } from '../libs/HardCodedSizes'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import { adjust } from '../ygdrasil/libs/Common'
import { formatObjectivePeriod } from '../ygdrasil/libs/ObjectiveHelper'
import Texts from '../ygdrasil/libs/Texts'
import { Objective, ObjectiveType } from '../ygdrasil/types/types'
import Box from './Box'
import { MoreActionVerWhite } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import StrategyViewObjectiveListObjectiveMenu from './StrategyViewObjectiveListObjectiveMenu'

type Props = {
  objective: Objective
  index: number
  numberOfObjectives: number
  type?: ObjectiveType
  className?: string
}

export function FocusedObjective(props: Props): JSX.Element {
  const { objective: o, index, numberOfObjectives, className } = props
  const { type } = o
  const { state, setSearchState, setState } = useAppState()
  const { searchState } = state
  const { isSelected } = useIsSelectedObjective(o)
  const config = useLoggedInConfig()

  const preferenceStylesColor = o?.backgroundColor

  const onClickObjective = () => {
    if (o._id === searchState.selectedObjectiveId) {
      return setState({
        ...state,
        showListView: true,
        showDropDownList: false,
        searchState: { ...state.searchState, selectedObjectiveId: undefined, selectedItemId: undefined }
      })
    }
    setSearchState({ ...searchState, selectedObjectiveId: o._id, selectedItemId: o._id })
  }

  const dateTextKey = getTextKeyForPeriodText({ isSelected, type })
  const textKey = Texts.strategicObjectivesListStrategicObjectiveDescription

  return (
    <FocusedObjectiveContainer
      onClick={onClickObjective}
      index={index}
      numberOfObjectives={numberOfObjectives}
      top
      right
      left
      type={o.type}
      spacing={smallSpacing}
      multiline
      isSelected={isSelected}
      className={className}
      preferenceColor={preferenceStylesColor}
      justify="space-between"
    >
      <Box direction="row" align="center" fullWidth justify="space-between">
        <Box fullWidth>
          <FigmaText textKey={textKey} text={config.enableShowId ? o._id : o.name} />
        </Box>
        <Box left alignSelf="stretch">
          <MoreActionWithMenu
            isAlwaysVisible
            visible
            Menu={(props) => <StrategyViewObjectiveListObjectiveMenu {...props} objective={o} />}
            MoreAction={MoreActionVerWhite}
          />
        </Box>
      </Box>
      <Box fullWidth alignText="right" bottom top spacing={tinySpacing}>
        <StyledFigmaText textKey={dateTextKey} text={formatObjectivePeriod(o)} />
      </Box>
    </FocusedObjectiveContainer>
  )
}

export type TopObjectiveContainerProps = {
  type: ObjectiveType
  index: number
  numberOfObjectives: number
  isSelected: boolean
  preferenceColor?: string
}

const StyledFigmaText = styled(FigmaText)<{ preferenceColor?: string }>`
  color: ${Colors.whiteBg} !important;
`

const FocusedObjectiveContainer = styled(Box)<TopObjectiveContainerProps>`
  ${({ isSelected, preferenceColor }) =>
    isSelected
      ? `border: 1px solid ${preferenceColor ? adjust(preferenceColor, -40) : Colors.primaryBase}`
      : 'border: 1px solid transparent'};
  border-radius: ${smallBorderRadius};
  background-color: ${(props) => getColor(props)};
  width: ${(props) => getWidth(props.numberOfObjectives)}px !important;
  min-width: ${(props) => getWidth(props.numberOfObjectives)}px !important;
  min-height: ${FOCUSED_OBJECTIVE_MIN_HEIGHT};
  max-height: ${FOCUSED_OBJECTIVE_MAX_HEIGHT};
  height: 100%;
  cursor: pointer;
`
