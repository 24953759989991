import { DriveItem, WorkbookWorksheet } from '@microsoft/microsoft-graph-types'
import { Formik } from 'formik'
import { useMsalGraphQuery } from '../hooks/useMsalGraphQuery'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import { EVERY_DAY } from '../ygdrasil/libs/Consts'
import { isValidData } from '../ygdrasil/libs/ExcelHelper'
import {
  ALL_EXCEL_FILES_QUERY,
  getQueryForDriveItemAndSheetData,
  getQueryForSheetsForDriveItemId
} from '../ygdrasil/libs/MsalQueries'
import { Printable } from '../ygdrasil/libs/TextRepository'
import Texts from '../ygdrasil/libs/Texts'
import { EditDatasourceMachineViewProps } from '../ygdrasil/types/EditDatasourceMachine'
import { IdName } from '../ygdrasil/types/types'
import Box from './Box'
import Button from './Button'
import { EditDatasourceMachineViewFooter } from './EditDatasourceMachineViewFooter'
import { Container, EditDatasourceMachineViewHeader } from './EditDatasourceMachineViewHeader'
import Loader from './Loader'
import SelectFieldWithLabel from './SelectFieldWithLabel'
import { TextFieldWithLabelFormik } from './TextFieldWithLabelFormik'
import * as Yup from 'yup'
import { EditDatasourceMachineViewEditSheetErrorTutorialMessage } from './EditDatasourceMachineViewEditSheetErrorTutorialMessage'
import { useEffect } from 'react'

const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation()
})

export default function EditDatasourceMachineViewEditSheet(props: EditDatasourceMachineViewProps) {
  const { setMachineState, onClose, state, onPressContinue } = props
  const isEditingDatasource = !!props.state.data.dataSource

  const setSelectedDriveItem = (selectedDriveItem: IdName) =>
    setMachineState({ ...state, data: { ...state.data, selectedDriveItem } })

  const setSelectedSheet = (selectedSheet: IdName) =>
    setMachineState({ ...state, data: { ...state.data, selectedSheet } })

  const { connection, dataSource, selectedDriveItem, selectedSheet, connectionType } = state.data
  const { data: driveItems = [], isLoading: isLoadingDriveItems } = useMsalGraphQuery<DriveItem[]>({
    url: ALL_EXCEL_FILES_QUERY,
    connection: state.data.connection
  })

  const showDriveITemsQueryError = driveItems.length === 0 && !isLoadingDriveItems

  const driveItemOptions = driveItems.map(({ name: key, id: value }) => ({
    key: key as string,
    value: value as string
  }))

  // Sheets
  const driveItemId = selectedDriveItem?.id || dataSource?.driveItemId || undefined
  const sheetId = selectedSheet?.id || dataSource?.sheetId || undefined
  const sheetsQuery = driveItemId ? getQueryForSheetsForDriveItemId(driveItemId) : undefined
  const { data: sheets, isLoading: isLoadingSheets } = useMsalGraphQuery<WorkbookWorksheet[]>({
    connection,
    url: sheetsQuery
  })
  const _sheets = sheets || []
  const sheetOptions = _sheets.map(({ name: key, id: value }) => ({
    key: key as string,
    value: value as string
  }))

  //Data
  const dataQuery = !!driveItemId && !!sheetId ? getQueryForDriveItemAndSheetData(driveItemId, sheetId) : undefined
  const { data: rows, isLoading: isLoadingData } = useMsalGraphQuery<Printable[][]>({
    connection,
    url: dataQuery,
    mapData: ({ values }) => values
  })

  let showSheetQueryError = sheetOptions.length === 0 && !!sheets
  if (!!rows && !isValidData(rows)) showSheetQueryError = true

  const defaultName = !!selectedDriveItem && !!selectedSheet ? `${selectedDriveItem.name} - ${selectedSheet.name}` : ''
  const initialValues = {
    name: dataSource?.name || defaultName,
    interval: EVERY_DAY
  }

  const onSubmit = ({ name }) => {
    !showDriveITemsQueryError &&
      !showSheetQueryError &&
      !!sheetId &&
      !!driveItemId &&
      onPressContinue({
        ...state,
        data: {
          ...state.data,
          dataSource: { ...state.data.dataSource, name },
          rows: rows as any[],
          selectedSheet: { id: sheetId, name: selectedSheet?.name || '' },
          selectedDriveItem: { id: driveItemId, name: selectedDriveItem?.name || '' }
        }
      })
  }

  return (
    <Container>
      <EditDatasourceMachineViewHeader
        textKey={Texts.adminIntegrationsCreateConnectionWizardStep2HeaderText}
        {...props}
        onClickBack={!isEditingDatasource ? props.onClickBack : undefined}
      />
      <Box fullWidth fullPadding>
        <SelectFieldWithLabel
          fullWidth
          labelTextKey={Texts.adminIntegrationsCreateConnectionWizardStep2SpreadSheetNameLabel}
          value={selectedDriveItem?.id || dataSource?.driveItemId || ''}
          options={driveItemOptions}
          onChange={({ target: { value: driveItemId } }) =>
            setSelectedDriveItem(driveItems.find(({ id }) => id === driveItemId) as IdName)
          }
          isLoading={isLoadingDriveItems}
        />
        {showDriveITemsQueryError && (
          <FigmaText textKey={Texts.adminIntegrationsCreateConnectionWizardStep2SpreadSheetNameErrorText} />
        )}
      </Box>

      <Box fullWidth fullPadding>
        <SelectFieldWithLabel
          fullWidth
          labelTextKey={Texts.adminIntegrationsCreateConnectionWizardStep2SheetNameLabel}
          value={selectedSheet?.id || dataSource?.sheetId || ''}
          options={sheetOptions}
          onChange={({ target: { value: sheetId } }) =>
            setSelectedSheet(_sheets.find(({ id }) => id === sheetId) as IdName)
          }
          isLoading={isLoadingSheets && !!selectedDriveItem}
        />
        {showSheetQueryError && (
          <EditDatasourceMachineViewEditSheetErrorTutorialMessage connectionType={connectionType} />
        )}
      </Box>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema} enableReinitialize>
        {(formProps) => (
          <Box fullWidth left right top>
            <Box fullWidth>
              <TextFieldWithLabelFormik
                fullWidth
                name="name"
                labelTextKey={Texts.adminIntegrationsCreateConnectionWizardStep2DataSourceNameLabel}
                formProps={formProps}
              />
            </Box>
            <Box fullWidth top>
              <SelectFieldWithLabel
                fullWidth
                name="interval"
                value={EVERY_DAY}
                labelTextKey={Texts.adminIntegrationsCreateConnectionWizardStep2SyncCadenceLabelLabel}
                options={[{ key: EVERY_DAY, value: EVERY_DAY }]}
              />
            </Box>
            <Box top fullWidth align="flex-end">
              <Button
                textKey={Texts.continueButtonText}
                onClick={formProps.handleSubmit as any}
                loading={state.isLoading || isLoadingData}
              />
            </Box>
          </Box>
        )}
      </Formik>
      <Box top fullWidth right>
        <EditDatasourceMachineViewFooter />
      </Box>
    </Container>
  )
}
