import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useState } from 'react'
import { minBorderRadius } from '../enums/BorderRadixes'
import { tinySpacing } from '../enums/Spacings'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { isOverDue } from '../ygdrasil/libs/ActivityHelper'
import Colors from '../ygdrasil/libs/Colors'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { updateActivity } from '../ygdrasil/libs/ProcessHandler'
import { Activity } from '../ygdrasil/types/types'
import Box from './Box'
import { DatePickerPopper } from './DatePickerPopper'
import { FigmaTextForDueDate } from './FigmaTextForDueDate'

export function ActivityBoardKanbanCardDueDate({
  activity,
  onEditActivity
}: {
  activity: Activity
  onEditActivity?: (activity: Activity) => void
}) {
  const logEvent = useLogEvent()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [value, setValue] = useState(activity.dueDate || '')

  const onChange = (dueDate) => {
    const newActivity = { ...activity, dueDate }
    onEditActivity && onEditActivity(newActivity)
    updateActivity(newActivity, logEvent)
  }

  const Container = isOverDue(activity) ? OverDueContainer : Box

  return (
    <>
      <Container
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          if (dayjs(value).isBefore(dayjs())) setValue(dayjs().format(YYYY_MM_DD))
        }}
        pointer
      >
        <FigmaTextForDueDate activity={activity} />
      </Container>
      <DatePickerPopper
        anchorEl={anchorEl}
        value={value}
        onChange={onChange}
        onClose={() => setAnchorEl(null)}
        minDate={dayjs().format(YYYY_MM_DD)}
      />
    </>
  )
}

const OverDueContainer = styled(Box)`
  background-color: ${Colors.error95};
  border-radius: ${minBorderRadius};
  padding: ${tinySpacing};
  box-shadow: 0px 1px 2px rgba(117, 3, 3, 0.4);

  span {
    color: ${Colors.darkred} !important;
    font-weight: 500 !important;
  }
`
