import React from 'react'
import Box from './Box'

const FixedContainer: React.FC<{ width: number }> = ({ width, children }) => {
  return (
    <>
      <Box width={`${width}px`} fullHeight />
      <Box width={`${width}px`} position="fixed" fullHeight>
        {children}
      </Box>
    </>
  )
}

export default FixedContainer
