import React from 'react'
import { DayJS } from '../ygdrasil/libs/DayJsHelper'
import { SearchDates } from '../ygdrasil/types/types'
import { Mark, OnChangePeriod } from './ObjectivePeriodAndTypeOptionsHelper'

export function createHandleSliderChange(setValue: React.Dispatch<React.SetStateAction<(number | undefined)[]>>) {
  return (e: Event, newValue: number | number[]) => {
    if (newValue[0] === newValue[1]) return

    setValue(newValue as number[])
  }
}

export function onResetSlider(isDisabled: boolean, onChange: OnChangePeriod, searchDates: SearchDates) {
  isDisabled &&
    onChange({
      startDate: DayJS(searchDates.startDate).startOf('quarter').format(),
      endDate: DayJS(searchDates.endDate).endOf('quarter').format()
    })
}

export const getValuesSideChanged = (valueHistory: (number | undefined)[], values: number[]) => {
  const isLeftValueChanged = valueHistory[0] !== values[0]
  const isRightValueChanged = valueHistory[1] !== values[1]
  return { isLeftValueChanged, isRightValueChanged }
}

export const getCurrentValuesIndexes = (marks: Mark[], values: number[]) => {
  const [leftIndex, rightIndex] = [
    marks.findIndex(({ value }) => value === values[0]),
    marks.findIndex(({ value }) => value === values[1])
  ]
  return { leftIndex, rightIndex }
}

export const getCalculatedSidesValues = (
  isRightValueChanged: boolean,
  isLeftValueChanged: boolean,
  marks: Mark[],
  values: number[],
  timespanRestriction?: number
) => {
  if (!timespanRestriction) {
    return {
      nextLeftValue: values[0],
      nextRightValue: values[1]
    }
  }

  const { leftIndex, rightIndex } = getCurrentValuesIndexes(marks, values)

  const nextLeftValueIndex = rightIndex - timespanRestriction < 0 ? 0 : rightIndex - timespanRestriction
  const nextRightValueIndex =
    leftIndex + timespanRestriction > marks.length ? marks.length : leftIndex + timespanRestriction

  const nextLeftValue = isRightValueChanged ? marks[nextLeftValueIndex].value : values[0]
  const nextRightValue = isLeftValueChanged ? marks[nextRightValueIndex].value : values[1]

  return {
    nextLeftValue,
    nextRightValue
  }
}
