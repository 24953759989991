import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { defaultSpacing, minSpacing, smallSpacing } from '../enums/Spacings'
import { getHeaderDates, isSameQuarter } from '../libs/RoadmapAccordionBodyHelper'
import { formatQuaterText } from '../libs/RoadmapHelper'
import FigmaText from '../ygdrasil/components/FigmaText'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'
import Colors from '../ygdrasil/libs/Colors'
import { getArray } from '../ygdrasil/libs/Common'
import { NO_OF_ROADMAP_HEADER_DATES } from '../ygdrasil/libs/Consts'
import Texts from '../ygdrasil/libs/Texts'
import { ArrowContainer } from './ArrowContainer'
import Box from './Box'
import ObjectivePeriodAndTypeOptionsSlider from './ObjectivePeriodAndTypeOptionsSlider'
import { RoadmapTopObjectives } from './RoadmapTopObjectives'

export default function RoadmapView() {
  const config = useLoggedInConfig()
  const defaultSearchDates = useMemo(() => {
    return {
      startDate: dayjs().startOf('year').format(),
      endDate: dayjs().endOf('year').format()
    }
  }, [])

  const [searchDates, setSearchDates] = useState(defaultSearchDates)

  const diffInQuaters = dayjs(searchDates.endDate).diff(dayjs(searchDates.startDate), 'quarter') + 1
  const headerDates = getHeaderDates(searchDates.startDate, diffInQuaters)

  const onClickBack = () =>
    setSearchDates({
      startDate: dayjs(searchDates.startDate).add(-1, 'quarter').startOf('quarter').format(),
      endDate: dayjs(searchDates.endDate).add(-1, 'quarter').endOf('quarter').format()
    })

  const onClickForward = () =>
    setSearchDates({
      startDate: dayjs(searchDates.startDate).add(1, 'quarter').startOf('quarter').format(),
      endDate: dayjs(searchDates.endDate).add(1, 'quarter').endOf('quarter').format()
    })

  const calculatedStartDate = dayjs(searchDates.startDate).add(-1, 'quarter').format()

  return (
    <>
      <Box fullWidth align="center">
        <Box style={{ width: '50%' }}>
          <ObjectivePeriodAndTypeOptionsSlider
            onChange={setSearchDates}
            searchDates={searchDates}
            sliderStartDate={dayjs(calculatedStartDate).toString()}
            sliderEndDate={dayjs(calculatedStartDate).add(4, 'year').add(1, 'quarter').toString()}
            enableHelperText={false}
            dynamicPeriod
          />
        </Box>
      </Box>
      <Container fullWidth top bottom spacing={minSpacing}>
        <Box fullWidth left right spacing={smallSpacing}>
          <Box direction="row" justify="space-between" align="center" fullWidth>
            <Box right spacing={smallSpacing}>
              <Box top spacing={defaultSpacing}>
                <ArrowContainer direction="back" onClick={onClickBack} />
              </Box>
            </Box>
            <Box fullWidth>
              <Box fullWidth direction="row" justify="space-around" bottom spacing={minSpacing}>
                {headerDates.map((date, key) => (
                  <FigmaText
                    key={key}
                    textKey={isSameQuarter(date) ? Texts.cssRoadmapQuarterTextCurrent : Texts.cssRoadmapQuarterText}
                    text={formatQuaterText(date)}
                  />
                ))}
              </Box>
              <Box position="relative" fullWidth>
                <Box position="absolute" fullWidth fullHeight direction="row" justify="space-between">
                  {getArray(NO_OF_ROADMAP_HEADER_DATES + 1).map((x, key) => (
                    <VerticalLine key={key} />
                  ))}
                </Box>
                <RoadmapTopObjectives headerDates={headerDates} />
              </Box>
            </Box>
            <Box left spacing={smallSpacing}>
              <Box top spacing={defaultSpacing}>
                <ArrowContainer direction="forward" onClick={onClickForward} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  )
}

const Container = styled(Box)`
  background: ${Colors.whiteBg};
  border-radius: ${tinyBorderRadius};
  border: 1px solid ${Colors.primary90};
  margin-top: ${defaultSpacing};
`

const VerticalLine = styled(Box)`
  height: 100%;
  width: 1px;
  background-color: ${Colors['surface+2']};
`
