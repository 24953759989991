import { useEffect, useState } from 'react'
import { TreeItem } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'

export const useIsExpandedTreeItem = (item: TreeItem) => {
  useEffect(() => {
    setIsExpanded(item.isExpanded || null)
  }, [item.isExpanded])

  const [isExpanded, setIsExpanded] = useState<boolean | null>(item.isExpanded || null)

  return { isExpanded, setIsExpanded }
}
