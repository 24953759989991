import React from 'react'
import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import { FigmaTextWithTooltipOverflow } from './FigmaTextWithTooltipOverflow'
import { IconProps } from './Icon'
import { SettingsNotificationRowInterval } from './SettingsNotificationRowInterval'

export function SettingsNotificationRow({
  object,
  key,
  Icon,
  onSelectInterval
}: {
  key: number
  object: Objective | KeyResult
  Icon: React.FC<Partial<IconProps>>
  onSelectInterval: (LeadNotificationInterval) => unknown
}): JSX.Element {
  return (
    <StyledSettingsNotificationRow
      fullWidth
      direction="row"
      justify="space-between"
      key={key}
      top
      spacing={smallSpacing}
    >
      <Box direction="row" align="center" width="90%">
        <Box right>
          <Icon />
        </Box>
        <FigmaTextWithTooltipOverflow textKey={Texts.cssAdminNotificationsListItemText} text={object.name} />
      </Box>
      <Box width="10%">
        <SettingsNotificationRowInterval object={object} onSelectInterval={onSelectInterval} />
      </Box>
    </StyledSettingsNotificationRow>
  )
}
const StyledSettingsNotificationRow = styled(Box)<{ key: number }>`
  background-color: ${({ key }) => (key % 2 === 0 ? Colors.surface : Colors.white)};
`
