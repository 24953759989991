import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import Box from './Box'
import { StrategyViewObjectiveListRowDetailBody } from './StrategyViewObjectiveListRowDetailBody'
import { TreeData } from '../ygdrasil/libs/StrategyViewTreeSearchHelper'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'

export const StrategyViewObjectiveListRowDetail = ({
  tree,
  onSelectItem,
  onClickClose
}: {
  tree: TreeData
  onSelectItem: (item: Item) => unknown
  onClickClose: () => void
}) => {
  const { state } = useAppState()
  const { searchState } = state
  const { selectedItemId } = searchState

  if (!selectedItemId) return null

  const selectedItem = (!!selectedItemId && tree.items[selectedItemId]) || undefined
  const parentId = selectedItem?.data.parentId

  const parentToSelectedItem = (parentId && tree.items[parentId]) || undefined

  if (!selectedItem) return null

  return (
    <Container spacing={smallSpacing} isListView={!state.showListView}>
      <StrategyViewObjectiveListRowDetailBody
        item={selectedItem}
        parentItem={parentToSelectedItem}
        onSelectItem={(itemId) => onSelectItem(tree.items[itemId])}
        onClickClose={onClickClose}
      />
    </Container>
  )
}

const Container = styled(Box)<{ isListView?: boolean }>`
  max-width: ${({ isListView }) => (isListView ? 'calc(100vw - 98px)' : '60vw')};
  min-width: ${({ isListView }) => (isListView ? 'calc(100vw - 98px)' : '60vw')};
`
