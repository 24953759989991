import styled from '@emotion/styled'
import { tinyBorderRadius } from '../enums/BorderRadixes'
import { PictureSizes, useUrlForProfilePicture } from '../ygdrasil/hooks/useUrlForProfilePicture'
import Colors from '../ygdrasil/libs/Colors'
import { User } from '../ygdrasil/types/types'
import Box from './Box'
import { BIG_ICON_SIZE, DEFAULT_ICON_SIZE, PersonIcon } from './Icons'
import { RoundImage, getRoundImageStyles } from './StyledComponents'
import React from 'react'
import Loader from './Loader'
import { ONE_SECOND, TWO_SECONDS } from '../ygdrasil/libs/Consts'

type Props = {
  size?: PictureSizes
  user: User
  overrideUrl?: string
}

export default function PersonalPhotoView({ user, size = PictureSizes.PROFILE_PICTURE, overrideUrl }: Props) {
  const showEmptyState = !overrideUrl && !user.profileImage
  return (
    <PersonalPhotoContainer align="center" justify="center" size={size}>
      <PersonalPhotoInnerContainer align="center" justify="center" size={size}>
        {!showEmptyState && <PersonalPhotoViewUrl user={user} size={size} overrideUrl={overrideUrl} />}
        {showEmptyState && (
          <PersonIcon
            size={size / 2.5}
            color={Colors.white}
            backgroundColor={Colors.primary10}
            borderRadius={tinyBorderRadius}
          />
        )}
      </PersonalPhotoInnerContainer>
    </PersonalPhotoContainer>
  )
}

const PersonalPhotoViewUrl = ({ size = PictureSizes.PROFILE_PICTURE, user }: Props) => {
  const url = useUrlForProfilePicture(size, user)
  const [error, setError] = React.useState<boolean>()
  const onError = () => {
    setError(true)
    setTimeout(() => setError(false), TWO_SECONDS)
  }

  if (error) return <Loader />
  return <RoundImage src={url} alt="Profile" size={size} onError={onError} />
}

const RATIO = 1.2
const PersonalPhotoContainer = styled(Box)<{ size: number }>`
  ${({ size }) => getRoundImageStyles(size * RATIO)}
  background-color: ${Colors.surface};
`

const PersonalPhotoInnerContainer = styled(Box)<{ size: number }>`
  ${({ size }) => getRoundImageStyles(size)}
  border: 1px dashed ${Colors.neutralUnselected};
`
