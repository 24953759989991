import React from 'react'
import styled from '@emotion/styled'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { minBorderRadius } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import Colors from '../ygdrasil/libs/Colors'
import Box from './Box'
import { AddIcon } from './Icons'
import { NoWrapFigmaText, NoWrapFigmaTextForStrategyModel } from './StyledComponents'
import shadows from '../enums/Shadows'
import Texts from '../ygdrasil/libs/Texts'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import { IconProps } from './Icon'

export default function AddButton(props: {
  labelTextKey: AntiloopTextType
  onClick?: () => void
  Icon?: React.FC<Partial<IconProps | undefined>>
  enableIcon?: boolean
}) {
  const { labelTextKey, onClick, Icon = AddIcon, enableIcon = true } = props
  return (
    <Container direction="row" justify="center" align="center" link onClick={onClick} top bottom spacing={tinySpacing}>
      <Box spacing={tinySpacing} left>
        <Box right spacing={tinySpacing}>
          {enableIcon ? <Icon /> : undefined}
        </Box>
      </Box>
      <Box right spacing={smallSpacing}>
        <NoWrapFigmaTextForStrategyModel
          textKey={Texts.goToActivityBoardButtonText}
          text={getFigmaText(labelTextKey)}
        />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.whiteBg};
  border-radius: ${minBorderRadius};
  box-shadow: ${shadows.infoCard};
  :hover {
    box-shadow: ${shadows.soft};
    text-decoration: none;
  }
`
