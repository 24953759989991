import React from 'react'
import useAppState from '../hooks/useAppState'
import { isDisabled } from '../libs/CheckinStatusHelper'
import { withStopProgagation } from '../libs/HtmlHelper'
import { CheckinStatus as CheckInStatusEnum } from '../ygdrasil/enums/CheckinStatus'
import useLogEvent from '../ygdrasil/hooks/useLogEvent'
import { getTextForTextKey } from '../ygdrasil/libs/FigmaTextForStrategyModelHelper'
import { addCurrentConfidenceToConfidences } from '../ygdrasil/libs/ObjectiveHelper'
import { useOrganization } from '../ygdrasil/libs/QueryHooks'
import Texts from '../ygdrasil/libs/Texts'
import { KeyResult, Objective } from '../ygdrasil/types/types'
import Box from './Box'
import CheckinStatusMenu from './CheckinStatusMenu'
import { CheckinStatusView } from './CheckinStatusView'
import Tooltip from './Tooltip'
import { updateIndicator, updateObjective } from '../ygdrasil/libs/ProcessHandler'

export const CheckInStatusForKeyResult = ({ keyResult, objective }: { keyResult: KeyResult; objective: Objective }) => {
  const { confidences = [] } = keyResult
  const logEvent = useLogEvent()
  const checkInStatus = confidences[confidences.length - 1]?.status
  const disabled = isDisabled(objective)
  const onSelectCheckinStatus = (checkInStatus: CheckInStatusEnum) =>
    updateIndicator(
      { ...keyResult, confidences: addCurrentConfidenceToConfidences(checkInStatus, confidences) },
      logEvent
    )
  return (
    <CheckinStatus checkinStatus={checkInStatus} onSelectCheckinStatus={onSelectCheckinStatus} disabled={disabled} />
  )
}

export const CheckInStatusForObjective = ({ objective }: { objective: Objective }) => {
  const logEvent = useLogEvent()
  const { confidences = [] } = objective
  const checkInStatus = confidences[confidences.length - 1]?.status
  const disabled = isDisabled(objective)

  const onSelectCheckinStatus = (checkInStatus: CheckInStatusEnum) => {
    return updateObjective(
      {
        ...objective,
        confidences: addCurrentConfidenceToConfidences(checkInStatus, confidences)
      },
      logEvent
    )
  }
  return (
    <CheckinStatus checkinStatus={checkInStatus} onSelectCheckinStatus={onSelectCheckinStatus} disabled={disabled} />
  )
}

function CheckinStatus({
  onSelectCheckinStatus,
  checkinStatus,
  disabled
}: {
  checkinStatus?: CheckInStatusEnum
  onSelectCheckinStatus: (CheckinStatus) => unknown
  disabled?: boolean
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const { state } = useAppState()
  const { data: org } = useOrganization(state)

  const _onClick = (e) => {
    if (disabled) return
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <CheckinStatusMenu
        onClose={withStopProgagation(() => setAnchorEl(undefined))}
        anchorEl={anchorEl}
        onSelectCheckinStatus={onSelectCheckinStatus}
      />
      <Tooltip title={disabled ? getTextForTextKey(Texts.checkinStatusOptionNotPossible, org) : ''}>
        <Box onClick={withStopProgagation(_onClick)} link>
          <CheckinStatusView checkinStatus={checkinStatus} />
        </Box>
      </Tooltip>
    </>
  )
}
