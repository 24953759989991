import { useEffect } from 'react'
import useNotification from '../hooks/useNotification'
import eventEmitter, { Events } from '../ygdrasil/libs/EventEmitter'
import Texts from '../ygdrasil/libs/Texts'
import { NotificationProps } from './Notification'

export default function NotificationGlobal() {
  const notification = useNotification()

  useEffect(() => {
    eventEmitter.on(Events.NEW_SERVER_ERROR, () => notification.error())
    eventEmitter.on(Events.NEW_ANALYTICS_EVENT, (text) => notification.info({ text }))
    eventEmitter.on(Events.NEW_NOTIFICATION, (props: Partial<NotificationProps>) => notification.info(props))

    return () => {
      eventEmitter.removeAllListeners()
    }
  }, [])

  return null
}
