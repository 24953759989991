import { Popper, styled } from '@mui/material'
import React from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { hasContent } from '../libs/WYSIWYGEditor'
import Colors from '../ygdrasil/libs/Colors'
import { Item } from '../ygdrasil/libs/StrategyViewObjectiveListHelper'
import Texts from '../ygdrasil/libs/Texts'
import Box from './Box'
import { BIG_ICON_SIZE, DEFAULT_ICON_SIZE, InfoIcon } from './Icons'
import { RichText } from './RichText'
import { StrategicPillar } from '../ygdrasil/types/types'

export function BusinessPlanRingArrowInfo({ strategicPillar }: { strategicPillar: StrategicPillar }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const { description } = strategicPillar

  const onMousLeave = () => setAnchorEl(undefined)
  const onMouseEnter = (e) => setAnchorEl(e.currentTarget)

  if (!description) return null

  return (
    <Box spacing={tinySpacing} link onMouseEnter={onMouseEnter} onMouseLeave={onMousLeave}>
      <InfoIcon size={BIG_ICON_SIZE} />
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} placement="bottom-start">
        <Box fullPadding spacing={smallSpacing}>
          <Container fullPadding spacing={smallSpacing}>
            <RichText fallbackTextKey={Texts.allTooltipTextWysiwig} richText={description} fallbackText={description} />
          </Container>
        </Box>
      </Popper>
    </Box>
  )
}

const Container = styled(Box)`
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25));
  background: ${Colors.whiteBg};
`
