/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-restricted-globals */
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import { useState } from 'react'
import styled from '@emotion/styled'
import Colors from '../ygdrasil/libs/Colors'
import FigmaText from '../ygdrasil/components/FigmaText'
import Texts from '../ygdrasil/libs/Texts'
import { Team } from '../ygdrasil/types/types'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useSelectedTeam from '../hooks/useSelectedTeam'
import { useTeams } from '../ygdrasil/libs/QueryHooks'
import Box from './Box'
import { TeamMenuAddIcon, TeamMenuEditIcon } from './Icons'
import { MenuItem } from './MenuItem'
import { ModalCreateEditTeam } from './ModalCreateEditTeam'
import { ModalDeleteTeam } from './ModalDeleteTeam'
import { formatText, getFigmaText } from '../ygdrasil/libs/TextRepository'
import { getStateForStrategicView, getStateForTeamView } from '../libs/MenuHelper'
import useLoggedInConfig from '../ygdrasil/hooks/useLoggedInConfig'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
}

const TIMOUT_DURATION = 200 // better ux if you have this
export default function MenuButtonTeamMenu({ anchorEl, onClose }: Props) {
  const { state, setState } = useAppState()
  const { selectedTeamId } = state
  const { data: teams = [] } = useTeams(state)
  const selectedTeam = useSelectedTeam()
  const [teamToBeEdited, setEditableTeam] = useState<Team | null>()
  const [teamToBeRemoved, setTeamToBeRemoved] = useState<Team>()
  const config = useLoggedInConfig()

  const onClickEditTeam = () => {
    setEditableTeam(selectedTeam)
    onClose()
  }
  const onClickAddTeam = () => {
    setEditableTeam(null)
    onClose()
  }
  const onClickTeam = (team: Team) => {
    onClose()
    setTimeout(() => {
      if (selectedTeamId && team._id === selectedTeamId) setState(getStateForStrategicView(state))
      else setState(getStateForTeamView(team._id, state))
    }, TIMOUT_DURATION)
  }

  return (
    <>
      {teamToBeEdited !== undefined && (
        <ModalCreateEditTeam
          onClose={() => setEditableTeam(undefined)}
          team={teamToBeEdited ? teamToBeEdited : undefined}
          onClickDeleteTeam={() => teamToBeEdited && setTeamToBeRemoved(teamToBeEdited)}
        />
      )}
      {teamToBeRemoved && (
        <ModalDeleteTeam onClose={() => setTeamToBeRemoved(undefined)} team={teamToBeRemoved} onTeamRemoved={onClose} />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableAutoFocusItem
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {selectedTeam && config.enableEditTeam && (
          <MenuItem
            onClick={onClickEditTeam}
            textKey={Texts.applicationMenuTeamViewSelectedMenuEditTeamButtonText}
            text={formatText(getFigmaText(Texts.applicationMenuTeamViewSelectedMenuEditTeamButtonText), [
              selectedTeam.name
            ])}
            leftElement={<TeamMenuEditIcon />}
          />
        )}
        {config.enableEditTeam && (
          <MenuItem
            onClick={onClickAddTeam}
            textKey={Texts.applicationMenuTeamViewSelectedMenuAddNewTeamButtonText}
            leftElement={<TeamMenuAddIcon />}
          />
        )}
        {teams.length > 0 && <Divider />}
        {teams.length > 0 && (
          <Box spacing={tinySpacing} top>
            <Box left spacing={smallSpacing}>
              <FigmaText textKey={Texts.applicationMenuTeamViewSelectMenuSelectedTeamLabel} />
            </Box>
          </Box>
        )}
        {teams.map((team, i) => {
          const isSelected = team._id === selectedTeamId
          return (
            <TeamMenuItem
              key={i}
              isSelected={isSelected}
              textKey={
                isSelected
                  ? Texts.cssApplicationMenuTeamViewSelectMenuSelectedTeamText
                  : Texts.cssApplicationMenuTeamViewSelectMenuTeamText
              }
              text={team.name}
              onClick={() => onClickTeam(team)}
            />
          )
        })}
      </Menu>
    </>
  )
}

const SelectedStyles = `
  background-color: ${Colors.neutral90};
  padding: ${tinySpacing} ${smallSpacing};
  color: ${Colors.white};
`

const TeamMenuItem = styled(MenuItem)<{ isSelected?: boolean }>`
  ${({ isSelected }) => (isSelected ? SelectedStyles : '')}
  &:hover {
    ${({ isSelected }) => (isSelected ? SelectedStyles : '')}
  }
`
