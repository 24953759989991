import styled from '@emotion/styled'
import React from 'react'
import { Box } from '@mui/material'
import OnboardingPaginationDots from './OnboardingPaginationDot'
import { BoxProps } from './Box'

type Props = {
  opacityFirstDot: number
  opacitySecondDot: number
  opacityThirdDot: number
  opacityForthDot: number
}

export default function OnboardingPaginationComponent({
  opacityFirstDot,
  opacitySecondDot,
  opacityThirdDot,
  opacityForthDot
}: Props) {
  return (
    <PaginationBox direction="row" display="flex" width="100px">
      <OnboardingPaginationDots opacity={opacityFirstDot} />
      <OnboardingPaginationDots opacity={opacitySecondDot} />
      <OnboardingPaginationDots opacity={opacityThirdDot} />
      <OnboardingPaginationDots opacity={opacityForthDot} />
    </PaginationBox>
  )
}

const PaginationBox = styled(Box)<BoxProps>`
  justify-content: space-between;
`
