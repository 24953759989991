import dayjs, { Dayjs } from 'dayjs'
import { isNumber } from '../ygdrasil/libs/Common'
import { YYYY_MM_DD } from '../ygdrasil/libs/Consts'
import { GrowthIndicatorValue, GrowthIndicatorValues } from '../ygdrasil/types/types'
import { getPreviousValue } from './GrowthViewGrowthIndicatorValueGoalHelper'

export const canEditActual = (date: string, now: string = dayjs().format()) => dayjs(now).endOf('month').isAfter(date)

export function getDateFromHeaderDate(headerDate: Dayjs) {
  return dayjs(headerDate).startOf('month').format(YYYY_MM_DD)
}

export function shouldShowEmptyState(value: GrowthIndicatorValue, values: GrowthIndicatorValues) {
  return !isNumber(value?.actual) && !isNumber(value?.goal) && !isNumber(getPreviousValue({ values, value }))
}

export const getUniqueActualTabIndex = (rowIndex?: number, elementIndex?: number, elementsLength?: number) => {
  return Number(rowIndex) * 2 * Number(elementsLength) + Number(elementIndex) + 1
}

export const getUniqueGoalTabIndex = (rowIndex?: number, elementIndex?: number, elementsLength?: number) => {
  return (Number(rowIndex) * 2 + 1) * Number(elementsLength) + Number(elementIndex) + 1
}
