import withStyles from '@mui/styles/withStyles'
import { TextFieldProps } from '@mui/material/TextField'
import React from 'react'
import { getFigmaText } from '../ygdrasil/libs/TextRepository'
import { AntiloopTextType } from '../ygdrasil/types/Antiloop'
import { TextField } from './TextFieldOutlined'

type Props = {
  labelTextKey?: AntiloopTextType
  placeholderTextKey?: AntiloopTextType
  pointer?: boolean
} & TextFieldProps

export const TextFieldMobile = (props: Props) => {
  const { labelTextKey, placeholderTextKey } = props

  return (
    <StyledTextField
      variant="outlined"
      size="small"
      label={labelTextKey ? getFigmaText(labelTextKey) : undefined}
      placeholder={placeholderTextKey ? getFigmaText(placeholderTextKey) : undefined}
      {...props}
    />
  )
}

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white'
    },
    '& label': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white'
    },
    '& .MuiInputBase-input': {
      color: 'white'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'white'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white'
      }
    }
  }
})(TextField)
