import config from '../Config'
import { POST } from '../ygdrasil/libs/Consts'
import { SlackChannels } from '../ygdrasil/types/types'

export const slack = (mrkDown: string, channel: SlackChannels, extra?: Object): Promise<any> => {
  return fetch(config.slackWebHookUrl, {
    method: POST,
    body: JSON.stringify({
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: mrkDown
          }
        }
      ],
      channel,
      ...extra
    })
  }).catch(
    () => ({}) // if slack borks then unhandled promise rejection will throw slack messag => infinite loop => slack turns off integration
  )
}
