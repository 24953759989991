import React from 'react'
import { AnnouncementIcon } from './Icons'
import Box from './Box'
import styled from '@emotion/styled'
import { FormHelperText } from '@mui/material'
import Colors from '../ygdrasil/libs/Colors'
import { ErrorComponentProps } from './TextFieldFormik'
import { tinySpacing, minSpacing } from '../enums/Spacings'
import { minBorderRadius } from '../enums/BorderRadixes'

export default function TextFieldFormikErrorComponent({ formProps, name }: ErrorComponentProps) {
  return (
    <WhiteBox direction="row" align="center" fullWidth right spacing={minSpacing} fullPadding>
      <Box right spacing={tinySpacing}>
        <AnnouncementIcon />
      </Box>
      <FormHelperText error>{formProps.errors[name]}</FormHelperText>
    </WhiteBox>
  )
}

const WhiteBox = styled(Box)`
  background-color: ${Colors.whiteBg};
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1);
  border-radius: ${minBorderRadius};
`
